import React from "react";

const PrevClickableImage = (props) => {
  const { size, fill, style, className, selected } = props;
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="33.2619"
        transform="matrix(-1 0 0 1 33.7381 33.2619)"
        fill="white"
        fill-opacity="0.8"
      />
      <path
        d="M45.3796 32.7575L32.7147 20.1089C32.4218 19.8161 32.0307 19.6548 31.6138 19.6548C31.1969 19.6548 30.8058 19.8161 30.5129 20.1089L29.5802 21.0401C28.9733 21.647 28.9733 22.6332 29.5802 23.2391L40.2153 33.8607L29.5684 44.4941C29.2755 44.7869 29.1138 45.1772 29.1138 45.5934C29.1138 46.0101 29.2755 46.4004 29.5684 46.6934L30.5011 47.6244C30.7943 47.9172 31.1851 48.0785 31.602 48.0785C32.0189 48.0785 32.41 47.9172 32.7029 47.6244L45.3796 34.9642C45.6733 34.6705 45.8345 34.2783 45.8336 33.8614C45.8345 33.4429 45.6733 33.051 45.3796 32.7575Z"
        fill="#3B3B3B"
      />
    </svg>
  );
};

export default PrevClickableImage;
