import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import BackArrow from "../../components/icons/BackArrow";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import {
  getCar,
  getCarDetails,
  getCarGroups,
  publishCarForBuyers,
  publishCarForSeller,
  reportBuilder,
} from "../../store/actions/car";
import { checkVisFormsPoints, DTCFaults } from "../../store/actions/forms";
import { toggleNotification } from "../../store/actions/notification";

import Box from "@material-ui/core/Box";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MaterialLink from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Slider from "react-slick";
import InspectionReport from "../../wrappers/InspectionReportProto";

import theme from "../../theme";

// import backArrow from "../../icons/back_arrow.svg";
import BackArrowTail from "../../components/icons/BackArrowTail";

import { useTranslation } from "react-i18next";

import PositionSwitch from "../../components/PositionSwitch";
// import BookAppointmentActionBar from "../../components/BookAppointmentActionBar";
import ActionBar from "../../components/ActionBar";
import GPS from "../../components/icons/gps";
import GPSOutlined from "../../components/icons/GPSOutlined";
import CountrySpecs from "../../components/icons/CountrySpecs";
import DriveType from "../../components/icons/DriveType";
import FuelType from "../../components/icons/FuelType";
import Odometer from "../../components/icons/Odometer";
import Maximize from "../../components/icons/Maximize";
import NegotiableWebEng from "../../components/icons/NegotiableWebEng";
import NegotiableWebAr from "../../components/icons/NegotiableWebAr";
import FinalpriceWebEng from "../../components/icons/FinalpriceWebEng";
import FinalpriceWebAr from "../../components/icons/FinalpriceWebAr";
import FinalPrice from "../../components/icons/FinalPrice";
import ConditionReport from "../../components/ConditionReport";
import CloseIcon from "@material-ui/icons/Close";
import gallary from "../../icons/gallary.svg";
// import ImageActions from "../../components/ImageActions";

import FeatureIcons from "../../helpers/FeatureIcons";
import Skeleton from "@material-ui/lab/Skeleton";
import { distanceWithCommas, numberWithCommas } from "../../helpers/functions";

const getUniqueArray = (array, key) => {
  var unique = {};
  var distinct = [];
  for (var i in array) {
    if (typeof unique[array[i][key]] == "undefined") {
      distinct.push(array[i]);
    }
    unique[array[i][key]] = 0;
  }
  return distinct;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
  },
  navigationPaper: {
    display: "flex",
    backgroundColor: "#f3f3f3",
    borderRadius: 0,
    boxShadow: "none",
    height: "39px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#186985",
    },
  },
  breadcrumbsComponent: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      width: "927px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1009px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(320px + 70.414vw)",
    },
  },
  maindiv: {
    padding: "0 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  mediaGrid: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      maxHeight: "52.4419vw",
      minHeight: "42.4419vw",
      marginBottom: "0.53155vw",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "655px",
      marginBottom: "6.09px",
      minHeight: "505px",
    },
  },
  media: {
    // marginBottom: "14px",
    marginBottom: "0.721392vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      // margin: "0 -16px 18px",
      margin: "0 0 18px",
      maxHeight: "70vw",
      // maxHeight: "50vh",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "52.4419vw",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "655px",
    },
  },
  scorediv: {
    justifyContent: "spaceBetween",
    width: "100%",
  },
  heading: {
    padding: "10px",
    fontSize: "1.1rem",
    fontWeight: "700",
    color: "#0c6885",
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#07303E",
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "12px 0",
      fontWeight: "500",
      fontSize: "16px",
      color: "#186985",
    },
  },
  scoreTitle: {
    padding: "0",
    fontSize: "22px",
    fontWeight: "500",
    color: "#07303E",
  },
  scoredivv: {
    flex: "right",
    justifyContent: "spaceBetween",
    width: "100%",
  },
  viewicon: {
    position: "absolute",
  },
  iconsize: {
    fontSize: "50px",
    fill: "primary",
  },
  rep: {
    fontSize: "25px",
    display: "flex",
    justifyContent: "center",
  },
  arrow: {
    height: "20px",
    dropdownOpen: {
      transform: "rotate(-180deg)",
    },
    dropdownClosed: {
      transform: "rotate(0)",
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    margin: "50px 36px 50px 23px",
    padding: "12px 4.444vw",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 80px",
      padding: "0 16px",
      borderRadius: 0,
    },
  },
  table: {
    width: "100%",
    fontSize: "1rem",
  },
  firstitem: {
    borderRight: "1px dashed black",
    padding: "5px 0 5px 20px",
    fontWeight: "700",
  },
  seconditem: {
    paddingLeft: "20px",
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      margin: "16px 0 16px",
      color: "#186985",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "22px",
      color: "#186985",
      padding: "0",
      margin: "34px 0",
    },
  },
  card: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
  maingrid: {
    justifyContent: "spaceAround",
    width: "100%",
  },
  buttons: {
    fontSize: "50px",
  },
  paper: {
    margin: "0px 0 16px",
    fontSize: "30px",
    padding: "0",
  },
  textOverlay: {
    display: "inline",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
  },
  rotate: {
    transform: "rotate(90deg)",
    width: "100%",
    height: "100%",
  },
  fullland: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    width: "auto",
  },
  fullport: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(90deg)",
    height: "auto",
    width: "150%",
    transition: "none;",
  },
  grid: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    height: "100%",
    transition: "none;",
  },
  portexit: {
    position: "fixed",
    top: "95%",
    left: "95%",
    transform: "translate(-50%, -50%) rotate(90deg)",
  },
  landexit: {
    position: "fixed",
    top: "5%",
    left: "95%",
    transform: "translate(-50%, -50%)",
  },
  portdiv: {
    transform: "translate(-50%,-50%) rotate(90deg)",
    transformOrigin: "50% 50%",
  },
  Link: {
    fontSize: "18px",
    cursor: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  detailItem: {
    marginTop: "0",
    marginBottom: "0",
  },
  detailName: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#07303E",
    padding: "16px 16px 16px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px 16px",
    },
  },
  detail: {
    paddingLeft: "15%",
    fontSize: "14px",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "14px",
      fontSize: "12px",
    },
  },
  expandedExpansionPanelSummary: {
    margin: 0,
  },
  featureLI: {
    textAlign: "left",
    display: "inline-block",
    listStyle: "none",
    padding: 0,
    position: "relative",
    margin: "12px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "7px 0",
    },

    "&::before": {
      // before has :: instead of : as hover
      color: "#C4C4C4",
      content: '"\\2022"', // content must be double quoted to work
      /* Unicode of character to precede the list item */
      display: "inline-block",
      fontSize: "2em",
      left: "-13px",
      position: "absolute",
      top: "0em",
      margin: "-10px 0",
    },
  },
  previosIcon: {
    position: "absolute",
    left: 0,
    top: "50%",
    top: "24.221vw",
    transform: "translate(0,-50%)",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      top: "277.5px",
    },
  },
  nextIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    top: "24.221vw",
    transform: "translate(0,-50%)",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      top: "277.5px",
    },
  },
  previosIconInDialog: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(0,-50%)",
    zIndex: 1,
  },
  nextIconInDialog: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(0,-50%)",
    zIndex: 1,
  },
  imageStepCount: {
    top: "calc(100% - 12px)",
    zIndex: "1",
    position: "absolute",
    left: "19px",
    borderRadius: "5px",
    transform: "translate(0,calc(-100% - 12px))",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    [theme.breakpoints.up("lg")]: {
      // top: "555px",
    },
  },
  imagePreviousButton: {
    padding: 0,
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    borderRadius: 0,
    minWidth: "0px",
    width: "3.5vw",
    height: "6vw",
  },
  imageNextButton: {
    padding: 0,
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0.7) -52.5%, rgba(0, 0, 0, 0) 138.33%)",
    borderRadius: 0,
    minWidth: "0px",
    width: "3.5vw",
    height: "6vw",
  },
  vehicleDetailsExpansionPanelDetails: {
    display: "block",
    padding: "0 0 0",
    display: "grid",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 -16px",
      border: "none",
    },
  },
  cardContentDiv: {
    display: "flex",
    justifyContent: "space-around",
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0 20px",
      justifyContent: "space-between",
    },
  },
  iconDiv: {
    display: "inline-grid",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  iconTypography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      height: "max-content",
      margin: "auto",
      marginLeft: "5px",
    },
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  secondIconTypography: {
    fontSize: "14px",
    marginLeft: "auto",
  },
  secondIconDiv: {
    justifySelf: "center",
    marginRight: "13px",
  },
  firstHr: {
    height: "1px",
    margin: "0 -16px 30px",
    width: "calc(100% + 32px)",
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px 16px",
    },
  },
  priceTypography: {
    fontSize: "24px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    color: "#F15A29",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  priceNegotiableDiv: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginTop: "22px",
      justifyContent: "space-between",
    },
  },
  negotiableFlagMainDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  //////////////////////////////////
  mainDetailAppointmentDiv: {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  mainDetailDiv: {
    width: "100%",
    maxWidth: "725px",
    margin: "45px 25px 0 auto",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      minWidth: "100%",
      flexGrow: 0,
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "63.28vw",
      flexGrow: 0,
      margin: "45px 21px 0",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "725px",
      minWidth: "725px",
      flexGrow: 0,
      margin: "45px auto 0 auto",
    },
    [theme.breakpoints.up("xl")]: {},
  },
  mainDetailDiv2: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  mainInspectionDiv: {
    margin: "41.13px auto 45px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 0",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 42px)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1040px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1165px",
    },
  },
  negotiableFlagSecondDiv: {
    borderRadius: "50%",
    border: "2px solid #07303E",
    height: "36px",
    width: "36px",
    marginRight: "-26px",
    position: "relative",
    backgroundColor: "white",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      width: "28px",
    },
  },
  negotiableFlagTextDiv: {
    borderRadius: "100rem",
    backgroundColor: "#07303E",
    height: "28.57px",
    paddingLeft: "26px",
    [theme.breakpoints.down("sm")]: {
      height: "20px",
    },
  },
  negotiableFlagTextTypography: {
    margin: "4px 18.5px 2px 11.5px",
    width: "max-content",
    color: "white",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      margin: "3px 12px 2px 12.2px",
    },
  },
  carid: {
    fontFamily: "poppins",
    background: "#F3F3F3",
    padding: "3px 10px",
    color: "#636363",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 7px",
      height: "fit-content",
      background: "#F3F3F3",
      fontFamily: "poppins",
      fontSize: "10px",
      margin: "auto 0",
      whiteSpace: "nowrap",
    },
  },
  installmentsFirstTypography: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  installmentsSecondTypography: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#07303E",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  priceDiv: {
    // marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  fourthHr: {
    height: "1px",
    // margin: "32px -20px 10px",
    // margin: "32px 0 10px",
    margin: "0 -16px 30px", ////new
    width: "calc(100% + 32px)", ////new
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      // margin: "12px -20px 10px",
      margin: "12px 0 10px", ////////
      margin: "0 -16px 30px", ////new
      width: "calc(100% + 32px)", ////new
    },
  },
  listItem: {
    height: "100%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  fifthHr: {
    height: "1px",
    // margin: "28px -20px 30px",
    // margin: "28px 0 30px",
    margin: "0 -16px 30px", ////new
    width: "calc(100% + 32px)", ////new
    border: "none",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
    [theme.breakpoints.down("sm")]: {
      // margin: "10px -20px 0",
      // margin: "10px 0 0",
      margin: "0 -16px 30px", ////new
      width: "calc(100% + 32px)", ////new
    },
  },
  detailNameGrid: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      // padding: "5px 0 5px 0px",
    },
  },
  iconComponent: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "12px",
    },
  },
  webFeaturesHr: {
    height: "1px",
    border: "none",
    marginTop: "1px",
    position: "absolute",
    right: 0,
    top: "100%",

    width: "300%",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  mobFeaturesHr: {
    height: "1px",
    border: "none",
    marginTop: "1px",
    position: "absolute",
    right: 0,
    top: "100%",
    width: "200%",
    backgroundImage:
      "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
  },
  maximizeDiv: {
    position: "absolute",
    transform: "translate(50%,-50%)",
    borderRadius: "50%",
    backgroundColor: "rgba(83, 83, 83, 0.5)",
    padding: "5px",
    right: "calc(5% - 2px)",
    top: "calc(5% + 14px)",
  },
  imagePopperCloseIcon: {
    color: "white",
    float: "right",
    margin: "1% 10%",
    cursor: "pointer",
  },
  arrowAnimation: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeOut}`,
  },
  arrowAnimationExit: {
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes myEffect": {
    "0%": {
      fontSize: "6vw",
    },
    "50%": {
      fontSize: "4vw",
    },
    "100%": {
      fontSize: "6vw",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      fontSize: "6vw",
    },
    "50%": {
      fontSize: "4vw",
    },
    "100%": {
      fontSize: "6vw",
    },
  },
  carImage: {
    height: "100",
    // objectFit: "cover",
    margin: "auto",
    filter: "brightness(0.5) !important",
  },
  carImage2: {
    height: "100",
    // objectFit: "cover",
    margin: "auto",
    filter: "brightness(1) !important",
  },
  carImage3: {
    height: 100,
    // objectFit: "cover",
    width: "95.8%",
    filter: "brightness(0.5) !important",
    "&:hover": {
      cursor: "pointer",
    },
  },

  carImage4: {
    height: 100,
    // objectFit: "cover",
    width: "95.8%",
    filter: "brightness(1) !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  mobileGallaryImage: {
    position: "absolute",
    top: "100%",
    zIndex: "1",
    transform: "translate(0%, -100%)",
    left: "-5%",
  },
  mobilegallarydiv: {
    background: "rgba(0, 0, 0, 0.3)",
    // paddingLeft: "12px",
    padding: "3px 4px", ////new
    borderRadius: "4px",
    display: "flex",
  },
  carImagesLengthTypography: {
    color: "white",
    // padding: "8px 12px",
    padding: "0 0 0 6px",
    zIndex: "10",
    fontSize: "12px",
  },
  swipeSlider: {
    height: "70vw",
    width: "calc(100% + 32px)",
    margin: "0 -16px",
    transitionDuration: "1s",
    transitionTimingFunction: "ease-in-out",
  },
  CarDetailsTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#07303E",
    marginTop: "auto", ////new
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#07303E",
      marginTop: "0", ////new
    },
  },
  headerWrap: {
    margin: "13px 0",
    [theme.breakpoints.up("md")]: {
      margin: "34px 0",
    },
  },
  Divider: {
    height: 1,
    backgroundColor: "#D2D2D2",
    marginLeft: "24px",
    "flex-shrink": 0,
  },
  featureHideDiv: {
    height: "180px",
    overflow: "hidden",
    position: "relative",
    transition: "height 4000ms ease",
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      position: "relative",
      transition: theme.transitions.create(["height"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
      }),
      height: "270px",
      transform: "height",
    },
  },
  listItemIconRoot: {
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    padding: "9px",
  },
  listItemTextRoot: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "500",
      color: "#07303E",
    },
  },
  mediaSkeleton: {
    width: "100%",
    height: "555px",
    transform: "none",
    marginBottom: "18px",
    [theme.breakpoints.up("md")]: {
      height: "48.4419vw",
    },
    [theme.breakpoints.up("lg")]: {
      height: "555px",
    },
  },
  sliderSkeleton: {
    width: "100%",
    height: "100px",
    transform: "none",
  },
  sliderImageSkeleton: {
    width: "94.3%",
    height: "100px",
    transform: "none",
  },
  iconTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      width: "45px",
      margin: "auto 0 auto 5px",
    },
    width: "90px",
    height: "24px",
    marginLeft: "0px",
  },
  priceTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      height: "27px",
      width: "85px",
    },
    width: "115px",
    height: "36px",
  },
  installmentsTypographySkeleton: {
    [theme.breakpoints.down("sm")]: {
      width: "240px",
      height: "21px",
    },
    width: "365px",
    height: "30px",
  },
  vehicleInformationSkeleton: {
    width: "100%",
    transform: "none",
    marginBottom: "29px",
    [theme.breakpoints.down("sm")]: {
      height: "28px",
    },
    height: "29px",
  },
  mobileNameSkeleton: {
    width: "55%",
    height: "27px",
    marginTop: "28px",
  },
  webNameSkeleton: {
    width: "35%",
    height: "36px",
  },
  webLocationSkeleton: {
    width: "25%",
    height: "24px",
    marginBottom: "14px",
  },
  mobSecondIconSkeleton: {
    width: "80px",
    height: "21px",
    marginLeft: "auto",
  },
  showMoreAndLess: {
    padding: "10px 32px",
    textTransform: "none",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "10px 32px",
      textTransform: "none",
      justifyContent: "flex-end",
      alignContent: "center",
    },
  },
  showMoreAndLessText: {
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    color: "#00BDFF",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CarDetails = (props) => {
  const classes = useStyles();
  const {
    getCar,
    getCarDetails,
    getCarGroups,
    hideAppBar,
    showAppBar,
    history,
    carList,
    carScore,
    enteredBy,
    date,
    role,
    mainRef,
    checkVisFormsPoints,
    toggleNotification,
    DTCFaults,
    reportBuilder,
  } = props;

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumDown = useMediaQuery(theme.breakpoints.down("md"));

  const maxSteps = carList.length;
  const carId = props.match.params.id;
  let carIds = [];
  carList.map((item) => {
    carIds.push(item.id);
  });
  const cars = {};
  for (var i = 0; i <= carList.length - 1; i++) {
    cars[carList[i].id] = carList[i];
  }
  const [activeStep, setActiveStep] = React.useState(
    carIds.indexOf(parseInt(carId))
  );
  const dateObject = new Date(date);

  const [carData, setCarData] = useState({});
  const [reportGroups, setReportGroups] = useState({});

  const [loadingCar, setLoadingCar] = useState(true);
  const [positionImages, setPositionImages] = useState([]);
  const [switchState, setSwitchState] = useState(true); //switchState will later be used to render interior or exterior images
  const [positionActiveImage, setPositionActiveImage] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [visualisationPath, setVisualizationPath] = useState(""); // a state containing the path that the link on the visualization icon directs to small screen: to cosmetic condition page, big screen: do not redirect
  const [isSmallDevice, setIsSmallDevice] = useState(true); //a stste describing the size of the screen true:mobile or tablet, false:big tablet or pc
  const [imagePath, setImagePath] = useState(""); // a state containing the path that the link on the image directs to small screen: to image view page, big screen: do not redirect
  const [openDialog, setOpenDialog] = useState(false); // a state to open and close the bigger image view on Big screens
  // const [openVisualization, setOpenVisualization] = useState(false); // a state to open and close the bigger image view on Big screens
  const [nextClicked, setNextClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [backClicked, setBackClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [extLength, setExtLength] = useState(0);
  // const [imagePos, setImagePos] = useState(0);
  const backgroundRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const slickRef = useRef();
  const innerSlickRef = useRef();
  const inspectionReportRef = useRef(); // reference to the div node that contains the bigger image and stepper in the Big screen, used for exiting the "dialog" bach to the normal vehicle detail page
  const vehicleFeaturesRef = useRef();
  const [openFeature, setOpenFeature] = useState(false);
  const { t, i18n } = useTranslation();
  const [carInfo, setCarInfo] = useState(null);
  const mainDivRef = useRef();
  const [hideCTA, setHideCTA] = useState(false);

  //////////////////////////////////////////////////////////////////////////////
  const cardMediaRef = useRef();
  const [cardMediaLoaded, setCardMediaLoaded] = useState(false);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [swipeSliderLoaded, setSwipeSliderLoaded] = useState(false);
  // const [carDataLoaded, setCarDataLoaded] = useState(false);
  const swipeSliderRef = useRef();

  const [numOfPoints, setNumOfPoints] = useState(0);

  const handleCallback = (childData) => {
    setNumOfPoints(childData);
  };

  useEffect(() => {
    if (
      cardMediaRef?.current?.complete && // problem was that carImages.length !== 0 ? carImages?.[activeImage]
      cardMediaRef?.current?.src?.includes("http")
    ) {
      setCardMediaLoaded(true);
    }
  }, [cardMediaRef.current?.complete, cardMediaRef?.current?.src]);

  useEffect(() => {
    if (slickRef?.current) {
      setSliderLoaded(true);
    }
  }, [slickRef?.current, cardMediaLoaded]);

  useEffect(() => {
    if (swipeSliderRef?.current) {
      setSwipeSliderLoaded(true);
    }
  }, [swipeSliderRef?.current, carData]);

  const fieldTypes = {
    for_details: 1,
  };

  const handlePreviousImage = () => {
    activeImage >= 1
      ? setActiveImage((prevActiveStep) => prevActiveStep - 1)
      : setActiveImage(() => carImages.length - 1);
    setBackClicked(!backClicked);
  };

  const handleNextImage = () => {
    activeImage < carImages.length - 1
      ? setActiveImage((prevActiveStep) => prevActiveStep + 1)
      : setActiveImage(() => 0);
    setNextClicked(!nextClicked);
  };

  useEffect(() => {
    // if (slickRef?.current) {
    //   slickRef.current.slickGoTo(activeImage);
    // }
    if (innerSlickRef?.current) {
      innerSlickRef.current.slickGoTo(activeImage);
    }
    if (swipeSliderRef?.current) {
      swipeSliderRef.current.slickGoTo(activeImage);
    }
  }, [activeImage, openDialog]);

  const handleSwitchChange = (event) => {
    // swich change function the value of switchState will later be used to render interior or exterior images
    setSwitchState(event.target.checked);
    setActiveImage(event.target.checked ? 0 : positionImages.length);
    setPositionActiveImage(0);
  };

  let query = "";
  for (let key in fieldTypes) {
    if (fieldTypes.hasOwnProperty(key)) {
      query = query + key + "=" + fieldTypes[key] + "&";
    }
  }

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
    setLoadingCar(true);
    getCar({ id: carId });
    // DTCFaults({
    //   carId: carId,
    //   pdf: "https://markabte-static.s3.eu-central-1.amazonaws.com/DTC_Reports/2019+Toyota+Camry+Firas+.pdf",
    //   callback: (response) => {
    //     response.data.faults.map((row) => {
    //       console.log("aaaaaaaaaa", row);
    //     });

    //   },
    // });
    getCarDetails({
      carId: carId,
      callback: (details) => {
        getCarGroups({
          carId: carId,
          query: query,
          callback: (groups) => {
            details["groups"] = groups;
            setCarData(details); // now carData has images along with other car related data obtained from /api/car/page/id,
            //the object has an images key that contains an array of two objects where the first object has an images key that contain the featured image,
            //and the second object also has an images key that contains the rest of the images
            setCarInfo(details);
            getCarGroups({
              carId: carId,
              query: "for_details_report=1&",
              callback: (groups) => {
                let details = {};
                details["groups"] = groups;
                setReportGroups(details);
                setLoadingCar(false);
              },
            });
          },
        });
      },
    });
  }, [carId, getCarDetails, query, getCarGroups]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
    // here the category of the size of the screen is set (isSmallDevice) and the path (imagePath) is set accordingly to the category
    const isTabletOrMobileWidth = window.matchMedia(
      "(max-device-width: 959px)"
    );
    const isTabletOrMobileHeight = window.matchMedia(
      "(max-device-height: 959px)"
    );
    if (isTabletOrMobileHeight.matches && isTabletOrMobileWidth.matches) {
      setIsSmallDevice(true);
      setImagePath("/imageview");

      setVisualizationPath("/visualization");
    } else {
      setIsSmallDevice(false);
      setImagePath("");
      setVisualizationPath("");
    }
  }, []);

  const handleImgClick = (event) => {
    // image's onClick function requests full screen and orientation lock if available in small screen and sets openDialog if big screen
    //not sure if necessary still doesnot fullscreen on safari and the rest did without extra vendor prefix
    //but condition is neccissary
    if (isSmallDevice) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen().catch((e) => console.log(e));
      } else if (document.body.mozRequestFullscreen) {
        document.body.mozRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen().catch((e) => console.log(e));
      }
      if (window.screen.orientation) {
        window.screen.orientation
          .lock("landscape-primary")
          .catch((e) => console.log(e));
      }
      hideAppBar(); // hides app bar in small screen visualization so that the speed dial button 2021 Markabte. All Rights Reserved.'s transparent black div get positioned right
    } else {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    // adding style to body element to stop scrolling when image or visualisation is open and to return scrolling when closed
    openDialog
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [openDialog]);

  useEffect(() => {
    // to close big screen's dialog if anything but the image or the stepper was clicked (containing div) or if Esc was pressed
    const handleModalClose = (event) => {
      if (backgroundRef.current === event.target) {
        setOpenDialog(false);
      }
    };
    document.addEventListener("mousedown", handleModalClose);
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setOpenDialog(false);
      }
    });

    return (_) => {
      // clean up
      document.removeEventListener("mousedown", handleModalClose);
      window.removeEventListener("keyup", (e) => {
        if (e.key === "Escape") {
          setOpenDialog(false);
        }
      });
    };
  }, [backgroundRef]);

  const getCarImages = (images) => {
    let carImages = [];
    for (let i = 0; i < images.length; i++) {
      carImages = [...carImages, ...images[i].answer.images];
    }
    return carImages;
  };

  const getUniqueFeatures = (features) => {
    let featuresArray = [];
    for (let i = 0; i < features.length; i++) {
      featuresArray.push(features[i].form_object);
    }
    return getUniqueArray(featuresArray, "main_feature_title");
  };

  var carCard = carData.card; // could've been named "card" and declared in the line above but had to name it carCard to avoid confusion upon searching
  if (carData.groups !== undefined) {
    var { images, groups, generic_features_names } = carData;
    var features = {};
    generic_features_names
      .map((feat) =>
        feat.field_tag.id == 16
          ? (features[feat.field_title] = feat.selected_option)
          : (features[feat.option_title] = feat.selected_option)
      )
      .sort((a, b) => a.length - b.length);
    var featureNames = generic_features_names
      .map((feat) =>
        feat.field_tag.id == 16 ? feat.field_title : feat.option_title
      )
      .sort((a, b) => a.length - b.length);
    var compressFeatureList = featureNames.length > 12;
  }
  const getFeatureId = (feature) => {
    try {
      return Object.fromEntries(
        Object.entries(features).filter(([key, value]) => key === feature)
      )[feature];
    } catch {
      return null;
    }
  };
  // const milageUnit = t((carCard["unit_of_measurement"] === "Kilometer" ? "Km" : "Mile") ||"Km");
  // const milageUnit = t("Km");

  const destructuredGroups = [];
  const groupsDataDestructure = () => {
    if (carData.groups !== undefined) {
      groups.groups.map((item, i) => {
        let sub_groups = item.sub_groups[0];
        sub_groups.fields.map((item) => {
          if (item.option_title !== "Yes") {
            // to remove insurance and installments if answer is yes
            destructuredGroups.push(item);
          }
        });
      });
    }
  };
  groupsDataDestructure();

  let carImages = getCarImages(images || []);

  let exteriorImages = [];
  let interiorImages = [];
  useEffect(() => {
    if (images) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].position === "ext") {
          exteriorImages = [...exteriorImages, ...images[i].answer.images];
        } else {
          interiorImages = [...interiorImages, ...images[i].answer.images];
        }
      }
    }
    if (activeImage < exteriorImages.length || exteriorImages.length == 0) {
      setPositionImages(exteriorImages);
      setPositionActiveImage(activeImage);
      setSwitchState(true);
      setExtLength(exteriorImages.length);
    } else {
      setPositionImages(interiorImages);
      setPositionActiveImage(activeImage - exteriorImages.length);
      setSwitchState(false);
    }
  }, [openDialog, activeImage, images]);

  const handleScrollClick = () => {
    window.scrollTo({
      left: 0,
      top: inspectionReportRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (mainRef.current) {
        if (
          window.innerHeight + window.scrollY >=
          mainRef.current.scrollHeight
        ) {
          setHideCTA(true);
        } else {
          setHideCTA(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log("ppppppppppppwww", slickRef?.current);
  });

  const renderDialog = (carImages, activeImage) => {
    const handleOpenBigScreenVisualization = () => {
      // setOpenVisualization(false);
    };

    const fullScreenSettings = {
      //number of images
      // props for the image stepper from react-slick
      arrows: false, // include arrows
      dots: true, // include dots
      //true became carImages.length > 5 to solve duplication in case of images less than slidesToShow setting below,
      infinite: true, // to keep scrolling after last and firs images back to first and last image respectivly
      speed: 750, // scroll speed
      slidesToShow: 5, // No. of images shown in the slider
      slidesToScroll: 1, // number of images to scroll when the arrow is clicked
      // initialSlide: switchState ? 0 : extLength, // initial image (vehicle featured image)
    };

    const handleSwitchChange = (event) => {
      // swich change function the value of switchState will later be used to render interior or exterior images
      setSwitchState(event.target.checked);
      setActiveImage(event.target.checked ? 0 : positionImages.length);
      setPositionActiveImage(0);
    };
    return (
      // (openDialog || openVisualization) && (
      openDialog && (
        <div
          ref={backgroundRef}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.95)",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: "2000",
          }}
        >
          <div>
            <div
              style={{
                height: "70%",
                position: "fixed",
                top: "40%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                // width: "843px",
                // height: "450px",
                height: "68.71vh",
                width: "91.61vh",
              }}
            >
              {console.log("rrrrrrrrrrrr", carImages, activeImage)}
              <img
                src={carImages[activeImage]}
                alt={"car-image-" + activeImage}
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "2000",
                  // width: "843px",
                  // height: "481px",
                  height: "68.71vh",
                  // objectFit: "cover",
                }}
              />
              <PositionSwitch
                setPositionExternally={switchState}
                onChange={handleSwitchChange}
                style={{
                  position: "fixed",
                  top: "10%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "2000",
                }}
              />
              <Grid
                style={{ zIndex: "2222" }}
                item
                className={classes.previosIconInDialog}
              >
                <Button
                  className={classes.imagePreviousButton}
                  onClick={
                    theme.direction === "rtl"
                      ? handleNextImage
                      : handlePreviousImage
                  }
                >
                  {theme.direction === "ltr" ? (
                    <KeyboardArrowLeft
                      className={clsx(classes.arrowAnimation, {
                        [classes.arrowAnimationExit]: nextClicked,
                      })}
                      style={{ fill: "white", fontSize: "6vw" }}
                    />
                  ) : (
                    <KeyboardArrowRight
                      className={clsx(classes.arrowAnimation, {
                        [classes.arrowAnimationExit]: backClicked,
                      })}
                      style={{ fill: "white", fontSize: "6vw" }}
                    />
                  )}
                </Button>
              </Grid>
              <Grid
                style={{ zIndex: "2222" }}
                item
                className={classes.nextIconInDialog}
              >
                <Button
                  className={classes.imageNextButton}
                  onClick={
                    theme.direction === "rtl"
                      ? handlePreviousImage
                      : handleNextImage
                  }
                >
                  {theme.direction === "ltr" ? (
                    <KeyboardArrowRight
                      className={clsx(classes.arrowAnimation, {
                        [classes.arrowAnimationExit]: backClicked,
                      })}
                      style={{ fill: "white", fontSize: "6vw" }}
                    />
                  ) : (
                    <KeyboardArrowLeft
                      className={clsx(classes.arrowAnimation, {
                        [classes.arrowAnimationExit]: nextClicked,
                      })}
                      style={{ fill: "white", fontSize: "6vw" }}
                    />
                  )}
                </Button>
              </Grid>
            </div>
            <CloseIcon
              className={classes.imagePopperCloseIcon}
              // style={{ color: "white", float: "right", margin: "1% 10%" }}
              onClick={() => {
                setOpenDialog(false);
                // setOpenVisualization(false);
              }}
            ></CloseIcon>
            <Paper
              style={{
                marginBottom: "20px",
                position: "fixed",
                top: "88%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "2000",
                // width: "60%",
                backgroundColor: "rgba(0,0,0,0)",
                width: "91.61vw",
              }}
            >
              <Slider
                {...fullScreenSettings}
                ref={innerSlickRef}
                rtl={localStorage.getItem("language") === "en" ? null : true}
              >
                {carImages.map((image, i) => {
                  return (
                    <div key={"car-image-container" + i}>
                      <img
                        // width={"80%"}
                        // style={{ height: "15vh" }}
                        style={{
                          width: "calc(18.322vw - 5px)",
                          height: "calc(13.7415vw - 3.75px)",
                        }}
                        src={image}
                        alt={"car-image-" + i}
                        className={
                          i != activeImage
                            ? classes.carImage
                            : classes.carImage2
                        }
                        onClick={() => setActiveImage(i)}
                      />
                    </div>
                  );
                })}
              </Slider>
            </Paper>
          </div>
        </div>
      )
    );
  };

  const renderFirstTab = (activeStep) => {
    const fullScreenSettings2 = {
      /// outside slider (5)
      // props for the image stepper from react-slick
      lazyLoad: true,
      arrows: true, // include arrows
      infinite: true, // to keep scrolling after last and firs images back to first and last image respectivly
      speed: 750, // scroll speed
      slidesToShow: 5, // No. of images shown in the slider
      slidesToScroll: 1, // number of images to scroll when the arrow is clicked
      adaptiveHeight: true, //
      focusOnSelect: true,
    };
    const fullScreenSettings3 = {
      // props for the image stepper from react-slick
      arrows: false, // include arrows
      infinite: switchState
        ? exteriorImages.length > 1
        : interiorImages.length > 1, // to keep scrolling after last and firs images back to first and last image respectivly
      speed: 450, // scroll speed
      slidesToShow: 1, // No. of images shown in the slider
      slidesToScroll: 1, // number of images to scroll when the arrow is clicked
      initialSlide: 0, // initial image (vehicle featured image)
    };
    return (
      <div
        ref={mainDivRef}
        style={{
          backgroundColor: "white",
        }}
      >
        {renderDialog(carImages, activeImage)}
        <div className={classes.mainDetailDiv2}>
          <div className={classes.mainDetailAppointmentDiv}>
            <div className={classes.mainDetailDiv}>
              <Hidden smDown>
                {carCard?.year ? (
                  <div style={{ display: "flex" }}>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#07303E",
                      }}
                    >
                      {`${carCard?.year}`}
                    </Typography>
                    {"\u00A0"}
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#07303E",
                      }}
                    >
                      {`${carCard?.make}`}
                    </Typography>
                    {"\u00A0"}
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#07303E",
                      }}
                    >
                      {`${carCard?.model}`}
                    </Typography>
                    {"\u00A0"}
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#07303E",
                      }}
                    >
                      {`${carCard?.trim}`}
                    </Typography>
                  </div>
                ) : (
                  <Skeleton className={classes.webNameSkeleton} />
                )}
                {carCard?.year ? (
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#186985",
                      display: "flex",
                      marginBottom: "14px",
                    }}
                  >
                    <GPS
                      style={{ alignSelf: "center" }}
                      size="17.94"
                      fill="#186985"
                    />
                    {`${t(carCard?.city)}, ${carCard?.area}`}
                  </Typography>
                ) : (
                  <Skeleton className={classes.webLocationSkeleton} />
                )}
              </Hidden>
              {/* ////////////////////////////////////////////// */}
              <Grid container style={{ position: "relative" }}>
                <Hidden smDown>
                  <Grid item className={classes.previosIcon}>
                    <Button
                      className={classes.imagePreviousButton}
                      onClick={
                        theme.direction === "ltr"
                          ? handlePreviousImage
                          : handleNextImage
                      }
                    >
                      {theme.direction === "ltr" ? (
                        <KeyboardArrowLeft
                          className={clsx(classes.arrowAnimation, {
                            [classes.arrowAnimationExit]: nextClicked,
                          })}
                          style={{ fill: "white", fontSize: "6vw" }}
                        />
                      ) : (
                        <KeyboardArrowRight
                          className={clsx(classes.arrowAnimation, {
                            [classes.arrowAnimationExit]: backClicked,
                          })}
                          style={{ fill: "white", fontSize: "6vw" }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item className={classes.nextIcon}>
                    <Button
                      className={classes.imageNextButton}
                      onClick={
                        theme.direction === "ltr"
                          ? handleNextImage
                          : handlePreviousImage
                      }
                    >
                      {theme.direction === "ltr" ? (
                        <KeyboardArrowRight
                          className={clsx(classes.arrowAnimation, {
                            [classes.arrowAnimationExit]: backClicked,
                          })}
                          style={{ fill: "white", fontSize: "6vw" }}
                        />
                      ) : (
                        <KeyboardArrowLeft
                          className={clsx(classes.arrowAnimation, {
                            [classes.arrowAnimationExit]: nextClicked,
                          })}
                          style={{ fill: "white", fontSize: "6vw" }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item className={classes.mobileGallaryImage}>
                    <div className={classes.mobilegallarydiv}>
                      {/* <img
                        src={gallary}
                        style={{
                          width: "15px",
                        }}
                      ></img> */}
                      <Typography className={classes.carImagesLengthTypography}>
                        {switchState
                          ? positionActiveImage + 1
                          : positionActiveImage + extLength + 1}
                        / {carImages.length}
                      </Typography>
                    </div>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid
                    item
                    xs={12}
                    style={{ position: "relative", display: "flex" }}
                  >
                    {!swipeSliderLoaded && (
                      <Skeleton
                        variant="rect"
                        style={{
                          transform: "none",
                          zIndex: 1,
                          position: "absolute",
                        }}
                        className={classes.swipeSlider}
                      />
                    )}
                    <Slider
                      rtl={
                        localStorage.getItem("language") === "en" ? null : true
                      }
                      // afterChange={(index) => setPositionActiveImage(index)}
                      afterChange={(index) => setActiveImage(index)}
                      ref={swipeSliderRef}
                      {...fullScreenSettings3}
                      initialSlide={
                        localStorage.getItem("language") === "en" ? 0 : -1
                      }
                      // dds
                      className={classes.swipeSlider}
                      // style={{
                      //   height: "70vw",
                      //   width: "calc(100% + 32px)",
                      //   margin: "0 -16px",
                      // }}
                    >
                      {carImages.map((image, i) => {
                        return (
                          <div key={"car-image-container" + i}>
                            <Link
                              to={{
                                pathname: imagePath,
                                images: carImages,
                                imageObj: images,
                                carId: carId,
                                imageId: i,
                              }}
                            >
                              <img
                                className={"test"}
                                style={{
                                  width: "100%",
                                  // objectFit: "cover",
                                  height: "70vw",
                                }}
                                src={image}
                                onClick={handleImgClick}
                              />
                            </Link>
                          </div>
                        );
                      })}
                    </Slider>
                    {swipeSliderLoaded ? (
                      <PositionSwitch
                        setPositionExternally={switchState}
                        onChange={handleSwitchChange}
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                          transform: "translate(-50%,20%)",
                          left: "50%",
                          position: "absolute",
                        }}
                        // className={switchclasspick}
                      />
                    ) : (
                      ""
                    )}
                    {/* <ImageActions /> */}
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item xs={12} className={classes.mediaGrid}>
                    <img
                      ref={cardMediaRef}
                      style={{
                        // objectFit: "cover",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      src={carImages[activeImage]}
                      className={classes.media}
                      onClick={handleImgClick}
                    />
                    <PositionSwitch
                      setPositionExternally={switchState}
                      onChange={handleSwitchChange}
                      // outer position switch
                      style={{
                        position: "absolute",
                        top: "23px",
                        left: "35%",
                      }}
                    />
                    <Grid item className={classes.imageStepCount}>
                      <div>
                        <Typography
                          style={{
                            color: "white",
                            padding: "8px 12px",
                            zIndex: "10",
                          }}
                        >
                          {activeImage + 1} {t("of")} {carImages.length}
                        </Typography>
                      </div>
                    </Grid>
                    {!cardMediaLoaded && (
                      // true
                      <div
                        style={{
                          width: "100%",
                          marginBottom: "1px",
                          position: "absolute",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "white",
                        }}
                      >
                        <Skeleton className={classes.mediaSkeleton} />
                      </div>
                    )}
                    {/* <ImageActions /> */}
                  </Grid>
                </Hidden>
                <Paper
                  style={{
                    width: "100%",
                    boxShadow: "none",
                    zIndex: 2,
                    position: "relative",
                  }}
                >
                  <Hidden smDown>
                    {carImages.length === 0 && (
                      // still problem unmounts before sliderImageSkeleton mounts
                      <Skeleton className={classes.sliderSkeleton} />
                    )}
                    <Slider
                      {...fullScreenSettings2}
                      initialSlide={
                        localStorage.getItem("language") === "en" ? 0 : -1
                      }
                      rtl={
                        localStorage.getItem("language") === "en" ? null : true
                      }
                      ref={slickRef}
                      style={{ marginRight: "-1px" }}
                      beforeChange={(oldIndex, newIndex) => {
                        if (newIndex > 0) {
                          setActiveImage(newIndex);
                        }
                      }}
                      onReInit={() => {
                        slickRef.current.slickGoTo(activeImage);
                      }}
                    >
                      {carImages.map((image, i) => {
                        // add exterior and interior
                        return (
                          <div key={"car-image-container" + i}>
                            <img
                              width={"80%"}
                              src={image}
                              // alt={"car-image-" + i}
                              className={
                                i != activeImage
                                  ? classes.carImage3
                                  : classes.carImage4
                              }
                              onClick={() => setActiveImage(i)}
                            />
                            {console.log("sssssss22", !sliderLoaded)}
                            {
                              // true
                              !sliderLoaded && (
                                <div
                                  style={{
                                    position: "absolute",
                                    // width: `calc(100% / ${carImages.length * 2})`,
                                    // width: "3%", //## check
                                    width: "145px", //## check
                                    top: 0,
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Skeleton
                                    className={classes.sliderImageSkeleton}
                                  />
                                </div>
                              )
                            }
                          </div>
                        );
                      })}
                    </Slider>
                  </Hidden>
                </Paper>
              </Grid>
              <Hidden mdUp>
                {carCard?.year ? (
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography classes={{ root: classes.CarDetailsTitle }}>
                        {`${carCard?.year}`}
                      </Typography>
                      {"\u00A0"}
                      <Typography classes={{ root: classes.CarDetailsTitle }}>
                        {`${carCard?.make}`}
                      </Typography>
                      {"\u00A0"}
                      <Typography classes={{ root: classes.CarDetailsTitle }}>
                        {`${carCard?.model}`}
                      </Typography>
                      {"\u00A0"}
                      <Typography classes={{ root: classes.CarDetailsTitle }}>
                        {`${carCard?.trim}`}
                      </Typography>
                    </div>
                    <div className={classes.carid}>{`ID #${carId}`}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    <Skeleton className={classes.mobileNameSkeleton} />
                    <Skeleton
                      className={classes.mobileNameSkeleton}
                      style={{ width: "70px" }}
                    />
                  </div>
                )}
              </Hidden>
              <Hidden smDown>
                <Divider
                  component="div"
                  classes={{ root: classes.Divider }}
                  style={{ margin: "17px 0 0 0" }}
                />
              </Hidden>
              {/* ///////////////////////////////////////////////////////// */}
              <div className={classes.cardContent}>
                <div className={classes.cardContentDiv}>
                  <Hidden smDown>
                    <div style={{ display: "inline-grid" }}>
                      <Odometer
                        className={classes.iconComponent}
                        style={{ justifySelf: "center" }}
                        size="25"
                        fill="#07303E"
                      />
                      {carCard?.milage ? (
                        <Typography color="#07303E">
                          {distanceWithCommas(carCard?.milage)}
                        </Typography>
                      ) : (
                        <Skeleton className={classes.iconTypographySkeleton} />
                      )}
                    </div>
                  </Hidden>
                  <div className={classes.iconDiv}>
                    <DriveType
                      className={classes.iconComponent}
                      style={{ justifySelf: "center" }}
                      size={!small ? "25" : "19"}
                      fill="#07303E"
                    />
                    {carCard?.drive_type ? (
                      <Typography
                        color="#07303E"
                        className={classes.iconTypography}
                      >
                        {carCard?.drive_type}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.iconTypographySkeleton} />
                    )}
                  </div>
                  <div className={classes.iconDiv}>
                    <FuelType
                      className={classes.iconComponent}
                      style={{ justifySelf: "center" }}
                      size={!small ? "25" : "19"}
                      fill="#07303E"
                    />
                    {carCard?.fuel_type ? (
                      <Typography
                        color="#07303E"
                        className={classes.iconTypography}
                      >
                        {carCard?.fuel_type}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.iconTypographySkeleton} />
                    )}
                  </div>
                  <div className={classes.iconDiv}>
                    <CountrySpecs
                      className={classes.iconComponent}
                      style={{ justifySelf: "center" }}
                      size={!small ? "25" : "19"}
                      fill="#07303E"
                    />
                    {carCard?.country_specs ? (
                      <Typography
                        color="#07303E"
                        className={classes.iconTypography}
                      >
                        {carCard?.country_specs}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.iconTypographySkeleton} />
                    )}
                  </div>
                </div>
                {small ? (
                  <hr
                    style={{
                      height: "1px",
                      margin: "0 -16px 20px",
                      width: "calc(100% + 32px)",
                      border: "none",
                      backgroundImage:
                        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    }}
                  />
                ) : (
                  <Divider
                    component="div"
                    classes={{ root: classes.Divider }}
                    style={{ margin: "0" }}
                  />
                )}
                <Hidden mdUp>
                  <div style={{ display: "flex", marginBottom: "12px" }}>
                    <Odometer
                      className={classes.secondIconDiv}
                      size="19"
                      fill="#07303E"
                    />
                    <Typography color="#07303E" style={{ fontSize: "14px" }}>
                      {t("Odometer")}
                    </Typography>
                    {carCard?.milage ? (
                      <Typography
                        color="#07303E"
                        className={classes.secondIconTypography}
                      >
                        {distanceWithCommas(carCard?.milage)}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.mobSecondIconSkeleton} />
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <GPSOutlined
                      className={classes.secondIconDiv}
                      size="19"
                      fill="#07303E"
                    />
                    <Typography color="#07303E" style={{ fontSize: "14px" }}>
                      {t("Location")}
                    </Typography>
                    {carCard?.city ? (
                      <Typography
                        color="#07303E"
                        className={classes.secondIconTypography}
                      >
                        {`${t(carCard?.city)}, ${carCard?.area}`}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.mobSecondIconSkeleton} />
                    )}
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      margin: "20px -16px 28px",
                      width: "calc(100% + 32px)",
                      border: "none",
                      backgroundImage:
                        "radial-gradient(50% 50% at 50% 50%, rgba(24, 105, 133, 0.5) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    }}
                  />
                </Hidden>
              </div>

              {/* //////////////////////////////////////////// */}
              <Hidden smDown>
                <div className={classes.priceDiv}>
                  <div className={classes.priceNegotiableDiv}>
                    {carCard?.price ? (
                      <Typography className={classes.priceTypography}>
                        {t("english JD")}
                        {`${numberWithCommas(carCard?.price)}`}
                        {t("arabic JD")}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.priceTypographySkeleton} />
                    )}
                    <div className={classes.carid}>ID #{carId}</div>
                  </div>
                </div>
                <div className={classes.priceDiv}>
                  <div className={classes.priceNegotiableDiv}>
                    {carCard?.price ? (
                      (carCard?.installments_period !== "N/A" ||
                        carCard?.down_payment !== "N/A") && (
                        <Typography
                          className={classes.priceinstallmentsTypography}
                        >
                          {t("english JD")}
                          {t(numberWithCommas(carCard?.installments_amount))}
                          {t("arabic JD")}
                          {t("/ Month .")}{" "}
                          <Typography
                            className={classes.installmentsSecondTypography}
                          >
                            {carCard?.installments_period} {t(" yrs . JD")}{" "}
                            {carCard?.down_payment / 1000} {t("K down")}
                          </Typography>
                        </Typography>
                      )
                    ) : (
                      <Skeleton className={classes.priceTypographySkeleton} />
                    )}
                    {carCard?.price_status === "Negotiable" ||
                    carCard?.price_status === "قابل للتفاوض" ? ( // probably wrong should send default name along with the translated name from backend to filter on the default
                      localStorage.getItem("language") === "en" ? (
                        <NegotiableWebEng
                          fill={"#07303E"}
                          size={small ? 37.03 : 56.2}
                        />
                      ) : (
                        <NegotiableWebAr
                          fill={"#07303E"}
                          size={small ? 37.03 : 56.2}
                        />
                      )
                    ) : localStorage.getItem("language") === "en" ? (
                      <FinalpriceWebEng
                        fill={"#07303E"}
                        size={small ? 37.03 : 56.2}
                      />
                    ) : (
                      <FinalpriceWebAr
                        fill={"#07303E"}
                        size={small ? 37.03 : 56.2}
                      />
                    )}
                  </div>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.priceDiv}>
                  <div className={classes.priceNegotiableDiv}>
                    {carCard?.price ? (
                      <Typography className={classes.priceTypography}>
                        {t("english JD")}
                        {`${numberWithCommas(carCard?.price)}`}
                        {t("arabic JD")}
                      </Typography>
                    ) : (
                      <Skeleton className={classes.priceTypographySkeleton} />
                    )}
                    {carCard?.price_status === "Negotiable" ||
                    carCard?.price_status === "قابل للتفاوض" ? ( // probably wrong should send default name along with the translated name from backend to filter on the default
                      localStorage.getItem("language") === "en" ? (
                        <NegotiableWebEng
                          fill={"#07303E"}
                          size={small ? 37.03 : 56.2}
                        />
                      ) : (
                        <NegotiableWebAr
                          fill={"#07303E"}
                          size={small ? 37.03 : 56.2}
                        />
                      )
                    ) : localStorage.getItem("language") === "en" ? (
                      <FinalpriceWebEng
                        fill={"#07303E"}
                        size={small ? 37.03 : 56.2}
                      />
                    ) : (
                      <FinalpriceWebAr
                        fill={"#07303E"}
                        size={small ? 37.03 : 56.2}
                      />
                    )}
                  </div>
                  {carCard?.installments_amount ? (
                    carCard?.installments_amount !== "N/A" ||
                    carCard?.installments_period !== "N/A" ||
                    (carCard?.down_payment !== "N/A" && ( // probably wrong should send default name along with the translated name from backend to filter on the default
                      <Typography
                        className={classes.installmentsFirstTypography}
                      >
                        {t("english JD")}
                        {t(numberWithCommas(carCard?.installments_amount))}
                        {t("arabic JD")}
                        {t("/ Month .")}{" "}
                        <Typography
                          className={classes.installmentsSecondTypography}
                        >
                          {carCard?.installments_period} {t(" yrs . JD")}{" "}
                          {carCard?.down_payment / 1000} {t("K down")}
                        </Typography>
                      </Typography>
                    ))
                  ) : (
                    <Skeleton
                      className={classes.installmentsTypographySkeleton}
                    />
                  )}
                </div>
              </Hidden>
              <Grid container className={classes.scorediv}>
                <Hidden mdUp>
                  <ConditionReport
                    carId={carId}
                    noOfPoints={numOfPoints}
                    groups={reportGroups.groups}
                    enteredBy={enteredBy}
                    dateObject={dateObject}
                    score={Math.floor(carScore)}
                    scroll={handleScrollClick}
                  />
                  <Divider light />
                  {/* <Paper
                    style={{
                      margin: "0 -16px", ////new
                      // margin: "0",
                      padding: "6px 30px 64px",
                      // boxShadow: " 0px 2px 4px rgba(15, 71, 90, 0.4)",
                      boxShadow: "unset", ////new
                      width: "100%",
                      borderTop: "1px solid #F3F3F3",
                    }}
                  ></Paper> */}
                </Hidden>
              </Grid>
              {/* ///////////////////////////////////////////////////////// */}
              <Grid container className={classes.scorediv}>
                <Hidden smDown>
                  <ConditionReport
                    carId={carId}
                    noOfPoints={numOfPoints}
                    groups={reportGroups.groups}
                    enteredBy={enteredBy}
                    dateObject={dateObject}
                    score={Math.floor(carScore)}
                    scroll={handleScrollClick}
                  />
                </Hidden>
              </Grid>
              {/* ///////////////////////////////////////////////////////// */}
              <Hidden mdUp>
                <Divider
                  light
                  style={{
                    height: "9px",
                    width: "calc(100% + 32px)",
                    margin: "0 -16px",
                  }}
                />
              </Hidden>
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                justify="space-between"
                classes={{ root: classes.headerwrap }}
              >
                <Grid item xs zeroMinWidth style={{ maxWidth: "min-content" }}>
                  <Typography
                    style={
                      localStorage.getItem("language") === "ar"
                        ? { fontWeight: "700" }
                        : { fontWeight: "500" }
                    }
                    className={classes.header}
                    noWrap
                  >
                    {t("Vehicle Information")}
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item xs>
                    <Divider
                      component="div"
                      classes={{ root: classes.Divider }}
                    />
                  </Grid>
                </Hidden>
              </Grid>
              <ExpansionPanel
                defaultExpanded={true}
                disabled={true}
                style={{
                  all: "unset",
                  marginBottom: "32px",
                }}
              >
                <ExpansionPanelDetails
                  className={classes.vehicleDetailsExpansionPanelDetails}
                >
                  {destructuredGroups.length !== 0
                    ? destructuredGroups
                        .sort((a, b) => {
                          return a.field_tree_id - b.field_tree_id;
                        })
                        .map((field, i) => {
                          let rowColor = !mediumDown
                            ? i % 2 === 0
                              ? "#BFD6DE"
                              : "white"
                            : i % 2 === 0
                            ? "#BFD6DE"
                            : "white";
                          let gridColumn = 1; /* gridColumn used to be equal to (i % 2 === 0 ? 1 : 2) to seperate information into 2 columns*/
                          return (
                            <List
                              style={{
                                padding: "0",
                                display: "grid",
                                gridColumn: gridColumn,
                              }}
                              defaultExpanded={i === 0}
                              key={`group_${i}`}
                            >
                              <div>
                                {(field.text !== null && field.text !== "") ||
                                (field.option_title !== null &&
                                  field.option_title !== "") ? (
                                  <ListItem
                                    key={`group_field_${i}`}
                                    className={classes.listItem}
                                  >
                                    <ListItemText
                                      classes={{ root: classes.detailItem }}
                                      style={{
                                        backgroundColor: rowColor,
                                        height: "100%",
                                      }}
                                    >
                                      <Grid
                                        container
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={6}
                                          className={classes.detailNameGrid}
                                        >
                                          <b
                                            style={
                                              localStorage.getItem(
                                                "language"
                                              ) === "ar"
                                                ? { fontWeight: "600" }
                                                : { fontWeight: "500" }
                                            }
                                            className={classes.detailName}
                                          >
                                            {field.field_title}
                                          </b>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{
                                            padding: "4px 0",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <text
                                            style={
                                              localStorage.getItem(
                                                "language"
                                              ) === "ar"
                                                ? { fontWeight: "600" }
                                                : { fontWeight: "500" }
                                            }
                                            className={classes.detail}
                                          >
                                            {field.field_id == 226 ? (
                                              <>
                                                <Typography
                                                  style={
                                                    localStorage.getItem(
                                                      "language"
                                                    ) === "ar"
                                                      ? {
                                                          fontWeight: "600",
                                                          textTransform:
                                                            "uppercase",
                                                          padding: 0,
                                                        }
                                                      : {
                                                          fontWeight: "500",
                                                          textTransform:
                                                            "uppercase",
                                                          padding: 0,
                                                        }
                                                  }
                                                  className={classes.detail}
                                                >
                                                  {field.option_title ||
                                                    field.text}
                                                </Typography>
                                              </>
                                            ) : (
                                              <>
                                                <p
                                                  style={{
                                                    direction:
                                                      localStorage.getItem(
                                                        "language"
                                                      ) === "ar" &&
                                                      (field.field_id == 235 ||
                                                        field.field_id ==
                                                          238) &&
                                                      "ltr",
                                                  }}
                                                >
                                                  {field.option_title ||
                                                    field.text}
                                                </p>
                                              </>
                                            )}
                                          </text>
                                        </Grid>
                                      </Grid>
                                    </ListItemText>
                                  </ListItem>
                                ) : null}
                              </div>
                            </List>
                          );
                        })
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                        return (
                          <Skeleton
                            variant="rect"
                            className={classes.vehicleInformationSkeleton}
                          />
                        );
                      })}
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* ///////////////////////////////////////////////// */}
              <Hidden mdUp>
                <Divider
                  light
                  style={{
                    height: "9px",
                    width: "calc(100% + 32px)",
                    margin: "0 -16px",
                  }}
                />
              </Hidden>
              <ExpansionPanel
                defaultExpanded={true}
                disabled={true}
                style={{
                  all: "unset",
                  marginBottom: "32px",
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                  justify="space-between"
                  classes={{ root: classes.headerwrap }}
                >
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    style={{ maxWidth: "min-content" }}
                  >
                    <Typography
                      style={
                        localStorage.getItem("language") === "ar"
                          ? { fontWeight: "700" }
                          : { fontWeight: "500" }
                      }
                      className={classes.header}
                      noWrap
                    >
                      {t("Vehicle Features")}
                    </Typography>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs>
                      <Divider
                        component="div"
                        classes={{ root: classes.Divider }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
                <Collapse
                  in={openFeature}
                  collapsedHeight={small ? 180 : 260}
                  ref={vehicleFeaturesRef}
                  style={{ position: "relative" }}
                  timeout={700}
                >
                  <ul style={{ margin: 0, padding: 0, width: "100%" }}>
                    <Grid container>
                      {carData?.generic_features_names ? (
                        generic_features_names?.length > 0 ? ( //##
                          // 0
                          featureNames.map((feature, i) => (
                            <Grid
                              item
                              xs={6}
                              md={6}
                              style={{ position: "relative" }}
                            >
                              <ListItem
                                style={{
                                  padding: "0 0 0 13px",
                                }}
                                key={`feature_first_set_${i}`}
                              >
                                <ListItemIcon
                                  classes={{ root: classes.listItemIconRoot }}
                                >
                                  <FeatureIcons
                                    iconId={getFeatureId(feature)}
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography
                                    style={
                                      localStorage.getItem("language") === "ar"
                                        ? { fontWeight: "600" }
                                        : { fontWeight: "400" }
                                    }
                                    classes={{
                                      root: classes.listItemTextRoot,
                                    }}
                                  >
                                    {feature}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            </Grid>
                          ))
                        ) : (
                          <Typography>
                            {t("No Features available for this car!")}
                          </Typography>
                        )
                      ) : (
                        <Grid container>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                            let inc = small ? 5 : 10;
                            return (
                              <Grid item xs={6}>
                                <Skeleton
                                  style={{
                                    width: `${80 + i * inc}px`,
                                    height: "21px",
                                    marginBottom: "18px",
                                    transform: "none",
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                  </ul>
                  {!openFeature && compressFeatureList ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translate(0,-100%)",
                        width: "100%",
                        height: "105px",
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 16.98%, #FFFFFF 99.99%, #FFFFFF 100%)",
                      }}
                    />
                  ) : null}
                </Collapse>
                {compressFeatureList ? (
                  <>
                    <Divider light style={{ height: "1px", width: "100%" }} />
                    <Grid container style={{ width: "100%" }}>
                      <Button
                        classes={{ root: classes.showMoreAndLess }}
                        fullWidth
                        onClick={() => {
                          setOpenFeature(!openFeature);
                          window.scrollTo({
                            left: 0,
                            top: vehicleFeaturesRef.current.offsetTop,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Grid item>
                          <Typography className={classes.showMoreAndLessText}>
                            {!openFeature ? t("Show more") : t("Show less")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ExpandMoreIcon
                            className={[classes.arrow]}
                            style={
                              openFeature
                                ? {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    color: "#00BDFF",
                                    height: "100%",
                                    transform: "rotate(-180deg)",
                                  }
                                : {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    color: "#00BDFF",
                                    height: "100%",
                                    transform: "rotate(0)",
                                  }
                            }
                          />
                        </Grid>
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </ExpansionPanel>
              {/* ////////////////////////////////////////////////////// */}
              <Hidden mdUp>
                <Divider
                  light
                  style={{
                    height: "9px",
                    width: "calc(100% + 32px)",
                    margin: "0 -16px",
                    // margin: "0 -12px 0 -12px",
                  }}
                />
              </Hidden>

              {/* ///////////////////////////////////////////////////////////////// */}
            </div>
            {!hideCTA ? (
              <ActionBar
                carId={carId}
                publishCarForBuyers={props.publishCarForBuyers}
                publishCarForSeller={props.publishCarForSeller}
                reportBuilder={reportBuilder}
                checkVisFormsPoints={checkVisFormsPoints}
                toggleNotification={toggleNotification}
                carCard={carData.card}
                carImages={carImages}
              />
            ) : null}
          </div>
          {/* <Hidden smDown> */}
          <div className={classes.mainInspectionDiv} ref={inspectionReportRef}>
            {/* {!loadingCar && ( */}
            <InspectionReport
              carInfo={carData}
              detailsLoadingCar={loadingCar} // to reload inspection reports use effect that fets the tables data so that the tables would load
              hideAppBar={hideAppBar}
              showAppBar={showAppBar}
              handleCallback={handleCallback}
              carCard={carCard}
            />
            {/* )} */}
          </div>
          {/* </Hidden> */}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center" style={{ padding: "0" }}>
        <Grid item xs={12}>
          <Paper className={classes.navigationPaper} square>
            <Hidden smDown>
              <Breadcrumbs
                className={classes.breadcrumbsComponent}
                separator={"/"}
                aria-label="breadcrumb"
              >
                <MaterialLink className={classes.Link} color="#626465">
                  <Link
                    style={{
                      color: "#626465",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                    className={classes.Link}
                    onClick={() => {
                      history.length <= 2
                        ? history.replace("/")
                        : history.goBack();
                    }}
                  >
                    {t("Car Listings")}
                  </Link>
                </MaterialLink>

                {carCard?.year ? (
                  <div style={{ display: "flex" }}>
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={`${carCard?.year}`}
                    />
                    {"\u00A0"}
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={`${carCard?.make}`}
                    />
                    {"\u00A0"}
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={`${carCard?.model}`}
                    />
                    {"\u00A0"}
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={`${carCard?.trim}`}
                    />
                    {"\u00A0"}
                    <Typography
                      color="#07303E"
                      style={{
                        fontSize: "14px",
                        color: "#07303E",
                      }}
                      children={t("Details")}
                    />
                  </div>
                ) : (
                  <Typography
                    color="#07303E"
                    style={{
                      fontSize: "14px",
                      color: "#07303E",
                    }}
                    children={`Car Loading ...`}
                  />
                )}
              </Breadcrumbs>
            </Hidden>
            <Hidden mdUp>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton
                  onClick={() => {
                    history.replace("/");
                  }}
                >
                  <BackArrow
                    size="15"
                    fill="white"
                    style={
                      localStorage.getItem("language") == "en"
                        ? {}
                        : { transform: "rotate(180deg)" }
                    }
                  ></BackArrow>
                </IconButton>
                <Typography
                  style={
                    localStorage.getItem("language") === "ar"
                      ? {
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "white",
                          margin: "auto",
                        }
                      : {
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "white",
                          margin: "auto",
                        }
                  }
                >
                  {t("All Results")}
                </Typography>
              </div>
            </Hidden>
          </Paper>
          <div>{renderFirstTab(activeStep)}</div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state, // dont know why but adding this here causes changes in carList to cause a rerender so that the reservation changes is caught ???!!!
  carList: state.car.cars,
  carScore: state.car.car.score,
  date: state.car.car.created_at,
  enteredBy: state.car.car.entered_by_fullname,
  role: state?.auth?.user?.role,
});

const mapDispatchToProps = (dispatch) => ({
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getCarDetails(payload) {
    dispatch(getCarDetails(payload));
  },
  getCarGroups(payload) {
    dispatch(getCarGroups(payload));
  },
  publishCarForBuyers(payload) {
    dispatch(publishCarForBuyers(payload));
  },
  reportBuilder(payload) {
    dispatch(reportBuilder(payload));
  },
  publishCarForSeller(payload) {
    dispatch(publishCarForSeller(payload));
  },

  checkVisFormsPoints(payload) {
    dispatch(checkVisFormsPoints(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  DTCFaults(payload) {
    dispatch(DTCFaults(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarDetails)
);
