import {
  FETCH_TOKEN_REQUEST,
  TOKEN_REMOVE_REQUEST,
  RESET_PASSWORD_CONFIRM,
  ACTIVATE_ACCOUNT_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
  SEND_OTP,
  VALIDATE_OTP,
} from "../constants";

export function getToken(credentials) {
  return {
    type: FETCH_TOKEN_REQUEST,
    payload: credentials,
  };
}

export function confirmReset(payload) {
  return {
    type: RESET_PASSWORD_CONFIRM,
    payload,
  };
}

export function activateAccount(payload) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST,
    payload,
  };
}

export function logout() {
  return {
    type: TOKEN_REMOVE_REQUEST,
  };
}

export function updateToken(payload) {
  return {
    type: FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
    payload: payload,
  };
}

export function sendOTP(payload) {
  return {
    type: SEND_OTP,
    payload: payload,
  };
}

// VALIDATE_OTP
export function validateOTP(payload) {
  return {
    type: VALIDATE_OTP,
    payload: payload,
  };
}
