import React from "react";

const FeatureIcon7 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon7Clip0)">
<path d="M31.6667 0H18.3333C13.2791 0 9.1666 4.1125 9.1666 9.1667V40.8334C9.1666 45.8876 13.2791 50.0001 18.3333 50.0001H31.6666C36.7208 50.0001 40.8333 45.8876 40.8333 40.8334V9.1667C40.8333 4.1125 36.7208 0 31.6667 0ZM39.1667 40.8333C39.1667 44.9691 35.8017 48.3333 31.6667 48.3333H18.3333C14.1983 48.3333 10.8333 44.9691 10.8333 40.8333V9.1667C10.8333 5.03086 14.1983 1.6667 18.3333 1.6667H31.6666C35.8016 1.6667 39.1666 5.03086 39.1666 9.1667V40.8333H39.1667Z" fill="#07303E"/>
<path d="M17.5 4.16675C15.2025 4.16675 13.3333 6.03589 13.3333 8.33344C13.3333 10.6309 15.2024 12.5001 17.5 12.5001C19.7975 12.5001 21.6667 10.631 21.6667 8.33344C21.6667 6.03589 19.7975 4.16675 17.5 4.16675ZM17.5 10.8333C16.1217 10.8333 15 9.71167 15 8.33335C15 6.95503 16.1217 5.83335 17.5 5.83335C18.8783 5.83335 20 6.95503 20 8.33335C20 9.71167 18.8783 10.8333 17.5 10.8333Z" fill="#07303E"/>
<path d="M28.3333 22.5H21.6666C20.2883 22.5 19.1666 23.6217 19.1666 25C19.1666 26.3783 20.2883 27.5 21.6666 27.5H28.3333C29.7116 27.5 30.8333 26.3783 30.8333 25C30.8333 23.6217 29.7116 22.5 28.3333 22.5ZM28.3333 25.8333H21.6666C21.2074 25.8333 20.8333 25.46 20.8333 25C20.8333 24.54 21.2074 24.1667 21.6666 24.1667H28.3333C28.7925 24.1667 29.1666 24.54 29.1666 25C29.1666 25.46 28.7925 25.8333 28.3333 25.8333Z" fill="#07303E"/>
<path d="M28.3333 32.5H21.6666C20.2883 32.5 19.1666 33.6217 19.1666 35C19.1666 36.3783 20.2883 37.5 21.6666 37.5H28.3333C29.7116 37.5 30.8333 36.3783 30.8333 35C30.8333 33.6217 29.7116 32.5 28.3333 32.5ZM28.3333 35.8333H21.6666C21.2074 35.8333 20.8333 35.46 20.8333 35C20.8333 34.54 21.2074 34.1667 21.6666 34.1667H28.3333C28.7925 34.1667 29.1666 34.54 29.1666 35C29.1666 35.46 28.7925 35.8333 28.3333 35.8333Z" fill="#07303E"/>
<path d="M28.3333 14.1667H21.6666C17.5316 14.1667 14.1666 17.5309 14.1666 21.6667V25C14.1666 25.4609 14.5399 25.8333 14.9999 25.8333H19.9999C20.4599 25.8333 20.8332 25.4609 20.8332 25C20.8332 24.5401 21.2073 24.1667 21.6665 24.1667H28.3332C28.7924 24.1667 29.1665 24.5401 29.1665 25C29.1665 25.4609 29.5398 25.8333 29.9998 25.8333H34.9998C35.4598 25.8333 35.8331 25.4609 35.8331 25V21.6667C35.8333 17.5309 32.4684 14.1667 28.3333 14.1667ZM34.1658 24.1667H30.6899C30.3466 23.1967 29.4191 22.5 28.3324 22.5H21.6657C20.5791 22.5 19.6523 23.1967 19.3082 24.1667H15.8323V21.6667C15.8323 18.4509 18.449 15.8334 21.6656 15.8334H28.3323C31.549 15.8334 34.1656 18.4509 34.1656 21.6667V24.1667H34.1658Z" fill="#07303E"/>
<path d="M35 34.1667H30C29.54 34.1667 29.1667 34.5392 29.1667 35C29.1667 35.46 28.7926 35.8334 28.3334 35.8334H21.6667C21.2075 35.8334 20.8334 35.46 20.8334 35C20.8334 34.5392 20.4601 34.1667 20.0001 34.1667H15.0001C14.5401 34.1667 14.1668 34.5392 14.1668 35V38.3333C14.1668 42.4692 17.5318 45.8334 21.6668 45.8334H28.3335C32.4685 45.8334 35.8335 42.4692 35.8335 38.3333V35C35.8333 34.5392 35.46 34.1667 35 34.1667ZM34.1667 38.3333C34.1667 41.5492 31.55 44.1666 28.3334 44.1666H21.6667C18.45 44.1666 15.8334 41.5492 15.8334 38.3333V35.8334H19.3093C19.6533 36.8034 20.58 37.5 21.6667 37.5H28.3334C29.4201 37.5 30.3468 36.8034 30.6909 35.8334H34.1668V38.3333H34.1667Z" fill="#07303E"/>
<path d="M35 24.1667H30C29.54 24.1667 29.1667 24.5392 29.1667 25C29.1667 25.46 28.7926 25.8333 28.3334 25.8333H21.6667C21.2075 25.8333 20.8334 25.46 20.8334 25C20.8334 24.5392 20.4601 24.1667 20.0001 24.1667H15.0001C14.5401 24.1667 14.1668 24.5392 14.1668 25V35C14.1668 35.4609 14.5401 35.8334 15.0001 35.8334H20.0001C20.4601 35.8334 20.8334 35.4609 20.8334 35C20.8334 34.5401 21.2075 34.1667 21.6667 34.1667H28.3334C28.7926 34.1667 29.1667 34.5401 29.1667 35C29.1667 35.4609 29.54 35.8334 30 35.8334H35C35.46 35.8334 35.8333 35.4609 35.8333 35V25C35.8333 24.5392 35.46 24.1667 35 24.1667ZM34.1658 34.1667H30.6899C30.3466 33.1967 29.4191 32.5 28.3324 32.5H21.6657C20.579 32.5 19.6523 33.1967 19.3082 34.1667H15.8323V25.8334H19.3081C19.6522 26.8035 20.5789 27.5001 21.6656 27.5001H28.3323C29.419 27.5001 30.3457 26.8035 30.6898 25.8334H34.1657V34.1667H34.1658Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon7Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon7;
