import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  TextField,
  Paper,
  Fab,
  Slide,
  Hidden,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import InfoButton from "./PdfPreviewer";

const useStyles = makeStyles((theme) => ({
  anything: { whiteSpace: "unset" },
  chip: { margin: "2px" },
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  defaultDisabled: {
    backgroundColor: "#F3F4FA",
  },
  emptyDisabled: {
    backgroundColor: "#DDDDDE",
  },
  innerSelectIcon: {
    left: "7px",
    right: "auto",
  },
  inputsContainer: {
    paddingRight: "11px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "9px",
    },
  },
  answerPaper: {
    display: "flex",
    padding: "16px",
    margin: "18px 0",
    [theme.breakpoints.down("sm")]: {
      padding:
        theme.direction == "ltr" ? "16px 5px 16px 16px" : "16px 16px 16px 5px",
    },
  },
  dialogContent: {
    padding: "0 30px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 30px",
    },
  },
  dialogTitle: {
    padding:
      theme.direction == "ltr" ? "30px 30px 0px 18px" : "30px 18px 0px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.direction == "ltr" ? "24px 15px 0 3px" : "24px 3px 0 15px",
    },
  },
  dialogTitleTypography: {
    fontSize: "24px",
    fontWeight: "500",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  hideLable: {
    display: "none",
  },
  mobListBox: {
    maxHeight: "calc(100vh - 150px)",
    minHeight: "calc(100vh - 150px)",
    padding: "0",
  },
  mobPaper: {
    margin: "5px 0",
    boxShadow: "unset",
  },
  mobPopper: {
    transform: "translate3d(16px, 120px, 0px) !important", /// wrong depends on scroll
    // transform: "translate3d(16px, 290px, 0px) !important",
  },
  mobOption: {
    padding: "6px 0",
  },
  rootDirection: {
    direction: "rtl",
  },
  endAdornment: {
    left: "9px",
    right: "auto!important",
    [theme.breakpoints.down("sm")]: {
      left: 0,
      right: "auto",
    },
  },
  inputRootEnglish: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "56px",
      paddingRight: "0px!important",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "14px!important",
      paddingLeft: "65px!important",
    },
  },
  inputRootShared: {
    [theme.breakpoints.up("md")]: {
      borderRadius: "8.23px",
      marginBottom: "16px",
    },
  },
}));
////////////////////////////////////////////////////////////////////

const filterOptions = createFilterOptions({
  // matchFrom: "start",
  stringify: (option) => option.title,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AutocompleteComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    options,
    handleChange,
    formTitle,
    handleClickOpen,
    handleInvokedField,
    fromMobile,
    answer,
    i,
    handleOptitonError,
    setShowInfo,
    SetInfoOption,
    setOpen,
  } = props;

  const conditionalProps = fromMobile
    ? { open: { fromMobile }, PopperComponent: "Popper" }
    : {};
  return (
    <Autocomplete
      id="combo-box-demo"
      {...conditionalProps}
      // options={options.filter((option) => option.title !== "Excellent")}
      options={options}
      // value={answer?.title}
      value={
        answer?.option?.title !== ""
          ? answer?.option?.title
          : "ﻗﻢ ﺑﺈدﺧﺎل اﻟﻤﺸﻜﻠﺔ..."
      }
      // getOptionLabel={(option) => option} //// ????
      // getOptionLabel={(option) =>
      //   option.default === false ? option.title : null
      // }
      getOptionSelected={(option, value) => {}}
      style={{ width: "100%" }}
      onChange={(event, value) => {
        if (event.target.nodeName != "path" && event.target.nodeName != "svg") {
          if (value) {
            handleChange(event, value, i);
            setOpen(false);
          }
        }
      }}
      filterOptions={filterOptions}
      renderOption={(option) => (
        <>
          <Typography>{option.title}</Typography>
          <InfoButton
            option={option}
            SetInfoOption={SetInfoOption}
            setShowInfo={setShowInfo}
          />
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          // label={t("Options")}
          // label={t("ﻗﻢ ﺑﺈدﺧﺎل اﻟﻤﺸﻜﻠﺔ...")}
          variant={fromMobile ? "standard" : "outlined"}
          onClick={handleClickOpen}
          InputLabelProps={{
            shrink: false,
            className: clsx({ [classes.hideLable]: answer?.text !== "" }),
          }}
          // error={handleOptitonError(i)}
        />
      )}
      classes={{
        listbox: clsx({ [classes.mobListBox]: fromMobile }),
        root: clsx({
          [classes.switch_track]: handleInvokedField(),
          [classes.switch_base]: !handleInvokedField(),
          [classes.rootDirection]: theme.direction == "ltr", // mob only ?
        }),
        paper: clsx({ [classes.mobPaper]: fromMobile }),
        popper: clsx({
          [classes.mobPopper]: fromMobile,
          [classes.rootDirection]: theme.direction == "ltr",
        }),
        option: clsx({ [classes.mobOption]: fromMobile }),
        endAdornment: clsx({
          [classes.endAdornment]: theme.direction == "ltr",
        }), // mob only ?
        inputRoot: clsx({
          [classes.inputRootEnglish]: theme.direction == "ltr",
          [classes.inputRootShared]: !fromMobile,
        }), // mob only ?

        // endAdornment:clsx({ [classes.mobPaper]: fromMobile }),
      }}
      // />
    ></Autocomplete>
  );
};

const BreakPointAutoCompleteSelection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    options,
    formTitle,
    index,
    handleInvokedField,
    isdone,
    answer,
    answers,
    fieldId,
    handleSelectOption,
  } = props;
  const [open, setOpen] = useState(false); // Autocomplete component has a bug initial state null solves it
  const [infoOption, SetInfoOption] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleChange = (event, value, i) => {
    // let newAnswer = Object.assign({}, answers[i], {
    //   id: value.id.toString(),
    //   option: options.find((el) => el.id === value.id),
    //   // expanded: false,
    //   // answerPointLink: `${fieldId}-${value.id}-${i}`,
    // });
    // answers[i] = newAnswer;
    let x = { target: { value: {} } };
    // x["target"]["value"] = value;
    // x.target.value = value;
    x.target.value = value.id.toString();
    handleSelectOption(x);
    // handleSelectOption(value.id.toString());

    // handleSelectOption(event);
    // setAnswers([...answers]);
    // setSelectedOption(value.id);
  };

  return (
    <>
      <Hidden smDown>
        <AutocompleteComponent
          SetInfoOption={SetInfoOption}
          setShowInfo={setShowInfo}
          options={options}
          handleChange={handleChange}
          formTitle={formTitle}
          handleClickOpen={handleClickOpen}
          // handleInvokedField={handleInvokedField}
          handleInvokedField={() => {}}
          fromMobile={false}
          answer={answer}
          // i={i}
          i={index}
          setOpen={() => {}}
          // handleOptitonError={handleOptitonError}
        />
      </Hidden>
      <Hidden mdUp>
        <FormControl
          fullWidth
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          <Select
            variant="outlined"
            value={
              answer?.option?.title !== "" ? (
                answer?.option?.title
              ) : (
                <em>{"ﻗﻢ ﺑﺈدﺧﺎل اﻟﻤﺸﻜﻠﺔ..."}</em>
              )
            }
            onClick={options.length !== 0 ? handleClickOpen : null}
            inputProps={{ readOnly: true }}
            onChange={(event) => {
              // handleSelectOption(event)
            }}
            // disabled={options.length === 0 ? true : disabled}
            renderValue={(selected) => {
              return selected;
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "8.23px",
              direction: "rtl",
            }}
            classes={{
              icon: clsx({
                [classes.innerSelectIcon]: theme.direction === "ltr",
              }),
            }}
          >
            {options.map((option, i) => (
              <MenuItem
                key={`select_menu_item_${option.id}_${i}`}
                value={option.id}
              >
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClickClose}
          TransitionComponent={Transition}
          style={{ paddingTop: "56px" }}
        >
          <Paper style={{ padding: "16px", height: "100%" }}>
            {" "}
            <AppBar className={classes.appBar}>
              <Toolbar
                style={{ justifyContent: "space-between", direction: "ltr" }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClickClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {t(formTitle)} {t("Options")}
                </Typography>
              </Toolbar>
            </AppBar>
            <AutocompleteComponent
              SetInfoOption={SetInfoOption}
              setShowInfo={setShowInfo}
              options={options}
              handleChange={handleChange}
              formTitle={formTitle}
              handleClickOpen={handleClickOpen}
              handleInvokedField={handleInvokedField}
              fromMobile={true}
              answer={answer}
              i={index}
              setOpen={setOpen}
              // handleOptitonError={handleOptitonError}
            />
          </Paper>
        </Dialog>
      </Hidden>
    </>
  );
};
////////////////////////////////////////////////////////////////////

const AnswerComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    options,
    index,
    onChange,
    answerArray,
    handleInvokedField,
    isdone,
    fieldId,
    formTitle,
  } = props;
  const thisAnswer = answerArray[index];
  const changeFinalAnswers = (event, key, value) => {
    let tempAnswerArray = [...answerArray];
    tempAnswerArray[index][key] = value;
    if (key == "option") {
      tempAnswerArray[index]["text"] = value?.title;
      tempAnswerArray[index]["id"] = value?.id;
    }
    // updateReduxAnswers
    onChange(event, [...tempAnswerArray]);
  };

  const handleSelectOption = (event) => {
    changeFinalAnswers(
      event,
      "option",
      options.filter((el) => el.id == event.target.value)[0]
    );
  };

  const handleTextChange = (event) => {
    changeFinalAnswers(event, "text", event.target.value);
  };

  return (
    <Paper
      /*style={{ display: "flex", padding: "16px", margin: "18px 0" }}*/ className={
        classes.answerPaper
      }
    >
      <IconButton
        style={{ alignItems: "baseline", padding: "9px" }}
        onClick={(event) => {
          let tempAnswerArray = [...answerArray];
          tempAnswerArray.splice(index, 1);
          onChange(event, [...tempAnswerArray]);
        }}
      >
        <DeleteIcon />
      </IconButton>
      <FormControl fullWidth className={classes.inputsContainer}>
        <BreakPointAutoCompleteSelection
          options={options}
          formTitle={formTitle}
          index={index}
          handleInvokedField={handleInvokedField}
          isdone={isdone}
          answer={thisAnswer}
          answers={[...answerArray]}
          fieldId={fieldId}
          handleSelectOption={handleSelectOption}
        />
        <TextField
          variant="outlined"
          value={answerArray[index]?.text}
          disabled={!thisAnswer?.option?.title || thisAnswer?.option?.default}
          onChange={handleTextChange}
          InputProps={{
            style: { borderRadius: "8.23px" },
            className: clsx({
              [classes.defaultDisabled]: thisAnswer?.option?.default,
              [classes.emptyDisabled]: !thisAnswer?.option?.title,
            }),
          }}
        />
      </FormControl>
    </Paper>
  );
};

const FrameFieldDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    openDialog,
    handleClose,
    formTitle,
    answerArray,
    options,
    onChange,
    field,
    handleInvokedField,
    isdone,
  } = props;

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={openDialog}
      onClose={handleClose}
      maxWidth="sm"
      style={{ direction: "rtl" }}
    >
      <DialogTitle
        /*style={{ padding: "30px 30px 0px 18px" }}*/ className={
          classes.dialogTitle
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            // style={{ fontSize: "24px", fontWeight: "500", color: "#07303E" }}
            className={classes.dialogTitleTypography}
          >
            {formTitle}
          </Typography>
          <IconButton onClick={handleClose} style={{ color: "#07303E" }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div style={{ width: "100%" }}>
          {answerArray.map((answer, index) => (
            <AnswerComponent
              options={options}
              index={index}
              onChange={onChange}
              answerArray={answerArray}
              handleInvokedField={handleInvokedField}
              isdone={isdone}
              fieldId={field.id}
              formTitle={formTitle}
            />
          ))}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Fab
              color="primary"
              size="small"
              onClick={(event) => {
                // here add an obj to redux state
                let tempAnswerArray = [...answerArray];
                tempAnswerArray.push({
                  option: { title: "", id: "" },
                  id: "", // causes error on submit if empty answer was left
                  field: field,
                  text: "",
                });
                onChange(event, [...tempAnswerArray]);
              }}
              style={{ margin: "15px 0 0" }}
            >
              <AddIcon />
            </Fab>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const FrameField = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    field,
    disabled,
    formTitle,
    options,
    answerArray,
    onChange,
    isdone,
    invokedFields,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = (event) => {
    // let cleanedAnswerArray = answerArray.filter((el) => el.option!=null&&el.option.title !== "");
    let cleanedAnswerArray = answerArray.filter((el) => el.option.title !== "");
    onChange(event, [...cleanedAnswerArray]);
    setOpenDialog(false);
  };

  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          style={{ display: "flex", top: "-8px" }}
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          {formTitle}
        </InputLabel>
        <Select
          multiple
          classes={{
            selectMenu: classes.anything,
          }}
          open={false}
          fullWidth
          onOpen={() => {
            setOpenDialog(true);
          }}
          labelId={formTitle}
          value={answerArray.map((answer) => {
            // value looks like this (options ids) ['3439','3440']
            return String(answer.id);
          })}
          disabled={disabled}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => {
            return (
              <div className={classes.chips}>
                {selected.map((value, i) => {
                  const current = answerArray.filter(
                    (el) =>
                      // (!el.id==null||!value==null)&&
                      el.id?.toString() === value?.toString() &&
                      answerArray.indexOf(el) == i
                  );
                  const index = answerArray;
                  if (current) {
                    let title = current[0]?.text;
                    return (
                      <Chip
                        key={`field_chip_${value}_${props.fieldId}_${i}`}
                        label={title}
                        className={classes.chip}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          }}
        />
      </FormControl>
      <FrameFieldDialog
        openDialog={openDialog}
        handleClose={handleClose}
        formTitle={formTitle}
        answerArray={answerArray}
        options={options}
        onChange={onChange}
        field={field}
        handleInvokedField={handleInvokedField}
        isdone={isdone}
      />
    </div>
  );
};

export default FrameField;
