import { Button, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { urlToObject } from "../../helpers/imagesFunctions";
import imageMerger from "../../helpers/mergeImages";
import { getDownloadImages } from "../../store/actions/car";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  item: {
    marginBottom: "8px",
  },
}));

const ImageDownloader = (props) => {
  const { getDownloadImages } = props;

  const classes = useStyles();

  const [carId, setCarId] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getImages = () => {
    getDownloadImages({
      carId,
      callback: (result) => {
        console.log(result.results);
        let imagesArray = result.results.map((obj) => {
          return obj.url;
        });
        setImages(imagesArray);
      },
    });
  };

  const downloadImages = async () => {
    setLoading(true);

    await Promise.all(
      images.map(async (imageObj, i) => {
        let photoKey = imageObj;

        let fetchedCar = await urlToObject(imageObj);
        if (!fetchedCar.includes("data:application/xml;")) {
          await imageMerger(
            {
              carImage: fetchedCar,
            },
            false,
            true
          ).then(async (b64MergedImage) => {
            const base64Response = await fetch(b64MergedImage)
              .then((resp) => resp.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = `Car-${carId}-Image-${i}.png`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              })

              .catch(() => alert("oh no!"));
          });
        }
      })
    ).then(() => setLoading(false));
  };

  const handleChange = (event) => {
    setImages([]);
    setLoading(false);
    setCarId(event.target.value);
  };
  return (
    <div className={classes.mainContainer}>
      <TextField
        variant="outlined"
        label="car ID"
        onChange={handleChange}
        className={classes.item}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.item}
        onClick={getImages}
        disabled={images?.length}
      >
        {!images?.length ? "Get Images" : `${images?.length} Car Images`}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.item}
        onClick={downloadImages}
        disabled={loading}
      >
        Download Images
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getDownloadImages(payload) {
    dispatch(getDownloadImages(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageDownloader);
