import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";
import { getPaparazziCarImages } from "../../store/actions/forms";
import { changeBackgrounds, getCar } from "../../store/actions/car";
import SelectDialog from "../PaparazziTester/SelectDialog";
import wm from "../../images/WaterMark.png";
import imageMerger from "../../helpers/mergeImages";
import {
  dataURLtoFile,
  handleCompress,
  handleCompressMiniImage,
} from "../../helpers/aws";
import { urlToObject } from "../../helpers/imagesFunctions";
import CustomButton from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "20px 0",
    display: "flex",
    position: "relative",
  },
  actionBar: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    padding: "20px",
    position: "sticky",
    top: "20px",
    height: "fit-content",
  },
  actions: {
    marginBottom: "20px",
  },
  buttons: {
    textTransform: "none",
  },
  gridContainer: {
    paddingBlock: "20px",
  },
  gridItem: {
    marginBottom: "20px",
  },
  imagesDiv: {
    display: "grid",
    width: "100%",
  },
  images: {
    width: "100%",
    height: "auto",
    gridColumn: 1,
    gridRow: 1,
  },
  scaledCarImage: {
    transform: "scale(0.8)",
  },
  hide: {
    display: "none",
  },
}));

const RadioForm = (props) => {
  const classes = useStyles();
  const { options, disabled, title, value, onChange } = props;
  return (
    <FormControl
      component="fieldset"
      className={classes.actions}
      disabled={disabled}
    >
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        aria-label="type"
        name="type"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "auto",
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ExtBgEditor = (props) => {
  const classes = useStyles();

  // Props
  const { getPaparazziCarImages, getCar, bodyType, changeBackgrounds } = props;

  // States
  const [carId, setCarId] = useState(0);
  const [images, setImages] = useState([]);
  const [getImages, setGetImages] = useState(false);
  const [type, setType] = useState("");
  const [pointsShifter, setPointsShifter] = useState(null);
  const [openPapaSelector, setOpenPapaSelector] = useState(false);
  const [paparazzi, setPaparazzi] = useState([]);
  const [bigBody, setBigBody] = useState(false);
  const [showWaterMark, setShowWaterMark] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadObject, setLoadObject] = useState({});

  // UseEffects
  useNonInitialEffect(() => {
    getCar({
      id: carId,
      callback: () => {},
    });
    getPaparazziCarImages({
      carId,
      callback: (result) => {
        setImages(result);
      },
    });
    setType("");
  }, [getImages]);

  useEffect(() => {
    let tempImages = [...images];
    tempImages.map((imageObj) => {
      let imageString = imageObj.image;
      let substringIndex = imageString.indexOf(".com/") + 5;
      let secondSubstringIndex = imageString.indexOf("Car-");
      let newImgName =
        imageString.substring(0, substringIndex) +
        type +
        "-" +
        imageString.substring(secondSubstringIndex, imageString.length);
      imageObj.image = newImgName;
    });
    setImages(tempImages);
    if (type == "NoBg") {
      setShowWaterMark(true);
    }
  }, [type]);

  useEffect(() => {
    bodyType &&
      setBigBody(
        bodyType?.body_type_object?.selected_option == 932 ||
          bodyType?.body_type_object?.selected_option == 938
      );
  }, [bodyType]);

  // Functions
  const handleGetImages = () => {
    setImages([]);
    setGetImages(!getImages);
  };
  const onImageLoad = ({ target: img }) => {
    const { naturalWidth, id: index } = img;
    let tempImages = [...images];
    tempImages[index].width = naturalWidth;
    setImages(tempImages);
  };
  const getImageTitle = (imageLink) => {
    let begining = imageLink.indexOf(".com/") + 5;
    let title = decodeURIComponent(imageLink.substring(begining));
    return title;
  };

  // Change Functions
  const handleChange = (event) => {
    setCarId(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handleShifterChange = (event) => {
    setPointsShifter(event.target.value);
  };

  const handleOpenPapaSelector = () => {
    setOpenPapaSelector(true);
  };
  const handleToggleWaterMark = () => {
    setShowWaterMark((prev) => {
      return !prev;
    });
  };

  // Main Function
  const handleChangeImages = async () => {
    setLoading(true);
    let tempImages = [...images];
    console.log("IIIIIIIIIIIIIIIdd", tempImages);
    // if NoBg || Origional => MergeImages (car/bg/shadow/watermark || car/watermark)
    if (["NoBg", "Origional"].includes(type)) {
      ////////////////////////////////////////////////////
      await Promise.all(
        tempImages.map(async (imageObj) => {
          let photoKey =
            type == "Origional"
              ? getImageTitle(imageObj.image)
              : getImageTitle(imageObj.image).replace("NoBg", "Papa");
          let fieldPapa = paparazzi.find((item) => {
            return item.image_field == imageObj.field_id;
          });
          const { image_url: bgImage, shadow } = fieldPapa;
          let fetchedCar = await urlToObject(imageObj.image);
          if (!fetchedCar.includes("data:application/xml;")) {
            await imageMerger(
              {
                carImage: fetchedCar,
                bgImage: type == "Origional" ? null : bgImage,
                shadow: type == "Origional" ? null : shadow,
              },
              type == "Origional" ? false : true, // exterior (0.8)
              bigBody
            ).then(async (b64MergedImage) => {
              const base64Response = await fetch(b64MergedImage);
              let pngImage = dataURLtoFile(b64MergedImage, "imagename.jpg");
              // replace s3: Origional => replace Origional / NoBg => replace Papa

              imageObj.image = imageObj.image.replace("NoBg", "Papa");

              handleCompress(pngImage, photoKey, () => {});
              handleCompressMiniImage(
                pngImage,
                `CPDTest/${photoKey}`,
                () => {}
              );
              ///////////////////////////////////xxxx////////////////
            });
          }
        })
      );

      ///////////////////////////////////////////////////
    }
    // replace DB entries
    const explode =
      pointsShifter === "Explode"
        ? true
        : pointsShifter === "Implode"
        ? false
        : null;
    changeBackgrounds({
      carId,
      body: { images: tempImages, explode },
      callback: () => {
        setLoading(false);
        // window.location.reload();
        setLoadObject({ images: tempImages, explode });
      },
    });
  };

  return (
    <div className={classes.mainContainer}>
      {Object.keys(loadObject).length == 0 ? (
        <>
          <div className={classes.actionBar}>
            <TextField
              variant="outlined"
              label="car ID"
              onChange={handleChange}
              className={classes.actions}
            />
            <Button
              onClick={handleGetImages}
              variant="contained"
              color="primary"
              size="large"
              className={clsx({ [classes.actions]: 1, [classes.buttons]: 1 })}
            >
              Get Images
            </Button>
            <RadioForm
              options={[
                { label: "Origional", value: "Origional" },
                { label: "NoBg", value: "NoBg" },
                { label: "Papa", value: "Papa" },
              ]}
              disabled={!images.length}
              title={"Images Type"}
              value={type}
              onChange={handleTypeChange}
            />
            <RadioForm
              options={[
                { label: "Explode", value: "Explode" },
                { label: "Implode", value: "Implode" },
                { label: "No shift", value: "No shift" },
              ]}
              title={"Shift Visualization Points"}
              value={pointsShifter}
              onChange={handleShifterChange}
            />
            <Button
              onClick={handleOpenPapaSelector}
              variant="contained"
              color="primary"
              size="large"
              className={clsx({ [classes.actions]: 1, [classes.buttons]: 1 })}
              disabled={!(type == "NoBg" && !!images.length)}
            >
              Change Papa Bg
            </Button>
            <FormControl
              component="fieldset"
              className={classes.actions}
              disabled={!images.length || type == "NoBg"}
            >
              <FormLabel component="legend">Show WaterMark</FormLabel>
              <IOSSwitch
                checked={showWaterMark}
                onChange={handleToggleWaterMark}
                name="Toggle Watermark"
              />
            </FormControl>
            <CustomButton
              onClick={handleChangeImages}
              variant="contained"
              color="secondary"
              // size="large"
              className={clsx({ [classes.actions]: 1, [classes.buttons]: 1 })}
              disabled={!images.length} // no images // can resubmit db saved images
              loading={loading}
            >
              Change Images
            </CustomButton>
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            {images.map((imageObj, index) => {
              let fieldPapa = paparazzi.find((item) => {
                return item.image_field == imageObj.field_id;
              });
              return (
                <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                  <Typography>{imageObj.field_title}</Typography>
                  <div className={classes.imagesDiv}>
                    <img src={fieldPapa.image_url} className={classes.images} />
                    <img src={fieldPapa.shadow} className={classes.images} />
                    <img
                      id={index}
                      src={imageObj.image}
                      className={clsx({
                        [classes.images]: 1,
                        [classes.scaledCarImage]: type == "NoBg" && !bigBody,
                      })}
                      onLoad={onImageLoad}
                    />
                    <img
                      src={wm}
                      className={clsx({
                        [classes.images]: 1,
                        [classes.hide]: !showWaterMark,
                      })}
                    />
                  </div>
                  <Typography>{imageObj.image}</Typography>
                </Grid>
              );
            })}
          </Grid>
          <SelectDialog
            open={openPapaSelector}
            setOpen={setOpenPapaSelector}
            setPaparazzi={setPaparazzi}
          />
        </>
      ) : (
        <Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigator.clipboard.writeText(JSON.stringify(loadObject, null, 4))
            }
          >
            Copy Object
          </Button>
          <pre>{JSON.stringify(loadObject, null, 4)}</pre>
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  bodyType: state.car.car.body_type_config,
});

const mapDispatchToProps = (dispatch) => ({
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getPaparazziCarImages(payload) {
    dispatch(getPaparazziCarImages(payload));
  },
  changeBackgrounds(payload) {
    dispatch(changeBackgrounds(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtBgEditor);
