import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        "Car Listings": "Car Listings",
        Earnings: "Earnings",
        "Listing Requests": "Listing Requests",
        Language: "Language (AR)",
        "Sign out": "Sign out",
        Dispatcher: "Dispatcher",
        Officer: "Officer",
        "Car Details": "Car Details",
        "Edit Car": "Edit Car",
        "Add New Car": "Add New Car",
        "Edit Car Information": "Edit Car Information",
        "Submit to qc": "Submit to qc",
        "Submit Form": "Submit Form",
        "Submit & Exit": "Submit & Exit",
        "Sort By": "Sort By",
        Sort: "Sort",
        "Date (Oldest First)": "Date (Oldest First)",
        "Date (Newest First)": "Date (Newest First)",
        "Price (High to Low)": "Price (High to Low)",
        "Price (Low to High)": "Price (Low to High)",
        "Score (High to Low)": "Score (High to Low)",
        "Score (Low to High)": "Score (Low to High)",
        "Odometer (Low to High)": "Odometer (Low to High)",
        "Odometer (High to Low)": "Odometer (High to Low)",
        "Year (Oldest First)": "Year (Oldest First)",
        "Year (Newest First)": "Year (Newest First)",
        Date: "Date",
        Price: "Price",
        Score: "Score",
        Milage: "Milage",
        Year: "Year",
        "(Oldest First)": "(Oldest First)",
        "(Newest First)": "(Newest First)",
        "(Low to High)": "(Low to High)",
        "(High to Low)": "(High to Low)",
        "Condition Score": "Condition Score",
        Results: "Results",
        Odometer: "Odometer",
        Location: "Location",
        Action: "Action",
        "Vehicle Details": "Vehicle Details",
        "Vehicle Details Page": "Vehicle Details Page",
        "Vehicle Features": "Vehicle Features",
        "Vehicle Information": "Vehicle Information",
        "Vehicle Condition": "Vehicle Condition",
        "No Features available for this car!":
          "No Features available for this car!",
        "Markabte Inspection Score": "Markabte Inspection Score",
        "View Full Report": "View Full Report",
        "Edit Car": "Edit Car",
        Listing: "Listing",
        "out of": "out of",
        "Inspection Report": "Inspection Report",
        "Show full report": "Show full report",
        "Markabte Inspection Details": "Markabte Inspection Details:",
        "Inspection Date": "Inspection Date :",
        "Inspected By": "Inspected By :",
        "Inspection Points": "Inspection Points",
        "Overall Inspection Score": "Overall Inspection Score :",
        "Detailed Inspection Points": "Detailed Inspection Points :",
        "Show inspection points with faults only":
          "Show inspection points with faults only",
        "Markabte Officer Comments": "Markabte Officer Comments :",
        "No comments": "No comments!",
        "Back To Vehicle Detail Page": "Back To Vehicle Detail Page",
        "Based on our FREE ": "Based on our FREE ",
        "+ point condition report": "+ point condition report",
        "Incoming Requests": "Incoming Requests",
        Incoming: "Incoming",
        "Claimed Requests": "Claimed Requests",
        Claimed: "Claimed",
        submitted: "submitted",
        "hour ago": "hour ago",
        Claim: "Claim",
        continue: "Continue",
        "List Vehicle": "List Vehicle",
        "List Car": "List Car",
        Listed: "Listed",
        "days ago": "days ago",
        Mile: "Mile",
        Km: "Km",
        JD: "JD",
        "english JD": "JD ",
        "arabic JD": "",
        "/ Month .": "/ Month .",
        " yrs . JD": " yrs . JD",
        "K down": "K down",
        "Edit Content": "Edit Content",
        "Publish Vehicle": "Publish Vehicle",
        "Publish Vehicle?": "Publish Vehicle?",
        "Are you sure that you want to publish the vehicle listing?":
          "Are you sure that you want to publish the vehicle listing?",
        Publish: "Publish",
        Cancel: "Cancel",
        of: "of",
        "View Exterior": "View Exterior",
        "View Interior": "View Interior",
        "Driver Front": "Driver Front",
        "Passenger Rear": "Passenger Rear",
        "Front Angle": "Front Angle",
        "Rear Angle": "Rear Angle",
        Driver: "Driver",
        "Driver View": "Driver View",
        Passenger: "Passenger",
        "Passenger View": "Passenger View",
        Front: "Front",
        Back: "Back",
        "Reset Password": "Reset Password",
        "3rd row": "3rd row",
        "3rd row": "3rd Row",
        Trunk: "Trunk",
        Trunk: "Trunk",
        "View List": "View List",
        "Sign In": "Sign In",
        Email: "Email",
        Password: "Password",
        "forgot password": "Forgot Password?",
        "Hurray, No issues were found": "Hurray, No issues were found!",
        Exterior: "Exterior",
        Interior: "Interior",
        EXTERIOR: "EXTERIOR",
        INTERIOR: "INTERIOR",
        "Drive Front View": "Drive Front View",
        "Passenger Rear View": "Passenger Rear View",
        "3rd Row View": "3rd Row View",
        "Trunk View": "Trunk View",
        "Rear View": "Rear View",
        "Front View": "Front View",
        "Sign in": "Sign in",
        "Keep me signed in": "Keep me signed in",
        "Send Reset Link": "Send Reset Link",
        Minor: "Minor",
        Major: "Major",
        "Minor Issues": "Minor Issues",
        "Major Issues": "Major Issues",
        "Please enter your email and we will send you a reset link.":
          "Please enter your email and we will send you a reset link.",
        "Forgot Your Password?": "Forgot Your Password?",
        "Forgot your password?": "Forgot your password?",
        Connect: "Connect",
        "with serious car buyers.": "with serious car buyers.",
        "Back to Sign In": "Back to Sign In",
        "Please enter your new password.": "Please enter your new password.",
        "New Password": "New Password",
        "Confirm Password": "Confirm Password",
        "Reset Your Password": "Reset Your Password",
        "Sign In to Your Account": "Sign In to Your Account",
        "Keep me signed in": "Keep me signed in",
        "What do you want to do next?": "What do you want to do next?",
        "Vehicle Condition Report": "Vehicle Condition Report",
        "Markabte Condition Report": "Markabte Condition Report",
        "Markabte Officer": "Markabte Officer",
        "Inspection On": "Inspection On",
        "Report Summary": "Report Summary",
        "Vehicle Visual Condition": "Vehicle Visual Condition",
        "Landscape Mode Is Recommended For The Best User Experience!":
          "Landscape Mode Is Recommended For The Best User Experience!",
        "View Visual Cosmetic Report": "View Visual Cosmetic Report",
        "Markabte Full Condition Report": "Markabte Full Condition Report",
        Disclaimer: "Disclaimer",
        "You are successfully connected to live changes":
          "You are successfully connected to live changes",
        "Please make sure you entered valid credentials":
          "Please make sure you entered valid credentials",
        "Your passwords do not match!": "Your passwords do not match!",
        "account activated! please login with your new password":
          "account activated! please login with your new password",
        error: "error",
        "Your password reset reqest has been sent to your email.":
          "Your password reset reqest has been sent to your email.",
        "Please make sure you entered a valid email address":
          "Please make sure you entered a valid email address",
        "Car sale status successfully updated":
          "Car sale status successfully updated",
        "Oops! an error has occured! please try again.":
          "Oops! an error has occured! please try again.",
        "Your changes have been saved": "Your changes have been saved",
        "There was an error sending your request please try again":
          "There was an error sending your request please try again",
        "Answers missing visualization point allocation":
          "Answers missing visualization point allocation",
        "Car successfully published": "Car successfully published",
        "Claimed successfully": "Claimed successfully",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "We measure temperature of cooling/heating systems & check air throw for all AC vents.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)",
        "We check the availability of standard equipment in the vehicle":
          "We check the availability of standard equipment in the vehicle",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.",
        Excellent: "Excellent",
        "Minor Issue": "Minor Issue",
        "Major Issue": "Major Issue",
        "Origional Paint": "Origional Paint",
        Repainted: "Repainted",
        Working: "Working",
        "Not Working": "Not Working",
        "Additional Information": "Additional Information",
        "No codes": "No codes",
        "Minor issues": "Minor issues",
        "Major issues": "Major issues",
        Good: "Good",
        Monitor: "Monitor",
        Replace: "Replace",
        Available: "Available",
        "Not Available": "Not Available",
        "Welcome Back!": "Welcome Back!",
        INSPECT: "INSPECT",
        "the easy way,": "the easy way,",
        EARN: "EARN",
        "money your way.": "money your way.",
        "There where no Major issues spotted in this view!":
          "There where no Major issues spotted in this view!",
        "There where no Minor issues spotted in this view!":
          "There where no Minor issues spotted in this view!",
        "This Angle has no Minor issue": "This Angle has no Minor issue",
        "This Angle has no Major issue": "This Angle has no Major issue",
        amman: "Amman",
        aqaba: "Aqaba",
        jerash: "Jerash",
        irbid: "Irbid",
        madaba: "Madaba",
        zarqa: "Zarqa",
        kerak: "Kerak",
        mafraq: "Mafraq",
        ajloun: "Ajloun",
        salt: "Salt",
        "ma'an": "Ma'an",
        "at-tafilah": "Tafilah",
        "Angle Issues List": "Angle Issues List",
        Options: "Options",
        "Deleting this image would remove all cosmetic hotspots from the previously uploaded image":
          "Deleting this image would remove all cosmetic hotspots from the previously uploaded image",
        Delete: "Delete",
        "Warning!": "Warning!",
        "Please check the visualisation points for the following forms":
          "Please check the visualisation points for the following forms",
        "Please check and resubmitt highlighted forms":
          "Please check and resubmitt highlighted forms",
        "Changing this answer deletes all hotspot paint readings!":
          "Changing this answer deletes all hotspot paint readings!",
        Confirm: "Confirm",
        "Don't Change": "Don't Change",
        System: "System",
        "System Group": "System Group",
        "No.Of Faults": "No.Of Faults",
        Fault: "Fault",
        Description: "Description",
        State: "State",
        "Add A New System": "Add A New System",
        "System Name": "System Name",
        "Fault Code": "Fault Code",
        description: "description",
        state: "state",
        "Delete Code": "Delete Code",
        "Delete Fault": "Delete Fault",
        "Go To Homepage": "Go To Homepage", /// i think remove
        "We couldn’t find the page you are looking for.":
          "We couldn’t find the page you are looking for.",
        "You Don’t Have Car Listings to Publish.":
          "You Don’t Have Car Listings to Publish.",
        "Car listings will appear here.": "Car listings will appear here.",
        "Go to Listing Requests": "Go to Listing Requests",
        ///////////////////////////
        "Coming soon": "Coming soon",
        "We are currently working on it!": "We are currently working on it!",
        "Oops!": "Oops!",
        "Something went wrong, please try again later.":
          "Something went wrong, please try again later.",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds",
        ago: "ago",
        ///////////////////////////////
        "What’s Next?": "What’s Next?",
        "Publish the vehicle if it’s ready!":
          "Publish the vehicle if it’s ready!",
        "All Results": "All Results",
        "Inspection Report Summary": "Inspection Report Summary",
        "Markabte Score": "Markabte Score",
        "Inspected On": "Inspected On",
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "May",
        Jun: "Jun",
        Jul: "July",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Oct",
        Nov: "Nov",
        Dec: "Dec",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        "(3rd Party)": "(3rd Party)",
        "(Markabte)": "(Markabte)",
        faults: "faults",
        Faults: "Faults",
        "Show more": "Show more",
        "Show less": "Show less",
        "Visual Inspection Report": "Visual Inspection Report",
        "Tap to view vehicle cosmetic issues.":
          "Tap to view vehicle cosmetic issues.",
        Note: "Note",
        "Full Inspection Report": "Full Inspection Report",
        "View Faults Only": "View Faults Only",
        "Click on the colored markers to see issue details.":
          "Click on the colored markers to see issue details.",
        "No Faults": "No Faults",
        System: "System",
        Description: "Description",
        "Search for more information": "Search for more information",
        "Good/ Good+Issues": "Good/ Good+Issues",
        Issues: "Issues",
        good: "good",
        "No Issues": "No Issues",
        "Report Image": "Report Image",
        Good: "Good",
        "Exterior Issues": "Exterior Issues",
        "Interior Issues": "Interior Issues",
        "Driver Angle": "Driver Angle",
        "Passenger Angle": "Passenger Angle",
        "This Angle has no Minor issue": "This Angle has no Minor issue",
        "This Angle has no Major issue": "This Angle has no Major issue",
        "You Don’t Have Cars in Your Account.":
          "You Don’t Have Cars in Your Account.",
        "Request officers to list cars under your account.":
          "Request officers to list cars under your account.",
        "Faults | ": "Faults | ",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new",
        Moderate: "Moderate",
        Poor: "Poor",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.",
        "View Image": "View Image",
        "Are you sure that you want to claim this request":
          "Are you sure that you want to claim this request",
        "Phone Number": "Phone Number",
        Previous: "Previous",
        "Click to view issues": "Click to view issues",
        Next: "Next",
        "Visual Report": "Visual Report",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* We do not record any issue in the body that is less than 0.2 (cm).",
        "* In some cases, Re-painted panels cannot be detected.":
          "* In some cases, Re-painted panels cannot be detected.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* We do not record any scratch that cannot be felt by hand.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* We do not inspect whether the battery was replaced or repaired.",
        "* We do not check if the battery was replaced or repaired.":
          "* We do not check if the battery was replaced or repaired.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* We do not inspect the inside of the tire or the internal sidewall of the tire.",
        "* We do not confirm the genuineness of the equipment.":
          "* We do not confirm the genuineness of the equipment.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).",
        "* We do not validate the results of an inspection report from any third party.":
          "* We do not validate the results of an inspection report from any third party.",
        /////////////////////////////
        "We test the battery under load by evaluating cell resistance & voltage values.":
          "We test the battery under load by evaluating cell resistance & voltage values.",
        "No Notes": "No Notes",
        Notes: "Notes",
        "Replace Battery": "Replace Battery",
        "Not Inspected due to influence of resistance values":
          "Not Inspected due to influence of resistance values",

        "upload pdf File to be converted": "upload pdf File to be converted",
        "file is ready to be uploaded": "file is ready to be uploaded",
        "file is not pdf please make sure to upload pdf file":
          "file is not pdf please make sure to upload pdf file",
        "Fill DTC Report": "Fill DTC Report",
        "Re-painted": "Re-painted",
        "Original Paint": "Original Paint",
        "Not measured": "Not measured",
        "Car Views": "Car Views",
        "Click on the views to check the car's condition from a specific view.":
          "Click on the views to check the car's condition from a specific view.",
        "You can view all the issues of the selected car view here.":
          "You can view all the issues of the selected car view here.",
        "issues list": "issues list",
        "Click on the hotspots to see an up-close image of the damage.":
          "Click on the hotspots to see an up-close image of the damage.",
        "Damage Areas": "Damage Areas",
        Next: "Next",
        "Click here for help": "Click here for help",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "Inspection Unavailable, diagnostic tools could not connect to car's computer",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "Voltage difference inconclusive, due to the effect of Abnormal resistance values",
        Exit: "Exit",
        "Click here to view the issue’s list of a certain view.":
          "Click here to view the issue’s list of a certain view.",
        "* Close DZ app on phone": "* Close DZ app on phone",
        "* Disconnect Scanner from car": "* Disconnect Scanner from car",
        " yr. ": " yr. ",
        " mos": " mos",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        PM: "PM",
        AM: "AM",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity",
        "* We do not take images of light scratches on the car's Exterior":
          "* We do not take images of light scratches on the car's Exterior",
        "Independent Seller": "Independent Seller",
        "Car Publish Options": "Car Publish Options",
        "Select who you want to publish the car to":
          "Select who you want to publish the car to",
        "Seller Only": "Seller Only",
        "Buyers & Sellers": "Buyers & Sellers",
        "Car successfully published for seller.":
          "Car successfully published for seller.",
        "Car successfully published for buyers and seller.":
          "Car successfully published for buyers and seller.",
        "Not Included": "Not Included",
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.":
          "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.",
        image: "image",
        Inspected: "Inspected",
        "Hybrid Battery": "Hybrid Battery",
        "(Lithium)": "(Lithium)",
        "Inspected Point": "Inspected Point",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.",
        "Reports Generator": "Reports Generator",
        "all angles": "all angles",
        "with grids": "with grids",
        "without grids": "without grids",
        original: "original",
        papparazzi: "papparazzi",
        "background name": "background name",
        "change background": "change background",
        "initial temperture": "initial temperture",
        "change tempreture": "change tempreture",
        "Save Changes": "Save Changes",
        "edited (paparazzi)": "edited (paparazzi)",
        Images: "Images",
        "Please select the Paparazzi option and a background first":
          "Please select the Paparazzi option and a background first",

        "All Angles": "All Angles",
        Grids: "Grids",
        "With Grids": "With Grids",
        "Without Grids": "Withot Grids",
        "Background Suggest": "Background Suggest",
        "Location Field": "Location",
        Showroom: "Showroom",
        Outside: "Outside",
        "Reflection On Car": "Reflection On Car",
        Trees: "Trees",
        Buildings: "Buildings",
        "No Reflection": "No Reflection",
        "Interior Lights": "Interior Lights",
        Lights: "Lights",
        "No Lights": "No Lights",
        "Suggest Background": "Suggest Background",
        "Background Select": "Background Select",
        Original: "Original",
        Suggested: "Suggested",
        "No Suggestions": "No Suggestions",
        Manual: "Manual",
        "Not Selected": "Not Selected",
        "Select Background": "Select Background",
        "Shadow Select": "Shadow Select",
        "Shadow Location": "Shadow Location",
        "Main Temperature": "Main Temperature",
        "Change Temperature": "Change Temperature",
        "Change Background": "Change Background",
        "Change Temperatue For All Images": "Change Temperatue For All Images",
        "Original Image": "Original Image",
        "Modified Image (Paparazzi)": "Modified Image (Paparazzi)",
        "Error merging or compressing image":
          "Error merging or compressing image",
        "Error submitting images": "Error submitting images",
        "Please select a background type other than Original and select a background first":
          "Please select a background type other than Original and select a background first",
        "Suggested Package": "Suggested Package",
        "Submitting, Please Wait": "Submitting, Please Wait",
        "Images Successfully Changed": "Images Successfully Changed",
        "Checking NoBg Images": "Checking NoBg Images",
      },
    },
    ar: {
      translations: {
        "Car Listings": "قائمة السيارات",
        "Keep me signed in": "ابقني مسجل",
        "Sign In to Your Account": "تسجيل الدخول إلى حسابك",
        "New Password": "كلمة المرور الجديدة",
        "Confirm Password": "تأكيد كلمة المرور",
        "Reset Your Password": "إعادة تعيين كلمة المرور",
        "Please enter your new password.":
          "الرجاء إدخال كلمة المرور الجديدة الخاصة بك.",
        Earnings: "الأرباح",
        Next: "التالي",
        "Phone Number": "رقم الهاتف",
        "Listing Requests": "طلبات الإدراج",
        "Reset Password": "إعادة تعيين كلمة المرور",
        Language: "اللغة (EN)",
        "Sign out": "تسجيل الخروج",
        // Dispatcher: "المرسل",
        // Officer: "موظف",
        "Please enter your email and we will send you a reset link.":
          "الرجاء إدخال البريد الإلكتروني الخاص بك وسنرسل لك رابط إعادة تعيين.",
        "Send Reset Link": "إرسال رابط إعادة التعيين",
        "Car Details": "تفاصيل السيارة",
        "Edit Car": "تحرير السيارة",
        "Add New Car": "إضافة سيارة جديدة",
        "Edit Car Information": "تحرير معلومات السيارة",
        "Submit to qc": " إرسال لمراقبة الجودة ",
        "Submit Form": "تقديم النموذج",
        "Submit & Exit": "إرسال وخروج",
        "Sort By": "صنف حسب",
        Sort: "صنف",
        "Date (Oldest First)": "التاريخ (الأقدم أولاً)",
        "Date (Newest First)": "التاريخ (الأحدث أولاً)",
        "Price (High to Low)": "السعر (من الاعلى الى الأقل)",
        "Price (Low to High)": "السعر (من الأقل الى الاعلى)",
        "Score (High to Low)": "النتيجة (من الأعلى إلى الأقل)",
        "Score (Low to High)": "النتيجة (من الأقل إلى الأعلى)",
        "Odometer (Low to High)": "عداد المسافة (من الأقل إلى الأعلى)",
        "Odometer (High to Low)": "عداد المسافة (من الأعلى إلى الأقل)",
        "Year (Oldest First)": "السنة (الأقدم أولاً)",
        "Year (Newest First)": "السنة (الأحدث أولاً)",
        Date: "التاريخ",
        Price: "السعر",
        Score: "النتيجة",
        Milage: "الأميال",
        Year: "السنة",
        "(Oldest First)": "(الأقدم أولاً)",
        "(Newest First)": "(الأحدث أولاً)",
        "(Low to High)": "(من الأعلى إلى الأقل)",
        "(High to Low)": "(من الأعلى إلى الأقل)",
        "Condition Score": "نتيجة الحالة",
        Results: "نتيجة",
        Odometer: "عداد المسافة",
        Location: "موقع التاجر",
        "Location ": "الموقع",
        "Continue Listing": "متابعة الإدخال",
        "Claim Request": "حجز الطلب",
        "Seller Name": "اسم البائع",
        "Anonymous Dealer": "تاجر مجهول",
        GPS: "GPS",
        "Listing Number": "رقم الطلب",
        "Time Passed": "وقت مضى",
        Action: "الإجراء",
        "You Have No Incoming Requests!": "لا يوجد لديك اي طلبات واردة",
        "Stay tuned, dealers will send requests at anytime.":
          "ابق على اطلاع, سيقوم التجار بإرسال الطلبات في أي وقت.",
        "You Have No Claimed Requests!": "لا يوجد لديك أي طلبات محجوزة",
        "When you claim a request, you’ll find it here.":
          "عندما تقوم بحجز طلب, ستجده هنا.",
        "Are you sure that you want to claim this request":
          "هل انت متأكد من الرغبة في حجز هذا طلب",
        "Vehicle Details": "تفاصيل السيارة",
        "Vehicle Details Page": "صفحة تفاصيل السيارة",
        "Vehicle Features": "ميزات السيارة",
        "Vehicle Information": "معلومات السيارة",
        "Vehicle Condition": "حالة المركبة",
        "No Features available for this car!":
          "لا توجد ميزات متاحة لهذه السيارة!",
        "Markabte Inspection Score": "تقييم مركبتي",
        "View Full Report": "عرض التقرير الكامل",
        "Edit Car": "تعديل السيارة",
        Listing: "قائمة",
        "Forgot Your Password?": "هل نسيت كلمة المرور؟",
        "Forgot your password?": "هل نسيت كلمة المرور؟",
        Connect: "تواصل",
        "with serious car buyers.": "مع مشتري السيارات الجادين.",
        "Back to Sign In": "تسجيل الدخول",
        "Keep me signed in": "ابقني مسجل",
        "out of": "من",
        "Inspection Report": "تقرير التفتيش",
        "Show full report": "إظهار التقرير الكامل",
        "Markabte Inspection Details": "تفاصيل فحص مركبتي",
        "Inspection Date": "تاريخ الفحص :",
        "Inspected By": "تم الفحص بواسطة :",
        "Inspection Points": "نقطة تفتيش",
        "Overall Inspection Score": "نتيجة الفحص العامة",
        "Detailed Inspection Points": "نقاط الفحص التفصيلية",
        "Show inspection points with faults only":
          "إظهار نقاط الفحص ذات العيوب فقط",
        "Markabte Officer Comments": "ملاحظات موظف مركبتي",
        "No comments": "لا يوجد ملاحظات",
        "Back To Vehicle Detail Page": "العودة الى صفحة تفاصيل المركبة",
        "Based on our FREE ": "بناءا على",
        "+ point condition report": "+ نقطة في فحصنا المجاني لحالة المركبة",

        "Incoming Requests": "قائمة الطلبات الواردة  ",
        Incoming: "الوارد  ",
        "Claimed Requests": "قائمة طلباتي",
        Claimed: "طلباتي",
        submitted: " قدمت : منذ",
        "hour ago": "ساعة مضت",
        Claim: "حجز الطلب",
        // continue: " ... متابعة الادخال",
        continue: "متابعة الادخال",
        "List Vehicle": "بدء الادخال",
        "List Car": "بدء الادخال",
        Listed: "ادرجت : منذ",
        "days ago": "ايام مضت",
        Km: "كم",
        Mile: "ميل",
        JD: "د.ا",
        "english JD": "",
        "arabic JD": " د.ا",
        "/ Month .": "/ شهر .",
        " yrs . JD": "سنوات . ",
        "K down": "ألف دينار دفعة أولى",
        "Edit Content": "تعديل المحتوى",
        "Publish Vehicle": "نشر المركبة",
        "Publish Vehicle?": "نشر المركبة؟",
        "Are you sure that you want to publish the vehicle listing?":
          "هل أنت متأكد أنك تريد نشر المركبة؟",
        Publish: "نشر",
        Cancel: "إلغاء",
        of: "من",
        "View Exterior": "مظهر خارجى",
        "View Interior": "مظهر داخلي",
        "Driver Front": "من الامام",
        "Passenger Rear": "من الخلف",
        "Front Angle": "الزاوية الأمامية",
        "Rear Angle": "الزاوية الخلفية",
        Driver: "السائق",
        "Driver View": "السائق",
        Passenger: "الراكب",
        "Passenger View": "الراكب",
        Front: "منظور السائق",
        Back: "منظور الراكب",
        "3rd row": "الصف الثالت",
        "3rd Row": "الصف الثالت",
        Trunk: "الصندوق",
        "Trunk View": "الصندوق",
        "View List": "عرض القائمة",
        "Sign In": "تسجيل الدخول",
        "Sign in": "تسجيل الدخول",
        Email: "البريد الإلكتروني",
        Password: "كلمه السر",
        "forgot password": "هل نسيت كلمة المرور؟",
        "Hurray, No issues were found": "مرحى ، لم يتم العثور على مشكلات!",
        Exterior: "خارجي",
        Interior: "داخلي",
        EXTERIOR: "خارجي",
        INTERIOR: "داخلي",
        "Drive Front View": "منظر أمامي للسائق",
        "Passenger Rear View": "الرؤية الخلفية للراكب",
        "3rd Row View": "عرض الصف الثالث",
        "Rear View": "رؤية خلفية",
        "Front View": "منظر أمامي",
        Minor: "ثانوي",
        Major: "رئيسي",
        "Minor Issues": "مشاكل صغيرة",
        "Major Issues": "مشاكل كبيرة",
        "What do you want to do next?": "ماذا تريد ان تفعل لاحقا؟",
        "Vehicle Condition Report": "تقرير حالة المركبة",
        "Markabte Condition Report": "تقرير حالة مركبتي",
        "Markabte Officer": "موظف مركبتي",
        "Inspection On": "تاريخ التفتيش",
        "Report Summary": "ملخص التقرير",
        "Vehicle Visual Condition": "الحالة البصرية للمركبة",
        "Landscape Mode Is Recommended For The Best User Experience!":
          "يوصى باستخدام الوضع الأفقي للحصول على أفضل تجربة للمستخدم!",
        "View Visual Cosmetic Report": "معاينة التقرير المرئي",
        "Markabte Full Condition Report": "تقرير مركبتي الكامل للحالة",
        Disclaimer: "إخلاء مسؤولية",
        "You are successfully connected to live changes":
          "تم الاتصال بنجاح بالتغييرات الحية",
        "Please make sure you entered valid credentials":
          "الرجاء التأكد من إدخال بيانات اعتماد صالحة",
        "Your passwords do not match!": "كلمات السر الخاصة بك لا تتطابق!",
        "account activated! please login with your new password":
          "تم تفعيل الحساب! الرجاء تسجيل الدخول باستخدام كلمة المرور الجديدة",
        error: "خطأ",
        "Your password reset reqest has been sent to your email.":
          "تم إرسال طلب إعادة تعيين كلمة المرور إلى بريدك الإلكتروني.",
        "Please make sure you entered a valid email address":
          "يرجى التأكد من إدخال عنوان بريد إلكتروني صالح",
        "Car sale status successfully updated":
          "تم تحديث حالة بيع السيارة بنجاح",
        "Oops! an error has occured! please try again.":
          "عفوًا! حدث خطأ! يرجى المحاولة مرة أخرى.",
        "Your changes have been saved": "تم حفظ التغييرات",
        "There was an error sending your request please try again":
          "حدث خطأ أثناء إرسال طلبك ، يرجى المحاولة مرة أخرى",
        "Answers missing visualization point allocation":
          "تفتقر الإجابات إلى تخصيص نقطة التصور",
        "Car successfully published": "تم نشر السيارة بنجاح",
        "Claimed successfully": "تمت المطالبة بنجاح",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الملاحظات الخاصة بالهيكل الخارجي للمركبة",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "نقوم بقياس و تحليل قراءات سماكة الطلاء الخاصة بالمركبة لتحديد اللوحات المدهونة من اللوحات ذات الطلاء الأصلي",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "نقوم بقياس درجة حرارة نظام التكييف / التدفئة ونتحقق من تحكم الهواء لجميع فتحات التكييف.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الملاحظات الخاصة بالمقصورة الداخلية للمركبة",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "نختبر وظائف أجزاء التحكم المتعددة بالمركبة (أزرار، مفاتيح، أنظمة) لتحديد ما يعمل و ما لا يعمل",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "نقوم بإجراء فحص خاص بكمبيوتر المركبة لتشخيص وتحديد رموز الأعطال الخاصة بالأنظمة الميكانيكية والإلكترونية للمركبة",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "تقرير فحص شاصي المركبة مقدم من جهة فحص أخرى٬ يرجى ملاحظة تاريخ الفحص",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "نحدد الحالة العامة للإطار بناءً على عمق الفرزة المتبقي٬ العمر٬ ونتائج الفحص النظري للإطار (تشققات أو فقاعات)",
        "We check the availability of standard equipment in the vehicle":
          "نقوم بالتحقق من توافر المعدات الأساسية في المركبة",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "تحتاج الإطارات إلى تبديل إذا كان العمر >5 سنوات٬ عمق الفرزة المتبقي <1.6 مم أو عند تواجد فقاعات أو تشققات.",
        Excellent: "ممتاز",
        "Minor Issue": "ملاحظة بسيطة",
        "Major Issue": "ملاحظة كبيرة",
        "Origional Paint": "الطلاء الأصلي",
        Repainted: "مدهون",
        Working: "يعمل",
        "Not Working": "لا يعمل",
        "Additional Information": "معلومات إضافية",
        "No codes": "لا أعطال",
        "Minor issues": "أعطال بسيطة",
        "Major issues": "أعطال كبيرة",
        Good: "جيد",
        Monitor: "مراقبة",
        Replace: "تبديل",
        Available: "متوفر",
        "Not Available": "غير متوفر",
        "Welcome Back!": "مرحبا بعودتك!",
        INSPECT: "افحص",
        "visual report": "الحالة المرئية",
        "the easy way,": "المركبات و حقق",
        EARN: "دخلك",
        "money your way.": "على طريقتك مع مركبتي",
        "There where no Major issues spotted in this view!":
          "لا يوجد مشاكل كبيرة من هذا المنظور",
        "There where no Minor issues spotted in this view!":
          "لا يوجد مشاكل صغيرة من هذا المنظور",
        "This Angle has no Minor issue": "لا يوجد مشاكل صغيرة من هذا المنظور",
        "This Angle has no Major issue": "لا يوجد مشاكل كبيرة من هذا المنظور",
        amman: "عمان",
        aqaba: "العقبة",
        jerash: "جرش",
        irbid: "اربد",
        madaba: "مادبا",
        zarqa: "الزرقاء",
        kerak: "الكرك",
        mafraq: "المفرق",
        ajloun: "عجلون",
        salt: "السلط",
        "ma'an": "معان",
        "at-tafilah": "الطفيلة",
        Dispatcher: "موزع",
        Officer: "ضابط",
        "Angle Issues List": "قائمة مشاكل الزاوية",
        Options: "خيارات",
        "Deleting this image would remove all cosmetic hotspots from the previously uploaded image":
          "حذف هذه الصورة سوف يؤدي إلى حذف جميع نقاط الملاحظات على الصورة المدخلة مسبقًا",
        Delete: "حذف",
        "Warning!": "تنبيه!",
        "Please check the visualisation points for the following forms":
          "يرجى التحقق من نقاط التصور للنماذج التالية",
        "Please check and resubmitt highlighted forms":
          "الرجاء مراجعة النماذج المعلمة وإعادة إرسالها",
        "Changing this answer deletes all hotspot paint readings!":
          "تغيير هذه الإجابة سيؤدي إلى حذف جميع قراءات الطلاء المدخلة مسبقًا!",
        Confirm: "تأكيد",
        "Don't Change": "عدم تغيير",
        System: "النظام",
        "System Group": "تصنيف النظام",
        "No.Of Faults": "عدد الأعطال",
        Fault: "العطل",
        Description: "الشرح",
        State: "الحال",
        "Add A New System": "اضافة نظام جديد",
        "System Name": "اسم النظام",
        "Fault Code": "رمز العطل",
        description: "الشرح",
        state: "الحالة",
        "Delete Code": "حذف الرمز",
        "Delete Fault": "حذف العطل",
        "Go To Homepage": "الانتقال إلى الصفحة الرئيسية", /// i think remove
        "We couldn’t find the page you are looking for.":
          "لم نتمكن من العثور على الصفحة التي تبحث عنها.",
        "You Don’t Have Car Listings to Publish.": "لا يوجد لديك مركبات للنشر.",
        "Car listings will appear here.": "قوائم المركبات ستظهر هنا.",
        "Go to Listing Requests": "الذهاب إلى قائمة الطلبات",
        ///////////////////////////
        "Coming soon": "قريباً",
        "We are currently working on it!": "ﻧﻘﻮم ﺑﺎﻟﻌﻤﻞ ﻋﻠﻴﻪ ﻓﻲ اﻟﻮﻗﺖ اﻟﺤﺎﻟﻲ!",
        "Oops!": "عذراً!",
        "Something went wrong, please try again later.":
          "حدث خطأ، يرجى المحاولة فى وقت لاحق.",
        days: "أيام",
        hours: "ساعات",
        minutes: "دقائق",
        seconds: "ثواني",
        ago: "",
        ///////////////////////////////
        "What’s Next?": "ما التالي؟",
        "Publish the vehicle if it’s ready!": "انشر المركبة إذا كانت جاهزة!",
        "All Results": "جميع النتائج",
        "Inspection Report Summary": "تقرير مركبتي لحالة السيارة",
        "Markabte Score": "نتيجة مركبتي",
        "Inspected On": "تاريخ الفحص",
        Jan: "يناير",
        Feb: "فبراير",
        Mar: "مارس",
        Apr: "أبريل",
        May: "مايو",
        Jun: "يونيو",
        Jul: "يوليو",
        Aug: "أغسطس",
        Sep: "سبتمبر",
        Oct: "أكتوبر",
        Nov: "نوفمبر",
        Dec: "ديسمبر",
        January: "يناير",
        February: "فبراير",
        March: "مارس",
        April: "أبريل",
        May: "مايو",
        June: "يونيو",
        July: "يوليو",
        August: "أغسطس",
        September: "سبتمبر",
        October: "أكتوبر",
        November: "نوفمبر",
        December: "ديسمبر",
        "(3rd Party)": "(جهة فحص أخرى)",
        "(Markabte)": "(مركبتي)",
        faults: "أعطال",
        Faults: "أعطال",
        "Show more": "أظهر المزيد",
        "Show less": "عرض أقل",
        "Visual Inspection Report": "تقرير الفحص المفصل",
        "Tap to view vehicle cosmetic issues.": ".انقر لعرض مشاكل المركبة",
        Note: "ملاحظة",
        "Full Inspection Report": "التقرير الكامل لحالة المركبة",
        "View Faults Only": "عرض المشاكل فقط",
        "Click on the colored markers to see issue details.":
          "اضغط على العلامات الملوّنة لرؤية تفاصيل الملاحظة",
        "No Faults": "لا أعطال",
        System: "النظام",
        Description: "الوصف",
        "Search for more information": "ابحث عن مزيد من المعلومات",
        "Good/ Good+Issues": "جيد/ جيد+ملاحظات",
        Issues: "ملاحظات",
        good: "جيد",
        "No Issues": "لا توجد ملاحظات",
        "Report Image": "صورة التقرير",
        Good: "جيد",
        coco: "coco",
        "Exterior Issues": "المشاكل الخارجية",
        "Interior Issues": "المشاكل الداخلية",
        "Driver Angle": "زاوية السائق",
        "Passenger Angle": "زاوية الراكب",
        "This Angle has no Minor issue":
          "لا يوجد اي ملاحظات صغيرة في هذه الزاوية",
        "This Angle has no Major issue":
          "لا يوجد اي ملاحظات كبيرة في هذه الزاوية",
        "Faults | ": "أعطال | ",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "ﻳﻘﺪر اﻟﻌﻤﺮ اﻟﻤﺘﺒﻘﻲ ﻟﻠﺒﻄﺎرﻳﺔ ﻣﻦ ﺧﻠﺎل ﻣﻘﺎرﻧﺔ ﺳﻌﺘﻬﺎ اﻟﺤﺎﻟﻴﺔ ﺑﺴﻌﺘﻬﺎاﻟﻤﺜﺎﻟﻴﺔ ﻋﻨﺪﻣﺎ ﺗﻜﻮن ﺟﺪﻳﺪة",
        Moderate: "متوسط",
        Poor: "ضعيف",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "ﻣﺆﺷﺮ ﻳﻌﻜﺲ ﺣﺎﻟﺔ اﻟﺒﻄﺎرﻳﺔ اﻟﻌﺎﻣﺔ و ﻗﺪرﺗﻬﺎ ﻋﻠﻰ ﺗﻘﺪﻳﻢ اﻟﺄداء اﻟﻤﻄﻠﻮب ﻣﻘﺎرﻧﺔ ﺑﺒﻄﺎرﻳﺔ ﺟﺪﻳﺪة.",
        "View Image": "رؤﻳﺔ اﻟﺼﻮرة",
        Previous: "السابق",
        "Click to view issues": "أنقر لمعاينة الملاحظات",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* لا نسجل أي ضرر على هيكل المركبة لا يتجاوز 0.2 (سم) بالمساحة.",
        "* In some cases, Re-painted panels cannot be detected.":
          "* في بعض الحالات لا يمكن اكتشاف الأجزاء المدهونة بالمركبة.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* لا نسجل أي خدش لا يمكن الإحساس به باليد.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not check if the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* لا نفحص الإطار من الداخل أو الجزء الداخلي من الحائط الجانبي للإطار.",
        "* We do not confirm the genuineness of the equipment.":
          "* لا نحدد إذا كانت المعدات اصلية.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* لا نسجل رموز الأعطال التي تم حفظها في كمبيوتر المركبة و لكنها غير فعالة (تم إصلاحها).",
        "* We do not validate the results of an inspection report from any third party.":
          "* لا نتحقق من صحة نتائج أي فحص من جهة أخرى.",
        /////////////////////////////
        "We test the battery under load by evaluating cell resistance & voltage values.":
          "ﻧﻘﻮم ﺑﺎﺧﺘﺒﺎر اﻟﺒﻄﺎرﻳﺔ ﺗﺤﺖ اﻟﺘﺤﻤﻞ ﻣﻦ ﺧﻠﺎل ﺗﻘﻴﻴﻢ ﻗﻴﻢ اﻟﻤﻘﺎوﻣﺔ واﻟﻔﻮﻟﺘﻴﺔ ﻟﻠﺨﻠﺎﻳﺎ.",
        "No Notes": "ﻟﺎ ﻣﻠﺎﺣﻈﺎت",
        Notes: "ملاحظات",
        "Replace Battery": "ﺗﺒﺪﻳﻞ اﻟﺒﻄﺎرﻳﺔ",
        "Not Inspected due to influence of resistance values":
          "ﻟﻢ ﻳﺘﻢ اﻟﻔﺤﺺ ﺑﺴﺒﺐ ﺗﺄﺛﺮ ﻗﻴﻢ اﻟﻤﻘﺎوﻣﺔ",

        "upload pdf File to be converted": "قم بتحميل ملف pdf ليتم تحويله",
        "file is ready to be uploaded": "الملف جاهز للتحميل",
        "file is not pdf please make sure to upload pdf file":
          "صيغة الملف خاطئة يرجى التأكد من تحميل ملف pdf",
        "Fill DTC Report": "ملء تقرير فحص الكمبيوتر",
        "Re-painted": "أعيد طلاؤها",
        "Original Paint": "الطلاء الأصلي",
        "Not measured": "لم يتم قياسها",
        "Car Views": "ﺟﻬﺎت اﻟﻤﺮﻛﺒﺔ",
        "Click on the views to check the car's condition from a specific view.":
          "اضغط على الأزرار  لتفقد حالة حالة المركبة من جهة معينة",
        "You can view all the issues of the selected car view here.":
          "ﻳﻤﻜﻨﻚ اﻟﺈﻃﻠﺎع ﻋﻠﻰ ﺟﻤﻴﻊ اﻟﺄﺿﺮار ﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة ﻫﻨﺎ.",
        "issues list": "ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار",
        "Click on the hotspots to see an up-close image of the damage.":
          "اﺿﻐﻂ ﻋﻠﻰ اﻟﻌﻠﺎﻣﺎت اﻟﻤﻠﻮﻧﺔ ﻟﺮؤﻳﺔ ﺻﻮرة اﻟﻀﺮرﻋﻦ ﻗﺮب.",
        "Damage Areas": "ﻣﻨﺎﻃﻖ اﻟﻀﺮر",
        Next: "التالي",
        "Click here for help": "اﺿﻐﻂ ﻫﻨﺎ ﻟﻠﻤﺴﺎﻋﺪة",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "الفحص غير متوفر ، أجهزة الفحص لم تتمكن من قراءة كمبيوتر المركبة",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "دورة القيادة غير كافية ، يجب قيادة السيارة لمسافة 70 كم وإعادة فحصها للحصول على نتائج موثوقة",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "فرق الجهد غير حاسم ، بسبب تأثير قيم المقاومة غير الطبيعية",
        Exit: "الخروج",
        "Click here to view the issue’s list of a certain view.":
          ".اﺿﻐﻂ ﻫﻨﺎ ﻟﻌﺮض ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار ﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة",
        "* Close DZ app on phone": "* أغلق تطبيق DZ من الهاتف",
        "* Disconnect Scanner from car": "* إفصل جهاز الفحص عن المركبة",
        " yr. ": " سنة ",
        " mos": " شهر",
        Sunday: "الأحد",
        Monday: "الاثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الأربعاء",
        Thursday: "الخميس",
        Friday: "الجمعة",
        Saturday: "السبت",
        PM: "مساءً",
        AM: "صباحاً",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "(* التقرير النظري يشمل الخدوش من الدرجة (العميقة) فقط و ليس الدرجة (الخفيفة",
        "* We do not take images of light scratches on the car's Exterior":
          "* لا نقوم بإرفاق صور للخدوش (الخفيفة) المدرجة بفحص الهيكل الخارجي ",
        "Independent Seller": "بائع مستقل",
        "Car Publish Options": "خيارات نشر المركبة",
        "Select who you want to publish the car to":
          "إختر لمن تريد نشر المركبة",
        "Seller Only": "البائع فقط",
        "Buyers & Sellers": "المشتريين والبائع",
        "Car successfully published for seller.":
          "تم نشر المركبة للبائع بنجاح.",
        "Car successfully published for buyers and seller.":
          "تم نشر المركبة للمشتريين والبائع بنجاح.",
        "Not Included": "غير مشمول",
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.":
          "نقوم بإجراء فحص خاص لكمبيوتر المركبة لتشخيص و تحديد رموز الأعطال الخاصة ببطارية الهايبرد.",
        image: "صورة",
        Inspected: "مفحوصة",
        "Hybrid Battery": "بطارية الهايبرد",
        "(Lithium)": "(ليثيوم)",
        "Inspected Point": "نقطة فحص",
        "* The car's safety and security systems are inspected using the latest and most advanced equipment, but the safety and security system report does not include examining the interior panels, and some cases of airbag tampering may not appear in the report.":
          "* يتم معاينة أنظمة السلامة و الأمان للمركبة بواسطة أحدث الأجهزة و أكثرها تطوراً إلا أن تقرير نظام السلامة و الأمان لا يشمل فحص البطانات و قد لا يظهر في التقرير بعض حالات التلاعب بالوسائد الهوائية.",
        "Reports Generator": "انشاء تقرير",
        "all angles": "جميع الزوايا",
        "with grids": "مع Grids",
        "without grids": "بدون Grids",
        original: "الأصلية",
        papparazzi: "باباراتزي",
        "background name": "اسم الخلفية",
        "change background": "تغيير الخلفية",
        "initial temperture": "الحرارة المبدئية",
        "change tempreture": "تغيير الحرارة",
        "Save Changes": "حفظ التغييرات",
        "edited (paparazzi)": "الصورة المعدلة (باباراتزي)",
        Images: "الصور",
        "Please select the Paparazzi option and a background first":
          "الرجاء اختيار باباراتزي و تحديد خلفية أولاً",

        "All Angles": "جميع الزوايا",
        Grids: "الاطارات",
        "With Grids": "مع اطارات",
        "Without Grids": "بدون اطارات",
        "Background Suggest": "اقتراح الخلفية",
        "Location Field": "الموقع",
        Showroom: "صالة عرض",
        Outside: "الخارج",
        "Reflection On Car": "الانعكاسات على السيارة",
        Trees: "أشجار",
        Buildings: "مباني",
        "No Reflection": "لا انعكاسات",
        "Interior Lights": "اﻷضواء الداخلية",
        Lights: "مضاء",
        "No Lights": "غير مضاء",
        "Suggest Background": "اقترح خلفية",
        "Background Select": "اختيار الخلفية",
        Original: "الصور الأصلية",
        Suggested: "الصور المقترحة",
        "No Suggestions": "لا اقتراحات",
        Manual: "الصور اليدوية",
        "Not Selected": "غير مختارة",
        "Select Background": "اختر خلفية",
        "Shadow Select": "اختيار الظل",
        "Shadow Location": "موقع الظل",
        "Main Temperature": "الحرارة المبدئية",
        "Change Temperature": "تغيير الحرارة",
        "Change Background": "تغيير الخلفية",
        "Change Temperatue For All Images": "تغيير الحرارة لجميع الصور",
        "Original Image": "الصورة الأصلية",
        "Modified Image (Paparazzi)": "الصورة المعدلة (باباراتزي)",
        "Error merging or compressing image": "خطأ في دمج أو ضغط الصورة",
        "Error submitting images": "خطأ في حفظ الصور",
        "Please select a background type other than Original and select a background first":
          "الرجاء تحديد نوع خلفية غير الخلفية الأصلية وتحديد الخلفية أولاً",
        "Suggested Package": "الخلفية المقترحة",
        "Submitting, Please Wait": "جار الحفظ, يرجى الانتظار",
        "Images Successfully Changed": "يم تعديل الصور بنجاح",
        "Checking NoBg Images": "جار التأكد من الصور بدون خلفية",
      },
    },
  },
  fallbackLng: localStorage.getItem("language") ?? "ar",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
