import React from "react";

const FeatureIcon5 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M16 12H49V27H6V22.5L16 12Z" fill="#07303E" fill-opacity="0.75"/>
<path d="M49.1667 6.25H13.3333C12.5833 6.25 11.8333 6.5833 11.3333 7.1667L0.75 18.4167C0.25 18.9167 0 19.6667 0 20.3334V27.0834V42.9167C0 43.4167 0.333301 43.75 0.833301 43.75H49.1666C49.6666 43.75 49.9999 43.4167 49.9999 42.9167V27.0833V12.0833V7.0833C50 6.5833 49.6667 6.25 49.1667 6.25ZM48.3333 26.25H6.6667V23.3333C6.6667 23.0833 6.75 22.75 7 22.5833L15.6667 13.25C15.9167 13.0833 16.1667 12.9167 16.5 12.9167H48.3333V26.25ZM1.6667 20.3333C1.6667 20.0833 1.75 19.8333 2 19.5833L12.5 8.25C12.75 8.0833 13 7.9167 13.3333 7.9167H48.3333V11.25H16.5C15.6667 11.25 14.9167 11.5833 14.4167 12.1667L5.75 21.4167C5.25 21.9167 5 22.6667 5 23.3334V26.25H1.6667V20.3333ZM48.3333 42.0833H1.6667V27.9167H5.8334H48.3334V42.0833H48.3333Z" fill="#07303E"/>
<rect x="3" y="33" width="5" height="2" rx="1" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon5;
