import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { validateOTP, sendOTP } from "../../store/actions/auth";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, Input, Slide, TextField, Typography } from "@material-ui/core";
import CountDownTimer from "./CountDownTimer";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  helperText: {
    marginBottom: "131.4px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "83.4px",
      fontSize: "14px",
    },
  },
  inputGridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputGriditem: { flexBasis: "22%", minWidth: "22%", flexGrow: 0 },
  textField: {
    caretColor: "#186985",
    // textAlign: "center",
    // fontSize: "37px",
    // fontWeight: "500",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "30px",
    // },
  },
  textFieldInput: {
    color: "#07303E",
    textAlign: "center",
    fontSize: "37px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  underlinesDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  underline: {
    flexBasis: "22%",
    minWidth: "22%",
    flexGrow: 0,
    borderBottom: "1px solid black",
    textAlign: "center",
    fontSize: "37px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  filledUnderline: {
    borderBottom: "2px solid #186985",
  },
  errorTypographyDiv: {
    overflow: "hidden",
    marginTop: "12px",
    minHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "11px",
      minHeight: "21px",
    },
  },
  errorTypography: {
    fontSize: "16px",
    color: "#FF0000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  verifyButton: {
    textTransform: "none",
    // margin: "66px 0 70px 0",
    margin: "30px 0 70px 0",
    fontSize: "18px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    borderRadius: "8.67px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      // margin: "66px 0 28px 0",
      margin: "37px 0 28px 0",
      borderRadius: "5px", //check
    },
  },
  typographyDivs: {
    display: "flex",
  },
  firstTypography: {
    display: "flex",
    flexWrap: "wrap",
    color: "#07303E",
    fontSize: "16px",
    whiteSpace: "break-spaces",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  secondTypography: {
    display: "contents",
    color: "#186985",
    fontSize: "16px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  resendCodeButton: {
    padding: 0,
    textTransform: "none",
    height: "max-content",
  },
  resendCodeTypography: {
    fontSize: "16px",
    fontWeight: localStorage.getItem("language") === "en" ? "500" : "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  container: {
    width: "32vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const OTPVerification = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    phoneNumber,
    editMobileNumber,
    handleOTPVerified,
    validateOTP,
    sendOTP,
    // history,
  } = props;

  const [timer, setTimer] = useState(false);
  const [cleared, setCleared] = useState(false);
  const [otpCode, setOtpCode] = useState(["", "", "", ""]);
  const [pasteCode, setPasteCode] = useState([]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [reset, setReset] = useState(0);
  const [codeError, setCodeError] = useState(false);
  const [errorText, setErrorText] = useState("Error");
  // const [codeError, setCodeError] = useState(false);
  // const [errorText, setErrorText] = useState(false);

  const history = useHistory();
  const firstCharRef = useRef();
  const secondCharRef = useRef();
  const thirdCharRef = useRef();
  const fourthCharRef = useRef();
  const ButtonRef = useRef();
  const refsArr = [
    firstCharRef,
    secondCharRef,
    thirdCharRef,
    fourthCharRef,
    ButtonRef,
  ];

  useEffect(() => {
    refsArr.map((inpt, index) => {
      if (inpt.current === document.activeElement) {
        if (otpCode[index] !== "" && !cleared && index !== refsArr.length - 1) {
          refsArr[index + 1].current.focus(); // i can add document.activeElement = null if index condition is false
        } else {
          if (cleared) {
            (refsArr[index - 1] ?? refsArr[0]).current.focus();
            setCleared(false);
          }
        }
      }
    });
  }, [otpCode]);

  useEffect(() => {
    if (pasteCode[1]) {
      setOtpCode([...pasteCode[0]]);
      setPasteCode([]);
    }
  }, [pasteCode]);

  const handleInputClear = (event) => {
    var key = event.keyCode || event.charCode;
    if (key == 8) {
      if (event.target.id !== "0") {
        setCleared(true);
      }
      let index = event.target.id;
      let otpTemp = [...otpCode];
      otpTemp.splice(index, 1, "");
      setOtpCode([...otpTemp]);
    }
  };

  const inputLength = (e) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 1);
  };

  const otpArrChange = (event) => {
    if (!cleared) {
      let index = event.target.id;
      let otpTemp = [...otpCode];
      otpTemp.splice(index, 1, event.target.value);
      setOtpCode(otpTemp);
    }
  };

  const handlePaste = (event) => {
    let pastedData = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    const regExp = new RegExp(/^[0-9]{0,4}$/);
    if ((pastedData, pastedData.search(regExp) > -1)) {
      setPasteCode([
        [pastedData[0], pastedData[1], pastedData[2], pastedData[3]],
        true,
      ]);
    }
  };

  const handlePreventPasteDrop = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.helperText}>
        {t("Please enter the verification code sent to ")}
        {phoneNumber}
      </Typography>
      <div className={classes.inputGridContainer} style={{ direction: "ltr" }}>
        <div className={classes.inputGriditem}>
          <TextField
            id="0"
            inputRef={firstCharRef}
            value={otpCode[0]}
            autoFocus
            error={codeError}
            className={classes.textField}
            onInput={inputLength}
            type="number"
            inputProps={{ className: classes.textFieldInput }}
            onChange={otpArrChange}
            onKeyDown={handleInputClear}
            onPaste={handlePaste}
          />
        </div>
        <div className={classes.inputGriditem}>
          <TextField
            id="1"
            inputRef={secondCharRef}
            value={otpCode[1]}
            disabled={otpCode[0] === "" && !pasteCode[1]}
            error={codeError}
            className={classes.textField}
            onInput={inputLength}
            type="number"
            inputProps={{ className: classes.textFieldInput }}
            onChange={otpArrChange}
            onKeyDown={handleInputClear}
            // to prevent paste and drop in last 3 inputs
            autocomplete="off"
            onPaste={handlePreventPasteDrop}
            onDrop={handlePreventPasteDrop}
            /////////////////////////////////////////////
          />
        </div>
        <div className={classes.inputGriditem}>
          <TextField
            id="2"
            inputRef={thirdCharRef}
            value={otpCode[2]}
            disabled={otpCode[1] === "" && !pasteCode[1]}
            error={codeError}
            className={classes.textField}
            onInput={inputLength}
            type="number"
            inputProps={{ className: classes.textFieldInput }}
            onChange={otpArrChange}
            onKeyDown={handleInputClear}
            // to prevent paste and drop in last 3 inputs
            autocomplete="off"
            onPaste={handlePreventPasteDrop}
            onDrop={handlePreventPasteDrop}
            /////////////////////////////////////////////
          />
        </div>
        <div className={classes.inputGriditem}>
          <TextField
            id="3"
            inputRef={fourthCharRef}
            value={otpCode[3]}
            disabled={otpCode[2] === "" && !pasteCode[1]}
            error={codeError}
            className={classes.textField}
            onInput={inputLength}
            type="number"
            inputProps={{ className: classes.textFieldInput }}
            onChange={otpArrChange}
            onKeyDown={handleInputClear}
            // to prevent paste and drop in last 3 inputs
            autocomplete="off"
            onPaste={handlePreventPasteDrop}
            onDrop={handlePreventPasteDrop}
            /////////////////////////////////////////////
          />
        </div>
      </div>
      <div className={classes.errorTypographyDiv}>
        <Slide direction="bottom" in={codeError} mountOnEnter unmountOnExit>
          <Typography className={classes.errorTypography}>
            {/* {" "}
            {t("The code you entered is incorrect.Try again")}{" "} */}
            {t(errorText)}
          </Typography>
        </Slide>
      </div>
      <Button
        ref={ButtonRef}
        className={classes.verifyButton}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          validateOTP({
            phone: phoneNumber,
            otp: otpCode[0] + otpCode[1] + otpCode[2] + otpCode[3],
            callback: (response) => {
              if (response.status) {
                handleOTPVerified(
                  otpCode[0] + otpCode[1] + otpCode[2] + otpCode[3]
                );
              } else {
                // probably will never happen
                setCodeError(true);
                setErrorText(response.response);
              }
            },
          });
          // handleSignup(otpCode[0] + otpCode[1] + otpCode[2] + otpCode[3]);
        }}
      >
        {t("Verify and Continue")}
      </Button>
      <div className={classes.typographyDivs}>
        <Typography className={classes.firstTypography}>
          {t("Didn't receive a code?")}
          <Button
            color="primary"
            disabled={resendDisabled} // still has a problem
            className={classes.resendCodeButton}
            onClick={() => {
              sendOTP({
                payload: phoneNumber,
                callback: (response) => {
                  if (response.status) {
                    setResendDisabled(true);
                    setReset((prev) => prev + 1);
                  } else if (response.code === "max") {
                    setResendDisabled(true);
                    setTimer({ m: 15, s: 0 });
                  } else {
                    // add this
                  }
                },
              });
            }}
          >
            <Typography
              className={classes.resendCodeTypography}
              // className={classes.secondTypography}
            >
              {t("Resend code")}
            </Typography>
          </Button>
          <Typography className={classes.firstTypography}>
            {` ${t("in")} `} {/*{timer}*/}
          </Typography>
          <CountDownTimer
            // interval={timer || { m: 0, s: 5 }}// still wrong
            reset={reset}
            maxResets={5}
            className={classes.firstTypography}
            interval={timer || { m: 1, s: 0 }} // still wrong
            doneCallback={setResendDisabled}
          />
        </Typography>
      </div>
      {!props.withoutEdit ? (
        <div
          className={classes.typographyDivs} /*style={{ flexWrap: "wrap" }}*/
        >
          <Typography className={classes.firstTypography}>
            {t("Wrong Mobile number?")}
            <Typography
              className={classes.secondTypography}
              onClick={() =>
                props?.tabs ? props?.setTabs(1) : window.history.back()
              }
            >
              {t("Edit Mobile Number")}
            </Typography>
          </Typography>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

OTPVerification.defaultProps = {
  phoneNumber: "+xxxxxxxxxxxx",
  editMobileNumber: () => {},
};

OTPVerification.propTypes = {
  phoneNumber: PropTypes.string,
  editMobileNumber: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  validateOTP(payload) {
    dispatch(validateOTP(payload));
  },
  sendOTP(payload) {
    dispatch(sendOTP(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OTPVerification)
);
