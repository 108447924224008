import { put, takeLatest, select, call, take } from "redux-saga/effects";
// import { requestAPI, checkHttpStatus } from "../helpers";
import {
  CONNECTION_REQUEST,
  CONNECTION_REQUEST_FAILURE,
  CONNECTION_REQUEST_SUCCEED,
  ADD_CAR,
  REMOVE_CAR,
  ADD_REQUEST,
  REMOVE_REQUEST,
  UPDATE_PAGE_TIME_STAMP,
  UPDATE_PAGE_TIME_STAMP_SUCCEED,
  UPDATE_PAGE_TIME_STAMP_FAILURE,
  FETCH_PAGE_TIME_STAMP,
  FETCH_PAGE_TIME_STAMP_SUCCEED,
  FETCH_PAGE_TIME_STAMP_FAILURE,
  FETCH_UNSEEN_NOTIFICATIONS,
  FETCH_UNSEEN_NOTIFICATIONS_SUCCEED,
  FETCH_UNSEEN_NOTIFICATIONS_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
  FETCH_REFRESH_TOKEN_REQUEST,
} from "../constants";

import { requestAPI } from "../helpers";

import { eventChannel } from "redux-saga";
import API_URLS from "../../api";
import cookies from "js-cookie";
// let wsInitialPath =  "ws://localhost:8000/ws" || process.env.REACT_APP_WS_URL;
let wsInitialPath = "wss://api.markabte.com/ws";
// let wsInitialPath = "ws://localhost:8000/ws";
// let wsInitialPath = "ws://192.168.0.160:8000/ws";
// let wsInitialPath = "ws://192.168.0.125:8000/ws";
// let wsInitialPath = "ws://192.168.1.121:8000/ws";
// let wsInitialPath = "ws://192.168.0.188:8000/ws";
// let wsInitialPath = "ws://192.168.1.72:8000/ws";

function initWebsocket(wsPath) {
  return eventChannel((emitter) => {
    let ws = new WebSocket(
      `${wsInitialPath}/listing_requests/${
        wsPath ||
        JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).user
          .role
      }/${1}/`
    );
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          message: "hey",
        })
      );
    };
    ws.onerror = (error) => {
      console.log("WebSocket error ", error);
      console.dir(error);
      // notify();
      // return emitter({ type: CONNECTION_REQUEST });
    };
    ws.onmessage = (e) => {
      let msg = null;
      try {
        msg = JSON.parse(e.data);
      } catch (e) {
        console.error(`Error parsing : ${e.data}`);
      }
      console.log("errweb", msg);
      if (msg) {
        const action = msg?.action;
        switch (action) {
          case "add_car":
            return emitter({ type: ADD_CAR, payload: msg });
          case "remove_car":
            return emitter({ type: REMOVE_CAR, payload: msg });
          case "add_request":
            return emitter({ type: ADD_REQUEST, payload: msg });
          case "remove_request":
            return emitter({ type: REMOVE_REQUEST, payload: msg });
          default:
          // nothing to do
        }
      }
    };
    ws.onclose = (e) => {
      if (e.code === 1005) {
        console.log("WebSocket: closed");
      } else {
        console.log(
          "Socket is closed Unexpectedly. Reconnect will be attempted in 4 second.",
          e
        );

        setTimeout(() => {
          // notify();
          return emitter({ type: CONNECTION_REQUEST });
        }, 4000);
      }
    };
    // unsubscribe function
    return () => {
      console.log("Socket off");
      // ws.close();
    };
  });
}
export function* requestConnection(payload) {
  try {
    const channel = yield call(initWebsocket, payload.payload);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    yield put({
      type: CONNECTION_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* requestConnectionWatcher() {
  yield takeLatest(CONNECTION_REQUEST, requestConnection);
}

/////////////////////////////////////////////
export function* updatePageTimeStamp(payload) {
  try {
    let data = {
      url: API_URLS(`${payload.payload.user_id}`).NOTIFICATIONS.UPDATE_STAMP,
      method: "POST",
      body: {
        page: payload.payload.page,
      },
    };
    const response = yield requestAPI(data);
    yield put({
      type: UPDATE_PAGE_TIME_STAMP_SUCCEED,
      payload: response.results,
    });
    yield put({
      type: FETCH_REFRESH_TOKEN_REQUEST,
    });
  } catch (e) {
    yield put({ type: UPDATE_PAGE_TIME_STAMP_FAILURE, error: new Error(e) });
  }
}

export function* updatePageTimeStampWatcher() {
  yield takeLatest(UPDATE_PAGE_TIME_STAMP, updatePageTimeStamp);
}

export function* getPageTimeStamps(payload) {
  try {
    let data = {
      url: API_URLS(`${payload.payload.user_id}`).NOTIFICATIONS.GET_STAMPS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_PAGE_TIME_STAMP_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({ type: FETCH_PAGE_TIME_STAMP_FAILURE, error: new Error(e) });
  }
}

export function* getPageTimeStampsWatcher() {
  yield takeLatest(FETCH_PAGE_TIME_STAMP, getPageTimeStamps);
}

export function* getUnseenNotifications(payload) {
  try {
    let data = {
      url: API_URLS(`${payload.payload.user_id}`).NOTIFICATIONS
        .GET_UNSEEN_NOTIFICATIONS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_UNSEEN_NOTIFICATIONS_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: FETCH_UNSEEN_NOTIFICATIONS_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getUnseenNotificationsWatcher() {
  yield takeLatest(FETCH_UNSEEN_NOTIFICATIONS, getUnseenNotifications);
}
