import React, { useState } from "react";
import ControlBar from "./ControlBar";
import { useTranslation } from "react-i18next";
import PapaHubImageContainer from "./PapaHubImageContainer";
import PapaPopup from "./PapaPopup";
import imageMerger from "../../helpers/mergeImages";
import { urlToFile, urlToObject } from "../../helpers/imagesFunctions";
import {
  checkFileExists,
  dataURLtoFile,
  handleCompress,
  handleCompressMiniImage,
  s3Uploader,
} from "../../helpers/aws";
import { connect } from "react-redux";
import { changeBackgrounds } from "../../store/actions/car";
import { toggleNotification } from "../../store/actions/notification";
import removeBg from "../../helpers/removeBg";
import PapaHubBackDrop from "./PapaHubBackDrop";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";

const PapaHub = (props) => {
  const { open, setOpen, carId, changeBackgrounds, toggleNotification,setRefreshExtImagesForm } = props;
  const { t } = useTranslation();

  const [withFrame, setWithFrame] = useState("with");

  const [background, setBackground] = useState(4198);
  const [reflection, setReflection] = useState(4295);
  const [interiorLights, setInteriorLights] = useState(4296);
  const [suggestedPackage, setSuggestedPackage] = useState({
    id: null,
    package_title: "No Suggestions",
  });

  const [backgroundOption, setBackgroundOption] = useState();
  const [selectedPackage, setSelectedPackage] = useState({
    id: null,
    title: "Not Selected",
  });

  const [sunLocation, setSunLocation] = useState(4288);

  const [openMainTemperaturePopup, setOpenMainTemperaturePopup] =
    useState(false);

  const [savedImages, setSavedImages] = useState([]);
  const [shadowImages, setShadowImages] = useState([]);

  // these are states that hold images array of objects extra data are added for defineing new states such as (new image url, selected mood, ...)
  // might have a problem since BE returns objects in different key names check their Sagas then the EndPoint and unify namings (but check every where they are used in PapaHub and its children as well as other places like BgEditor, ....)
  const [backgroundImages, setBackgroundImages] = useState([]); // This is a holder only that holds the value of either selectedBackgroundImages or suggestedBackgroundImages it is used to show all the images
  const [selectedBackgroundImages, setSelectedBackgroundImages] = useState([]); // This is the manual images array used to save the state of manual images on the change of type to original or suggested
  const [suggestedBackgroundImages, setSuggestedBackgroundImages] = useState(
    []
  ); // This is the suggested images array used to save the state of suggested images on the change of type to original or manual

  const [loading, setLoading] = useState({
    open: false,
    message: "Loading Please Wait",
    saving: false,
  });

  useNonInitialEffect(() => {
    const filteredSavedImagesLinks = savedImages
      .map((imageObject) => decodeURIComponent(imageObject.image))
      .filter((imageLink) => imageLink.includes("Origional"));

    Promise.all(
      filteredSavedImagesLinks.map(async (link, index) => {
        setLoading({
          open: true,
          message: "Checking NoBg Images",
          saving: false,
        });
        if (link.includes("Origional")) {
          let noBgLink = link.replace("Origional", "NoBg");
          let endOfUrlIndex = noBgLink?.indexOf(".com/") + 5;
          let noBgPhotoKey = noBgLink?.slice(endOfUrlIndex);

          await checkFileExists(noBgPhotoKey)
            .then((exists) => {
              console.log(
                `EEEEEEEEEEEEEEEEEERR The file ${noBgPhotoKey} with index ${index} ${
                  exists ? "exists" : "does not exist"
                }.`
              );

              if (!exists) {
                // Create NoBg Image
                const createNoBgImage = async (link) => {
                  setLoading({
                    open: true,
                    message: "Creating NoBg Images",
                    saving: false,
                  });
                  let fetchedOrigional = await urlToFile(link);

                  await removeBg(fetchedOrigional)
                    .then(async (removed) => {
                      const base64Response = await fetch(
                        `data:image/png;base64,${removed}`
                      );
                      const blob = await base64Response.blob();
                      await s3Uploader(noBgPhotoKey, blob, () => {});
                    })
                    .catch((e) => {
                      console.log("EEEEEEEEEE RemoveBg Error", e);
                      setLoading({ open: false, message: "", saving: false });
                      toggleNotification({
                        variant: "error",
                        open: true,
                        message: t("Error creating or uploading NoBg images"), // add translation
                      });
                    });
                };

                createNoBgImage(link);
              }
            })
            .catch((error) => {
              console.log("EEEEEEEEEEee CheckFile Error", error);
              setLoading({ open: false, message: "", saving: false });
              toggleNotification({
                variant: "error",
                open: true,
                message: t("Error checking for NoBg images"), // add translation
              });

              console.log(
                `EEEEEEEEEEEEEEEEEERR Error checking if file ${noBgPhotoKey} exists: ${error}`
              );
            });
        }
      })
    ).then(() => {
      setLoading({ open: false, message: "", saving: false }); // all Promises resolved
    });
  }, [savedImages]);

  const handleClose = () => {
    setOpen(false);
  };

  const submitNewImages = async () => {
    setLoading({
      open: true,
      message: "Submitting, Please Wait",
      saving: true,
    });
    if (backgroundOption) {
      let newBEImages = [...savedImages];
      if (backgroundOption !== "original") {
        await Promise.all(
          savedImages.map(async (savedImageObject) => {
            const {
              field_id: fieldId,
              // field_title: fieldTitle,
              image: savedImageName,
            } = savedImageObject;

            let backgroundObject = backgroundImages.filter(
              (bgObj) => bgObj.image_field === fieldId
            )[0];
            const { image_url: bgImage } = backgroundObject;

            let shadowObject = shadowImages.filter(
              (bgObj) => bgObj.image_field === fieldId
            )[0];
            const { image_url: shadowImage } = shadowObject;

            let endOfUrlIndex = savedImageName?.indexOf(".com/") + 5;
            let photoKey = decodeURIComponent(
              // decodeURIComponent was used because savedImageName has encoded name so if not decoded it will encode the encoded name yielding errors %20 is a space when you encode it it becomes %2520 hence not correct image name
              savedImageName?.slice(endOfUrlIndex)?.replace("Origional", "Papa")
            ); // replace('Papa','Papa is not needed)

            let noBgImage = savedImageName
              ?.replace("Origional", "NoBg")
              ?.replace("Papa", "NoBg");

            let noBgImageB64 = await urlToObject(noBgImage);

            // imageMerger({carImage,bgImage,shadow},exterior, big_body) if exterior is false then big_body is not used
            await imageMerger(
              {
                carImage: noBgImageB64,
                bgImage: bgImage,
                shadow: shadowImage,
              },
              false
            )
              .then(async (b64MergedImage) => {
                const base64Response = await fetch(b64MergedImage);
                let pngImage = dataURLtoFile(b64MergedImage, "imagename.jpg");

                handleCompress(pngImage, photoKey, () => {});
                handleCompressMiniImage(
                  pngImage,
                  `CPDTest/${photoKey}`,
                  () => {}
                );
              })
              .catch((e) => {
                setLoading({ open: false, message: "", saving: false });
                console.log("Error merging or compressing image", e);
                toggleNotification({
                  variant: "error",
                  open: true,
                  message: t("Error merging or compressing image"),
                });
                return;
              });
          })
        );
      }

      let newNameIdentifier =
        backgroundOption === "original" ? "Origional" : "Papa";

      newBEImages = newBEImages.map((obj) => {
        return {
          ...obj,
          image: obj.image
            .replace("Papa", newNameIdentifier)
            .replace("Origional", newNameIdentifier),
          width: 2000, // Added 2000 for BE Explode/ implode although it is not used but it is not properly handled in BE and kept it here incase we re-used it
        };
      });

      // Modify images names in BE Answers
      changeBackgrounds({
        carId: carId,
        body: { images: newBEImages, explode: null },
        callback: (response) => {
          if (response.msg === "success") {
            setRefreshExtImagesForm(prev=>(!prev))
            setLoading({ open: false, message: "", saving: false });
          } else {
            toggleNotification({
              variant: "error",
              open: true,
              message: t("Error submitting images"),
            });
          }
        },
      });
    }
  };

  return (
    <PapaPopup
      open={open}
      onClose={handleClose}
      fullScreen
      title={"All Angles"}
    >
      <PapaHubBackDrop loading={loading} />
      <ControlBar
        gridsProps={{ withFrame, setWithFrame }}
        bgSuggestProps={{
          background,
          setBackground,
          reflection,
          setReflection,
          interiorLights,
          setInteriorLights,
          suggestedPackage,
          setSuggestedPackage,
        }}
        bgSelectprops={{
          backgroundOption,
          setBackgroundOption,
          suggestedPackage,
          selectedPackage,
          setSelectedPackage,
        }}
        shadowSelectProps={{ sunLocation, setSunLocation }}
        mainTempProps={{
          setOpenMainTemperaturePopup,
          mainTemperature:
            backgroundImages?.[0]?.mainTemperatureName ?? "neutral",
          backgroundImages,
          imageType: backgroundOption,
        }}
        submitNewImages={submitNewImages}
        saving={loading.saving}
      />
      <PapaHubImageContainer
        showFrame={withFrame === "with"}
        imageType={backgroundOption}
        sunLocation={sunLocation}
        selectedPackage={selectedPackage}
        suggestedPackage={suggestedPackage}
        imagesStates={{
          savedImages,
          setSavedImages,
          shadowImages,
          setShadowImages,
          backgroundImages,
          setBackgroundImages,
          selectedBackgroundImages,
          setSelectedBackgroundImages,
          suggestedBackgroundImages,
          setSuggestedBackgroundImages,
        }}
        mainTempProps={{
          openMainTemperaturePopup,
          setOpenMainTemperaturePopup,
        }}
      />
    </PapaPopup>
  );
};

const mapStateToProps = (state) => ({
  carId: state.car.car.id,
});

const mapDispatchToProps = (dispatch) => ({
  changeBackgrounds(payload) {
    dispatch(changeBackgrounds(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PapaHub);
