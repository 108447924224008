import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Button from "./Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import InfoButton from "./InfoButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import imageTimeStamp from "../helpers/imageTimeStamp";
import { makeStyles } from "@material-ui/core/styles";
import uploadImage from "../helpers/aws";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import FilterTypes from "../helpers/filter-types";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Embelem from "./icons/Embelem";
import Global from "./icons/Global";
// import { interiorRemoveBgIds } from "../helpers/removeBgFields";
import Live from "../helpers/Live";
import useNonInitialEffect from "../helpers/useNonInitialEffect";
import { urlToFile } from "../helpers/imagesFunctions";
import { connect } from "react-redux";
import theme from "../theme";
// import { placeholderImages } from "../wrappers/FormFields";
// import placeholderImages from "../helpers/placeholderImages";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
    width: "150px",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 19px",
    },
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  dialogContentText: {
    padding: "0",
  },
  filterOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
  },
  subIcon: {
    position: "absolute",
    right: "5px",
    bottom: "5px",
  },
}));

const placeholderImages = {
  269: "Front Right_placeholder.png",
  638: "Front Up_placeholder.png",
  637: "Front Down_placeholder.png",
  642: "Rear Left_placeholder.png",
  641: "Trunk Closed_placeholder.png",
  639: "Front Left_placeholder.png",
  640: "Rear Right_placeholder.png",
  643: "Interior Door_placeholder.png",
  644: "Stearing wheel_placeholder.png",
  645: "Instrument Cluster_placeholder.png",
  646: "Dashboard Low_placeholder.png",
  647: "Center colnsol_placeholder.png",
  648: "Interior Front Chairs_placeholder.png",
  649: "Rear Seats_placeholder.png",
  891: "Rear Seats (2 Doors)_placeholder.png",
  650: "Driver View_placeholder.png",
  651: "Hood_placeholder.png",
  652: "Exterior Trunk_placeholder.png",
  284: "Passenger View_placeholder.png",
  283: "Trunk_placeholder.png",
  279: "Front Angle_placeholder.png",
  280: "Rear Angle_placeholder.png",
  285: "Driver View-1_placeholder.png",
  282: "3rd 3rd_placeholder.png",
};

const DeleteVisImgDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, handelDeleteVisImg, history } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        style={{
          display: "inline",
          margin: "auto",
          padding: "0 0 2px",
        }}
      >
        <Typography style={{ fontSize: "22px", fontWeight: 500 }}>
          {t("Warning!")}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentText }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            textAlign: "center",
            fontSize: "14px",
            padding: "0 0 22px",
            margin: 0,
            color: "black",
          }}
        >
          {t(
            "Deleting this image would remove all cosmetic hotspots from the previously uploaded image"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", padding: 0 }}>
        <Button
          onClick={handelDeleteVisImg}
          color="primary"
          variant="contained"
          className={classes.dialogButtons}
        >
          {t("Delete")}
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          autoFocus
          className={classes.dialogButtons}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SingleImageInput = (props) => {
  const {
    title,
    fieldId,
    value,
    onChange,
    field,
    setVisualisationImage,
    points,
    invokedFields,
    isdone,
    triggerUpdatedForms,
    forms,
    imageUrl,
    formId,
    packageImages,
    paparazziImage,
    setPaparazziUnLoaded,
    // setPaparazziAnswers,
    updatePaparazziImage,
    updatePaparazziVisImage,
    bodyType,
    user,
    papaEditAction,
  } = props;
  const classes = useStyles();
  const carId = props.match.params.id;
  const mediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [filterImage, setFilterImage] = useState(null);
  const [big_body, setbig_body] = useState(false);

  const selectedFilter = FilterTypes?.find(
    (el) => String(el.id) === String(fieldId)
  );
  // boolean to decide if image gets its background removed or not
  const removeBackground = Live() && [18, 20, 22].includes(field.tag); // 18 20 22 exterior images, exterior visualization images and Featured Image
  // ||
  // interiorRemoveBgIds.includes(field.id);
  useEffect(() => {
    bodyType &&
      setbig_body(
        bodyType?.body_type_object?.selected_option == 932 ||
          bodyType?.body_type_object?.selected_option == 938
      );
  }, [bodyType]);

  const mobileCameraFields = [335, 645, 875, 876, 877, 878, 952];

  useNonInitialEffect(() => {
    if (imageUrl) {
      // imageUrl remove renoved

      const updateRedux = (image) => {
        let point = {
          [field.side]: {
            field: field,
            image: image,
            points: {},
          },
        };
        field.side !== null
          ? setVisualisationImage({
              carId,
              formId,
              points: {
                ...points,
                ...point,
              },
            })
          : onChange(null, {
              text: field.title_en,
              images: [image],
              field: field,
              option: null,
            });
      };

      const updateReduxPaparazzi = (image) => {
        field.side !== null
          ? updatePaparazziVisImage({
              carId,
              formId,
              pointImage: image,
              side: field.side,
            })
          : updatePaparazziImage({
              carId,
              formId: formId,
              fieldAnswer: {
                [fieldId]: {
                  text: field.title_en,
                  images: [image],
                  field: field,
                  option: null,
                },
              },
            });
      };

      // disable submit form and Upload Origional image to redux
      let newImageUrl = imageUrl;
      setPaparazziUnLoaded((prev) => {
        var arr = [...prev];
        arr.push(fieldId);
        return arr;
      });
      updateRedux(newImageUrl);
      //////////////////////////////////
      // this is used to remove background, merge and upload canon images from the web scince merge-images-v2 didnot work on react native and enable form submit when done
      const mergeCanon = async () => {
        try {
          let startOfNameIndex = newImageUrl.indexOf(`Car-${carId}-Image`);
          const imageFile = await urlToFile(newImageUrl);
          newImageUrl = newImageUrl.slice(startOfNameIndex);
          uploadImage(
            imageFile,
            newImageUrl,
            (data) => {
              // this code sets a state in Forms that has the return of paparazzi kept it in case we needed to use it later
              // looks like this {[fieldId1]:'https://Papa-Car....,[fieldId2]:'https://Papa-Car....,}
              // setPaparazziAnswers((prev) => {
              //   // alert(JSON.stringify(prev));
              //   return Object.assign(
              //     {},
              //     { ...prev },
              //     {
              //       [fieldId]: {
              //         text: field.title_en,
              //         images: [data.Location],
              //         field: field,
              //         option: null,
              //       },
              //     }
              //   );
              // });
              updateReduxPaparazzi(data.Location);
              setPaparazziUnLoaded((prev) => {
                var arr = [...prev];
                return arr.filter((el) => {
                  return el != fieldId;
                });
              });
            },
            removeBackground,
            packageImages,
            [18, 20, 22].includes(field.tag),
            big_body
          );
        } catch (e) {
          // enables form submit in case of error
          setPaparazziUnLoaded((prev) => {
            var arr = [...prev];
            return arr.filter((el) => {
              return el != fieldId;
            });
          });
        }
      };
      mergeCanon();
    }
  }, [imageUrl]);

  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  const handleFile = (event) => {
    uploadImage(
      event.target.files[0],
      `Car-${carId}-Image-${title}-${fieldId}-${imageTimeStamp()}.png`,
      (data) => {
        let point = {
          [field.side]: {
            field: field,
            image: data.Location,
            points: {},
          },
        };
        field.side !== null
          ? setVisualisationImage({
              carId,
              formId,
              points: {
                ...points,
                ...point,
              },
            })
          : onChange(event, {
              text: field.title_en,
              images: [data.Location],
              field: field,
              option: null,
            });
      },
      removeBackground,
      packageImages,
      [18, 20, 22].includes(field.tag),
      big_body
    );
    event.target.value = ""; // this line will reset the input field so if you removed it you can re-add the same file.
  };

  const deleteVisImage = () => {
    delete points[field.side];
    setVisualisationImage({
      carId,
      formId,
      points: {
        ...points,
      },
    });
  };

  const handleDeletePress = (event) => {
    if (field.side !== null) {
      // visualization image
      if (
        // visualization image has assigned points (open warning dialog)
        points[field.side]?.points
          ? Object.keys(points[field.side]?.points).length
          : false
      ) {
        setOpen(true);
      } else {
        // visualization image dont have assigned points (delete directly)
        deleteVisImage();
      }
    } else {
      onChange(event, {
        text: field.title_en,
        // images: [require("../images/img_placeholder.png")],
        field: field,
        option: null,
      });
    }
  };

  const handelDeleteVisImg = () => {
    deleteVisImage();
    let exteriorForms = [];
    let interiorForms = [];

    forms.map((form) => {
      if (form.is_exterior_visual && !form.is_interior_visual) {
        if (form.is_done) {
          exteriorForms.push(form.id);
        }
      } else if (!form.is_exterior_visual && form.is_interior_visual) {
        if (form.is_done) {
          interiorForms.push(form.id);
        }
      }
    });

    switch (
      field.side // bug
    ) {
      case "dfv":
      case "prf":
        triggerUpdatedForms({ updated_forms: exteriorForms });
        break;
      case "fv":
      case "rf":
      case "3rd":
      case "tv":
        triggerUpdatedForms({ updated_forms: interiorForms });
        break;
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilterImage(selectedFilter?.image);
  }, [title]);

  const handleUpload = () => {
    // alert(selectedFilter.key);
    console.log(window.parent);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        `Single-${carId}-${selectedFilter?.key}-${fieldId}-${field.tag}`
      );
    }
  };

  return (
    <Paper style={{ padding: "1em" }}>
      <div style={{ display: "flex" }}>
        <Typography
          children={title}
          style={{ margin: "auto 0" }}
          classes={
            handleInvokedField() && isdone
              ? { body1: classes.switch_track }
              : { body1: classes.switch_base }
          }
        />
        {field?.field_info && <InfoButton infoFiles={field.field_info} />}
      </div>
      <Grid container spacing={2} justify={"center"}>
        <Grid item xs={12} md={6}>
          <div
            style={{ position: "relative" }}
            onClick={() => {
              if (mediumUp) {
                papaEditAction();
              }
            }}
          >
            <img
              width={"100%"}
              src={
                value.length == 0
                  ? // ? placeholderImages(field.id)
                    //   ? require(`../images/${placeholderImages?.[field.id]}`)
                    //   : "missing placeholder"
                    require(`../images/${
                      placeholderImages?.[field.id] ?? "img_placeholder.png"
                    }`)
                  : value + `?${Date.now()}`
              }
              alt={"placeholder"}
            />
            <img
              width={"100%"}
              src={filterImage}
              alt={""}
              className={classes.filterOverlay}
              style={
                value[0]?.includes("placeholder") ||
                field.position === "int" ||
                field.tag == 19 || // Interior Images
                field.tag == 21 || // Interior Visualization Images
                (!paparazziImage && [18, 20, 22].includes(field.tag))
                  ? {}
                  : big_body
                  ? {}
                  : {
                      transform: "scale(0.8)",
                    }
              } // i think wrong value maybe placeholder at aws
            />
          </div>
          <Button
            fullWidth
            color={"secondary"}
            variant={"text"}
            disabled={value.length === 0 || value[0].includes("placeholder")} // disabled if no image was uploaded
            onClick={handleDeletePress}
            style={{ margin: "0 0 1em 0" }}
          >
            <DeleteIcon />
            Delete Image
          </Button>
          <Grid
            container
            justify="space-between"
            spacing={selectedFilter?.camera === "wide" ? 2 : 0}
          >
            <Grid item xs={selectedFilter?.camera === "wide" ? 6 : 12}>
              {window.ReactNativeWebView &&
                !mobileCameraFields.includes(fieldId) && (
                  <Button
                    color={"primary"}
                    fullWidth
                    variant={"contained"}
                    onClick={handleUpload}
                    style={{ marginBottom: "6px" }}
                  >
                    <PhotoCameraIcon fontSize={"large"} />
                    <Embelem
                      size={17}
                      variant="light"
                      className={classes.subIcon}
                    />
                  </Button>
                )}
            </Grid>
            <Grid
              item
              xs={
                selectedFilter?.camera === "wide" && window.ReactNativeWebView
                  ? 6
                  : 12
              }
            >
              {(selectedFilter?.camera === "wide" ||
                !window.ReactNativeWebView ||
                mobileCameraFields.includes(fieldId)) && (
                <Button
                  style={{
                    backgroundColor: "#BFD6DE",
                    border: "1px solid #0D6885",
                    color: "#186985",
                  }}
                  fullWidth
                  variant={"contained"}
                >
                  <input
                    className={"file-input"}
                    type={"file"}
                    onChange={handleFile}
                    id={"file_upload_" + fieldId}
                    accept={"image/*"}
                    style={{ display: "none" }}
                  />
                  <label
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      all: "inherit",
                    }}
                    htmlFor={"file_upload_" + fieldId}
                  >
                    <PhotoCameraIcon fontSize={"large"} />
                    <Global
                      size={17}
                      fill={"#186985"}
                      className={classes.subIcon}
                    />
                  </label>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteVisImgDialog
        open={open}
        handleClose={handleClose}
        handelDeleteVisImg={handelDeleteVisImg}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  bodyType: state.car.car.body_type_config,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, null)(SingleImageInput));
