// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import { TOGGLE_NOTIFICATION_BAR } from "../constants";

const initialState = {
  message: "",
  open: false,
  variant: "success",
};

export default createReducer(initialState, {
  [TOGGLE_NOTIFICATION_BAR]: (state, payload) => {
    return Object.assign({}, state, {
      ...payload,
    });
  },
});
