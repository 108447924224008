import React from "react";

const FeatureIcon8 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon8Clip0)">
<path d="M24.9999 0C17.2808 0 11.0007 6.28008 11.0007 13.9993V20.8541C11.0007 21.3209 11.3795 21.6994 11.8463 21.6994H16.5394C17.0062 21.6994 17.3847 21.3209 17.3847 20.8541V13.9993C17.3847 9.80029 20.8008 6.38408 24.9998 6.38408C29.1988 6.38408 32.615 9.80029 32.615 13.9993V20.8541C32.615 21.3209 32.9939 21.6994 33.4608 21.6994H38.1538C38.6206 21.6994 38.9991 21.3209 38.9991 20.8541V13.9993C38.9992 6.28008 32.7192 0 24.9999 0ZM37.3086 20.0088H34.3059V13.9993C34.3059 8.86807 30.1313 4.69346 25.0001 4.69346C19.869 4.69346 15.6943 8.86807 15.6943 13.9993V20.0088H12.6916V13.9993C12.6916 7.2123 18.2131 1.69072 25 1.69072C31.787 1.69072 37.3086 7.2123 37.3086 13.9993V20.0088Z" fill="#07303E"/>
<path d="M40.1006 20.0088H9.89949C7.89705 20.0088 6.26785 21.638 6.26785 23.6404V46.3684C6.26785 48.3708 7.89705 50 9.89949 50H40.1006C42.103 50 43.7321 48.3708 43.7321 46.3683V23.6404C43.7321 21.638 42.103 20.0088 40.1006 20.0088ZM42.0415 46.3683C42.0415 47.4386 41.1708 48.3093 40.1006 48.3093H9.89949C8.82918 48.3093 7.95848 47.4386 7.95848 46.3683V23.6404C7.95848 22.5701 8.82918 21.6994 9.89949 21.6994H40.1006C41.1708 21.6994 42.0415 22.5701 42.0415 23.6404V46.3683Z" fill="#07303E"/>
<path d="M27.8428 36.1115C28.8304 35.2793 29.4051 34.0628 29.4051 32.7555C29.4051 30.3265 27.4289 28.3503 24.9999 28.3503C22.5708 28.3503 20.5946 30.3265 20.5946 32.7555C20.5946 34.0628 21.1693 35.2793 22.1571 36.1117L21.2068 40.6392C21.1546 40.8886 21.2174 41.1481 21.3779 41.3458C21.5386 41.5434 21.7796 41.6583 22.0342 41.6583H27.9656C28.2203 41.6583 28.4613 41.5435 28.6218 41.3458C28.7822 41.148 28.845 40.8885 28.7928 40.6394L27.8428 36.1115ZM26.4503 35.0411C26.1534 35.2301 26.0045 35.5833 26.0769 35.9279L26.9245 39.9675H23.0754L23.9232 35.9282C23.9955 35.5835 23.8469 35.2303 23.5498 35.0414C22.758 34.5374 22.2854 33.6829 22.2854 32.7556C22.2854 31.2588 23.503 30.0412 24.9999 30.0412C26.4967 30.0412 27.7144 31.2588 27.7144 32.7556C27.7146 33.6831 27.2419 34.5374 26.4503 35.0411Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon8Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon8;
