// const currentPoint = {
//   fill: "#F8F8F8",
//   stroke: "#FF0000",
//   "stroke-width": "3",
// };

// const upcomingPoint = {
//   fill: "#F8F8F8",
//   stroke: "#0D6885",
//   "stroke-width": "1.5",
// };

// const answeredPoint = {
//   fill: "#3BB123",
//   stroke: "#0D6885",
//   "stroke-width": "1.5",
// };

// const hiddenPoint = {
//   fill: "#DDDDDE",
//   stroke: "#B0B0B0",
//   "stroke-width": "1.5",
// };

const pointStyles = {
  currentPoint: {
    // fill: "#F8F8F8",
    stroke: "#FF0000",
    strokeWidth: "3",
  },
  upcomingPoint: {
    // fill: "#F8F8F8",
    stroke: "#0D6885",
    strokeWidth: "1.5",
  },
  answeredPoint: {
    fill: "#3BB123",
    // stroke: "#0D6885",
    // strokeWidth: "1.5",
  },
  hiddenPoint: {
    fill: "#DDDDDE",
    // stroke: "#B0B0B0",
    // strokeWidth: "1.5",
  },
  unansweredPoint: {
    fill: "#F8F8F8",
  },
};
export default pointStyles;
