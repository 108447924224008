import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PapaTestRadioGroup from "../../components/PapaTestRadioGroup";
import { getTestPaparazziPackage } from "../../store/actions/forms";

const useStyles = makeStyles((theme) => ({
  shadowTitle: {
    padding: "0 9px",
  },
  dialog: { padding: "9px" },
}));

const SelectDialog = (props) => {
  const classes = useStyles();

  const { open, setOpen, getTestPaparazziPackage, setPaparazzi } = props;

  const [background, setBackground] = useState(4198);
  const [sunLocation, setSunLocation] = useState(4288);
  const [packageId, setPackageId] = useState(0);

  useEffect(() => {
    getTestPaparazziPackage({
      location: background,
      sun_location: sunLocation,
      reflection: null,
      interior_lights: null,
      carId: packageId,
      callback: (paparazzi) => {
        setPaparazzi(paparazzi);
      },
    });
  }, [sunLocation, packageId, background]);

  const handleSubmit = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.dialog }}
    >
      <Typography className={classes.shadowTitle}>Backgrounds</Typography>
      <PapaTestRadioGroup
        options={[
          { id: 4198, title: "Outside" },
          { id: 4199, title: "Showroom" },
        ]}
        setVal={setBackground}
        val={background}
      />
      {background == 4198 ? (
        <>
          <Typography className={classes.shadowTitle}>
            {/* Horizontal Sun Rays */}
            Sun Location
          </Typography>

          <PapaTestRadioGroup
            sunLocation={true}
            options={[
              { id: 4283, title: "Driver - Front" },
              { id: 4286, title: "Driver - Rear" },
              { id: 4284, title: "Driver - Top" },
              { id: 4287, title: "Middle - Front" },
              { id: 4289, title: "Middle - Rear" },
              { id: 4288, title: "Middle - Top" },
              { id: 4290, title: "Passenger - Front" },
              { id: 4292, title: "Passenger - Rear" },
              { id: 4291, title: "Passenger - Top" },
            ]}
            setVal={setSunLocation}
            val={sunLocation}
          />
          {/* <Typography className={classes.shadowTitle}>Reflection</Typography>
  
          <PapaTestRadioGroup
          options={[
            { id: 4293, title: "Trees" },
            { id: 4294, title: "Buildings" },
            { id: 4295, title: "No Reflection" },
          ]}
          setVal={setInnerReflection}
          val={innerReflection}
        /> */}
          <Typography className={classes.shadowTitle}>Package</Typography>

          <PapaTestRadioGroup
            options={[
              { id: 0, title: "Austrailian" },
              { id: 1, title: "Abdoun" },
              { id: 2, title: "Hamshari" },
              { id: 3, title: "Kaloti" },
              { id: 4, title: "Ward" },
              { id: 5, title: "KalotiB" },
              { id: 6, title: "Dahyet Al Rasheed" },
              { id: 7, title: "Masjid Abdoun" },
            ]}
            setVal={setPackageId}
            val={packageId}
          />
        </>
      ) : (
        <>
          <PapaTestRadioGroup
            options={[
              { id: 0, title: "City Mall" },
              { id: 1, title: "Mecca Mall" },
            ]}
            setVal={setPackageId}
            val={packageId}
          />
        </>
      )}
      <Button onClick={handleSubmit} variant="contained" color="primary">
        Submit
      </Button>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTestPaparazziPackage(payload) {
    dispatch(getTestPaparazziPackage(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDialog);
