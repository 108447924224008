import React from "react";

const NextClickableImage = (props) => {
  const { size, fill, style, className, selected } = props;
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="33.2619"
        cy="33.2619"
        r="33.2619"
        fill="white"
        fill-opacity="0.8"
      />
      <path
        d="M21.6209 32.7575L34.2858 20.1089C34.5787 19.8161 34.9697 19.6548 35.3867 19.6548C35.8036 19.6548 36.1947 19.8161 36.4876 20.1089L37.4203 21.0401C38.0272 21.647 38.0272 22.6332 37.4203 23.2391L26.7852 33.8607L37.4321 44.4941C37.725 44.7869 37.8867 45.1772 37.8867 45.5934C37.8867 46.0101 37.725 46.4004 37.4321 46.6934L36.4994 47.6244C36.2062 47.9172 35.8154 48.0785 35.3985 48.0785C34.9815 48.0785 34.5905 47.9172 34.2976 47.6244L21.6209 34.9642C21.3272 34.6705 21.166 34.2783 21.1669 33.8614C21.166 33.4429 21.3272 33.051 21.6209 32.7575Z"
        fill="#3B3B3B"
      />
    </svg>
  );
};

export default NextClickableImage;
