import React from "react";

const FeatureIcon13 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M0.687824 26.1761C3.01183 26.1837 5.14127 24.8795 6.19047 22.8058C6.19047 22.7837 6.19047 22.7631 6.19047 22.7404C6.19047 14.4349 11.4551 9.67163 20.6349 9.67163C29.2032 9.66819 41.2698 13.7264 41.2698 22.737C41.2698 31.7476 29.2032 35.8058 20.6349 35.8058H0.687824C0.307972 35.8058 -3.8147e-06 35.4978 -3.8147e-06 35.1179V26.864C-3.8147e-06 26.4841 0.307972 26.1761 0.687824 26.1761ZM39.8942 22.737C39.8942 14.7581 28.4019 11.0439 20.6349 11.0439C12.2076 11.0439 7.56613 15.197 7.56613 22.737C7.56613 30.277 12.2076 34.4301 20.6349 34.4301C28.4019 34.4301 39.8942 30.7158 39.8942 22.737ZM1.37566 34.4301H13.1816C9.2919 32.7325 6.92989 29.497 6.34661 24.9944C5.06733 26.4329 3.29178 27.335 1.37566 27.5201V34.4301Z" fill="#07303E"/>
<path d="M21.1398 12.4195C28.3565 12.4195 38.5185 15.6207 38.5185 22.737C38.5185 29.8532 28.3565 33.0544 21.1398 33.0544C13.3879 33.0544 8.94178 29.294 8.94178 22.737C8.94178 16.1799 13.3879 12.4195 21.1398 12.4195ZM21.1398 31.6788C27.5937 31.6788 37.1428 28.8366 37.1428 22.737C37.1428 16.6373 27.5937 13.7952 21.1398 13.7952C17.0981 13.7952 10.3174 14.9569 10.3174 22.737C10.3174 30.517 17.0981 31.6788 21.1398 31.6788Z" fill="#07303E"/>
<path d="M37.3394 19.1358H38.6147C39.7193 19.1358 40.6147 20.0312 40.6147 21.1358V24.3416C40.6147 25.4462 39.7193 26.3416 38.6147 26.3416H37.3394V19.1358Z" fill="#07303E"/>
<path d="M45.3704 23.0159L49.0741 23.0159C49.5858 23.0159 50 22.6608 50 22.2222C50 21.7836 49.5858 21.4286 49.0741 21.4286L45.3704 21.4286C44.8587 21.4286 44.4444 21.7836 44.4444 22.2222C44.4444 22.6608 44.8587 23.0159 45.3704 23.0159Z" fill="#07303E"/>
<path d="M42.3425 28.6094L45.1996 31.467C45.5717 31.8391 46.1743 31.8391 46.5463 31.467C46.9184 31.0949 46.9184 30.4924 46.5463 30.1204L43.6891 27.2635C43.5037 27.0768 43.2599 26.9841 43.0162 26.9841C42.7724 26.9841 42.5285 27.0768 42.3425 27.2628C41.9705 27.6348 41.9705 28.2374 42.3425 28.6094Z" fill="#07303E"/>
<path d="M41.5489 16.3879C41.7343 16.5739 41.9781 16.6667 42.2219 16.6667C42.4656 16.6667 42.7095 16.5739 42.8955 16.3879L45.7527 13.5306C46.1248 13.1585 46.1248 12.5559 45.7527 12.1838C45.3806 11.8117 44.778 11.8117 44.406 12.1838L41.5489 15.0412C41.1768 15.4132 41.1768 16.0158 41.5489 16.3879Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon13;
