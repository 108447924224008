import React from "react";

const GoogleLogo = (props) => {
  const { size, fill, className, style } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.54588 9.66836L2.98896 11.7475L0.953392 11.7905C0.345057 10.6622 0 9.37124 0 7.99938C0 6.67281 0.32262 5.42183 0.894487 4.32031H0.894924L2.70715 4.65256L3.50101 6.45391C3.33486 6.93831 3.2443 7.4583 3.2443 7.99938C3.24436 8.58662 3.35073 9.14927 3.54588 9.66836Z" fill="#FFC107"/>
<path d="M15.861 6.50513C15.9529 6.98906 16.0008 7.48883 16.0008 7.9996C16.0008 8.57235 15.9406 9.13103 15.8258 9.66992C15.4364 11.5037 14.4188 13.105 13.0092 14.2382L13.0087 14.2378L10.7262 14.1213L10.4031 12.1046C11.3385 11.5561 12.0695 10.6976 12.4545 9.66992H8.17676V6.50513H12.5169H15.861Z" fill="#03A9F4"/>
<path d="M13.0074 14.2382L13.0079 14.2387C11.6369 15.3406 9.8954 15.9999 7.99961 15.9999C4.95307 15.9999 2.30432 14.2971 0.953125 11.7912L3.54562 9.66907C4.2212 11.4721 5.96052 12.7556 7.99961 12.7556C8.87607 12.7556 9.69718 12.5187 10.4018 12.1051L13.0074 14.2382Z" fill="#4CAF50"/>
<path d="M13.1074 1.84172L10.5158 3.96344C9.78662 3.50764 8.92463 3.24433 8.00115 3.24433C5.9159 3.24433 4.14405 4.58671 3.50231 6.4544L0.896189 4.32081H0.895752C2.22717 1.75382 4.90932 0 8.00115 0C9.94221 0 11.722 0.691427 13.1074 1.84172Z" fill="#F44336"/>
</svg>
  );
};

export default GoogleLogo;
