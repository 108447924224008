import {
  CONNECTION_REQUEST,
  CLEAR_NEW_REQUESTS,
  CLEAR_NEW_CARS,
  CLEAR_REMOVED_CARS,
  CLEAR_REMOVED_REQUESTS,
  UPDATE_PAGE_TIME_STAMP,
  FETCH_PAGE_TIME_STAMP,
  FETCH_UNSEEN_NOTIFICATIONS,
} from "../constants";

export function socketConnect(payload) {
  return {
    type: CONNECTION_REQUEST,
    payload: payload,
  };
}

export function clearNewRequests() {
  return {
    type: CLEAR_NEW_REQUESTS,
  };
}

export function clearNewCars() {
  return {
    type: CLEAR_NEW_CARS,
  };
}

export function clearRemovedCars() {
  return {
    type: CLEAR_REMOVED_CARS,
  };
}

export function clearRemovedRequests() {
  return {
    type: CLEAR_REMOVED_REQUESTS,
  };
}

export function updatePageTimeStamp(payload) {
  return {
    type: UPDATE_PAGE_TIME_STAMP,
    payload: payload,
  };
}

export function getPageTimeStamp(payload) {
  return {
    type: FETCH_PAGE_TIME_STAMP,
    payload: payload,
  };
}

export function getUnseenNotifications(payload) {
  return {
    type: FETCH_UNSEEN_NOTIFICATIONS,
    payload: payload,
  };
}
