import { DialogTitle, makeStyles } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  endIcon: {
    margin: 0,
  },
  card: {
    marginBottom: " 1px",
    borderRadius: 0,
    height: "100%",
    display: "grid",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardContent: {
    padding: "21px 31px 0 17px",
    marginBottom: "-24px", // 24px is to over ride .MuiCardContent-root:last-child's 24px padding just a quick fix
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0 16px", // 24px is over ridden in .MuiCardContent-root:last-child just included it to explain the -9 margin just a quick fix
      margin: "0 0 -9px",
    },
  },
  cardActions: {
    padding: "22px 31px 29px",
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
      marginTop: "0",
      width: "auto",
    },
  },
  dialogPaper: {
    borderRadius: "12px",
    padding: "10px 32px 36px",
    maxWidth: "488px",
    position: "fixed",
    zIndex: 10000,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 36px",
      width: "100vw",
      height: "100%",
      maxHeight: "none",
      maxWidth: "none",
      margin: "0",
      paddingTop: "101px",
    },
  },
  dialogRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  dialogContentText: {
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      flex: "none",
    },
    "&.MuiDialogContent": {
      overflow: "visible",
    },
  },
  ".MuiTableCell-root	": {
    display: "inline",
  },
  listingnumberTypo: { fontSize: "14px", fontWeight: "500", color: "#07303E" },
  continuelistingTypo: {
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    color: "#186985",
    verticalAlign: "top",
    cursor: "pointer",
  },
  continuelistingTypotwo: {
    color: "#186985",
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  listvehicleTypo: {
    cursor: "pointer",
    color: "#186985",
  },
  listvehicleTypotwo: {
    color: "#186985",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  cardDiv: { display: "flex", width: "100%", justifyContent: "space-between" },
  cardDivtwo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dialogtitle: {
    float: "left",
    "&.MuiDialogTitle": {
      padding: "16px 14px",
      display: "inline-flex",
      justifyContent: "space-between",
    },
  },
  claimRequestTypo: {
    fontSize: "26px",
    fontWeight: 500,
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  dialogActions: {
    padding: 0,
    display: "block",
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      justifyContent: "center",
    },
  },
  cardBase: {
    zIndex: 100000,
    postion: "fixed",
  },
}));

function FaultDetails(props) {
  const { getApptInterval } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { open, setOpen, children } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          document.getElementById("root").style.filter = "none";
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          disableTypography={true}
          className={classes.dialogTitle}
          style={{
            padding: "16px 14px",
            display: "inline-flex",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.claimRequestTypo}>
            {props.system || "systems"}
          </Typography>
          <CloseIcon
            onClick={() => {
              document.getElementById("root").style.filter = "none";
              setOpen(false);
            }}
          />
        </DialogTitle>
        <DialogContent
          classes={{ root: classes.dialogContentText }}
          style={{
            postion: !props.action && "absolute",
            width: !props.action && "1px",
            display: "contents",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentTextTwo}
          >
            <Typography style={{ marginLeft: "10px" }}>
              {props.faultcnt}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {children}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FaultDetails;
