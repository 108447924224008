import React from "react";

const Global = (props) => {
  const { size, style, className, fill } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#globalClip0)">
        <path
          d="M8.80385 16.9898C10.1038 16.8051 11.2435 15.4483 11.9606 13.4446C10.9239 13.2124 9.86604 13.0878 8.80385 13.0729V16.9898Z"
          fill={fill}
        />
        <path
          d="M10.8808 16.6717C10.9577 16.6494 11.0342 16.627 11.1102 16.6024C11.1743 16.5818 11.2375 16.5595 11.3007 16.5374C11.3754 16.5116 11.4499 16.4851 11.5241 16.4569C11.5873 16.4328 11.6499 16.4073 11.7126 16.3818C11.785 16.3514 11.8573 16.3211 11.9288 16.2907C11.9912 16.2633 12.0532 16.2345 12.1153 16.2056C12.1854 16.1725 12.2553 16.1388 12.3246 16.1038C12.3854 16.0728 12.4461 16.0412 12.5069 16.0087C12.575 15.9722 12.6426 15.935 12.7098 15.8967C12.7707 15.8623 12.8293 15.8277 12.8885 15.7921C12.9543 15.7516 13.0199 15.7111 13.085 15.6706C13.1433 15.6335 13.2015 15.5959 13.2589 15.557C13.323 15.5138 13.3862 15.4689 13.4491 15.4239C13.5056 15.3834 13.5618 15.3431 13.617 15.3024C13.6778 15.2555 13.7401 15.207 13.8011 15.1584C13.8553 15.1152 13.9093 15.0723 13.9625 15.028C14.0232 14.9775 14.0813 14.9253 14.1402 14.8731C14.1919 14.8272 14.2438 14.7819 14.2942 14.7348C14.3522 14.6807 14.4087 14.6246 14.4653 14.569C14.5142 14.5206 14.5638 14.473 14.6117 14.4234C14.6206 14.4142 14.629 14.4043 14.6382 14.3948C13.9756 14.0526 13.2776 13.7839 12.5565 13.5937C12.1814 14.7897 11.514 15.8736 10.6147 16.7467C10.6393 16.7404 10.664 16.7352 10.6886 16.7288C10.7536 16.7106 10.8173 16.6905 10.8808 16.6717Z"
          fill={fill}
        />
        <path
          d="M17.0002 8.81058H13.3573C13.3435 10.233 13.1372 11.6469 12.7439 13.0139C13.5459 13.2281 14.3198 13.5361 15.0497 13.9315C16.2481 12.4871 16.934 10.6863 17.0002 8.81058Z"
          fill={fill}
        />
        <path
          d="M8.80385 8.20274H12.7495C12.7341 6.83065 12.5328 5.46702 12.1514 4.14893C11.0527 4.39949 9.93057 4.5333 8.80385 4.54814V8.20274Z"
          fill={fill}
        />
        <path
          d="M8.80385 0.024231V3.94112C9.86604 3.92613 10.9239 3.80167 11.9606 3.5695C11.2435 1.56574 10.1038 0.208927 8.80385 0.024231Z"
          fill={fill}
        />
        <path
          d="M8.80385 12.4652C9.93057 12.4802 11.0527 12.614 12.1514 12.8645C12.5328 11.5465 12.7341 10.1827 12.7495 8.81058H8.80385V12.4652Z"
          fill={fill}
        />
        <path
          d="M15.0497 3.0824C14.3198 3.47775 13.5459 3.78573 12.7439 3.99994C13.1372 5.36699 13.3435 6.78091 13.3573 8.2033H17.0002C16.9339 6.3277 16.2479 4.52688 15.0497 3.0824Z"
          fill={fill}
        />
        <path
          d="M14.6393 2.62177C14.6304 2.61272 14.6219 2.60293 14.6132 2.59358C14.5651 2.54403 14.5153 2.49627 14.4663 2.44805C14.4099 2.39242 14.3537 2.3362 14.2956 2.2822C14.2453 2.23532 14.1932 2.19096 14.1416 2.14423C14.0827 2.09201 14.0238 2.03935 13.9632 1.98891C13.9107 1.94455 13.8569 1.90212 13.8035 1.85955C13.7426 1.81029 13.6803 1.76134 13.6177 1.71431C13.5628 1.67292 13.5072 1.63287 13.4513 1.59281C13.3878 1.54712 13.324 1.50158 13.2593 1.45811C13.2025 1.41984 13.1448 1.38245 13.0871 1.34581C13.0214 1.30353 12.9558 1.26214 12.8889 1.22209C12.8303 1.18693 12.7711 1.15221 12.7118 1.11854C12.6438 1.07967 12.5757 1.04199 12.507 1.0052C12.4462 0.974787 12.3855 0.941705 12.3247 0.911293C12.2548 0.875986 12.1844 0.842013 12.1121 0.808635C12.0513 0.779706 11.9896 0.75152 11.927 0.724075C11.855 0.69218 11.7823 0.6634 11.7098 0.632988C11.6472 0.607472 11.5849 0.582252 11.5217 0.55822C11.448 0.530033 11.3732 0.503627 11.2984 0.477814C11.2352 0.455561 11.172 0.433457 11.1079 0.412688C11.032 0.388062 10.956 0.365661 10.878 0.343112C10.8148 0.324568 10.7513 0.305728 10.6874 0.288816C10.6629 0.28214 10.638 0.276948 10.6133 0.270569C11.5126 1.14376 12.1799 2.22745 12.5551 3.4236C13.277 3.23342 13.976 2.96461 14.6393 2.62177Z"
          fill={fill}
        />
        <path
          d="M0 8.2033H3.64288C3.65668 6.78091 3.86304 5.36699 4.25616 3.99994C3.45433 3.78587 2.68039 3.4779 1.95051 3.0824C0.752135 4.52673 0.0661642 6.3277 0 8.2033Z"
          fill={fill}
        />
        <path
          d="M8.19638 16.9898V13.0729C7.1342 13.0879 6.07631 13.2124 5.03964 13.4446C5.75662 15.4483 6.89639 16.8051 8.19638 16.9898Z"
          fill={fill}
        />
        <path
          d="M8.19612 8.81058H4.25046C4.26589 10.1827 4.46705 11.5465 4.8486 12.8645C5.94729 12.6138 7.06926 12.48 8.19612 12.4652V8.81058Z"
          fill={fill}
        />
        <path
          d="M8.19638 0.024231C6.89639 0.208927 5.75662 1.56574 5.03964 3.5695C6.07631 3.80182 7.1342 3.92628 8.19638 3.94112V0.024231Z"
          fill={fill}
        />
        <path
          d="M8.19612 4.54814C7.0694 4.5333 5.94729 4.39949 4.8486 4.14893C4.46705 5.46702 4.26589 6.83065 4.25046 8.20274H8.19612V4.54814Z"
          fill={fill}
        />
        <path
          d="M6.38471 0.270386C6.36009 0.276765 6.33546 0.281957 6.31083 0.288336C6.24645 0.305545 6.18295 0.324682 6.11887 0.343226C6.04262 0.365478 5.96696 0.387879 5.89041 0.412208C5.82588 0.433126 5.76238 0.455378 5.69889 0.477483C5.62456 0.503741 5.55009 0.52985 5.47651 0.55774C5.41302 0.582069 5.35041 0.607289 5.28751 0.633102C5.21527 0.663514 5.14287 0.693925 5.07151 0.724189C5.00891 0.751634 4.94705 0.780413 4.88504 0.809342C4.81487 0.842424 4.745 0.876099 4.67572 0.91111C4.61489 0.942115 4.55407 0.973862 4.49339 1.0062C4.4253 1.0427 4.3578 1.08008 4.29075 1.11835C4.22992 1.15233 4.17014 1.18719 4.11154 1.22279C4.04552 1.26255 3.98025 1.30364 3.91527 1.34429C3.85682 1.38168 3.79882 1.41936 3.74141 1.45823C3.67732 1.50139 3.61412 1.54635 3.55122 1.5913C3.4947 1.63179 3.43847 1.67215 3.38314 1.71294C3.32098 1.75967 3.26164 1.80833 3.19904 1.85684C3.14504 1.90001 3.09059 1.94288 3.03778 1.98754C2.97696 2.03768 2.91925 2.0896 2.86065 2.14153C2.80873 2.18781 2.75636 2.23276 2.70562 2.28068C2.64792 2.33453 2.59169 2.39046 2.53532 2.44594C2.48636 2.4943 2.43652 2.54207 2.38845 2.59177C2.3797 2.60097 2.37124 2.61105 2.36205 2.6204C3.02458 2.96279 3.72271 3.23145 4.44384 3.42164C4.81887 2.22623 5.48586 1.14313 6.38471 0.270386Z"
          fill={fill}
        />
        <path
          d="M2.53387 14.5661C2.59039 14.6217 2.64662 14.678 2.70462 14.7321C2.75506 14.7788 2.80699 14.8232 2.85876 14.8699C2.91765 14.9223 2.97655 14.9748 3.03708 15.0253C3.08959 15.0693 3.14315 15.1119 3.19685 15.1546C3.25767 15.2039 3.31835 15.2528 3.38273 15.3001C3.43747 15.3412 3.4931 15.381 3.54874 15.4218C3.61223 15.4673 3.67632 15.5129 3.741 15.5566C3.79782 15.5949 3.85552 15.6323 3.91323 15.6691C3.97895 15.7112 4.04452 15.7526 4.11143 15.7927C4.17003 15.828 4.22922 15.8625 4.28856 15.8964C4.3565 15.9352 4.4246 15.9729 4.49328 16.0096C4.55396 16.04 4.61478 16.0731 4.6756 16.1035C4.74548 16.1388 4.81594 16.1727 4.88819 16.2063C4.94901 16.2349 5.01073 16.2631 5.07333 16.2907C5.14528 16.3226 5.21782 16.3514 5.29051 16.3818C5.35312 16.4073 5.41543 16.4325 5.47862 16.4565C5.55235 16.4849 5.62712 16.5113 5.70189 16.5371C5.76509 16.5592 5.82828 16.5814 5.89237 16.6021C5.96833 16.6267 6.04428 16.6492 6.12231 16.6716C6.18551 16.6902 6.24901 16.709 6.3128 16.7261C6.33742 16.7328 6.36234 16.738 6.38697 16.7443C5.48767 15.871 4.82039 14.7873 4.44522 13.5912C3.72409 13.7815 3.02595 14.0502 2.36342 14.3927C2.37232 14.4017 2.38078 14.4115 2.38968 14.4209C2.43522 14.4701 2.48432 14.5179 2.53387 14.5661Z"
          fill={fill}
        />
        <path
          d="M1.95051 13.9315C2.68039 13.5361 3.45433 13.2281 4.25616 13.0139C3.86304 11.6469 3.65668 10.233 3.64288 8.81058H0C0.0663125 10.6862 0.752135 12.487 1.95051 13.9315Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="globalClip0">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Global;
