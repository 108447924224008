import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StackedImageComponent from "./StackedImageComponent";

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    padding: "16px",
    flexWrap: "nowrap",
    overflow: "scroll",
  },
  mainGridItem: {
    maxWidth: "none",
  },
  secondMainGrid: {
    width: "82%",
    margin: "auto",
    maxWidth: "1000px",
  },
  innerGridContainer: {
    justifyContent: "center",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  previewImagesGridItem: {
    maxWidth: "500px",
  },
  bigTitles: {
    fontSize: "22px",
    fontWeight: 600,
    color: "#07303E",
    marginBottom: "16px",
  },
  smallTitles: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#07303E",
  },
  temperaturesContainerDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  temperatureDiv: {
    width: "20%",
  },
}));

const TemperatureSelect = (props) => {
  const {
    packageObject,
    originalImage,
    setTemperature,
    temperatureSelectType,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={classes.mainGridContainer}
    >
      <Grid item xs={8} className={classes.mainGridItem}>
        <Grid container spacing={3} className={classes.innerGridContainer}>
          <Grid item xs={5} className={classes.previewImagesGridItem}>
            <Typography
              className={[classes.bigTitles, classes.textAlignCenter]}
            >
              {t("Original Image")}
            </Typography>
            <StackedImageComponent
              imageType="original"
              savedImage={originalImage}
            />
          </Grid>
          <Grid item xs={5} className={classes.previewImagesGridItem}>
            <Typography
              className={[classes.bigTitles, classes.textAlignCenter]}
            >
              {t("Modified Image (Paparazzi)")}
            </Typography>
            <StackedImageComponent
              imageType="manual"
              savedImage={originalImage}
              backgroundImage={packageObject.image_url}
              noBgImage={originalImage
                ?.replace("Origional", "NoBg")
                ?.replace("Papa", "NoBg")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        className={[classes.mainGridItem, classes.secondMainGrid]}
      >
        <div className={classes.temperaturesContainerDiv}>
          {packageObject?.moods?.map((mood) => (
            <div
              className={classes.temperatureDiv}
              onClick={() => {
                setTemperature([
                  {
                    ...packageObject,
                    image_url: mood.img,
                    selectedMoodName: mood.mood_name, // these are always set but only used in setTemperature for all images
                    selectedMoodId: mood.mood, // these are always set but only used in setTemperature for all images
                  },
                ]);
              }}
            >
              <img
                src={mood.img}
                width="100%"
                style={
                  (temperatureSelectType === "single" &&
                    packageObject.selectedMoodId === mood.mood) ||
                  (temperatureSelectType === "multi" &&
                    packageObject.mainTemperature === mood.mood)
                    ? { border: "5px solid #186985" }
                    : {}
                }
              />
              <Typography
                className={[classes.smallTitles, classes.textAlignCenter]}
              >
                {t(mood?.mood_name)}
              </Typography>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default TemperatureSelect;
