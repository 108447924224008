// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import { TOGGLE_LOADING_BACKDROP } from "../constants";

const initialState = {
  message: "",
  open: false,
};

export default createReducer(initialState, {
  [TOGGLE_LOADING_BACKDROP]: (state, payload) => {
    return Object.assign({}, state, {
      ...payload,
    });
  },
});
