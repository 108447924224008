import React from "react";

const FeatureIcon15 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#AutomaticHeadlightsClip0)">
<path d="M29.9 9.7C33.65 10.65 39.4 13.6 42.25 16.1C44.95 18.45 47 22.3 47 25C47 27.7 44.95 31.55 42.2 33.95C37.2 38.35 26.55 42.1 23.3 40.6C20.7 39.4 19 33.35 19 25C19 17.1 20.65 10.75 22.95 9.5C24.2 8.84999 26.6 8.9 29.9 9.7ZM23.4 11.85C22.1 13.3 21.45 16.4 21.15 22.5C20.75 30.9 21.9 37.3 24.1 38.7C26.6 40.25 37.75 35.75 41.65 31.6C45.9 27 45.9 23 41.7 18.5C37 13.45 25.6 9.35 23.4 11.85Z" fill="#07303E"/>
<path d="M17.4 12.85C17.25 13.65 16.4 13.75 9.09999 13.9C1.24999 14 0.999994 14 0.999994 13C0.999994 12.05 1.29999 12 9.29999 12C16.9 12 17.55 12.05 17.4 12.85Z" fill="#07303E"/>
<path d="M16.4 18.85C16.25 19.65 15.4 19.75 8.59998 19.9C1.24998 20 0.999977 20 0.999977 19C0.999977 18.05 1.29998 18 8.79998 18C15.95 18 16.55 18.05 16.4 18.85Z" fill="#07303E"/>
<path d="M16 25C16 25.95 15.65 26 8.5 26C1.35 26 1 25.95 1 25C1 24.05 1.35 24 8.5 24C15.65 24 16 24.05 16 25Z" fill="#07303E"/>
<path d="M16.15 30.35C16.35 30.5 16.5 30.95 16.5 31.35C16.5 31.85 14.85 32 8.75 32C1.35 32 1 31.95 1 31C1 30.05 1.35 30 8.4 30C12.5 30 16 30.15 16.15 30.35Z" fill="#07303E"/>
<path d="M17.15 36.35C17.35 36.5 17.5 36.95 17.5 37.35C17.5 37.85 15.8 38 9.25 38C1.35 38 1 37.95 1 37C1 36.05 1.35 36 8.9 36C13.25 36 17 36.15 17.15 36.35Z" fill="#07303E"/>
<path d="M45.2489 22.3269L45.4817 23H46.194H49.428H50.8496L50.3691 21.6621L44.8471 6.28405L44.6094 5.62201H43.906H40.32H39.6165L39.3788 6.28405L33.8568 21.6621L33.3764 23H34.798H38.054H38.7662L38.999 22.3269L39.7782 20.074H44.4697L45.2489 22.3269ZM42.942 15.61H41.306L42.124 13.2452L42.942 15.61Z" fill="#07303E" stroke="white" strokeWidth="2"/>
</g>
<defs>
<clipPath id="AutomaticHeadlightsClip0">
<rect width="50" height="50" fill="white" transform="matrix(-1 0 0 1 50 0)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon15;
