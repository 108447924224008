import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  root: {
    "& .MuiInputBase-input": {
      height: "100%",
    },
  },
}));

const TextInput = (props) => {
  const {
    tag,
    onChange,
    field,
    answers,
    invokedFields,
    type,
    isdone,
    prefix,
    listOfOptions,
    brands,
    setBrands,
    sizes,
    setSizes,
    disabled,
  } = props;
  const classes = useStyles();

  const prefixSwitcher = (str, pref, old) => {
    if (str == undefined || str == 0 || str == "" || str == null) return "";
    var substr = pref.substr(0, pref.length - 1);
    var formaterswapper = str
      .replaceAll(" " + pref, "")
      .replaceAll(pref, "")
      .replaceAll(substr, "")
      .replaceAll(" " + substr, "")
      .replace(/[^0-9.]/g, "");

    console.log(formaterswapper + " " + pref.trim(), "mmmmm");
    if (formaterswapper == "") {
      return "";
    }

    if (str.endsWith(substr)) {
      var res = [...formaterswapper]
        .splice(0, formaterswapper.length - 1)
        .join("");
      if (res == "") return "";
      console.log(
        "222222220000",
        [...formaterswapper].splice(0, formaterswapper.length - 1).join("") +
          " " +
          pref.trim()
      );
      return `${[...formaterswapper]
        .splice(0, formaterswapper.length - 1)
        .join("")} ${pref.trim()}`;
    } else {
      return `${formaterswapper} ${pref.trim()}`;
    }
  };

  const handleChange = (event, value) => {
    var valueOfinput = value ?? event.target.value;
    let brandsArr = { ...brands };
    if (field.id == 259) {
      brandsArr.first = valueOfinput; //event.target.value;

      setBrands(brandsArr);
    } else if (field.id == 260) {
      brandsArr.second = valueOfinput; //event.target.value;
      setBrands(brandsArr);
    } else if (field.id == 261) {
      brandsArr.third = valueOfinput; // event.target.value;
      setBrands(brandsArr);
    } else if (field.id == 262) {
      brandsArr.fourth = valueOfinput; //event.target.value;
      setBrands(brandsArr);
    }
    let sizesArr = { ...sizes };
    if (field.id == 57) {
      sizesArr.first = valueOfinput; //event.target.value;
      setSizes(sizesArr);
    } else if (field.id == 64) {
      sizesArr.second = valueOfinput; //event.target.value;
      setSizes(sizesArr);
    } else if (field.id == 71) {
      sizesArr.third = valueOfinput; // event.target.value;
      setSizes(sizesArr);
    } else if (field.id == 78) {
      sizesArr.fourth = valueOfinput; //event.target.value;
      setSizes(sizesArr);
    }

    if (tag) {
      console.log("xxxxxxxxx", tag);
      onChange(event, {
        score: valueOfinput, // event.target.value,
        text: answers[field?.id]?.text,
        field: field,
      });
      // onChange(event, { text: event.target.value, field: field });
    } else if (type === "tel") {
      onChange(event, {
        text: valueOfinput.replace(/[^0-9.]/g, ""), //event.target.value.replace(/[^0-9.]/g, ""),
        field: field,
      });
    } else {
      onChange(event, {
        text: !prefix
          ? valueOfinput.replaceAll(",", "") //event.target.value.replaceAll(",", "")
          : prefixSwitcher(valueOfinput.replaceAll(",", ""), prefix),
        //  prefixSwitcher(event.target.value.replaceAll(",", ""), prefix),
        field: field,
      });
    }
  };

  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  return (
    <FormControl fullWidth>
      <Autocomplete
        value={props.value}
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={Object.values(listOfOptions ?? {}) ?? []}
        disabled={disabled}
        onChange={(e, value) => handleChange(e, value)}
        renderInput={(params) => (
          <>
            <TextField
              {...props}
              {...params}
              inputProps={Object.assign(
                {},
                params.inputProps,
                props.inputProps
              )}
              fullWidth
              className={classes.root}
              onChange={handleChange}
              InputLabelProps={{
                className:
                  handleInvokedField() && isdone
                    ? classes.switch_track
                    : classes.switch_base,
              }}
              InputProps={{
                ...params.InputProps,
                type: type,
              }}
            />
          </>
        )}
      />
    </FormControl>
  );
};

export default TextInput;
