import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { publishCarForBuyers, publishCarForSeller } from "../store/actions/car";
import { checkVisFormsPoints } from "../store/actions/forms";
import { toggleNotification } from "../store/actions/notification";

import { useTranslation } from "react-i18next";
import GPS from "../icons/gps.svg";
import Tooltip from "@material-ui/core/Tooltip";
import PublishDialog from "./PublishDialog";
import {
  distanceWithCommas,
  numberWithCommas,
  timerSinceDisplay,
} from "../helpers/functions";
import sellerTag from "../images/sellerTag.png";
import sellerTagAr from "../images/sellerTagAr.png";
import smallLogo from "../icons/markabteSmallLogo.svg";

const useStyles = makeStyles((theme) => ({
  firstPaper: {
    margin: "7.5px 0",
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    borderRadius: "0",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0",
    },
  },
  imagePaper: {
    // maxHeight: 235,
    minHeight: "200px",
    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      // minHeight: "343px",
    },
    [theme.breakpoints.up("lg")]: {
      // minHeight: 235,
    },
  },
  Image: {
    // backgroundColor: "rgba(32,32,32,0.7)",
    // filter: "blur(12px)",
    // height: "235px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      // height: "343px",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "235px",
    },
    // [theme.breakpoints.up("xl")]: {
    // },
  },
  overlayDiv: {
    backgroundColor: "rgba(0,0,0,0.59)",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    padding: "4px 6px",
    [theme.breakpoints.down("sm")]: {
      bottom: "-6px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "-8px",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "-4px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-8px",
    },
  },
  overlaySecondTypography: {
    marginLeft: "auto",
  },
  listedSinceTypography: {
    fontSize: "8px",
    color: "white",
    fontWeight: 500,
    margin: "0 1px",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "8px",
    },
  },
  locationIcon: {
    marginRight: "4px",
    width: "8px",
    [theme.breakpoints.up("md")]: {
      width: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "8px",
    },
  },
  locationTypography: {
    fontSize: "8px",
    color: "white",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "8px",
    },
  },
  dialogContentText: {
    padding: "0",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  milageTypography: {
    fontSize: "12px",
    color: "#07303E",
    padding: "0 12px 0 5px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  firstButton: {
    padding: "0 4px 0 0",
    [theme.breakpoints.up("md")]: {
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 4px 0 0",
    },
  },
  firstButtonButton: {
    margin: "16px 0 8px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  secondButton: {
    padding: "0 0 0 4px",
    [theme.breakpoints.up("md")]: {
      flexGrow: "0",
      flexBasis: "100%",
      maxWidth: "100%",
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      flexGrow: "0",
      flexBasis: "50%",
      maxWidth: "50%",
      padding: "0 0 0 4px",
    },
  },
  secondButtonButton: {
    margin: "16px 0 8px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  Button: {
    margin: "17px 0 7px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      margin: "auto 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "17px 0 1px",
    },
  },
  buttonGridContainer: {
    borderTop: "0.5px solid #c4c4c4",
    // padding: "0 16px",
    width: "calc(100% + 16px)",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      borderTop: "none",
      ////new
      margin: "-25px -8px 0",
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
      padding: "0 0 0 20px",
      ////////
      alignContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      borderTop: "0.5px solid #c4c4c4",
      ////new
      margin: "0",
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 0,
      ////////
    },
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainTextDiv: {
    padding: "4px 8px 8px",
    zIndex: 2,
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: "4px 8px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "4px 8px 8px",
    },
  },
  lastRowTypography: {
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  sellerTag: {
    position: "absolute",
    zIndex: "1",
    top: "0",
    left: "0",
  },
  titleIDRowDiv: {
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  inspectedDiv: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
    WebkitUserSelect: "none",
    backgroundColor: "rgba(24, 105, 133, 0.05)",
    padding: "3px 8px",
    borderRadius: "50em",
  },
  inspectedTypography: {
    color: "#626465",
    fontSize: "11px",
    marginLeft: "4px",
  },
  typoChip: {
    whiteSpace: "nowrap",
    fontSize: "14px",
    marginBottom: "5px",
  },
}));

const CarCard = (props) => {
  const {
    publishCarForBuyers,
    publishCarForSeller,
    car_id,
    make,
    model,
    trim,
    year,
    milage,
    fuelType,
    image,
    price,
    score,

    dealershipLocation,
    dealershipArea,
    createdAt,
    enteredByFullName,
    onClick, ////
    onClick2,
    uom,
    driveType,
    countrySpecs,
    engineSize,
    batterySize,
    checkVisFormsPoints,
    toggleNotification,
    publishedForSeller,
  } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const getScoreColor = (x) => {
    if (isNaN(x)) {
      return "#07303E";
    }
    if (x <= 20) {
      return "#D40000";
    } else if (x <= 30) {
      return "#EA3800";
    } else if (x <= 40) {
      return "#EA5400";
    } else if (x <= 50) {
      return "#FF7A00";
    } else if (x <= 60) {
      return "#FFA800";
    } else if (x <= 70) {
      return "#FFD600";
    } else if (x <= 80) {
      return "#C5E500";
    } else if (x <= 90) {
      return "#5BCE00";
    } else if (x <= 100) {
      return "#00A900";
    }
  };
  const [open, setOpen] = React.useState(false);
  const [carId, setCarId] = React.useState();
  // var difference = new Date() - new Date(createdAt);
  // var days = 1000 * 60 * 60 * 24; //milli_sec sec min hour
  // var listedSince = Math.floor(difference / days); //to limit the difference to days and not hours,...
  var listedSinceObj = timerSinceDisplay(new Date(createdAt));
  var listedSince = listedSinceObj.time;
  var listedSinceUnit = listedSinceObj.unit;
  // const icons = [Odometer, Transmission, Fuel, Rating, Score, Location]; //to use in map function and render out icons
  const data = [fuelType, engineSize, driveType, countrySpecs]; //to use in map function and render out data

  const handleClickOpen = (e) => {
    setCarId(car_id);
    e.stopPropagation();
    setOpen(true);
    document.getElementById("root").style.filter = "blur(16px)"; // adds blur to root element
  };

  //////////////////
  const handleClose = () => {
    setOpen(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
  };
  const handelPublishCar = (value) => {
    checkVisFormsPoints({
      // check if all points are entered then publish
      carId: car_id,
      callback: (response) => {
        response.length === 0 && value == "Buyers and Seller"
          ? publishCarForBuyers({
              publish: true,
              car_id: car_id,
              callback: (res) => {
                if (res.result) {
                }
              },
            })
          : response.length === 0 && value == "Seller"
          ? publishCarForSeller({ publish: true, car_id: car_id })
          : toggleNotification({
              variant: "error",
              open: true,
              message: t(
                `Please check the visualisation points for the following forms` +
                  ` ${response}`
              ),
            });
      },
    });
    // publishCar({ publish: true, car_id: car_id });
    handleClose();
  };
  //////////////////
  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} style={{ minWidth: "290px" }}>
        <Paper className={classes.firstPaper}>
          <Grid container spacing={2} alignContent={"space-between"}>
            <Grid
              item
              xs={12}
              md={12}
              onClick={() => {
                props.history.push(`/car/${car_id}`);
              }}
              className={classes.cardHover}
            >
              {publishedForSeller ? (
                <img
                  className={classes.sellerTag}
                  src={
                    localStorage.getItem("language") == "en"
                      ? sellerTag
                      : sellerTagAr
                  }
                />
              ) : (
                ""
              )}
              <Paper style={{ height: "102%" }} className={classes.imagePaper}>
                <img
                  loading="lazy"
                  src={image}
                  alt={"car_thumbnail"}
                  width={"100%"}
                  height={this?.container?.offsetWidth + "px"}
                  className={classes.Image}
                />
                <div
                  // style={{
                  //   backgroundColor: "rgba(0,0,0,0.59)",
                  //   position: "absolute",
                  //   bottom: 0,
                  //   right: 0,
                  //   left: 0,
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   padding: "4px 6px",
                  // }}
                  className={classes.overlayDiv}
                >
                  <img
                    src={GPS}
                    alt="gps_icon"
                    className={classes.locationIcon}
                  />
                  <Typography
                    // style={{ fontSize: "8px", color: "white", fontWeight: 500 }}
                    className={classes.locationTypography}
                  >
                    {dealershipLocation}, {dealershipArea}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "8px",
                      color: "white",
                      fontWeight: 500,
                      display: "flex",
                    }}
                    className={classes.overlaySecondTypography}
                  >
                    <Typography
                      // style={{
                      //   fontSize: "8px",
                      //   color: "white",
                      //   fontWeight: 500,
                      //   margin: "0 1px",
                      // }}
                      className={classes.listedSinceTypography}
                    >
                      {enteredByFullName}
                    </Typography>{" "}
                    <Typography
                      // style={{
                      //   fontSize: "8px",
                      //   color: "white",
                      //   fontWeight: 500,
                      //   margin: "0 1px",
                      // }}
                      className={classes.listedSinceTypography}
                    >
                      {` | ${listedSince} ${t(listedSinceUnit)} ${t("ago")}`}
                    </Typography>
                  </Typography>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div
                onClick={() => {
                  props.history.push(`/car/${car_id}`);
                }}
                style={
                  {
                    // // borderBottom: "0.5px solid #c4c4c4",
                    // padding: "4px 8px 8px",
                    // zIndex: 2,
                    // position: "relative",
                  }
                }
                className={clsx({
                  [classes.mainTextDiv]: true,
                  [classes.cardHover]: true,
                })}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className={classes.titleIDRowDiv}
                >
                  <Typography
                    children={
                      localStorage.getItem("language") === "ar"
                        ? `${numberWithCommas(price)} ${t("JD")}`
                        : `${t("JD")} ${numberWithCommas(price)}`
                    }
                    style={
                      localStorage.getItem("language") === "en"
                        ? {
                            fontWeight: "600",
                            color: "#f15a29",
                            marginBottom: "5px",
                          }
                        : {
                            fontWeight: "700",
                            color: "#f15a29",
                            marginBottom: "5px",
                          }
                    }
                  />
                  <div
                    style={{
                      background: "#F3F3F3",
                      fontSize: "14px",
                      padding: "4px 6px",
                      color: "#636363",
                    }}
                  >
                    ID #{car_id}
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12}>
                    <Tooltip
                      disableFocusListener
                      title={`${year}  ${make}  ${model}  ${trim}`}
                    >
                      <div style={{ display: "flex", overflow: "hidden" }}>
                        <Typography
                          children={year}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}
                        <Typography
                          children={make}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}

                        <Typography
                          children={model}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}

                        <Typography
                          children={trim}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: "#07303E",
                                }
                          }
                        />
                      </div>
                    </Tooltip>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {data.map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // overflow: "hidden",////new
                          }}
                        >
                          <Typography ///fix at user
                            children={
                              item != "N/A"
                                ? item
                                : index == 1
                                ? batterySize
                                : "N/A"
                            }
                            style={{
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#626465",
                              marginBottom: "5px",
                              whiteSpace: "nowrap",
                              direction: index == 1 && "ltr", ////new
                              // textOverflow: "ellipsis",////new
                              // overflow: "hidden",////new
                            }}
                          />
                          {index === data.length - 1 ? null : (
                            <Typography
                              children="|"
                              style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#C4C4C4",
                                padding: "0 5px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600", // i think 500
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                          }
                          className={classes.lastRowTypography}
                        >
                          {t("Odometer")}:{" "}
                        </Typography>
                        <Typography
                          children={distanceWithCommas(milage)}
                          className={classes.milageTypography}
                          style={
                            localStorage.getItem("language") === "en"
                              ? { color: "#07303E" }
                              : {
                                  color: "#07303E",
                                  fontWeight: 600,
                                }
                          }
                        />
                      </div>
                      <div className={classes.inspectedDiv}>
                        <img src={smallLogo} width="12px" />
                        <Typography className={classes.inspectedTypography}>
                          {t("Inspected")}
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  {/* ////////////////////////////////////// */}
                  <Grid
                    container
                    // style={{
                    //   padding: "0 16px",
                    //   width: "calc(100% + 16px)",
                    // margin: "0 -8px",
                    // }}
                    className={classes.buttonGridContainer} ///???
                  >
                    {" "}
                    {/*adding this padding causes long button text to wrap due to less space fix*/}
                    <Grid
                      item
                      xs={6}
                      md={12}
                      lg={6}
                      style={{ display: "grid" }}
                      className={classes.firstButton}
                    >
                      <Button
                        // style={{
                        //   margin: "16px 0 8px",
                        //   padding: "8px 24px",
                        //   borderRadius: "5px",
                        //   textTransform: "none",
                        //   whiteSpace: "nowrap",
                        //   fontSize: "clamp(12px,1vw,14px)",
                        // }}
                        children={t("Publish Vehicle")}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleClickOpen}
                        className={classes.firstButtonButton}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "grid" }}
                      className={classes.secondButton}
                    >
                      <Button
                        // style={{
                        //   margin: "16px 0 8px",
                        //   padding: "8px 24px",
                        //   borderRadius: "5px",
                        //   textTransform: "none",
                        //   whiteSpace: "nowrap",
                        //   fontSize: "clamp(12px,1vw,14px)",
                        // }}
                        children={t("Edit Content")}
                        variant={"outlined"}
                        color={"primary"}
                        onClick={(event) => {
                          event.stopPropagation();
                          props.history.push("/car-edit/" + car_id);
                        }}
                        className={classes.secondButtonButton}
                      />
                    </Grid>
                  </Grid>
                  {/* ////////////////////////////////////// */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <PublishDialog
        carId={carId}
        open={open}
        handleClose={handleClose}
        handelPublishCar={handelPublishCar}
      />
    </Grid>
  );
};

CarCard.defaultProps = {
  make: "Make",
  model: "Model",
  year: "Year",
  dealershipLocation: "Location",
  milage: "Milage",
  fuelType: "Fuel Type",
  engineSize: "Engine Size",
  onClick: () => {},
};

CarCard.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.string,
  dealershipLocation: PropTypes.string,
  milage: PropTypes.string,
  fuelType: PropTypes.string,
  engineSize: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  car: state.car.car,
});

const mapDispatchToProps = (dispatch) => ({
  publishCarForBuyers(payload) {
    dispatch(publishCarForBuyers(payload));
  },
  publishCarForSeller(payload) {
    dispatch(publishCarForSeller(payload));
  },
  checkVisFormsPoints(payload) {
    dispatch(checkVisFormsPoints(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarCard)
);
