import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getPaparazziCarImages,
  getPaparazziShadows,
  getTestPaparazziPackage,
} from "../../store/actions/forms";
import { toggleNotification } from "../../store/actions/notification";
import PapaPopup from "./PapaPopup";
import StackedImageComponent from "./StackedImageComponent";
import TemperatureSelect from "./TemperatureSelect";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "calc(100% - 32px)",
    padding: "16px",
    overflow: "scroll",
  },
  imageTitleDiv: {
    display: "flex",
    marginBlock: "8px",
    alignItems: "baseline",
  },
  title: {
    fontWeight: 600,
    color: "#07303E",
  },
  tempTitle: {
    fontSize: "14px",
    color: "#9F9F9F",
  },
}));

const PapaHubImageContainer = (props) => {
  const {
    imageType,
    getPaparazziCarImages,
    getPaparazziShadows,
    carId,
    showFrame,
    bodyTypeID,
    sunLocation,
    selectedPackage,
    suggestedPackage,
    getTestPaparazziPackage,
    toggleNotification,
    imagesStates,
    mainTempProps,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [openSingleTemp, setOpenSingleTemp] = useState(null);

  const {
    savedImages,
    setSavedImages,
    shadowImages,
    setShadowImages,
    backgroundImages,
    setBackgroundImages,
    selectedBackgroundImages,
    setSelectedBackgroundImages,
    suggestedBackgroundImages,
    setSuggestedBackgroundImages,
  } = imagesStates;
  const { openMainTemperaturePopup, setOpenMainTemperaturePopup } =
    mainTempProps;

  useEffect(() => {
    // here i get the saved images for first view (saved image, field title and id), also i get "NoBg" images from it
    getPaparazziCarImages({
      carId: carId,
      callback: (result) => {
        setSavedImages(result);
      },
    });
  }, []);

  useEffect(() => {
    getPaparazziShadows({
      bodyType: bodyTypeID ?? 1, // 1 Sedan for when body type is not answered
      sun_location: bodyTypeID ? sunLocation : 4288, // 4288 Middle - Top
      callback: (resp) => {
        setShadowImages(resp.results);
      },
    });
  }, [sunLocation, bodyTypeID]);

  useEffect(() => {
    getTestPaparazziPackage({
      package_id: selectedPackage.id,
      callback: (papaPackage) => {
        setSelectedBackgroundImages(papaPackage);
      },
    });
  }, [selectedPackage]);

  useEffect(() => {
    getTestPaparazziPackage({
      package_id: suggestedPackage.package,
      callback: (papaPackage) => {
        setSuggestedBackgroundImages(papaPackage);
      },
    });
  }, [suggestedPackage]);

  useEffect(() => {
    if (imageType === "manual") {
      setBackgroundImages(selectedBackgroundImages);
    }
    if (imageType === "suggested") {
      setBackgroundImages(suggestedBackgroundImages);
    }
  }, [imageType, selectedBackgroundImages, suggestedBackgroundImages]);

  const changeSingleImage = (imageArray, imageObject) => {
    let tempArray = [...imageArray];
    let imageIndex = tempArray
      .map((imageObj) => imageObj.image_field)
      .indexOf(imageObject.image_field);
    if (imageIndex !== -1) {
      tempArray.splice(imageIndex, 1, {
        ...tempArray?.[imageIndex],
        ...imageObject,
      });
      return tempArray;
    }
  };

  const setTemperature = (selectionType, newBgArr) => {
    // newBgArr Structure is {fieldId:fieldId, image:image}
    if (selectionType === "manual") {
      if (newBgArr.length === 1) {
        // single
        let newArray = changeSingleImage(selectedBackgroundImages, newBgArr[0]);
        setSelectedBackgroundImages(newArray);
      } else {
        // all
        setSelectedBackgroundImages(newBgArr);
      }
    } else if (selectionType === "suggested") {
      if (newBgArr.length === 1) {
        // single
        let newArray = changeSingleImage(selectedBackgroundImages, newBgArr[0]);
        setSuggestedBackgroundImages(newArray);
      } else {
        // all
        setSuggestedBackgroundImages(newBgArr);
      }
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={2}>
        {savedImages.map((item) => (
          <>
            <Grid
              item
              xs={4}
              onClick={() => {
                if (backgroundImages.length && imageType !== "original") {
                  setOpenSingleTemp(item.field_id);
                } else {
                  toggleNotification({
                    variant: "warning",
                    open: true,
                    message: t(
                      "Please select a background type other than Original and select a background first"
                    ),
                  });
                }
              }}
            >
              <div className={classes.imageTitleDiv}>
                <Typography className={classes.title}>
                  {t(item.field_title)}
                </Typography>
                {"\u00A0"}

                {imageType &&
                  imageType !== "original" &&
                  !!backgroundImages.length && (
                    <Typography className={classes.tempTitle}>
                      {`(${t(
                        backgroundImages?.filter(
                          (packageObj) =>
                            packageObj.image_field === item.field_id
                        )?.[0]?.singleTemperature ??
                          backgroundImages?.filter(
                            (packageObj) =>
                              packageObj.image_field === item.field_id
                          )?.[0]?.mainTemperatureName ??
                          "neutral"
                      )})`}
                    </Typography>
                  )}
              </div>

              <StackedImageComponent
                imageType={imageType}
                showFrame={showFrame}
                savedImage={item.image}
                fieldId={item.field_id}
                backgroundImage={
                  backgroundImages?.filter(
                    (packageObj) => packageObj.image_field === item.field_id
                  )?.[0]?.image_url
                }
                shadowImage={
                  shadowImages?.filter(
                    (shadowObj) => shadowObj.image_field === item.field_id
                  )?.[0]?.image_url
                }
                noBgImage={item?.image
                  ?.replace("Origional", "NoBg")
                  ?.replace("Papa", "NoBg")}
              />
            </Grid>
            <PapaPopup
              open={openSingleTemp === item.field_id}
              onClose={() => setOpenSingleTemp(null)}
              title={item.field_title}
            >
              <TemperatureSelect
                originalImage={item?.image?.replace("Papa", "Origional")}
                packageObject={
                  backgroundImages?.filter(
                    (packageObj) => packageObj.image_field === item.field_id
                  )?.[0]
                }
                setTemperature={(imageArray) => {
                  let tempArray = [...imageArray];
                  let singleTemperature = imageArray?.[0]?.selectedMoodName;

                  tempArray.splice(0, 1, {
                    ...tempArray?.[0],
                    singleTemperature,
                  });

                  setTemperature(imageType, tempArray);
                }}
                temperatureSelectType="single"
              />
            </PapaPopup>
          </>
        ))}

        <PapaPopup
          open={openMainTemperaturePopup}
          onClose={() => setOpenMainTemperaturePopup(false)}
          title={t("Change Temperatue For All Images")}
        >
          <TemperatureSelect
            originalImage={
              savedImages
                .filter((item) => item?.field_id === 639)?.[0]
                ?.image?.replace("Papa", "Origional") ??
              savedImages?.[0]?.image?.replace("Papa", "Origional")
            } // 639 => Left Front Angle ?? First image
            packageObject={
              backgroundImages?.filter((item) => item?.image_field === 639)?.[0]
            }
            setTemperature={(imageArray) => {
              let selectedMood = imageArray?.[0]?.selectedMoodId;
              let selectedMoodName = imageArray?.[0]?.selectedMoodName;

              setTemperature(
                imageType,
                backgroundImages.map((obj) => {
                  return {
                    ...obj,
                    image_url: obj.moods.filter(
                      (moodObj) => moodObj.mood === selectedMood
                    )?.[0]?.img,
                    mainTemperatureName: selectedMoodName,
                    mainTemperature: selectedMood,
                    selectedMoodId: selectedMood, // set selectedMoodId initially (used for single) to show selected at first entrance after selecting mainTemp also to reset every single mode after selecting mainTemp
                  };
                })
              );
            }}
            temperatureSelectType="multi"
          />
        </PapaPopup>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  carId: state.car.car.id,
  bodyTypeID: state.car.car?.body_type_config?.configuration?.id,
});

const mapDispatchToProps = (dispatch) => ({
  getPaparazziCarImages(payload) {
    dispatch(getPaparazziCarImages(payload));
  },
  getPaparazziShadows(payload) {
    dispatch(getPaparazziShadows(payload));
  },
  getTestPaparazziPackage(payload) {
    dispatch(getTestPaparazziPackage(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PapaHubImageContainer);
