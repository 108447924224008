import React from "react";

const FeatureIcon41 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M11.9828 19.5731V21.4091H14.1428V22.2461H11.9828V24.1631H14.4128V25.0001H10.9568V18.7361H14.4128V19.5731H11.9828Z" fill="#07303E"/>
<path d="M18.1785 19.9601C18.5685 19.9601 18.9165 20.0411 19.2225 20.2031C19.5345 20.3651 19.7775 20.6051 19.9515 20.9231C20.1255 21.2411 20.2125 21.6251 20.2125 22.0751V25.0001H19.1955V22.2281C19.1955 21.7841 19.0845 21.4451 18.8625 21.2111C18.6405 20.9711 18.3375 20.8511 17.9535 20.8511C17.5695 20.8511 17.2635 20.9711 17.0355 21.2111C16.8135 21.4451 16.7025 21.7841 16.7025 22.2281V25.0001H15.6765V20.0411H16.7025V20.6081C16.8705 20.4041 17.0835 20.2451 17.3415 20.1311C17.6055 20.0171 17.8845 19.9601 18.1785 19.9601Z" fill="#07303E"/>
<path d="M23.5192 19.9601C23.9032 19.9601 24.2422 20.0381 24.5362 20.1941C24.8362 20.3441 25.0702 20.5331 25.2382 20.7611V20.0411H26.2732V25.0811C26.2732 25.5371 26.1772 25.9421 25.9852 26.2961C25.7932 26.6561 25.5142 26.9381 25.1482 27.1421C24.7882 27.3461 24.3562 27.4481 23.8522 27.4481C23.1802 27.4481 22.6222 27.2891 22.1782 26.9711C21.7342 26.6591 21.4822 26.2331 21.4222 25.6931H22.4392C22.5172 25.9511 22.6822 26.1581 22.9342 26.3141C23.1922 26.4761 23.4982 26.5571 23.8522 26.5571C24.2662 26.5571 24.5992 26.4311 24.8512 26.1791C25.1092 25.9271 25.2382 25.5611 25.2382 25.0811V24.2531C25.0642 24.4871 24.8272 24.6851 24.5272 24.8471C24.2332 25.0031 23.8972 25.0811 23.5192 25.0811C23.0872 25.0811 22.6912 24.9731 22.3312 24.7571C21.9772 24.5351 21.6952 24.2291 21.4852 23.8391C21.2812 23.4431 21.1792 22.9961 21.1792 22.4981C21.1792 22.0001 21.2812 21.5591 21.4852 21.1751C21.6952 20.7911 21.9772 20.4941 22.3312 20.2841C22.6912 20.0681 23.0872 19.9601 23.5192 19.9601ZM25.2382 22.5161C25.2382 22.1741 25.1662 21.8771 25.0222 21.6251C24.8842 21.3731 24.7012 21.1811 24.4732 21.0491C24.2452 20.9171 23.9992 20.8511 23.7352 20.8511C23.4712 20.8511 23.2252 20.9171 22.9972 21.0491C22.7692 21.1751 22.5832 21.3641 22.4392 21.6161C22.3012 21.8621 22.2322 22.1561 22.2322 22.4981C22.2322 22.8401 22.3012 23.1401 22.4392 23.3981C22.5832 23.6561 22.7692 23.8541 22.9972 23.9921C23.2312 24.1241 23.4772 24.1901 23.7352 24.1901C23.9992 24.1901 24.2452 24.1241 24.4732 23.9921C24.7012 23.8601 24.8842 23.6681 25.0222 23.4161C25.1662 23.1581 25.2382 22.8581 25.2382 22.5161Z" fill="#07303E"/>
<path d="M28.1428 19.3841C27.9568 19.3841 27.8008 19.3211 27.6749 19.1951C27.5489 19.0691 27.4858 18.9131 27.4858 18.7271C27.4858 18.5411 27.5489 18.3851 27.6749 18.2591C27.8008 18.1331 27.9568 18.0701 28.1428 18.0701C28.3228 18.0701 28.4759 18.1331 28.6019 18.2591C28.7278 18.3851 28.7908 18.5411 28.7908 18.7271C28.7908 18.9131 28.7278 19.0691 28.6019 19.1951C28.4759 19.3211 28.3228 19.3841 28.1428 19.3841ZM28.6469 20.0411V25.0001H27.6208V20.0411H28.6469Z" fill="#07303E"/>
<path d="M32.4959 19.9601C32.8859 19.9601 33.2339 20.0411 33.5399 20.2031C33.8519 20.3651 34.0949 20.6051 34.2689 20.9231C34.4429 21.2411 34.5299 21.6251 34.5299 22.0751V25.0001H33.5129V22.2281C33.5129 21.7841 33.4019 21.4451 33.1799 21.2111C32.9579 20.9711 32.6549 20.8511 32.2709 20.8511C31.8869 20.8511 31.5809 20.9711 31.3529 21.2111C31.1309 21.4451 31.0199 21.7841 31.0199 22.2281V25.0001H29.9939V20.0411H31.0199V20.6081C31.1879 20.4041 31.4009 20.2451 31.6589 20.1311C31.9229 20.0171 32.2019 19.9601 32.4959 19.9601Z" fill="#07303E"/>
<path d="M40.3836 22.3991C40.3836 22.5851 40.3716 22.7531 40.3476 22.9031H36.5586C36.5886 23.2991 36.7356 23.6171 36.9996 23.8571C37.2636 24.0971 37.5876 24.2171 37.9716 24.2171C38.5236 24.2171 38.9136 23.9861 39.1416 23.5241H40.2486C40.0986 23.9801 39.8256 24.3551 39.4296 24.6491C39.0396 24.9371 38.5536 25.0811 37.9716 25.0811C37.4976 25.0811 37.0716 24.9761 36.6936 24.7661C36.3216 24.5501 36.0276 24.2501 35.8116 23.8661C35.6016 23.4761 35.4966 23.0261 35.4966 22.5161C35.4966 22.0061 35.5986 21.5591 35.8026 21.1751C36.0126 20.7851 36.3036 20.4851 36.6756 20.2751C37.0536 20.0651 37.4856 19.9601 37.9716 19.9601C38.4396 19.9601 38.8566 20.0621 39.2226 20.2661C39.5886 20.4701 39.8736 20.7581 40.0776 21.1301C40.2816 21.4961 40.3836 21.9191 40.3836 22.3991ZM39.3126 22.0751C39.3066 21.6971 39.1716 21.3941 38.9076 21.1661C38.6436 20.9381 38.3166 20.8241 37.9266 20.8241C37.5726 20.8241 37.2696 20.9381 37.0176 21.1661C36.7656 21.3881 36.6156 21.6911 36.5676 22.0751H39.3126Z" fill="#07303E"/>
<path d="M14.2495 36.0631C13.8115 36.0631 13.4155 35.9881 13.0615 35.8381C12.7135 35.6881 12.4375 35.4721 12.2335 35.1901C12.0295 34.9081 11.9245 34.5751 11.9185 34.1911H13.2685C13.2865 34.4491 13.3765 34.6531 13.5385 34.8031C13.7065 34.9531 13.9345 35.0281 14.2225 35.0281C14.5165 35.0281 14.7475 34.9591 14.9155 34.8211C15.0835 34.6771 15.1675 34.4911 15.1675 34.2631C15.1675 34.0771 15.1105 33.9241 14.9965 33.8041C14.8825 33.6841 14.7385 33.5911 14.5645 33.5251C14.3965 33.4531 14.1625 33.3751 13.8625 33.2911C13.4545 33.1711 13.1215 33.0541 12.8635 32.9401C12.6115 32.8201 12.3925 32.6431 12.2065 32.4091C12.0265 32.1691 11.9365 31.8511 11.9365 31.4551C11.9365 31.0831 12.0295 30.7591 12.2155 30.4831C12.4015 30.2071 12.6625 29.9971 12.9985 29.8531C13.3345 29.7031 13.7185 29.6281 14.1505 29.6281C14.7985 29.6281 15.3235 29.7871 15.7255 30.1051C16.1335 30.4171 16.3585 30.8551 16.4005 31.4191H15.0145C15.0025 31.2031 14.9095 31.0261 14.7355 30.8881C14.5675 30.7441 14.3425 30.6721 14.0605 30.6721C13.8145 30.6721 13.6165 30.7351 13.4665 30.8611C13.3225 30.9871 13.2505 31.1701 13.2505 31.4101C13.2505 31.5781 13.3045 31.7191 13.4125 31.8331C13.5265 31.9411 13.6645 32.0311 13.8265 32.1031C13.9945 32.1691 14.2285 32.2471 14.5285 32.3371C14.9365 32.4571 15.2695 32.5771 15.5275 32.6971C15.7855 32.8171 16.0075 32.9971 16.1935 33.2371C16.3795 33.4771 16.4725 33.7921 16.4725 34.1821C16.4725 34.5181 16.3855 34.8301 16.2115 35.1181C16.0375 35.4061 15.7825 35.6371 15.4465 35.8111C15.1105 35.9791 14.7115 36.0631 14.2495 36.0631Z" fill="#07303E"/>
<path d="M21.8399 29.7181V30.7351H20.1659V36.0001H18.9059V30.7351H17.2319V29.7181H21.8399Z" fill="#07303E"/>
<path d="M26.6112 34.8031H24.1092L23.6952 36.0001H22.3722L24.6312 29.7091H26.0982L28.3572 36.0001H27.0252L26.6112 34.8031ZM26.2692 33.7951L25.3602 31.1671L24.4512 33.7951H26.2692Z" fill="#07303E"/>
<path d="M32.4416 36.0001L31.0556 33.5521H30.4616V36.0001H29.2016V29.7181H31.5596C32.0456 29.7181 32.4596 29.8051 32.8016 29.9791C33.1436 30.1471 33.3986 30.3781 33.5666 30.6721C33.7406 30.9601 33.8276 31.2841 33.8276 31.6441C33.8276 32.0581 33.7076 32.4331 33.4676 32.7691C33.2276 33.0991 32.8706 33.3271 32.3966 33.4531L33.8996 36.0001H32.4416ZM30.4616 32.6071H31.5146C31.8566 32.6071 32.1116 32.5261 32.2796 32.3641C32.4476 32.1961 32.5316 31.9651 32.5316 31.6711C32.5316 31.3831 32.4476 31.1611 32.2796 31.0051C32.1116 30.8431 31.8566 30.7621 31.5146 30.7621H30.4616V32.6071Z" fill="#07303E"/>
<path d="M39.2422 29.7181V30.7351H37.5682V36.0001H36.3082V30.7351H34.6342V29.7181H39.2422Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2C12.2975 2 2 12.2975 2 25C2 37.7025 12.2975 48 25 48ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#07303E"/>
<path d="M20 9.5C20 8.67157 20.6716 8 21.5 8H28.5C29.3284 8 30 8.67157 30 9.5C30 10.3284 29.3284 11 28.5 11H21.5C20.6716 11 20 10.3284 20 9.5Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 7H28.5C29.8807 7 31 8.11929 31 9.5C31 10.8807 29.8807 12 28.5 12H21.5C20.1193 12 19 10.8807 19 9.5C19 8.11929 20.1193 7 21.5 7ZM21.5 8C20.6716 8 20 8.67157 20 9.5C20 10.3284 20.6716 11 21.5 11H28.5C29.3284 11 30 10.3284 30 9.5C30 8.67157 29.3284 8 28.5 8H21.5Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon41;
