import {
  FETCH_FORMS_REQUEST,
  FETCH_SUBFORMS_REQUEST,
  UPDATE_ANSWERS,
  UPDATE_FOEMFIELDS,
  UPDATE_HIDDEN,
  UPDATE_FEATURES,
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_OPTIONS_REQUEST,
  FETCH_GENERIC_FEATURES_REQUEST,
  UPDATE_POINTS,
  UPDATE_GENERIC_FEATURES,
  FETCH_MODEL_OPTIONS_REQUEST,
  RESET_NOTIFICATION,
  TRIGGER_UPDATED_FORMS,
  TRIGGER_UPDATED_FIELDS,
  CHECK_VIS_FORMS_POINTS,
  FETCH_FIELDS_COUNT_REQUEST,
  CLEAR_ANSWERS,
  FILTER_FORMS_OK,
  GET_MAP,
  UPDATE_CAR_POINTS,
  CALCULATE_EAGLE_EYE,
  CLEAR_SAVED_FORM_DATA,
  TOTAL_TIME,
  DTC_FAULTS,
  FETCH_PAPARAZZI_PACKAGE_REQUEST,
  UPDATE_PAPARAZZI_IMAGE,
  UPDATE_PAPARAZZI_VIS_IMAGE,
  FETCH_PAPARAZZI_CAR_IMAGES_REQUEST,
  FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST,
  FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST,
  FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST,
  ADD_VIDEO_IMAGE,
  REMOVE_SELECTED_VIDEO_IMAGES,
  CLEAR_VIDEO_IMAGES,
  TOGGLE_SELECTED_IMAGE,
} from "../constants";

export function getForms(payload) {
  return {
    type: FETCH_FORMS_REQUEST,
    payload: payload,
  };
}

export function getSubforms(payload) {
  return {
    type: FETCH_SUBFORMS_REQUEST,
    payload: payload,
  };
}

export function getFields(payload) {
  return {
    type: FETCH_FIELDS_REQUEST,
    payload: payload,
  };
}

export function resetNotification(payload) {
  return {
    type: RESET_NOTIFICATION,
    payload: payload,
  };
}

export function getModelOption(payload) {
  return {
    type: FETCH_MODEL_OPTIONS_REQUEST,
    payload: payload,
  };
}

export function getGenericFeatures(payload) {
  return {
    type: FETCH_GENERIC_FEATURES_REQUEST,
    payload: payload,
  };
}

export function updateAnswers(payload) {
  return {
    type: UPDATE_ANSWERS,
    payload: payload,
  };
}

export function updateformFields(payload) {
  return {
    type: UPDATE_FOEMFIELDS,
    payload: payload,
  };
}

export function updateHidden(payload) {
  return {
    type: UPDATE_HIDDEN,
    payload: payload,
  };
}

export function updateFeatures(payload) {
  return {
    type: UPDATE_FEATURES,
    payload: payload,
  };
}

export function updatePoints(payload) {
  return {
    type: UPDATE_POINTS,
    payload: payload,
  };
}

export function updateGenericFeatures(payload) {
  return {
    type: UPDATE_GENERIC_FEATURES,
    payload: payload,
  };
}

export function getOptionsFields(payload) {
  return {
    type: FETCH_FIELDS_OPTIONS_REQUEST,
    payload: payload,
  };
}

export function triggerUpdatedForms(payload) {
  // to highlite form
  return {
    type: TRIGGER_UPDATED_FORMS,
    payload: payload,
  };
}

export function triggerUpdatedFields(payload) {
  // to highlite field
  return {
    type: TRIGGER_UPDATED_FIELDS,
    payload: payload,
  };
}

export function checkVisFormsPoints(payload) {
  // to check if vis points are added
  return {
    type: CHECK_VIS_FORMS_POINTS,
    payload: payload,
  };
}

export function fetchFieldsCount(payload) {
  return {
    type: FETCH_FIELDS_COUNT_REQUEST,
    payload: payload,
  };
}

export function clearAnswers(payload) {
  return {
    type: CLEAR_ANSWERS,
    payload: payload,
  };
}

export function filterForms(payload) {
  return {
    type: FILTER_FORMS_OK,
    payload: payload,
  };
}

export function getMap(payload) {
  return {
    type: GET_MAP,
    payload: payload,
  };
}

export function updateCarPoints(payload) {
  return {
    type: UPDATE_CAR_POINTS,
    payload: payload,
  };
}

export function calculateEagleEye(payload) {
  return {
    type: CALCULATE_EAGLE_EYE,
    payload: payload,
  };
}

export function clearSavedFormData(payload) {
  return {
    type: CLEAR_SAVED_FORM_DATA,
    payload: payload,
  };
}

export function totalTime(payload) {
  return {
    type: TOTAL_TIME,
    payload: payload,
  };
}

export function DTCFaults(payload) {
  return {
    type: DTC_FAULTS,
    payload: payload,
  };
}

export function getPaparazziPackage(payload) {
  return {
    type: FETCH_PAPARAZZI_PACKAGE_REQUEST,
    payload: payload,
  };
}

export function updatePaparazziImage(payload) {
  return {
    type: UPDATE_PAPARAZZI_IMAGE,
    payload: payload,
  };
}

export function updatePaparazziVisImage(payload) {
  return {
    type: UPDATE_PAPARAZZI_VIS_IMAGE,
    payload: payload,
  };
}

export function getTestPaparazziPackage(payload) {
  return {
    type: FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST,
    payload: payload,
  };
}

export function getPaparazziCarImages(payload) {
  console.log("pppppppppppppoo", payload);
  return {
    type: FETCH_PAPARAZZI_CAR_IMAGES_REQUEST,
    payload: payload,
  };
}

export function getPaparazziPackageList(payload) {
  // console.log("ppppppppppppnnnnnnnn", payload);
  return {
    type: FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST,
    payload: payload,
  };
}

export function getPaparazziShadows(payload) {
  return {
    type: FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST,
    payload: payload,
  };
}

export function addVideoImage(payload) {
  return {
    type: ADD_VIDEO_IMAGE,
    payload: payload,
  };
}

export function removeSelectedVideoImages(payload) {
  return {
    type: REMOVE_SELECTED_VIDEO_IMAGES,
    payload: payload,
  };
}

export function clearVideoImages(payload) {
  return {
    type: CLEAR_VIDEO_IMAGES,
  };
}

export function toggleSelectedImage(payload) {
  return {
    type: TOGGLE_SELECTED_IMAGE,
    payload: payload,
  };
}
