import React from "react";

const FeatureIcon56 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon56Clip0)">
<path d="M21.432 0.0810117C17.0005 0.814163 12.2041 3.90387 9.64946 7.64818C7.1991 11.2616 6.20853 14.4037 6.20853 18.6455C6.20853 26.9458 11.2917 34.0155 19.1641 36.6339C21.0149 37.2623 21.6406 37.3408 24.899 37.3408C28.1575 37.3408 28.7831 37.2623 30.6339 36.6339C33.6317 35.6389 35.8213 34.2511 38.1935 31.8684C40.5135 29.538 41.7126 27.6789 42.625 25.0082C44.3976 19.9285 44.0587 15.0059 41.6344 10.0047C40.7742 8.19805 40.1225 7.33398 38.1935 5.39636C36.1863 3.35401 35.4825 2.83033 33.371 1.80915C29.5912 -0.0237242 25.5507 -0.599772 21.432 0.0810117ZM29.4348 1.62587C35.3521 3.27546 39.94 7.70055 41.7647 13.5396C42.625 16.2365 42.625 21.1591 41.7647 23.7513C40.696 26.9196 39.6793 28.5692 37.2811 30.9519C35.0132 33.2561 33.1624 34.4082 30.3211 35.4294C28.1835 36.1887 23.4392 36.3982 21.041 35.8222C14.7066 34.3297 9.67552 29.4333 7.92899 23.0706C7.35551 20.9758 7.2773 16.446 7.79866 14.5869C9.59732 8.09331 14.1592 3.40638 20.3632 1.62587C22.7615 0.918899 26.9323 0.945083 29.4348 1.62587Z" fill="#07303E"/>
<path d="M17.7565 9.14077C16.0882 9.97866 15.4365 12.021 16.2967 13.6706C17.157 15.3202 19.868 15.8177 21.1974 14.5609C22.4487 13.4088 22.7615 11.9163 22.0577 10.5547C21.2756 9.03603 19.2684 8.38143 17.7565 9.14077Z" fill="#07303E"/>
<path d="M11.7088 9.79532C10.9789 10.031 10.4315 10.9736 10.4315 11.9424C10.4315 13.8538 14.0288 21.2377 17.4958 26.3698C20.5978 31.0044 21.9794 31.8161 25.6289 31.1091C28.6527 30.533 38.1674 27.8099 38.5584 27.4171C39.1319 26.8411 38.7148 26.3174 37.1508 25.7414C35.2478 25.0344 33.7099 25.0606 31.2074 25.8461C30.0865 26.2127 28.9134 26.5007 28.6006 26.5007C27.4797 26.4745 26.046 25.5319 21.9012 22.0756C17.3394 18.2527 16.4792 17.1792 14.8369 13.0945C14.3938 12.0471 13.9245 10.9212 13.7681 10.6332C13.4032 9.84768 12.543 9.50729 11.7088 9.79532Z" fill="#07303E"/>
<path d="M26.0199 13.9324C24.8469 14.2204 20.285 16.4984 20.0244 16.9174C19.3205 17.9647 20.4154 19.0644 26.6977 23.5419C28.5745 24.9035 29.2784 24.9558 32.0415 23.987C34.3355 23.2015 36.1342 23.2277 37.8285 24.1179C39.0537 24.7725 39.7054 24.7987 40.2528 24.2489C41.2695 23.2539 40.4874 21.997 38.1935 20.9759C37.0987 20.4784 36.5252 20.3998 34.544 20.4522C28.6006 20.6355 29.2523 20.7402 27.4276 19.4572C26.5413 18.8026 25.8374 18.1218 25.8635 17.9385C25.9156 17.7291 26.8801 17.2054 28.0271 16.7341C29.5912 16.1056 30.1386 15.7653 30.1907 15.3463C30.3732 14.1157 28.2096 13.3825 26.0199 13.9324Z" fill="#07303E"/>
<path d="M11.2135 39.8807C10.8486 40.0117 10.249 40.4568 9.85799 40.8234C9.2845 41.3732 9.12809 41.766 9.12809 42.6824C9.12809 44.0964 9.70158 44.6986 11.8913 45.4579C13.4814 46.034 13.8985 46.5838 13.2989 47.317C12.934 47.7621 11.5524 47.8145 10.5357 47.4479C9.70158 47.1075 9.12809 47.5003 9.12809 48.3905C9.12809 49.1761 9.88405 49.5688 11.7088 49.7259C13.5857 49.883 14.8108 49.2808 15.4625 47.893C16.427 45.9554 15.3322 44.2011 12.7776 43.5203C11.5263 43.1799 11.031 42.6301 11.4742 42.0802C11.7349 41.766 12.2301 41.6874 13.4032 41.7398C14.8369 41.8184 14.9933 41.766 15.0715 41.2685C15.1236 40.9805 14.9412 40.5615 14.6805 40.3259C14.0809 39.776 12.0477 39.5142 11.2135 39.8807Z" fill="#07303E"/>
<path d="M19.4769 39.9593C17.4958 40.6924 16.427 42.342 16.427 44.6724C16.427 46.4006 16.7398 47.317 17.6783 48.3382C19.0859 49.8307 21.1453 50.1972 23.1003 49.307C25.4986 48.2072 26.4891 44.9866 25.2379 42.473C24.0388 40.1688 21.6927 39.1214 19.4769 39.9593ZM22.8136 42.3682C23.9866 43.625 23.9084 46.2173 22.6572 47.3432C21.823 48.1025 20.2329 47.9978 19.3727 47.1075C18.721 46.4791 18.6428 46.1911 18.6428 44.7248C18.6428 43.3108 18.747 42.9443 19.2684 42.3682C20.1547 41.3994 21.9273 41.3994 22.8136 42.3682Z" fill="#07303E"/>
<path d="M6.15641 40.2473C6.0782 40.4829 6 42.5515 6 44.8295C6 48.2596 6.0782 49.0451 6.41708 49.3855C6.62562 49.6212 6.9645 49.8044 7.14697 49.8044C7.98113 49.8044 8.0854 49.0975 8.03327 44.5415L7.95507 39.9855L7.14697 39.9069C6.57349 39.8545 6.28674 39.9593 6.15641 40.2473Z" fill="#07303E"/>
<path d="M26.8801 40.352C26.6977 40.64 26.5934 42.5776 26.5934 44.8818C26.5934 49.4902 26.8019 50.1448 28.0011 49.5949C28.6267 49.3069 28.6788 49.176 28.6788 47.4479V45.615H29.9561C31.3638 45.615 31.9633 45.1699 31.6766 44.3058C31.5463 43.8607 31.2856 43.7821 30.0865 43.7821H28.6788V41.7136L30.3211 41.635C31.9373 41.5565 31.9373 41.5565 31.9373 40.7709V39.9854L29.539 39.9069C27.3754 39.8545 27.1148 39.8807 26.8801 40.352Z" fill="#07303E"/>
<path d="M33.0321 40.0116C32.9278 40.1164 32.8496 42.2373 32.8496 44.6986C32.8496 48.4952 32.9278 49.2808 33.2667 49.5688C33.5535 49.8044 33.7881 49.8306 34.1009 49.6212C34.4919 49.3855 34.544 48.7571 34.544 44.6724V39.9854L33.8923 39.9069C33.5274 39.8545 33.1364 39.9069 33.0321 40.0116Z" fill="#07303E"/>
<path d="M36.1081 40.3782C35.9256 40.9805 35.9777 41.059 37.3333 42.8919C37.8807 43.625 38.3238 44.2796 38.3238 44.3582C38.3238 44.4106 37.6721 45.3532 36.8901 46.4005C35.4043 48.3905 35.1957 48.9928 35.8474 49.5426C36.447 50.0401 37.1247 49.6212 38.2717 47.9716C38.8973 47.1075 39.4969 46.4005 39.6272 46.4005C39.7576 46.4005 40.331 47.1075 40.9306 47.9454C42.1297 49.6474 42.5468 49.9354 43.3028 49.5426C44.0848 49.1237 43.9023 48.5476 42.3643 46.5053C41.5823 45.4579 40.9306 44.5153 40.9306 44.4367C40.9306 44.332 41.478 43.5203 42.1297 42.5777C43.4852 40.6924 43.5113 39.8545 42.234 39.8545C41.6344 39.8545 41.3477 40.0902 40.6699 41.1637C40.2007 41.8707 39.7576 42.4729 39.6533 42.4729C39.549 42.4729 39.0277 41.8707 38.5063 41.1637C37.4375 39.7236 36.4209 39.3832 36.1081 40.3782Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon56Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon56;
