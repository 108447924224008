import React from "react";

const FeatureIcon39 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 63" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon39Clip0)">
<path d="M16.7647 29.1282L17.5774 37.1802L32.4184 37.1802L33.2269 29.1274C33.3209 28.2043 32.8949 27.3052 32.1208 26.7935L31.9108 26.6543C27.7228 23.8648 22.2688 23.8648 18.0808 26.6543L17.8699 26.7943C17.096 27.306 16.6702 28.2052 16.7647 29.1282ZM18.7951 28.1838L19.006 28.0438C22.6362 25.6257 27.3638 25.6257 30.9939 28.0438L31.204 28.183C31.4608 28.354 31.6023 28.6528 31.5715 28.9599L30.9139 35.5131L19.0819 35.5131L18.4242 28.959C18.3949 28.6519 18.5376 28.3537 18.7951 28.1838Z" fill="#07303E"/>
<path d="M18.12 54.3063L19.7362 55.204C23.0057 57.0335 26.9908 57.0335 30.2604 55.204L31.8783 54.3063C32.8012 53.791 33.3014 52.7519 33.1286 51.709L32.3742 47.1713L17.6257 47.1713L16.8697 51.7082C16.6965 52.7513 17.1967 53.7909 18.12 54.3063ZM18.5142 51.9824L19.0377 48.8384L30.9622 48.8384L31.4865 51.9833C31.5446 52.3313 31.3773 52.6781 31.0689 52.8493L29.4527 53.747C26.686 55.2949 23.3139 55.2949 20.5472 53.747L18.931 52.8493C18.6237 52.6767 18.4572 52.3303 18.5142 51.9824Z" fill="#07303E"/>
<path d="M31.8116 24.2997L33.1986 23.375L29.8645 18.3742L28.4775 19.2989L31.8116 24.2997Z" fill="#07303E"/>
<path d="M18.1845 24.2811L21.5201 19.2803L20.1331 18.3551L16.7975 23.3559L18.1845 24.2811Z" fill="#07303E"/>
<path d="M9.52877 34.5251C9.7581 34.6804 10.0495 34.7116 10.3065 34.6085L13.7615 33.2265L14.164 38.0026L12.9704 52.256C12.6466 55.988 14.6464 59.536 18.0066 61.1915L18.6651 61.5191C22.6575 63.4936 27.3424 63.4936 31.3348 61.5191L31.9925 61.1915C35.354 59.5371 37.3552 55.9887 37.032 52.256L35.8359 38.0026L36.236 33.2232L39.691 34.6052C40.1177 34.7778 40.6036 34.5718 40.7762 34.145C40.8161 34.0465 40.8368 33.9412 40.8371 33.835L40.8371 31.6294C40.8403 30.6058 40.2166 29.6845 39.265 29.3072L36.6536 28.2628L37.0028 24.1135C37.3314 20.1884 35.1026 16.496 31.4765 14.958L28.6125 13.7352C26.3051 12.7549 23.6982 12.7549 21.3908 13.7352L18.5268 14.958C14.8993 16.4949 12.6691 20.1875 12.9971 24.1135L13.3447 28.2628L10.7332 29.3072C9.78227 29.685 9.15942 30.6062 9.16285 31.6294L9.16285 33.835C9.16285 34.1115 9.29986 34.37 9.52877 34.5251ZM36.6694 30.0649L38.6457 30.8559C38.9631 30.9813 39.1712 31.2882 39.17 31.6294L39.17 32.6038L36.6694 31.6036L36.6694 30.0649ZM14.6308 52.3986L15.8311 38.0026L14.6575 23.9709C14.3919 20.7626 16.2157 17.7461 19.1802 16.4908L22.0443 15.2689C23.9329 14.4662 26.067 14.4662 27.9557 15.2689L30.8197 16.4908C33.7831 17.7488 35.6043 20.7669 35.3358 23.9751L34.1688 38.0026L35.3699 52.3986C35.6328 55.4482 33.9976 58.3467 31.2515 59.6987L30.593 60.0262C27.0685 61.7701 22.9323 61.7701 19.4078 60.0262L18.7501 59.6987C16.0038 58.3469 14.3682 55.4484 14.6308 52.3986ZM10.8299 31.6294C10.8288 31.2882 11.0368 30.9812 11.3542 30.8559L13.3305 30.0649L13.3305 31.6036L10.8299 32.6038L10.8299 31.6294Z" fill="#07303E"/>
</g>
<g clip-path="url(#FeatureIcon39Clip1)">
<path d="M35.7798 5.7469C33.0838 3.12984 29.4332 1.68851 25.4998 1.68848C21.5669 1.68851 17.9162 3.12984 15.2202 5.7469C14.9114 6.04674 14.9296 6.5164 15.261 6.79587C15.5925 7.0753 16.1115 7.05882 16.4204 6.75894C18.8026 4.44644 22.0271 3.17289 25.5001 3.17285C28.9729 3.17289 32.1974 4.44644 34.5796 6.75894C34.7411 6.91577 34.9602 6.99511 35.1798 6.99511C35.3801 6.99511 35.5809 6.92913 35.739 6.79587C36.0704 6.51636 36.0887 6.04674 35.7798 5.7469Z" fill="#07303E"/>
<path d="M32.7677 7.79649C30.7748 5.97985 28.1938 4.97942 25.4999 4.97949C22.8053 4.97953 20.2242 5.98 18.232 7.79657C17.9129 8.08754 17.9149 8.55745 18.2365 8.84616C18.5581 9.13487 19.0775 9.13306 19.3966 8.84208C21.0785 7.30851 23.246 6.4639 25.4999 6.46386C27.7532 6.46379 29.9207 7.30843 31.6031 8.84216C31.7635 8.98833 31.9744 9.06151 32.1854 9.06151C32.3942 9.06151 32.6033 8.98974 32.7633 8.84609C33.0848 8.55734 33.0868 8.08743 32.7677 7.79649Z" fill="#07303E"/>
<path d="M29.4847 9.85458C28.4175 8.89794 26.8877 8.30371 25.4923 8.30371C25.4921 8.30371 25.4918 8.30371 25.4917 8.30371H25.4326C25.4323 8.30371 25.4322 8.30371 25.432 8.30371C24.0365 8.30371 22.5066 8.89798 21.4394 9.85458C21.1176 10.1431 21.1152 10.613 21.434 10.9042C21.5945 11.0507 21.8056 11.124 22.0168 11.124C22.2254 11.124 22.4342 11.0524 22.5941 10.9091C23.3538 10.228 24.4677 9.78808 25.432 9.78808C25.4322 9.78808 25.4323 9.78808 25.4324 9.78808H25.4918C25.492 9.78808 25.492 9.78808 25.4922 9.78808C26.4564 9.78808 27.5703 10.2281 28.33 10.9091C28.6518 11.1975 29.1713 11.1955 29.4901 10.9042C29.809 10.613 29.8066 10.1431 29.4847 9.85458Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon39Clip0">
<rect width="50" height="50" fill="white" transform="translate(50 63) rotate(-180)"/>
</clipPath>
<clipPath id="FeatureIcon39Clip1">
<rect width="21" height="19" fill="white" transform="translate(15)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon39;
