import React from "react";

const FeatureIcon77 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 51 51" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon77Clip0)">
<path d="M34.0903 33.8891L33.2776 25.8372L25.8571 26.8372L18.4366 25.8372L17.628 33.8899C17.5341 34.8131 17.9601 35.7121 18.7341 36.2238L18.9442 36.363C23.1322 39.1526 28.5862 39.1526 32.7742 36.363L32.9851 36.223C33.759 35.7113 34.1848 34.8121 34.0903 33.8891ZM32.0598 34.8335L31.849 34.9735C28.2188 37.3916 23.4912 37.3916 19.8611 34.9735L19.651 34.8343C19.3942 34.6634 19.2527 34.3645 19.2834 34.0575L19.9411 27.5042L25.8571 28.5042L31.7731 27.5042L32.4308 34.0583C32.46 34.3655 32.3174 34.6637 32.0598 34.8335Z" fill="#07303E"/>
<path d="M32.735 6.71052L31.1188 5.8128C27.8493 3.9833 23.8641 3.9833 20.5946 5.8128L18.9767 6.71052C18.0538 7.22585 17.5536 8.26495 17.7264 9.3078L18.4808 24.8456L25.855 25.8456L33.2293 24.8456L33.9853 9.30864C34.1585 8.26558 33.6582 7.22595 32.735 6.71052ZM32.3407 9.03441L31.8173 23.1785L25.855 24.1785L19.8928 23.1785L19.3685 9.03357C19.3103 8.68557 19.4777 8.33872 19.7861 8.16753L21.4023 7.26982C24.169 5.72194 27.5411 5.72194 30.3078 7.26982L31.924 8.16753C32.2312 8.34018 32.3977 8.68651 32.3407 9.03441Z" fill="#07303E"/>
<path d="M19.0434 38.7172L17.6564 39.642L20.3234 42.1424L20.9905 44.6428L22.3775 43.7181L21.7104 41.2177L19.0434 38.7172Z" fill="#07303E"/>
<path d="M32.6705 38.736L34.0575 39.6612L31.3897 42.1616L30.7218 44.662L29.3348 43.7369L30.0027 41.2365L32.6705 38.736Z" fill="#07303E"/>
<path d="M41.3262 28.4919C41.0969 28.3367 40.8055 28.3054 40.5485 28.4086L37.0935 29.7906L36.691 25.0144L37.8846 10.761C38.2084 7.02908 36.2086 3.48104 32.8484 1.82554L32.1899 1.49796C28.1975 -0.476574 23.5126 -0.476574 19.5202 1.49796L18.8625 1.82554C15.501 3.48 13.4998 7.02835 13.823 10.761L15.0191 25.0144L14.619 29.7939L11.164 28.4119C10.7372 28.2393 10.2514 28.4452 10.0787 28.872C10.0388 28.9706 10.0182 29.0758 10.0179 29.1821V31.3876C10.0147 32.4113 10.6384 33.3326 11.5899 33.7098L14.2014 34.7543L13.8521 38.9036C13.5236 42.8287 15.7524 46.521 19.3785 48.0591L22.2425 49.2819C24.5499 50.2621 27.1568 50.2621 29.4642 49.2819L32.3282 48.0591C35.9557 46.5222 38.1859 42.8295 37.8579 38.9036L37.5103 34.7543L40.1218 33.7098C41.0727 33.332 41.6956 32.4109 41.6922 31.3876V29.1821C41.6922 28.9056 41.5551 28.6471 41.3262 28.4919ZM14.1856 32.9522L12.2093 32.1611C11.8919 32.0358 11.6838 31.7288 11.685 31.3876V30.4132L14.1856 31.4135V32.9522ZM36.2242 10.6185L35.0239 25.0144L36.1975 39.0461C36.4631 42.2545 34.6393 45.2709 31.6748 46.5262L28.8107 47.7482C26.922 48.5509 24.788 48.5509 22.8993 47.7482L20.0353 46.5262C17.0719 45.2682 15.2507 42.2501 15.5192 39.042L16.6862 25.0144L15.485 10.6185C15.2222 7.56889 16.8573 4.67039 19.6035 3.3184L20.262 2.99082C23.7865 1.24696 27.9227 1.24696 31.4472 2.99082L32.1049 3.3184C34.8512 4.67018 36.4868 7.56869 36.2242 10.6185ZM40.0251 31.3876C40.0262 31.7288 39.8182 32.0359 39.5008 32.1611L37.5245 32.9522V31.4135L40.0251 30.4132V31.3876Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon77Clip0">
<rect width="50" height="50" fill="white" transform="translate(0.85498 0.0170898)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon77;
