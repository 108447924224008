import React from "react";

const FeatureIcon68 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 40" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon68Clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.5312 12.3438H14.9283C12.9919 12.3335 11.1886 13.3376 10.164 14.9939L6.61892 20.7082L2.60253 22.5612C1.01442 23.2962 -0.0101697 24.9111 7.61406e-05 26.6813V30.0456C7.61406e-05 32.5405 1.96728 34.6005 4.43654 34.6005H5.66605C6.17834 36.6191 7.74597 38.1926 9.74391 38.7206C12.7869 39.5177 15.8914 37.675 16.6804 34.6005H33.8935C34.4058 36.6191 35.9734 38.1926 37.9713 38.7206C41.0144 39.5177 44.1189 37.675 44.9078 34.6005H45.5635C48.0328 34.6005 50 32.5405 50 30.0456V27.0953C50 25.0664 48.6578 23.2755 46.7213 22.7372L39.0881 20.6254L34.5082 14.5695C34.0425 13.9542 33.4633 13.4516 32.8125 13.0807V15.7258C32.8386 15.7574 32.8642 15.7895 32.8894 15.8221L37.664 22.1471C37.7971 22.3334 37.9918 22.4577 38.207 22.5198L46.1885 24.7247C47.2439 25.025 47.9713 25.9877 47.9611 27.0953V30.0456C47.9611 31.4017 46.916 32.5301 45.5738 32.5301H45.0615C44.7746 29.8593 42.6844 27.7372 40.0308 27.4473C36.9058 27.0953 34.0881 29.3728 33.75 32.5301H16.8341C16.5472 29.8593 14.457 27.7372 11.8033 27.4473C8.67834 27.0953 5.86072 29.3728 5.52261 32.5301H4.43654C3.09433 32.5301 2.04925 31.4017 2.04925 30.0456H2.0595V26.6813C2.04925 25.7185 2.60253 24.849 3.46318 24.4452L7.74597 22.468C7.92015 22.3852 8.08408 22.2506 8.18654 22.0747L11.8956 16.0912C12.541 15.0353 13.6988 14.4039 14.9283 14.4142H19.0578L19.5312 13.379V12.3438ZM43.043 33.1409C43.043 35.1699 41.4139 36.8158 39.4058 36.8158C37.3873 36.8055 35.7582 35.1595 35.7685 33.1409C35.7685 31.1119 37.3976 29.4659 39.4058 29.4659C41.4139 29.4659 43.043 31.1119 43.043 33.1409ZM14.8156 33.1409C14.8156 35.1699 13.1865 36.8158 11.1783 36.8158C9.1599 36.8055 7.5308 35.1595 7.54105 33.1409C7.54105 31.1119 9.17014 29.4659 11.1783 29.4659C13.1865 29.4659 14.8156 31.1119 14.8156 33.1409Z" fill="#07303E"/>
<path d="M17.1875 12.7066L29.3909 10L29.8511 12.0745L19.2693 14.4016C18.5199 14.5664 17.7617 14.1626 17.4802 13.4488L17.1875 12.7066Z" fill="#07303E"/>
<path d="M38.9648 9.71056C39.1822 9.14616 39.6171 8.95079 39.9215 9.2764C40.1607 9.49348 40.0955 9.90592 39.7258 10.9045C39.117 12.4674 38.9865 12.5325 37.4861 11.9247C36.2032 11.4255 35.8118 11.0564 36.0727 10.6006C36.1597 10.492 36.4859 10.4269 36.8338 10.4703C37.5949 10.5789 37.6166 10.4486 36.9208 9.34153C35.6378 7.32272 33.4851 5.89002 31.0062 5.43416C29.7233 5.21709 27.4619 5.32562 27.4619 5.62953C27.4619 5.71636 27.6681 5.83677 27.9185 6.15051C27.9185 6.15051 28.2874 6.70508 28.1029 7.03069C27.9185 7.3563 27.2332 6.92215 27.2332 6.92215C27.0157 6.81361 25.4613 5.86831 25.2439 5.56441C25.0699 5.28221 25.831 3.87122 26.5268 3.21999C27.2009 2.59047 27.6793 2.9595 27.3314 3.87122L27.0922 4.47903L28.3751 4.28366C32.0065 3.69756 36.1597 5.75978 38.0733 9.12445L38.7473 10.2967L38.9648 9.71056Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon68Clip0">
<rect width="50" height="39.0625" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon68;
