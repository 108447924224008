// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import {
  CREATE_EMPTY_CAR_REQUEST_SUCCEED,
  CREATE_EMPTY_CAR_REQUEST,
  CREATE_EMPTY_CAR_REQUEST_FAILURE,
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_FAILURE,
  LIST_CARS_REQUEST_SUCCEED,
  CONNECTION_REQUEST_SUCCEED,
  ADD_CAR,
  REMOVE_CAR,
  INITIATING_PAPRAZZI,
  INITIATING_PAPRAZZI_SUCCEED,
} from "../constants";

const initialState = {
  creatingCar: false,
  car: {},
  fetchingCar: true,
  cars: [],
  fetching: true,
};

export default createReducer(initialState, {
  [CREATE_EMPTY_CAR_REQUEST]: (state) => {
    return Object.assign({}, state, {
      creatingCar: true,
    });
  },
  [CREATE_EMPTY_CAR_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      car: payload,
      creatingCar: false,
    });
  },
  [CREATE_EMPTY_CAR_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      creatingCar: false,
    });
  },
  [FETCH_CAR_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: true,
    });
  },
  [FETCH_CAR_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      car: payload,
      fetchingCar: false,
    });
  },
  [FETCH_CAR_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingCar: false,
    });
  },
  [LIST_CARS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetching: true,
    });
  },
  [LIST_CARS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      cars: payload,
      fetching: false,
    });
  },
  [LIST_CARS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetching: false,
    });
  },
  [ADD_CAR]: (state, payload) => {
    const modifiedCars = state.cars;
    let added_car = payload.instance;
    let old_cars = state.cars;
    let isIn = old_cars.find((x) => x?.id == added_car.id);
    let found = old_cars.some((x) => x?.id == added_car.id);
    found
      ? (modifiedCars[old_cars.indexOf(isIn)] = payload.instance)
      : modifiedCars.push(payload.instance);
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    const removed = payload.instance;
    const modifiedCars = state.cars;
    modifiedCars.forEach((element, index) => {
      if (element.id === removed) {
        modifiedCars.splice(index, 1);
      }
    });
    return Object.assign({}, state, {
      cars: modifiedCars,
    });
  },
  [INITIATING_PAPRAZZI_SUCCEED]: (state, payload) => {
    var paparazzi_selection_data = { ...state.car.paparazzi_selection_data };
    if (payload.fieldId == "972") {
      paparazzi_selection_data["sun_location"] = Number(payload.value);
    } else if (payload.fieldId == "973") {
      paparazzi_selection_data["reflection"] = Number(payload.value);
    } else if (payload.fieldId == "974") {
      paparazzi_selection_data["interior_lights"] = Number(payload.value);
    } else if (payload.fieldId == "941") {
      if (payload.value == "4199") {
        paparazzi_selection_data["location"] = Number(payload.value);
        paparazzi_selection_data["sun_location"] = null;
        paparazzi_selection_data["reflection"] = null;
      } else {
        paparazzi_selection_data["location"] = Number(payload.value);
        paparazzi_selection_data["interior_lights"] = null;
      }
    }

    return Object.assign({}, state, {
      car: { ...state.car, paparazzi_selection_data: paparazzi_selection_data },
    });
  },
});
