import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import CarCard from "../../components/CarCard";
import CloseIcon from "@material-ui/icons/Close";
import { getCarsList } from "../../store/actions/car";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import {
  clearRemovedCars,
  updatePageTimeStamp,
  clearNewCars,
} from "../../store/actions/websockets";

import Sort from "../../components/sort";

import { useTranslation } from "react-i18next";
import CarCardSkeleton from "../../components/skeletons/CarCardSkeleton";
import Button from "@material-ui/core/Button";
import vlpEmptyState from "../../images/vlpEmptyState.png";

const mobileDrawerWidth = 231;

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    backgroundColor: "white",
  },
  container: {
    // backgroundColor: "#f3f3f3",
    // [theme.breakpoints.down("sm")]: {
    //   padding: theme.spacing(1),
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: "24px 68px",
    // },
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "607px",
    },
    [theme.breakpoints.up("lg")]: {
      // width: "689px", //// try 750
      width: "750px", //// try 750
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70.414vw",
      padding: "25px",
    },
  },
  breadcrumbsPaper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: 12,
    paddingBottom: 12,
  },
  sortDiv: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      padding: "8px 16px" /* remove if fails menue card results*/,
    },
    [theme.breakpoints.up("md")]: {
      padding: "8px 68px",
    },
  },
  sortContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "47px",
    },
    [theme.breakpoints.up("md")]: {
      height: "66px",
    },
  },
  resultsGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
  sortGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  LinearProgressBar: {
    [theme.breakpoints.down("sm")]: {
      margin: "-8px",
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      margin: "-24px -68px",
    },
  },
  labelPlacementStyle: {
    margin: "8px 13px",
  },
  formControlLable: {
    marginRight: "auto",
  },
  radioButton: {
    padding: 0,
  },
  sortLable: {
    marginRight: "auto",
    color: "#07303E",
  },
  cardsGrid: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      /* remove if fails menue card results*/ display: "flex",
      justifyContent: "center",
    },
  },
  gridContainerSpacing: {
    paddingTop: "21px",
    margin: "-16px",
    width: "calc(100% + 32px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0px",
      margin: "0 -16px", ////new
    },
    [theme.breakpoints.up("lg")]: {
      margin: "-16px", ////new
    },
  },
  carCardGridItem: {
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  vlpEmptyStateImage: {
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: "188px",
    },
  },
  emptyStateDiv: {
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
}));

function CarListings(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const {
    history,
    user,
    cars,
    getCarsList,
    fetching,
    isAuthenticated,
    removedCars,
    clearRemovedCars,
    state,
    updatePageTimeStamp,
    clearNewCars,
  } = props;
  const [sortCriteria, setSortCriteria] = React.useState("oldest");
  const [updatedCars, setUpdatedCars] = useState(cars);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [radioDefaultValue, setRadioDefaultValue] = useState("oldest");

  useEffect(() => {
    var bodyTag = document.getElementsByTagName("body");
    bodyTag[0].style.overflow = "auto";
  }, []);

  //This function is called in Sort Component and excuted here to change state of Listing and cause a re-render of the sortedCarList
  const handleChange = (event) => {
    setRadioDefaultValue(event.target.value);
    setOpenDrawer(false);
    setSortCriteria(event.target.value);
    if (event.target.value === "oldest") {
      updatedCars.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    } else if (event.target.value === "newest") {
      updatedCars.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    } else if (event.target.value === "highestPrice") {
      updatedCars.sort((a, b) => {
        return b.card.price - a.card.price;
      });
    } else if (event.target.value === "lowestPrice") {
      updatedCars.sort((a, b) => {
        return a.card.price - b.card.price;
      });
    } else if (event.target.value === "highestScore") {
      updatedCars.sort((a, b) => {
        return b.carScore - a.carScore;
      });
    } else if (event.target.value === "lowestScore") {
      updatedCars.sort((a, b) => {
        return a.carScore - b.carScore;
      });
    } else if (event.target.value === "lowestMilage") {
      updatedCars.sort((a, b) => {
        return a.card.milage - b.card.milage;
      });
    } else if (event.target.value === "highestMilage") {
      updatedCars.sort((a, b) => {
        return b.card.milage - a.card.milage;
      });
    } else if (event.target.value === "newestYear") {
      updatedCars.sort((a, b) => {
        return a.card.year - b.card.year;
      });
    } else if (event.target.value === "oldestYear") {
      updatedCars.sort((a, b) => {
        return b.card.year - a.card.year;
      });
    }
  };

  useEffect(() => {
    getCarsList({
      filter:
        "?is_active=True&published_for_buyers=false&finished_at__isnull=false&is_sold=False",
    });
    clearRemovedCars(); // reset removed cars
    updatePageTimeStamp({
      user_id: user?.user_id,
      page: "car_list_stamp",
    });
    clearNewCars();
  }, [getCarsList]);

  useEffect(() => {
    // this is probably wrong but for some reason react is not seeing changes in state.car.cars so i had to send state as a prop and check for changes in state.car
    setUpdatedCars(cars);
  }, [state.car]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const renderMdDownDrawer = () => {
    return (
      <Drawer
        anchor="right"
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "white" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControl>
              <FormLabel
                style={{ display: "flex", padding: "12px 13px 18px 12px" }} // or 8 13 8 12
              >
                <Typography classes={{ root: classes.sortLable }}>
                  {t("Sort By")}
                </Typography>{" "}
                <IconButton
                  style={{ padding: "0" }}
                  color="#07303E"
                  className={classes.button}
                  component="span"
                  onClick={handleCloseDrawer}
                >
                  <CloseIcon
                    onClick={handleCloseDrawer}
                    style={{ fill: "#07303E" }}
                  />
                </IconButton>
              </FormLabel>
              <hr
                style={{
                  height: "1px",
                  border: "0px none",
                  backgroundImage:
                    "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                  margin: "0px 0px 8px",
                }}
              />
              <RadioGroup
                aria-label="sort"
                defaultValue={radioDefaultValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestPrice"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Price (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestPrice"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Price (High to Low)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestMilage"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Odometer (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestMilage"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Odometer (High to Low)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"newestYear"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Year (Oldest First)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"oldestYear"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Year (Newest First)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"oldest"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Date (Oldest First)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"newest"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Date (Newest First)")}
                  labelPlacement="start"
                />
                <hr
                  style={{
                    height: "1px",
                    border: "0px none",
                    backgroundImage:
                      "radial-gradient(50% 50%, rgb(24, 105, 133) 0%, rgba(24, 105, 133, 0.13) 100%)",
                    margin: "8px 0px 8px",
                  }}
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"lowestScore"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Score (Low to High)")}
                  labelPlacement="start"
                />
                <FormControlLabel
                  classes={{
                    labelPlacementStart: classes.labelPlacementStyle,
                    label: classes.formControlLable,
                  }}
                  value={"highestScore"}
                  control={<Radio className={classes.radioButton} />}
                  label={t("Score (High to Low)")}
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div>
      <Hidden mdUp>{renderMdDownDrawer()}</Hidden>
      {/* To prevent a non dispatcher account from seing this page */}
      {isAuthenticated ? (
        user?.role === "dispatcher" ? (
          <div className={classes.mainDiv}>
            <Paper
              style={{
                boxShadow: "none",
                borderBottom: "2px solid #0c6885",
                borderRadius: 0,
              }}
              classes={{ root: classes.sortDiv }}
            >
              <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                spacing={2}
                className={classes.sortContainer}
              >
                <Grid item xs={4} className={classes.resultsGrid}>
                  <Typography>
                    {updatedCars.length} {t("Results")}
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.sortGrid}>
                  {/* Sort child Component called with handleChange function and sortCriteria value as props  */}
                  <Sort
                    handleChange={handleChange}
                    sortCriteria={sortCriteria}
                    handleOpenDrawer={handleOpenDrawer}
                  />
                </Grid>
              </Grid>
            </Paper>
            <div className={classes.container}>
              <Grid container>
                <Grid item md={12} className={classes.cardsGrid}>
                  {fetching ? (
                    // <LinearProgress className={classes.LinearProgressBar} />
                    <Grid container className={classes.gridContainerSpacing}>
                      {[1, 2, 3, 4, 5].map(() => (
                        <CarCardSkeleton />
                      ))}
                    </Grid>
                  ) : (
                    <Grid container className={classes.gridContainerSpacing}>
                      {updatedCars.length > 0 ? (
                        updatedCars.map((car, i) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                              xl={4}
                              className={classes.carCardGridItem}
                            >
                              <CarCard
                                model={car.card.model}
                                make={car.card.make}
                                trim={car.card.trim}
                                year={car.card.year}
                                image={car.card.feature_image}
                                milage={car.card.milage}
                                transmission={car.card.transmission}
                                fuelType={car.card.fuel_type}
                                driveType={car.card.drive_type}
                                engineSize={car.card.engine_size}
                                batterySize={car.card.battery_size}
                                countrySpecs={car.card.country_specs}
                                score={
                                  typeof car.carScore === "string"
                                    ? "N/A"
                                    : Math.floor(car.carScore)
                                }
                                rating={car.card.rating}
                                dealershipLocation={car.card.city}
                                dealershipArea={car.card.area}
                                createdAt={car.created_at}
                                enteredByFullName={car.entered_by_fullname}
                                price={car.card.price}
                                uom={car.card.unit_of_measurement}
                                onClick2={() => {
                                  history.push("/car/" + car.id);
                                }}
                                car_id={car.id}
                                publishedForSeller={car.published_for_seller}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <div className={classes.emptyStateDiv}>
                          <img
                            className={classes.vlpEmptyStateImage}
                            src={vlpEmptyState}
                          />
                          <Typography
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#07303E",
                              marginTop: "20px",
                            }}
                          >
                            {t("You Don’t Have Car Listings to Publish.")}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#626465",
                              marginTop: "8px",
                            }}
                          >
                            {t("Car listings will appear here.")}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              marginTop: "20px",
                              textTransform: "none",
                              width: "210px",
                              height: "30px",
                            }}
                            // className={classes.requestListingEntryButton}
                            onClick={() => history.push("/listing-requests/")}
                          >
                            {t("Go to Listing Requests")}
                          </Button>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null
      ) : null}
      {/* should redirect somewhere else will do later when the page directed to is ready */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
  cars: state.car.cars,
  isAuthenticated: state.auth.isAuthenticated,
  fetching: state.car.fetching,
  removedCars: state.websockets.removedCars,
});

const mapDispatchToProps = (dispatch) => ({
  getCarsList(payload) {
    dispatch(getCarsList(payload));
  },
  clearRemovedCars(payload) {
    dispatch(clearRemovedCars(payload));
  },
  updatePageTimeStamp(payload) {
    dispatch(updatePageTimeStamp(payload));
  },
  clearNewCars() {
    dispatch(clearNewCars());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarListings)
);
