import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "306px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 19px",
      margin: 0,
    },
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  dialogContentText: {
    padding: "0",
  },
  radio: {
    "&$checked": {
      color: "#0c6885",
    },
  },
  checked: {},
}));

const PublishDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, handelPublishCar, carId, car_id, car } = props;
  const [publishFor, setPublishFor] = useState("");
  const [publishedForSeller, setPublishedForSeller] = useState(false);

  useEffect(() => {
    setPublishFor("");
    if (car != undefined) {
      let vv = Object?.values?.(car);
      let dd = vv.find((x) => x?.id == carId);
      console.log(dd?.id, carId, dd?.published_for_seller);
      setPublishedForSeller(dd?.published_for_seller);
    }
  }, [open, car]);

  const handleChange = (e) => {
    setPublishFor(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        style={{
          display: "inline",
          padding: "0 0 10px",
        }}
      >
        <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
          {t("Car Publish Options")}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentText }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            fontSize: "14px",
            padding: "0 0 20px",
            margin: 0,
            color: "#07303E",
            fontWeight: 500,
          }}
        >
          {t("Select who you want to publish the car to")}:
        </DialogContentText>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue={publishedForSeller ? "Buyers and Seller" : "Seller"}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="Seller"
              control={
                <Radio
                  classes={{ root: classes.radio, checked: classes.checked }}
                  disabled={publishedForSeller}
                />
              }
              label={t("Seller Only")}
            />
            <FormControlLabel
              value="Buyers and Seller"
              control={
                <Radio
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              }
              label={t("Buyers & Sellers")}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", padding: 0, marginTop: "15px" }}
      >
        <Button
          style={{
            width: "50%",
          }}
          onClick={() => {
            handelPublishCar(publishFor);
          }}
          disabled={publishFor == ""}
          color="primary"
          variant="contained"
          className={classes.dialogButtons}
        >
          {t("Publish")}
        </Button>
        <Button
          style={{
            width: "50%",
          }}
          onClick={handleClose}
          color="primary"
          variant="outlined"
          autoFocus
          className={classes.dialogButtons}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  car: state.car.cars,
});

export default withRouter(connect(mapStateToProps, null)(PublishDialog));
