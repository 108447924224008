import {
  FETCH_INCOMING_LISTING_REQUEST,
  CLAIM_LISTING_REQUEST,
} from "../constants";

export function getListingRequests(payload) {
  return {
    type: FETCH_INCOMING_LISTING_REQUEST,
    payload: payload,
  };
}

export function ClaimListingRequests(payload) {
  return {
    type: CLAIM_LISTING_REQUEST,
    payload: payload,
  };
}
