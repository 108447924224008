import React from "react";

const FeatureIcon60 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M50 0.833333C50 0.61232 49.9122 0.400358 49.7559 0.244078C49.5996 0.0877973 49.3877 0 49.1667 0H45.8333C45.6962 2.34436e-06 45.5611 0.0338695 45.4401 0.098596C45.3192 0.163322 45.2161 0.256906 45.14 0.371042L44.0462 2.01208C42.4922 4.33799 41.664 7.07303 41.6667 9.87031V10.5555L38.7424 14.4548C37.4164 16.2197 36.4379 18.2204 35.859 20.3506L31.8635 35H17.5C16.8372 35.0008 16.2018 35.2644 15.7331 35.7331C15.2644 36.2018 15.0008 36.8372 15 37.5V44.1667C15.0008 44.8295 15.2644 45.4649 15.7331 45.9336C16.2018 46.4023 16.8372 46.6659 17.5 46.6667H18.6517L20.088 49.5394C20.1572 49.6778 20.2636 49.7942 20.3953 49.8755C20.5269 49.9569 20.6786 50 20.8333 50H39.1667C39.3877 50 39.5996 49.9122 39.7559 49.7559C39.9122 49.5996 40 49.3877 40 49.1667V46.6667H42.5C43.1628 46.6659 43.7982 46.4023 44.2669 45.9336C44.7356 45.4649 44.9992 44.8295 45 44.1667V35.9159L49.9839 10.9968C49.9943 10.9429 49.9995 10.8882 49.9992 10.8333H50V0.833333ZM45.4327 2.93656L46.2793 1.66667H48.3333V10H43.3333V9.87031C43.331 7.40216 44.0617 4.98892 45.4327 2.93656ZM17.5 36.6667H31.1517L30.3183 38.3333H16.6667V37.5C16.6669 37.2791 16.7548 37.0672 16.911 36.911C17.0672 36.7548 17.2791 36.6669 17.5 36.6667ZM38.3333 48.3333H21.3483L20.515 46.6667H38.3333V48.3333ZM43.3333 44.1667C43.3331 44.3876 43.2452 44.5994 43.089 44.7556C42.9328 44.9119 42.7209 44.9998 42.5 45H17.5C17.2791 44.9998 17.0672 44.9119 16.911 44.7556C16.7548 44.5994 16.6669 44.3876 16.6667 44.1667V40H30.8333C30.9881 40 31.1398 39.9569 31.2714 39.8755C31.4031 39.7942 31.5094 39.6778 31.5786 39.5394L33.015 36.6667H43.3333V44.1667ZM43.4834 35H33.591L37.4669 20.7886C37.9908 18.8615 38.8761 17.0514 40.0757 15.4547L42.9167 11.6667H48.1501L43.4834 35Z" fill="#07303E"/>
<path d="M9.13003 10.3219V17.9999H7.59003V13.0059L5.53303 17.9999H4.36703L2.29903 13.0059V17.9999H0.759033V10.3219H2.50803L4.95003 16.0309L7.39203 10.3219H9.13003Z" fill="#07303E"/>
<path d="M12.9107 11.3999V9.98095H15.5617V17.9999H13.9777V11.3999H12.9107Z" fill="#07303E"/>
<path d="M20.1881 16.2619C20.8921 15.6753 21.4531 15.1876 21.8711 14.7989C22.2891 14.4029 22.6375 13.9923 22.9161 13.5669C23.1948 13.1416 23.3341 12.7236 23.3341 12.3129C23.3341 11.9389 23.2461 11.6456 23.0701 11.4329C22.8941 11.2203 22.6228 11.1139 22.2561 11.1139C21.8895 11.1139 21.6071 11.2386 21.4091 11.4879C21.2111 11.7299 21.1085 12.0636 21.1011 12.4889H19.6051C19.6345 11.6089 19.8948 10.9416 20.3861 10.4869C20.8848 10.0323 21.5155 9.80495 22.2781 9.80495C23.1141 9.80495 23.7558 10.0286 24.2031 10.4759C24.6505 10.9159 24.8741 11.4989 24.8741 12.2249C24.8741 12.7969 24.7201 13.3433 24.4121 13.8639C24.1041 14.3846 23.7521 14.8393 23.3561 15.2279C22.9601 15.6093 22.4431 16.0713 21.8051 16.6139H25.0501V17.8899H19.6161V16.7459L20.1881 16.2619Z" fill="#07303E"/>
<path d="M28.6636 12.0929C28.7003 11.3596 28.957 10.7949 29.4336 10.3989C29.9176 9.99561 30.552 9.79395 31.3366 9.79395C31.872 9.79395 32.3303 9.88928 32.7116 10.0799C33.093 10.2633 33.379 10.5163 33.5696 10.8389C33.7676 11.1543 33.8666 11.5136 33.8666 11.9169C33.8666 12.3789 33.7456 12.7713 33.5036 13.0939C33.269 13.4093 32.9866 13.6219 32.6566 13.7319V13.7759C33.082 13.9079 33.412 14.1426 33.6466 14.4799C33.8886 14.8173 34.0096 15.2499 34.0096 15.7779C34.0096 16.2179 33.907 16.6103 33.7016 16.9549C33.5036 17.2996 33.2066 17.5709 32.8106 17.7689C32.422 17.9596 31.9526 18.0549 31.4026 18.0549C30.574 18.0549 29.8993 17.8459 29.3786 17.4279C28.858 17.0099 28.583 16.3939 28.5536 15.5799H30.0496C30.0643 15.9393 30.1853 16.2289 30.4126 16.4489C30.6473 16.6616 30.9663 16.7679 31.3696 16.7679C31.7436 16.7679 32.0296 16.6653 32.2276 16.4599C32.433 16.2473 32.5356 15.9759 32.5356 15.6459C32.5356 15.2059 32.3963 14.8906 32.1176 14.6999C31.839 14.5093 31.4063 14.4139 30.8196 14.4139H30.5006V13.1489H30.8196C31.861 13.1489 32.3816 12.8006 32.3816 12.1039C32.3816 11.7886 32.2863 11.5429 32.0956 11.3669C31.9123 11.1909 31.6446 11.1029 31.2926 11.1029C30.948 11.1029 30.6803 11.1983 30.4896 11.3889C30.3063 11.5723 30.2 11.8069 30.1706 12.0929H28.6636Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon60;
