import React from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Outside from "../icons/paparazziIcons/Outside.svg";
import ShowRoom from "../icons/paparazziIcons/ShowRoom.svg";

import Trees from "../icons/paparazziIcons/Trees.svg";
import Buildings from "../icons/paparazziIcons/Buildings.svg";
import NoReflection from "../icons/paparazziIcons/NoReflection.svg";
import Lights from "../icons/paparazziIcons/lights.svg";
import NoLights from "../icons/paparazziIcons/noLights.svg";

import PaparazziSelectorComponent from "./sunLocationComponent/PaparazziSelectorComponent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControlLabelLabel: { display: "none" },
  radioRoot: { padding: 0, margin: 0 },
  button: {
    border: "1px solid #C4C4C4",
    width: "100px",
  },
  buttonTypography: {
    color: "#186985",
    fontSize: "14px",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
}));

const iconSelector = (optionId) => {
  switch (optionId) {
    case 4198:
      return Outside;
    case 4199:
      return ShowRoom;
    case 4293:
      return Trees;
    case 4294:
      return Buildings;
    case 4295:
      return NoReflection;
    case 4296:
      return Lights;
    case 4297:
      return NoLights;
    default:
      return "#";
  }
};

const CheckButton = (props) => {
  const classes = useStyles();

  const { title, optionId, divStyle } = props;
  return (
    <Button variant="outlined" className={classes.button} style={divStyle}>
      <div className={classes.buttonInnerDiv}>
        {iconSelector(optionId) !== "#" && <img src={iconSelector(optionId)} />}
        <Typography className={classes.buttonTypography}>{title}</Typography>
      </div>
    </Button>
  );
};

const PapaTestRadioGroup = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { options, setVal, val, sunLocation, size } = props;

  const handleChange = (event) => {
    let option = options.find((el) => el.id.toString() === event.target.value);
    setVal(option.id);
  };

  const handleSunLocationChange = (value) => {
    let option = options.find((el) => {
      return el.id.toString() === value;
    });
    setVal(option.id.toString());
  };

  return (
    <>
      {sunLocation ? (
        <PaparazziSelectorComponent
          handleSunLocationChange={handleSunLocationChange}
          value={val}
          size={size}
        />
      ) : (
        <RadioGroup
          value={val.toString()}
          onChange={handleChange}
          style={{
            flexDirection: "row",
            width: "100%",
          }}
        >
          {options.map((option, i) => (
            <div style={{ display: "flex" }}>
              <FormControlLabel
                value={option.id.toString()}
                control={
                  <Radio
                    color={"primary"}
                    checkedIcon={
                      <CheckButton
                        title={t(option.title)}
                        optionId={option.id}
                        divStyle={{ border: "4px solid #186985" }}
                      />
                    }
                    icon={
                      <CheckButton
                        title={t(option.title)}
                        optionId={option.id}
                      />
                    }
                  />
                }
                label={t(option.title)}
                key={`field_radio_button_${i}_${option.id}`}
                classes={{
                  label: classes.formControlLabelLabel,
                  root: classes.radioRoot,
                }}
              />
            </div>
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default PapaTestRadioGroup;
