import cookie from "js-cookie";
import fetch from "isomorphic-fetch";
import { logout, updateToken } from "./actions/auth";
import cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import API_URLS from "../api";
import store from "./index";

// export function createReducer(initialState, reducerMap) {
//   return (state = initialState, action) => {
//     const reducer = reducerMap[action.type];

//     return reducer ? reducer(state, action.payload) : state;
//   };
// }

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  var hr = window.location.href;
  // if (response.status == 404 && !hr.includes("car-")) {
  //   window.location.replace("/404");
  // }
  let error;
  if (response) {
    error = new Error(response.message);
  } else {
    error = new Error(response.statusText);
  }
  error.response = response;
  throw error;
}

export function parseJSON(response) {
  return response.json();
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

//consider changing the location for token check below
export function requestAPI(data) {
  const token = cookies.get("token");
  const refresh = cookies.get("refresh-token")
    ? cookies.get("refresh-token")
    : null; // check if null is not problimatic
  const tokenEXP = token !== undefined ? jwtDecode(token)?.exp : 0;
  const refreshEXP = refresh ? jwtDecode(refresh).exp : 0;
  const currentTime = Date.now() / 1000;

  if (cookie.get("token") !== undefined && !(tokenEXP >= currentTime)) {
    // if token not expired or no token (not logged in) then no need for refresh
    if (refreshEXP >= currentTime && tokenEXP <= currentTime) {
      // if refresh is not expired but token is then refresh (two calls: 1.refresh 2.origional call)
      // expired token and unexpired refresh
      let refreshData = {
        // data for refresh, could not use saga since the new token will be saved after the second (origional) call is fired check though I MAY HAVE DONE SOMETHING WRONG
        url: API_URLS().AUTH.OBTAIN_REFRESH_TOKEN,
        method: "POST",
        body: {
          refresh: cookies.get("refresh-token"),
        },
      };
      let refreshResponse = () => {
        // i think should not be async check later
        return fetch(refreshData.url, {
          // return *
          method: refreshData.method || "GET",
          body: JSON.stringify(refreshData.body),
          headers: {
            Accept: refreshData.accept || "application/json",
            "Accept-Language": localStorage.getItem("language") || "en",
            "Content-Type": refreshData.contentType || "application/json",
            Authorization: cookie.get("token")
              ? `JWT ${cookie.get("token")}`
              : undefined,
          },
        })
          .then(checkHttpStatus)
          .then(parseJSON)
          .then((result) => {
            cookies.set("token", result.access, {
              expires: 2110,
            });
            store.dispatch(updateToken(result));
          }) // end of refresh call
          .then(() => {
            // origional call
            return fetch(data.url, {
              // return to responce = requestAPI() in sage not really sure how it works with other returns (*,**)
              method: data.method || "GET",
              body: JSON.stringify(data.body),
              headers: {
                Accept: data.accept || "application/json",
                "Accept-Language": localStorage.getItem("language") || "en",
                "Content-Type": data.contentType || "application/json",
                Authorization: cookie.get("token")
                  ? `JWT ${cookie.get("token")}`
                  : undefined,
              },
            })
              .then(checkHttpStatus)
              .then(parseJSON);
          });
      };
      return refreshResponse(); // return *
    } else if (refreshEXP <= currentTime) {
      // expired token and expired refresh
      if (window.location.href.indexOf("/login") === -1) {
        window.location.replace("/login"); // redirect to login if refresh is expired no need to remove token since it already expires before refresh
      }
      store.dispatch(logout());
      return false; // not sure what to return (false from previos tries)
    }
  } else {
    // if no refresh is required (token not available(logged out) or not expired)
    return fetch(data.url, {
      method: data.method || "GET",
      body: JSON.stringify(data.body),
      headers: {
        Accept: data.accept || "application/json",
        "Accept-Language": localStorage.getItem("language") || "en",
        "Content-Type": data.contentType || "application/json",
        Authorization: cookie.get("token")
          ? `JWT ${cookie.get("token")}`
          : undefined,
      },
    })
      .then(checkHttpStatus)
      .then(parseJSON);
  }
}
