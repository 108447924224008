import { createReducer } from "../createReducerFunc";
import { SET_APP_MESSAGE } from "../constants";

const initialState = {
  appMessage: {},
};

export default createReducer(initialState, {
  [SET_APP_MESSAGE]: (state, payload) => {
    console.log("appMessagePayload", payload);
    return Object.assign({}, state, {
      appMessage: payload,
    });
  },
});
