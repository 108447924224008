import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { withRouter } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@material-ui/icons/Language";
import Grid from "@material-ui/core/Grid";
import Markabte_English_Logo from "../icons/Markabte_English_Logo.svg";
import LOGO from "../icons/MOB_LOGIN_Logo.svg";
import LogInImage from "../images/loginImg.png";

import Hidden from "@material-ui/core/Hidden";
import { getToken } from "../store/actions/auth";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MarkabteLogoEn from "../components/icons/MarkabteLogoEn";
import MarkabteLogoAr from "../components/icons/MarkabteLogoAr";

import MuiPhoneInput from "material-ui-phone-number";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { removePhoneZero } from "../helpers/functions";

const useStyle = makeStyles((theme) => ({
  paperContainer: {
    height: "50%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  bottomImg: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  formControlRoot: {
    // borderRadius: "8.67px",
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707, // mobile keypad Phone Number pass problem solve
      maxHeight: "40px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707, // mobile keypad Phone Number pass problem solve
      maxHeight: "40px",
      backgroundColor: "white",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  svgIconRoot: {
    fontSize: "2rem",
  },
  checkbox: {
    padding: "0 8px 0 0 ",
  },
  keepLable: {
    fontSize: "clamp(0.8rem,1.28vw,1rem)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  forgotText: {
    textTransform: "none",
    fontWeight: "400",
    padding: 0,
    fontSize: "16px",
    whiteSpace: "nowrap",
    marginLeft: "8px",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  circleDiv: {
    height: "130vh",
    borderRadius: "0% 100rem 100rem 0%", // problematic at high heights and low widths
    // borderRadius: "0% 50% 50% 0%", // not as circular
    backgroundColor: "white",
    position: "relative",
    transform: "translate(0, -15vh)",
    maxWidth: "46.11%",
    flexBasis: "46.11%",
    padding: "15vh 0 15vh 7.013vw",
    zIndex: "2",
  },
  languageButton: {
    display: "flex",
    alignSelf: "flex-end",
    margin: "auto 10.5vw auto 0",
  },
  languageIcon: {
    fill: "#07303E",
    width: "18px",
    height: "18px",
    marginRight: "6px",
  },
  logo: {
    width: "fit-content", // why?
    // marginLeft: "-17px",
  },
  logoGridItem: {
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "9.722vw",
      height: "max-content",
    },
  },
  // checkbox: {
  //   padding: 0,
  //   marginRight: "1vw",
  // },
  signinButton: {
    width: "100%",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "none",
    padding: "9px 45px",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    boxShadow: "0px 2px 5px rgba(9, 38, 48, 0.4)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 25px",
    },
  },
  loginImage: {
    position: "absolute",
    top: 0,
    right: 0,
    // right: "-10px",
    height: "100%",
    width: "70%",
    // width: "67.85%",
    objectFit: "cover",
  },
  secondIntroTypography: {
    color: "white",
    fontSize: "30px",
    fontWeight: "600",
    marginLeft: "auto",
    zIndex: "1",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      color: "rgb(7,48,62)",
    },
  },

  icon: {
    borderRadius: 3,
    width: 22,
    height: 22,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    [theme.breakpoints.down("sm")]: {
      width: 16,
      height: 16,
    },
  },
  checkedIcon: {
    backgroundColor: "#0c6885",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      [theme.breakpoints.down("sm")]: {
        width: 16,
        height: 16,
      },
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  phone: {
    "& .MuiOutlinedInput-notchedOutline": {
      direction: "ltr",
    },
  },
}));

const Login = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [Phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { history, isAuthenticating, getToken } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (Phone?.[4] == "0") {
      let phoneNumber = removePhoneZero(Phone);
      setPhone(phoneNumber);
    }
    if (Phone?.length < 4 || Phone == undefined) {
      setPhone("+962");
    }
  }, [Phone]);

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
    var bodyTag = document.getElementsByTagName("body");
    bodyTag[0].style.overflow = "hidden";
    window.scroll(0, 0);
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    getToken({
      Phone: Phone,
      password: password,
      callback: () => {
        history.push("/");
      },
      callback2: () => {
        history.push("/account_activation");
      },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const convertPhone = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const [errors, setErrors] = useState({});

  return (
    <div style={{ height: "100%" }}>
      <Hidden smDown>
        <Grid
          container
          style={{
            minHeight: "100%",
            // background: colors.cssGradient.secondary,
            background: "#dddddd",
          }}
        >
          <div
            className={classes.loginImage}
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 103.8%)",
              zIndex: "1",
              height: "42.22%",
              transform: "rotate(-180deg)",
            }}
          />
          <img src={LogInImage} className={classes.loginImage} />
          <Grid item className={classes.circleDiv}>
            <Grid
              container
              justify={"center"}
              style={{
                paddingTop: "5.7vh", // it is supposed to be 7.852vh but sence the logo has white space above it it was taken down to 5.7vh
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Grid item className={classes.languageButton}>
                      <Button
                        style={{ padding: "0" }}
                        onClick={() => {
                          localStorage.getItem("language") === "ar"
                            ? localStorage.setItem("language", "en")
                            : localStorage.setItem("language", "ar");
                          window.location.reload();
                        }}
                        name="checkedB"
                      >
                        <LanguageIcon className={classes.languageIcon} />
                        {localStorage.getItem("language") === "en" ? (
                          <Typography
                            style={{
                              color: "#0c6885",
                              textTransform: "none",
                            }}
                          >
                            Arabic
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              color: "#0c6885",
                            }}
                          >
                            English
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                    <Grid item className={classes.logoGridItem}>
                      {localStorage.getItem("language") === "ar" ? (
                        <MarkabteLogoAr
                          style={{ width: "148px" }}
                          className={classes.logo}
                        />
                      ) : (
                        <MarkabteLogoEn
                          style={{ width: "148px" }}
                          className={classes.logo}
                        />
                      )}
                    </Grid>
                  </div>
                  <Grid item xs={12}>
                    <Typography
                      variant={"h3"}
                      style={{
                        fontWeight: "700",
                        fontSize: "24px",
                        color: "#07303E",
                        margin: "8.44vh 0 6.81vh",
                        lineHeight: "1.5",
                      }}
                    >
                      {t("Welcome Back!")}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <form onSubmit={handleLogin}>
                      <FormControl
                        fullWidth
                        style={{
                          boxShadow:
                            "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                          marginBottom: "3.555vh",
                          width: "28.542vw",
                          direction: "ltr",
                        }}
                        classes={{ root: classes.formControlRoot }}
                      >
                        {/* <TextField
                          InputProps={{
                            classes: { root: classes.inputBaseRoot },
                          }}
                          value={Phone}
                          onChange={(event) => {
                            setPhone(event.target.value);
                          }}
                          fullWidth
                          label={t("Phone Number")}
                          variant={"outlined"}
                          InputLabelProps={{
                            classes: {
                              outlined: clsx({
                                [classes.inputLableOutlined]:
                                  theme.direction === "ltr" ||
                                  localStorage.getItem("language") === "en",
                                [classes.inputLableOutlinedArabic]:
                                  theme.direction === "rtl" ||
                                  localStorage.getItem("language") === "ar",
                              }),
                            },
                          }}
                        /> */}
                        <PhoneInput
                          id="webLoginField"
                          smartCaret={false}
                          required
                          international
                          addInternationalOption={false}
                          countries={["JO"]}
                          country="JO"
                          defaultCountry="JO"
                          value={Phone}
                          onChange={(e) => {
                            setPhone(convertPhone("phone", e).target.value);
                          }}
                          fullWidth
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        style={{
                          boxShadow:
                            "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                          marginBottom: "4vh",
                          width: "28.542vw",
                        }}
                        classes={{ root: classes.formControlRoot }}
                      >
                        <TextField
                          InputProps={{
                            classes: { root: classes.inputBaseRoot },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOutlinedIcon />
                                  ) : (
                                    <VisibilityOffOutlinedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          value={password}
                          type={showPassword ? "text" : "password"}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          fullWidth
                          label={t("Password")}
                          variant={"outlined"}
                          InputLabelProps={{
                            classes: {
                              outlined: clsx({
                                [classes.inputLableOutlined]:
                                  theme.direction === "ltr" ||
                                  localStorage.getItem("language") === "en",
                                [classes.inputLableOutlinedArabic]:
                                  theme.direction === "rtl" ||
                                  localStorage.getItem("language") === "ar",
                              }),
                            },
                          }}
                        />
                      </FormControl>
                      <Grid
                        container
                        justify="space-between"
                        style={{
                          width: "28.542vw",
                          marginBottom: "5.83vh",
                        }}
                      >
                        <Grid item xs={7}>
                          <FormControlLabel
                            style={{
                              color: "#07303E",
                              margin: 0,
                            }}
                            boxShadow
                            control={
                              <Checkbox
                                className={classes.checkbox}
                                // style={{ padding: "0 8px 0 0 " }}
                                disableRipple
                                color="primary"
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                icon={<span className={classes.icon} />}
                                inputProps={{
                                  "aria-label": "decorative checkbox",
                                }}
                              />
                            }
                            classes={{ label: classes.keepLable }}
                            label={t("Keep me signed in")}
                            labelPlacement="end"
                          />
                        </Grid>
                      </Grid>
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          width: "28.542vw",
                          marginBottom: "3.55vh",
                        }}
                      >
                        <Button
                          type="submit"
                          loading={isAuthenticating}
                          children={t("Sign In")}
                          variant={"contained"}
                          color={"primary"}
                          onClick={handleLogin}
                          className={classes.signinButton}
                        />
                      </FormControl>
                      <div style={{ display: "flex" }}>
                        <Typography>{t("Forgot your password?")}</Typography>
                        <Button
                          size="small"
                          className={classes.forgotText}
                          color={"primary"}
                          children={t("Reset Password")}
                          onClick={() => {
                            history.push("/forgot_password/");
                          }}
                        />
                      </div>
                    </form>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              maxWidth: "43.4722%",
              flexBasis: "43.4722%",
            }}
          >
            <Typography
              variant="h4"
              style={{
                color: "white",
                fontSize: "30px",
                fontWeight: "600",
                marginTop: "10.52vh",
                zIndex: "1",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{t("INSPECT")}</span>{" "}
              {t("the easy way,")}
            </Typography>
            <Typography variant="h4" className={classes.secondIntroTypography}>
              <span style={{ fontWeight: "bold" }}>{t("EARN")}</span>{" "}
              {t("money your way.")}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div
          style={{
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <img
            src={LogInImage}
            style={{
              position: "fixed",
              filter: "blur(1.3px)", // try a lower blur 1px for example
              left: "-176px",
              width: "202.22%", // try objectFit:'cover', instead of height & width
              height: "128.8%",
            }}
          />
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              background: "rgba(255, 255, 255, 0.3)",
            }}
          />
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 99.51%)",
              transform: "rotate(-180deg)",
            }}
          />
          <Grid container>
            <div
              style={{
                width: "100%",
                display: "flex",
                margin: "6.107vh 0 14.885vh",
              }}
            >
              <Grid
                item
                className={classes.logoGridItem}
                style={
                  {
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // marginLeft: "9.722vw",
                    // height: "max-content",
                  }
                }
              >
                {localStorage.getItem("language") === "ar" ? (
                  <MarkabteLogoAr
                    style={{
                      zIndex: "2",
                      width: "123px",
                      height: "auto",
                    }}
                  />
                ) : (
                  <MarkabteLogoEn
                    style={{
                      zIndex: "2",
                      width: "153px",
                      height: "auto",
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  margin: "auto 9.722vw auto",
                }}
              >
                <Button
                  style={
                    localStorage.getItem("language") === "en"
                      ? {
                          padding: 0,
                          height: "max-content",
                        }
                      : { /*right: "-5%",*/ padding: 0 }
                  }
                  onClick={() => {
                    localStorage.getItem("language") === "ar"
                      ? localStorage.setItem("language", "en")
                      : localStorage.setItem("language", "ar");
                    window.location.reload();
                  }}
                  name="checkedB"
                >
                  <LanguageIcon
                    style={{
                      fill: "#07303E",
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                    }}
                  />
                  {localStorage.getItem("language") === "en" ? (
                    <Typography
                      style={{
                        color: "#0c6885",
                        fontSize: "14px",
                        textTransform: "none",
                      }}
                    >
                      Arabic
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        color: "#0c6885",
                      }}
                    >
                      English
                    </Typography>
                  )}
                </Button>
              </Grid>
            </div>
            <Grid
              item
              xs={12}
              style={{
                margin: "0 9.722vw",
                position: "relative",
                zIndex: 10,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={"h3"}
                  style={{
                    fontWeight: "700",
                    marginBottom: "1.145vh",
                    fontSize: "22px",
                    lineHeight: "1.5",
                  }}
                >
                  {t("Welcome Back!")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "4.58vh", display: "flex" }}
              >
                <Typography
                  style={{
                    color: "rgb(7,48,62)",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <span style={{ fontWeight: "600", color: "#f15a29" }}>
                    {t("INSPECT")}
                  </span>{" "}
                  {t("the easy way,")}{" "}
                  <span style={{ fontWeight: "600", color: "#f15a29" }}>
                    {t("EARN")}
                  </span>{" "}
                  {t("money your way.")}
                </Typography>
              </Grid>

              <Grid container>
                <form onSubmit={handleLogin}>
                  <FormControl
                    fullWidth
                    style={{
                      boxShadow:
                        "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                      marginBottom: "3.05vh",
                      display: "contents",
                      direction: "ltr",
                    }}
                    classes={{ root: classes.formControlRoot }}
                  >
                    {/* <TextField
                      InputProps={{
                        classes: { root: classes.inputBaseRoot },
                      }}
                      value={Phone}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                      fullWidth
                      label={t("Phone Number")}
                      variant={"outlined"}
                      InputLabelProps={{
                        classes: {
                          outlined: clsx({
                            [classes.inputLableOutlined]:
                              theme.direction === "ltr" ||
                              localStorage.getItem("language") === "en",
                            [classes.inputLableOutlinedArabic]:
                              theme.direction === "rtl" ||
                              localStorage.getItem("language") === "ar",
                          }),
                        },
                      }}
                    /> */}
                    <PhoneInput
                      smartCaret={false}
                      required
                      international
                      addInternationalOption={false}
                      countries={["JO"]}
                      country="JO"
                      defaultCountry="JO"
                      value={Phone}
                      onChange={(e) => {
                        setPhone(convertPhone("phone", e).target.value);
                      }}
                      fullWidth
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    style={{
                      boxShadow:
                        "0px 5.20309px 8.67182px rgba(130, 148, 154, 0.4)",
                      marginBottom: "3.44vh",
                    }}
                    classes={{ root: classes.formControlRoot }}
                  >
                    <TextField
                      InputProps={{
                        classes: { root: classes.inputBaseRoot },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      fullWidth
                      label={t("Password")}
                      variant={"outlined"}
                      InputLabelProps={{
                        classes: {
                          outlined: clsx({
                            [classes.inputLableOutlined]:
                              theme.direction === "ltr" ||
                              localStorage.getItem("language") === "en",
                            [classes.inputLableOutlinedArabic]:
                              theme.direction === "rtl" ||
                              localStorage.getItem("language") === "ar",
                          }),
                        },
                      }}
                    />
                  </FormControl>
                  <Grid
                    container
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={7}>
                      <FormControlLabel
                        style={{
                          color: "#07303E",
                          margin: 0,
                        }}
                        boxShadow
                        control={
                          <Checkbox
                            className={classes.checkbox}
                            // style={{ padding: "0 8px 0 0 " }}
                            disableRipple
                            color="primary"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                        classes={{ label: classes.keepLable }}
                        label={t("Keep me signed in")}
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Button
                        className={classes.forgotText}
                        size="small"
                        color={"primary"}
                        children={t("forgot password")}
                        onClick={() => {
                          history.push("/forgot_password/");
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FormControl
                    style={{
                      margin: "4.96vh 0 0",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      type="submit"
                      loading={isAuthenticating}
                      children={t("Sign In")}
                      variant={"contained"}
                      color={"primary"}
                      onClick={handleLogin}
                      className={classes.signinButton}
                    />
                  </FormControl>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticating: state.auth.isAuthenticating,
});

const mapDispatchToProps = (dispatch) => ({
  getToken(credentials) {
    dispatch(getToken(credentials));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
