import React from "react";

const FeatureIcon69 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 54" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M15.0059 11L12.8499 7.19203H11.9259V11H9.96594V1.22803H13.6339C14.3899 1.22803 15.0339 1.36336 15.5659 1.63403C16.0979 1.89536 16.4946 2.25469 16.7559 2.71203C17.0266 3.16003 17.1619 3.66403 17.1619 4.22403C17.1619 4.86803 16.9753 5.45136 16.6019 5.97403C16.2286 6.48736 15.6733 6.84203 14.9359 7.03803L17.2739 11H15.0059ZM11.9259 5.72203H13.5639C14.0959 5.72203 14.4926 5.59603 14.7539 5.34403C15.0153 5.08269 15.1459 4.72336 15.1459 4.26603C15.1459 3.81803 15.0153 3.47269 14.7539 3.23003C14.4926 2.97803 14.0959 2.85203 13.5639 2.85203H11.9259V5.72203Z" fill="#07303E"/>
<path d="M26.1447 6.95403C26.1447 7.23403 26.126 7.48603 26.0887 7.71003H20.4187C20.4654 8.27003 20.6614 8.70869 21.0067 9.02603C21.352 9.34336 21.7767 9.50203 22.2807 9.50203C23.0087 9.50203 23.5267 9.18936 23.8347 8.56403H25.9487C25.7247 9.31069 25.2954 9.92669 24.6607 10.412C24.026 10.888 23.2467 11.126 22.3227 11.126C21.576 11.126 20.904 10.9627 20.3067 10.636C19.7187 10.3 19.2567 9.82869 18.9207 9.22203C18.594 8.61536 18.4307 7.91536 18.4307 7.12203C18.4307 6.31936 18.594 5.61469 18.9207 5.00803C19.2474 4.40136 19.7047 3.93469 20.2927 3.60803C20.8807 3.28136 21.5574 3.11803 22.3227 3.11803C23.06 3.11803 23.718 3.27669 24.2967 3.59403C24.8847 3.91136 25.3374 4.36403 25.6547 4.95203C25.9814 5.53069 26.1447 6.19803 26.1447 6.95403ZM24.1147 6.39403C24.1054 5.89003 23.9234 5.48869 23.5687 5.19003C23.214 4.88203 22.78 4.72803 22.2667 4.72803C21.7814 4.72803 21.3707 4.87736 21.0347 5.17603C20.708 5.46536 20.5074 5.87136 20.4327 6.39403H24.1147Z" fill="#07303E"/>
<path d="M27.0713 7.09403C27.0713 6.31003 27.2253 5.61469 27.5333 5.00803C27.8507 4.40136 28.2753 3.93469 28.8073 3.60803C29.3487 3.28136 29.9507 3.11803 30.6133 3.11803C31.192 3.11803 31.696 3.23469 32.1253 3.46803C32.564 3.70136 32.914 3.99536 33.1753 4.35003V3.24403H35.1493V11H33.1753V9.86603C32.9233 10.23 32.5733 10.5334 32.1253 10.776C31.6867 11.0094 31.178 11.126 30.5993 11.126C29.946 11.126 29.3487 10.958 28.8073 10.622C28.2753 10.286 27.8507 9.81469 27.5333 9.20803C27.2253 8.59203 27.0713 7.88736 27.0713 7.09403ZM33.1753 7.12203C33.1753 6.64603 33.082 6.24003 32.8953 5.90403C32.7087 5.55869 32.4567 5.29736 32.1393 5.12003C31.822 4.93336 31.4813 4.84003 31.1173 4.84003C30.7533 4.84003 30.4173 4.92869 30.1093 5.10603C29.8013 5.28336 29.5493 5.54469 29.3533 5.89003C29.1667 6.22603 29.0733 6.62736 29.0733 7.09403C29.0733 7.56069 29.1667 7.97136 29.3533 8.32603C29.5493 8.67136 29.8013 8.93736 30.1093 9.12403C30.4267 9.31069 30.7626 9.40403 31.1173 9.40403C31.4813 9.40403 31.822 9.31536 32.1393 9.13803C32.4567 8.95136 32.7087 8.69003 32.8953 8.35403C33.082 8.00869 33.1753 7.59803 33.1753 7.12203Z" fill="#07303E"/>
<path d="M39.0236 4.44803C39.2756 4.03736 39.6023 3.71536 40.0036 3.48203C40.4143 3.24869 40.8809 3.13203 41.4036 3.13203V5.19003H40.8856C40.2696 5.19003 39.8029 5.33469 39.4856 5.62403C39.1776 5.91336 39.0236 6.41736 39.0236 7.13603V11H37.0636V3.24403H39.0236V4.44803Z" fill="#07303E"/>
<path d="M11.5391 20.4038H11.2891V20.6538C11.2891 28.2265 17.4279 34.3653 25.0006 34.3653C32.5733 34.3653 38.7121 28.2265 38.7121 20.6538V20.4038H38.4621L11.5391 20.4038ZM6.51084 35.2395L6.51086 35.2395C7.16443 34.5859 8.22294 34.5859 8.87651 35.2395C9.20319 35.5661 9.36609 35.9941 9.36609 36.423C9.36609 36.8518 9.20321 37.2793 8.87576 37.6045L8.87516 37.6051L3.10593 43.3743L3.10592 43.3743C2.45236 44.028 1.39402 44.0281 0.74029 43.3743L0.740272 43.3743C0.0865927 42.7208 0.0865603 41.6624 0.740269 41.0087C0.740276 41.0087 0.740283 41.0087 0.74029 41.0087L6.51084 35.2395ZM41.1246 35.2411L41.1248 35.2408C41.7786 34.5871 42.8369 34.5871 43.4905 35.2408L43.6673 35.0641L43.4905 35.2408L49.2597 41.0101L49.4365 40.8333L49.2597 41.0101C49.9134 41.6636 49.9134 42.722 49.2597 43.3757L49.4365 43.5525L49.2597 43.3757C48.6061 44.0294 47.5478 44.0294 46.8941 43.3757L41.1248 37.6065C40.7982 37.2798 40.6353 36.8518 40.6353 36.423C40.6353 35.9941 40.7982 35.5665 41.1246 35.2411ZM38.4621 17.0576C40.4484 17.0576 42.0583 18.6674 42.0583 20.6538C42.0583 30.0592 34.4061 37.7114 25.0006 37.7114C15.5951 37.7114 7.94293 30.0592 7.94293 20.6538C7.94293 18.6674 9.55276 17.0576 11.5391 17.0576L38.4621 17.0576ZM23.3276 51.4229V43.7306C23.3276 42.8059 24.076 42.0576 25.0007 42.0576C25.9254 42.0576 26.6738 42.8059 26.6738 43.7306V51.4229C26.6738 52.3477 25.9254 53.096 25.0007 53.096C24.076 53.096 23.3276 52.3476 23.3276 51.4229Z" fill="#07303E" stroke="white" strokeWidth="0.5"/>
</svg>
);
};

export default FeatureIcon69;
