import React from "react";

const FeatureIcon65 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon65Clip0)">
<path d="M0.833336 29.1645C3.64896 29.1737 6.22886 27.5936 7.5 25.0812C7.5 25.0545 7.5 25.0295 7.5 25.002C7.5 14.9395 13.8783 9.1687 25 9.1687C35.3808 9.16454 50 14.0812 50 24.9979C50 35.9145 35.3808 40.8312 25 40.8312H0.833336C0.373127 40.8312 0 40.4581 0 39.9979V29.9979C0 29.5377 0.373127 29.1645 0.833336 29.1645ZM48.3333 24.9979C48.3333 15.3312 34.41 10.8312 25 10.8312C14.79 10.8312 9.16667 15.8629 9.16667 24.9979C9.16667 34.1329 14.79 39.1645 25 39.1645C34.41 39.1645 48.3333 34.6645 48.3333 24.9979ZM1.66667 39.1645H15.97C11.2575 37.1079 8.39584 33.1879 7.68917 27.7329C6.13927 29.4757 3.98812 30.5686 1.66667 30.7929V39.1645Z" fill="#07303E"/>
<path d="M25.6116 12.4978C34.355 12.4978 46.6666 16.3761 46.6666 24.9978C46.6666 33.6195 34.355 37.4978 25.6116 37.4978C16.22 37.4978 10.8333 32.942 10.8333 24.9978C10.8333 17.0536 16.22 12.4978 25.6116 12.4978ZM25.6116 35.8311C33.4308 35.8311 45 32.3878 45 24.9978C45 17.6078 33.4308 14.1645 25.6116 14.1645C20.715 14.1645 12.5 15.572 12.5 24.9978C12.5 34.4236 20.715 35.8311 25.6116 35.8311Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon65Clip0">
<rect width="50" height="50" fill="white" transform="matrix(-1 0 0 1 50 0)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon65;
