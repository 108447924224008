import React, { useRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import queryString from "query-string";
import FormFields from "./FormFields";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ErrorIcon from "@material-ui/icons/Error";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import SaveIcon from "@material-ui/icons/Save";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQueryMUI from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import {
  updateFeatures,
  getFields,
  updateformFields,
  updateAnswers,
  updatePoints,
  getGenericFeatures,
  updateGenericFeatures,
  getOptionsFields,
  clearAnswers,
  updateHidden,
  getPaparazziPackage,
} from "../store/actions/forms";
import {
  getCarForms,
  getCarFeatures,
  getCarVisualization,
  getCarGenericFeatures,
} from "../store/actions/car";
import { toggleNotification } from "../store/actions/notification";

import theme from "../theme";

import LinearProgress from "@material-ui/core/LinearProgress";
import { FormLabel, Hidden } from "@material-ui/core";
import useWindowSize from "../helpers/useWindowSize";
import ComputerScanTable from "./ComputerScanTable";
import StaticText from "../components/StaticText";
import { useTranslation } from "react-i18next";
import AddNewCar from "../containers/CarListings/AddNewCar";
import PAangles from "../containers/papaPopers/PAangles";
import PapaHub from "../containers/papaPopers/PapaHub";

const getUniqueArray = (array, key) => {
  var unique = {};
  var distinct = [];
  for (var i in array) {
    if (typeof unique[array[i][key]] == "undefined") {
      distinct.push(array[i]);
    }
    unique[array[i][key]] = 0;
  }
  return distinct;
};

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    position: "relative",
    pointerEvents: "none",
  },
  circularProg: {
    position: "sticky",
    top: "50%",
    justifySelf: "center",
  },
  bgDio: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
    },
  },
  // speedDial: isIOS
  //   ? {
  //       position: "fixed",
  //       "&.MuiFab-root": {
  //         bottom: theme.spacing(5),
  //         right: theme.spacing(4),
  //       },
  //     }
  //   : {
  //       position: "fixed",
  //       "&.MuiFab-root": {
  //         bottom: theme.spacing(2),
  //         right: theme.spacing(2),
  //       },
  //     },
  speedDial: {
    // for web
    // position: "fixed",
    // position: "absolute",
    // transform: "translate(-50%,-50%)",
    // top: "calc(50% + 50vh - 50px)",
    // left: "90%",
    // "&.MuiFab-root": {
    //   bottom: theme.spacing(4),
    //   right: theme.spacing(4),
    // },
    // for mobile check IOS 50vw
    [theme.breakpoints.down("sm")]: isIOS
      ? {
          position: "absolute",
          transform: "translate(-50%,-50%)",
          top: "calc(50% + 50vw - 50px)",
          left: "90%",
        }
      : {
          position: "absolute",
          transform: "translate(-50%,-50%)",
          top: "calc(50% + 50vw - 50px)",
          left: "90%",
        },
  },

  paperWidthSm: {
    maxWidth: "90%",
    // margin: 0,
    height: "90%",
    maxHeight: "90vh",
  },
  visPaperPosition: {
    transform: "translate(-50%)",
    left: "50%",
  },
}));

const Point = (props) => {
  const {
    point,
    imageData,
    selectedPoint,
    isPortrait,
    isSmallDevice,
    imageRef,
  } = props;

  if (point) {
    var { x, y, option } = point;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  let currentWidth, currentHeight, naturalWidth, naturalHeight;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (imageData) {
      function handleResize() {
        currentWidth = imageData.width;
        currentHeight = imageData.height;

        naturalWidth = imageData.naturalWidth;
        naturalHeight = imageData.naturalHeight;
        if (isSmallDevice) {
          if (isPortrait) {
            style = {
              ...style,
              left:
                (y * currentHeight) / naturalWidth -
                window.screen.height / 35 / 2,
              top:
                (x * currentWidth) / naturalHeight -
                window.screen.height / 35 / 2,
            };
          } else {
            // for mobile landscape WORKING
            style = {
              ...style,
              top:
                (x * currentHeight) / naturalHeight -
                window.screen.width / 35 / 2,
              left:
                (y * currentWidth) / naturalWidth -
                window.screen.width / 35 / 2,
            };
          }
        } else {
          // for pc WORKING
          style = {
            ...style,
            top: (x * currentHeight) / naturalHeight - currentWidth / 35 / 2,
            left: (y * currentWidth) / naturalWidth - currentWidth / 35 / 2,
          };
        }
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);

      return (_) => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("orientationchange", handleResize);
      };
    }
  }, [imageRef, imageData, isSmallDevice, isPortrait]);

  let style = { position: "absolute" };

  const open = Boolean(anchorEl);

  if (!point || !imageData) {
    style["display"] = "none";
    return null;
  } else {
    let fromBigScreen = !isSmallDevice;
    // currentWidth = fromBigScreen ? imageData.width : window.screen.width;
    currentWidth = imageData.width;

    // currentHeight = fromBigScreen ? imageData.height : window.screen.height; //used to be imageData.height but it didnot rerender when orientation changed
    currentHeight = imageData.height;

    let naturalWidth = imageData.naturalWidth,
      naturalHeight = imageData.naturalHeight;

    const bigScreenIconSize = currentWidth / 38.5; //
    const mobScreenIconSize = isPortrait
      ? window.screen.height / 35 < 16
        ? 16
        : window.screen.height / 35
      : window.screen.width / 35 < 16
      ? 16
      : window.screen.width / 35; //

    // example: nh=853,ch=603,nw=1280, cw=616 notIOS and Web
    const verticalImageRatio = isPortrait
      ? currentWidth / naturalHeight // in Portrait current values are flipped
      : currentHeight / naturalHeight; // 0.707
    const horizontalImageRatio = isPortrait
      ? currentHeight / naturalWidth // in Portrait current values are flipped
      : currentWidth / naturalWidth; // 0.48...
    let imageSingleSideLoss = 0;
    let imageSingleLossVertical = 0;
    const imageHorizontalCenter = currentWidth / 2; // 308
    const imageVerticalCenter = currentHeight / 2; //301.5

    if (verticalImageRatio > horizontalImageRatio) {
      isPortrait
        ? (imageSingleSideLoss =
            (naturalWidth * verticalImageRatio - currentHeight) / 2)
        : (imageSingleSideLoss =
            (naturalWidth * verticalImageRatio - currentWidth) / 2); // ((1280*0.707)-616)/2 = 144.48
    } else {
      isPortrait
        ? (imageSingleLossVertical =
            (naturalHeight * horizontalImageRatio - currentWidth) / 2)
        : (imageSingleLossVertical =
            (naturalHeight * horizontalImageRatio - currentHeight) / 2); // ((1280*0.707)-616)/2 = 144.48
    }

    style["top"] = fromBigScreen
      ? (point.x * currentHeight) / naturalHeight +
        (((point.x * currentHeight) / naturalHeight - imageVerticalCenter) /
          imageVerticalCenter) *
          imageSingleLossVertical -
        bigScreenIconSize / 2 // porbably could remove and be added to the landscape option down
      : isPortrait
      ? (point.x * currentWidth) / naturalHeight - mobScreenIconSize / 2
      : // +
        //   (((point.x * currentWidth) / naturalHeight - imageHorizontalCenter) /
        //     imageHorizontalCenter) *
        //     imageSingleLossVertical -
        //   mobScreenIconSize / 2
        (point.x * currentHeight) / naturalHeight - mobScreenIconSize / 2;
    //  +
    //   (((point.x * currentHeight) / naturalHeight - imageVerticalCenter) /
    //     imageVerticalCenter) *
    //     imageSingleLossVertical -
    //   mobScreenIconSize / 2;

    fromBigScreen
      ? (style["right"] = // positioned from right instead of left because of drawer on the left that if i positioned from left i should compensate for could be changed
          currentWidth -
          (point.y * currentWidth) / naturalWidth + // origional right + (((origional right - imageHorizontalCenter)/imageHorizontalCenter)*imageSingleSideLoss - half the point width --ie. center--)
          ((currentWidth -
            (point.y * currentWidth) / naturalWidth -
            imageHorizontalCenter) /
            imageHorizontalCenter) *
            imageSingleSideLoss -
          bigScreenIconSize / 2)
      : (style["left"] = isPortrait
          ? // ? (point.y * currentHeight) / naturalWidth +
            //   (((point.y * currentHeight) / naturalWidth - imageVerticalCenter) /
            //     imageVerticalCenter) *
            //     imageSingleSideLoss -
            //   mobScreenIconSize / 2
            (point.y * currentHeight) / naturalWidth - mobScreenIconSize / 2
          : (point.y * currentWidth) / naturalWidth - mobScreenIconSize / 2);
    // +
    //   (((point.y * currentWidth) / naturalWidth - imageHorizontalCenter) /
    //     imageHorizontalCenter) *
    //     imageSingleSideLoss -
    //   mobScreenIconSize / 2);

    return (
      <div>
        <div style={style}>
          <Fab
            style={{
              width: isPortrait ? currentHeight / 35 : currentWidth / 35,
              height: isPortrait ? currentHeight / 35 : currentWidth / 35,
              minHeight: "unset",
            }}
            children={
              <WarningIcon
                style={{
                  fontSize: isPortrait ? currentHeight / 40 : currentWidth / 40,
                  fill: selectedPoint ? "#f15a29" : "rgba(0,0,0,0.87)",
                }}
              />
            }
            aria-describedby={"details-button-" + option.id}
            onClick={handleClick}
          />
        </div>
        {/* <div
          style={{
            width: "5px",
            height: "5px",
            backgroundColor: "green",
            position: "absolute",
            top: style["top"],
            left: style["left"],
          }}
        ></div> */}
      </div>
    );
  }
};

const Assign = (props) => {
  const classes = useStyles();

  const {
    getCarFaults,
    toggleNotification,
    position,
    formFields,
    getCarVisualization,
    points,
    updatePoints,
    openVisualizationDialog,
    setOpenVisualizationDialog,
    side,
    carId,
    selectedOptionAnswerId,
    selectedOption,
    formId,
  } = props;
  const theme = useTheme();
  const stageRef = useRef();
  const imageRef = useRef();
  const [stageHeight, setStageHeight] = useState(80);
  const [faults, setFaults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldIndex, setFieldIndex] = useState(0);
  const [imageData, setImageData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [first, setFirst] = useState(true);
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" }); // from react responsive node module

  const fullScreen = useMediaQueryMUI(theme.breakpoints.down("sm"));

  useEffect(() => {
    const disableZoom = (event) => {
      if (event.scale !== 1) event.preventDefault();
    };
    if (openVisualizationDialog) {
      document.addEventListener("touchmove", disableZoom, { passive: false });
    }
    return (_) => {
      document.removeEventListener("touchmove", disableZoom, {
        passive: false,
      });
      window.removeEventListener("touchmove", disableZoom, { passive: false });
    };
  }, [openVisualizationDialog]);

  useEffect(() => {
    if (imageRef.current) {
      let boundingRect = imageRef.current.getBoundingClientRect();

      if (window.innerWidth > 960) {
        const { width } = boundingRect;
        const { naturalWidth, naturalHeight } = imageRef.current;
        setStageHeight((width * naturalHeight) / naturalWidth);
      } else {
        let height = 80;
        setStageHeight(height / 1);
      }

      function handleResize() {
        let boundingRect = imageRef.current.getBoundingClientRect();

        if (window.innerWidth > 960) {
          const { width } = boundingRect;
          const { naturalWidth, naturalHeight } = imageRef.current;
          setStageHeight((width * naturalHeight) / naturalWidth);
        } else {
          const { width } = boundingRect;
          const { naturalWidth, naturalHeight } = imageRef.current;
          setStageHeight((width * naturalHeight) / naturalWidth);
        }
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);

      return (_) => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("orientationchange", handleResize);
      };
    }
  }, [
    imageRef,
    getCarFaults,
    stageHeight,
    carId,
    side,
    position,
    getCarVisualization,
    first,
    window.screen.width,
  ]);

  useEffect(() => {
    // here the category of the size of the screen is set (isSmallDevice)
    const isTabletOrMobileWidth = window.matchMedia(
      // "(max-device-width: 1224px)"
      "(max-device-width: 959px)"
    );
    const isTabletOrMobileHeight = window.matchMedia(
      // "(max-device-height: 1224px)"
      "(max-device-height: 959px)"
    );
    if (isTabletOrMobileHeight.matches && isTabletOrMobileWidth.matches) {
      setIsSmallDevice(true);
    } else {
      setIsSmallDevice(false);
    }
  }, []);

  const handleImageClick = (event) => {
    if (selectedOptionAnswerId) {
      let boundingRect = imageRef.current.getBoundingClientRect();

      let clientXOffset, clientYOffset;

      let naturalWidth, naturalHeight;

      let currentWidth, currentHeight;

      if (isSmallDevice) {
        if (isIOS()) {
          // not needed officer no ios and lock screen
          clientXOffset = event.clientY - boundingRect.top;
          clientYOffset =
            Math.abs(window.screen.width - event.clientX) - boundingRect.left;

          naturalWidth = imageRef.current.naturalWidth;
          naturalHeight = imageRef.current.naturalHeight;

          currentWidth = boundingRect.height;
          currentHeight = boundingRect.width;
        } else {
          if (isPortrait) {
            clientXOffset = event.clientY - boundingRect.top;
            clientYOffset =
              Math.abs(window.screen.width - event.clientX) - boundingRect.left;

            naturalWidth = imageRef.current.naturalWidth;
            naturalHeight = imageRef.current.naturalHeight;

            currentWidth = boundingRect.height;
            currentHeight = boundingRect.width;
          } else {
            // not needed officer no ios and lock screen
            clientXOffset = event.clientX - boundingRect.left;
            clientYOffset = event.clientY - boundingRect.top;

            naturalWidth = imageRef.current.naturalWidth;
            naturalHeight = imageRef.current.naturalHeight;

            currentWidth = boundingRect.width;
            currentHeight = boundingRect.height;
          }
        }
      } else {
        clientXOffset = event.clientX - boundingRect.left;
        clientYOffset = event.clientY - boundingRect.top;

        naturalWidth = imageRef.current.naturalWidth;
        naturalHeight = imageRef.current.naturalHeight;

        currentWidth = boundingRect.width;
        currentHeight = boundingRect.height;
      }

      let actualImageHeight = (currentWidth / naturalWidth) * naturalHeight,
        singleSideLoss = (actualImageHeight - currentHeight) / 2,
        verticalPointImagePercentage =
          (clientYOffset + singleSideLoss) / actualImageHeight;

      let naturalX = (clientXOffset * naturalWidth) / currentWidth,
        naturalY = verticalPointImagePercentage * naturalHeight;

      let point = {};
      if (points[side]) {
        point = {
          [side]: {
            ...points[side],
            points: {
              ...points[side].points,
              [selectedOptionAnswerId]: {
                x: naturalY,
                y: naturalX,
                option: selectedOption,
              },
            },
          },
        };
      }
      updatePoints({
        carId,
        formId,
        points: {
          ...points,
          ...point,
        },
      });
    } else {
      toggleNotification({
        variant: "warning",
        open: true,
        message:
          "Please select an option from the list before assigning to the image",
      });
    }
  };

  let availableWidth = window.innerWidth - 64,
    newHeight = window.innerHeight - 64,
    neededWidth = newHeight / 0.75,
    newWidth = neededWidth;
  if (neededWidth > availableWidth) {
    newWidth = availableWidth - 64;
    newHeight = newWidth * 0.75;
  }

  const windowSize = useWindowSize();
  const [responsivePaper, setResponsivePaper] = useState({
    overflow: "hidden",
    height: `${newHeight}px`,
    width: `${newWidth}px`,
  });
  useEffect(() => {
    setResponsivePaper({
      overflow: "hidden",
      height: `${newHeight}px`,
      width: `${newWidth}px`,
    });
  }, [windowSize]);
  return (
    <Dialog
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
      PaperProps={{ style: responsivePaper }}
      fullScreen={fullScreen}
      open={openVisualizationDialog}
      onEnter={() => {
        if (isSmallDevice) {
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen().catch((e) => console.log(e));
          } else if (document.body.mozRequestFullscreen) {
            document.body.mozRequestFullscreen().catch((e) => console.log(e));
          } else if (document.body.webkitRequestFullscreen) {
            document.body
              .webkitRequestFullscreen()
              .catch((e) => console.log(e));
          } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen().catch((e) => console.log(e));
          }
        }
      }}
      ref={stageRef}
    >
      <div
        style={
          isPortrait
            ? {
                transform: "translate(-50%,-50%) rotate(90deg)",
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100vh",
                height: "auto",
                backgroundColor: "black",
              }
            : { backgroundColor: "black" }
        }
      >
        <Paper
          style={
            isSmallDevice
              ? isPortrait
                ? {
                    height: window.screen.width,
                    position: "relative",
                    width: (window.screen.width / 3) * 4,
                  }
                : {
                    width: window.screen.height,
                    position: "relative",
                    height: (window.screen.height / 3) * 4,
                  } // not needed no ios and lock screen
              : { position: "relative" }
          }
          className={classes.visPaperPosition}
        >
          {points?.[side] ? (
            <div>
              <img
                style={
                  isSmallDevice
                    ? {
                        transform: "translate(-50%,-50%)",
                        top: "50%",
                        left: "50%",
                        position: "absolute",
                      }
                    : { objectFit: "cover" }
                } //
                src={points[side].image}
                width={"100%"}
                alt={"test"}
                ref={imageRef}
                onLoad={(event) => {
                  event.persist();
                  const boundingRect = event.target.getBoundingClientRect();
                  setImageData({
                    ...boundingRect,
                    naturalHeight: event.target.naturalHeight,
                    naturalWidth: event.target.naturalWidth,
                    width: boundingRect.width,
                    height: boundingRect.height,
                  });
                }}
                onClick={handleImageClick}
              />
              <Fab
                style={
                  isSmallDevice
                    ? {}
                    : {
                        position: "absolute",
                        transform: "translate(0,-100%)",
                        top: "calc(100% - 32px)",
                        right: "32px",
                      }
                  // {
                  //     position: "absolute",
                  //     transform: "translate(-50%,-50%)",
                  //     top: `calc(45vh + ${
                  //       //50% instead of 45vh
                  //       stageRef?.current?.childNodes[2].childNodes[0] //"stageRef?.current?.childNodes[2].childNodes[0]" is the dialog's paper
                  //         .clientHeight / 2
                  //     }px - 50px)`,
                  //     left: `calc(50% + ${
                  //       stageRef?.current?.childNodes[2].childNodes[0] //"stageRef?.current?.childNodes[2].childNodes[0]" is the dialog's paper
                  //         .clientWidth / 2
                  //     }px - 50px)`,
                  //     // top: `calc(50% + ${
                  //     //   imageData?.height / 2
                  //     // }px - 50px)`,
                  //     // left: `calc(50% + ${
                  //     //   imageData?.width / 2
                  //     // }px - 50px)`,
                  //   }
                }
                children={<SaveIcon />}
                color={"primary"}
                className={classes.speedDial}
                size={"large"}
                onClick={() => {
                  setOpenVisualizationDialog(false);
                  // if (document.fullscreenElement) {
                  //   document
                  //     .exitFullscreen()
                  //     .then(() =>
                  //       console.log("Document Exited form Full screen mode")
                  //     )
                  //     .catch((err) => console.error(err));
                  // }
                }}
              />
              {points[side].points
                ? Object.keys(points[side].points).map((key, i) => {
                    return (
                      <Point
                        point={points[side].points[key]}
                        imageData={imageData}
                        key={`point_${i}_${key}`}
                        isPortrait={isPortrait}
                        isSmallDevice={isSmallDevice}
                        imageRef={imageRef}
                        selectedPoint={
                          key === selectedOptionAnswerId.toString()
                            ? true
                            : false
                        }
                      />
                    );
                  })
                : null}
            </div>
          ) : null}
        </Paper>
      </div>
    </Dialog>
  );
};

const Forms = (props) => {
  const { t, i18n } = useTranslation();
  const {
    subforms,
    form,
    features,
    updateFeatures,
    clearAnswers,
    getFields,
    getOptionsFields,
    formFields,
    getCarForms,
    getCarFeatures,
    getCarVisualization,
    points,
    toggleNotification,
    visualizationPointError,
    getGenericFeatures,

    getCarGenericFeatures,
    carGenericFeatures,
    updateGenericFeatures,
    fetchedData,
    loadingForm,
    fetchingSubforms,
    fetchingForms,
    fetchingFields,
    submitting,
    setSubformloading,
    subformloading,
    answers,
    appMessage,
    saved,
    updateformFields,
    updateHidden,
    updateAnswers,
    updatePoints,
    paparazziSelectionData,
    getPaparazziPackage,
    setPaparazziUnLoaded,
    fuel_type,
  } = props;
  const classes = useStyles();
  const { is_exterior_visual, is_interior_visual } = form;
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rehydratingSaved, setRehydratingSaved] = useState(false);
  const [openVisualizationDialog, setOpenVisualizationDialog] = useState(false);
  const [selectedOptionAnswerId, setSelectedOptionAnswerId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [side, setSide] = useState("");
  const [genericFeatures, setGenericFeatures] = useState([]);
  const [featuresSubforms, setFeaturesSubforms] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [messageAnswerPointLinks, setMessageAnswerPointLinks] = useState({});

  const [invoker_id, setInvokerId] = useState();
  const [computerScanAnswers, setComputerScanAnswers] = useState(null);
  const [savedDtcFetched, setSavedDtcFetched] = useState(false);

  const [fetchingIsDone, setFetchingIsDone] = useState(false);
  const [callbackFormId, setCallbackFormId] = useState(form.id);
  const [brands, setBrands] = useState({});
  const [sizes, setSizes] = useState({});
  const [fetchedDtcFromBE, setFetchedDtcFromBE] = useState(null);

  const [BGselectorDialog, setBGselectorDialog] = useState(false);
  const [refreshExtImagesForm, setRefreshExtImagesForm] = useState(false);

  // const [paparazziAnswers, setPaparazziAnswers] = useState({});

  let subformTitles = [];
  const carId = props.match.params.id;

  useEffect(() => {
    subformloading !== undefined &&
      setSubformloading(
        fetchingSubforms || fetchingForms || submitting || rehydratingSaved
      );
  }, [fetchingSubforms, fetchingForms, submitting, rehydratingSaved]);

  useEffect(() => {
    // alert("appMessage");
    if (appMessage.action == "Camera") {
      let tempUrlsObj = { ...imageUrls };
      let tempAnswerPointLinksObject = { ...messageAnswerPointLinks };
      tempUrlsObj[appMessage?.fieldId] = appMessage?.newImageUrl;
      tempAnswerPointLinksObject[appMessage?.fieldId] =
        appMessage?.answerPointLink;
      setImageUrls(tempUrlsObj);
      setMessageAnswerPointLinks(tempAnswerPointLinksObject);
    }
  }, [appMessage]);

  useEffect(() => {
    setPaparazziUnLoaded([]);
    setLoading(true);
    ////here clearAnswers
    clearAnswers(); // answers are cleared before fetching new forms answers so that no answers from previous form are migrated to new form (this used to happen)
    setFetchingIsDone(false);
    getFields({
      formId: form.id,
      car_id: carId,
      callback: () => {
        getCarForms({
          formId: form.id,
          carId: carId,
          callback: (response) => {
            if (form.id == 15 && response?.[0]) {
              setFetchedDtcFromBE(response[0].form_answers["table"]);
              setComputerScanAnswers(response[0].form_answers["table"]);
            }
            getCarFeatures({
              carId: carId,
              featureForm: form.id,
              callback: (result) => {
                let featureArray = [];
                for (let i = 0; i < result.length; i++) {
                  featureArray.push(result[i].form_object);
                }
                updateFeatures(getUniqueArray(featureArray, "id"));
                // kept to show clouser effect (if you switch forms quickly and you log form.id outside you will notice that outside
                // it will be the new form then in the callback it will be the old form then again in the callback it will be the new form )

                // console.log(
                //   "ooooooooooooooo000",
                //   form.id
                // );

                ///////// callbackFormId is just a state to cause the saved update useEffect to trigger on fast form change /////////
                //////// where the callback would set fetchingIsDone to true prematurely (from the previous form) /////////
                ///////// which will cause the newly saved not to be added to the new form /////////
                setCallbackFormId(form.id);
                setFetchingIsDone(true);
              },
            });
          },
        });
      },
    });
    if (is_exterior_visual || is_interior_visual) {
      if (is_exterior_visual && is_interior_visual) {
        getCarVisualization({
          query: `field__car_id=${carId}`,
          first: true,
          callback: (data) => {},
        });
      } else {
        let position = is_exterior_visual ? "ext" : "int";
        getCarVisualization({
          query: `field__car_id=${carId}&position=${position}`,
          first: true,
          callback: (data) => {},
        });
      }
    }
    setLoading(false);
  }, [
    form.id,
    fetchedData,
    // form, // adding form will mess up the exterior cosmetic form after a submit of the features form
    getFields,
    carId,
    getCarForms,
    getCarFeatures,
    updateFeatures,
    getCarVisualization,
    is_exterior_visual,
    is_interior_visual,
    getGenericFeatures,
    refreshExtImagesForm,
  ]);

  useEffect(() => {
    // rehydrates form data if exist in saved **Does not submit
    const formSavedObject = saved?.[carId]?.[form.id];
    if (formSavedObject) {
      setRehydratingSaved(true);
    } else if (saved?.[carId]?.["15"] && form.id !== 15) {
      // since dtc form saved does not get deleted submitting dtc will cause the next form to keep on loading
      // because "if (formSavedObject)" up enteres from dtc form dep. Arr. changes and sets setRehydratingSaved(true)
      // but is not reset in "if (formSavedObject)" down to setRehydratingSaved(false) if it hase no saved obj
      // bad design, change when you solve dtc removesaved in AddNewCar
      // this does not disrupt normal operation if a form has saved because it is in the else statement
      setRehydratingSaved(false);
    }
    if (fetchingIsDone) {
      if (formSavedObject) {
        const formSavedFormFields = formSavedObject?.formFields;
        const formSavedHidden = formSavedObject?.hidden;
        const formSavedAnswers = formSavedObject?.answers;
        const formSavedPoints = formSavedObject?.points;
        if (formSavedFormFields) {
          setRehydratingSaved(true);
          updateformFields({
            formFields: formSavedFormFields,
            callback: () => {
              setRehydratingSaved(false);
            },
          });
        }
        if (formSavedHidden) {
          setRehydratingSaved(true);
          updateHidden({
            hidden: formSavedHidden,
            callback: () => {
              setRehydratingSaved(false);
            },
          });
        }
        if (formSavedAnswers) {
          setRehydratingSaved(true);
          updateAnswers({
            answers: formSavedAnswers,
            callback: () => {
              setRehydratingSaved(false);
            },
          });
          if (form.id == 15 && formSavedAnswers?.["table"]) {
            setComputerScanAnswers(formSavedAnswers["table"]);
            setSavedDtcFetched(!savedDtcFetched); // stupid but needed to trigger computerScanAnswers useEffect in ComputerScanTable component in a very specific flow (dtc never been answered => upload file => change form => go back to dtc )
          }
        }
        if (formSavedPoints) {
          setRehydratingSaved(true);
          updatePoints({
            points: formSavedPoints,
            callback: () => {
              setRehydratingSaved(false);
            },
          });
        }
      }
    }
  }, [form, fetchingIsDone, carId, callbackFormId]);
  //// callbackFormId is important to regulate the useEffect entrance problem due to callback's clouser and fetchingIsDone state mixup
  //// "but the callback function captures the old state of myState (as a closure)." // the state itself (form.id) didnot cause the problem but it led to the answer
  //// read : https://stackoverflow.com/questions/65537966/state-does-not-update-in-async-function

  useEffect(() => {
    // const { location, horizontal, vertical } = paparazziSelectionData;
    getPaparazziPackage({
      location: paparazziSelectionData?.location,
      sun_location: paparazziSelectionData?.sun_location,
      reflection: paparazziSelectionData?.reflection,
      interior_lights: paparazziSelectionData?.interior_lights,
      carId: carId,
      // callback: (response) => {
      //   console.log("yyyyyyyyyyyyyttt", response);
      // },
    });
  }, [paparazziSelectionData]);

  useEffect(() => {
    if (invoker_id !== undefined) {
      getOptionsFields({
        carId,
        formId: form.id,
        formId: form.id,
        invoker_id: invoker_id,
      });
    }
  }, [invoker_id]);

  if (loading) {
    return <LinearProgress />;
  }

  let formFeatureFields = features?.filter((el) => el?.form === form?.id);

  let formFieldsWithFeatures = formFields.concat(formFeatureFields);

  let formFieldsWithFeaturesSorted = formFieldsWithFeatures.sort((a, b) => {
    if (a.subform_tree_id > b.subform_tree_id) {
      return 1;
    } else if (a.subform_tree_id < b.subform_tree_id) {
      return -1;
    }
    // Else go to the 2nd item
    if (a.tree_id < b.tree_id) {
      return -1;
    } else if (a.tree_id > b.tree_id) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <div
        className={clsx({
          [classes.mainDiv]:
            fetchingSubforms || fetchingForms || submitting || rehydratingSaved,
        })}
        style={{
          margin: "0.4em",
          border: "1px solid rgba(191,214,222,0.4)",
        }}
      >
        {(fetchingSubforms ||
          fetchingForms ||
          submitting ||
          rehydratingSaved) && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <div
              style={{
                height: window.innerHeight,
                position: "sticky",
                top: 0,
                display: "grid",
              }}
            >
              <CircularProgress className={classes.circularProg} />
            </div>
          </div>
        )}
        {/* <div> */}
        <div>
          <div>
            <div>
              {subforms?.map((subform, i) => {
                let filteredFormFieldsWithFeaturesSorted =
                  formFieldsWithFeaturesSorted.filter(
                    // remaining problem is with subform=other
                    (el) => el?.subform_title === subform.title
                  );
                return (
                  <div>
                    {filteredFormFieldsWithFeaturesSorted.length !== 0 && (
                      <div
                        style={{
                          backgroundColor: "rgba(191,214,222,1)",
                          padding: "0.5em",
                          marginBottom: "1.5em",
                        }}
                      >
                        {subform.title}
                      </div>
                    )}

                    {subform.id == 48 && ( // 48 exterior images subform
                      <PapaHub
                        open={BGselectorDialog}
                        setOpen={setBGselectorDialog}
                        setRefreshExtImagesForm={setRefreshExtImagesForm}
                      />
                    )}
                    {filteredFormFieldsWithFeaturesSorted.map((field, i) => {
                      // filtered for subgroup
                      return (
                        <div
                          key={`field_${field.type}_${i}`}
                          style={{ margin: "1em" }}
                        >
                          <FormFields
                            papaEditAction={() => {
                              setBGselectorDialog(true);
                            }}
                            setPaparazziUnLoaded={setPaparazziUnLoaded}
                            brands={brands}
                            setBrands={setBrands}
                            sizes={sizes}
                            setSizes={setSizes}
                            form={form}
                            carId={carId}
                            imageUrls={imageUrls}
                            messageAnswerPointLinks={messageAnswerPointLinks}
                            field={field}
                            setOpenVisualizationDialog={
                              setOpenVisualizationDialog
                            }
                            setSide={setSide}
                            setSelectedOptionAnswerId={
                              setSelectedOptionAnswerId
                            }
                            setSelectedOptionDdma={setSelectedOption}
                            visualizationPointError={visualizationPointError}
                            setInvokerId={setInvokerId}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {/* // \/ //*/}
              {/* </div> */}
              {/* // /\ //*/}
            </div>
            <div>
              {
                <div>
                  {formFieldsWithFeaturesSorted.filter(
                    // remaining problem is with subform=other
                    (el) => el.subform_title === "other"
                  ).length !== 0 && (
                    <div
                      style={{
                        backgroundColor: "rgba(191,214,222,1)",
                        padding: "0.5em",
                        marginBottom: "1.5em",
                      }}
                    >
                      <Typography>Other</Typography>
                    </div>
                  )}
                  {console.log("vvvvvvvvvvv", imageUrls)}
                  {formFieldsWithFeaturesSorted
                    .filter(
                      // remaining problem is with subform=other
                      (el) => el.subform_title === "other"
                    )
                    .map((field, i) => {
                      // filtered for subgroup
                      return (
                        <div
                          key={`field_${field.type}_${i}`}
                          style={{ margin: "1em" }}

                          // onClick={() => {
                          //   setBGselectorDialog(true);
                          // }}
                        >
                          <FormFields
                            setPaparazziUnLoaded={setPaparazziUnLoaded}
                            brands={brands}
                            setBrands={setBrands}
                            sizes={sizes}
                            papaEditAction={() => {
                              setBGselectorDialog(true);
                            }}
                            setSizes={setSizes}
                            form={form}
                            carId={carId}
                            field={field}
                            imageUrls={imageUrls}
                            messageAnswerPointLinks={messageAnswerPointLinks}
                            setOpenVisualizationDialog={
                              setOpenVisualizationDialog
                            }
                            setSide={setSide}
                            setSelectedOptionAnswerId={
                              setSelectedOptionAnswerId
                            }
                            setSelectedOptionDdma={setSelectedOption}
                            visualizationPointError={visualizationPointError}
                            setInvokerId={setInvokerId}
                            // paparazziAnswers={paparazziAnswers} setPaparazziAnswers={setPaparazziAnswers}
                          />
                        </div>
                      );
                    })}
                </div>
              }
            </div>
          </div>
          {(is_exterior_visual && !is_interior_visual) ||
          (is_interior_visual && !is_exterior_visual) ? (
            <Assign
              openVisualizationDialog={openVisualizationDialog}
              points={points}
              side={side}
              carId={carId}
              toggleNotification={toggleNotification}
              setOpenVisualizationDialog={setOpenVisualizationDialog}
              selectedOptionAnswerId={selectedOptionAnswerId}
              updatePoints={props.updatePoints}
              selectedOption={selectedOption}
              formId={form.id}
            />
          ) : null}
        </div>
      </div>
      <div
        style={{
          margin: "0.4em",
        }}
      >
        {form.id == 15 ? (
          <ComputerScanTable
            computerScanAnswers={computerScanAnswers}
            savedDtcFetched={savedDtcFetched}
            formId={15}
            fetchedDtcFromBE={fetchedDtcFromBE}
            setFetchedDtcFromBE={setFetchedDtcFromBE}
          ></ComputerScanTable>
        ) : (
          ""
        )}

        {form.id == 15 && (fuel_type == 925 || fuel_type == 929) ? (
          <>
            <div
              style={{
                display: "flex",
                backgroundColor: "#FDEDED",
                borderRadius: "3px",
                marginBlock: "20px",
              }}
            >
              <StaticText label={t("* Close DZ app on phone")} />
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#FDEDED",
                borderRadius: "3px",
                marginBlock: "20px",
              }}
            >
              <StaticText label={t("* Disconnect Scanner from car")} />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  features: state.forms.features,
  formFields: state.forms.formFields,
  subforms: state.forms.subforms,
  answers: state.forms.answers,
  points: state.forms.points,
  carGenericFeatures: state.forms.carGenericFeatures,
  fetchingSubforms: state.forms.fetchingSubforms,
  fetchingForms: state.forms.fetchingForms,
  fetchingFields: state.forms.fetchingFields,
  appMessage: state.webview.appMessage,
  saved: state.forms.saved,
  paparazziSelectionData: state.car.car.paparazzi_selection_data,
  fuel_type: state.car.car.fuel_type,
});

const mapDispatchToProps = (dispatch) => ({
  updateformFields(payload) {
    dispatch(updateformFields(payload));
  },
  updateHidden(payload) {
    dispatch(updateHidden(payload));
  },
  updateAnswers(payload) {
    dispatch(updateAnswers(payload));
  },
  updatePoints(payload) {
    dispatch(updatePoints(payload));
  },
  updateFeatures(payload) {
    dispatch(updateFeatures(payload));
  },
  clearAnswers(payload) {
    dispatch(clearAnswers(payload));
  },
  getFields(payload) {
    dispatch(getFields(payload));
  },
  getOptionsFields(payload) {
    dispatch(getOptionsFields(payload));
  },
  getCarForms(payload) {
    dispatch(getCarForms(payload));
  },
  getCarFeatures(payload) {
    dispatch(getCarFeatures(payload));
  },
  getCarVisualization(payload) {
    dispatch(getCarVisualization(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  getGenericFeatures(payload) {
    dispatch(getGenericFeatures(payload));
  },
  getCarGenericFeatures(payload) {
    dispatch(getCarGenericFeatures(payload));
  },
  updateGenericFeatures(payload) {
    dispatch(updateGenericFeatures(payload));
  },
  getPaparazziPackage(payload) {
    dispatch(getPaparazziPackage(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forms));
