import React from "react";

const FeatureIcon38 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 63" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon38Clip0)">
<path d="M16.7647 16.1282L17.5774 24.1802L32.4184 24.1802L33.2269 16.1274C33.3209 15.2043 32.8949 14.3052 32.1208 13.7935L31.9108 13.6543C27.7228 10.8648 22.2688 10.8648 18.0808 13.6543L17.8699 13.7943C17.096 14.306 16.6702 15.2052 16.7647 16.1282ZM18.7951 15.1838L19.006 15.0438C22.6362 12.6257 27.3638 12.6257 30.9939 15.0438L31.204 15.183C31.4608 15.354 31.6023 15.6528 31.5715 15.9599L30.9139 22.5131L19.0819 22.5131L18.4242 15.959C18.3949 15.6519 18.5376 15.3537 18.7951 15.1838Z" fill="#07303E"/>
<path d="M18.12 41.3063L19.7362 42.204C23.0057 44.0335 26.9908 44.0335 30.2604 42.204L31.8783 41.3063C32.8012 40.791 33.3014 39.7519 33.1286 38.709L32.3742 34.1713L17.6257 34.1713L16.8697 38.7082C16.6965 39.7513 17.1967 40.7909 18.12 41.3063ZM18.5142 38.9824L19.0377 35.8384L30.9622 35.8384L31.4865 38.9833C31.5446 39.3313 31.3773 39.6781 31.0689 39.8493L29.4527 40.747C26.686 42.2949 23.3139 42.2949 20.5472 40.747L18.931 39.8493C18.6237 39.6767 18.4572 39.3303 18.5142 38.9824Z" fill="#07303E"/>
<path d="M31.8116 11.2997L33.1986 10.375L29.8645 5.37418L28.4775 6.2989L31.8116 11.2997Z" fill="#07303E"/>
<path d="M18.1845 11.2811L21.5201 6.28027L20.1331 5.3551L16.7975 10.3559L18.1845 11.2811Z" fill="#07303E"/>
<path d="M9.52877 21.5251C9.7581 21.6804 10.0495 21.7116 10.3065 21.6085L13.7615 20.2265L14.164 25.0026L12.9704 39.256C12.6466 42.988 14.6464 46.536 18.0066 48.1915L18.6651 48.5191C22.6575 50.4936 27.3424 50.4936 31.3348 48.5191L31.9925 48.1915C35.354 46.5371 37.3552 42.9887 37.032 39.256L35.8359 25.0026L36.236 20.2232L39.691 21.6052C40.1177 21.7778 40.6036 21.5718 40.7762 21.145C40.8161 21.0465 40.8368 20.9412 40.8371 20.835L40.8371 18.6294C40.8403 17.6058 40.2166 16.6845 39.265 16.3072L36.6536 15.2628L37.0028 11.1135C37.3314 7.18837 35.1026 3.49603 31.4765 1.95795L28.6125 0.73516C26.3051 -0.245076 23.6982 -0.245077 21.3908 0.735159L18.5268 1.95795C14.8993 3.49488 12.6691 7.18754 12.9971 11.1135L13.3447 15.2628L10.7332 16.3072C9.78227 16.685 9.15942 17.6062 9.16285 18.6294L9.16285 20.835C9.16285 21.1115 9.29986 21.37 9.52877 21.5251ZM36.6694 17.0649L38.6457 17.8559C38.9631 17.9813 39.1712 18.2882 39.17 18.6294L39.17 19.6038L36.6694 18.6036L36.6694 17.0649ZM14.6308 39.3986L15.8311 25.0026L14.6575 10.9709C14.3919 7.76257 16.2157 4.74612 19.1802 3.49082L22.0443 2.26886C23.9329 1.46617 26.067 1.46617 27.9557 2.26886L30.8197 3.49082C33.7831 4.74883 35.6043 7.76694 35.3358 10.9751L34.1688 25.0026L35.3699 39.3986C35.6328 42.4482 33.9976 45.3467 31.2515 46.6987L30.593 47.0262C27.0685 48.7701 22.9323 48.7701 19.4078 47.0262L18.7501 46.6987C16.0038 45.3469 14.3682 42.4484 14.6308 39.3986ZM10.8299 18.6294C10.8288 18.2882 11.0368 17.9812 11.3542 17.8559L13.3305 17.0649L13.3305 18.6036L10.8299 19.6038L10.8299 18.6294Z" fill="#07303E"/>
</g>
<g clip-path="url(#FeatureIcon38Clip1)">
<path d="M15.2202 57.2531C17.9162 59.8702 21.5668 61.3115 25.5002 61.3115C29.4331 61.3115 33.0838 59.8702 35.7798 57.2531C36.0886 56.9533 36.0704 56.4836 35.739 56.2041C35.4075 55.9247 34.8885 55.9412 34.5796 56.2411C32.1974 58.5536 28.9729 59.8271 25.4999 59.8272C22.0271 59.8271 18.8026 58.5536 16.4204 56.2411C16.2589 56.0842 16.0398 56.0049 15.8202 56.0049C15.6199 56.0049 15.4191 56.0709 15.261 56.2041C14.9296 56.4836 14.9113 56.9533 15.2202 57.2531Z" fill="#07303E"/>
<path d="M18.2323 55.2035C20.2252 57.0202 22.8062 58.0206 25.5001 58.0205C28.1947 58.0205 30.7758 57.02 32.768 55.2034C33.0871 54.9125 33.0851 54.4425 32.7635 54.1538C32.4419 53.8651 31.9225 53.8669 31.6034 54.1579C29.9215 55.6915 27.754 56.5361 25.5001 56.5361C23.2468 56.5362 21.0793 55.6916 19.3969 54.1578C19.2365 54.0117 19.0256 53.9385 18.8146 53.9385C18.6058 53.9385 18.3967 54.0103 18.2367 54.1539C17.9152 54.4427 17.9132 54.9126 18.2323 55.2035Z" fill="#07303E"/>
<path d="M21.5153 53.1454C22.5825 54.1021 24.1123 54.6963 25.5077 54.6963C25.5079 54.6963 25.5082 54.6963 25.5083 54.6963L25.5674 54.6963C25.5677 54.6963 25.5678 54.6963 25.568 54.6963C26.9635 54.6963 28.4934 54.102 29.5606 53.1454C29.8824 52.8569 29.8848 52.387 29.566 52.0958C29.4055 51.9493 29.1944 51.876 28.9832 51.876C28.7746 51.876 28.5658 51.9476 28.4059 52.0909C27.6462 52.7719 26.5323 53.2119 25.568 53.2119C25.5678 53.2119 25.5677 53.2119 25.5676 53.2119L25.5082 53.2119C25.508 53.2119 25.508 53.2119 25.5078 53.2119C24.5436 53.2119 23.4297 52.7719 22.67 52.0909C22.3482 51.8025 21.8287 51.8045 21.5099 52.0958C21.191 52.387 21.1934 52.8569 21.5153 53.1454Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon38Clip0">
<rect width="50" height="50" fill="white" transform="translate(50 50) rotate(-180)"/>
</clipPath>
<clipPath id="FeatureIcon38Clip1">
<rect width="21" height="19" fill="white" transform="translate(36 63) rotate(-180)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon38;
