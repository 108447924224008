import React, { useEffect } from "react";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { getCarIdsTitlesList, reportBuilder } from "../store/actions/car";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  methodDiv: {
    display: "block",
    width: "70vw",
    margin: "16px",
    padding: "8px",
    marginInline: "auto",
  },
  typography: {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "16px",
  },
  paperItem: { marginBlock: "8px", textTransform: "none" },
  selectDiv: {
    width: "100vw",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

function ReportsGenerator(props) {
  const { reportBuilder, getCarIdsTitlesList } = props;

  const classes = useStyles();

  const [carGenerated, setCarGenerated] = useState([]);
  const [carId, setCarId] = useState("");
  const [selectedCarsIds, setSelectedCarsIds] = useState([]);
  const [carsIdsOptions, setCarsIdsOptions] = useState([]);

  const handleChange = (event) => {
    setCarId(event.target.value);
  };

  useEffect(() => {
    getCarIdsTitlesList({
      callback: (results) => {
        setCarsIdsOptions(results);
      },
    });
  }, []);

  const handleSelectedCarsIds = (event) => {
    setSelectedCarsIds(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(id, selectedCarsIds, theme) {
    return {
      fontWeight:
        selectedCarsIds.indexOf(id) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div style={{ padding: "15px", display: "flex", flexWrap: "wrap" }}>
      <Paper className={classes.methodDiv}>
        <Typography className={classes.typography}>
          Generate single car report
        </Typography>
        <TextField
          variant="outlined"
          label="car ID"
          onChange={handleChange}
          className={classes.paperItem}
          fullWidth
        />
        <Button
          disabled={!carId}
          variant="contained"
          color="primary"
          className={classes.paperItem}
          fullWidth
          size="large"
          onClick={() => {
            reportBuilder({
              carId: carId,
              set: "one",
              callback: (data) => {},
            });
          }}
        >
          Generate Car File
        </Button>
      </Paper>
      <Paper className={classes.methodDiv}>
        <Typography className={classes.typography}>
          Generate list of cars reports
        </Typography>
        <FormControl className={classes.paperItem} fullWidth>
          <InputLabel id="select-label">Select Cars</InputLabel>
          <Select
            fullWidth
            multiple
            value={selectedCarsIds}
            onChange={handleSelectedCarsIds}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  let item = carsIdsOptions.find((obj) => {
                    return obj.id === value;
                  });
                  return (
                    <Chip
                      key={value}
                      label={item.id + " | " + item.title}
                      className={classes.chip}
                    />
                  );
                })}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {carsIdsOptions.map((carIdOption) => (
              <MenuItem
                key={carIdOption.id + " | " + carIdOption.title}
                value={carIdOption.id}
                style={getStyles(carIdOption.id, selectedCarsIds, theme)}
              >
                {carIdOption.id + " | " + carIdOption.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          disabled={!selectedCarsIds.length}
          onClick={() => {
            reportBuilder({
              carId: selectedCarsIds,
              set: "list",
              callback: (data) => {
                setCarGenerated(data);
                setSelectedCarsIds([]);
              },
            });
          }}
          className={classes.paperItem}
        >
          Generate list of cars reports
        </Button>
      </Paper>
      <Paper className={classes.methodDiv}>
        <Typography className={classes.typography}>
          Generate Reports For All Cars
        </Typography>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            reportBuilder({
              carId: 0,
              set: "all",
              callback: (data) => {
                setCarGenerated(data);
              },
            });
          }}
          className={classes.paperItem}
        >
          Generate Reports For All Cars
        </Button>
      </Paper>
      {carsIdsOptions && !!carGenerated.length && (
        <Paper className={classes.methodDiv}>
          <Typography className={classes.typography}>
            Generated reports List
          </Typography>
          <div className={classes.paperItem}>
            <div className={classes.chips}>
              {carGenerated?.map((generated) => {
                let item = carsIdsOptions.find((obj) => {
                  return obj.id == generated;
                });
                return (
                  <Chip
                    key={generated}
                    label={item?.id + " | " + item?.title}
                    className={classes.chip}
                  />
                );
              })}
            </div>
          </div>
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  reportBuilder(payload) {
    dispatch(reportBuilder(payload));
  },
  getCarIdsTitlesList(payload) {
    dispatch(getCarIdsTitlesList(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsGenerator)
);
