import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { publishCarForBuyers, publishCarForSeller } from "../store/actions/car";
import { checkVisFormsPoints } from "../store/actions/forms";
import { toggleNotification } from "../store/actions/notification";

import { useTranslation } from "react-i18next";
import GPS from "../icons/gps.svg";
import Tooltip from "@material-ui/core/Tooltip";
import SendReportPublishDialog from "./SendReportPublishDialog";
import {
  distanceWithCommas,
  numberWithCommas,
  timerSinceDisplay,
} from "../helpers/functions";
import sellerTag from "../images/sellerTag.png";
import sellerTagAr from "../images/sellerTagAr.png";
import smallLogo from "../icons/markabteSmallLogo.svg";

const useStyles = makeStyles((theme) => ({
  firstPaper: {
    margin: "7.5px 0",
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    borderRadius: "0",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0",
    },
  },
  imagePaper: {
    minHeight: "200px",
    margin: "-8px",
    position: "relative",
    borderRadius: "0",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  Image: {
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  overlayDiv: {
    backgroundColor: "rgba(0,0,0,0.59)",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    padding: "4px 6px",
    [theme.breakpoints.down("sm")]: {
      bottom: "-6px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "-8px",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "-4px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-8px",
    },
  },
  overlaySecondTypography: {
    marginLeft: "auto",
  },
  listedSinceTypography: {
    fontSize: "8px",
    color: "white",
    fontWeight: 500,
    margin: "0 1px",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "8px",
    },
  },
  locationIcon: {
    marginRight: "4px",
    width: "8px",
    [theme.breakpoints.up("md")]: {
      width: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "8px",
    },
  },
  locationTypography: {
    fontSize: "8px",
    color: "white",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "8px",
    },
  },
  dialogContentText: {
    padding: "0",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  milageTypography: {
    fontSize: "12px",
    color: "#07303E",
    padding: "0 12px 0 5px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  firstButton: {
    padding: "0 4px 0 0",
    [theme.breakpoints.up("md")]: {
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 4px 0 0",
    },
  },
  firstButtonButton: {
    margin: "16px 0 8px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  secondButton: {
    padding: "0 0 0 4px",
    [theme.breakpoints.up("md")]: {
      flexGrow: "0",
      flexBasis: "100%",
      maxWidth: "100%",
      padding: "0",
      alignContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      flexGrow: "0",
      flexBasis: "50%",
      maxWidth: "50%",
      padding: "0 0 0 4px",
    },
  },
  secondButtonButton: {
    margin: "16px 0 8px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "clamp(12px,1vw,14px)",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "16px 0 8px",
    },
  },
  Button: {
    margin: "17px 0 7px",
    padding: "8px 24px",
    borderRadius: "5px",
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      margin: "auto 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "17px 0 1px",
    },
  },
  buttonGridContainer: {
    borderTop: "0.5px solid #c4c4c4",
    width: "calc(100% + 16px)",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      borderTop: "none",
      margin: "-25px -8px 0",
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
      padding: "0 0 0 20px",
      alignContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      borderTop: "0.5px solid #c4c4c4",
      margin: "0",
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      padding: 0,
    },
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainTextDiv: {
    padding: "4px 8px 8px",
    zIndex: 2,
    position: "relative",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: "4px 8px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "4px 8px 8px",
    },
  },
  lastRowTypography: {
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  sellerTag: {
    position: "absolute",
    zIndex: "1",
    top: "0",
    left: "0",
  },
  titleIDRowDiv: {
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  inspectedDiv: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
    WebkitUserSelect: "none",
    backgroundColor: "rgba(24, 105, 133, 0.05)",
    padding: "3px 8px",
    borderRadius: "50em",
  },
  inspectedTypography: {
    color: "#626465",
    fontSize: "11px",
    marginLeft: "4px",
  },
  typoChip: {
    whiteSpace: "nowrap",
    fontSize: "14px",
    marginBottom: "5px",
  },
}));

const SendReportCard = (props) => {
  const {
    publishCarForBuyers,
    publishCarForSeller,
    car_id,
    make,
    model,
    trim,
    year,
    milage,
    fuelType,
    image,
    price,
    score,
    dealershipLocation,
    dealershipArea,
    createdAt,
    enteredByFullName,
    driveType,
    countrySpecs,
    engineSize,
    batterySize,
    checkVisFormsPoints,
    toggleNotification,
    publishedForSeller,
    owner_phone,
    cars,
  } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [carId, setCarId] = React.useState();
  const [currentCars, setCurrentCars] = React.useState(cars);
  const [phone, setPhone] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  var listedSinceObj = timerSinceDisplay(new Date(createdAt));
  var listedSince = listedSinceObj.time;
  var listedSinceUnit = listedSinceObj.unit;
  const data = [fuelType, engineSize, driveType, countrySpecs];

  useEffect(() => {
    setCurrentCars(cars);
    console.log("ggteddd", cars);
  }, [cars]);

  const handleClickOpen = (e) => {
    setCarId(car_id);
    setPhone(
      currentCars?.filter((car) => car.id == car_id)[0]?.dealership_details
        ?.phone_number
    );
    setOwnerName(
      currentCars?.filter((car) => car.id == car_id)[0]?.dealership_details
        ?.owner_name
    );
    e.stopPropagation();
    setOpen(true);
    document.getElementById("root").style.filter = "blur(16px)";
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("root").style.filter = "none";
  };
  const handelPublishCar = () => {
    checkVisFormsPoints({
      carId: car_id,
      callback: () => {
        publishCarForBuyers({
          publish: true,
          car_id: car_id,
          callback: (res) => {
            if (res.result) {
            }
          },
        });
      },
    });
    handleClose();
  };
  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} style={{ minWidth: "290px" }}>
        <Paper className={classes.firstPaper}>
          <Grid container spacing={2} alignContent={"space-between"}>
            <Grid
              item
              xs={12}
              md={12}
              onClick={() => {
                props.history.push(`/car/${car_id}`);
              }}
              className={classes.cardHover}
            >
              {publishedForSeller ? (
                <img
                  className={classes.sellerTag}
                  src={
                    localStorage.getItem("language") == "en"
                      ? sellerTag
                      : sellerTagAr
                  }
                />
              ) : (
                ""
              )}
              <Paper style={{ height: "102%" }} className={classes.imagePaper}>
                <img
                  loading="lazy"
                  src={image}
                  alt={"car_thumbnail"}
                  width={"100%"}
                  height={this?.container?.offsetWidth + "px"}
                  className={classes.Image}
                />
                <div className={classes.overlayDiv}>
                  <img
                    src={GPS}
                    alt="gps_icon"
                    className={classes.locationIcon}
                  />
                  <Typography className={classes.locationTypography}>
                    {dealershipLocation}, {dealershipArea}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "8px",
                      color: "white",
                      fontWeight: 500,
                      display: "flex",
                    }}
                    className={classes.overlaySecondTypography}
                  >
                    <Typography className={classes.listedSinceTypography}>
                      {enteredByFullName}
                    </Typography>{" "}
                    <Typography className={classes.listedSinceTypography}>
                      {` | ${listedSince} ${t(listedSinceUnit)} ${t("ago")}`}
                    </Typography>
                  </Typography>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div
                onClick={() => {
                  props.history.push(`/car/${car_id}`);
                }}
                className={clsx({
                  [classes.mainTextDiv]: true,
                  [classes.cardHover]: true,
                })}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className={classes.titleIDRowDiv}
                >
                  <Typography
                    children={
                      localStorage.getItem("language") === "ar"
                        ? `${numberWithCommas(price)} ${t("JD")}`
                        : `${t("JD")} ${numberWithCommas(price)}`
                    }
                    style={
                      localStorage.getItem("language") === "en"
                        ? {
                            fontWeight: "600",
                            color: "#f15a29",
                            marginBottom: "5px",
                          }
                        : {
                            fontWeight: "700",
                            color: "#f15a29",
                            marginBottom: "5px",
                          }
                    }
                  />
                  <div
                    style={{
                      background: "#F3F3F3",
                      fontSize: "14px",
                      padding: "4px 6px",
                      color: "#636363",
                    }}
                  >
                    ID #{car_id}
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12}>
                    <Tooltip
                      disableFocusListener
                      title={`${year}  ${make}  ${model}  ${trim}`}
                    >
                      <div style={{ display: "flex", overflow: "hidden" }}>
                        <Typography
                          children={year}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}
                        <Typography
                          children={make}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}

                        <Typography
                          children={model}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  color: "#07303E",
                                }
                          }
                        />
                        {"\u00A0"}

                        <Typography
                          children={trim}
                          className={classes.typoChip}
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "700",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  color: "#07303E",
                                }
                          }
                        />
                      </div>
                    </Tooltip>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {data.map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Typography
                            children={
                              item != "N/A"
                                ? item
                                : index == 1
                                ? batterySize
                                : "N/A"
                            }
                            style={{
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#626465",
                              marginBottom: "5px",
                              whiteSpace: "nowrap",
                              direction: index == 1 && "ltr",
                            }}
                          />
                          {index === data.length - 1 ? null : (
                            <Typography
                              children="|"
                              style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#C4C4C4",
                                padding: "0 5px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={
                            localStorage.getItem("language") === "en"
                              ? {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                              : {
                                  fontWeight: "600",
                                  color: "#07303E",
                                }
                          }
                          className={classes.lastRowTypography}
                        >
                          {t("Odometer")}:{" "}
                        </Typography>
                        <Typography
                          children={distanceWithCommas(milage)}
                          className={classes.milageTypography}
                          style={
                            localStorage.getItem("language") === "en"
                              ? { color: "#07303E" }
                              : {
                                  color: "#07303E",
                                  fontWeight: 600,
                                }
                          }
                        />
                      </div>
                      <div className={classes.inspectedDiv}>
                        <img src={smallLogo} width="12px" />
                        <Typography className={classes.inspectedTypography}>
                          {t("Inspected")}
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  <Grid container className={classes.buttonGridContainer}>
                    {" "}
                    <Grid
                      item
                      xs={6}
                      md={12}
                      lg={6}
                      style={{ display: "grid" }}
                      className={classes.firstButton}
                    >
                      <Button
                        children={t("Publish Vehicle")}
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleClickOpen}
                        className={classes.firstButtonButton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <SendReportPublishDialog
        carId={carId}
        open={open}
        handleClose={handleClose}
        handelPublishCar={handelPublishCar}
        phone={phone}
        ownerName={ownerName}
      />
    </Grid>
  );
};

SendReportCard.defaultProps = {
  make: "Make",
  model: "Model",
  year: "Year",
  dealershipLocation: "Location",
  milage: "Milage",
  fuelType: "Fuel Type",
  engineSize: "Engine Size",
  onClick: () => {},
};

SendReportCard.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.string,
  dealershipLocation: PropTypes.string,
  milage: PropTypes.string,
  fuelType: PropTypes.string,
  engineSize: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  car: state.car.car,
  cars: state.car.cars,
});

const mapDispatchToProps = (dispatch) => ({
  publishCarForBuyers(payload) {
    dispatch(publishCarForBuyers(payload));
  },
  publishCarForSeller(payload) {
    dispatch(publishCarForSeller(payload));
  },
  checkVisFormsPoints(payload) {
    dispatch(checkVisFormsPoints(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SendReportCard)
);
