import React from "react";

const FeatureIcon73 = (props) => {
  const { size, fill, style, className, selected } = props;
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#FeatureIcon73Clip0)">
        <path
          d="M7.60672 46.7008L9.84859 44.3035C5.96774 40.6743 3.73239 35.7511 3.55434 30.4407C3.37629 25.1303 5.27692 20.0684 8.90607 16.1876C16.398 8.17585 29.0107 7.75331 37.022 15.2451C44.4099 22.1537 45.3444 33.4173 39.5698 41.4115L36.1778 33.9409L33.1893 35.2978L38.3453 46.6538L49.7013 41.4977L48.3444 38.5092L43.9856 40.4883C48.6722 31.4918 47.0261 20.1066 39.2637 12.8476C32.2154 6.25643 22.1115 4.92301 13.8269 8.68525C11.1413 9.90486 8.64545 11.6607 6.50871 13.9455C-2.21917 23.279 -1.72651 37.9729 7.60672 46.7008Z"
          fill="#07303E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.90607 16.1876C16.398 8.17585 29.0107 7.75331 37.022 15.2451C44.2645 22.0177 45.3051 32.9757 39.9029 40.9358C39.7944 41.0956 39.6834 41.2541 39.5698 41.4115L36.1778 33.9409L33.1893 35.2978L38.3453 46.6538L49.7013 41.4977L48.3444 38.5092L43.9856 40.4883C44.1081 40.2532 44.2262 40.0164 44.34 39.7782C48.582 30.8996 46.8233 19.9169 39.2637 12.8476C32.2154 6.25643 22.1115 4.92301 13.8269 8.68525C11.1413 9.90486 8.64545 11.6607 6.50871 13.9455C-2.10505 23.1569 -1.73782 37.5895 7.24515 46.3554C7.36202 46.4694 7.48034 46.5825 7.60012 46.6946C7.60232 46.6966 7.60452 46.6987 7.60672 46.7008L9.84859 44.3035C5.96773 40.6743 3.73239 35.7511 3.55434 30.4407C3.37629 25.1303 5.27692 20.0684 8.90607 16.1876ZM9.14596 44.3228C5.39079 40.639 3.23152 35.7335 3.05462 30.4575C2.87213 25.0145 4.82114 19.8238 8.54088 15.8461C16.2214 7.63264 29.1505 7.1995 37.3635 14.8799C44.9379 21.9628 45.8951 33.5088 39.9751 41.7042L39.4706 42.4026L35.9293 34.6029L33.8513 35.5464L38.5939 45.9918L49.0393 41.2492L48.0958 39.1712L42.8725 41.5428L43.5422 40.2572C48.128 31.4543 46.516 20.3142 38.9222 13.2128C32.027 6.76487 22.141 5.45875 14.0337 9.14051C11.4066 10.3335 8.96492 12.0511 6.87389 14.287C-1.55123 23.2968 -1.19458 37.4129 7.58675 45.9901L9.14596 44.3228Z"
          fill="white"
        />
        <path
          d="M16.214 29.21C17.366 28.214 18.272 27.398 18.932 26.762C19.604 26.114 20.162 25.442 20.606 24.746C21.05 24.05 21.272 23.354 21.272 22.658C21.272 21.938 21.098 21.374 20.75 20.966C20.414 20.558 19.88 20.354 19.148 20.354C18.44 20.354 17.888 20.582 17.492 21.038C17.108 21.482 16.904 22.082 16.88 22.838H14.9C14.936 21.47 15.344 20.426 16.124 19.706C16.916 18.974 17.918 18.608 19.13 18.608C20.438 18.608 21.458 18.968 22.19 19.688C22.934 20.408 23.306 21.368 23.306 22.568C23.306 23.432 23.084 24.266 22.64 25.07C22.208 25.862 21.686 26.576 21.074 27.212C20.474 27.836 19.706 28.562 18.77 29.39L17.96 30.11H23.666V31.82L14.918 31.82L14.918 30.326L16.214 29.21Z"
          fill="#07303E"
        />
        <path
          d="M29.9867 26.978L33.1907 32H30.8687L28.7267 28.634L26.7107 32L24.5687 32L27.7727 27.122L24.5687 22.082L26.8907 22.082L29.0327 25.448L31.0487 22.082H33.1907L29.9867 26.978Z"
          fill="#07303E"
        />
      </g>
      <defs>
        <clipPath id="FeatureIcon73Clip0">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeatureIcon73;
