import { combineReducers } from "redux";
import auth from "./reducer-definitions/auth";
import forms from "./reducer-definitions/forms";
import notifications from "./reducer-definitions/notification";
import backdrop from "./reducer-definitions/backdrop";
import car from "./reducer-definitions/car";
import listings from "./reducer-definitions/listings";
import language from "./reducer-definitions/language";
import websockets from "./reducer-definitions/websockets";
import webview from "./reducer-definitions/webview";
import timeSlots from "./reducer-definitions/timeSlots";

export default combineReducers({
  auth: auth,
  forms: forms,
  notifications: notifications,
  backdrop: backdrop,
  car: car,
  listings: listings,
  timeSlots:timeSlots,
  language: language,
  websockets: websockets,
  webview: webview,
});
