import React, { useEffect, useRef, useState } from "react";
import theme from "../theme";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { IconButton, Paper, TextField, Typography } from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { borderRadius } from "@material-ui/system";
import Save from "./icons/Save";
import { set } from "js-cookie";

import useWindowSize from "../helpers/useWindowSize";

import Sedan from "./icons/EagleEye/Sedan.js";
import Coupe from "./icons/EagleEye/Coupe.js";
import Convertible from "./icons/EagleEye/Convertible.js";
import PickUpFD from "./icons/EagleEye/PickUpFD.js";
import PickUpTD from "./icons/EagleEye/PickUpTD.js";
import useNonInitialEffect from "../helpers/useNonInitialEffect";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "6px 8px",
  },
  subformTitle: {
    backgroundColor: "#BFD6DE",
    fontSize: "14px",
    fontWeight: "500",
    color: "#07303E",
    padding: "11px 10px",
  },
  imageInputDiv: {
    maxWidth: "405px",
    margin: "0 auto",
    padding: "0 6px",
  },
  image: {
    width: "100%",
  },
  inputDiv: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    padding: "10px 4px",
    borderTop: "1px solid #C4C4C4",
    maxHeight: "42px",
  },
  hideButton: {
    color: "#0D6885",
  },
  textField: {
    color: "#6D747A",
    borderRadius: "5px",
  },
  textFieldInput: {
    padding: "11.5px 14px",
  },
  navigationButton: {
    border: "1px solid #0D6885",
    borderRadius: "2.3px",
    marginLeft: "9px",
    width: "59px",
    color: "#05243C",
    fontSize: "3em",
    padding: "0",
  },
  previousButton: {},
  nextButton: {},
  disabledButton: {
    backgroundColor: "#F0F1F2!important",
    border: "1px solid #707678",
  },
  greenSaveBackground: {
    backgroundColor: "#3BB123",
    "&:hover": {
      backgroundColor: "rgba(59, 177, 35, 0.7)",
    },
  },
}));

const ChooseBodyTypeImage = (props) => {
  const { bodyTypeConfig } = props;
  switch (bodyTypeConfig?.body_type) {
    case "Sedan":
      return <Sedan {...props} />;
    case "Coupe":
      return <Coupe {...props} />;
    case "Convertible":
      return <Convertible {...props} />;
    case "Pickup (4 Doors)":
      return <PickUpFD {...props} />;
    case "Pickup (2 Doors)":
      return <PickUpTD {...props} />;
    default:
      return <div {...props} />;
  }
};

const EagleEyeField = (props) => {
  //   const { t } = useTranslation();
  const classes = useStyles();
  const {
    subformTitle,
    subformId,
    // subformAnswers,
    points,
    answers,
    answersArrived,
    handleSubformSubmit,
    bodyTypeConfig,
    backendSubFormAnswers,
    // eeSubmitAll,
    updateAnswers,
    carId,
    setFetchedSubformsCount,
    rehydrateSubformState,
    subforms,
  } = props;

  const inputRef = useRef();
  const subformRef = useRef();
  const [currentPoint, setCurrentPoint] = useState(0);
  const [subformAnswers, setSubformAnswers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [hidden, setHidden] = useState(false);
  const [imageWidth, setImageWidth] = useState(0);
  const imageDivRef = useRef();
  const windowSize = useWindowSize();
  const fetchingRef = useRef(null);

  useEffect(() => {
    if (answersArrived) {
      setSubformAnswers(answers[subformId] ?? []);
      setFetchedSubformsCount((prev) => prev + 1); // to trigger saved answers use after all subforms put their BE saved answers
      setInputValue(
        // Object.values(answers[subformId]?.[currentPoint] ?? { 1: undefined })[0]
        Object.values(answers[subformId]?.[currentPoint] ?? { 1: "" })[0]
      );
    }
  }, [answersArrived]);

  useEffect(() => {
    // used to change the subformAnswers state when answers change in redux due to saved
    setSubformAnswers(rehydrateSubformState[subformId] ?? []);
  }, [rehydrateSubformState]);

  useNonInitialEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentPoint]);

  const ScrollSubform = () => {
    if (subformRef.current) {
      window.scrollTo({
        left: 0,
        top: subformRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const changeSubformAnswers = (condition, value) => {
    value = value === undefined ? condition : value;
    let tempSubformAnswers = [...subformAnswers];
    tempSubformAnswers[currentPoint] = {
      [points[currentPoint]?.id]: condition !== "" ? value : null,
    };
    setSubformAnswers(tempSubformAnswers);
    return tempSubformAnswers;
  };

  const handleNext = () => {
    if (currentPoint !== points.length - 1) {
      setInputValue(
        Object.values(subformAnswers?.[currentPoint + 1] ?? { 1: "" })[0]
      );
      setCurrentPoint((prev) => prev + 1);
      setHidden(
        Object.values(subformAnswers?.[currentPoint + 1] ?? { 1: "" })[0] !==
          null
          ? false
          : true
      );
    } else {
      let tempSubformAnswers = changeSubformAnswers(inputValue);
      handleSubformSubmit(tempSubformAnswers);
    }
  };
  const handlePrevious = () => {
    if (currentPoint !== 0) {
      setInputValue(Object.values(subformAnswers?.[currentPoint - 1])[0]);
      setCurrentPoint((prev) => prev - 1);
      setHidden(
        Object.values(subformAnswers?.[currentPoint - 1])[0] !== null
          ? false
          : true
      );
    }
  };
  const handleInputChange = (event) => {
    let numVal = event.target.value.replace(/[^0-9]/g, "");
    setInputValue(numVal);
    changeSubformAnswers(numVal);
  };

  useNonInitialEffect(() => {
    let newAnswers = Object.assign(
      {},
      answers,
      subformAnswers.length !== 0
        ? {
            [subformId]: subformAnswers,
          }
        : {}
    );

    if (!fetchingRef.current) {
      // first entry of this useEffect is when fetched answers update so dont change saved

      let subformsIds = [];
      subforms.map((subform) => {
        subformsIds.push(subform.id);
      });
      let correctAnswers = Object.keys(newAnswers).filter((answerId) =>
        subformsIds.includes(answerId)
      );
      // updateAnswers({ answers: newAnswers }); // this is a datafetching updateAnswers used to not make another reducer so use it without carIf or formId to prevent changing saved object
      updateAnswers({ answers: correctAnswers }); // this is a datafetching updateAnswers used to not make another reducer so use it without carIf or formId to prevent changing saved object
      // we have a problem in a useEffect in Forms that triggers after submit with the old form id
      // i couldnot fix it properly scince it has a comment that it was done to fix a rb and cb fields problem
      // fetcheing previous form data is automatically fixed for regular forms but fails in EEForm
      // had to filter out answers in redux that are fetched from previous form
      // not the correct way to handle this
    } else {
      updateAnswers({ carId, formId: 2, answers: newAnswers }); // regular change (change saved)
    }
    fetchingRef.current = true; // ref could be a problem if the useEffect triggers in a different way than it does when this code was written ( if it enters twice (once before updating fetched answers))
  }, [subformAnswers]);

  useEffect(() => {
    if (imageDivRef.current) {
      let boundingRect = imageDivRef.current.getBoundingClientRect();

      setImageWidth(boundingRect.width - 12);
    }
  }, [windowSize]);

  // useNonInitialEffect(() => {
  //   // let tempSubformAnswers = changeSubformAnswers(inputValue);
  //   console.log(
  //     "iiiiiiiiieeee",
  //     // tempSubformAnswers,
  //     subformAnswers,
  //     inputValue,
  //     subformId
  //   );
  //   handleSubformSubmit(subformId, subformAnswers);
  // }, [eeSubmitAll]);

  return (
    <Paper className={classes.paper} ref={subformRef}>
      <Typography className={classes.subformTitle}>{subformTitle}</Typography>
      <div ref={imageDivRef} className={classes.imageInputDiv}>
        {/* <img src={"image"} className={classes.image} /> */}
        <ChooseBodyTypeImage
          width={imageWidth}
          view={subformTitle}
          viewId={subformId}
          currentPoint={currentPoint}
          subformAnswers={subformAnswers}
          points={points}
          bodyTypeConfig={bodyTypeConfig}
        />
        <div className={classes.inputDiv}>
          <IconButton
            className={classes.hideButton}
            onClick={() => {
              setHidden(!hidden);
              changeSubformAnswers(hidden, null);
            }}
          >
            {!hidden ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          <TextField
            inputRef={inputRef}
            variant="outlined"
            disabled={hidden}
            value={inputValue}
            className={classes.textField}
            inputProps={{
              className: classes.textFieldInput,
              onClick: (e) => {
                ScrollSubform();
              },
            }}
            onChange={handleInputChange}
            type="tel"
          />
          <IconButton
            size="medium"
            color="primary"
            disabled={currentPoint === 0}
            className={clsx({
              [classes.previousButton]: 1,
              [classes.navigationButton]: 1,
              [classes.disabledButton]: currentPoint === 0,
            })}
          >
            {theme.direction === "ltr" ? (
              <KeyboardArrowLeft
                fontSize="inherit"
                color="inherit"
                onClick={handlePrevious}
              />
            ) : (
              <KeyboardArrowRight
                fontSize="inherit"
                color="inherit"
                onClick={handlePrevious}
              />
            )}
          </IconButton>
          <IconButton
            size="medium"
            // color="primary"
            color="inherit"
            disabled={inputValue === "" && !hidden}
            className={clsx({
              [classes.nextButton]: 1,
              [classes.navigationButton]: 1,
              [classes.disabledButton]: inputValue === "" && !hidden,
              [classes.greenSaveBackground]:
                currentPoint === points.length - 1 &&
                JSON.stringify(subformAnswers) ===
                  JSON.stringify(backendSubFormAnswers),
            })}
            onClick={handleNext}
          >
            {currentPoint !== points.length - 1 ? (
              theme.direction === "ltr" ? (
                <KeyboardArrowRight fontSize="inherit" color="inherit" />
              ) : (
                <KeyboardArrowLeft fontSize="inherit" color="inherit" />
              )
            ) : (
              <Save
                fill={
                  JSON.stringify(subformAnswers) ===
                  JSON.stringify(backendSubFormAnswers)
                    ? "white"
                    : "#05243C"
                }
                size="28"
              />
            )}
          </IconButton>
        </div>
      </div>
    </Paper>
  );
};

EagleEyeField.defaultProps = {
  subformTitle: "subform title",
  subformId: 0, // check
  // subformAnswers: [{ 1: null }, { 2: null }],
  // bodyTypeConfig:,
  points: [],
  handleSubformSubmit: () => {},
};

EagleEyeField.propTypes = {
  subformTitle: PropTypes.string,
  subformId: PropTypes.number,
  // bodyTypeConfig:,
  // subformAnswers: PropTypes.array,
  points: PropTypes.array,
  handleSubformSubmit: PropTypes.func,
};

export default EagleEyeField;
