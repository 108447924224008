import React, { useEffect } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCarsList } from "../store/actions/car";
import SendReportCard from "../components/SendReportCard";
import vlpEmptyState from "../images/vlpEmptyState.png";
import { useTranslation } from "react-i18next";
import { clearRemovedCars, clearNewCars } from "../store/actions/websockets";

const useStyles = makeStyles((theme) => ({
  methodDiv: {
    display: "block",
    width: "70vw",
    margin: "16px",
    padding: "8px",
    marginInline: "auto",
  },
  container: {
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "607px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "750px",
      padding: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70.414vw",
      padding: "25px",
    },
  },
  cardsGrid: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  gridContainerSpacing: {
    paddingTop: "21px",
    margin: "-16px",
    width: "calc(100% + 32px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0px",
      margin: "0 -16px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "-16px",
    },
  },
  carCardGridItem: {
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  vlpEmptyStateImage: {
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: "188px",
    },
  },
  emptyStateDiv: {
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
}));

function CarHistory(props) {
  const { getCarsList, cars, history, clearRemovedCars, clearNewCars, state } =
    props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [updatedCars, setUpdatedCars] = useState(cars);

  useEffect(() => {
    getCarsList({
      filter:
        "?is_active=True&published_for_seller=True&finished_at__isnull=false&is_sold=False",
    });
  }, [getCarsList]);

  useEffect(() => {
    setUpdatedCars(cars);
  }, [state.car]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item md={12} className={classes.cardsGrid}>
          <Grid container className={classes.gridContainerSpacing}>
            {updatedCars.length > 0 ? (
              updatedCars.map((car, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    lg={6}
                    xl={4}
                    className={classes.carCardGridItem}
                  >
                    <SendReportCard
                      model={car.card.model}
                      make={car.card.make}
                      trim={car.card.trim}
                      year={car.card.year}
                      image={car.card.feature_image}
                      milage={car.card.milage}
                      transmission={car.card.transmission}
                      fuelType={car.card.fuel_type}
                      driveType={car.card.drive_type}
                      engineSize={car.card.engine_size}
                      batterySize={car.card.battery_size}
                      countrySpecs={car.card.country_specs}
                      score={
                        typeof car.carScore === "string"
                          ? "N/A"
                          : Math.floor(car.carScore)
                      }
                      rating={car.card.rating}
                      dealershipLocation={car.card.city}
                      dealershipArea={car.card.area}
                      createdAt={car.created_at}
                      enteredByFullName={car.entered_by_fullname}
                      price={car.card.price}
                      uom={car.card.unit_of_measurement}
                      onClick2={() => {
                        history.push("/car/" + car.id);
                      }}
                      car_id={car.id}
                      publishedForSeller={car.published_for_seller}
                    />
                  </Grid>
                );
              })
            ) : (
              <div className={classes.emptyStateDiv}>
                <img
                  className={classes.vlpEmptyStateImage}
                  src={vlpEmptyState}
                />
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#07303E",
                    marginTop: "20px",
                  }}
                >
                  {t("You Don’t Have Car Listings to Publish.")}
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#626465",
                    marginTop: "8px",
                  }}
                >
                  {t("Car listings will appear here.")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "20px",
                    textTransform: "none",
                    width: "210px",
                    height: "30px",
                  }}
                  onClick={() => history.push("/listing-requests/")}
                >
                  {t("Go to Listing Requests")}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  time_slots: state.timeSlots.time_slots,
  cars: state.car.cars,
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  getCarsList(payload) {
    dispatch(getCarsList(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarHistory)
);
