import { createReducer } from "../createReducerFunc";

import {
  GET_TIME_SLOTS_REQUEST,
  GET_TIME_SLOTS_REQUEST_SUCCEED,
  GET_TIME_SLOTS_REQUEST_FAILURE,
  CREATE_TIME_SLOT_REQUEST,
  CREATE_TIME_SLOT_REQUEST_SUCCEED,
  CREATE_TIME_SLOT_REQUEST_FAILURE,
  UPDATE_TIME_SLOT_REQUEST,
  UPDATE_TIME_SLOT_REQUEST_SUCCEED,
  UPDATE_TIME_SLOT_REQUEST_FAILURE,
} from "../constants";

const initialState = {
  time_slots: [],
  fetchingTimeSlots: null,
  creatingTimeSlot: null,
};

export default createReducer(initialState, {
  [GET_TIME_SLOTS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingTimeSlots: true,
    });
  },
  [GET_TIME_SLOTS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      time_slots: payload.results,
      fetchingTimeSlots: false,
    });
  },
  [GET_TIME_SLOTS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingTimeSlots: false,
    });
  },

  [CREATE_TIME_SLOT_REQUEST]: (state) => {
    return Object.assign({}, state, {
      creatingTimeSlot: true,
    });
  },
  [CREATE_TIME_SLOT_REQUEST_SUCCEED]: (state, payload) => {
    const splitted_start_time = payload.start_time.split(":");
    const splitted_end_time = payload.end_time.split(":");

    payload.start_time =
      splitted_start_time[0] + ":" + splitted_start_time[1] + ":00";
    payload.end_time =
      splitted_end_time[0] + ":" + splitted_end_time[1] + ":00";

    return Object.assign({}, state, {
      time_slots: [...state.time_slots, payload],
      creatingTimeSlot: false,
    });
  },
  [CREATE_TIME_SLOT_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      creatingTimeSlot: false,
    });
  },

  [UPDATE_TIME_SLOT_REQUEST_SUCCEED]: (state, payload) => {
    let ay = [...state.time_slots];
    let oldObjIndex = ay.findIndex((item) => item.id == payload.previous);
    let oldObj = ay[oldObjIndex];

    const splitted_start_time = payload.start_time.split(":");
    const splitted_end_time = payload.end_time.split(":");

    payload.start_time =
      splitted_start_time[0] + ":" + splitted_start_time[1] + ":00";
    payload.end_time =
      splitted_end_time[0] + ":" + splitted_end_time[1] + ":00";

    ay.splice(oldObjIndex, 1, payload);
    return Object.assign({}, state, {
      time_slots: ay,
      creatingTimeSlot: false,
    });
  },
});
