import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ConfirmReset from "./ConfirmReset";
import AccountActivation from "./AccountActivation";
import Dashboard from "./Dashboard";
import NotificationWrapper from "../wrappers/Notifications";
import ErrorBoundary from "../components/ErrorBoundary";
import listings from "../store/reducer-definitions/listings";
// import LoadingBackdrop from '../wrappers/BackdropWrapper';
import { connect } from "react-redux";

function App({ user, notifications }) {
  useEffect(() => {}, [notifications]);
  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
  }, []);
  return (
    <Router>
      <NotificationWrapper />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/account_activation/">
            <AccountActivation />
          </Route>
          <Route exact path="/forgot_password/">
            <ForgotPassword />
          </Route>
          <Route exact path="/request_reset/:code/:phone">
            <ConfirmReset />
          </Route>
          {/* route to dealers app  */}

          <Route
            exact
            path="/redirect-dealers"
            component={() => {
              window.location.href = "https://dealers.markabte.com/";
              return null;
            }}
          />
          {/* route to other apps  */}
          {/* <Route
          exact
          path="/redirect-user"
          component={() => {
            window.location.href = "https://google.com/";
            return null;
          }}
        /> */}
          <Route path={"/"}>
            <Dashboard />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

const mapStateToProps = ({ auth, notifications }) => ({
  user: auth.user,
  notifications,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
