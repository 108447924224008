import React from "react";
import Handle404 from "./Handle404";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log(error, info, "errrrrrrr");
  }
  render() {
    var hr = window.location.href;
    if (this.state.hasError && !hr.includes("car-")) {
      //   return <Handle404 />;
      window.location.replace("/something-went-wrong");
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
