import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./style";
import clsx from "clsx";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { makeStyles } from "@material-ui/core";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
    },
  },
  inputSpacing: {
    height: "46px",
    borderRadius: "8.67px",
    boxShadow: "0px 2.20309px 2.67182px rgba(130, 148, 154, 0.4)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      margin: "0",
    },
  },
  inputBaseRoot: {
    borderRadius: "10.67px",
    // height: "37px", /////
    height: "46px", /////
    [theme.breakpoints.down("sm")]: {
      // height: window.screen.height * 0.0707,
      // maxHeight: "40px",
      backgroundColor: "white",
      height: "37px",
    },
  },
  content: {
    display: "contents",
  },
  inputLabel: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(14px,11px) scale(1)",
    },
    transform: "translate(14px,15px) scale(1)",
  },
  correctBorder: {
    borderColor: "#37C534!important",
  },
  correctColor: {
    color: "#37C534!important",
  },
  helperStyle: {
    "& .MuiFormHelperText-root": {
      margin: "0 0 10px",
    },
  },
  fieldGrids: {
    margin: "0 0 28px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 20px 5px",
      flexBasis: "calc(50% - 5px)",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 20px 0",
      flexBasis: "100%",
    },
  },
}));

function Password(props) {
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const resetPass = useRef();
  const resetPassConfirm = useRef();

  useNonInitialEffect(() => {
    if (
      document?.activeElement?.parentElement?.parentElement?.firstChild ===
        resetPass.current &&
      document?.activeElement?.parentElement?.parentElement?.firstChild !==
        undefined
    ) {
      let end = resetPass?.current;
      let len = end.value.length;
      if (end.setSelectionRange) {
        end.focus();
        end.setSelectionRange(len, len);
      } else if (end.createTextRange) {
        var t = end.createTextRange();
        t.collapse(true);
        t.moveEnd("character", len);
        t.moveStart("character", len);
        t.select();
      }
    } else if (
      document?.activeElement?.parentElement?.parentElement?.firstChild ===
        resetPassConfirm.current &&
      document?.activeElement?.parentElement?.parentElement?.firstChild !==
        undefined
    ) {
      let end = resetPassConfirm?.current;
      let len = end.value.length;
      if (end.setSelectionRange) {
        end.focus();
        end.setSelectionRange(len, len);
      } else if (end.createTextRange) {
        var t = end.createTextRange();
        t.collapse(true);
        t.moveEnd("character", len);
        t.moveStart("character", len);
        t.select();
      }
    }
  }, [resetPass?.current?.type, resetPassConfirm?.current?.type, []]);

  const classes = useStyles();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        fullWidth
        className={clsx({ [classes.fieldGrids]: !props.passerror.error })}
      >
        <FormControl ///put this for fields
          className={classes.inputSpacing}
          fullWidth
          style={{ display: "contents" }}
          classes={{ root: classes.formControlRoot }}
        >
          <TextField
            inputRef={resetPass}
            {...props.passerror}
            fullWidth
            required
            className={classes.helperStyle}
            InputProps={{
              classes: {
                root: classes.inputBaseRoot,
                notchedOutline: clsx({
                  [classes.correctBorder]:
                    props?.passerror?.error === undefined &&
                    props.password !== "",
                }),
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={props.password}
            name="password"
            onChange={props.handleInputChange}
            type={showPassword ? "text" : "password"}
            label={t("Password")}
            variant={"outlined"}
            InputLabelProps={{
              className: classes.inputLabel,
              classes: {
                outlined: clsx({
                  [classes.inputLableOutlined]:
                    theme.direction === "ltr" ||
                    localStorage.getItem("language") === "en",
                  [classes.inputLableOutlinedArabic]:
                    theme.direction === "rtl" ||
                    localStorage.getItem("language") === "ar",
                  [classes.correctColor]:
                    props?.passerror?.error === undefined &&
                    props.password !== "",
                }),
              },
            }}
          />
        </FormControl>
      </Grid>
      {/* Confirm */}

      <Grid item xs={12} fullWidth>
        <FormControl
          fullWidth
          className={classes.content}
          style={{ display: "contents" }}
        >
          <TextField
            className={clsx({
              [classes.inputSpacing]: 1,
              [classes.helperStyle]: 1,
            })}
            style={{ marginBottom: "30px" }}
            inputRef={resetPassConfirm}
            {...props.confirmerror}
            required
            InputProps={{
              classes: {
                root: classes.inputBaseRoot,
                notchedOutline: clsx({
                  [classes.correctBorder]:
                    props?.confirmerror?.error === undefined &&
                    props.confirm !== "",
                }),
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirm}
                    edge="end"
                  >
                    {showConfirm ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={props.confirm}
            name="confirm"
            type={showConfirm ? "text" : "password"}
            onChange={props.handleInputChange}
            fullWidth
            label={t("PassConfirm")}
            variant={"outlined"}
            InputLabelProps={{
              className: classes.inputLabel,

              classes: {
                outlined: clsx({
                  [classes.inputLableOutlined]:
                    theme.direction === "ltr" ||
                    localStorage.getItem("language") === "en",
                  [classes.inputLableOutlinedArabic]:
                    theme.direction === "rtl" ||
                    localStorage.getItem("language") === "ar",
                  [classes.correctColor]:
                    props?.confirmerror?.error === undefined &&
                    props.confirm !== "",
                }),
              },
            }}
          />
        </FormControl>
      </Grid>
    </>
  );
}

export default Password;
