import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

import Button from "../components/Button";

import uploadImage from "../helpers/aws";
import imageTimeStamp from "../helpers/imageTimeStamp";

const useStyles = makeStyles(() => ({
  img: {
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
}));

const ImagesStepper = (props) => {
  const classes = useStyles();
  const { images, onDeleteImage } = props;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Paper>
        <img
          className={classes.img}
          src={images[activeStep]}
          alt={images[activeStep]}
        />
        <Button
          fullWidth
          color={"secondary"}
          variant={"text"}
          onClick={(event) => {
            onDeleteImage(event, activeStep);
          }}
        >
          <DeleteIcon />
          Delete Image
        </Button>
      </Paper>
      <br />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            Back
          </Button>
        }
      />
    </div>
  );
};

const ImageUploader = (props) => {
  const { fieldId, images, carId, selectedOption, onDeleteImage } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  return (
    <div>
      {images.length === 0 ? (
        <Typography
          variant={"h6"}
          align={"center"}
          children={"No images were added"}
        />
      ) : (
        <div>
          <Hidden mdUp>
            <ImagesStepper images={images} onDeleteImage={onDeleteImage} />
          </Hidden>
          <Hidden smDown>
            <Grid container spacing={4}>
              {images.map((image, i) => {
                return (
                  <Grid item md={6} key={image + i}>
                    <Paper>
                      <img className={classes.img} src={image} alt={image} />
                      <Button
                        fullWidth
                        color={"secondary"}
                        variant={"text"}
                        onClick={(event) => {
                          onDeleteImage(event, i);
                        }}
                      >
                        <DeleteIcon />
                        Delete Image
                      </Button>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Hidden>
        </div>
      )}
      <br />
    </div>
  );
};

ImageUploader.defaultProps = {
  images: [],
  onDeleteImage: () => {},
};

ImageUploader.propTypes = {
  images: PropTypes.array,
  onDeleteImage: PropTypes.func,
};

export default connect()(ImageUploader);
