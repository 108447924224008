import React, { useState } from "react";
import HelpIcon from "@material-ui/icons/Help";
import { IconButton } from "@material-ui/core";

const InfoButton = (props) => {
  const { option, setShowInfo, SetInfoOption, optionClass } = props;
  if (option.option_info) {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <IconButton
          onClick={() => {
            setShowInfo(true);
            SetInfoOption(option);
          }}
          classes={{ root: optionClass }}
        >
          <HelpIcon color="primary" />
        </IconButton>
      </div>
    );
  } else {
    return null;
  }
};

export default InfoButton;
