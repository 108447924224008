import React from "react";

const FeatureIcon57 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M49 0.833333C49 0.61232 48.9122 0.400358 48.7559 0.244078C48.5996 0.0877973 48.3877 0 48.1667 0H44.8333C44.6962 2.34436e-06 44.5611 0.0338695 44.4401 0.098596C44.3192 0.163322 44.2161 0.256906 44.14 0.371042L43.0462 2.01208C41.4922 4.33799 40.664 7.07303 40.6667 9.87031V10.5555L37.7424 14.4548C36.4164 16.2197 35.4379 18.2204 34.859 20.3506L30.8635 35H16.5C15.8372 35.0008 15.2018 35.2644 14.7331 35.7331C14.2644 36.2018 14.0008 36.8372 14 37.5V44.1667C14.0008 44.8295 14.2644 45.4649 14.7331 45.9336C15.2018 46.4023 15.8372 46.6659 16.5 46.6667H17.6517L19.088 49.5394C19.1572 49.6778 19.2636 49.7942 19.3953 49.8755C19.5269 49.9569 19.6786 50 19.8333 50H38.1667C38.3877 50 38.5996 49.9122 38.7559 49.7559C38.9122 49.5996 39 49.3877 39 49.1667V46.6667H41.5C42.1628 46.6659 42.7982 46.4023 43.2669 45.9336C43.7356 45.4649 43.9992 44.8295 44 44.1667V35.9159L48.9839 10.9968C48.9943 10.9429 48.9995 10.8882 48.9992 10.8333H49V0.833333ZM44.4327 2.93656L45.2793 1.66667H47.3333V10H42.3333V9.87031C42.331 7.40216 43.0617 4.98892 44.4327 2.93656ZM16.5 36.6667H30.1517L29.3183 38.3333H15.6667V37.5C15.6669 37.2791 15.7548 37.0672 15.911 36.911C16.0672 36.7548 16.2791 36.6669 16.5 36.6667ZM37.3333 48.3333H20.3483L19.515 46.6667H37.3333V48.3333ZM42.3333 44.1667C42.3331 44.3876 42.2452 44.5994 42.089 44.7556C41.9328 44.9119 41.7209 44.9998 41.5 45H16.5C16.2791 44.9998 16.0672 44.9119 15.911 44.7556C15.7548 44.5994 15.6669 44.3876 15.6667 44.1667V40H29.8333C29.9881 40 30.1398 39.9569 30.2714 39.8755C30.4031 39.7942 30.5094 39.6778 30.5786 39.5394L32.015 36.6667H42.3333V44.1667ZM42.4834 35H32.591L36.4669 20.7886C36.9908 18.8615 37.8761 17.0514 39.0757 15.4547L41.9167 11.6667H47.1501L42.4834 35Z" fill="#07303E"/>
<g clip-path="url(#FeatureIcon57Clip0)">
<path d="M30.3983 7.14059C29.6132 6.63772 29.4547 5.93287 30.0428 5.58852C30.4463 5.30547 31.0494 5.53471 32.443 6.40219C34.6148 7.80463 34.6722 8.01819 33.2811 10.0367C32.1224 11.7693 31.4413 12.228 30.8341 11.6959C30.6973 11.5326 30.7015 11.0302 30.8766 10.5307C31.2802 9.4424 31.0901 9.36832 29.1971 10.0384C25.7408 11.2812 22.8971 13.9966 21.4278 17.5083C20.6959 19.3319 20.1484 22.7046 20.608 22.8026C20.7393 22.8306 20.9862 22.565 21.5394 22.2965C21.5394 22.2965 22.4941 21.9308 22.9284 22.308C23.3627 22.6852 22.4906 23.5568 22.4906 23.5568C22.2581 23.8427 20.3396 25.8321 19.8116 26.0551C19.3302 26.2209 17.4361 24.6428 16.6703 23.4059C15.9305 22.208 16.639 21.6209 17.9082 22.4283L18.752 22.9773L18.8602 21.0208C19.1166 15.4722 23.5417 10.0071 29.2315 8.2675L31.2161 7.65047L30.3983 7.14059Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon57Clip0">
<rect width="22" height="22" fill="white" transform="translate(14 27) rotate(-90)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon57;
