import React from "react";

const FeatureIcon24 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M22.8992 38.7917C22.1396 35.6202 21.2218 33.8043 20.0933 33.24C17.1913 31.789 14.0021 32.4931 10.988 33.4699C10.7087 33.5604 10.5451 33.8496 10.6113 34.1356C12.0192 40.2176 17.1234 44.8445 23.3125 45.6493C23.6708 45.6958 23.9877 45.3843 23.9476 45.0255C23.8022 43.7251 23.4727 41.186 22.8992 38.7917ZM11.8407 34.3863C14.4728 33.571 17.2021 33.0593 19.5874 34.2519C20.8313 34.8739 22.0497 38.8289 22.7368 44.4139C17.5118 43.4497 13.2376 39.5249 11.8407 34.3863Z" fill="#07303E"/>
<path d="M39.5536 33.4699C36.5394 32.493 33.3501 31.7891 30.4483 33.24C29.3199 33.8042 28.402 35.6202 27.6424 38.7917C27.0688 41.186 26.7393 43.7251 26.594 45.0255C26.5538 45.3842 26.8707 45.6959 27.2291 45.6493C33.4182 44.8445 38.5224 40.2175 39.9303 34.1356C39.9965 33.8496 39.8329 33.5604 39.5536 33.4699ZM27.8048 44.4139C28.4919 38.8288 29.7103 34.8739 30.9542 34.2519C31.9648 33.7466 33.0368 33.5473 34.1373 33.5473C35.6344 33.5473 37.1839 33.9163 38.7009 34.3863C37.304 39.525 33.0297 43.4497 27.8048 44.4139Z" fill="#07303E"/>
<path d="M10.9018 29.4845H38.1785C38.4909 29.4845 38.7442 29.2312 38.7442 28.9188C38.7442 28.6064 38.4909 28.3531 38.1785 28.3531H11.5573C12.6949 21.7304 18.4803 16.8132 25.2708 16.8132C32.0613 16.8132 37.8466 21.7304 38.9842 28.3531H34.925C34.6126 28.3531 34.3594 28.6064 34.3594 28.9188C34.3594 29.2312 34.6126 29.4845 34.925 29.4845H39.6397C39.9747 29.4845 40.2425 29.1814 40.201 28.8488C39.265 21.3425 32.8464 15.6819 25.2708 15.6819C17.6952 15.6819 11.2765 21.3425 10.3404 28.8488C10.2989 29.1814 10.5668 29.4845 10.9018 29.4845Z" fill="#07303E"/>
<path d="M38.8973 17.1025C35.2575 13.4628 30.4182 11.4583 25.2708 11.4583C20.1233 11.4583 15.284 13.4628 11.6442 17.1025C8.00448 20.7423 5.99994 25.5816 5.99994 30.7291C5.99994 35.8765 8.00448 40.7158 11.6442 44.3556C15.284 47.9954 20.1233 49.9999 25.2708 49.9999C30.4182 49.9999 35.2575 47.9954 38.8973 44.3556C42.5371 40.7158 44.5416 35.8765 44.5416 30.7291C44.5416 25.5816 42.5371 20.7423 38.8973 17.1025ZM25.2708 48.8686C15.2686 48.8686 7.1313 40.7312 7.1313 30.7291C7.1313 20.727 15.2686 12.5896 25.2708 12.5896C35.2729 12.5896 43.4102 20.727 43.4102 30.7291C43.4102 40.7312 35.2729 48.8686 25.2708 48.8686Z" fill="#07303E"/>
<path d="M28.25 33.1426C28.25 31.4998 26.9135 30.1633 25.2708 30.1633C23.628 30.1633 22.2915 31.4998 22.2915 33.1426C22.2915 34.7853 23.628 36.1218 25.2708 36.1218C26.9135 36.1218 28.25 34.7853 28.25 33.1426ZM25.2708 34.9905C24.2519 34.9905 23.4229 34.1615 23.4229 33.1426C23.4229 32.1237 24.2519 31.2947 25.2708 31.2947C26.2897 31.2947 27.1186 32.1237 27.1186 33.1426C27.1186 34.1615 26.2897 34.9905 25.2708 34.9905Z" fill="#07303E"/>
<path d="M16.13 1.19695V8.87494H14.59V3.88095L12.533 8.87494H11.367L9.29897 3.88095V8.87494H7.75897V1.19695H9.50797L11.95 6.90594L14.392 1.19695H16.13Z" fill="#07303E"/>
<path d="M19.9106 2.27495V0.855946H22.5616V8.87494H20.9776V2.27495H19.9106Z" fill="#07303E"/>
<path d="M27.1881 7.13694C27.8921 6.55028 28.4531 6.06261 28.8711 5.67395C29.2891 5.27795 29.6374 4.86728 29.9161 4.44195C30.1947 4.01661 30.3341 3.59861 30.3341 3.18795C30.3341 2.81395 30.2461 2.52061 30.0701 2.30795C29.8941 2.09528 29.6227 1.98895 29.2561 1.98895C28.8894 1.98895 28.6071 2.11361 28.4091 2.36295C28.2111 2.60495 28.1084 2.93861 28.1011 3.36395H26.6051C26.6344 2.48395 26.8947 1.81661 27.3861 1.36195C27.8847 0.907279 28.5154 0.679945 29.2781 0.679945C30.1141 0.679945 30.7557 0.903612 31.2031 1.35095C31.6504 1.79095 31.8741 2.37395 31.8741 3.09995C31.8741 3.67195 31.7201 4.21828 31.4121 4.73895C31.1041 5.25961 30.7521 5.71428 30.3561 6.10295C29.9601 6.48428 29.4431 6.94628 28.8051 7.48895H32.0501V8.76495H26.6161V7.62094L27.1881 7.13694Z" fill="#07303E"/>
<path d="M35.6636 2.96795C35.7002 2.23461 35.9569 1.66995 36.4336 1.27395C36.9176 0.870612 37.5519 0.668945 38.3366 0.668945C38.8719 0.668945 39.3302 0.764279 39.7116 0.954946C40.0929 1.13828 40.3789 1.39128 40.5696 1.71395C40.7676 2.02928 40.8666 2.38861 40.8666 2.79195C40.8666 3.25395 40.7456 3.64628 40.5036 3.96895C40.2689 4.28428 39.9866 4.49695 39.6566 4.60695V4.65095C40.0819 4.78295 40.4119 5.01761 40.6466 5.35495C40.8886 5.69228 41.0096 6.12495 41.0096 6.65295C41.0096 7.09295 40.9069 7.48528 40.7016 7.82995C40.5036 8.17461 40.2066 8.44594 39.8106 8.64394C39.4219 8.83461 38.9526 8.92994 38.4026 8.92994C37.5739 8.92994 36.8992 8.72095 36.3786 8.30295C35.8579 7.88495 35.5829 7.26894 35.5536 6.45495H37.0496C37.0642 6.81428 37.1852 7.10395 37.4126 7.32395C37.6472 7.53661 37.9662 7.64294 38.3696 7.64294C38.7436 7.64294 39.0296 7.54028 39.2276 7.33494C39.4329 7.12228 39.5356 6.85094 39.5356 6.52095C39.5356 6.08095 39.3962 5.76561 39.1176 5.57495C38.8389 5.38428 38.4062 5.28895 37.8196 5.28895H37.5006V4.02395H37.8196C38.8609 4.02395 39.3816 3.67561 39.3816 2.97895C39.3816 2.66361 39.2862 2.41795 39.0956 2.24195C38.9122 2.06595 38.6446 1.97795 38.2926 1.97795C37.9479 1.97795 37.6802 2.07328 37.4896 2.26395C37.3062 2.44728 37.1999 2.68195 37.1706 2.96795H35.6636Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon24;
