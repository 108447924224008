import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import UploadDTC from "../icons/Upload.svg";
import Success from "../icons/success.png";
import uploadImage from "../helpers/awsPDFuploader";
import { connect } from "react-redux";
import imageTimeStamp from "../helpers/imageTimeStamp";
import { useTranslation } from "react-i18next";

function UploadDTCPDF(props) {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState({});
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const car = props.car;
  const [selectionCase, setSelectionCase] = useState({
    icon: UploadDTC,
    message: t("upload pdf File to be converted"),
    error: false,
    uploaded: false,
  });
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = async () => {
    await uploadImage(
      selectedFile,
      `Car-${car.car.id}-File-${imageTimeStamp()}-${selectedFile.name}`,
      (data) => {
        console.log("kkkkkkkkkkkkkkk", data.Location);
        props.setFileLink(data.Location);
      }
    );
  };

  useEffect(() => {
    if (selectedFile?.name?.split(".")[1] == "pdf") {
      setSelectionCase({
        uploaded: false,
        icon: UploadDTC,
        message: t("file is ready to be uploaded"),
        error: false,
      });
    } else {
      if (
        selectedFile &&
        Object.keys(selectedFile).length === 0 &&
        Object.getPrototypeOf(selectedFile) === Object.prototype
      ) {
        setSelectionCase({
          uploaded: false,
          icon: UploadDTC,
          message: t("upload pdf File to be converted"),
          error: false,
        });
      } else {
        setSelectionCase({
          uploaded: false,
          icon: UploadDTC,
          message: t("file is not pdf please make sure to upload pdf file"),
          error: true,
        });
      }
    }
  }, [selectedFile]);

  return (
    <div
      style={{
        margin: "15px auto",
        background: "#f5f5f5",
        maxWidth: "330px",
        padding: "10px",
        borderRadius: "10px",
        border: "2px dashed #0473A3",
      }}
      onClick={() => document.getElementById("DTCfile").click()}
    >
      <input
        enctype="multipart/form-data"
        type="file"
        name="file"
        onChange={changeHandler}
        style={{ opacity: "0" }}
        id="DTCfile"
      />
      <div style={{ margin: "auto", maxWidth: "200px" }}>
        <div
          style={{ width: "fit-content", margin: "auto", pointer: "cursor" }}
        >
          <img src={selectionCase.icon} style={{ width: "70px" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "1em",
            margin: "10px auto",
          }}
        >
          {selectedFile?.name}
        </div>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "1.1em",
            margin: "10px auto",
            fontWeight: "500",
            color: selectionCase.error && "red",
          }}
        >
          {selectionCase.message}
        </div>
        <div style={{ margin: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              //   props.k();
              handleSubmission();
              setSelectionCase({
                icon: Success,
                message: "file submitted succefully",
                error: false,
                uploaded: true,
              });
            }}
            disabled={
              selectionCase.uploaded ||
              selectionCase.error ||
              (selectedFile &&
                Object.keys(selectedFile).length === 0 &&
                Object.getPrototypeOf(selectedFile) === Object.prototype)
            }
          >
            {t("Fill DTC Report")}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  car: state.car,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDTCPDF);
