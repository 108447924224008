import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MarkabteSmallLogo from "../images/MarkabteSmallLogo.png";
import Sms from "../images/Sms.png";
import Whatsapp from "../images/Whatsapp.png";
import PublishDialog from "./PublishDialog";
import { encrypt } from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "306px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 19px",
      margin: 0,
    },
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  dialogContentText: {
    padding: "0",
  },
  radio: {
    "&$checked": {
      color: "#0c6885",
    },
  },
  checked: {},
}));

const SendReportPublishDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, handelPublishCar, carId, phone, ownerName } =
    props;
  const [publishFor, setPublishFor] = useState("");
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const handleClose1 = () => {
    setOpenPublishDialog(false);
    document.getElementById("root").style.filter = "none";
  };

  const handleChange = (e) => {
    setPublishFor(e.target.value);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          disableTypography={true}
          style={{
            display: "inline",
            padding: "0 0 10px",
          }}
        >
          <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
            {t("Car Publish Options")}
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentText }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontSize: "14px",
              padding: "0 0 20px",
              margin: 0,
              color: "#07303E",
              fontWeight: 500,
            }}
          >
            {t("Select where you want to publish the car")}:
          </DialogContentText>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel
                control={
                  <>
                    <Radio
                      value="markabte"
                      checked={publishFor === "markabte"}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                    <img
                      style={{
                        marginRight: "10px",
                      }}
                      src={MarkabteSmallLogo}
                    />
                  </>
                }
                label={t("Markabte.com")}
              />
              <FormControlLabel
                control={
                  <>
                    <Radio
                      value="whatsapp"
                      checked={publishFor === "whatsapp"}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                    <img
                      style={{
                        marginRight: "10px",
                      }}
                      src={Whatsapp}
                    />
                  </>
                }
                label={t("WhatsApp")}
              />
              <FormControlLabel
                control={
                  <>
                    <Radio
                      value="sms"
                      checked={publishFor === "sms"}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                    <img
                      style={{
                        marginRight: "10px",
                      }}
                      src={Sms}
                    />
                  </>
                }
                label={t("SMS")}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", padding: 0, marginTop: "15px" }}
        >
          <Button
            style={{
              width: "50%",
            }}
            onClick={() => {
              if (publishFor === "markabte") {
                setOpenPublishDialog(true);
              } else if (publishFor === "whatsapp") {
                window.open(
                  `https://wa.me/${phone}/?text=مرحباً سيد/ة ${ownerName}, يمكنك مشاهدة تقرير الفحص الخاص بسيارتك من الرابط الآتي: %0a https://markabte.com/car-report/${encrypt(
                    carId
                  )}`
                );
                handleClose();
              } else if (publishFor == "sms") {
                (async () =>
                  await fetch(
                    `http://josmsservice.com/sms/api/SendSingleMessage.cfm?numbers=${phone}&senderid=Markabte&AccName=Markabte&AccPass=NuDnU@UFtpnJmjgS&Msg=مرحباً سيد/ة ${ownerName}, يمكنك مشاهدة تقرير الفحص الخاص بسيارتك من الرابط الآتي: %0a https://markabte.com/car-report/${encrypt(
                      carId
                    )}`,
                    { method: "POST" }
                  ))().catch((e) => {
                  console.log("errrqwwq", e);
                });
                handleClose();
              }
            }}
            disabled={publishFor == ""}
            color="primary"
            variant="contained"
            className={classes.dialogButtons}
          >
            {t("Publish")}
          </Button>
          <Button
            style={{
              width: "50%",
            }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
            className={classes.dialogButtons}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <PublishDialog
        open={openPublishDialog}
        handleClose={handleClose1}
        handelPublishCar={handelPublishCar}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  car: state.car.cars,
});

export default withRouter(
  connect(mapStateToProps, null)(SendReportPublishDialog)
);
