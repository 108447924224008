import React from "react";

const Spoiler = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M49.5652 19.7826L40.8696 13.2609C40.6815 13.1196 40.4522 13.0435 40.2174 13.0435H9.78261C9.54783 13.0435 9.31848 13.1196 9.13043 13.2609L0.434783 19.7826C0.16087 19.988 0 20.3109 0 20.6522V31.5217C0 31.9337 0.232609 32.3098 0.601087 32.4946C0.969565 32.6772 1.40978 32.6391 1.73913 32.3913L8.69565 27.1739V34.7826C8.69565 35.9815 9.67065 36.9565 10.8696 36.9565H15.2174C16.4163 36.9565 17.3913 35.9815 17.3913 34.7826V26.087H32.6087V34.7826C32.6087 35.9815 33.5837 36.9565 34.7826 36.9565H39.1304C40.3293 36.9565 41.3043 35.9815 41.3043 34.7826V27.1739L48.2609 32.3913C48.4533 32.5359 48.6826 32.6087 48.913 32.6087C49.0783 32.6087 49.2457 32.5706 49.3989 32.4946C49.7674 32.3098 50 31.9337 50 31.5217V20.6522C50 20.3109 49.8391 19.988 49.5652 19.7826ZM15.2174 34.7826H10.8696V26.087H15.2174V34.7826ZM39.1304 34.7826H34.7826V26.087H39.1304V34.7826ZM47.8261 29.3478L40.8696 24.1304C40.8239 24.0967 40.7707 24.0793 40.7217 24.0533C40.6815 24.0315 40.6457 24.0076 40.6033 23.9913C40.4793 23.9435 40.3511 23.913 40.2174 23.913H9.78261C9.64891 23.913 9.52065 23.9435 9.39783 23.9902C9.35543 24.0065 9.31848 24.0315 9.27935 24.0522C9.23043 24.0783 9.17609 24.0956 9.13152 24.1293L2.17391 29.3478V21.1956L10.1446 15.2174H39.8543L47.8261 21.1956V29.3478Z" fill="#07303E"/>
</svg>
);
};

export default Spoiler;
