import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { cropImage } from "./cropUtils";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ImageCropper5 = (props) => {
  const { image, openCrop, setOpenCrop, containerStyle } = props;
  const [croppedImage, setCroppedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // useEffect(() => {
  //   console.log("frecs", croppedImage);
  //   props.ff(croppedImage);
  // }, [croppedImage]);
  // fghjkl
  const handleClose = () => {
    setOpenCrop(false);
  };
  return (
    <div>
      <Dialog open={openCrop} maxWidth="sm" fullWidth>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          <Typography>Crop Image</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent
          style={{
            height: "500px",
          }}
        >
          <div>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={(_, croppedAreaPixels) => {
                setCroppedAreaPixels(croppedAreaPixels);
                setCroppedImage(image);
              }}
              onComplete={(imagePromisse) => {
                imagePromisse.then((image) => {
                  setCroppedImage(image);
                  setDialogOpen(false);
                });
              }}
              onZoomChange={setZoom}
              {...props}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              cropImage(image, croppedAreaPixels, console.log).then(
                (result) => {
                  setCroppedImage(result);
                  props.ff(result);
                }
              );
              // props.ff(croppedImage);
              setOpenCrop(false);
            }}
          >
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      {/* {croppedImage ? (
        <img
          style={{
            width: "400px",
            height: "300px",
            maxWidth: "400px",
            maxHeight: "300px",
          }}
          src={croppedImage}
          alt="blab"
        />
      ) : (
        <img
          alt="placeholder"
          style={{
            width: "400px",
            height: "300px",
            maxWidth: "400px",
            maxHeight: "300px",
          }}
          src={require("../images/img_placeholder.png")}
        />
      )} */}
    </div>
  );
};
export default withRouter(connect(null, null)(ImageCropper5));
