import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import theme from "../theme";
import { DataGrid, GridCloseIcon } from "@material-ui/data-grid";
import {
  getTimeSlots,
  createTimeSlot,
  updateTimeSlot,
  getAreaGroups,
} from "../store/actions/timeSlots";
import TextInput from "./TextInput";

const useStyles = makeStyles((theme) => ({
  methodDiv: {
    display: "block",
    width: "70vw",
    margin: "16px",
    padding: "8px",
    marginInline: "auto",
  },
}));

function TimeSlots(props) {
  const {
    getTimeSlots,
    getAreaGroups,
    createTimeSlot,
    updateTimeSlot,
    time_slots,
  } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [areaGroups, setAreaGroups] = useState([]);

  const [id, setid] = useState(0);
  const [areaGroup, setAreaGroup] = useState(1);
  const [capacity, setcapacity] = useState(1);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("2023-03-20");
  const [endDate, setEndDate] = useState("2023-03-20");

  const [currentSlot, setCurrentSlot] = useState({}); // needed ?

  const [currentId, setCurrentId] = useState(0);
  const [currentCity, setCurrentCity] = useState("");
  const [currentAreaGroup, setCurrentAreaGroup] = useState("");
  const [currentCapacity, setCurrentCapacity] = useState(1);
  const [currentStartTime, setCurrentStartTime] = useState("");
  const [currentEndTime, setCurrentEndTime] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState("2023-03-20");
  const [currentEndDate, setCurrentEndDate] = useState("2023-03-20");
  const [currentActive, setCurrentActive] = useState(false);
  const [currentHistory, setCurrentHistory] = useState(false); // needed ?

  const today = new Date().toISOString().split("T")[0]; // Set today's date as min date

  const columns = [
    {
      field: "city_name",
      headerName: "City",
      valueGetter: (params) => params.row.area_group.city?.city_name,
    },
    {
      field: "area_group",
      headerName: "Area Group",
      width: 200,
      editable: true,
      valueGetter: (params) => params.row.area_group.area_group_name,
    },
    {
      field: "time_slot",
      headerName: "Time slot",
      width: 200,
      editable: true,
      valueGetter: (params) =>
        params.row.start_time.slice(0, -3) +
        "  -  " +
        params.row.end_time.slice(0, -3),
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 160,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 160,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      valueGetter: (params) =>
        params.row.active == true ? "Active" : "Inactive",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setIsOpen(true);
          /////////////////
          setCurrentSlot(time_slots.find((x) => x.id === params.row.id));
          ///////
          setCurrentId(time_slots.find((x) => x.id === params.row.id).id);
          setCurrentCity(
            time_slots.find((x) => x.id === params.row.id)?.area_group?.city
              ?.city_name
          );
          setCurrentAreaGroup(
            time_slots.find((x) => x.id === params.row.id)?.area_group?.id
          );
          setCurrentCapacity(
            time_slots.find((x) => x.id === params.row.id).capacity
          );
          setCurrentStartTime(
            time_slots.find((x) => x.id === params.row.id).start_time
          );
          setCurrentEndTime(
            time_slots.find((x) => x.id === params.row.id).end_time
          );
          setCurrentStartDate(
            time_slots.find((x) => x.id === params.row.id).start_date
          );
          setCurrentEndDate(
            time_slots.find((x) => x.id === params.row.id).end_date
          );
          setCurrentActive(
            time_slots.find((x) => x.id === params.row.id).active
          );
          setCurrentHistory(
            time_slots.find((x) => x.id === params.row.id).history
          );

          ///////////////
        };

        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const handleSaveEdit = () => {
    updateTimeSlot({
      id: currentId,
      area_group_id: currentAreaGroup,
      capacity: currentCapacity,
      start_time: currentStartTime,
      end_time: currentEndTime,
      start_date: currentStartDate,
      end_date: currentEndDate,
      active: currentActive,
      history: false,
      previous: null,
    });
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsOpenCreate(false);
  };

  const handleSave = () => {
    createTimeSlot({
      area_group_id: areaGroup,
      start_time: startTime,
      end_time: endTime,
      start_date: startDate,
      end_date: endDate,
      capacity: capacity,
      active: 1,
      history: 0,
      previous: null,
    });
    // Handle saving the field values

    setIsOpenCreate(false);
    // getTimeSlots();
  };

  useEffect(() => {
    getTimeSlots();
    getAreaGroups({
      callback: (response) => {
        setAreaGroups(response.results);
      },
    });
  }, []);

  return (
    <div style={{ height: "80vh", padding: "15px 25px 0 25px" }}>
      <Button
        style={{
          marginBottom: "15px",
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          setIsOpenCreate(true);
        }}
      >
        Create timeslot
      </Button>
      <DataGrid
        rows={time_slots || []}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        stretch
      />
      <Dialog style={{ display: "flex" }} open={isOpen}>
        <GridCloseIcon
          style={{
            width: "auto",
            marginLeft: "auto",
            padding: "10px",
          }}
          onClick={() => setIsOpen(false)}
        ></GridCloseIcon>
        <DialogTitle>Edit a timeslot</DialogTitle>
        <DialogContent>
          <Select
            value={areaGroup}
            onChange={(e) => {
              setCurrentAreaGroup(e.target.value);
            }}
            fullWidth
          >
            {areaGroups.map((area) => {
              return (
                <MenuItem value={area.id}>{area.area_group_name}</MenuItem>
              );
            })}
          </Select>
          <TextField
            label="City"
            value={
              areaGroups?.filter((area) => area.id === currentAreaGroup)?.[0]
                ?.city?.city_name ?? currentAreaGroup
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Capacity"
            value={currentCapacity}
            onChange={(e) => setCurrentCapacity(Number(e.target.value))}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            type="time"
            label="Time From"
            value={currentStartTime}
            onChange={(e) => setCurrentStartTime(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            type="time"
            label="Time To"
            value={currentEndTime}
            onChange={(e) => setCurrentEndTime(e.target.value)}
            fullWidth
            margin="normal"
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Date From</Typography>
            <TextField
              // label="Date From"
              value={currentStartDate}
              onChange={(e) => setCurrentStartDate(e.target.value)}
              fullWidth
              margin="normal"
              type={"date"}
              inputProps={{
                min: today,
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Date To</Typography>
            <TextField
              // label="Date To"
              value={currentEndDate}
              onChange={(e) => setCurrentEndDate(e.target.value)}
              fullWidth
              margin="normal"
              type={"date"}
              inputProps={{
                min: today,
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Active</Typography>
            <Checkbox
              checked={currentActive}
              label="Active"
              color="primary"
              onChange={() => setCurrentActive(!currentActive)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveEdit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog style={{ display: "flex" }} open={isOpenCreate}>
        <GridCloseIcon
          style={{
            width: "auto",
            marginLeft: "auto",
            padding: "10px",
          }}
          onClick={() => setIsOpenCreate(false)}
        ></GridCloseIcon>
        <DialogTitle>Create a time slot</DialogTitle>
        <DialogContent>
          <Select
            value={areaGroup}
            onChange={(e) => {
              setAreaGroup(e.target.value);
            }}
            fullWidth
          >
            {areaGroups.map((area) => {
              return (
                <MenuItem value={area.id}>{area.area_group_name}</MenuItem>
              );
            })}
          </Select>
          <TextField
            label="City"
            value={
              areaGroups?.filter((area) => area.id === areaGroup)?.[0]?.city
                ?.city_name
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Capacity"
            // value={field2}
            onChange={(e) => setcapacity(Number(e.target.value))}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            type="time"
            label="Time From"
            // value={field4}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            type="time"
            label="Time To"
            // value={field4}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            margin="normal"
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Date From</Typography>
            <TextField
              // label="Date From"
              // value={field5}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              margin="normal"
              type={"date"}
              inputProps={{
                min: today,
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Date To</Typography>
            <TextField
              // label="Date To"
              // value={field5}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              margin="normal"
              type={"date"}
              inputProps={{
                min: today,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  time_slots: state.timeSlots.time_slots,
});

const mapDispatchToProps = (dispatch) => ({
  getTimeSlots(payload) {
    dispatch(getTimeSlots(payload));
  },
  createTimeSlot(payload) {
    dispatch(createTimeSlot(payload));
  },
  updateTimeSlot(payload) {
    dispatch(updateTimeSlot(payload));
  },
  getAreaGroups(payload) {
    dispatch(getAreaGroups(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeSlots)
);
