import React from "react";

const FeatureIcon49 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M13.6593 7.40391H10.3233L9.77132 8.99991H8.00732L11.0193 0.611906H12.9753L15.9873 8.99991H14.2113L13.6593 7.40391ZM13.2033 6.05991L11.9913 2.55591L10.7793 6.05991H13.2033Z" fill="#07303E"/>
<path d="M18.7692 0.623906V5.80791C18.7692 6.37591 18.9172 6.81191 19.2132 7.11591C19.5092 7.41191 19.9252 7.55991 20.4612 7.55991C21.0052 7.55991 21.4252 7.41191 21.7212 7.11591C22.0172 6.81191 22.1652 6.37591 22.1652 5.80791V0.623906H23.8572V5.79591C23.8572 6.50791 23.7012 7.11191 23.3892 7.60791C23.0852 8.09591 22.6732 8.46391 22.1532 8.71191C21.6412 8.95991 21.0692 9.08391 20.4372 9.08391C19.8132 9.08391 19.2452 8.95991 18.7332 8.71191C18.2292 8.46391 17.8292 8.09591 17.5332 7.60791C17.2372 7.11191 17.0892 6.50791 17.0892 5.79591V0.623906H18.7692Z" fill="#07303E"/>
<path d="M31.1804 0.623906V1.97991H28.9484V8.99991H27.2684V1.97991H25.0364V0.623906H31.1804Z" fill="#07303E"/>
<path d="M36.2941 9.08391C35.5101 9.08391 34.7901 8.89991 34.1341 8.53191C33.4781 8.16391 32.9581 7.65591 32.5741 7.00791C32.1901 6.35191 31.9981 5.61191 31.9981 4.78791C31.9981 3.97191 32.1901 3.23991 32.5741 2.59191C32.9581 1.93591 33.4781 1.42391 34.1341 1.05591C34.7901 0.687906 35.5101 0.503906 36.2941 0.503906C37.0861 0.503906 37.8061 0.687906 38.4541 1.05591C39.1101 1.42391 39.6261 1.93591 40.0021 2.59191C40.3861 3.23991 40.5781 3.97191 40.5781 4.78791C40.5781 5.61191 40.3861 6.35191 40.0021 7.00791C39.6261 7.65591 39.1101 8.16391 38.4541 8.53191C37.7981 8.89991 37.0781 9.08391 36.2941 9.08391ZM36.2941 7.58391C36.7981 7.58391 37.2421 7.47191 37.6261 7.24791C38.0101 7.01591 38.3101 6.68791 38.5261 6.26391C38.7421 5.83991 38.8501 5.34791 38.8501 4.78791C38.8501 4.22791 38.7421 3.73991 38.5261 3.32391C38.3101 2.89991 38.0101 2.57591 37.6261 2.35191C37.2421 2.12791 36.7981 2.01591 36.2941 2.01591C35.7901 2.01591 35.3421 2.12791 34.9501 2.35191C34.5661 2.57591 34.2661 2.89991 34.0501 3.32391C33.8341 3.73991 33.7261 4.22791 33.7261 4.78791C33.7261 5.34791 33.8341 5.83991 34.0501 6.26391C34.2661 6.68791 34.5661 7.01591 34.9501 7.24791C35.3421 7.47191 35.7901 7.58391 36.2941 7.58391Z" fill="#07303E"/>
<path d="M37.8446 20.0368C35.2825 17.0991 31.8413 15.0863 28.0625 14.2924V12.0698H20.6975V14.2926C16.887 15.0932 13.4178 17.1359 10.8524 20.1096C8.05856 23.3477 6.52002 27.4892 6.52002 31.7711C6.52002 41.6191 14.532 49.6311 24.38 49.6311C34.2281 49.6311 42.24 41.6191 42.24 31.7711C42.24 27.4539 40.679 23.2866 37.8446 20.0368ZM23.4594 14.8317H25.3006V20.3554H23.4594V14.8317ZM24.38 46.8692C16.0549 46.8692 9.28188 40.0962 9.28188 31.7711C9.28188 28.1513 10.5823 24.6505 12.9434 21.9137C14.9848 19.5475 17.7014 17.8781 20.6975 17.1256V23.1172H28.0625V17.1263C31.0318 17.8719 33.7257 19.5159 35.7631 21.8521C38.1588 24.5989 39.4782 28.1215 39.4782 31.7711C39.4782 40.0962 32.7052 46.8692 24.38 46.8692Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6825 14.6008V12.4498H21.0775V14.601L20.7757 14.6644C17.0468 15.448 13.6512 17.4471 11.1401 20.3578C8.4059 23.5268 6.90002 27.5802 6.90002 31.7711C6.90002 41.4092 14.7418 49.2511 24.38 49.2511C34.0182 49.2511 41.86 41.4092 41.86 31.7711C41.86 27.5456 40.3322 23.4671 37.5582 20.2865C35.0503 17.411 31.6822 15.4412 27.9844 14.6643L27.6825 14.6008ZM20.6975 12.0698H28.0625V14.2924C31.8413 15.0863 35.2825 17.0991 37.8446 20.0368C40.679 23.2866 42.24 27.4539 42.24 31.7711C42.24 41.6191 34.2281 49.6311 24.38 49.6311C14.532 49.6311 6.52002 41.6191 6.52002 31.7711C6.52002 27.4892 8.05856 23.3477 10.8524 20.1096C13.4178 17.1359 16.887 15.0932 20.6975 14.2926V12.0698ZM23.0794 14.4517H25.6806V20.7354H23.0794V14.4517ZM12.6556 21.6655C14.7482 19.2401 17.5329 17.5286 20.605 16.757L21.0775 16.6383V22.7372H27.6825V16.6391L28.155 16.7578C31.1995 17.5223 33.9611 19.2076 36.0495 21.6024C38.5057 24.4184 39.8582 28.0299 39.8582 31.7711C39.8582 40.3061 32.915 47.2492 24.38 47.2492C15.845 47.2492 8.90188 40.3061 8.90188 31.7711C8.90188 28.0603 10.2349 24.4714 12.6556 21.6655ZM35.7631 21.8521C33.813 19.6159 31.2613 18.014 28.4425 17.2271C28.3164 17.1918 28.1897 17.1583 28.0625 17.1263V23.1172H20.6975V17.1256C20.5704 17.1575 20.4437 17.1911 20.3175 17.2264C17.472 18.0211 14.8982 19.648 12.9434 21.9137C10.5823 24.6505 9.28188 28.1513 9.28188 31.7711C9.28188 40.0962 16.0549 46.8692 24.38 46.8692C32.7052 46.8692 39.4782 40.0962 39.4782 31.7711C39.4782 28.1215 38.1588 24.5989 35.7631 21.8521ZM23.4594 14.8317V20.3554H25.3006V14.8317H23.4594Z" fill="white"/>
<path d="M17.8101 28.7622H16.1479L13.36 36.2566H15.5377L15.9796 34.7894H17.9679L18.4203 36.2566H20.5876L17.8101 28.7622ZM16.2848 33.4172L16.9791 30.905L17.6419 33.4172H16.2848Z" fill="#07303E"/>
<path d="M26.4053 28.7622L20.9873 36.2566H23.1861L28.6146 28.7622H26.4053Z" fill="#07303E"/>
<path d="M33.7272 33.5755C33.671 33.7444 33.5957 33.8869 33.501 34.003C33.4064 34.1191 33.3012 34.2141 33.1854 34.288C33.0697 34.3619 32.9487 34.4147 32.8224 34.4464C32.6962 34.478 32.5733 34.4939 32.4542 34.4939C32.2157 34.4939 32.0035 34.4394 31.8177 34.3302C31.6318 34.2213 31.4739 34.0752 31.3444 33.8922C31.2145 33.7092 31.1163 33.4982 31.0498 33.2589C30.983 33.0197 30.9498 32.7699 30.9498 32.5094C30.9498 32.2772 30.9777 32.0433 31.034 31.8075C31.0903 31.5718 31.1797 31.3607 31.3025 31.1741C31.4251 30.9877 31.5795 30.8364 31.7654 30.7202C31.9511 30.6041 32.1739 30.5461 32.4335 30.5461C32.5455 30.5461 32.6684 30.5602 32.8016 30.5883C32.9349 30.6165 33.061 30.6658 33.1804 30.7361C33.2995 30.8065 33.41 30.9015 33.5117 31.0211C33.6133 31.1408 33.6959 31.2921 33.759 31.475L35.3265 30.3561C35.088 29.8636 34.7198 29.4677 34.2218 29.1686C33.7237 28.8696 33.1417 28.72 32.4754 28.72C31.9074 28.72 31.4005 28.8291 30.9552 29.0472C30.5097 29.2654 30.131 29.5504 29.819 29.9022C29.5068 30.2541 29.2701 30.6516 29.1088 31.095C28.9474 31.5383 28.8668 31.9888 28.8668 32.4461C28.8668 32.9247 28.9545 33.3961 29.1298 33.8605C29.3051 34.325 29.5488 34.7402 29.8611 35.1061C30.1731 35.4721 30.5465 35.7658 30.9814 35.9875C31.4162 36.2091 31.8931 36.32 32.4122 36.32C32.7068 36.32 33.0083 36.2812 33.3169 36.2039C33.6255 36.1265 33.9166 36.0139 34.1901 35.8661C34.4637 35.7183 34.7072 35.5354 34.9213 35.3172C35.1351 35.0991 35.2948 34.8527 35.4 34.5783L33.7272 33.5755Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon49;
