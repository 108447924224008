import React from "react";

const Odometer = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 143 143"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M21.0715 109.607L12.7093 115.269C4.99619 103.707 0.921767 90.2361 0.921767 76.2771C0.921767 57.481 8.24259 39.8122 21.5376 26.5213C34.8324 13.2312 52.5063 5.91275 71.3077 5.91275C90.1092 5.91275 107.783 13.2312 121.078 26.5213C134.373 39.8122 141.694 57.481 141.694 76.2771C141.694 90.2361 137.619 103.707 129.906 115.269L121.544 109.607C128.121 99.7226 131.595 88.2085 131.595 76.2771C131.595 43.0458 104.551 16.0114 71.3077 16.0114C38.0648 16.0114 11.0204 43.0458 11.0204 76.2771C11.0204 88.2085 14.4942 99.7226 21.0715 109.607ZM72.2279 67.9776L72.4605 68.0031L72.6259 67.8377L100.752 39.7115L107.893 46.8525L79.7669 74.9786L79.6016 75.1439L79.627 75.3763C79.6601 75.6804 79.6784 75.9875 79.6784 76.2975C79.6784 80.9209 75.9303 84.669 71.3068 84.669C66.6834 84.669 62.9353 80.9209 62.9353 76.2975C62.9353 71.674 66.6834 67.926 71.3068 67.926C71.6169 67.926 71.9238 67.9442 72.2279 67.9776ZM90.6867 125.511H91.1744V125.023V113.949V113.461H90.6867H51.9276H51.4399V113.949V125.023V125.511H51.9276H90.6867ZM76.3563 33.11C76.3563 35.8986 74.0957 38.1593 71.307 38.1593C68.5184 38.1593 66.2577 35.8986 66.2577 33.11C66.2577 30.3213 68.5184 28.0607 71.307 28.0607C74.0957 28.0607 76.3563 30.3213 76.3563 33.11ZM33.1682 76.2979C33.1682 79.0866 30.9076 81.3472 28.1189 81.3472C25.3303 81.3472 23.0696 79.0866 23.0696 76.2979C23.0696 73.5093 25.3303 71.2486 28.1189 71.2486C30.9076 71.2486 33.1682 73.5093 33.1682 76.2979ZM45.903 45.8455C45.903 48.6341 43.6423 50.8948 40.8536 50.8948C38.065 50.8948 35.8043 48.6341 35.8043 45.8455C35.8043 43.0568 38.065 40.7962 40.8536 40.7962C43.6423 40.7962 45.903 43.0568 45.903 45.8455ZM119.545 76.2979C119.545 79.0866 117.285 81.3472 114.496 81.3472C111.707 81.3472 109.447 79.0866 109.447 76.2979C109.447 73.5093 111.707 71.2486 114.496 71.2486C117.285 71.2486 119.545 73.5093 119.545 76.2979ZM101.273 135.609H41.3413V103.363H101.273V135.609Z"
          fill={fill}
          stroke="white"
          stroke-width="0.975401"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="141.747"
            height="141.747"
            fill="white"
            transform="translate(0.434814 0.995483)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Odometer;
