import React from "react";

const FeatureIcon28 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M25 0C11.2152 0 0 11.2152 0 25C0 38.7848 11.2152 50 25 50C38.7848 50 50 38.7848 50 25C50 11.2152 38.7848 0 25 0ZM25 48.2457C12.1822 48.2457 1.75434 37.8178 1.75434 25C1.75434 12.1822 12.1822 1.75434 25 1.75434C37.8178 1.75434 48.2457 12.1822 48.2457 25C48.2457 37.8178 37.8178 48.2457 25 48.2457Z" fill="#07303E"/>
<path d="M25 19.2983C21.8561 19.2983 19.2983 21.8563 19.2983 25.0001C19.2983 28.1439 21.8562 30.7018 25 30.7018C28.1439 30.7018 30.7017 28.1438 30.7017 25.0001C30.7017 21.8562 28.1439 19.2983 25 19.2983ZM25 28.9474C22.8233 28.9474 21.0526 27.1768 21.0526 25.0001C21.0526 22.8233 22.8233 21.0527 25 21.0527C27.1767 21.0527 28.9474 22.8233 28.9474 25.0001C28.9474 27.1768 27.1767 28.9474 25 28.9474Z" fill="#07303E"/>
<path d="M25 15.7896C19.9211 15.7896 15.7895 19.9212 15.7895 25.0001C15.7895 30.079 19.9211 34.2106 25 34.2106C30.0789 34.2106 34.2105 30.079 34.2105 25.0001C34.2105 19.9212 30.0789 15.7896 25 15.7896ZM25 32.4563C20.8891 32.4563 17.5438 29.111 17.5438 25.0001C17.5438 20.8891 20.8891 17.5439 25 17.5439C29.111 17.5439 32.4562 20.8891 32.4562 25.0001C32.4562 29.111 29.111 32.4563 25 32.4563Z" fill="#07303E"/>
<path d="M25 19.2983C21.8561 19.2983 19.2983 21.8563 19.2983 25C19.2983 28.1439 21.8562 30.7017 25 30.7017C28.1439 30.7017 30.7017 28.1438 30.7017 25C30.7017 21.8562 28.1439 19.2983 25 19.2983ZM25 28.9474C22.8233 28.9474 21.0526 27.1767 21.0526 25C21.0526 22.8233 22.8233 21.0527 25 21.0527C27.1767 21.0527 28.9474 22.8233 28.9474 25C28.9474 27.1767 27.1767 28.9474 25 28.9474Z" fill="#07303E"/>
<path d="M15.4586 7.51809C15.2103 7.10164 14.6722 6.96453 14.256 7.21304C9.83744 9.84372 6.61671 13.9993 5.18682 18.9146C5.05151 19.3797 5.31878 19.8663 5.78394 20.0016C5.86527 20.0255 5.94841 20.0367 6.02885 20.0367C6.40932 20.0367 6.7596 19.7882 6.87049 19.4047C8.17613 14.9176 11.1179 11.1226 15.1534 8.72067C15.57 8.47306 15.7061 7.93428 15.4586 7.51809Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon28;
