import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import FormControl from "@material-ui/core/FormControl";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InfoButton from "./PdfPreviewer";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Page } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { number } from "prop-types";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  mobListBox: {
    maxHeight: "calc(100vh - 150px)",
    minHeight: "calc(100vh - 150px)",
    padding: "0",
  },
  mobPaper: {
    margin: "5px 0",
    boxShadow: "unset",
  },
  mobPopper: {
    transform: "translate3d(16px, 120px, 0px) !important", /// wrong depends on scroll
    // transform: "translate3d(16px, 290px, 0px) !important",
  },
  mobOption: {
    padding: "6px 0",
  },
  dialogPaper: {
    borderRadius: "5px",
    padding: "32px 45px",
    width: "298px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 19px",
    },
  },
  dialogButtons: {
    fontSize: "14px",
    borderRadius: "5px",
    textTransform: "none",
    padding: "8px 20px",
    fontWeight: 500,
  },
  dialogRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  dialogContentText: {
    padding: "0",
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeConfigurationDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, changeDialogEvent, changeDialogData, onChange } =
    props;

  const handelChangeAnswer = () => {
    onChange(changeDialogEvent, changeDialogData);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        style={{
          display: "inline",
          margin: "auto",
          padding: "0 0 2px",
        }}
      >
        <Typography style={{ fontSize: "22px", fontWeight: 500 }}>
          {t("Warning!")}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentText }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            textAlign: "center",
            fontSize: "14px",
            padding: "0 0 22px",
            margin: 0,
            color: "black",
          }}
        >
          {t("Changing this answer deletes all hotspot paint readings!")}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", padding: 0 }}>
        <Button
          onClick={handelChangeAnswer}
          color="primary"
          variant="outlined"
          className={classes.dialogButtons}
        >
          {t("Confirm")}
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
          className={classes.dialogButtons}
        >
          {t("Don't Change")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AutocompleteComponent = (props) => {
  const classes = useStyles();

  const {
    options,
    handleChange,
    disabled,
    formTitle,
    handleClickOpen,
    handleInvokedField,
    fromMobile,
    value,
    isdone,
    SetInfoOption,
    setShowInfo,
  } = props;
  // const [showInfo, setShowInfo] = useState(false);
  // const [numPages, setNumPages] = useState(null);
  const conditionalProps = fromMobile
    ? { open: { fromMobile }, PopperComponent: "Popper" }
    : {};
  const [pastAnswer, setPastAnswer] = useState(null); // Autocomplete component has a bug initial state null solves it

  useEffect(() => {
    setPastAnswer(options.filter((option) => option.id == value)[0]);
  }, [value, options]);

  return (
    <Autocomplete
      id="combo-box-demo"
      {...conditionalProps}
      options={options}
      value={pastAnswer}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <>
          <Typography>{option.title}</Typography>
          <InfoButton
            option={option}
            SetInfoOption={SetInfoOption}
            setShowInfo={setShowInfo}
          />
        </>
      )}
      style={{ width: "100%" }}
      onChange={handleChange}
      disabled={options.length === 0 ? true : disabled}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField {...params} label={formTitle} onClick={handleClickOpen} />
      )}
      classes={{
        listbox: clsx({ [classes.mobListBox]: fromMobile }),
        root: clsx({
          [classes.switch_track]: handleInvokedField() && isdone,
          [classes.switch_base]: !handleInvokedField(),
        }),
        paper: clsx({ [classes.mobPaper]: fromMobile }),
        popper: clsx({ [classes.mobPopper]: fromMobile }),
        option: clsx({ [classes.mobOption]: fromMobile }),
      }}
    />
  );
};

const DropdownAutocomplete = (props) => {
  const { t } = useTranslation();

  const {
    value,
    onChange,
    formTitle,
    options,
    disabled,
    field,
    invokedFields,
    isdone,
    renderFieldInfo,
    bodyTypeConfigInvokers,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false); // Autocomplete component has a bug initial state null solves it
  const [changeOpen, setChangeOpen] = useState(false);
  const [changeDialogEvent, setChangeDialogEvent] = useState();
  const [changeDialogData, setChangeDialogData] = useState();

  const [infoOption, SetInfoOption] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleChange = (event, value) => {
    // alert(value)
    let option = options.find((el) => el.id.toString() == value?.id); // ? buggs out in mobile only ???
    if (field?.title_en.includes("Configuration")) {
      if (
        bodyTypeConfigInvokers != undefined &&
        !bodyTypeConfigInvokers.includes(Number(value.id))
      ) {
        setChangeOpen(true);
        setChangeDialogEvent(event);
        setChangeDialogData({ id: value?.id, field: field, option: option });
      } else {
        onChange(event, { id: value?.id, field: field, option: option }); // ? buggs out in mobile only ???
      }
    } else {
      onChange(event, { id: value?.id, field: field, option: option }); // ? buggs out in mobile only ???
    }
    if (value !== null) setOpen(false);
  };
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeClose = () => {
    setChangeOpen(false);
  };

  return (
    <>
      <Dialog
        open={showInfo}
        onClose={() => {
          setShowInfo(false);
        }}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowInfo(false);
                setOpen(true);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            <Document
              file={
                localStorage.getItem("language") === "ar"
                  ? infoOption?.option_info.ar
                  : infoOption?.option_info.en
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={window.innerWidth}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Hidden smDown>
        <AutocompleteComponent
          SetInfoOption={SetInfoOption}
          setShowInfo={setShowInfo}
          options={options}
          handleChange={handleChange}
          disabled={disabled}
          formTitle={formTitle}
          handleClickOpen={handleClickOpen}
          handleInvokedField={handleInvokedField}
          fromMobile={false}
          value={value}
          isdone={isdone}
        />
      </Hidden>
      <Hidden mdUp>
        <FormControl
          fullWidth
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          <InputLabel id={formTitle}>{formTitle}</InputLabel>
          <Select
            value={value}
            onClick={options.length !== 0 ? handleClickOpen : null}
            inputProps={{ readOnly: true }}
            disabled={options.length === 0 ? true : disabled}
          >
            {options.map((option, i) => (
              <MenuItem
                key={`select_menu_item_${option.id}_${i}`}
                value={option.id}
              >
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{ paddingTop: "56px" }}
        >
          <Paper style={{ padding: "16px", height: "100%" }}>
            {" "}
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {t(formTitle)} {t("Options")}
                </Typography>
              </Toolbar>
            </AppBar>
            <AutocompleteComponent
              SetInfoOption={SetInfoOption}
              setShowInfo={setShowInfo}
              options={options}
              handleChange={handleChange}
              disabled={disabled}
              formTitle={formTitle}
              handleClickOpen={handleClickOpen}
              handleInvokedField={handleInvokedField}
              fromMobile={true}
              value={value}
              isdone={isdone}
            />
          </Paper>
        </Dialog>
      </Hidden>
      <ChangeConfigurationDialog
        open={changeOpen}
        handleClose={handleChangeClose}
        changeDialogEvent={changeDialogEvent}
        changeDialogData={changeDialogData}
        onChange={onChange}
      />
    </>
  );
};

export default DropdownAutocomplete;
