const FiltersTypes = [
  {
    camera: "wide",
    id: "282",
    key: "3rd_row",
    label: "3rd Row (Wide Lens)",
    image: require("../images/imageFilters/3rd_row.png"),
  },
  {
    camera: "regular",
    id: "647",
    key: "center_console",
    label: "Center Console",
    image: require("../images/imageFilters/center_console.png"),
  },
  {
    camera: "regular",
    id: "646",
    key: "dashboard",
    label: "Dashboard",
    image: require("../images/imageFilters/dashboard.png"),
  },
  {
    camera: "regular",
    id: "643",
    key: "driver_door_panel",
    label: "Driver Door Panel",
    image: require("../images/imageFilters/driver_door_panel.png"),
  },
  {
    camera: "regular",
    id: "650",
    key: "driver_view",
    label: "Driver View",
    image: require("../images/imageFilters/driver_view.png"),
  },
  {
    camera: "wide",
    id: "285",
    key: "driver",
    label: "Driver (Wide Lens)",
    image: require("../images/imageFilters/driver.png"),
  },
  {
    camera: "regular",
    id: "279",
    key: "front_angle",
    label: "Front Angle",
    image: require("../images/imageFilters/front_angle.png"),
    serverKey: "Front Angle",
  },
  {
    camera: "regular",
    id: "648",
    key: "front_seats",
    label: "Front Seats",
    image: require("../images/imageFilters/front_seats.png"),
  },
  {
    camera: "regular",
    id: "638",
    key: "high_front_angle",
    label: "High Front Angle",
    image: require("../images/imageFilters/high_front_angle.png"),
  },
  {
    camera: "regular",
    id: "652",
    key: "inside_the_trunk",
    label: "Inside the Trunk",
    image: require("../images/imageFilters/inside_the_trunk.png"),
  },
  {
    camera: "regular",
    id: "645",
    key: "instrument_cluster",
    label: "Instrument Cluster",
    image: require("../images/imageFilters/instrument_cluster.png"),
  },
  {
    camera: "regular",
    id: "639",
    key: "left_front_angle",
    label: "Left Front Angle",
    image: require("../images/imageFilters/left_front_angle.png"),
    serverKey: "Left Front Angle",
  },
  {
    camera: "regular",
    id: "642",
    key: "left_rear_angle",
    label: "Left Rear Angle",
    image: require("../images/imageFilters/left_rear_angle.png"),
    serverKey: "Left Rear Angle",
  },
  {
    camera: "regular",
    id: "637",
    key: "low_front_angle",
    label: "Low Front Angle",
    image: require("../images/imageFilters/low_front_angle.png"),
    serverKey: "Low Front Angle",
  },
  {
    camera: "regular",
    id: "641",
    key: "middle_rear_angle",
    label: "Middle Rear Angle",
    image: require("../images/imageFilters/middle_rear_angle.png"),
    serverKey: "Middle Rear Angle",
  },
  {
    camera: "wide",
    id: "284",
    key: "passenger",
    label: "Passenger (Wide Lens)",
    image: require("../images/imageFilters/passenger.png"),
  },
  {
    camera: "regular",
    id: "280",
    key: "rear_angle",
    label: "Rear Angle",
    image: require("../images/imageFilters/rear_angle.png"),
    serverKey: "Rear Angle",
  },
  {
    camera: "regular",
    id: "649",
    key: "rear_seats",
    label: "Rear Seats",
    image: require("../images/imageFilters/rear_seats.png"),
  },
  {
    camera: "regular",
    id: "891",
    key: "rear_seats_2d",
    label: "Rear Seats (2 Doors)",
    image: require("../images/imageFilters/rear_seats_2d.png"),
  },
  {
    camera: "regular",
    id: "269",
    key: "right_front_angle",
    label: "Right Front Angle",
    image: require("../images/imageFilters/right_front_angle.png"),
    serverKey: "Right Front Angle",
  },
  {
    camera: "regular",
    id: "640",
    key: "right_rear_angle",
    label: "Right Rear Angle",
    image: require("../images/imageFilters/right_rear_angle.png"),
    serverKey: "Right Rear Angle",
  },
  {
    camera: "regular",
    id: "644",
    key: "steering_wheel",
    label: "Steering Wheel",
    image: require("../images/imageFilters/steering_wheel.png"),
  },
  {
    camera: "wide",
    id: "283",
    key: "trunk",
    label: "Trunk (Wide Lens)",
    image: require("../images/imageFilters/trunk.png"),
  },
  {
    camera: "regular",
    id: "651",
    key: "under_the_hood",
    label: "Under the Hood",
    image: require("../images/imageFilters/under_the_hood.png"),
  },
];

export default FiltersTypes;
