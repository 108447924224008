import React from "react";

const FeatureIcon43 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M25.1105 9.12176C21.9625 9.12165 18.8851 10.0741 16.2676 11.8587C13.6501 13.6432 11.61 16.1797 10.4053 19.1474C9.20056 22.1151 8.88532 25.3807 9.49944 28.5312C10.1136 31.6818 11.6295 34.5757 13.8554 36.8471C16.0814 39.1185 18.9174 40.6653 22.005 41.292C25.0925 41.9186 28.2927 41.5969 31.2011 40.3676C34.1094 39.1383 36.5952 37.0566 38.3441 34.3856C40.093 31.7147 41.0264 28.5746 41.0263 25.3624C41.0216 21.0565 39.3432 16.9285 36.3595 13.8838C33.3757 10.8391 29.3302 9.12654 25.1105 9.12176ZM25.1105 39.7449C22.3227 39.745 19.5976 38.9015 17.2796 37.3212C14.9617 35.7409 13.155 33.4947 12.0881 30.8667C11.0213 28.2386 10.7421 25.3468 11.2859 22.5568C11.8296 19.7668 13.172 17.2041 15.1432 15.1926C17.1144 13.1811 19.6258 11.8112 22.36 11.2562C25.0941 10.7012 27.9282 10.986 30.5037 12.0746C33.0792 13.1632 35.2806 15.0066 36.8294 17.3718C38.3782 19.737 39.2048 22.5177 39.2048 25.3624C39.2008 29.1755 37.7146 32.8313 35.0723 35.5276C32.43 38.224 28.8473 39.7406 25.1105 39.7449Z" fill="#07303E"/>
<path d="M25.1105 4.83472C21.1315 4.83461 17.2419 6.03849 13.9335 8.29412C10.6251 10.5497 8.04651 13.7558 6.52379 17.5069C5.00107 21.2579 4.60263 25.3855 5.37886 29.3676C6.15508 33.3497 8.07111 37.0075 10.8846 39.8785C13.6982 42.7494 17.2828 44.7046 21.1853 45.4966C25.0878 46.2887 29.1328 45.8821 32.8089 44.3283C36.4849 42.7745 39.6268 40.1433 41.8373 36.7674C44.0479 33.3915 45.2277 29.4225 45.2276 25.3624C45.2215 19.92 43.1001 14.7023 39.3287 10.854C35.5573 7.00561 30.444 4.8409 25.1105 4.83472ZM25.1105 44.0319C21.4918 44.032 17.9544 42.9372 14.9455 40.8858C11.9367 38.8344 9.59151 35.9187 8.20664 32.5073C6.82176 29.0959 6.45936 25.342 7.16527 21.7204C7.87117 18.0989 9.61367 14.7722 12.1724 12.1612C14.7312 9.55014 17.9912 7.77198 21.5403 7.05157C25.0895 6.33116 28.7682 6.70085 32.1115 8.11389C35.4547 9.52693 38.3122 11.9199 40.3226 14.9901C42.333 18.0603 43.4061 21.6698 43.4061 25.3624C43.4002 30.3119 41.4707 35.057 38.0409 38.5569C34.6111 42.0568 29.961 44.0257 25.1105 44.0319Z" fill="#07303E"/>
<path d="M2.43139 25.3624C2.43407 19.9917 4.26435 14.7886 7.6095 10.6421C7.76141 10.4518 7.83336 10.2078 7.80963 9.96357C7.7859 9.7193 7.66842 9.49453 7.48284 9.33834C7.29726 9.18215 7.05866 9.10722 6.81913 9.12992C6.5796 9.15262 6.3586 9.2711 6.20438 9.4595C2.5221 14.0182 0.54061 19.76 0.611198 25.6672C0.681785 31.5744 2.79989 37.2652 6.59001 41.7309C6.74825 41.9172 6.97252 42.0317 7.21349 42.0492C7.45445 42.0667 7.69237 41.9859 7.87491 41.8244C8.05744 41.6629 8.16964 41.4341 8.18682 41.1882C8.20399 40.9423 8.12474 40.6995 7.9665 40.5133C4.39516 36.307 2.42994 30.9277 2.43139 25.3624Z" fill="#07303E"/>
<path d="M43.7891 9.1823C43.7117 9.0893 43.617 9.01279 43.5106 8.95713C43.4041 8.90146 43.288 8.86773 43.1688 8.85787C43.0495 8.84801 42.9296 8.8622 42.8157 8.89965C42.7019 8.93709 42.5964 8.99705 42.5053 9.07609C42.4141 9.15514 42.3392 9.25173 42.2846 9.36034C42.2301 9.46896 42.197 9.58747 42.1873 9.70913C42.1777 9.83078 42.1916 9.95318 42.2283 10.0693C42.265 10.1855 42.3237 10.2932 42.4012 10.3862C45.8432 14.5254 47.7499 19.7674 47.788 25.1958C47.826 30.6242 45.993 35.8936 42.6094 40.0826C42.5332 40.1768 42.476 40.2853 42.441 40.402C42.4059 40.5187 42.3938 40.6413 42.4052 40.7628C42.4166 40.8843 42.4513 41.0023 42.5074 41.1101C42.5635 41.2179 42.6399 41.3134 42.7322 41.3911C42.8244 41.4688 42.9308 41.5272 43.0452 41.5629C43.1595 41.5987 43.2797 41.6111 43.3988 41.5994C43.5178 41.5878 43.6335 41.5523 43.7391 41.4951C43.8448 41.4378 43.9383 41.3599 44.0145 41.2658C47.67 36.7399 49.6502 31.0468 49.6087 25.182C49.5673 19.3172 47.5068 13.6539 43.7876 9.1823H43.7891Z" fill="#07303E"/>
<path d="M17.2044 22.0524L14.7022 28.8497H16.8026L17.1273 27.7281H19.4624L19.796 28.8497H21.95L19.4484 22.0524H17.2044ZM17.5739 26.255L18.3024 23.8123L19.0378 26.255H17.5739Z" fill="#07303E"/>
<path d="M27.3675 25.2333C27.6413 25.1482 27.8887 24.9917 28.0852 24.7793C28.3296 24.4936 28.4606 24.1251 28.4526 23.7459C28.4568 23.5191 28.4129 23.2939 28.324 23.086C28.2351 22.878 28.1032 22.6921 27.9374 22.541C27.5238 22.1919 26.9949 22.0179 26.4594 22.0546H22.6083V28.8497H26.1596C26.4682 28.835 26.7758 28.8041 27.0813 28.7573C27.3528 28.7335 27.6176 28.658 27.8619 28.5348C28.1355 28.3759 28.3628 28.1455 28.5202 27.8673C28.683 27.5785 28.7662 27.2503 28.7612 26.9172C28.7752 26.529 28.6555 26.1479 28.4229 25.8403C28.1472 25.5236 27.7758 25.3099 27.3675 25.2333ZM24.6837 23.4331H25.5919C25.823 23.4103 26.0547 23.4693 26.2481 23.6004C26.3114 23.6583 26.3612 23.73 26.3938 23.8101C26.4264 23.8903 26.441 23.9768 26.4365 24.0635C26.4423 24.1541 26.4284 24.2449 26.3958 24.3295C26.3633 24.414 26.3128 24.4902 26.2481 24.5525C26.0505 24.6857 25.814 24.7454 25.5783 24.7214H24.6837V23.4331ZM26.4818 27.1982C26.2611 27.3484 25.9964 27.4163 25.7324 27.3904H24.6837V26.0118H25.7282C25.9932 25.9861 26.259 26.0505 26.4844 26.195C26.5544 26.2532 26.6103 26.3272 26.6477 26.4111C26.6851 26.4951 26.7029 26.5867 26.6998 26.6788C26.7039 26.7761 26.6864 26.8731 26.6486 26.9626C26.6109 27.052 26.5538 27.1316 26.4818 27.1955V27.1982Z" fill="#07303E"/>
<path d="M35.2533 25.6316C35.0451 25.3042 34.7508 25.0432 34.404 24.8786C33.7851 24.6023 33.1366 24.4009 32.4717 24.2785C32.2263 24.2478 31.9906 24.1624 31.7811 24.0284C31.7363 23.9943 31.6996 23.9504 31.6737 23.8998C31.6479 23.8492 31.6335 23.7933 31.6318 23.7363C31.6318 23.6634 31.6482 23.5915 31.6797 23.5261C31.7112 23.4606 31.7569 23.4034 31.8134 23.3588C31.9701 23.2441 32.1613 23.1892 32.3536 23.2037C32.4769 23.1893 32.6018 23.2009 32.7206 23.2378C32.8393 23.2747 32.9494 23.3362 33.0439 23.4183C33.1384 23.5004 33.2154 23.6014 33.27 23.7152C33.3247 23.8289 33.3559 23.9529 33.3616 24.0794L35.3007 23.9636C35.2842 23.6636 35.2045 23.3707 35.067 23.1049C34.9295 22.8391 34.7375 22.6068 34.5039 22.4236C33.9189 22.0634 33.24 21.8944 32.5581 21.9393C32.0005 21.9154 31.4438 22.0057 30.9209 22.2048C30.5582 22.3532 30.2469 22.608 30.0263 22.9371C29.8334 23.2296 29.7298 23.5739 29.7286 23.9264C29.7229 24.1756 29.7721 24.4228 29.8727 24.6499C29.9734 24.8769 30.1228 25.078 30.3099 25.2381C30.8806 25.6645 31.542 25.947 32.2401 26.0628C32.6149 26.1152 32.9742 26.2496 33.2935 26.4568C33.3629 26.5139 33.4192 26.5858 33.4585 26.6674C33.4978 26.7491 33.5191 26.8385 33.5209 26.9294C33.5195 27.0239 33.4973 27.1169 33.456 27.2015C33.4146 27.286 33.3552 27.36 33.282 27.418C33.0893 27.5703 32.8488 27.6458 32.6055 27.6304C32.4329 27.6402 32.2605 27.608 32.1026 27.5363C31.9447 27.4647 31.8058 27.3557 31.6973 27.2183C31.5483 26.998 31.4586 26.7417 31.4371 26.4749L29.4799 26.6002C29.5167 27.2386 29.7799 27.8415 30.2204 28.2963C30.6558 28.7406 31.4399 28.9629 32.5727 28.9633C33.1204 28.9813 33.666 28.8844 34.1756 28.6787C34.5789 28.5057 34.923 28.2146 35.1643 27.8423C35.3973 27.4856 35.5207 27.066 35.5187 26.6374C35.5232 26.2835 35.4313 25.9353 35.2533 25.6316Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon43;
