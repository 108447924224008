import * as icons from "../components/icons/features-icons";
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";

const FeatureIcons = ({ iconId }) => {
  let small = useMediaQuery(theme.breakpoints.down("sm"));
  let fill = "#07303E";
  let size = small ? 18 : 24;
  const featureIconsObject = {
    2606: <icons.FeatureIcon1 size={size} fill={fill} />,
    2732: <icons.FeatureIcon1 size={size} fill={fill} />,
    2733: <icons.FeatureIcon2 size={size} fill={fill} />,
    2734: <icons.FeatureIcon3 size={size} fill={fill} />,
    2689: <icons.FeatureIcon4 size={size} fill={fill} />,
    2688: <icons.FeatureIcon5 size={size} fill={fill} />,
    2735: <icons.FeatureIcon6 size={size} fill={fill} />,
    2736: <icons.FeatureIcon7 size={size} fill={fill} />,
    2737: <icons.FeatureIcon8 size={size} fill={fill} />,
    3191: <icons.FeatureIcon8 size={size} fill={fill} />,
    2738: <icons.FeatureIcon9 size={size} fill={fill} />,
    3209: <icons.FeatureIcon9 size={size} fill={fill} />,
    3192: <icons.FeatureIcon9 size={size} fill={fill} />,
    2690: <icons.FeatureIcon10 size={size} fill={fill} />,
    2691: <icons.FeatureIcon10 size={size} fill={fill} />,
    2618: <icons.FeatureIcon10 size={size} fill={fill} />,
    2739: <icons.FeatureIcon11 size={size} fill={fill} />,
    2740: <icons.FeatureIcon12 size={size} fill={fill} />,
    2741: <icons.FeatureIcon13 size={size} fill={fill} />,
    2692: <icons.FeatureIcon14 size={size} fill={fill} />,
    2693: <icons.FeatureIcon14 size={size} fill={fill} />,
    2694: <icons.FeatureIcon14 size={size} fill={fill} />,
    2695: <icons.FeatureIcon15 size={size} fill={fill} />,
    2742: <icons.FeatureIcon16 size={size} fill={fill} />,
    2696: <icons.FeatureIcon17 size={size} fill={fill} />,
    2697: <icons.FeatureIcon18 size={size} fill={fill} />,
    2744: <icons.FeatureIcon19 size={size} fill={fill} />,
    2743: <icons.FeatureIcon20 size={size} fill={fill} />,
    2748: <icons.FeatureIcon24 size={size} fill={fill} />,
    2698: <icons.FeatureIcon25 size={size} fill={fill} />,
    2749: <icons.FeatureIcon26 size={size} fill={fill} />,
    2750: <icons.FeatureIcon27 size={size} fill={fill} />,
    2751: <icons.FeatureIcon28 size={size} fill={fill} />,
    2752: <icons.FeatureIcon29 size={size} fill={fill} />,
    2753: <icons.FeatureIcon30 size={size} fill={fill} />,
    2699: <icons.FeatureIcon31 size={size} fill={fill} />,
    2700: <icons.FeatureIcon32 size={size} fill={fill} />,
    2701: <icons.FeatureIcon33 size={size} fill={fill} />,
    2702: <icons.FeatureIcon34 size={size} fill={fill} />,
    2703: <icons.FeatureIcon35 size={size} fill={fill} />,
    2754: <icons.FeatureIcon36 size={size} fill={fill} />,
    2755: <icons.FeatureIcon37 size={size} fill={fill} />,
    2756: <icons.FeatureIcon38 size={size} fill={fill} />,
    2757: <icons.FeatureIcon39 size={size} fill={fill} />,
    2758: <icons.FeatureIcon40 size={size} fill={fill} />,
    2759: <icons.FeatureIcon41 size={size} fill={fill} />,
    2704: <icons.FeatureIcon42 size={size} fill={fill} />,
    2705: <icons.FeatureIcon43 size={size} fill={fill} />,
    2706: <icons.FeatureIcon44 size={size} fill={fill} />,
    2707: <icons.FeatureIcon45 size={size} fill={fill} />,
    2708: <icons.FeatureIcon46 size={size} fill={fill} />,
    2709: <icons.FeatureIcon47 size={size} fill={fill} />,
    2761: <icons.FeatureIcon48 size={size} fill={fill} />,
    2710: <icons.FeatureIcon49 size={size} fill={fill} />,
    2711: <icons.FeatureIcon50 size={size} fill={fill} />,
    2762: <icons.FeatureIcon51 size={size} fill={fill} />,
    2763: <icons.FeatureIcon52 size={size} fill={fill} />,
    2764: <icons.FeatureIcon53 size={size} fill={fill} />,
    2712: <icons.FeatureIcon54 size={size} fill={fill} />,
    2713: <icons.FeatureIcon54 size={size} fill={fill} />,
    2666: <icons.FeatureIcon54 size={size} fill={fill} />,
    2714: <icons.FeatureIcon56 size={size} fill={fill} />,
    2715: <icons.FeatureIcon57 size={size} fill={fill} />,
    2716: <icons.FeatureIcon57 size={size} fill={fill} />,
    2717: <icons.FeatureIcon57 size={size} fill={fill} />,
    2718: <icons.FeatureIcon57 size={size} fill={fill} />,
    2765: <icons.FeatureIcon57 size={size} fill={fill} />,
    2766: <icons.FeatureIcon58 size={size} fill={fill} />,
    2768: <icons.FeatureIcon58 size={size} fill={fill} />,
    2771: <icons.FeatureIcon59 size={size} fill={fill} />,
    2773: <icons.FeatureIcon59 size={size} fill={fill} />,
    2776: <icons.FeatureIcon60 size={size} fill={fill} />,
    2778: <icons.FeatureIcon60 size={size} fill={fill} />,
    2719: <icons.FeatureIcon61 size={size} fill={fill} />,
    2721: <icons.FeatureIcon61 size={size} fill={fill} />,
    2783: <icons.FeatureIcon62 size={size} fill={fill} />,
    2784: <icons.FeatureIcon62 size={size} fill={fill} />,
    2725: <icons.FeatureIcon62 size={size} fill={fill} />,
    2726: <icons.FeatureIcon62 size={size} fill={fill} />,
    2727: <icons.FeatureIcon62 size={size} fill={fill} />,
    2782: <icons.FeatureIcon62 size={size} fill={fill} />,
    2785: <icons.FeatureIcon62 size={size} fill={fill} />,
    2786: <icons.FeatureIcon62 size={size} fill={fill} />,
    2781: <icons.FeatureIcon63 size={size} fill={fill} />,
    2787: <icons.FeatureIcon64 size={size} fill={fill} />,
    2788: <icons.FeatureIcon64 size={size} fill={fill} />,
    2789: <icons.FeatureIcon64 size={size} fill={fill} />,
    2790: <icons.FeatureIcon64 size={size} fill={fill} />,
    2728: <icons.FeatureIcon65 size={size} fill={fill} />,
    2791: <icons.FeatureIcon66 size={size} fill={fill} />,
    2729: <icons.FeatureIcon67 size={size} fill={fill} />,
    2609: <icons.FeatureIcon68 size={size} fill={fill} />,
    2610: <icons.FeatureIcon68 size={size} fill={fill} />,
    2760: <icons.FeatureIcon69 size={size} fill={fill} />,
    2730: <icons.FeatureIcon70 size={size} fill={fill} />,
    2731: <icons.FeatureIcon70 size={size} fill={fill} />,
    2907: <icons.FeatureIcon71 size={size} fill={fill} />,
    2747: <icons.FeatureIcon71 size={size} fill={fill} />,
    3195: <icons.FeatureIcon72 size={size} fill={fill} />,
    3193: <icons.FeatureIcon73 size={size} fill={fill} />,
    2905: <icons.FeatureIcon74 size={size} fill={fill} />,
    2745: <icons.FeatureIcon74 size={size} fill={fill} />,
    2898: <icons.FeatureIcon75 size={size} fill={fill} />,
    2897: <icons.FeatureIcon75 size={size} fill={fill} />,
    3194: <icons.FeatureIcon76 size={size} fill={fill} />,
    2890: <icons.FeatureIcon77 size={size} fill={fill} />,
    2906: <icons.FeatureIcon78 size={size} fill={fill} />,
    2746: <icons.FeatureIcon78 size={size} fill={fill} />,
    2991: <icons.FeatureIcon79 size={size} fill={fill} />,
    2992: <icons.FeatureIcon79 size={size} fill={fill} />,
    2993: <icons.FeatureIcon79 size={size} fill={fill} />,
    2994: <icons.FeatureIcon79 size={size} fill={fill} />,
    2995: <icons.FeatureIcon79 size={size} fill={fill} />,
    2996: <icons.FeatureIcon79 size={size} fill={fill} />,
    2997: <icons.FeatureIcon79 size={size} fill={fill} />,
    2998: <icons.FeatureIcon79 size={size} fill={fill} />,
    2999: <icons.FeatureIcon79 size={size} fill={fill} />,
    3000: <icons.FeatureIcon79 size={size} fill={fill} />,
    3130: <icons.FeatureIcon80 size={size} fill={fill} />,
    3131: <icons.FeatureIcon80 size={size} fill={fill} />,
    3132: <icons.FeatureIcon80 size={size} fill={fill} />,
    3133: <icons.FeatureIcon80 size={size} fill={fill} />,
    3134: <icons.FeatureIcon80 size={size} fill={fill} />,
    3135: <icons.FeatureIcon80 size={size} fill={fill} />,
    3136: <icons.FeatureIcon80 size={size} fill={fill} />,
    3137: <icons.FeatureIcon80 size={size} fill={fill} />,
    3054: <icons.FeatureIcon81 size={size} fill={fill} />,
    3055: <icons.FeatureIcon81 size={size} fill={fill} />,
    3056: <icons.FeatureIcon81 size={size} fill={fill} />,
    3057: <icons.FeatureIcon81 size={size} fill={fill} />,
    3058: <icons.FeatureIcon81 size={size} fill={fill} />,
    3060: <icons.FeatureIcon81 size={size} fill={fill} />,
    3062: <icons.FeatureIcon81 size={size} fill={fill} />,
    3063: <icons.FeatureIcon81 size={size} fill={fill} />,
    3188: <icons.FeatureIcon60 size={size} fill={fill} />,
    3105: <icons.FeatureIcon84 size={size} fill={fill} />,
    3106: <icons.FeatureIcon84 size={size} fill={fill} />,
    3107: <icons.FeatureIcon84 size={size} fill={fill} />,
    3109: <icons.FeatureIcon84 size={size} fill={fill} />,
    3110: <icons.FeatureIcon84 size={size} fill={fill} />,
    3111: <icons.FeatureIcon84 size={size} fill={fill} />,
    3186: <icons.FeatureIcon58 size={size} fill={fill} />,
    3190: <icons.FeatureIcon57 size={size} fill={fill} />,
    3235: <icons.FeatureIcon87 size={size} fill={fill} />,
    3237: <icons.FeatureIcon87 size={size} fill={fill} />,
    3189: <icons.FeatureIcon61 size={size} fill={fill} />,
    3187: <icons.FeatureIcon59 size={size} fill={fill} />,
    3184: <icons.FeatureIcon90 size={size} fill={fill} />,
  };
  return featureIconsObject[iconId] ?? null;
};

export default FeatureIcons;
