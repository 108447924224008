import React, { useState } from "react";
import {
  getModelOption,
  triggerUpdatedFields,
  triggerUpdatedForms,
  updatePaparazziImage,
  updatePaparazziVisImage,
} from "../store/actions/forms";
import {
  updateAnswers,
  updateHidden,
  updatePoints,
  updateformFields,
} from "../store/actions/forms";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckboxInput from "../components/CheckboxInput";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import DropDownMultipleDialogAutocomplete from "../components/DropDownMultipleDialogAutocomplete";
import DropdownAutocomplete from "../components/DropDownAutocomplete";
import HelpIcon from "@material-ui/icons/Help";
import HideIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InfoButton from "../components/InfoButton";
import MultiImageInput from "../components/MultiImageInput";
import FrameField from "../components/FrameField";
import { Page } from "react-pdf";
import RadioButtonInput from "../components/RadioButtonInput";
import SingleImageInput from "../components/SingleImageInput";
import Snackbar from "@material-ui/core/Snackbar";
import StaticText from "../components/StaticText";
import TextInput from "../components/TextInput";
import Toolbar from "@material-ui/core/Toolbar";
import UnhideIcon from "@material-ui/icons/Visibility";
import { connect } from "react-redux";
import { toggleNotification } from "../store/actions/notification";
import RadioButtonGroup from "../components/RadioButtonGroup";
import { useEffect } from "react";
import DistanceField from "../components/DistanceField";
import VideoUploader from "../components/VideoUploader";
// import placeholderImages from "../helpers/placeholderImages";

export const placeholderImages = {
  269: "Front Right_placeholder.png",
  638: "Front Up_placeholder.png",
  637: "Front Down_placeholder.png",
  642: "Rear Left_placeholder.png",
  641: "Trunk Closed_placeholder.png",
  639: "Front Left_placeholder.png",
  640: "Rear Right_placeholder.png",
  643: "Interior Door_placeholder.png",
  644: "Stearing wheel_placeholder.png",
  645: "Instrument Cluster_placeholder.png",
  646: "Dashboard Low_placeholder.png",
  647: "Center colnsol_placeholder.png",
  648: "Interior Front Chairs_placeholder.png",
  649: "Rear Seats_placeholder.png",
  891: "Rear Seats (2 Doors)_placeholder.png",
  650: "Driver View_placeholder.png",
  651: "Hood_placeholder.png",
  652: "Exterior Trunk_placeholder.png",
  284: "Passenger View_placeholder.png",
  283: "Trunk_placeholder.png",
  279: "Front Angle_placeholder.png",
  280: "Rear Angle_placeholder.png",
  285: "Driver View-1_placeholder.png",
  282: "3rd 3rd_placeholder.png",
};

const FormFields = (props) => {
  const {
    form,
    field,
    formFields,
    answers,
    hidden,
    updateAnswers,
    updateHidden,
    points,
    setOpenVisualizationDialog,
    setSide,
    setPoints,
    setSelectedOptionAnswerId,
    setSelectedOptionDdma,
    visualizationPointError,
    toggleNotification,
    getModelOption,
    triggerUpdatedForms,
    triggerUpdatedFields,
    setInvokerId,
    updateformFields,
    invokedFields,
    forms,
    appMessage,
    bodyTypeConfigInvokers,
    imageUrls,
    messageAnswerPointLinks,
    carId,
    paparazziImages,
    brands,
    setBrands,
    sizes,
    setSizes,
    setPaparazziUnLoaded,
    // setPaparazziAnswers,
    updatePaparazziImage,
    updatePaparazziVisImage,
    papaEditAction,
  } = props;
  const { type, required, tag } = field;
  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const createCurrentAnswersWithDefaultValues = () => {
    const { options } = field;
    let defaultValue =
      typeof options === "object"
        ? options.find((el) => el.default == true)
        : false;
    return defaultValue ? defaultValue.id.toString() : undefined;
  };

  function numberWithCommas(x) {
    if (x !== undefined) {
      return x?.replace(/[^0-9.]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return null;
    }
  }

  const getCurrentValues = (isImage, fieldID, isScore) => {
    if (answers[field.id]) {
      if (isScore) {
        return answers[field.id]?.score;
      } else if (isImage) {
        if (Array.isArray(answers[field.id])) {
          return answers[field.id][0].images;
        }
        return answers[field.id].images;
      } else if (Array.isArray(answers[field.id])) {
        return answers[field.id].map((el) => el.id || el.text);
      } else {
        if (
          type === "num" &&
          !(
            field.id == 58 ||
            field.id == 65 ||
            field.id == 72 ||
            field.id == 79
          )
        ) {
          return numberWithCommas(answers[field.id]?.text);
        } else {
          return answers[field.id].id || answers[field.id]?.text;
        }
        // // return answers[field.id].id !=null?answers[field.id].id: answers[field.id]?.text;
      }
    }
    return undefined;
  };

  const prefixSwitcher = (str, pref, old) => {
    if (str == undefined || str == 0 || str == "" || str == null) return "";
    var substr = pref.substr(0, pref.length - 1);
    var formaterswapper = str
      .replaceAll(" " + pref, "")
      .replaceAll(pref, "")
      .replaceAll(substr, "")
      .replaceAll(" " + substr, "")
      .replace(/[^0-9.]/g, "");

    console.log(formaterswapper, "mmmmm");
    if (formaterswapper == "") {
      return "";
    }

    if (str.endsWith(substr)) {
      var res = [...formaterswapper]
        .splice(0, formaterswapper.length - 1)
        .join("");
      if (res == "") return "";
      return (
        [...formaterswapper].splice(0, formaterswapper.length - 1).join("") +
        " " +
        pref
      );
    } else {
      return formaterswapper + " " + pref;
    }
  };

  const getVisualizationImage = () => {
    if (points?.[field.side]) {
      return [points[field.side]?.image];
    }
  };

  let defaultValue = createCurrentAnswersWithDefaultValues();

  const handelScoreErrorSuccess = () => {
    if (answers[field.id]?.score < 0 || answers[field.id]?.score > 100) {
      setOpenErr(true);
    } else {
      setOpen(false);
      setOpenErr(false);
    }
  };

  const handleChange = (event, value) => {
    let reducedAnswers = answers;
    let reducedHidden = hidden;
    if (!Array.isArray(value)) {
      // remove and add code for ddma (value is an array of objects) if necessary
      if (
        answers[value?.field?.id] !== undefined &&
        answers[value?.field?.id].option !== null &&
        answers[value?.field?.id].option !== undefined &&
        field?.id !== 227 && // (227,228) check if they are really a special case or does the same error occure elsewhere
        field?.id !== 228
      ) {
        let disInvokedInvokers = [];

        // answers undefined is for fields that havenot been answered yet and null is for dropdown fieds (that dont have a default answer or dropdown that is saved in text not option im not sure) and option undefined is for fieds that do not contain an option (text)
        let newFormFields = formFields.filter((element) => {
          let counter = 0;
          for (let i = 0; i < element.invokers.length; i++) {
            if (
              element.invokers[i] !== value.id &&
              (element.invokers[i] == answers[value?.field.id].id ||
                disInvokedInvokers.includes(element.invokers[i]))
            ) {
              counter++;
              if (answers[element.id]?.option?.invoker_option) {
                // adds removed invoker option to field removal condition # note that in order for this to work a field should not be higher in the tree order than its invoker
                disInvokedInvokers.push(answers[element.id]?.option.id);
              } else if (Array.isArray(answers[element.id])) {
                // adds disinvoked invoker for checkbox fields ( answer is Array )
                answers[element.id].map((answer) => {
                  if (answer?.option?.invoker_option) {
                    disInvokedInvokers.push(answer?.option.id);
                  }
                });
              }
            }
          }
          return counter > 0 ? false : true;
        });

        let newFormFieldsIds = newFormFields.map(({ id }) => id);
        //removed fields are any fields not in formFields
        let removedFieldsIds = formFields.filter((oldFormField) => {
          return !newFormFieldsIds.includes(oldFormField.id);
        });
        let newAnswers = [];
        let newHidden = [];

        for (let i = 0; i < removedFieldsIds.length; i++) {
          newAnswers = Object.keys(reducedAnswers).filter(
            (element) => removedFieldsIds[i]?.id != element
          );
          reducedAnswers = newAnswers.reduce((obj, key) => {
            obj[key] = answers[key];
            return obj;
          }, {});
          newHidden = Object.keys(reducedHidden).filter(
            (element) => removedFieldsIds[i]?.id != element
          );
          reducedHidden = newHidden.reduce((obj, key) => {
            obj[key] = hidden[key];
            return obj;
          }, {});
        }
        updateformFields({ carId, formId: form.id, formFields: newFormFields });
      }
    } else if (!event.target.checked) {
      let disInvokedInvokers = [];
      let newFormFields = formFields.filter((element) => {
        let counter = 0;
        for (let i = 0; i < element.invokers.length; i++) {
          if (
            element.invokers[i] == event.target.value ||
            disInvokedInvokers.includes(element.invokers[i])
          ) {
            counter++;
            if (answers[element.id]?.option?.invoker_option) {
              disInvokedInvokers.push(answers[element.id]?.option.id);
            }
          }
        }
        return counter > 0 ? false : true;
      });
      updateformFields({ carId, formId: form.id, formFields: newFormFields });
    }

    if (value?.option?.invoker_option) {
      setInvokerId(value.option.id);
    } else if (
      value[0] !== undefined && // type array
      value?.filter((option) => {
        return option.id === event.target.value;
      })[0]?.option?.invoker_option &&
      event.target.checked
    ) {
      // added for fields with array answers ("checkbox" i think it would work on ddma)
      // setInvokerId(value[0].option.id);
      setInvokerId(Number(event.target.value));
    } else {
      setInvokerId();
    }
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (field.id == "227") {
      getModelOption({ carId, formId: form.id, make: value.option?.id }); // ? buggs out in mobile only ???
    }
    if (reducedAnswers?.[field.id]?.text !== "" && value?.text == "") {
      // if a text field was answered then deleted entirely then remove the field from answers entirely instead of keeping it with an empty string ""
      delete reducedAnswers[field.id];
      updateAnswers({
        carId,
        formId: form.id,
        answers: Object.assign({}, reducedAnswers),
      });
    } else {
      updateAnswers({
        carId,
        formId: form.id,
        answers: Object.assign({}, reducedAnswers, {
          // reducedAnswers instead of answers?
          [field.id]: value,
        }),
      });
    }
    updateHidden({
      carId,
      formId: form.id,
      hidden: Object.assign({}, reducedHidden, {
        [field.id]: false,
      }),
    });
  };

  const handleHide = (value) => {
    setHide(value);
    delete answers[field.id];
    hidden[field.id] = value;
    updateHidden({ carId, formId: form.id, hidden: Object.assign({}, hidden) });
    updateAnswers({
      carId,
      formId: form.id,
      answers: Object.assign({}, answers),
    });
  };

  const renderHideButton = () =>
    required ? null : (
      <IconButton
        onClick={() => {
          handleHide(!hidden[field.id]);
        }}
      >
        {hide || hidden[field.id] ? (
          <HideIcon fontSize={"small"} color={"secondary"} />
        ) : (
          <UnhideIcon fontSize={"small"} color={"secondary"} />
        )}
      </IconButton>
    );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const renderInfoButton = () => {
    if (field.field_info)
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <IconButton
            onClick={() => {
              setShowInfo(true);
            }}
          >
            <HelpIcon color="primary" />
          </IconButton>

          <Dialog
            open={showInfo}
            onClose={() => {
              setShowInfo(false);
            }}
            aria-labelledby="form-dialog-title"
            fullScreen={true}
          >
            <AppBar style={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setShowInfo(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText>
                <Document
                  file={field.field_info.en}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      width={window.innerWidth}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ))}
                </Document>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      );
  };

  if (type === "stat") {
    return (
      <div
        style={{
          display: "flex",
          // border: "1px solid black",
          // borderRight: "none",
          // borderLeft: "none",
          backgroundColor: "#FDEDED",
          borderRadius: "3px",
        }}
      >
        <StaticText label={field.title} />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
      </div>
    );
  }

  if (type === "text") {
    return (
      <div style={{ display: "flex" }}>
        <TextInput
          placeholder={
            (field.id == 57 && "_ _ _/_ _  _ _ _") ||
            (field.id == 64 && "_ _ _/_ _  _ _ _") ||
            (field.id == 71 && "_ _ _/_ _  _ _ _") ||
            (field.id == 78 && "_ _ _/_ _  _ _ _")
          }
          id={field.id}
          // || field.id == 238     235
          disabled={hidden[field.id]}
          brands={brands}
          setBrands={setBrands}
          sizes={sizes}
          setSizes={setSizes}
          value={getCurrentValues() || ""}
          field={field}
          prefix={
            field.id == 235
              ? "cc"
              : field.id == 238
              ? "kWh"
              : field.id == 919 || field.id == 920 || field.id == 922
              ? " V"
              : field.id == 944 || field.id == 950
              ? "Km"
              : null
          }
          listOfOptions={
            field.id == 259 ||
            field.id == 260 ||
            field.id == 261 ||
            field.id == 262
              ? brands
              : field.id == 57 ||
                field.id == 64 ||
                field.id == 71 ||
                field.id == 78
              ? sizes
              : {}
          }
          onChange={handleChange}
          label={field.title}
          answerObject={answers[field.id] || {}}
          invokedFields={invokedFields}
          inputProps={
            field.id == 226
              ? { style: { textTransform: "uppercase" } }
              : (field.id == 235 ||
                  field.id == 238 ||
                  field.id == 919 ||
                  field.id == 920 ||
                  field.id == 922 ||
                  field.id == 944 ||
                  field.id == 950) && {
                  style: { direction: "ltr" },
                }
          }
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "dist") {
    return (
      <div style={{ display: "flex" }}>
        <DistanceField
          disabled={hidden[field.id]}
          value={getCurrentValues() || ""}
          field={field}
          onChange={handleChange}
          label={field.title}
          answerObject={answers[field.id] || {}}
          invokedFields={invokedFields}
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "date") {
    return (
      <div style={{ display: "flex" }}>
        <TextInput
          disabled={hidden[field.id]}
          value={getCurrentValues() || ""}
          defaultValue={defaultValue}
          field={field}
          onChange={handleChange}
          label={field.title}
          type={"date"}
          answerObject={answers[field.id] || {}}
          InputLabelProps={{
            shrink: true,
          }}
          invokedFields={invokedFields}
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "num") {
    return (
      <div style={{ display: "flex" }}>
        <TextInput
          disabled={hidden[field.id]}
          value={getCurrentValues(false, "num") || ""}
          inputProps={{
            pattern: "d*",
            maxlength:
              (field.id == 58 ||
                field.id == 65 ||
                field.id == 72 ||
                field.id == 79) &&
              4,
          }}
          field={field}
          // type={"tel"}
          answerObject={answers[field.id] || {}}
          onChange={handleChange}
          label={field.title}
          invokedFields={invokedFields}
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "rb") {
    return (
      <div style={{ display: "flex" }}>
        <RadioButtonInput
          disabled={hidden[field.id]}
          value={getCurrentValues() || defaultValue || ""}
          field={field}
          answerObject={answers[field.id] || {}}
          onChange={handleChange}
          options={field.options}
          formTitle={field.title}
          invokedFields={invokedFields}
          isdone={form?.is_done}
          renderHide={renderHideButton()}
          carId={carId}
          title={field.title}
          image={answers?.[field.id]?.images?.[0] ?? []}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={true}></InfoButton>
        )}
      </div>
    );
  }

  if (type === "rbg") {
    return (
      <div style={{ display: "flex" }}>
        <RadioButtonGroup
          value={getCurrentValues() || defaultValue || ""}
          field={field}
          carId={carId}
          // answerObject={answers[field.id] || {}}
          onChange={handleChange}
          options={field.options}
          fieldTitle={field.title}
          invokedFields={invokedFields}
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={true}></InfoButton>
        )}
      </div>
    );
  }

  if (type === "cb") {
    return (
      <div style={{ display: "flex" }}>
        <CheckboxInput
          disabled={hidden[field.id]}
          value={getCurrentValues() || [defaultValue] || []}
          field={field}
          answerObject={answers[field.id] || []}
          onChange={handleChange}
          options={field.options}
          formTitle={field.title}
          invokedFields={invokedFields}
          isdone={form?.is_done}
          renderHide={renderHideButton()}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
      </div>
    );
  }

  if (type === "dd") {
    return (
      <div style={{ display: "flex" }}>
        <DropdownAutocomplete
          disabled={hidden[field.id]}
          value={getCurrentValues() || defaultValue || ""}
          field={field}
          onChange={handleChange}
          answerObject={answers[field.id] || {}}
          options={field.options}
          formTitle={field.title}
          invokedFields={invokedFields}
          isdone={form?.is_done}
          renderHide={renderHideButton()}
          bodyTypeConfigInvokers={bodyTypeConfigInvokers}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }
  if (type === "ddma") {
    return (
      <div style={{ display: "flex" }}>
        <DropDownMultipleDialogAutocomplete
          disabled={hidden[field.id]}
          value={getCurrentValues() || [defaultValue] || []}
          onChange={handleChange}
          withImage={field.with_image}
          fieldId={field.id}
          field={field}
          options={field.options}
          formTitle={field.title}
          answerObject={answers[field.id] || []}
          defaultValue={defaultValue}
          points={points}
          side={field.side}
          setOpenVisualizationDialog={setOpenVisualizationDialog}
          setSide={setSide}
          setSelectedOptionAnswerId={setSelectedOptionAnswerId}
          setSelectedOptionDdma={setSelectedOptionDdma}
          setPoints={props.setPoints}
          visualizationPointError={visualizationPointError}
          toggleNotification={props.toggleNotification}
          invokedFields={invokedFields}
          isdone={form?.is_done}
          triggerUpdatedFields={triggerUpdatedFields}
          renderHide={renderHideButton()}
          imageUrl={imageUrls?.[field.id]}
          messageAnswerPointLink={messageAnswerPointLinks?.[field.id]}
          formId={form.id}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "ff") {
    return (
      <div style={{ display: "flex" }}>
        <FrameField
          disabled={hidden[field.id]}
          onChange={handleChange}
          field={field}
          options={field.options}
          formTitle={field.title}
          answerArray={answers[field.id] || []}
          invokedFields={invokedFields}
          isdone={form?.is_done}
        />
        {field?.field_info && (
          <InfoButton infoFiles={field.field_info} field={false}></InfoButton>
        )}
        {renderHideButton()}
      </div>
    );
  }

  if (type === "img") {
    return (
      <SingleImageInput
        updatePaparazziImage={updatePaparazziImage}
        updatePaparazziVisImage={updatePaparazziVisImage}
        // setPaparazziAnswers={setPaparazziAnswers}
        papaEditAction={papaEditAction}
        setPaparazziUnLoaded={setPaparazziUnLoaded}
        paparazziImage={
          answers[field.id]?.images?.[0]?.includes("Papa-") ||
          points[field?.side]?.image?.includes("Papa-")
        }
        title={field.title}
        fieldId={field.id}
        field={field}
        appMessage={appMessage}
        imageUrl={imageUrls?.[field.id]}
        value={
          field.id in placeholderImages
            ? // placeholderImages(field.id)
              getCurrentValues(true) ||
              getVisualizationImage() || [
                require(`../images/${placeholderImages?.[field.id]}`),
                // require(`../images/${placeholderImages(field.id)}`),
              ] || [require("../images/img_placeholder.png")]
            : getCurrentValues(true) ||
              getVisualizationImage() || [
                require("../images/img_placeholder.png"),
              ]
        }
        setVisualisationImage={setPoints}
        points={points}
        onChange={handleChange}
        invokedFields={invokedFields}
        isdone={form?.is_done}
        triggerUpdatedForms={triggerUpdatedForms}
        forms={forms}
        formId={form.id}
        packageImages={paparazziImages[field.id]}
      />
    );
  }

  if (type === "mimg") {
    return (
      <MultiImageInput
        title={field.title}
        fieldId={field.id}
        field={field}
        value={
          getCurrentValues(true) || require("../images/img_placeholder.png")
        }
        onChange={handleChange}
        invokedFields={invokedFields}
        isdone={form?.is_done}
      />
    );
  }

  if (type === "vid") {
    return <VideoUploader isExt={true} isInt={false} form={form} video={getCurrentValues(true) || []} />;
  }
  return null;
};

const mapStateToProps = (state) => ({
  answers: state.forms.answers,
  hidden: state.forms.hidden,
  points: state.forms.points,
  formFields: state.forms.formFields,
  invokedFields: state.forms.invokedFields,
  forms: state.forms.forms,
  bodyTypeConfigInvokers: state.car.car.body_type_config?.invokers,
  paparazziImages: state.forms.paparazziImages,
});

const mapDispatchToProps = (dispatch) => ({
  updateAnswers(payload) {
    dispatch(updateAnswers(payload));
  },
  updateformFields(payload) {
    dispatch(updateformFields(payload));
  },
  updateHidden(payload) {
    dispatch(updateHidden(payload));
  },
  setPoints(payload) {
    dispatch(updatePoints(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  getModelOption(payload) {
    dispatch(getModelOption(payload));
  },
  triggerUpdatedForms(payload) {
    dispatch(triggerUpdatedForms(payload));
  },
  triggerUpdatedFields(payload) {
    dispatch(triggerUpdatedFields(payload));
  },
  updatePaparazziImage(payload) {
    dispatch(updatePaparazziImage(payload));
  },
  updatePaparazziVisImage(payload) {
    dispatch(updatePaparazziVisImage(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormFields);
