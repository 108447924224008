import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  carCardGridItem: {
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  mainPaper: {
    backgroundColor: "unset",
    borderRadius: "0",
    boxShadow: "0px 4px 4px rgba(15, 71, 90, 0.4)",
    minWidth: "290px",
    [theme.breakpoints.down("sm")]: {
      margin: "7.5px 0",
    },
  },
  imagePaper: {
    width: "100%",
    height: "235px",
    transform: "none",
    borderRadius: "0",
    [theme.breakpoints.up("md")]: {
      height: "343px",
    },
    [theme.breakpoints.up("lg")]: {
      height: 235,
    },
  },
  lastTypography: {
    width: "50%",
    height: "18px",
    transform: "none",
    margin: "0px 8px 9px",
    [theme.breakpoints.up("md")]: {
      margin: "0px 8px 24px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px 8px 9px",
    },
  },
  appointmentButton: {
    width: "100%",
    height: "40.5px",
    transform: "none",
    margin: "18px 0px 14px ",
    [theme.breakpoints.up("xs")]: {
      margin: "18px 0px 20px ",
    },
    [theme.breakpoints.up("md")]: {
      margin: "25px 0px 0px ",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "18px 0px 14px ",
    },
  },
}));

const CarCardSkeleton = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={12}
      lg={6}
      xl={4}
      className={classes.carCardGridItem}
    >
      <Paper className={classes.mainPaper}>
        <div style={{ display: "flex", position: "relative" }}>
          <Skeleton className={classes.imagePaper} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton
            style={{
              width: "90px",
              height: "24px",
              transform: "none",
              margin: "12px 16px 5px",
            }}
          />
          <Skeleton
            style={{
              width: "70px",
              height: "11px",
              transform: "none",
              margin: "12px 16px 5px",
            }}
          />
        </div>

        <Grid container>
          <Grid item xs={12} md={6} lg={12}>
            <Skeleton
              style={{
                width: "75%",
                height: "21px",
                transform: "none",
                margin: "0px 16px 5px",
              }}
            />
            <Skeleton
              style={{
                width: "85%",
                height: "18px",
                transform: "none",
                margin: "0px 16px 5px",
              }}
            />
            <div
              style={{
                display: "flex",
                borderBottom: "0.5px solid rgb(196, 196, 196)",
                margin: "0 8px",
              }}
            >
              <Skeleton className={classes.lastTypography} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={12} style={{ padding: "0px 16px 3px" }}>
            <Skeleton className={classes.appointmentButton} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CarCardSkeleton;
