import React from "react";

const FeatureIcon79 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon79Clip0)">
<path d="M16.6417 5.60626C16.0464 5.60626 15.5636 6.08908 15.5636 6.68439V7.87033C15.5636 8.46565 16.0464 8.94846 16.6417 8.94846C17.237 8.94846 17.7199 8.46565 17.7199 7.87033V6.68439C17.7199 6.08908 17.237 5.60626 16.6417 5.60626Z" fill="#07303E"/>
<path d="M12.3292 5.60626C11.7339 5.60626 11.2511 6.08908 11.2511 6.68439V7.87033C11.2511 8.46565 11.7339 8.94846 12.3292 8.94846C12.9245 8.94846 13.4074 8.46565 13.4074 7.87033V6.68439C13.4074 6.08908 12.9245 5.60626 12.3292 5.60626Z" fill="#07303E"/>
<path d="M39.7465 3.77657C35.9865 3.77657 32.9276 6.83546 32.9276 10.5954V43.1812C32.9276 45.7522 30.836 47.8438 28.265 47.8438H20.2261C17.6551 47.8438 15.5635 45.7522 15.5635 43.1812V40.4298H18.7979C19.3932 40.4298 19.876 39.947 19.876 39.3516V35.8455C23.1338 34.9556 25.5362 31.9716 25.5362 28.4356V13.4766C25.5362 12.8813 25.0534 12.3985 24.4581 12.3985H22.0323V1.07813C22.0323 0.482814 21.5495 0 20.9542 0H8.01673C7.42142 0 6.93861 0.482814 6.93861 1.07813V12.3985H4.51282C3.91751 12.3985 3.43469 12.8813 3.43469 13.4766V28.4356C3.43469 31.9716 5.83704 34.9555 9.09486 35.8455V39.3516C9.09486 39.947 9.57768 40.4298 10.173 40.4298H13.4074V43.1812C13.4074 46.9412 16.4663 50.0001 20.2262 50.0001H28.2651C32.0251 50.0001 35.084 46.9412 35.084 43.1812V10.5954C35.084 8.02443 37.1756 5.93282 39.7466 5.93282C42.3176 5.93282 44.4092 8.02443 44.4092 10.5954V38.6177C44.4092 39.213 44.892 39.6958 45.4873 39.6958C46.0826 39.6958 46.5655 39.213 46.5655 38.6177V10.5954C46.5654 6.83546 43.5065 3.77657 39.7465 3.77657ZM9.09476 2.15625H19.876V12.3985H9.09476V2.15625ZM5.59085 28.4355V14.5547H23.38V28.4356C23.38 31.4824 20.9013 33.961 17.8546 33.961H11.1163C8.06947 33.9609 5.59085 31.4823 5.59085 28.4355ZM11.251 38.2734V36.1172H17.7198V38.2734H11.251Z" fill="#07303E"/>
<path d="M15.5635 25.8448V23.3621L19.2091 21.8583C19.6127 21.6918 19.8761 21.2983 19.8761 20.8617V18.6515C19.8761 18.0562 19.3932 17.5734 18.7979 17.5734C18.2026 17.5734 17.7198 18.0562 17.7198 18.6515V20.1402L15.5635 21.0297V17.6812C15.5635 17.0859 15.0807 16.6031 14.4854 16.6031C13.8901 16.6031 13.4073 17.0859 13.4073 17.6812V22.6273C13.4073 22.6306 13.4073 22.634 13.4073 22.6373V22.8999L11.251 22.1452V20.8617C11.251 20.2664 10.7682 19.7836 10.1729 19.7836C9.5776 19.7836 9.09479 20.2664 9.09479 20.8617V22.9102C9.09479 23.3683 9.38434 23.7765 9.81666 23.9278L13.4073 25.1845V25.8449C12.1525 26.2899 11.251 27.4883 11.251 28.8938C11.251 30.6772 12.702 32.1282 14.4854 32.1282C16.2688 32.1282 17.7198 30.6772 17.7198 28.8938C17.7198 27.4884 16.8183 26.2899 15.5635 25.8448ZM14.4854 29.9719C13.891 29.9719 13.4073 29.4882 13.4073 28.8937C13.4073 28.2993 13.891 27.8156 14.4854 27.8156C15.0799 27.8156 15.5635 28.2993 15.5635 28.8937C15.5635 29.4883 15.0799 29.9719 14.4854 29.9719Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon79Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon79;
