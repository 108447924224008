import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import MaterialLink from "@material-ui/core/Link";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import Button from "../../components/Button";

import VerticalTabs from "../../components/VerticalTabs";
import HorizontalTabs from "../../components/HorizontalTabs";

import {
  getForms,
  getSubforms,
  resetNotification,
  checkVisFormsPoints,
  triggerUpdatedForms,
  fetchFieldsCount,
  filterForms,
  calculateEagleEye,
  clearSavedFormData,
  updateCarPoints,
  totalTime,
} from "../../store/actions/forms";
import {
  updateCarForm,
  getCar,
  updateCarVisuals,
  submitCarToQC,
  updateDtcForm,
  updateMissedMapping,
  getCarInvoked,
} from "../../store/actions/car";
import Forms from "../../wrappers/Forms";
import { toggleNotification } from "../../store/actions/notification";
import { object } from "prop-types";
import theme from "../../theme";

import { useTranslation } from "react-i18next";
import EagleEyeForm from "../../wrappers/EagleEyeForm";
import Save from "../../components/icons/Save";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  breadcrumbsPaper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: 12,
    paddingBottom: 12,
  },
  Link: {
    cursor: "auto",
    color: "rgba(0,0,0,0.87)",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  carId: {
    fontWeight: "600",
  },
}));

function AddNewCar(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  ////////////////////// Demo Code //////////////////////
  const searchParams = new URLSearchParams(document.location.search);
  const indexOfForm = searchParams.get("formIndex");
  const [formIndex, setFormIndex] = useState(indexOfForm ?? 0);
  ////////////////////// Demo Code //////////////////////
  const [currentForms, setCurrentForms] = useState([]);
  const [tillIndex, setTillIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visualizationPointError, setVisualizationPointError] = useState([]);
  const {
    getForms,
    getSubforms,
    forms,
    filterForms,
    user,
    updated_forms,
    updateCarForm,
    updateDtcForm,
    answers,
    hidden,
    getCar,
    features,
    points,
    updateCarVisuals,
    edit,
    toggleNotification,
    submitCarToQC,
    history,
    car,
    carGenericFeatures,
    resetNotification,
    getCarInvoked,
    checkVisFormsPoints,
    triggerUpdatedForms,
    fetchFieldsCount,
    calculateEagleEye,
    clearSavedFormData,
    saved,
    updateCarPoints,
    totalTime,
    fetchingForms,
    fetchingSubforms,
    updateMissedMapping,
  } = props;

  const [redirect, setRedirect] = useState(""); //state to set redirect path
  const [formFieldsCount, setFormFieldsCount] = useState({});
  const [filtedForm, setfiltedForm] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [subformloading, setSubformloading] = useState(true);
  const [timeFrom, setTimeFrom] = useState("");
  const [paparazziUnLoaded, setPaparazziUnLoaded] = useState([]);

  const carId = props.match.params.id;
  async function fetchData(submit) {
    setLoading(true);
    let index = 0;
    await getForms({
      carId,
      callback: async (forms) => {
        await getCar({
          id: carId,
          callback: async (car) => {
            for (let i = 0; i < forms.length; i++) {
              let originalForm = forms[i];
              for (let j = 0; j < car.car_forms.length; j++) {
                let { form, is_done } = car.car_forms[j];
                if (originalForm.id === form) {
                  originalForm["is_done"] = is_done;
                  index++;
                }
              }
            }
            setCurrentForms([...forms]);
            if (!edit) {
              if (formIndex + 1 > forms.length - 1) {
                setFormIndex(formIndex);
              } else if (submit) {
                setFormIndex(formIndex + 1);
              }
              setTillIndex(index);
            } else {
              setTillIndex(forms.length - 1);
            }
            setLoading(false);
            setSubmitting(false);
          },
        });
        await getSubforms({
          id: carId,
          formIndex: forms[formIndex].id,
        });
        // await fetchFieldsCount(carId);
      },
    });
    setFetchedData(!fetchedData);
    setLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      let index = 0;
      await getForms({
        carId,
        callback: async (forms) => {
          await getCar({
            id: carId,
            callback: async (car) => {
              for (let i = 0; i < forms.length; i++) {
                let originalForm = forms[i];
                for (let j = 0; j < car.car_forms.length; j++) {
                  let { form, is_done } = car.car_forms[j];
                  if (originalForm.id === form) {
                    originalForm["is_done"] = is_done;
                    index++;
                  }
                }
              }
              setCurrentForms([...forms]);
              if (!edit) {
                if (index > forms.length - 1) {
                  //indexer auto next
                  setFormIndex(forms.length - 1);
                } else {
                  setFormIndex(index);
                }
                setTillIndex(index);
              } else {
                setTillIndex(forms.length - 1);
              }
              setLoading(false);
            },
          });
          // await fetchFieldsCount(carId);
        },
      });
    }
    fetchData();
  }, [getForms, getCar, carId, edit]); //, forms[formIndex]?.id]);

  useEffect(() => {
    // taken out from fetchData() because it needs to be called on formIndex change but calling fetchData() on formIndex change will setFormIndex to the last form if (!edit)
    if (forms?.length !== 0) {
      // if statement was added with forms in the dependency array because in edit at first entry forms is empty and an error occures
      getSubforms({
        id: carId,
        formIndex: forms[formIndex]?.id || 0,
      });
      fetchFieldsCount({
        carId,
        callback: (response) => {
          setFormFieldsCount(response);
          var newforms = [];
          for (let i in response) {
            var empty = response[i].combined_fields;
            // let formindex =forms.indexOf((ele) => ele.title == i))
            // forms.forEach((element) => element.title==i?return i /;
            if (empty) newforms.push(forms.filter((ele) => ele.title == i)[0]);
          }
          if (!filtedForm) {
            setCurrentForms(newforms);
            filterForms(newforms);
            setfiltedForm(true);
          }
        },
      });
    }
  }, [forms, formIndex]);

  const calcTimePerForm = () => {
    let totalTimePerForm = 0;
    let newTimeFrom = 0;
    let newTimeTo = 0;
    let timeTo = new Date().toLocaleString("en-GB");
    newTimeFrom += parseInt(timeFrom?.substr(12, 2)) * 3600;
    newTimeFrom += parseInt(timeFrom?.substr(15, 2)) * 60;
    newTimeFrom += parseInt(timeFrom?.substr(18, 2));
    newTimeTo += parseInt(timeTo?.substr(12, 2)) * 3600;
    newTimeTo += parseInt(timeTo?.substr(15, 2)) * 60;
    newTimeTo += parseInt(timeTo?.substr(18, 2));
    totalTimePerForm = newTimeTo - newTimeFrom;
    console.log("vvvvvvvvvvvv", timeFrom, "vvvvvvvvvvvv", timeTo);
    return totalTimePerForm;
  };

  // useEffect(() => {
  //   // maybe move this to the fetch data useEffect to prevent fetching on submitting and being an async function
  //   if (saved[carId]) {
  //     // setSubmitting(true); //????
  //     Object.keys(saved[carId]).map((savedForm) => {
  //       setSubmitting(true); //????
  //       // should it be async to save forms on the end of another only and move isSubmitting(false to the .then)
  //       // answers and hidden should exist togather so add hidden to saved on answers change nad viceversa
  //       const answersToSubmit = saved[carId][savedForm].answers;
  //       const hiddenToSubmit = saved[carId][savedForm].hidden;
  //       const pointsToSubmit = saved[carId][savedForm].points;
  //       const thisForm = forms.filter((form) => {
  //         return form.id == savedForm;
  //       });

  //       if (savedForm != 15 && savedForm != 2) {
  //         if (answersToSubmit && hiddenToSubmit) {
  //           updateCarForm({
  //             id: carId,
  //             body: {
  //               answers: answersToSubmit,
  //               form: savedForm,
  //               hidden: hiddenToSubmit,
  //             },
  //             callback: (payload) => {
  //               if (payload.status == "success") {
  //                 if (pointsToSubmit) {
  //                   updateCarVisuals({
  //                     id: carId,
  //                     body: {
  //                       answers: pointsToSubmit,
  //                       form: savedForm,
  //                       position: thisForm[0].is_exterior_visual
  //                         ? "ext"
  //                         : "int",
  //                     },
  //                     callback: () => {
  //                       clearSavedFormData({ carId, formId: savedForm });
  //                       fetchData(false); //????
  //                     },
  //                   });
  //                 } else {
  //                   clearSavedFormData({ carId, formId: savedForm });
  //                 }
  //               } else {
  //                 setSubmitting(false); //????
  //               }
  //             },
  //           });
  //         } else {
  //           updateCarVisuals({
  //             id: carId,
  //             body: {
  //               answers: pointsToSubmit,
  //               form: savedForm,
  //               position: thisForm[0].is_exterior_visual ? "ext" : "int",
  //             },
  //             callback: () => {
  //               clearSavedFormData({ carId, formId: savedForm });
  //               fetchData(false); //????
  //             },
  //           });
  //         }
  //       } else if (savedForm == 15) {
  //         //DTC
  //         // dont know where to add clear (clearSavedFormData({ carId, formId: savedForm });) no final callback
  //         let formAnswers = { ...answersToSubmit };
  //         delete formAnswers["table"];
  //         updateCarForm({
  //           id: carId,
  //           body: {
  //             answers: formAnswers,
  //             form: savedForm,
  //             hidden: hiddenToSubmit,
  //           },
  //           callback: () => {
  //             setSubmitting(false);
  //           },
  //         });
  //         updateDtcForm({
  //           carId,
  //           answers: answersToSubmit["table"],
  //           callback: (response) => {
  //             clearSavedFormData({ carId, formId: savedForm }); // not quite right but this is the important part // but this will probably cause a problem if the answer was not available
  //             fetchData(false);
  //           },
  //         });
  //       } else if (savedForm == 2) {
  //         // EE
  //         let savedCombinedAnswers = [];
  //         Object.values(answersToSubmit).map((subformAnswers) => {
  //           savedCombinedAnswers = savedCombinedAnswers.concat(subformAnswers);
  //         });
  //         updateCarPoints({
  //           carId,
  //           points: savedCombinedAnswers,
  //           callback: () => {
  //             calculateEagleEye({
  //               carId: carId,
  //               form: savedForm,
  //               callback: (payload) => {
  //                 if (payload.status == "success") {
  //                   clearSavedFormData({ carId, formId: savedForm });
  //                   fetchData(false);
  //                 } else {
  //                   setSubmitting(false);
  //                 }
  //               },
  //             });
  //           },
  //         });
  //       }
  //     });
  //     fetchData(false); //????
  //     setSubmitting(false);
  //   }
  // }, []);

  useEffect(() => {
    resetNotification([]);
    getCarInvoked({ carId });
  }, []);

  const handleSubmitting = (event, submit) => {
    // add remove saved forms on submit callback
    // event to know which button was clicked
    const form = forms[formIndex];
    const {
      // is_fields_selection,
      is_exterior_visual,
      is_interior_visual,
    } = form;
    setSubmitting(true);

    let missingPoints = [];
    let subAndEx = event?.currentTarget?.value === "Submit & Exit";

    if (form.id == 15) {
      // clearSavedFormData({ carId, formId: form.id });
      // dtc form does not clear saved due to its seperate submits where clearing saved will cause problem in the other
      // i think, solve later
      let formAnswers = { ...answers };
      delete formAnswers["table"];
      updateCarForm({
        id: carId,
        body: { answers: formAnswers, form: form.id, hidden },
        callback: () => {
          setSubmitting(false);
          // clearSavedFormData({ carId, formId: form.id, from: "DTC_FORM" });
          handleSubmitQcOrExit(subAndEx);
        },
      });
      updateDtcForm({
        carId,
        answers: answers["table"],
        callback: (response) => {
          console.log("qqqqqqqqqqqqq", answers["table"], "qqqqqq", response);
          updateMissedMapping({
            answers: answers["table"],
            carId,
            callback: (response) => {},
          });
          clearSavedFormData({ carId, formId: form.id });
          // console.log("xxxxxxxxxxxxxxxxx", response);
          fetchData(submit);
        },
      });
    } else if (is_exterior_visual || is_interior_visual) {
      if (
        (is_exterior_visual && !is_interior_visual) ||
        (!is_exterior_visual && is_interior_visual)
      ) {
        let sideOptions = [];
        let answerOptions = [];
        let passedOptions = [];
        let passedAnswers = [];
        for (const side in points) {
          sideOptions = Object.keys(points[side].points);
          passedOptions.push(...sideOptions);
        }

        Object.keys(answers).map((key) => {
          if (
            Array.isArray(answers[key]) &&
            (answers[key][0]?.field_type === "ddma" ||
              answers[key][0]?.field?.type == "ddma")
          ) {
            answerOptions = Object.keys(answers[key]).map((el) => {
              return answers[key][el];
            });
            let faultyOptions = answerOptions.filter((el) => {
              if (typeof el === "object") {
                // if (el?.option !== undefined) {
                if (el?.option) {
                  // if (el?.option?.default) {
                  return el.option.default !== true;
                }
              }
            });
            faultyOptions.map((el) => {
              if (
                (el?.unique_answer_id || el?.answerPointLink) &&
                el?.option?.with_image &&
                el.side
              ) {
                passedAnswers.push(
                  el.unique_answer_id?.toString() ||
                    el.answerPointLink.toString()
                  // el?.answerPointLink?.toString() ??
                  //   el.unique_answer_id?.toString()
                );
              }
            });
          }
        });
        for (const faultyAnswer in passedAnswers) {
          if (!passedOptions.includes(passedAnswers[faultyAnswer])) {
            missingPoints.push(passedAnswers[faultyAnswer]);
          }
        }

        if (missingPoints.length !== 0) {
          // error message
          toggleNotification({
            variant: "error",
            open: true,
            message: "Answers missing visualization point allocation",
          });
          setLoading(false);
          setSubmitting(false);
          setVisualizationPointError(missingPoints);
        } else {
          setVisualizationPointError([]);
          updateCarForm({
            id: carId,
            body: { answers: answers, form: form.id, hidden },
            callback: (payload) => {
              if (payload.status == "success") {
                // updateCarVisuals was called in the callback so that the forms with visualization flag/s dont get saved twice in carforminfo
                updateCarVisuals({
                  id: carId,
                  body: {
                    answers: points,
                    answerPointLink: payload.response.answerPointLink,
                    form: form.id,
                    position: is_exterior_visual ? "ext" : "int",
                  },
                  callback: () => {
                    clearSavedFormData({ carId, formId: form.id });
                    totalTime({
                      car_id: carId,
                      officer: user.user_id,
                      time_from: timeFrom,
                      time_to: new Date().toLocaleString("en-GB"),
                      time_per_form: calcTimePerForm(),
                      form: currentForms[formIndex].id,
                      edited: currentForms[formIndex].is_done,
                      callback: () => {
                        setTimeFrom(new Date().toLocaleString("en-GB"));
                      },
                    });
                    handleSubmitQcOrExit(subAndEx);
                    fetchData(submit);
                  },
                });
              } else {
                setSubmitting(false);
              }
            },
          });
        }
      } else {
        updateCarForm({
          id: carId,
          body: { answers: answers, form: form.id, hidden },
          callback: (payload) => {
            // updateCarVisuals was called in the callback so that the forms with visualization flag/s dont get saved twice in carforminfo
            if (payload.status == "success") {
              updateCarVisuals({
                id: carId,
                body: {
                  answers: points,
                  answerPointLink: payload.response.answerPointLink,
                  form: form.id,
                },
                callback: () => {
                  clearSavedFormData({ carId, formId: form.id });
                  totalTime({
                    car_id: carId,
                    officer: user.user_id,
                    time_from: timeFrom,
                    time_to: new Date().toLocaleString("en-GB"),
                    time_per_form: calcTimePerForm(),
                    form: currentForms[formIndex].id,
                    edited: currentForms[formIndex].is_done,
                    callback: () => {
                      setTimeFrom(new Date().toLocaleString("en-GB"));
                    },
                  });
                  handleSubmitQcOrExit(subAndEx);
                  fetchData(submit);
                },
              });
            } else {
              setSubmitting(false);
            }
          },
        });
      }
    } else {
      if (currentForms[formIndex].id !== 2) {
        updateCarForm({
          id: carId,
          body: { answers: answers, form: form.id, hidden },
          callback: (payload) => {
            if (payload.status == "success") {
              clearSavedFormData({ carId, formId: form.id });
              totalTime({
                car_id: carId,
                officer: user.user_id,
                time_from: timeFrom,
                time_to: new Date().toLocaleString("en-GB"),
                time_per_form: calcTimePerForm(),
                form: currentForms[formIndex].id,
                edited: currentForms[formIndex].is_done,
                callback: () => {
                  setTimeFrom(new Date().toLocaleString("en-GB"));
                },
              });
              handleSubmitQcOrExit(subAndEx);
              fetchData(submit);
            } else {
              setSubmitting(false);
            }
          },
        });
      } else {
        // eeSubmit();
        let submitCombinedAnswers = [];
        [85, 86, 87, 88, 89, 90].forEach(function (element) {
          if (answers[element] != undefined) {
            submitCombinedAnswers = submitCombinedAnswers.concat(
              answers[element]
            );
          }
        });
        updateCarPoints({
          carId,
          points: submitCombinedAnswers,
          callback: () => {
            calculateEagleEye({
              carId: carId,
              form: form.id,
              callback: (payload) => {
                if (payload.status == "success") {
                  clearSavedFormData({ carId, formId: form.id });

                  totalTime({
                    car_id: carId,
                    officer: user.user_id,
                    time_from: timeFrom,
                    time_to: new Date().toLocaleString("en-GB"),
                    time_per_form: calcTimePerForm(),
                    form: currentForms[formIndex].id,
                    edited: currentForms[formIndex].is_done,
                    callback: () => {
                      setTimeFrom(new Date().toLocaleString("en-GB"));
                    },
                  });
                  handleSubmitQcOrExit(subAndEx);
                  fetchData(false);
                  fetchData(submit);
                } else {
                  setSubmitting(false);
                }
              },
            });
          },
        });
      }
    }

    const handleSubmitQcOrExit = (subAndEx) => {
      //set redirect state to vehicle detail page if sumbit and exit was selected
      if (subAndEx && missingPoints.length === 0) {
        setRedirect("/car/" + carId);
      }

      if (formIndex === forms.length - 1 && car.finished_at === null) {
        missingPoints.length === 0 && // added missing ponts and moved its declaration to top because submit to qc would run an error but still exits making it unobvios to the officer thus trunk form would look like it had its answers deleted
          checkVisFormsPoints({
            // check if all vis points are entered
            carId,
            callback: (response) => {
              triggerUpdatedForms({ updated_forms: response }); // highlite forms
              response.length === 0
                ? submitCarToQC({
                    // submit to qc
                    carId: carId,
                    body: { finished_at: new Date().toISOString() },
                    callback: (response) => {
                      if (response) {
                        totalTime({
                          car_id: carId,
                          officer: user.user_id,
                          time_from: timeFrom,
                          time_to: new Date().toLocaleString("en-GB"),
                          time_per_form: calcTimePerForm(),
                          form: currentForms[formIndex].id,
                          edited: currentForms[formIndex].is_done,
                          callback: () => {
                            setTimeFrom(new Date().toLocaleString("en-GB"));
                          },
                        });
                        // will not work in case of websocket failure
                        history.push("/listing-requests/");
                      } else {
                        totalTime({
                          car_id: carId,
                          officer: user.user_id,
                          time_from: timeFrom,
                          time_to: new Date().toLocaleString("en-GB"),
                          time_per_form: calcTimePerForm(),
                          form: currentForms[formIndex].id,
                          edited: currentForms[formIndex].is_done,
                          callback: () => {
                            setTimeFrom(new Date().toLocaleString("en-GB"));
                          },
                        });
                        // will not work in case of websocket failure
                        history.push("/listing-requests/");
                      }
                    },
                  })
                : toggleNotification({
                    variant: "error",
                    open: true,
                    message: t("Please check and resubmitt highlighted forms"),
                  });
            },
          });
      }
    };
    setfiltedForm(false);
  };
  useEffect(() => {
    // if (tillIndex  formIndex + 1) setFormIndex();

    if (car)
      if (!edit) {
        let index = 0;
        for (let i = 0; i < forms.length; i++) {
          let originalForm = forms[i];
          for (let j = 0; j < car?.car_forms?.length ?? 0; j++) {
            // on new cars there is no car_forms yet check not looping on inside code
            let { form, is_done } = car.car_forms[j];
            if (originalForm?.id === form) {
              originalForm["is_done"] = is_done;
              index++;
            }
          }
        }
        setCurrentForms([...forms]);
        setTillIndex(index);
      }
  }, [forms]);

  useEffect(() => {
    setTimeFrom(new Date().toLocaleString("en-GB"));
  }, [formIndex, submitting]);

  return (
    <div>
      <Paper className={classes.breadcrumbsPaper} square>
        <Breadcrumbs
          separator={
            theme.direction === "ltr" ? (
              <NavigateNextIcon fontSize="small" />
            ) : (
              <NavigateBefore fontSize="small" />
            )
          }
          aria-label="breadcrumb"
        >
          <MaterialLink className={classes.Link} color="textPrimary">
            <Link
              style={{ textDecoration: "none" }}
              className={classes.Link}
              to="/"
            >
              {t("Car Listings")}
            </Link>
          </MaterialLink>
          {edit && (
            <MaterialLink className={classes.Link} color="textPrimary">
              <Link
                style={{ textDecoration: "none" }}
                className={classes.Link}
                to={"/car/" + carId}
              >
                {t("Car Details")}
              </Link>
            </MaterialLink>
          )}
          <Typography
            className={classes.carId}
            color="textPrimary"
            // children={`${edit ? t("Edit Car") : t("Add New Car")} ID #${carId}`}
            children={
              (edit ? t("Edit Car") : t("Add New Car")) + `ID # ${carId}`
            }
          />
        </Breadcrumbs>
      </Paper>
      {loading ? (
        <LinearProgress />
      ) : (
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Hidden smDown>
              <Grid item md={12}>
                <Paper square>
                  <div
                    style={{
                      padding: "1em 1.5em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      children={
                        edit
                          ? t("Edit Car Information")
                          : t("Adding New Car Information")
                      }
                      variant={"h6"}
                    />
                    <div style={{ width: "80%" }}>
                      <LinearProgress
                        color={"primary"}
                        value={
                          tillIndex === 0
                            ? 0
                            : (tillIndex / currentForms.length) * 100
                        }
                        style={{ height: 15, borderRadius: 4 }}
                        variant={"determinate"}
                      />
                    </div>
                  </div>
                  <Divider />
                  <VerticalTabs
                    updated_forms={updated_forms}
                    // tabs={forms}
                    tabs={currentForms}
                    index={formIndex}
                    tillIndex={tillIndex}
                    onChange={(value) => {
                      setFormIndex(value);
                    }}
                  >
                    {currentForms[formIndex] &&
                      (currentForms[formIndex].id !== 2 ? (
                        <Forms
                          // kk
                          setSubformloading={setSubformloading}
                          subformloading={subformloading}
                          loadingForm={loading}
                          submitting={submitting}
                          fetchedData={fetchedData}
                          form={currentForms[formIndex]}
                          visualizationPointError={visualizationPointError}
                          setPaparazziUnLoaded={setPaparazziUnLoaded}
                        />
                      ) : (
                        <EagleEyeForm
                          carId={carId}
                          loadingForm={loading}
                          submitting={submitting}
                          setSubformloading={setSubformloading}
                          subformloading={subformloading}
                          formId={2}
                        />
                      ))}
                  </VerticalTabs>
                </Paper>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12}>
                <HorizontalTabs
                  updated_forms={updated_forms}
                  tabs={currentForms}
                  index={formIndex}
                  tillIndex={tillIndex}
                  onChange={(value) => {
                    setFormIndex(value);
                  }}
                >
                  <Paper style={{ padding: "0.5em" }}>
                    <LinearProgress
                      color={"primary"}
                      value={
                        tillIndex === 0
                          ? 0
                          : (tillIndex / currentForms.length) * 100
                      }
                      style={{ height: 15, borderRadius: 4 }}
                      variant={"determinate"}
                    />
                  </Paper>
                  <br />
                  <Paper square style={{ padding: "1em 0" }}>
                    {currentForms[formIndex] &&
                      (currentForms[formIndex].id !== 2 ? (
                        <Forms
                          setSubformloading={setSubformloading}
                          subformloading={subformloading}
                          loadingForm={loading}
                          submitting={submitting}
                          fetchedData={fetchedData}
                          form={currentForms[formIndex]}
                          // form={forms[formIndex]}
                          // onSubmitting={handleSubmitting}
                          visualizationPointError={visualizationPointError}
                          setPaparazziUnLoaded={setPaparazziUnLoaded}
                        />
                      ) : (
                        <EagleEyeForm
                          carId={carId}
                          loadingForm={loading}
                          submitting={submitting}
                          setSubformloading={setSubformloading}
                          subformloading={subformloading}
                          formId={2}
                        />
                      ))}
                  </Paper>
                </HorizontalTabs>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                children={
                  car.finished_at === null && formIndex === forms.length - 1
                    ? t("Submit to qc")
                    : t("Submit Form")
                }
                variant={"contained"}
                fullWidth
                color={"primary"}
                loading={submitting}
                disabled={subformloading || paparazziUnLoaded?.length > 0}
                onClick={(event) => {
                  handleSubmitting(event, true);
                }}
                value={"Submit Form"}
              />
            </Grid>
            {/* a second button that only renders in edit mode, value was added to get its value 
              in event.currentTarget.vale, also currentTarget was used instead of target because 
              the value attribute is set on button and target returns the span inside the button 
              component where as currentTarget returns the component that the function was called in*/}
            <Grid item xs={12} sm={6}>
              {edit ? (
                <Button
                  children={t("Submit & Exit")}
                  variant={"contained"}
                  fullWidth
                  color={"primary"}
                  disabled={subformloading || paparazziUnLoaded?.length > 0}
                  loading={submitting || paparazziUnLoaded?.length > 0}
                  onClick={(event) => {
                    handleSubmitting(event, true);
                  }}
                  value={"Submit & Exit"}
                />
              ) : null}
            </Grid>
          </Grid>
          {/* redirect only works incase redirect state changes from empty string which in the code 
          only happens when submit and exit is selected */}
          {redirect !== "" ? <Redirect to={redirect} /> : null}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchingForms: state.forms.fetchingForms,
  fetchingSubforms: state.forms.fetchingSubforms,
  fetchingCar: state.car.fetchingCar,
  forms: state.forms.forms,
  answers: state.forms.answers,
  hidden: state.forms.hidden,
  features: state.forms.features,
  points: state.forms.points,
  user: state.auth.user,
  car: state.car.car,
  carGenericFeatures: state.forms.carGenericFeatures,
  updated_forms: state.forms.updated_forms,
  invokedFields: state.forms.invokedFields,
  saved: state.forms.saved,
});

const mapDispatchToProps = (dispatch) => ({
  getForms(payload) {
    dispatch(getForms(payload));
  },
  getSubforms(payload) {
    dispatch(getSubforms(payload));
  },
  updateCarForm(payload) {
    dispatch(updateCarForm(payload));
  },
  updateDtcForm(payload) {
    dispatch(updateDtcForm(payload));
  },
  updateMissedMapping(payload) {
    dispatch(updateMissedMapping(payload));
  },
  getCar(payload) {
    dispatch(getCar(payload));
  },

  updateCarVisuals(payload) {
    dispatch(updateCarVisuals(payload));
  },
  submitCarToQC(payload) {
    dispatch(submitCarToQC(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  resetNotification(payload) {
    dispatch(resetNotification(payload));
  },
  getCarInvoked(payload) {
    dispatch(getCarInvoked(payload));
  },
  checkVisFormsPoints(payload) {
    dispatch(checkVisFormsPoints(payload));
  },
  triggerUpdatedForms(payload) {
    dispatch(triggerUpdatedForms(payload));
  },
  fetchFieldsCount(payload) {
    dispatch(fetchFieldsCount(payload));
  },
  filterForms(payload) {
    dispatch(filterForms(payload));
  },
  calculateEagleEye(payload) {
    dispatch(calculateEagleEye(payload));
  },
  clearSavedFormData(payload) {
    dispatch(clearSavedFormData(payload));
  },
  updateCarPoints(payload) {
    dispatch(updateCarPoints(payload));
  },
  totalTime(payload) {
    dispatch(totalTime(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewCar)
);
