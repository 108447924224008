import mergeImages from "merge-images-v2";
import wm from "../images/WaterMark.png";
import { urlToObject } from "./imagesFunctions";

// function getBase64(file, onLoadCallback) {
//   return new Promise(function (resolve, reject) {
//     var reader = new FileReader();
//     reader.onload = function () {
//       resolve(reader.result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

// const urlToObject = async (image) => {
//   const response = await fetch(image);
//   // here image is url/location of image
//   const blob = await response.blob();
//   const file = new File([blob], "image.png", { type: blob.type });
//   console.log(file);
//   return getBase64(file);
// };

const imageMerger = async (imagesObj, exterior, big_body) => {
  // imagesObj?null:imagesObj = {} // neccisarry ??
  // imagesObj = {
  //     carImage:'.png',
  //     bgImage:'.png',
  //     shadow:'.png', // may be empty ??
  // }
  return new Promise(function (resolve, reject) {
    // var promise = getBase64(imagesObj.carImage);
    // promise.then(async function (carImageb64) {

    // var promise = getBase64(imagesObj.carImage);
    async function merge(carImageb64) {
      var imagesToMergeOptions = [];
      if (imagesObj?.bgImage) {
        let fetchedBg = await urlToObject(imagesObj?.bgImage);
        console.log("ttttttttttttttt5555555", fetchedBg);
        imagesToMergeOptions.push({
          src: fetchedBg,
          x: 0,
          y: 0,
          width: 2000,
          height: 1500,
        });
      }
      // probably wrong should always have a bgImage
      if (imagesObj?.shadow) {
        let fetchedShadow = await urlToObject(imagesObj?.shadow);
        imagesToMergeOptions.push({
          src: fetchedShadow,
          x: 0,
          y: 0,
          width: 2000,
          height: 1500,
        });
      }
      imagesToMergeOptions.push({
        src: carImageb64,
        x: exterior ? (!big_body ? 200 : 0) : 0,
        y: exterior ? (!big_body ? 150 : 0) : 0,
        width: exterior ? (!big_body ? 1600 : 2000) : 2000,
        height: exterior ? (!big_body ? 1200 : 1500) : 1500,
      });
      imagesToMergeOptions.push({
        src: wm,
        x: 0,
        y: 0,
        width: 2000,
        height: 1500,
      });
      mergeImages([...imagesToMergeOptions]).then((b64) => {
        resolve(b64);
      });
    }
    merge(imagesObj.carImage);
  });
};

export default imageMerger;
