import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Switch, Route } from "react-router-dom";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import cookies from "js-cookie";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import MenuIcon from "@material-ui/icons/Menu";

import CarListings from "./CarListings/CarListings";
import Earnings from "./Earnings/Earnings";
import EntryRequests from "./ListingRequests/ListingRequests";
import AddNewCar from "./CarListings/AddNewCar";
import CarDetailsTest from "./CarListings/CarDetailsProto";
import CarVisProto from "./CarListings/CarVisProto";
import ViewImg from "../components/imageviewProto";
import Visualization from "../wrappers/CosmeticConditionProto";
import InspectionReport from "../wrappers/InspectionReportProto";
import WebViewMessageReceiver from "../wrappers/WebViewMessageReceiver";

import { logout } from "../store/actions/auth";
import Logo from "../Markabte_English_Logo 2.svg";
import UserPlaceholder from "../images/user_placeholder.svg";
import { updateLanguage } from "../store/actions/language";
import {
  socketConnect,
  getPageTimeStamp,
  getUnseenNotifications,
} from "../store/actions/websockets";

import { useTranslation } from "react-i18next";

import ListingRequestsIcon from "../components/icons/ListingRequests";
import CarListingsIcon from "../components/icons/CarListing";
import LanguageNewIcon from "../components/icons/Language";
import SignOutIcon from "../components/icons/Signout";
import Login from "./Login";
import MarkabteLogoEn from "../components/icons/MarkabteLogoEn";
import MarkabteLogoAr from "../components/icons/MarkabteLogoAr";
import Handle404 from "../components/Handle404";
import NavBar from "../components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Theme from "../theme";
import Tour from "../wrappers/Tour";
import PaparazziTester from "./PaparazziTester/PaparazziTester";
import PapaContainer from "./PaparazziTester/PapaContainer";
import CPD from "./ImageEditors/CPD";
import ExtBgEditor from "./ImageEditors/ExtBgEditor";
import ImageDownloader from "./ImageEditors/ImageDownloader";
import ReportsGenerator from "../components/ReportsGenerator";
import TimeSlots from "../components/TimeSlots";
import ReportSender from "../components/ReportSender";
import CarHistory from "../components/CarHistory";

const drawerWidthDesktop = 254;
const drawerWidthMobile = 231;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: "white",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMobile,
    },
    background: "white",
    boxShadow: "5px 0px 14px rgba(7, 48, 62, 0.3)",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidthDesktop,
      minHeight: "90px",
    },
  },
  contentWithDrawer: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidthDesktop,
    },
  },
  contentNoDrawer: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  selected: {
    background: "rgba(196,196,196,0.5)",
    color: "#fff",
  },
  logoListItemRoot: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuuButtonRoot: {
    padding: 0,
  },
  listItemGutters: {
    paddingLeft: "25px",
  },
  listItemIconRoot: {
    width: "28px",
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
    paddingRight: "11.5px",
  },
  userListItemGutters: {
    padding: "25px 14px 0",
  },
  userListItemGuttersMdUp: {
    padding: "20px 0 0",
  },
  backdropRoot: {
    backgroundColor: "rgba(33,33,33,0.85)",
  },
  toolbarGutters: {
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "68px",
    },
  },
  ListItemRoot: {
    padding: "14px 16px 14px",
  },
  bigScreenFullName: {
    color: "#07303E",
    fontSize: "20px",
    lineHeight: "21px",
    marginLeft: "14px",
    padding: "0 0 20px ",
  },
  mobFullName: {
    color: "#07303E",
    fontSize: "14px",
    lineHeight: "21px",
    marginLeft: "14px",
  },
}));

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

const Dashboard = (props) => {
  if (props.user !== null) {
    var {
      user: { role, full_name_en, full_name_ar, user_id },
    } = props;
  }

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fullName, setFullName] = useState("");
  const {
    history,
    logout,
    updateLanguage,
    language,
    socketConnect,
    getPageTimeStamp,
    getUnseenNotifications,
    unseenIncRequests,
    unseenCarListings,
  } = props;
  const smDown = useMediaQuery(Theme.breakpoints.down("sm"));
  const [isVisualization, setIsVisualization] = useState(false);
  const theme = useTheme();
  const mainRef = useRef();

  useEffect(() => {
    if (!cookies.get("token")) {
      history.push("/login");
    }
  }, [history]);

  let offset = 0;

  if (
    history.location.pathname.includes("listing-requests") ||
    history.location.pathname.includes("/car-edit") ||
    history.location.pathname.includes("/car-form")
  ) {
    // offset is indicator height (48px) * position in list
    offset = 0;
  } else if (
    history.location.pathname === "/" ||
    history.location.pathname.includes("/car/")
  ) {
    offset = 48;
  }

  // const [tabIndicatorTop, setTabIndicatorTop] = useState(offset);

  // useEffect(() => {
  //   let lang = localStorage.getItem("language");
  //   if (lang === null) {
  //     localStorage.setItem("language", "en");
  //   }
  //   var htmlTag = document.getElementsByTagName("html");
  //   htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
  // }, []);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let language = localStorage.getItem("language");
    if (language === null) localStorage.setItem("language", "ar");
    language == "ar" ? setFullName(full_name_ar) : setFullName(full_name_en);
  }, []);

  useEffect(() => {
    if (props.user) {
      socketConnect(role);
      getPageTimeStamp({ user_id: user_id });
      getUnseenNotifications({ user_id: user_id });
    }
  }, [props.user]);

  const handleListItemClick = (path, event) => {
    history.push(path);
    setOpenDrawer(false);
    // setTabIndicatorTop(
    //   (event.currentTarget.id - 1) * event.currentTarget.clientHeight
    // );
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const renderDrawerList = () => {
    const { location } = props;

    let selected = "cars";
    if (location.pathname.includes("earnings")) {
      selected = "earnings";
    } else if (
      location.pathname.includes("listing-requests") ||
      location.pathname.includes("/car-edit") ||
      location.pathname.includes("/car-form")
    ) {
      selected = "requests";
    }
    if (location.pathname.includes("generateAllCarsReports")) {
      selected = "generateAllCarsReports";
    }
    if (location.pathname.includes("ccc")) {
      selected = "ccc";
    }
    return (
      <div>
        {/* redirects to another app if not operations account */}
        {role !== "dispatcher" && role !== "officer" ? (
          role === "dealer owner" || role === "dealer staff" ? (
            <Redirect to="/redirect-dealers" />
          ) : (
            <Redirect to="/redirect-user" />
          )
        ) : null}
        <Tabs
          orientation="vertical"
          TabIndicatorProps={{
            // style: { height: "48px", top: tabIndicatorTop, width: "3px" },
            style: { height: "48px", top: offset, width: "3px" },
          }}
        >
          <List disablePadding>
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "requests"}
              id={1}
              onClick={handleListItemClick.bind(this, "/listing-requests")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <ListingRequestsIcon size="24" fill="#07303E" />
                {/* <img src={ListingRequestsIcon} alt="listing_req_icon" /> */}
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Listing Requests")}
              </ListItemText>
              {unseenIncRequests > 0 ? (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <Typography align="center">{unseenIncRequests}</Typography>
                </div>
              ) : null}
            </ListItem>
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "cars"}
              id={2}
              onClick={handleListItemClick.bind(this, "/")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />

                {/* <img
                  src={CarListingsIcon}
                  alt="car_listing_icon"
                  style={{ width: "30px" }}
                /> */}
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Car Listings")}
              </ListItemText>
              {unseenCarListings > 0 ? (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <Typography align="center">{unseenCarListings}</Typography>
                </div>
              ) : null}
            </ListItem>

            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "generateAllCarsReports"}
              id={6}
              onClick={handleListItemClick.bind(
                this,
                "/generateAllCarsReports"
              )}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Reports Generator")}
              </ListItemText>
            </ListItem>

            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "timeSlots"}
              id={6}
              onClick={handleListItemClick.bind(this, "/timeSlots")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Time Slot")}
              </ListItemText>
            </ListItem>

            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "send-report"}
              id={6}
              onClick={handleListItemClick.bind(this, "/send-report")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Send Report")}
              </ListItemText>
            </ListItem>

            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "car-history"}
              id={6}
              onClick={handleListItemClick.bind(this, "/car-history")}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <CarListingsIcon size="28" fill="#07303E" />
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Car History")}
              </ListItemText>
            </ListItem>
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "language"}
              id={3}
              onClick={() => {
                language === "en" ? updateLanguage("ar") : updateLanguage("en");
                localStorage.getItem("language") === "ar"
                  ? localStorage.setItem("language", "en")
                  : localStorage.setItem("language", "ar");
                window.location.reload();
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <LanguageNewIcon size="24" fill="#07303E" />

                {/* <img src={LanguageNewIcon} alt="language_icon" /> */}
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {t("Language")}
              </ListItemText>
            </ListItem>
            <ListItem
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "Logout"}
              id={4}
              onClick={() => {
                logout();
                handleListItemClick.bind(this, "/login");
                history.push("/login");
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <SignOutIcon size="24" fill="#07303E" />

                {/* <img src={SignOutIcon} alt="sign_out_icon" /> */}
              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {" "}
                {t("Sign out")}
              </ListItemText>
            </ListItem>
            {/* <ListItem
              onClick={() => setOpen(true)}
              classes={{
                selected: classes.selected,
                gutters: classes.listItemGutters,
              }}
              button
              selected={selected === "eagle eye"}
              id={5}
              // onClick={() => {}}
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                <SignOutIcon size='24' fill='#07303E' />

              </ListItemIcon>
              <ListItemText style={{ color: "#07303E" }}>
                {" "}
                {t("eagle eye")}
              </ListItemText>
            </ListItem> */}
            {/* <PopupCard
              open={open}
              setOpen={setOpen}
              title="title"
              action="action"
            ></PopupCard> */}
          </List>
        </Tabs>
      </div>
    );
  };

  const renderMdUpDrawer = () => {
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        anchor="left"
        open={window.location.pathname == "/"}
      >
        <div>
          <ListItem classes={{ root: classes.ListItemRoot }}>
            <ListItemIcon
              classes={{
                selected: classes.selected,
                root: classes.logoListItemRoot,
              }}
              style={{ margin: "auto" }}
              onClick={() => {
                props.history.push("/");
              }}
            >
              {theme.direction === "rtl" ? (
                <MarkabteLogoAr style={{ width: "140px" }} />
              ) : (
                <MarkabteLogoEn style={{ width: "155px" }} />
              )}
            </ListItemIcon>
          </ListItem>
          <hr
            style={{
              height: "1px",
              border: "0px",
              "background-image":
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.15) 100%)",
              margin: "0 0 10px 0px",
            }}
          ></hr>
        </div>
        {renderDrawerList()}
      </Drawer>
    );
  };

  const renderMdDownDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "white" }}>
          <ListItem classes={{ gutters: classes.userListItemGutters }}>
            <ListItemIcon
              children={<img src={UserPlaceholder} alt="user_placeholder" />}
              classes={{ selected: classes.selected }}
            ></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                // style: {
                //   color: "#07303E",
                //   fontSize: "14px",
                //   lineHeight: "21px",
                //   marginLeft: "14px",
                // },
                className: classes.mobFullName,
              }}
            >
              {fullName}
              <div>
                {role === "dispatcher" ? (
                  <text
                    style={{
                      color: "#626465",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    {t("Dispatcher")}
                  </text>
                ) : (
                  <ListItemText>{t("Officer")}</ListItemText>
                )}
              </div>
            </ListItemText>
          </ListItem>

          <hr
            style={{
              height: "1px",
              border: "0px",
              "background-image":
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.15) 100%)",
              margin: "20px 0px",
            }}
          ></hr>
        </div>
        {renderDrawerList()}
      </Drawer>
    );
  };

  const hideAppBar = () => {
    setIsVisualization(true);
  };
  const showAppBar = () => {
    setIsVisualization(false);
  };

  return (
    <div>
      <WebViewMessageReceiver />
      {!isVisualization &&
        (window.location.pathname == "/" || smDown ? (
          <AppBar
            position="static"
            elevation={1}
            className={classNames(classes.appBar)}
          >
            <Toolbar
              className={classNames(classes.toolbar)}
              classes={{ gutters: classes.toolbarGutters }}
              style={{
                justifyContent: "space-between",
              }}
            >
              <Hidden mdUp>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    /* remove if fails menue card results*/ marginLeft: "-3px",
                  }}
                >
                  <IconButton
                    classes={{ root: classes.menuuButtonRoot }}
                    onClick={handleOpenDrawer}
                  >
                    <MenuIcon style={{ fill: "#0c6885" }} />
                  </IconButton>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <MarkabteLogoAr style={{ width: "130px" }} />
                  ) : (
                    <MarkabteLogoEn style={{ width: "150px" }} />
                  )}
                </div>
              </Hidden>
              <Hidden smDown>
                <ListItem
                  classes={{ gutters: classes.userListItemGuttersMdUp }}
                >
                  <ListItemIcon
                    children={
                      <img
                        src={UserPlaceholder}
                        alt="user_placeholder"
                        style={{ padding: "0 0 20px 0" }}
                      />
                    }
                    classes={{ selected: classes.selected }}
                    onClick={() => {
                      if (role === "dispatcher") {
                        history.push("/paparazzi/");
                      }
                    }}
                  ></ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.bigScreenFullName,
                    }}
                  >
                    {fullName}
                    <div>
                      {role === "dispatcher" ? (
                        <text
                          style={{
                            color: "#626465",
                            fontSize: "16px",
                            lineHeight: "18px",
                          }}
                        >
                          {t("Dispatcher")}
                        </text>
                      ) : (
                        <ListItemText>{t("Officer")}</ListItemText>
                      )}
                    </div>
                  </ListItemText>
                </ListItem>
              </Hidden>
            </Toolbar>
            <hr
              style={{
                height: "1px",
                border: "0px",
                "background-image":
                  "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%)",
                margin: "-3px 0 0",
              }}
            ></hr>
          </AppBar>
        ) : (
          <NavBar />
        ))}
      <Hidden smDown>{renderMdUpDrawer()}</Hidden>
      <Hidden mdUp>{renderMdDownDrawer()}</Hidden>

      <main
        className={clsx({
          [classes.contentWithDrawer]: window.location.pathname == "/",
          [classes.contentNoDrawer]: window.location.pathname != "/",
        })}
      >
        <Switch>
          <Route path={"/img-download"}>
            <ImageDownloader />
          </Route>
          <Route path={"/bg-editor"}>
            <ExtBgEditor />
          </Route>
          <Route path={"/cpd"}>
            <CPD />
          </Route>
          <Route path={"/earnings"}>
            <Earnings />
          </Route>
          <PrivateRoute path={"/listing-requests"}>
            <EntryRequests />
          </PrivateRoute>
          <Route path={"/car-form/:id"}>
            <AddNewCar />
          </Route>
          <Route path={"/car-edit/:id"}>
            <AddNewCar edit />
          </Route>
          <Route path={"/car/:id/inspection-report"}>
            <InspectionReport hideAppBar={hideAppBar} showAppBar={showAppBar} />
          </Route>
          <Route path={"/car/:id"}>
            <CarDetailsTest
              hideAppBar={hideAppBar}
              showAppBar={showAppBar}
              mainRef={mainRef}
            />
          </Route>
          <Route path={"/carVis/:id"}>
            <CarVisProto
              hideAppBar={hideAppBar}
              showAppBar={showAppBar}
              mainRef={mainRef}
            />
          </Route>
          <Route path="/imageview">
            <ViewImg showAppBar={showAppBar} />
          </Route>
          <Route path="/visualization">
            <Tour showAppBar={showAppBar} />
          </Route>
          <Route path={"/something-went-wrong"}>
            <Handle404 />
          </Route>
          <Route exact path={"/404"}>
            <Handle404 />
          </Route>
          <Route exact path={"/paparazzi/view"}>
            <PapaContainer />
          </Route>
          <Route exact path={"/paparazzi"}>
            <PaparazziTester />
          </Route>
          <Route path={"/generateAllCarsReports"}>
            <ReportsGenerator />
          </Route>
          <Route path={"/timeSlots"}>
            <TimeSlots />
          </Route>
          <Route path={"/send-report"}>
            <ReportSender />
          </Route>
          <Route path={"/car-history"}>
            <CarHistory />
          </Route>
          <Route path={"/"}>
            <CarListings />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  language: state.language.language,
  unseenIncRequests: state.websockets.unseenIncRequests,
  unseenCarListings: state.websockets.unseenCarListings,
});

const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(logout());
  },
  updateLanguage(payload) {
    dispatch(updateLanguage(payload));
  },
  socketConnect(payload) {
    dispatch(socketConnect(payload));
  },
  getPageTimeStamp(payload) {
    dispatch(getPageTimeStamp(payload));
  },
  getUnseenNotifications(payload) {
    dispatch(getUnseenNotifications(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
