import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleCompress, handleCompressMiniImage } from "../../helpers/aws";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";
import { getCarDetailImages } from "../../store/actions/car";

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    padding: "5px",
  },
  selectedImage: {
    transform: "scale(0.9)",
    backgroundColor: "#186985",
  },
  mainImagesDiv: {
    display: "flex",
    flexWrap: "wrap",
  },
  imageTitleTypography: {
    width: "200px",
    padding: "5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const CPD = (props) => {
  const { getCarDetailImages } = props;
  const classes = useStyles();
  const [carId, setCarId] = useState(0);
  const [type, setType] = useState("");
  const [getImages, setGetImages] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedPath, setSelectedPath] = useState("cpd");

  useNonInitialEffect(() => {
    getCarDetailImages({
      carId,
      callback: (response) => {
        const { images } = response;
        let imageCompact = [...images[0], ...images[1]];
        let allImages = [];
        let extimgs = [];
        let intimgs = [];
        let cnt = 0;
        for (let i of imageCompact) {
          allImages = [...allImages, ...i.answer.images];
          if (cnt > images[0].length) {
            intimgs = [...intimgs, ...i.answer.images];
          } else {
            extimgs = [...extimgs, ...i.answer.images];
          }
          cnt += 1;
        }
        setImages(allImages);
      },
    });
  }, [getImages]);

  useEffect(() => {
    // let tempImages = [...images]
    let tempImages = [];
    console.log("hhhhhhhhhhhh", images);
    for (const image in images) {
      if (Object.hasOwnProperty.call(images, image)) {
        const element = images[image];
        let substringIndex = element.indexOf(".com/") + 5;
        let secondSubstringIndex = element.indexOf("Car-");
        let imgName =
          element.substring(0, substringIndex) +
          type +
          "-" +
          element.substring(secondSubstringIndex, element.length);
        console.log("llllllllll", imgName);
        tempImages.push(imgName);
      }
    }
    console.log("llllllllllllllsssss", tempImages);
    setImages(tempImages);
  }, [type]);

  const handleGetImages = () => {
    setImages([]);
    setSelectedImages([]);
    setGetImages(!getImages);
  };

  const handleCustomImage = (event) => {
    setImages([event.target.value]);
  };

  const handleChange = (event) => {
    setCarId(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const Compress = async (selected) => {
    let compressImages = [...images];
    if (selected) {
      compressImages = compressImages?.filter((image, index) =>
        selectedImages.includes(index)
      );
    }

    await Promise.all(
      compressImages?.map(async (imageLink, index) => {
        const image = await fetch(imageLink)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "name", { type: "image/png" });
            let prefix = selectedPath == "cpd" ? "CPDTest/" : "";
            let substringIndex = imageLink.indexOf(".com/") + 5;
            let imgName = decodeURIComponent(
              prefix + imageLink.substring(substringIndex, imageLink.length)
            );
            selectedPath == "cpd"
              ? handleCompressMiniImage(file, imgName, (data) => {
                  console.log("sssssssssssseeCPD", data);
                })
              : handleCompress(file, imgName, (data) => {
                  console.log("sssssssssssseeRepl", data);
                });
            // new Compressor(file, {
            //   quality: 0.5,
            //   maxWidth: 800,
            //   maxHeight: 600,
            //   convertSize: 500,
            //   convertTypes: "image/png,image/jpeg,image/jpg",
            //   async success(result) {
            //     console.log(result, "vvvvvvvvvvvvvvvv", "Success", index);
            //     let prefix = selectedPath == "cpd" ? "CPDTest/" : "";
            //     let substringIndex = imageLink.indexOf(".com/") + 5;
            //     let imgName =
            //       // imageLink.substring(0, substringIndex) +
            //       prefix +
            //       imageLink.substring(substringIndex, imageLink.length);
            //     console.log("fffffffffff", imgName);
            //     await s3Uploader(imgName, result, (data) => {
            //       console.log("ssssssssssssee", data);
            //     });
            //   },
            //   error(err) {
            //     console.log(
            //       err.message,
            //       err,
            //       "vvvvvvvvvvvvvvvv",
            //       "Failure",
            //       index
            //     );
            //     alert(JSON.stringify(err));
            //   },
            // });
          });
      })
    );
  };

  const toggleImageSelected = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages((prev) => {
        let temp = [...prev];
        let removed = temp.splice(prev.indexOf(index), 1);
        return temp;
      });
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  };

  const handleImageTitleChange = (event) => {
    const index = event.target.id;
    console.log(index, event.target);
    let tempImages = [...images];
    tempImages[index] = event.target.value;
    console.log(tempImages);
    setImages(tempImages);
  };

  const handlePathChange = (event) => {
    setSelectedPath(event.target.value);
  };

  return (
    <div className={classes.mainContainer}>
      <TextField variant="outlined" label="car ID" onChange={handleChange} />
      <TextField
        variant="outlined"
        label="Image Type"
        onChange={handleChangeType}
      />
      <TextField
        variant="outlined"
        label="Custom Image"
        onChange={handleCustomImage}
      />
      <Button onClick={handleGetImages}>Get Images</Button>
      <Button onClick={() => Compress(true)} disabled={!selectedImages.length}>
        Compress Selected
      </Button>
      <Button onClick={() => Compress(false)}>Compress All</Button>
      <FormControl component="fieldset">
        <FormLabel component="legend">Path</FormLabel>
        <RadioGroup
          aria-label="path"
          name="path select"
          value={selectedPath}
          onChange={handlePathChange}
        >
          <FormControlLabel
            value="cpd"
            control={<Radio color="primary" />}
            label="Thumbnail"
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.mainImagesDiv}>
        {images?.map((image, index) => (
          <div>
            <img
              src={image}
              width="200px"
              height="auto"
              className={clsx({
                [classes.image]: 1,
                [classes.selectedImage]: selectedImages.includes(index),
              })}
              onClick={() => toggleImageSelected(index)}
            />
            {/* <Tooltip
              title={image.substring(image.indexOf(".com/") + 5, image.length)}
            > */}
            {/* <Typography className={classes.imageTitleTypography}>
                {image.substring(image.indexOf(".com/") + 5, image.length)}
              </Typography> */}
            <TextField
              className={classes.imageTitleTypography}
              id={index}
              // value={decodeURIComponent(image)}
              value={decodeURIComponent(image)}
              onChange={(event) => handleImageTitleChange(event)}
            />
            {/* </Tooltip> */}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCarDetailImages(payload) {
    dispatch(getCarDetailImages(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CPD);
