import React from "react";

const PaintDescription = (props) => {
  const { size, fill, style, className, hood, trunk, roof, color } = props;
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.8458"
        cy="12.846"
        r="7.33676"
        stroke={color}
        stroke-width="4.46585"
      />
      <circle
        cx="12.8456"
        cy="12.8456"
        r="11.8026"
        stroke={color}
        stroke-width="1.91394"
      />
    </svg>
  );
};

export default PaintDescription;
