import { Typography } from "@material-ui/core";
import React from "react";

function FrameFaultsLis(props) {
  const { data } = props;
  return (
    <div>
      {data.map((element, index) => (
        <div style={{ padding: "25.5px" }}>
          <Typography style={{ color: "#636363" }}>
            {index + 1}. {element}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default FrameFaultsLis;
