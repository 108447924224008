const API_ROOT = "https://api.markabte.com/api";
// const API_ROOT = "http://localhost:8000/api";
// const API_ROOT = "http://192.168.1.87:8000/api";
// const API_ROOT = "http://192.168.100.118:8000/api";
// const API_ROOT = "http://192.168.100.118:8000/api";
// const API_ROOT = "http://192.168.0.125:8000/api";
// const API_ROOT = "http://192.168.1.120:8000/api";
// const API_ROOT = "http://192.168.1.121:8000/api";
// const API_ROOT = "http://192.168.43.246:8000/api";
// const API_ROOT = "http://192.168.1.72:8000/api";
// const API_ROOT = "http://192.168.100.107:8000/api";
// const API_ROOT="http://192.168.8.110:8000/8000/api";
// const API_ROOT = "http://192.168.8.108:8000/api";
// const API_ROOT = "http://192.168.1.99:8000/api";
// const API_ROOT = process.env.REACT_APP_HOST_URL || "http://localhost:8000/api";

const API_URLS = (extraData) => ({
  ROOT: API_ROOT,
  AUTH: {
    OBTAIN_TOKEN: API_ROOT + "/token-auth/",
    OBTAIN_REFRESH_TOKEN: API_ROOT + "/token-refresh/",
    ACTIVATE_ACCOUNT: API_ROOT + "/account_activation/",
    CONFIRM_PASSWORD: API_ROOT + "/reset_confirm/",
    SEND_OTP: API_ROOT + "/send-otp/",
    VALIDATE_OTP: API_ROOT + "/validate-otp/",
  },
  CAR_FORM: {
    DETAILS: API_ROOT + "/car-forms/details/" + extraData,
    FORMS: API_ROOT + "/car-forms/forms/",
    SUBFORMS: API_ROOT + "/car-forms/subforms/?form=" + extraData,
    FORM_FIELDS: API_ROOT + "/car-forms/fields/?form=" + extraData,
    // INVOKED_FORM_FIELDS:
    //   API_ROOT + "/car-forms/fields/?form=" + extraData + "&is_feature=false",
    MODEL_OPTIONS: API_ROOT + "/car-forms/fields/?id=228&make=" + extraData,
    // FORM_FEATURES: API_ROOT + "/car-forms/fields/?is_feature=true",
    GENERIC_FEATURES: API_ROOT + "/car-forms/features/",
    FEATURES_SUBFORMS: API_ROOT + "/car-forms/features-subforms/",
    FEILDS_COUNT: API_ROOT + "/car-forms/details/none/form_fields_count/",
    TOTAL_TIME: API_ROOT + "/car-forms/time-per-form/",
  },
  CAR: {
    LIST: API_ROOT + "/car/cards/" + extraData,
    PUBLISH_CAR_FOR_BUYERS:
      API_ROOT + "/car/cards/" + extraData + "/publish_car_for_buyers/",
    PUBLISH_CAR_FOR_SELLER:
      API_ROOT + "/car/cards/" + extraData + "/publish_car_for_seller/",
    DETAILS: API_ROOT + "/car/details/" + extraData + "/",
    DETAILS_IMAGES: API_ROOT + "/car/details-images/" + extraData + "/",
    PAGE: API_ROOT + "/car/page/" + extraData + "/",
    UPDATE_FORM:
      API_ROOT + "/car/details/" + extraData + "/update_car_form_info/",
    UPDATE_DTC_FORM:
      API_ROOT + "/car/details/" + extraData + "/update_DTC_info/",
    UPDATE_VISUALS:
      API_ROOT + "/car/details/" + extraData + "/update_car_visuals/",
    CHECK_VIS_FORMS_POINTS:
      API_ROOT + "/car/details/" + extraData + "/check_vis_forms_points/",
    CAR_FIELDS: API_ROOT + "/car/fields/" + extraData,
    CAR_FORMS: API_ROOT + "/car/forms/" + extraData,
    CAR_FEATURES: API_ROOT + "/car/fields/?is_feature=true&" + extraData,
    CAR_FAULTS: API_ROOT + "/car/faulty-condition/?" + extraData,
    CAR_BLOCKING: API_ROOT + "/car/blocking/?" + extraData,
    CAR_VISUALIZATION: API_ROOT + "/car/visualization/?" + extraData,
    CAR_GROUPED_FIELDS: API_ROOT + "/car/grouped-fields/" + extraData,
    CAR_GENERIC_FEATURES: API_ROOT + "/car/generic-features/?" + extraData,
    DTC_MAPPING:
      API_ROOT +
      "/car/dtc-mapping/" +
      extraData +
      "/get_unsaved_systems_indecies/",
    CAR_INVOKED: API_ROOT + "/car/get_invoked/?car_id=" + extraData,
    CAR_IDS_TITLES_LIST: API_ROOT + "/car/cars_titles/",
  },
  LISTINGS: {
    REQUEST: API_ROOT + "/dealership/listing-requests/?" + extraData,
    CLAIM:
      API_ROOT +
      "/dealership/listing-requests/" +
      extraData +
      "/claim_listing/",
  },
  NOTIFICATIONS: {
    UPDATE_STAMP: API_ROOT + "/live/page-stamp/" + extraData + "/page_stamp/",
    GET_STAMPS: API_ROOT + "/live/page-stamp/" + extraData + "/",
    GET_UNSEEN_NOTIFICATIONS:
      API_ROOT + "/live/page-stamp/" + extraData + "/unseen_notifications/",
  },
  EAGLE_EYE: {
    MAP: API_ROOT + "/eagle-eye/map/" + extraData,
    UPDATE_CAR_POINT:
      API_ROOT + "/eagle-eye/car-point/" + extraData + "/submit_EE_subform/",
    CALCULATE:
      API_ROOT + "/eagle-eye/car-point/" + extraData + "/calculate_EE/",
  },
  COVERT_PDF: {
    PDF: API_ROOT + "/convertPDF/",
    PDF_BLUE: API_ROOT + "/convertPDF2/",
    FILLMISSEDMAPPING: API_ROOT + "/fillMissedMapping/",
  },
  PAPARAZZI: {
    PACKAGE: API_ROOT + "/paparazzi/packages/" + extraData,
    GET_SHADOW: API_ROOT + "/paparazzi/get_shadow/" + extraData,
    PACKAGE_LIST: API_ROOT + "/paparazzi/packages_list/",
    CARIMAGES: API_ROOT + "/paparazzi/car-images/" + extraData,
    BGEDITOR:
      API_ROOT + "/paparazzi/bg-editor/" + extraData + "/change_backgrounds/",
    IMAGEDOWNLOAD: API_ROOT + "/paparazzi/download-images/?car_id=" + extraData,
  },
  TIME_SLOTS: {
    TIME_SLOTS: API_ROOT + "/time_slots/time_slots/?history=false",
    // CREATE_TIME_SLOT:
    //   API_ROOT + "/time_slots/time_slots" + "/create_time_slot/",
    CREATE_TIME_SLOT: API_ROOT + "/time_slots/time_slots/" + extraData,
    GET_AREA_GROUPS: API_ROOT + "/time_slots/area_groups/",
  },
});

export default API_URLS;
