import React from "react";

const FeatureIcon10 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M25 0C11.1927 0 0 11.1927 0 25C0 38.8073 11.1927 50 25 50C38.8073 50 50 38.8073 50 25C50 11.1927 38.8073 0 25 0ZM25 47.9167C12.3636 47.9167 2.0833 37.6364 2.0833 25C2.0833 12.3636 12.3636 2.0833 25 2.0833C37.6364 2.0833 47.9167 12.3636 47.9167 25C47.9167 37.6364 37.6364 47.9167 25 47.9167Z" fill="#07303E"/>
<path d="M42.2073 17.5583L42.2448 17.5333L42.0678 17.2688C39.1208 10.775 32.5958 6.25 25 6.25C14.6446 6.25 6.25 14.6446 6.25 25C6.25 35.3554 14.6446 43.75 25 43.75C32.5958 43.75 39.1208 39.225 42.0677 32.7313L42.2447 32.4667L42.2072 32.4417C44.264 27.6938 44.264 22.3062 42.2073 17.5583ZM39.5156 16.8458L29.8896 23.2656C29.6059 22.4624 29.1286 21.7415 28.5 21.1667L38.2656 14.9521C38.7248 15.5543 39.1424 16.187 39.5156 16.8458ZM28.125 8.6375C31.4625 9.27627 34.5243 10.9215 36.8989 13.3521L28.125 18.9354V8.6375ZM23.9583 8.38643C24.3041 8.36455 24.6479 8.3333 25 8.3333C25.3521 8.3333 25.6958 8.36455 26.0417 8.38643V19.8958C25.3543 19.7559 24.6458 19.7559 23.9584 19.8958V8.38643H23.9583ZM21.875 8.6375V18.9354L13.1011 13.3521C15.4757 10.9215 18.5375 9.27627 21.875 8.6375ZM11.7344 14.95L21.5 21.1667C20.8646 21.7499 20.3848 22.4824 20.1042 23.2979H20.0979L10.3365 17.0834C10.7474 16.3376 11.2146 15.6244 11.7344 14.95ZM9.46768 19.0021L18.8927 25L9.46768 30.9979C7.95488 27.1424 7.95488 22.8576 9.46768 19.0021ZM11.7344 35.0479C11.2147 34.3742 10.7475 33.6617 10.3364 32.9167L20.0979 26.7011C20.3802 27.5176 20.8621 28.2506 21.4999 28.8334L11.7344 35.0479ZM21.875 41.3625C18.5375 40.7237 15.4757 39.0785 13.1011 36.6479L21.875 31.0646V41.3625ZM26.0417 41.6146C25.6959 41.6364 25.3521 41.6677 25 41.6677C24.6479 41.6677 24.3042 41.6354 23.9583 41.6135V30.1042C24.6457 30.2441 25.3542 30.2441 26.0416 30.1042V41.6146H26.0417ZM25 28.125C23.2741 28.125 21.875 26.7259 21.875 25C21.875 23.2741 23.2741 21.875 25 21.875C26.7259 21.875 28.125 23.2741 28.125 25C28.125 26.7259 26.7259 28.125 25 28.125ZM28.125 41.3625V31.1667L36.5021 37.0313C34.1864 39.2535 31.277 40.7578 28.125 41.3625ZM37.9344 35.4906L28.4656 28.8625C29.1112 28.2836 29.6012 27.5519 29.8906 26.7344L39.5208 33.1542C39.0588 33.9763 38.5281 34.7579 37.9344 35.4906ZM40.4354 31.2604L31.0417 25L40.4334 18.7396C41.2464 20.7267 41.6652 22.8529 41.6667 25C41.6659 27.1469 41.2477 29.2731 40.4354 31.2604Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon10;
