import React from "react";

const FeatureIcon32 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon32Clip0)">
<path d="M25 43.75C14.3104 43.75 6.25 40.1667 6.25 35.4167C6.25 33.0188 8.32083 30.8459 12.0812 29.2959C12.6146 29.0813 13.2229 29.3334 13.4417 29.8625C13.6604 30.3959 13.4062 31.0042 12.875 31.223C9.9875 32.4125 8.33333 33.9396 8.33333 35.4167C8.33333 38.373 15.1792 41.6667 25 41.6667C34.8208 41.6667 41.6667 38.373 41.6667 35.4167C41.6667 33.9355 40.0042 32.4063 37.1062 31.2167C36.5729 31 36.3187 30.3896 36.5375 29.8584C36.7562 29.325 37.3687 29.075 37.8958 29.2896C41.6708 30.8375 43.75 33.0125 43.75 35.4167C43.75 40.1667 35.6896 43.75 25 43.75Z" fill="#07303E"/>
<path d="M25 50C14.3104 50 6.25 46.4167 6.25 41.6667V35.4167C6.25 34.8417 6.71667 34.375 7.29167 34.375C7.86667 34.375 8.33333 34.8417 8.33333 35.4167V41.6667C8.33333 44.6229 15.1792 47.9167 25 47.9167C34.8208 47.9167 41.6667 44.6229 41.6667 41.6667V35.4167C41.6667 34.8417 42.1333 34.375 42.7083 34.375C43.2833 34.375 43.75 34.8417 43.75 35.4167V41.6667C43.75 46.4167 35.6896 50 25 50Z" fill="#07303E"/>
<path d="M33.1042 32.5207C32.8375 32.5207 32.5708 32.4187 32.3667 32.2166C30.4021 30.2499 27.7833 29.1666 25 29.1666C22.2167 29.1666 19.5979 30.2499 17.6312 32.2166C17.225 32.6228 16.5646 32.6228 16.1583 32.2166C15.7521 31.8103 15.7521 31.1499 16.1583 30.7437C18.5187 28.3833 21.6583 27.0833 25 27.0833C28.3417 27.0833 31.4812 28.3833 33.8417 30.7437C34.2479 31.1499 34.2479 31.8103 33.8417 32.2166C33.6375 32.4207 33.3708 32.5207 33.1042 32.5207Z" fill="#07303E"/>
<path d="M28.6875 36.9376C28.4208 36.9376 28.1542 36.8355 27.95 36.6334C26.3813 35.0626 23.6167 35.0626 22.0479 36.6334C21.6417 37.0397 20.9813 37.0397 20.575 36.6334C20.1688 36.2272 20.1688 35.5667 20.575 35.1605C22.9313 32.8042 27.0667 32.8042 29.4229 35.1605C29.8292 35.5667 29.8292 36.2272 29.4229 36.6334C29.2208 36.8376 28.9542 36.9376 28.6875 36.9376Z" fill="#07303E"/>
<path d="M7.29167 19.7917C6.71667 19.7917 6.25 19.325 6.25 18.75C6.25 17.0271 7.65208 15.625 9.375 15.625C9.95 15.625 10.4167 15.1563 10.4167 14.5833V8.33333C10.4167 7.76042 9.95 7.29167 9.375 7.29167C7.65208 7.29167 6.25 5.88958 6.25 4.16667C6.25 3.59167 6.71667 3.125 7.29167 3.125C7.86667 3.125 8.33333 3.59167 8.33333 4.16667C8.33333 4.73958 8.8 5.20833 9.375 5.20833C11.0979 5.20833 12.5 6.61042 12.5 8.33333V14.5833C12.5 16.3063 11.0979 17.7083 9.375 17.7083C8.8 17.7083 8.33333 18.1771 8.33333 18.75C8.33333 19.325 7.86667 19.7917 7.29167 19.7917Z" fill="#07303E"/>
<path d="M39.5833 22.9167H10.4167C8.11875 22.9167 6.25 21.0479 6.25 18.75V4.16667C6.25 1.86875 8.11875 0 10.4167 0H39.5833C41.8812 0 43.75 1.86875 43.75 4.16667V18.75C43.75 21.0479 41.8812 22.9167 39.5833 22.9167ZM10.4167 2.08333C9.26875 2.08333 8.33333 3.01667 8.33333 4.16667V18.75C8.33333 19.9 9.26875 20.8333 10.4167 20.8333H39.5833C40.7312 20.8333 41.6667 19.9 41.6667 18.75V4.16667C41.6667 3.01667 40.7312 2.08333 39.5833 2.08333H10.4167Z" fill="#07303E"/>
<path d="M23.9583 18.7499C23.7604 18.7499 23.5604 18.6937 23.3812 18.5749C22.9021 18.2562 22.7729 17.6082 23.0917 17.1312L26.1792 12.4999H21.875C21.4896 12.4999 21.1375 12.2874 20.9563 11.9499C20.775 11.6103 20.7958 11.1999 21.0083 10.8791L25.175 4.62908C25.4937 4.152 26.1375 4.01867 26.6187 4.3395C27.0979 4.65825 27.2271 5.30617 26.9083 5.78325L23.8208 10.4166H28.125C28.5104 10.4166 28.8625 10.6291 29.0437 10.9666C29.225 11.3062 29.2042 11.7166 28.9917 12.0374L24.825 18.2874C24.625 18.5874 24.2937 18.7499 23.9583 18.7499Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon32Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon32;
