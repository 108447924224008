import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import VisIns from "../../wrappers/VisIns";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
  },
  navigationPaper: {
    display: "flex",
    backgroundColor: "#f3f3f3",
    borderRadius: 0,
    boxShadow: "none",
    height: "39px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#186985",
    },
  },
  breadcrumbsComponent: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      width: "927px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1009px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(320px + 70.414vw)",
    },
  },
  Link: {
    fontSize: "18px",
    cursor: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "line-below",
    },
  },
  mainDetailDiv2: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  mainInspectionDiv: {
    margin: "41.13px auto 45px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 0",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 42px)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1040px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1165px",
    },
  },
}));

const CarVisProto = (props) => {
  const classes = useStyles();
  const {} = props;

  const carId = props.match.params.id;
  let carIds = [];
  const [activeStep, setActiveStep] = React.useState(
    carIds.indexOf(parseInt(carId))
  );
  const { t, i18n } = useTranslation();

  const renderFirstTab = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <div className={classes.mainDetailDiv2}>
          <div className={classes.mainInspectionDiv}>
            <VisIns />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div>{renderFirstTab(activeStep)}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, null)(CarVisProto));
