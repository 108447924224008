import React, { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import { Page } from "react-pdf";
import Toolbar from "@material-ui/core/Toolbar";

const InfoButton = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div
      style={{
        display: "flex",
        height: props.field && "min-content",
      }}>
      <IconButton
        onClick={() => {
          setShowInfo(true);
        }}
        style={{ padding: "0 12px" }}>
        <HelpIcon color='primary' />
      </IconButton>

      <Dialog
        open={showInfo}
        onClose={() => {
          setShowInfo(false);
        }}
        aria-labelledby='form-dialog-title'
        fullScreen={true}
        FordFord
        style={{ padding: 0 }}>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setShowInfo(false)}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            <Document
              file={
                localStorage.getItem("language") === "ar"
                  ? props.infoFiles?.ar || props.ar
                  : props.infoFiles?.en || props.en
              }
              onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth}
                />
              ))}
            </Document>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoButton;
