import {
  put,
  takeLatest,
  select,
  delay,
  call,
  takeEvery,
} from "redux-saga/effects";
import { requestAPI, checkHttpStatus } from "../helpers";
import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_REQUEST_SUCCEED,
  FETCH_TOKEN_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCEED,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  RESET_PASSWORD_CONFIRM,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_FAILURE,
  TOKEN_REMOVE_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST,
  FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
  FETCH_REFRESH_TOKEN_REQUEST_FAILURE,
  UPDATE_PAGE_TIME_STAMP,
  CONNECTION_REQUEST,
  VALIDATE_OTP,
  VALIDATE_OTP_FAILURE,
  SEND_OTP_FAILURE,
  SEND_OTP,
} from "../constants";

import API_URLS from "../../api";
import { NetworkManager } from "aws-sdk";
import cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { push } from "react-router-redux";

export function* checkForExpiredToken() {
  const token = cookies.get("refresh-token");
  if (token) {
    const currentTime = Date.now() / 1000;
    const user = jwtDecode(token);
    if (user.exp >= currentTime) {
      yield put({ type: FETCH_REFRESH_TOKEN_REQUEST });
    } else {
      yield put({ type: TOKEN_REMOVE_REQUEST }); //add push history or whatever to redirect to login page
    }
  }
}

export function* fetchRefreshToken() {
  try {
    let data = {
      url: API_URLS().AUTH.OBTAIN_REFRESH_TOKEN,
      method: "POST",
      body: {
        refresh: cookies.get("refresh-token"),
      },
    };
    const token = cookies.get("token");
    const refreshToken = cookies.get("refresh-token");
    const response = yield requestAPI(data);
    if (token) {
      const currentTime = Date.now() / 1000;
      const tokenExp = jwtDecode(token).exp;
      const refreshTokenExp = jwtDecode(refreshToken).exp;
      if (refreshTokenExp >= currentTime) {
        if (tokenExp - currentTime < 20) {
          yield put({
            type: FETCH_REFRESH_TOKEN_REQUEST_SUCCEED,
            payload: response,
          });
        }
      }
    }
  } catch (e) {
    yield put({
      type: FETCH_REFRESH_TOKEN_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({ type: TOKEN_REMOVE_REQUEST });
    yield put(push("/login"));
  }
}

export function* fetchToken(action) {
  try {
    let data = {
      url: API_URLS().AUTH.OBTAIN_TOKEN,
      method: "POST",
      body: {
        phone_number: action.payload.Phone,
        password: action.payload.password,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_TOKEN_REQUEST_SUCCEED, payload: response });
    const getAccountActivationStatus = (state) =>
      state.auth.user.account_activated; //this will get the account_activated status from the redux store
    const userActivationStatus = yield select(getAccountActivationStatus);
    if (userActivationStatus === false) {
      action.payload.callback2();
      return;
    }
    action.payload.callback();
  } catch (e) {
    console.log("ccccccccc", e);
    yield put({ type: FETCH_TOKEN_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Please make sure you entered valid credentials",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* activateAccount(action) {
  if (action.payload.verifiedPassword !== action.payload.password) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Your passwords do not match!",
        variant: "warning",
        open: true,
      },
    });
    return;
  }
  try {
    let data = {
      url: API_URLS().AUTH.ACTIVATE_ACCOUNT,
      method: "POST",
      body: {
        phone_number: action.payload.phone,
        password: action.payload.password,
      },
    };
    yield requestAPI(data);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "account activated! please login with your new password",
        variant: "success",
        open: true,
      },
    });
    action.payload.callback();
  } catch (e) {
    yield put({ type: ACTIVATE_ACCOUNT_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "error",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* confirmReset(action) {
  if (action.payload.verifiedPassword !== action.payload.password) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Your passwords do not match!",
        variant: "warning",
        open: true,
      },
    });
    return;
  }
  try {
    let data = {
      url: API_URLS().AUTH.CONFIRM_PASSWORD,
      method: "POST",
      body: {
        activation_key: action.payload.code,
        password: action.payload.password,
        phone: action.payload.phone,
      },
    };

    yield requestAPI(data);
    action.payload.callback();
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message:
          "Your password has been changed successfully, please login with your new password",
        variant: "success",
        open: true,
      },
    });

    action.payload.callback();
  } catch (e) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "error",
        variant: "error",
        open: true,
      },
    });
  }
}

// export function* validateOTP(action) {
//   try {
//     let data = {
//       url: API_URLS().AUTH.VALIDATE_OTP,
//       method: "POST",
//       body: { phone: action.payload.phone, otp: action.payload.otp },
//     };
//     const response = yield requestAPI(data);
//     action.payload.callback(response);
//   } catch (e) {
//     yield put({ type: VALIDATE_OTP_FAILURE, error: new Error(e) });
//   }

//   return;
// }

export function* sendOTP(action) {
  // SEND_OTP SIGNUP_USER
  try {
    console.log("tttttttttttttttt", action);
    let data = {
      url: API_URLS().AUTH.SEND_OTP,
      method: "POST",
      body: { phone: action.payload.phone },
    };
    const response = yield requestAPI(data); //check here
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: SEND_OTP_FAILURE, error: new Error(e) });
  }

  return;
}

export function* validateOTP(action) {
  try {
    let data = {
      url: API_URLS().AUTH.VALIDATE_OTP,
      method: "POST",
      body: { phone: action.payload.phone, otp: action.payload.otp },
    };
    const response = yield requestAPI(data);
    console.log("aaaaaaaaaaaaaaaa", response);
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: VALIDATE_OTP_FAILURE, error: new Error(e) });
  }

  return;
}

export function* validateOTPWatcher() {
  yield takeLatest(VALIDATE_OTP, validateOTP);
}

export function* sendOTPWatcher() {
  yield takeLatest(SEND_OTP, sendOTP);
}

export function* fetchTokenWatcher() {
  yield takeEvery(FETCH_TOKEN_REQUEST, fetchToken);
}

export function* fetchRefreshTokenWatcher() {
  yield takeLatest(FETCH_REFRESH_TOKEN_REQUEST, fetchRefreshToken);
}

export function* checkForExpiredTokenWatcher() {
  // yield takeEvery(UPDATE_PAGE_TIME_STAMP, checkForExpiredToken)
}

export function* activateAccountWatcher() {
  yield takeLatest(ACTIVATE_ACCOUNT_REQUEST, activateAccount);
}

export function* confirmResetWatcher() {
  yield takeLatest(RESET_PASSWORD_CONFIRM, confirmReset);
}

// export function* validateOTPWatcher() {
//   yield takeLatest(VALIDATE_OTP, validateOTP);
// }
