// FaultCard

import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// import {GoogleLogo} from '../../components/icons/GoogleLogo'
import React from "react";
import GoogleLogo from "../../components/icons/GoogleLogo";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  cardFrame: {
    minWidth: "327px",
    margin: "16px",
    background: "#FFFFFF",
    border: "1px solid #ECECEC",
    boxShadow: "0px 4px 6px rgba(10, 47, 60, 0.2)",
  },
  faultInfo: {
    width: "94%",
    display: "inline-flex",
    justifyContent: "space-between",
    padding: "14px",
  },
  fault: {
    fontWeight: "700",
    fontSize: "16px",
  },
  state: {
    fontWeight: "500",
    fontSize: "12px",
    color: "#BB0202",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
  },
  detail: {
    fontSize: "12px",
    lineHeight: "18px",
  },
  cardPortion: {
    margin: "14px",
  },
  filterHr: {
    borderTop: "0px solid #CECECE",
    background: "#CECECE",
    width: "100%",
  },
  searchbar: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "95%",
    padding: "13px",
  },
  googlelogo: {
    margin: "8px",
  },
  searchPanel: {
    display: "inline-flex",
  },
  back: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
  },
  closeIcon: {
    width: "20px",
    height: "20px",
    color: "#45A5C7",
    margin: "5px",
  },
}));

function FaultCard(props) {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const { data, googlelink } = props;
  //
  return (
    <div className={classes.cardFrame}>
      <div className={classes.faultInfo}>
        <div className={classes.fault}>{props.fault || "N/A"}</div>
        <div className={classes.state}>{props.state || "N/A"}</div>
      </div>
      <div className={classes.cardPortion}>
        <div className={classes.title}>{t("System")}:</div>
        <div className={classes.detail}>{props.system}</div>
      </div>
      <div className={classes.cardPortion}>
        <div className={classes.title}>{t("Description")}:</div>
        <div className={classes.detail}>{props.desc}</div>
      </div>
      <Divider className={classes.filterHr} />
      <a target="_blank" href={googlelink}>
        <div className={classes.searchbar}>
          <div className={classes.searchPanel}>
            <span className={classes.googlelogo}>
              {/* <img src={GoogleLogo} width={16} />  */}
              <GoogleLogo size={16} />
            </span>
            <Typography
              style={{ padding: "3px" }}
              style={{ color: "#45A5C7", paddingTop: "5px" }}
            >
              {t("Search for more information")}
            </Typography>
          </div>
          <div>
            <div className={classes.back}>
              {localStorage.getItem("language") == "en" ? (
                <div>
                  <ArrowBackIosIcon
                    className={classes.closeIcon}
                    style={
                      // localStorage.getItem("language") == "en"
                      //   ? {}
                      //   : { transform: "rotate(180deg)" }
                      { transform: "rotate(180deg)" }
                    }
                  />
                </div>
              ) : (
                <div>
                  <ArrowBackIosIcon
                    style={localStorage.getItem("language") == "en" ? {} : {}}
                    className={classes.closeIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default FaultCard;
