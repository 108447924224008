import React from "react";

const FeatureIcon46 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon46Clip0)">
<path d="M28.2888 17.7245L31.7255 16.1552L34.7589 11.3157C35.6356 9.913 37.1786 9.06259 38.8356 9.07136H51.7583C53.2574 9.07136 54.6777 9.76396 55.5895 10.9563L59.5084 16.085L66.0399 17.8735C67.6968 18.3294 68.8453 19.8461 68.8453 21.5645V24.0631C68.8453 26.176 67.1621 27.9206 65.0492 27.9206H64.4881C63.813 30.5244 61.1566 32.085 58.5528 31.4099C56.8432 30.9628 55.5018 29.6302 55.0635 27.9206H40.3347C39.6597 30.5244 37.0033 32.085 34.3994 31.4099C32.6898 30.9628 31.3485 29.6302 30.9101 27.9206H29.8581C27.7452 27.9206 26.0619 26.176 26.0619 24.0631V21.2138C26.0532 19.7146 26.9299 18.3469 28.2888 17.7245ZM59.7802 29.7968C61.4985 29.7968 62.8925 28.4028 62.8925 26.6844C62.8925 24.9661 61.4985 23.5721 59.7802 23.5721C58.0618 23.5721 56.6678 24.9661 56.6678 26.6844C56.6591 28.394 58.053 29.788 59.7802 29.7968ZM35.6268 29.7968C37.3452 29.7968 38.7391 28.4028 38.7391 26.6844C38.7391 24.9661 37.3452 23.5721 35.6268 23.5721C33.9085 23.5721 32.5145 24.9661 32.5145 26.6844C32.5057 28.394 33.8997 29.788 35.6268 29.7968ZM29.8581 26.1672H30.7874C31.0767 23.4932 33.4876 21.5645 36.1616 21.8625C38.4323 22.108 40.2208 23.9053 40.4663 26.1672H54.9407C55.23 23.4932 57.641 21.5645 60.315 21.8625C62.5856 22.108 64.3741 23.9053 64.6196 26.1672H65.058C66.2064 26.1672 67.1007 25.2116 67.1007 24.0631V21.5645C67.1095 20.6264 66.487 19.811 65.584 19.5568L58.7544 17.6894C58.5703 17.6368 58.4037 17.5316 58.2898 17.3738L54.2043 12.0171C53.6169 11.2631 52.7139 10.8248 51.7583 10.8248H38.8356C37.7835 10.816 36.7928 11.3508 36.2405 12.245L33.0668 17.3124C32.9792 17.4615 32.8389 17.5754 32.6898 17.6456L29.0252 19.3201C28.2888 19.662 27.8153 20.3984 27.8241 21.2138V24.0631H27.8153C27.8153 25.2116 28.7096 26.1672 29.8581 26.1672Z" fill="#07303E"/>
<path d="M20.0318 8.51404L17.5051 9.79458L20.7553 14.4988L21.9038 13.9167L20.0318 8.51404Z" fill="#07303E"/>
<path d="M21.8453 5H25.8453L24.7544 15H22.9362L21.8453 5Z" fill="#07303E"/>
<path d="M27.8135 8.51404L30.3402 9.79458L27.09 14.4988L25.9415 13.9167L27.8135 8.51404Z" fill="#07303E"/>
<path d="M49.8453 10.0001L49.8453 28.0087" stroke="#07303E" strokeWidth="2"/>
<line x1="0.154626" y1="10.0086" x2="-3.6053e-05" y2="28.0086" stroke="#07303E" strokeWidth="2"/>
<mask id="FeatureIcon46Mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="-21" y="9" width="43" height="23">
<path d="M-18.7731 17.9315L-15.3364 16.3622L-12.303 11.5228C-11.4263 10.12 -9.8833 9.26962 -8.22632 9.27839H4.69638C6.19555 9.27839 7.61582 9.97099 8.5276 11.1633L12.4465 16.2921L18.978 18.0805C20.635 18.5364 21.7834 20.0531 21.7834 21.7715V24.2701C21.7834 26.383 20.1002 28.1276 17.9873 28.1276H17.4262C16.7511 30.7315 14.0947 32.292 11.4909 31.6169C9.78129 31.1698 8.43993 29.8372 8.00158 28.1276H-6.72714C-7.40221 30.7315 -10.0586 32.292 -12.6625 31.6169C-14.3721 31.1698 -15.7134 29.8372 -16.1518 28.1276H-17.2038C-19.3167 28.1276 -21 26.383 -21 24.2701V21.4208C-21.0087 19.9216 -20.132 18.554 -18.7731 17.9315ZM12.7183 30.0038C14.4366 30.0038 15.8306 28.6098 15.8306 26.8915C15.8306 25.1731 14.4366 23.7792 12.7183 23.7792C10.9999 23.7792 9.60595 25.1731 9.60595 26.8915C9.59719 28.6011 10.9912 29.995 12.7183 30.0038ZM-11.4351 30.0038C-9.71672 30.0038 -8.32275 28.6098 -8.32275 26.8915C-8.32275 25.1731 -9.71672 23.7792 -11.4351 23.7792C-13.1534 23.7792 -14.5474 25.1731 -14.5474 26.8915C-14.5562 28.6011 -13.1622 29.995 -11.4351 30.0038ZM-17.2038 26.3742H-16.2745C-15.9852 23.7003 -13.5742 21.7715 -10.9003 22.0696C-8.6296 22.3151 -6.84112 24.1123 -6.59564 26.3742H7.87883C8.16815 23.7003 10.5791 21.7715 13.2531 22.0696C15.5237 22.3151 17.3122 24.1123 17.5577 26.3742H17.9961C19.1446 26.3742 20.0388 25.4186 20.0388 24.2701V21.7715C20.0476 20.8334 19.4251 20.0181 18.5221 19.7638L11.6925 17.8964C11.5084 17.8438 11.3418 17.7386 11.2279 17.5808L7.1424 12.2241C6.555 11.4702 5.65199 11.0318 4.69638 11.0318H-8.22632C-9.27837 11.023 -10.2691 11.5578 -10.8214 12.4521L-13.9951 17.5195C-14.0827 17.6685 -14.223 17.7825 -14.3721 17.8526L-18.0367 19.5271C-18.7731 19.869 -19.2466 20.6055 -19.2378 21.4208V24.2701H-19.2466C-19.2466 25.4186 -18.3523 26.3742 -17.2038 26.3742Z" fill="#07303E"/>
</mask>
<g mask="url(#FeatureIcon46Mask1)">
<rect x="-1.03442" y="-3.55664" width="35.6529" height="44.2096" fill="#07303E"/>
</g>
</g>
<defs>
<clipPath id="FeatureIcon46Clip0">
<rect width="50" height="50" fill="white" transform="matrix(-1 0 0 1 50 0)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon46;
