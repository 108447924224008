import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography, Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "29px",
    width: "86%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "23px",
      width: "96%",
    },
  },
  titleTypography: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 600, // check
      fontSize: "18px",
    },
  },
  tabIndicatorDiv: {
    display: "flex",
    margin: "auto 0",
  },
  tabIndicator: {
    transition: "background-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    width: "13px",
    height: "13px",
    margin: "0 5px",
    borderRadius: "50%",
    backgroundColor: "#989898",
    [theme.breakpoints.down("sm")]: {
      width: "8px",
      height: "8px",
      margin: "0 2.5px",
    },
  },
  tabIndicatorActive: {
    backgroundColor: "#000",
  },
  container: {
    height: "100%",
    width: "100%",
    width: "94%",
    margin: "auto",
  },
}));

const SignUpTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { firstTab, secondTab, firstTabSelected } = props;

  return (
    <div className={classes.container}>
      <div className={classes.titleDiv}>
        <Typography className={classes.titleTypography}>
          {firstTabSelected ? t(firstTab.title) : t(secondTab.title)}
        </Typography>
        <div className={classes.tabIndicatorDiv}>
          <div
            className={clsx({
              [classes.tabIndicator]: 1,
              [classes.tabIndicatorActive]: firstTabSelected,
            })}
          />
          <div
            className={clsx({
              [classes.tabIndicator]: 1,
              [classes.tabIndicatorActive]: !firstTabSelected,
            })}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ position: "relative" }}>
            {firstTabSelected && (
              <Slide
                direction="right"
                in={firstTabSelected}
                mountOnEnter
                unmountOnExit
              >
                <div>{firstTab.render}</div>
              </Slide>
            )}
            {!firstTabSelected && (
              <Slide
                direction="left"
                in={!firstTabSelected}
                mountOnEnter
                unmountOnExit
              >
                <div>{secondTab.render}</div>
              </Slide>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpTabs.defaultProps = {
  firstTab: { render: <div></div>, title: "First Tab" },
  secondTab: { render: <div></div>, title: "Second Tab" },
};

SignUpTabs.propTypes = {
  firstTab: PropTypes.object,
  secondTab: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpTabs)
);
