import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  GET_TIME_SLOTS_REQUEST,
  GET_TIME_SLOTS_REQUEST_SUCCEED,
  GET_TIME_SLOTS_REQUEST_FAILURE,
  CREATE_TIME_SLOT_REQUEST,
  CREATE_TIME_SLOT_REQUEST_SUCCEED,
  CREATE_TIME_SLOT_REQUEST_FAILURE,
  UPDATE_TIME_SLOT_REQUEST,
  UPDATE_TIME_SLOT_REQUEST_SUCCEED,
  UPDATE_TIME_SLOT_REQUEST_FAILURE,
  GET_AREA_GROUPS_REQUEST_SUCCEED,
  GET_AREA_GROUPS_REQUEST_FAILURE,
  GET_AREA_GROUPS_REQUEST,
  TOGGLE_NOTIFICATION_BAR,
} from "../constants";
import API_URLS from "../../api";

export function* getTimeSlotsRequest(action) {
  try {
    let data = {
      url: API_URLS(action.payload).TIME_SLOTS.TIME_SLOTS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: GET_TIME_SLOTS_REQUEST_SUCCEED,
      payload: response,
    });
  } catch (e) {
    yield put({
      type: GET_TIME_SLOTS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getTimeSlotsRequestsWatcher() {
  yield takeLatest(GET_TIME_SLOTS_REQUEST, getTimeSlotsRequest);
}

export function* createTimeSlot(action) {
  try {
    const data = {
      url: API_URLS("").TIME_SLOTS.CREATE_TIME_SLOT,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({ type: CREATE_TIME_SLOT_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Time Slot Succesfully Created",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: CREATE_TIME_SLOT_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Failed To Create Time Slot",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateTimeSlot(action) {
  try {
    const data = {
      url: API_URLS(action.payload.id + "/").TIME_SLOTS.CREATE_TIME_SLOT,
      method: "PUT",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({ type: UPDATE_TIME_SLOT_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Time Slot Succesfully Updated",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({ type: UPDATE_TIME_SLOT_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Failed To Update Time Slot",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* createTimeSlotWatcher() {
  yield takeLatest(CREATE_TIME_SLOT_REQUEST, createTimeSlot);
}
export function* updateTimeSlotWatcher() {
  yield takeLatest(UPDATE_TIME_SLOT_REQUEST, updateTimeSlot);
}

export function* getAreaGroups(action) {
  try {
    let data = {
      url: API_URLS().TIME_SLOTS.GET_AREA_GROUPS,
      method: "GET",
    };

    const response = yield requestAPI(data);

    yield put({
      type: GET_AREA_GROUPS_REQUEST_SUCCEED,
    });

    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: GET_AREA_GROUPS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getAreaGroupsWatcher() {
  yield takeLatest(GET_AREA_GROUPS_REQUEST, getAreaGroups);
}
