import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  secondDiv: {
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  thirdDiv: {
    position: "absolute",
    borderRadius: "50%",
    transform: "translate(-50%,-100%)",
    zIndex: "5",
    left: "50%",
  },
  fourthDiv: {
    position: "absolute",
    borderRadius: "50%",
    transform: "translate(-50%,-50%)",
  },
  typographyDiv: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    textAlign: "right",

    color: "#FFFFFF",
    zIndex: "3",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
  },
}));
const HotSpot = (props) => {
  const { score, number, size, max, min, onClick, className, center, id } =
    props;
  const classes = useStyles();
  const clamp = max !== undefined && min !== undefined;
  // a div margin auto 0 and center true are only used in clamp and not on points
  const theme = useTheme();

  return (
    <div
      id={id}
      className={className}
      onClick={onClick}
      style={{
        position: "absolute",
        width: clamp
          ? `clamp(${1.0865 * min}px,${1.0865 * size}vw,${1.0865 * max}px)`
          : `${1.0865 * size}px`,
        height: clamp
          ? `clamp(${1.0865 * min}px,${1.0865 * size}vw,${1.0865 * max}px)`
          : `${1.0865 * size}px`,
        transform: center
          ? theme.direction === "ltr"
            ? "translate(-50%,-50%)"
            : "translate(50%,-50%)"
          : "", //`translate(-${(1.0865 * size) / 2}px,0)`,
        background:
          "radial-gradient(50% 50% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0.1375) 86.25%, rgba(0, 0, 0, 0) 100%)",
        opacity: "0.95",
        borderRadius: "50%",
      }}
    >
      <div
        className={classes.secondDiv}
        style={
          {
            // transform: "translate(-50%,-50%)",
            // top: "50%",
            // left: "50%",
            // position: "absolute",
          }
        }
      >
        <div
          className={classes.thirdDiv}
          style={{
            width: clamp
              ? `clamp(${0.6954 * min}px,${0.6954 * size}vw,${0.6954 * max}px)`
              : `${0.6954 * size}px`,
            height: clamp
              ? `clamp(${0.4346 * min}px,${0.4346 * size}vw,${0.4346 * max}px)`
              : `${0.4346 * size}px`,
            /* Color */
            background:
              score > 50
                ? "linear-gradient(4.94deg, rgba(255, 255, 255, 0.31) 3.98%, rgba(255, 243, 183, 0.31) 44.58%, rgba(255, 235, 133, 0.31) 63.9%, rgba(255, 230, 94, 0.31) 78.7%, rgba(255, 223, 52, 0.31) 84.77%, rgba(255, 220, 40, 0.31) 91.15%, rgba(255, 214, 0, 0.31) 96.31%)"
                : "linear-gradient(4.94deg, rgba(255, 255, 255, 0.31) 3.98%, rgba(255, 183, 183, 0.31) 44.58%, rgba(255, 133, 133, 0.31) 63.9%, rgba(255, 94, 94, 0.31) 78.7%, rgba(255, 52, 52, 0.31) 84.77%, rgba(255, 40, 40, 0.31) 91.15%, rgba(255, 0, 0, 0.31) 96.31%)",
          }}
        />
        <div
          className={classes.fourthDiv}
          style={{
            width: clamp ? `clamp(${min}px,${size}vw,${max}px)` : `${size}px`,
            height: clamp ? `clamp(${min}px,${size}vw,${max}px)` : `${size}px`,

            /* Color */
            background:
              score > 50
                ? "radial-gradient(50% 50% at 50% 50%, #FDD500 0%, #D3B100 43.98%, #C2A300 64.9%, #B89B00 80.93%, #AE9200 94.41%, #A28800 100%)"
                : "radial-gradient(50% 50% at 50% 50%, #FF0016 0%, #EF0015 43.98%, #DA0013 64.9%, #BF0011 80.93%, #A5000E 94.41%, #B10010 100%)",
            boxShadow: clamp
              ? `-clamp(${0.0435 * min}px,${0.0435 * size}vw,${
                  0.0435 * max
                }px) clamp(${0.0435 * min}px,${0.0435 * size}vw,${
                  0.0435 * max
                }px) clamp(${0.08692 * min}px,${0.08692 * size}vw,${
                  0.08692 * max
                }px) rgba(48, 0, 0, 0.5)`
              : `-${0.0435 * size}px ${0.0435 * size}px ${
                  0.08692 * size
                }px rgba(48, 0, 0, 0.5)`,
          }}
        />
        <Typography
          className={classes.typographyDiv}
          style={{
            fontSize: clamp
              ? `clamp(${0.6519 * min}px,${0.6519 * size}vw,${0.6519 * max}px)`
              : `${0.6519 * size}px`,
            lineHeight: clamp
              ? `${0.9561 * min}px,${0.9561 * size}vw,${0.9561 * max}px`
              : `${0.9561 * size}px`,
            textShadow: clamp
              ? `0px clamp(${0.015 * min}px,${0.015 * size}vw,${
                  0.015 * max
                }px) clamp(${0.015 * min}px,${0.015 * size}vw,${
                  0.015 * max
                }px) rgba(0, 0, 0, 0.5)`
              : `0px ${0.015 * size}px ${0.015 * size}px rgba(0, 0, 0, 0.5)`,
          }}
        >
          {number}
        </Typography>
      </div>
    </div>
  );
};

export default HotSpot;
