import React, { useState, useEffect } from "react";
import pointStyles from "./HotSpotStyles";

const HotSpot = (props) => {
  const { view, id, x, y, zoom, subformAnswers, points, d, currentPoint } =
    props;

  const [pointAnswerObj, setPointAnswerObj] = useState(undefined);
  const [pointAnswer, setPointAnswer] = useState(undefined);

  useEffect(() => {
    setPointAnswerObj(
      subformAnswers?.[points.findIndex((point) => point.point_title === id)]
    );
  }, [subformAnswers, points, id]);

  useEffect(() => {
    if (pointAnswerObj !== undefined) {
      setPointAnswer(
        pointAnswerObj[
          Object.keys(
            subformAnswers?.[
              points.findIndex((point) => point.point_title === id)
            ]
          )[0]
        ]
      );
    }
  }, [pointAnswerObj]);

  const getPointStyle = (id) => {
    return {
      ...{
        ...(points?.[currentPoint]?.point_title === id
          ? pointStyles.currentPoint
          : pointStyles.upcomingPoint),
      },
      ...{
        ...(pointAnswerObj !== undefined // not answered yet
          ? pointAnswer !== null // not hidden
            ? pointStyles.answeredPoint
            : pointStyles.hiddenPoint
          : pointStyles.unansweredPoint),
      },
    };
  };
  return (
    <>
      <path id={id} d={d} style={{ ...getPointStyle(id) }} />
      <text
        fill="white"
        xmlSpace="preserve"
        // style="white-space: pre"
        font-family="Sans-serif"
        font-size={`${9.5 / zoom}`}
        letter-spacing="0em"
        style={
          view.includes("Right") || view.includes("يمين")
            ? {
                transform: "translate(0,14px) rotate(180deg)",
                transformOrigin: `${x + 10}px ${y + (9.5 / zoom - 9.5) / 2}px`, // added 10 because i subtracted 10 in the svg's components and i didnot want to change them all
                color: `${zoom}`,
              }
            : {
                transform: `translate(0,-${(9.5 / zoom - 9.5) / 2}px)`,
              }
        }
      >
        <tspan x={x + 10} y={y} textAnchor="middle">
          {/* // added 10 because i subtracted 10 in the svg's components and i didnot want to change them all */}
          {pointAnswer}
        </tspan>
      </text>
      {pointAnswerObj !== undefined && // not answered yet
        pointAnswer === null && (
          <g
            id={`hidden-${id}`}
            style={{
              transform: `translate(${(x - 117) * zoom}px,${
                (y - 16) * zoom
              }px) rotate(${
                view.includes("Right") || view.includes("يمين") ? 180 : 0
              }deg)`,
              transformOrigin: "127px 23px",
              scale: `${1 / zoom}`,
            }}
          >
            <rect
              id="Rectangle 211"
              x={"123.355"}
              y="8"
              width="13.2"
              height="1.76505"
              rx="0.882524"
              transform="rotate(45 123.355 8)"
              fill="white"
            />
            <rect
              id="Rectangle 212"
              width="13.2"
              height="1.76505"
              rx="0.882524"
              transform="matrix(-0.707107 0.707107 0.707107 0.707107 131.332 8)"
              fill="white"
            />
          </g>
        )}
    </>
  );
};

export default HotSpot;
