import React from "react";

const FeatureIcon2 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.7664 17.0088C40.5073 14.2339 32.7882 12.8226 25.0031 12.8504L24.997 12.8504C17.2125 12.8229 9.49382 14.2323 2.2339 17.0034L13.7684 36.8278C17.4735 35.4383 21.3753 34.7599 25.2725 34.7929L23.5116 28.0446L18.6793 19.7394C18.4432 19.3336 18.5807 18.8133 18.9865 18.5772C19.3922 18.3411 19.9126 18.4787 20.1486 18.8844L28.5123 33.2587C28.7483 33.6645 28.6108 34.1848 28.205 34.4209C27.7993 34.6569 27.279 34.5194 27.0429 34.1136L26.6971 33.5194L27.0462 34.857C30.1666 35.0566 33.2643 35.7135 36.2356 36.8278L47.7664 17.0088ZM26.8508 36.5481C30.063 36.7443 33.251 37.4522 36.2883 38.6718C36.6799 38.829 37.1275 38.6752 37.3397 38.3105L49.7347 17.0062C49.859 16.7926 49.8841 16.5356 49.8037 16.302C49.7233 16.0684 49.5453 15.8814 49.3159 15.7896C41.5892 12.6965 33.3313 11.1211 25 11.1504C16.6695 11.1214 8.4121 12.6948 0.684531 15.7837C0.455067 15.8754 0.276878 16.0624 0.196384 16.2961C0.115889 16.5297 0.141058 16.7868 0.265334 17.0004L12.6642 38.3105C12.8764 38.6752 13.3241 38.829 13.7156 38.6718C17.3656 37.2062 21.2331 36.4795 25.0988 36.4919L23.6182 41.6736C23.1116 41.9247 22.7603 42.4451 22.7603 43.0527C22.7603 43.9091 23.4581 44.5922 24.3052 44.5922C25.1522 44.5922 25.8501 43.9091 25.8501 43.0527C25.8501 42.5888 25.6453 42.1758 25.3231 41.8946L26.8508 36.5481ZM24.3052 43.2132C24.3847 43.2132 24.4603 43.1475 24.4603 43.0527C24.4603 42.9579 24.3847 42.8922 24.3052 42.8922C24.2257 42.8922 24.1501 42.9579 24.1501 43.0527C24.1501 43.1475 24.2257 43.2132 24.3052 43.2132Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon2;
