import React from "react";

const FeatureIcon16 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M25 0.78125C11.6461 0.78125 0.78125 11.6453 0.78125 25C0.78125 38.3547 11.6461 49.2188 25 49.2188C38.3539 49.2188 49.2188 38.3547 49.2188 25C49.2188 11.6453 38.3539 0.78125 25 0.78125ZM25 47.6562C12.507 47.6562 2.34375 37.4922 2.34375 25C2.34375 12.5078 12.507 2.34375 25 2.34375C37.493 2.34375 47.6562 12.5078 47.6562 25C47.6562 37.4922 37.493 47.6562 25 47.6562Z" fill="#07303E"/>
<path d="M25 5.46875C14.2305 5.46875 5.46875 14.2305 5.46875 25C5.46875 35.7695 14.2305 44.5312 25 44.5312C35.7695 44.5312 44.5312 35.7695 44.5312 25C44.5312 14.2305 35.7695 5.46875 25 5.46875ZM42.5359 28.9062H36.3555C35.868 28.9062 35.3875 28.9539 34.9187 29.0453C35.0711 28.2328 35.1562 27.4031 35.1562 26.5625C35.1562 23.4883 34.1102 20.5984 32.3031 18.4375C37.432 19.0805 41.2352 20.2008 42.4164 20.5766C42.7758 21.9922 42.9688 23.4742 42.9688 25C42.9688 26.3414 42.8164 27.6477 42.5359 28.9062ZM25 7.03125C32.7188 7.03125 39.3148 11.9234 41.8539 18.7703C39.0984 17.9695 32.7344 16.4062 25 16.4062C17.2656 16.4062 10.9016 17.9695 8.14609 18.7703C10.6852 11.9234 17.2812 7.03125 25 7.03125ZM7.03125 25C7.03125 23.4742 7.22422 21.9922 7.58359 20.5766C8.76406 20.2008 12.5648 19.0797 17.6977 18.4367C15.8898 20.5977 14.8438 23.4875 14.8438 26.5625C14.8438 27.4031 14.9289 28.2328 15.0805 29.0453C14.6125 28.9539 14.132 28.9062 13.6438 28.9062H7.46406C7.18359 27.6477 7.03125 26.3414 7.03125 25ZM7.88516 30.4688H13.6445C15.2172 30.4688 16.6953 31.0813 17.8078 32.193C18.9188 33.3055 19.5312 34.7828 19.5312 36.3555V42.1148C14.0148 40.3484 9.65156 35.9852 7.88516 30.4688ZM21.0938 42.5359V36.3555C21.0938 34.3656 20.3188 32.4953 18.9117 31.0883C18.293 30.4695 17.5797 29.9805 16.8102 29.618C16.5469 28.6328 16.4062 27.6078 16.4062 26.5625C16.4062 23.1875 17.8148 20.0766 20.1727 18.1773C21.7039 18.0477 23.3211 17.9688 25 17.9688C26.6789 17.9688 28.2969 18.0484 29.8297 18.1773C32.1859 20.0781 33.5938 23.1891 33.5938 26.5625C33.5938 27.6078 33.4531 28.6328 33.1891 29.618C32.4195 29.9805 31.707 30.4688 31.0875 31.0883C29.6812 32.4953 28.9062 34.3656 28.9062 36.3555V42.5359C27.6477 42.8164 26.3414 42.9688 25 42.9688C23.6586 42.9688 22.3523 42.8164 21.0938 42.5359ZM30.4688 42.1148V36.3555C30.4688 34.7828 31.0813 33.3055 32.193 32.193C33.3047 31.0813 34.7836 30.4688 36.3562 30.4688H42.1156C40.3484 35.9852 35.9852 40.3484 30.4688 42.1148Z" fill="#07303E"/>
<path d="M30.4688 25C30.4688 21.9852 28.0156 19.5312 25 19.5312C21.9844 19.5312 19.5312 21.9852 19.5312 25C19.5312 28.0148 21.9844 30.4688 25 30.4688C28.0156 30.4688 30.4688 28.0148 30.4688 25ZM21.0938 25C21.0938 22.8461 22.8461 21.0938 25 21.0938C27.1539 21.0938 28.9062 22.8461 28.9062 25C28.9062 27.1539 27.1539 28.9062 25 28.9062C22.8461 28.9062 21.0938 27.1539 21.0938 25Z" fill="#07303E"/>
<path d="M39.8438 21.0938H38.2812C37.4195 21.0938 36.7188 21.7945 36.7188 22.6562V25.7812C36.7188 26.643 37.4195 27.3438 38.2812 27.3438H39.8438C40.7055 27.3438 41.4062 26.643 41.4062 25.7812V22.6562C41.4062 21.7945 40.7055 21.0938 39.8438 21.0938ZM38.2812 25.7812V22.6562H39.8438L39.8445 25.7812H38.2812Z" fill="#07303E"/>
<path d="M11.7188 21.0938H10.1562C9.29453 21.0938 8.59375 21.7945 8.59375 22.6562V25.7812C8.59375 26.643 9.29453 27.3438 10.1562 27.3438H11.7188C12.5805 27.3438 13.2812 26.643 13.2812 25.7812V22.6562C13.2812 21.7945 12.5805 21.0938 11.7188 21.0938ZM10.1562 25.7812V22.6562H11.7188L11.7195 25.7812H10.1562Z" fill="#07303E"/>
<path d="M22.6562 36.7188H27.3438V38.2812H22.6562V36.7188Z" fill="#07303E"/>
<path d="M22.6562 33.5938H27.3438V35.1562H22.6562V33.5938Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon16;
