import { put, takeLatest, takeEvery, takeLeading } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  CREATE_EMPTY_CAR_REQUEST,
  CREATE_EMPTY_CAR_REQUEST_SUCCEED,
  CREATE_EMPTY_CAR_REQUEST_FAILURE,
  UPDATE_CAR_FORM_REQUEST,
  UPDATE_CAR_FORM_REQUEST_SUCCEED,
  UPDATE_CAR_FORM_REQUEST_FAILURE,
  UPDATE_DTC_FORM_REQUEST,
  UPDATE_DTC_FORM_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST,
  FETCH_CAR_REQUEST_SUCCEED,
  FETCH_CAR_REQUEST_FAILURE,
  FETCH_CAR_FIELDS_REQUEST,
  FETCH_CAR_FIELDS_REQUEST_SUCCEED,
  FETCH_CAR_FIELDS_REQUEST_FAILURE,
  FETCH_CAR_FORMS_REQUEST,
  FETCH_CAR_FORMS_REQUEST_SUCCEED,
  FETCH_CAR_FORMS_REQUEST_FAILURE,
  FETCH_CAR_FEATURES_REQUEST,
  FETCH_CAR_FEATURES_REQUEST_SUCCEED,
  FETCH_CAR_FEATURES_REQUEST_FAILURE,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_FAULTS_REQUEST_SUCCEED,
  FETCH_CAR_FAULTS_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
  FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
  UPDATE_CAR_VISUALS_REQUEST,
  UPDATE_CAR_VISUALS_REQUEST_SUCCEED,
  UPDATE_CAR_VISUALS_REQUEST_FAILURE,
  LIST_CARS_REQUEST,
  LIST_CARS_REQUEST_SUCCEED,
  LIST_CARS_REQUEST_FAILURE,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST_SUCCEED,
  FETCH_CAR_DETAILS_REQUEST_FAILURE,
  FETCH_CAR_GROUPS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST_SUCCEED,
  FETCH_CAR_GROUPS_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
  SUBMIT_CAR_TO_QC,
  SUBMIT_CAR_TO_QC_SUCCEED,
  SUBMIT_CAR_TO_QC_FAILURE,
  PUBLISH_CAR_FOR_BUYERS_REQUEST,
  PUBLISH_CAR_FOR_BUYERS_REQUEST_SUCCEED,
  PUBLISH_CAR_FOR_BUYERS_REQUEST_FAILURE,
  PUBLISH_CAR_FOR_SELLER_REQUEST,
  PUBLISH_CAR_FOR_SELLER_REQUEST_SUCCEED,
  PUBLISH_CAR_FOR_SELLER_REQUEST_FAILURE,
  FETCH_CAR_GENERIC_FEATURES_REQUEST,
  FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED,
  FETCH_CAR_GENERIC_FEATURES_REQUEST_FAILURE,
  REMOVE_UPDATED_FORM,
  UPDATE_MAPPED,
  UPDATE_MAPPED_SUCCEED,
  INITIATING_PAPRAZZI_SUCCEED,
  INITIATING_PAPRAZZI,
  GET_DTC_MAPPING,
  GET_DTC_MAPPING_SUCCESS,
  GET_DTC_MAPPING_FAILURE,
  FETCH_CAR_INVOKED,
  FETCH_CAR_INVOKED_FAILURE,
  FETCH_CAR_INVOKED_SUCCEED,
  FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED,
  FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE,
  FETCH_CAR_DETAILS_IMAGES_REQUEST,
  CHANGE_BG_IMAGE,
  CHANGE_BG_IMAGE_SUCCEED,
  CHANGE_BG_IMAGE_FAILURE,
  FETCH_IMAGE_DOWNLOAD,
  FETCH_IMAGE_DOWNLOAD_SUCCEED,
  FETCH_IMAGE_DOWNLOAD_FAILURE,
  BUILD_REPORT,
  FETCH_CAR_IDS_TITLES_LIST,
  // GET_MAP_SUCCEED,
} from "../constants";

import API_URLS from "../../api";

export function* createCar(action) {
  try {
    let data = {
      url: API_URLS("").CAR.LIST,
      method: "POST",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    yield put({ type: CREATE_EMPTY_CAR_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: CREATE_EMPTY_CAR_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* createCarWatcher() {
  yield takeLatest(CREATE_EMPTY_CAR_REQUEST, createCar);
}

export function* submitCarToQC(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.carId}`).CAR.DETAILS,
      method: "PUT",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    yield put({ type: SUBMIT_CAR_TO_QC_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    action.payload.callback();
    yield put({ type: SUBMIT_CAR_TO_QC_FAILURE, error: new Error(e) });
  }
}

export function* submitCarToQCWatcher() {
  yield takeLatest(SUBMIT_CAR_TO_QC, submitCarToQC);
}

export function* updateDtcForm(action) {
  console.log("qqqqqqqqqqqqqqqqq", action);
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.UPDATE_DTC_FORM,
      method: "POST",
      body: { carId: action.payload.carId, answers: action.payload.answers },
    };
    const response = yield requestAPI(data);
    yield put({ type: UPDATE_DTC_FORM_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (error) {
    console.log("qqqqqqqqqqqqqqqqq", error);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateDtcFormWatcher() {
  yield takeEvery(UPDATE_DTC_FORM_REQUEST, updateDtcForm); // for saved submit
}

export function* updateMissedMapping(action) {
  try {
    let data = {
      url: API_URLS().COVERT_PDF.FILLMISSEDMAPPING,
      method: "POST",
      body: { answers: action.payload.answers, carId: action.payload.carId },
    };
    const response = yield requestAPI(data);
    // yield put({ type: UPDATE_MAPPED_SUCCEED, payload: response });
    console.log("xxxxxxxxxxxxxxxxxxffffffffffffffffffff", response);
    action.payload.callback(response);
  } catch (error) {
    console.log("xxxxxxxxxxxff", error);
  }
}

export function* updateMissedMappingWatcher() {
  yield takeEvery(UPDATE_MAPPED, updateMissedMapping); // for saved submit
}

export function* updateCarForm(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.UPDATE_FORM,
      method: "POST",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    if (response.status == "success") {
      yield put({ type: UPDATE_CAR_FORM_REQUEST_SUCCEED, payload: response });
      yield put({ type: REMOVE_UPDATED_FORM, payload: action.payload });
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          //to display a notification
          message: "Your changes have been saved",
          variant: "success",
          open: true,
        },
      });
      action.payload.callback({ status: "success", response });
    } else {
      yield put({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          message: `Oops! an error has occured in ${response.field} field, please try again.`,
          variant: "error",
          open: true,
        },
      });
      action.payload.callback({ status: "failure", response });
    }
  } catch (e) {
    action.payload.callback({ status: "failure" }); ////////// check this later
    yield put({ type: UPDATE_CAR_FORM_REQUEST_FAILURE, error: new Error(e) });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateCarFormWatcher() {
  yield takeEvery(UPDATE_CAR_FORM_REQUEST, updateCarForm); // for saved submit
}

export function* getCar(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.DETAILS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarWatcher() {
  yield takeLatest(FETCH_CAR_REQUEST, getCar);
}

export function* getCarForms(action) {
  try {
    const { formId, carId } = action.payload;
    let data = {
      url: API_URLS(`?car_id=${carId}&form=${formId}`).CAR.CAR_FORMS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FORMS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_CAR_FORMS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarFormsWatcher() {
  yield takeLatest(FETCH_CAR_FORMS_REQUEST, getCarForms);
}

export function* getCarFields(action) {
  try {
    const { formId, carId } = action.payload;
    let data = {
      url: API_URLS(`?car_id=${carId}&form=${formId}`).CAR.CAR_FORMS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FIELDS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_CAR_FIELDS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarFieldsWatcher() {
  yield takeLatest(FETCH_CAR_FIELDS_REQUEST, getCarFields);
}

export function* getCarFeatures(action) {
  try {
    const { carId, featureForm } = action.payload;
    let data = {
      url: API_URLS(`car_id=${carId}&field_id__form=${featureForm}`).CAR
        .CAR_FEATURES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FEATURES_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({
      type: FETCH_CAR_FEATURES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarFeaturesWatcher() {
  yield takeLatest(FETCH_CAR_FEATURES_REQUEST, getCarFeatures);
}

export function* getCarGenericFeatures(action) {
  try {
    const { carId } = action.payload;
    let data = {
      url: API_URLS(`car_id=${carId}`).CAR.CAR_GENERIC_FEATURES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED,
      payload: response.results,
    });
  } catch (e) {
    yield put({
      type: FETCH_CAR_GENERIC_FEATURES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarGenericFeaturesWatcher() {
  yield takeLatest(FETCH_CAR_GENERIC_FEATURES_REQUEST, getCarGenericFeatures);
}

export function* getCarFaults(action) {
  try {
    const { carId, side, position } = action.payload;
    let data = {
      url: API_URLS(
        `car_id=${carId}&field__side=${side}&field__position=${position}`
      ).CAR.CAR_FAULTS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_FAULTS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_CAR_FAULTS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarFaultsWatcher() {
  yield takeLatest(FETCH_CAR_FAULTS_REQUEST, getCarFaults);
}

export function* reportBuilder(action) {
  try {
    const { carId } = action.payload;
    let data = {
      url: API_URLS(`car_id=${carId}`).CAR.CAR_BLOCKING,
      method: "POST",
      body: action.payload,
    };
    const response = yield requestAPI(data);
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "car report genrated successfuly.",
        variant: "success",
        open: true,
      },
    });
    // yield put({
    //   type: FETCH_CAR_FAULTS_REQUEST_SUCCEED,
    //   payload: response.results,
    // });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
    // yield put({ type: FETCH_CAR_FAULTS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* reportBuilderWatcher() {
  yield takeLatest(BUILD_REPORT, reportBuilder);
}

export function* getCarVisualization(action) {
  try {
    const { query } = action.payload;
    let data = {
      url: API_URLS(query).CAR.CAR_VISUALIZATION,
      method: "GET",
    };
    const response = yield requestAPI(data);
    let points = {};
    for (let i = 0; i < response.results.length; i++) {
      points[response.results[i].field.side] = response.results[i];
    }
    let first = action.payload.first;

    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
      payload: { points: points, first: first },
    });
    action.payload.callback(points);
  } catch (e) {
    yield put({
      type: FETCH_CAR_VISUALIZATION_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarVisualizationWatcher() {
  yield takeLatest(FETCH_CAR_VISUALIZATION_REQUEST, getCarVisualization);
}

export function* updateCarVisuals(action) {
  try {
    let data = {
      url: API_URLS(action.payload.id).CAR.UPDATE_VISUALS,
      method: "POST",
      body: action.payload.body,
      position: action.payload?.position, // added position to specify images to be deleted if form is ext and not is interior or viceversa(ext or int) or is both(undefined)
    };
    const response = yield requestAPI(data);

    yield put({ type: UPDATE_CAR_VISUALS_REQUEST_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: UPDATE_CAR_VISUALS_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* updateCarVisualsWatcher() {
  yield takeEvery(UPDATE_CAR_VISUALS_REQUEST, updateCarVisuals); // for saved submit
}

export function* getCarList(action) {
  try {
    let data = {
      url: API_URLS(action.payload.filter).CAR.LIST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: LIST_CARS_REQUEST_SUCCEED, payload: response.results });
  } catch (e) {
    yield put({ type: LIST_CARS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarListWatcher() {
  yield takeLatest(LIST_CARS_REQUEST, getCarList);
}

export function* publishCarForBuyers(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.car_id}`).CAR.PUBLISH_CAR_FOR_BUYERS,
      method: "POST",
    };
    const response = yield requestAPI(data);
    yield put({
      type: PUBLISH_CAR_FOR_BUYERS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback({ result: true });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car successfully published for buyers and seller.",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({
      type: PUBLISH_CAR_FOR_BUYERS_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* publishCarForSeller(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.car_id}`).CAR.PUBLISH_CAR_FOR_SELLER,
      method: "POST",
    };
    const response = yield requestAPI(data);
    yield put({
      type: PUBLISH_CAR_FOR_SELLER_REQUEST_SUCCEED,
      payload: response.results,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Car successfully published for seller.",
        variant: "success",
        open: true,
      },
    });
  } catch (e) {
    yield put({
      type: PUBLISH_CAR_FOR_SELLER_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* publishCarForBuyersWatcher() {
  yield takeLatest(PUBLISH_CAR_FOR_BUYERS_REQUEST, publishCarForBuyers);
}

export function* publishCarForSellerWatcher() {
  yield takeLatest(PUBLISH_CAR_FOR_SELLER_REQUEST, publishCarForSeller);
}

export function* getCarDetail(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.PAGE,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_DETAILS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarDetailsWatcher() {
  yield takeLatest(FETCH_CAR_DETAILS_REQUEST, getCarDetail);
}

export function* getCarGroups(action) {
  try {
    const query = action.payload.carId + "/?" + action.payload.query;
    let data = {
      url: API_URLS(query).CAR.CAR_GROUPED_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_SUCCEED, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_GROUPS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* getCarGroupsWatcher() {
  yield takeLatest(FETCH_CAR_GROUPS_REQUEST, getCarGroups);
}

export function* initiatingPaparazzi(action) {
  try {
    console.log("qqqqqqqqqqqqqqq tttttttttttttttttt", action);
    yield put({ type: INITIATING_PAPRAZZI_SUCCEED, payload: action.payload });
    console.log("qqqqqqqqqqqqqqq bbbbbbbbbbbbbbbb", action);
    action.payload.callback();
  } catch (e) {
    console.log("qqqqqqqqqqerror");
  }
}

export function* initiatingPaparazziWatcher() {
  yield takeLatest(INITIATING_PAPRAZZI, initiatingPaparazzi);
}

// export function* getMap(action) {
//   try {
//     const query = `?subform=${action.payload.form}& body_type_config_id=${action.payload.query}`;
//     let data = {
//       url: API_URLS(query).EAGLE_EYE.MAP,
//       method: "GET",
//     };
//     const response = yield requestAPI(data);
//     yield put({ type: GET_MAP_SUCCEED, payload: response });
//     action.payload.callback(response);
//   } catch (e) {
//     yield put({ type: FETCH_CAR_GROUPS_REQUEST_FAILURE, error: new Error(e) });
//   }
// }

// export function* getMapWatcher() {
//   yield takeLatest(FETCH_CAR_GROUPS_REQUEST, getMap);
// }

export function* getDTCMapping(action) {
  try {
    let data = {
      url: API_URLS().CAR.DTC_MAPPING,
      // method: "GET",
      method: "POST",
      body: {
        answeredSystems: action.payload.answeredSystems,
        carId: action.payload.carId,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: GET_DTC_MAPPING_SUCCESS, payload: response });
    action.payload.callback(response);
  } catch (e) {
    yield put({ type: GET_DTC_MAPPING_FAILURE, error: new Error(e) });
  }
}

export function* getDTCMappingWatcher() {
  yield takeLatest(GET_DTC_MAPPING, getDTCMapping);
}

export function* getCarInvoked(action) {
  try {
    const query = action.payload.carId;
    let data = {
      url: API_URLS(query).CAR.CAR_INVOKED,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_CAR_INVOKED_SUCCEED, payload: response });
    // action.payload.callback(response);
  } catch (e) {
    yield put({ type: FETCH_CAR_INVOKED_FAILURE, error: new Error(e) });
  }
}

export function* getCarInvokedWatcher() {
  yield takeLatest(FETCH_CAR_INVOKED, getCarInvoked);
}

export function* getCarDetailImages(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).CAR.DETAILS_IMAGES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED,
      payload: response,
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getCarDetailsImagesWatcher() {
  yield takeLatest(FETCH_CAR_DETAILS_IMAGES_REQUEST, getCarDetailImages);
}

export function* changeBgImages(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).PAPARAZZI.BGEDITOR,
      method: "POST",
      body: action.payload.body,
    };
    const response = yield requestAPI(data);
    yield put({
      type: CHANGE_BG_IMAGE_SUCCEED,
      payload: response,
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Images Successfully Changed",
        variant: "success",
        open: true,
      },
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: CHANGE_BG_IMAGE_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message: "Oops! an error has occured! please try again.",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* changeBgImagesWatcher() {
  yield takeLeading(CHANGE_BG_IMAGE, changeBgImages);
}

export function* getDownloadImages(action) {
  try {
    let data = {
      url: API_URLS(action.payload.carId).PAPARAZZI.IMAGEDOWNLOAD,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_IMAGE_DOWNLOAD_SUCCEED,
      payload: response,
    });
    action.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_IMAGE_DOWNLOAD_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getDownloadImagesWatcher() {
  yield takeLatest(FETCH_IMAGE_DOWNLOAD, getDownloadImages);
}

export function* getCarIdsTitlesList(action) {
  try {
    let data = {
      url: API_URLS().CAR.CAR_IDS_TITLES_LIST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    action.payload.callback(response);
  } catch (e) {
    console.log("FETCH_CAR_IDS_TITLES_LIST error", e);
  }
}

export function* getCarIdsTitlesListWatcher() {
  yield takeLatest(FETCH_CAR_IDS_TITLES_LIST, getCarIdsTitlesList);
}
