import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BodyPaintSedan from "./BodyPaintSedan";
import BodyPaintCoupe from "./BodyPaintCoupe";
import BodyPaintPickup2Doors from "./BodyPaintPickup2Doors";
import BodyPaintPickup4Doors from "./BodyPaintPickup4Doors";

const useStyles = makeStyles((theme) => ({}));

const BodyPaintResult = (props) => {
  const { t } = useTranslation();
  const { body_type, fields } = props;
  const [dest, setDest] = useState([]);
  const classes = useStyles();
  let arr = [];
  useEffect(() => {
    fields.map((item) => {
      let key = item.field_id;
      var obj = {};
      let y = item.field_score;
      obj[key] = y;
      arr.push(obj);
      setDest(arr);
    });
  }, []);
  return body_type == "Sedan" ? (
    <BodyPaintSedan fields={fields} />
  ) : body_type == "Coupe" ? (
    <BodyPaintCoupe fields={fields} />
  ) : body_type == "Convertible" ? (
    <BodyPaintCoupe roofColor={"White"} fields={fields} />
  ) : body_type == "Pickup (2 Doors)" ? (
    <BodyPaintPickup2Doors fields={fields} />
  ) : body_type == "Pickup (4 Doors)" ? (
    <BodyPaintPickup4Doors fields={fields} />
  ) : null;
};

const mapStateToProps = (state) => ({
  body_type: state.car.car?.body_type_config?.body_type,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BodyPaintResult)
);
