import React from "react";

const AddNewSystemIcon = (props) => {
  const { size, fill, style, className, pathColor, selected } = props;
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 53 53"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.03263 26.2771C2.03263 39.8561 13.0771 50.9006 26.6561 50.9006C40.2351 50.9006 51.2796 39.8561 51.2796 26.2771C51.2796 12.6981 40.2351 1.65363 26.6561 1.65363C13.0771 1.65363 2.03263 12.6981 2.03263 26.2771Z"
        fill={pathColor}
        stroke="white"
        stroke-width="3.28313"
      />
      <path
        d="M26.6538 38.8411C24.7932 38.8411 23.2831 37.3402 23.2831 35.4908V17.0644C23.2831 15.2151 24.7932 13.7142 26.6538 13.7142C28.5144 13.7142 30.0245 15.2151 30.0245 17.0644V35.4908C30.0245 37.3402 28.5144 38.8411 26.6538 38.8411Z"
        fill="white"
      />
      <path
        d="M35.8689 29.6473H17.4425C15.5932 29.6473 14.0923 28.1372 14.0923 26.2766C14.0923 24.416 15.5932 22.9059 17.4425 22.9059H35.8689C37.7183 22.9059 39.2192 24.416 39.2192 26.2766C39.2192 28.1372 37.7183 29.6473 35.8689 29.6473Z"
        fill="white"
      />
    </svg>
  );
};

export default AddNewSystemIcon;
