import React from "react";
import FaultCard from "./FaultCard";

function CardIterator(props) {
  const { carInfo } = props;
  return (
    <>
      {props.fields.extended_answers.map((flt) => (
        <div style={{ margin: "0" }}>
          <FaultCard
            googlelink={`https://www.google.com/search?q=${carInfo.card.make_en}+${carInfo.card.model_en}+${carInfo.card.year}+fault+code+${flt.fault}`}
            fault={flt.fault}
            state={flt.state}
            system={flt.system}
            desc={flt.description}
          ></FaultCard>
        </div>
      ))}
    </>
  );
}

export default CardIterator;
