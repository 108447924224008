import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import CheckCircle from "@material-ui/icons/CheckCircle";
import UncheckCircle from "@material-ui/icons/RadioButtonUnchecked";
import colors from "../theme/colors";
import { Typography } from "@material-ui/core";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // display: "inline-flex",
    display: "flex",

    minHeight: "76vh",
    width: "-webkit-fill-available",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 320,
  },
  tabWrapper: {
    alignItems: "start",
  },
  spanDiv: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  selected: {
    // background: colors.cssGradient.primary,
    background: "#e0e0e0",
    borderRight: `3px solid #f15a29`,
    color: "#fff",
    color: "#000",
  },
  tabRoot: {
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    maxWidth: "unset",
  },
  selectedError: {
    // background: colors.cssGradient.primary,
    background: "#e0e0e0",
    backgroundColor: "#ffff00",
    borderRight: `3px solid #f15a29`,
    color: "#000",
  },
  tabRootError: {
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    maxWidth: "unset",
    // color: "#DD4B4B",
    background: "#ffff00",
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const { onChange, tabs, tillIndex, updated_forms } = props;

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={props.index}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {tabs?.map((tab, index) => (
          <Tab
            label={
              <div className={classes.spanDiv}>
                {tab?.is_done && !updated_forms?.includes(tab?.id) ? (
                  <CheckCircle style={{ margin: "0 8px" }} />
                ) : (
                  <UncheckCircle style={{ margin: "0 8px" }} />
                )}
                <Typography style={{ textAlign: "initial" }}>
                  {tab?.title}
                </Typography>
              </div>
            }
            classes={{
              root: clsx({
                [classes.tabRoot]: !updated_forms?.includes(tab?.id),
                [classes.tabRootError]:
                  tab?.is_done && updated_forms?.includes(tab?.id),
              }),
              wrapper: classes.tabWrapper,
              selected: clsx({
                [classes.selected]: !updated_forms?.includes(tab?.id),
                [classes.selectedError]:
                  tab?.is_done && updated_forms?.includes(tab?.id),
              }),
            }}
            key={"form_tab_" + tab?.id}
            {...a11yProps(index)}
            disabled={index > tillIndex}
          />
        ))}
      </Tabs>
      <div style={{ width: "75%" }}>{props.children}</div>
    </div>
  );
}
