import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  containerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    color: "white",
    fontSize: "32px",
    fontWeight: 700,
    margin: "16px",
  },
}));

const PapaHubBackDrop = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loading } = props;
  const { open, message } = loading;

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div className={classes.containerDiv}>
        <Typography className={classes.message}>{t(message)}</Typography>
        <CircularProgress color="inherit" />
      </div>
    </Backdrop>
  );
};

export default PapaHubBackDrop;
