import React from "react";

const FeatureIcon34 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon34Clip0)">
<path d="M31.0179 4.97981L33.2082 1.329C33.4573 0.913215 33.3231 0.374628 32.9073 0.125509C32.4907 -0.124486 31.9529 0.00972189 31.7038 0.426381L29.3925 4.27894C28.0162 3.78948 26.5425 3.50878 25.0004 3.50878C23.4584 3.50878 21.9847 3.78948 20.6084 4.27894L18.297 0.426381C18.0479 0.00972189 17.5093 -0.123609 17.0936 0.125509C16.6778 0.374628 16.5436 0.913215 16.7927 1.329L18.983 4.97981C14.7489 7.16837 11.8428 11.5806 11.8428 16.6673C11.8428 17.1524 12.2357 17.5445 12.72 17.5445H37.2809C37.7651 17.5445 38.1581 17.1524 38.1581 16.6673C38.1572 11.5814 35.252 7.16925 31.0179 4.97981ZM13.6305 15.7901C14.0796 9.91043 19.0076 5.26402 25.0004 5.26402C30.9933 5.26402 35.9213 9.91043 36.3704 15.7901H13.6305Z" fill="#07303E"/>
<path d="M31.1205 9.96826H29.9644C29.6451 9.96826 29.3863 10.227 29.3863 10.5463V11.7024C29.3863 12.0217 29.6451 12.2805 29.9644 12.2805H31.1213C31.4406 12.2805 31.6994 12.0217 31.6994 11.7024V10.5463C31.6994 10.227 31.4398 9.96826 31.1205 9.96826Z" fill="#07303E"/>
<path d="M20.0356 9.96826H18.8786C18.5593 9.96826 18.3005 10.227 18.3005 10.5463V11.7024C18.3005 12.0217 18.5593 12.2805 18.8786 12.2805H20.0356C20.3549 12.2805 20.6137 12.0217 20.6137 11.7024V10.5463C20.6137 10.227 20.3549 9.96826 20.0356 9.96826Z" fill="#07303E"/>
<path d="M37.28 18.4216H12.719C12.2348 18.4216 11.8419 18.8137 11.8419 19.2988V36.9924C11.8419 39.3283 13.7445 41.2283 16.0821 41.2283H17.1049V46.4966C17.1049 48.4281 18.6786 50 20.6136 50C22.5487 50 24.1223 48.4281 24.1223 46.4966V41.2283H25.8767V46.4966C25.8767 48.4281 27.4504 50 29.3854 50C31.3205 50 32.8941 48.4281 32.8941 46.4966V41.2283H33.9169C36.2555 41.2283 38.1572 39.3283 38.1572 36.9924V19.2988C38.1572 18.8137 37.7651 18.4216 37.28 18.4216ZM36.4028 36.9924C36.4028 38.3608 35.2879 39.4739 33.9169 39.4739H32.0169C31.5327 39.4739 31.1398 39.866 31.1398 40.3511V46.4966C31.1398 47.4615 30.3529 48.2457 29.3854 48.2457C28.4179 48.2457 27.6311 47.4606 27.6311 46.4966V40.3511C27.6311 39.866 27.2381 39.4739 26.7539 39.4739H23.2452C22.761 39.4739 22.368 39.866 22.368 40.3511V46.4966C22.368 47.4615 21.5812 48.2457 20.6136 48.2457C19.6461 48.2457 18.8593 47.4606 18.8593 46.4966V40.3511C18.8593 39.866 18.4663 39.4739 17.9821 39.4739H16.083C14.712 39.4739 13.5962 38.3599 13.5962 36.9924V20.176H36.4028V36.9924Z" fill="#07303E"/>
<path d="M42.1045 18.4216C40.4115 18.4216 39.0344 19.8006 39.0344 21.4953V33.7687C39.0344 35.4634 40.4115 36.8424 42.1045 36.8424C43.7974 36.8424 45.1746 35.4634 45.1746 33.7687V21.4953C45.1746 19.8006 43.7974 18.4216 42.1045 18.4216ZM43.4203 33.7687C43.4203 34.4959 42.8299 35.088 42.1045 35.088C41.3791 35.088 40.7887 34.4959 40.7887 33.7687V21.4953C40.7887 20.7681 41.3791 20.176 42.1045 20.176C42.8299 20.176 43.4203 20.7681 43.4203 21.4953V33.7687Z" fill="#07303E"/>
<path d="M7.89458 18.4216C6.20163 18.4216 4.82446 19.8006 4.82446 21.4953V33.7687C4.82446 35.4634 6.20163 36.8424 7.89458 36.8424C9.58754 36.8424 10.9647 35.4634 10.9647 33.7687V21.4953C10.9647 19.8006 9.58754 18.4216 7.89458 18.4216ZM9.21035 33.7687C9.21035 34.4959 8.62001 35.088 7.89458 35.088C7.16916 35.088 6.57882 34.4959 6.57882 33.7687V21.4953C6.57882 20.7681 7.16916 20.176 7.89458 20.176C8.62001 20.176 9.21035 20.7681 9.21035 21.4953V33.7687Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon34Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon34;
