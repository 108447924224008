import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Hidden from "@material-ui/core/Hidden";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CloseIcon from "@material-ui/icons/Close";
import CarDealership from "./icons/CarDealership";
import Location from "./icons/Location";
import GPSOutlined from "./icons/GPSOutlined";

const useStyles = makeStyles((theme) => ({
  endIcon: {
    margin: 0,
  },
  card: {
    marginBottom: " 1px",
    borderRadius: 0,
    height: "100%",
    display: "grid",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardContent: {
    padding: "21px 31px 0 17px",
    marginBottom: "-24px", // 24px is to over ride .MuiCardContent-root:last-child's 24px padding just a quick fix
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0 16px", // 24px is over ridden in .MuiCardContent-root:last-child just included it to explain the -9 margin just a quick fix
      margin: "0 0 -9px",
      paddingBottom: "18px!important",
    },
  },
  firstCardGrid: {
    flexBasis: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "58.82%",
      maxWidth: "inherit",
    },
  },
  cardActions: {
    padding: "22px 31px 29px",
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
      marginTop: "0",
      width: "auto",
    },
  },
  dialogPaper: {
    borderRadius: "12px",
    padding: "0px 40px 39px 36px",
    width: "420px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
      maxHeight: "none",
      maxWidth: "none",
      margin: "0",
    },
  },
  dialogRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  dialogContentText: {
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      flex: "none",
    },
  },
  ".MuiTableCell-root	": {
    display: "inline",
  },
  listingnumberTypo: { fontSize: "14px", fontWeight: "600", color: "#07303E" },
  carTitleTypo: { fontSize: "14px", fontWeight: "500", color: "#07303E" },

  continuelistingTypo: {
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    color: "#186985",
    verticalAlign: "top",
    cursor: "pointer",
  },
  continuelistingTypotwo: {
    color: "#186985",
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  listvehicleTypo: {
    cursor: "pointer",
    color: "#186985",
  },
  listvehicleTypotwo: {
    color: "#186985",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  claimTypo: {
    color: " #186985",
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer",
  },
  tablecellone: {
    verticalAlign: "top",
    whiteSpace: "nowrap",
    backgroundSize: "1px 100%",
    backgroundImage:
      "linear-gradient(to bottom, rgb(255, 255, 255) 25%, #CBC6C6 25%, #CBC6C6 50%, #CBC6C6 50%, #CBC6C6 75%, rgb(255, 255, 255) 75%)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
  },
  tablecelltwo: {
    verticalAlign: "top",
    backgroundSize: "1px 100%",
    backgroundImage:
      "linear-gradient(to bottom, rgb(255, 255, 255) 25%, #CBC6C6 25%, #CBC6C6 50%, #CBC6C6 50%, #CBC6C6 75%, rgb(255, 255, 255) 75%)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
  },
  cardDiv: { display: "flex", width: "100%", justifyContent: "space-between" },
  cardDivtwo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  submittedSinceTypo: { fontSize: "12px", color: "#07303E", fontWeight: "400" },
  tablerowmargin: { marginBottom: "2px" },
  gridContainer: { display: "block" },
  dialogtitle: { float: "left" },
  closeIcon: {
    textAlignLast: "right",
    marginTop: "26px",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  claimRequestTypo: {
    marginTop: "-24px",
    fontSize: "26px",
    fontWeight: 500,
    color: "#07303E",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  dialogActions: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      justifyContent: "center",
    },
  },
  cancelButton: { width: "106px", height: "40px", textTransform: "none" },
  dialogTitle: { display: "inline", padding: "0 0 2px", marginBottom: "18px" },
  dialogContentTextTwo: {
    textAlign: "left",
    fontSize: "14px",
    padding: "0 0 62px",
    margin: 0,
  },
  contentTypo: { fontWeight: "400", fontSize: "18px", color: "#07303E" },
  claimButton: {
    backgroundColor: "#186985",
    color: "white",
    marginLeft: "24px",
    width: "167px",
    height: "40px",
    textTransform: "none",
  },
  tableRowDivTwo: {
    justifyContent: "space-between",
    display: "flex",
    marginTop: "18.88px",
    marginLeft: "16px",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  firstTablerow: {
    backgroundColor: "#F7F7F7",
    height: "64px",
    borderBottom: "solid 1px #CBC6C6",
  },
  AnonymousTypo: {
    marginLeft: "7px",
    fontWeight: "500",
    fontSize: "14px",
    color: "#07303E",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // width: '50px',
    textOverflow: "ellipsis",
  },
  getDirectionTypo: {
    color: "#186985",
    whiteSpace: "nowrap",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  verticalaligntop: { verticalAlign: "top", borderRight: "solid 1px #CBC6C6" },
  cityTypo: {
    color: "#727373",
    marginLeft: "4px",
    marginRight: "4px",
    fontWeight: "500",
    fontSize: "14px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  callDiv: {
    display: "flex",
    marginRight: "16px",
  },
}));

const ClaimDialog = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { open, handleClose, handleClaim } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialogPaper,
        root: classes.dialogRoot,
      }}
    >
      <div className={classes.closeIcon}>
        <CloseIcon onClick={handleClose} />
      </div>
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={true}
        className={classes.dialogTitle}
      >
        <Typography className={classes.claimRequestTypo}>
          {t("Claim Request")}
        </Typography>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContentText }}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentTextTwo}
        >
          <Typography className={classes.contentTypo}>
            {t("Are you sure that you want to claim this request")}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          // autoFocus
          className={classes.cancelButton}
        >
          {t("Cancel")}
        </Button>
        <Button className={classes.claimButton} onClick={handleClaim}>
          {t("Claim Request")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ListingRequestCard = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const {
    city,
    area,
    createdAt,
    id,
    Claim,
    page,
    handleAddCar,
    gpsLocation,
    started_at,
    carId,
    carTitle,
    day,
    time,
    runnerAccount,
    dealership_name,
    claimed,
    car_details,
    car_details_claimed,
    dealership_phone,
  } = props;
  const [open, setOpen] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  let submittedSince = Math.floor((new Date() - new Date(createdAt)) / 3600000);
  submittedSince > 24
    ? (submittedSince = Math.floor(submittedSince / 24))
    : (submittedSince = submittedSince);
  const handleClick = () => {
    history.push("/car-form/" + carId);
  };
  const handleClickOpen = () => {
    setOpen(true);
    document.getElementById("root").style.filter = "blur(3px)";
    // adds blur to root element
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
  };
  const handleCloseNotes = () => {
    setOpenNotes(false);
    document.getElementById("root").style.filter = "none"; // removes blur from root element
  };
  const handleClaim = () => {
    Claim(id);
    handleClose();
  };
  return (
    <>
      <Hidden smDown>
        <TableRow>
          {runnerAccount && (
            <TableCell className={classes.tablecellone} align="left">
              <Typography className={classes.carTitleTypo}>
                {t("Independent Seller")}
              </Typography>
            </TableCell>
          )}
          {runnerAccount &&
            (!claimed ? (
              <TableCell className={classes.tablecellone} align="left">
                <Typography className={classes.carTitleTypo}>
                  {claimed ? car_details_claimed.city : car_details.city} ,
                  {claimed ? car_details_claimed.area : car_details.area}
                </Typography>
              </TableCell>
            ) : (
              <TableCell
                // rowSpan={dealership.requests.length + 1}
                align="left"
                className={classes.verticalaligntop}
              >
                {car_details_claimed?.gps_location?.includes("google") ||
                car_details_claimed?.gps_location?.includes("goo.gl") ? (
                  <a
                    target="_blank"
                    href={`maps:${car_details_claimed.gps_location}`}
                  >
                    <Typography
                      className={classes.getDirectionTypo}
                      style={
                        localStorage.getItem("language") == "en"
                          ? { flexDirection: "row" }
                          : {
                              flexDirection: "row-reverse",
                              justifyContent: "flex-end",
                            }
                      }
                    >
                      {t("GPS")}
                      <GPSOutlined
                        size="13"
                        fill="#186985"
                        style={{ marginInline: "6px" }}
                      />
                    </Typography>
                  </a>
                ) : (
                  "N/A"
                )}
              </TableCell>
            ))}
          {claimed && (
            <TableCell align="left" className={classes.verticalaligntop}>
              {runnerAccount
                ? car_details_claimed?.phone_number
                : dealership_phone}
            </TableCell>
          )}
          <TableCell className={classes.tablecellone} align="left">
            <Typography className={classes.listingnumberTypo}>
              {"#"}
              {id}
            </Typography>
            {runnerAccount && (
              <Typography className={classes.carTitleTypo}>
                {carTitle}
              </Typography>
            )}
          </TableCell>
          <TableCell className={classes.tablecelltwo} align="left">
            {runnerAccount && !claimed
              ? `${t(day)}col (${
                  time
                    ? time.split(" ").map((el) => {
                        return el == "AM" || el == "PM" ? t(el) : el;
                      })
                    : ""
                })`
                  .replace(",", " ")
                  .replace("col", ",")
              : `${submittedSince} ${t("days ago")}`}
          </TableCell>
          {claimed && (
            <TableCell className={classes.tablecelltwo} align="left">
              <Typography
                className={classes.claimTypo}
                onClick={() => {
                  setOpenNotes(true);
                }}
              >
                {car_details_claimed?.notes == null ? "" : t("Notes")}
              </Typography>
            </TableCell>
          )}
          <TableCell align="left">
            {page == "incoming" ? (
              <>
                <Typography
                  className={classes.claimTypo}
                  onClick={handleClickOpen}
                >
                  {t("Claim Request")}
                </Typography>
                <ClaimDialog
                  open={open}
                  handleClose={handleClose}
                  handleClaim={handleClaim}
                />
              </>
            ) : started_at ? (
              <Typography
                className={classes.continuelistingTypo}
                variant="contained"
                value={id}
                onClick={handleClick}
              >
                {t("Continue Listing")}
              </Typography>
            ) : (
              <Typography
                className={classes.listvehicleTypo}
                value={id}
                onClick={() => {
                  handleAddCar(props.id);
                }}
              >
                {t("List Vehicle")}
              </Typography>
            )}
          </TableCell>
        </TableRow>
      </Hidden>
      {runnerAccount && (
        <Hidden mdUp>
          <TableRow className={classes.firstTablerow}>
            <div className={classes.tableRowDivTwo}>
              <div
                style={{
                  display: "flex",
                  flexBasis: "45%",
                  minWidth: "45%",
                  flexGrow: 0,
                }}
              >
                <CarDealership size="20" fill="#07303E" />
                <Tooltip
                  leaveDelay={2000}
                  disableFocusListener
                  title={t("Independent Seller")}
                >
                  <Typography className={classes.AnonymousTypo}>
                    {t("Independent Seller")}
                  </Typography>
                </Tooltip>
              </div>
              {claimed ? (
                <div style={{ padding: "0 10px" }}>
                  {car_details_claimed?.gps_location?.includes("google") ||
                  car_details_claimed?.gps_location?.includes("goo.gl") ? (
                    <Typography
                      className={classes.getDirectionTypo}
                      style={
                        localStorage.getItem("language") == "en"
                          ? { flexDirection: "row" }
                          : {
                              flexDirection: "row-reverse",
                              justifyContent: "flex-end",
                            }
                      }
                      onClick={() => {
                        window.location.href = car_details_claimed.gps_location;
                      }}
                    >
                      {t("GPS")}
                      <GPSOutlined
                        size="13"
                        fill="#186985"
                        style={{ marginInline: "6px" }}
                      />
                    </Typography>
                  ) : (
                    "N/A"
                  )}
                </div>
              ) : (
                <Tooltip
                  leaveDelay={2000}
                  disableFocusListener
                  title={`${t(
                    claimed ? car_details_claimed.city : car_details.city
                  )} ,
                  ${t(claimed ? car_details_claimed.area : car_details.area)}`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexBasis: "45%",
                      minWidth: "45%",
                      flexGrow: 0,
                    }}
                  >
                    <Location size="20" fill="#727373" />
                    <Typography className={classes.cityTypo}>
                      {t(claimed ? car_details_claimed.city : car_details.city)}{" "}
                      ,{" "}
                      {t(claimed ? car_details_claimed.area : car_details.area)}
                    </Typography>
                  </div>
                </Tooltip>
              )}
            </div>
          </TableRow>
        </Hidden>
      )}
      <Hidden mdUp>
        {page == "incoming" ? (
          <>
            <TableRow className={classes.tablerowmargin}>
              <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                  <Grid item className={classes.firstCardGrid}>
                    <CardContent className={classes.cardContent}>
                      <div style={{ marginBottom: "8px" }}>
                        <Typography className={classes.listingnumberTypo}>
                          {"#"}
                          {id}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.carTitleTypo}>
                          {carTitle}
                        </Typography>
                      </div>
                    </CardContent>
                  </Grid>
                  <Grid item>
                    <CardActions className={classes.cardActions}>
                      <div className={classes.cardDiv}>
                        <Typography className={classes.submittedSinceTypo}>
                          {runnerAccount
                            ? `${t(day)}col (${
                                time
                                  ? time.split(" ").map((el) => {
                                      return el == "AM" || el == "PM"
                                        ? t(el)
                                        : el;
                                    })
                                  : ""
                              })`
                                .replace(",", " ")
                                .replace("col", ",")
                            : `${submittedSince} ${t("days ago")}`}
                        </Typography>
                        <Typography
                          className={classes.claimTypo}
                          value={id}
                          endIcon={
                            localStorage.getItem("language") === "ar" ? (
                              <NavigateBeforeIcon />
                            ) : (
                              <NavigateNextIcon />
                            )
                          }
                          onClick={handleClickOpen}
                        >
                          {t("Claim Request")}
                        </Typography>
                        <ClaimDialog
                          open={open}
                          handleClose={handleClose}
                          handleClaim={handleClaim}
                        />
                      </div>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </TableRow>
          </>
        ) : (
          <>
            <TableRow className={classes.tablerowmargin}>
              <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                  <Grid item className={classes.firstCardGrid}>
                    <CardContent className={classes.cardContent}>
                      <div
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={classes.listingnumberTypo}>
                          {"#"}
                          {id}
                        </Typography>
                        <div>
                          <Typography
                            className={classes.listingnumberTypo}
                            onClick={() => {
                              setOpenNotes(true);
                            }}
                          >
                            {car_details_claimed?.notes == null
                              ? ""
                              : t("Notes")}
                          </Typography>
                        </div>
                        <a
                          href={
                            runnerAccount
                              ? "tel:" + car_details_claimed?.phone_number
                              : "tel:" + dealership_phone
                          }
                        >
                          <div className={classes.callDiv}>
                            <Typography
                              className={classes.listvehicleTypotwo}
                              style={{
                                color: "#186985",
                                alignSelf: "center",
                              }}
                            >
                              {t("Call")}
                            </Typography>
                          </div>
                        </a>
                      </div>
                      <div>
                        <Typography className={classes.carTitleTypo}>
                          {carTitle}
                        </Typography>
                      </div>
                    </CardContent>
                  </Grid>
                  <Grid item>
                    <CardActions className={classes.cardActions}>
                      <div className={classes.cardDivtwo}>
                        <Typography className={classes.submittedSinceTypo}>
                          {runnerAccount
                            ? `${t(day)}col (${
                                time
                                  ? time.split(" ").map((el) => {
                                      return el == "AM" || el == "PM"
                                        ? t(el)
                                        : el;
                                    })
                                  : ""
                              })`
                                .replace(",", " ")
                                .replace("col", ",")
                            : `${submittedSince} ${t("days ago")}`}
                        </Typography>
                      </div>
                      {started_at ? (
                        <Typography
                          className={classes.continuelistingTypotwo}
                          value={id}
                          onClick={handleClick}
                        >
                          {t("Continue Listing")}
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.listvehicleTypotwo}
                          value={id}
                          onClick={() => {
                            handleAddCar(props.id);
                          }}
                        >
                          {t("List Vehicle")}
                        </Typography>
                      )}
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </TableRow>
          </>
        )}
      </Hidden>
      <Dialog
        open={openNotes}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
        }}
      >
        <div className={classes.closeIcon}>
          <CloseIcon onClick={handleCloseNotes} />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          disableTypography={true}
          className={classes.dialogTitle}
        >
          <Typography className={classes.claimRequestTypo}>
            {t("Notes")}
          </Typography>
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContentText }}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentTextTwo}
          >
            <Typography className={classes.contentTypo}>
              {car_details_claimed?.notes}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListingRequestCard;
