import React from "react";

const ListingRequests = (props) => {
  const { size, fill, style } = props;
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 143 142"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M109.798 141.793H33.0178C24.8732 141.793 18.2524 134.22 18.2524 124.918V16.9205C18.2524 7.61919 24.8732 0.045837 33.0178 0.045837H97.9853C99.6154 0.045837 99.6464 1.55781 99.6464 3.42077C99.6464 5.28374 99.6154 6.79571 97.9853 6.79571H33.0178C28.1334 6.79571 24.1586 11.3384 24.1586 16.9205V124.918C24.1586 130.501 28.1334 135.043 33.0178 135.043H109.798C114.682 135.043 118.657 130.501 118.657 124.918V16.9205C118.657 11.3384 114.682 6.79571 109.798 6.79571H97.9853C96.3552 6.79571 95.0322 5.28374 95.0322 3.42077C95.0322 1.55781 96.3552 0.045837 97.9853 0.045837H109.798C117.942 0.045837 124.563 7.61919 124.563 16.9205V124.918C124.563 134.22 117.942 141.793 109.798 141.793Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.798 0.045837C117.942 0.045837 124.563 7.61919 124.563 16.9205V124.918C124.563 134.22 117.942 141.793 109.798 141.793H33.0178C24.8732 141.793 18.2524 134.22 18.2524 124.918V16.9205C18.2524 7.61919 24.8732 0.045837 33.0178 0.045837H109.798ZM33.0178 6.79571C28.1334 6.79571 24.1586 11.3384 24.1586 16.9205V124.918C24.1586 130.501 28.1334 135.043 33.0178 135.043H109.798C114.682 135.043 118.657 130.501 118.657 124.918V16.9205C118.657 11.3384 114.682 6.79571 109.798 6.79571H33.0178ZM33.0178 7.80444C28.8128 7.80444 25.1673 11.7647 25.1673 16.9205V124.918C25.1673 130.074 28.8128 134.035 33.0178 134.035H109.798C114.003 134.035 117.648 130.074 117.648 124.918V16.9205C117.648 11.7647 114.003 7.80444 109.798 7.80444H33.0178ZM109.798 -0.962891C118.622 -0.962891 125.572 7.1931 125.572 16.9205V124.918C125.572 134.646 118.622 142.802 109.798 142.802H33.0178C24.1935 142.802 17.2437 134.646 17.2437 124.918V16.9205C17.2437 7.1931 24.1935 -0.962891 33.0178 -0.962891H109.798Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M106.838 48.7699H35.9643V43.7262H106.838V48.7699Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M106.836 69.2572H35.9626V64.2136H106.836V69.2572Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M106.836 89.4672H35.9626V84.4235H106.836V89.4672Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M106.836 109.401H35.9626V104.357H106.836V109.401Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="141.747"
            height="141.747"
            fill="white"
            transform="translate(0.533722 0.0458984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListingRequests;
