import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CountDownTimer = (props) => {
  const { t } = useTranslation();
  const { interval, doneCallback, textStyle, reset, maxResets, className } =
    props;

  const [timeLeft, setTimeLeft] = useState(interval);
  const [nextDateObject, setnextDateObject] = useState();

  const timeMultiplier = {
    d: { multiplier: 86400000, mod: 100, endsWith: " days " }, // % 100 => max years = 100 then it turns to 0
    h: { multiplier: 3600000, mod: 24, endsWith: ":" },
    m: { multiplier: 60000, mod: 60, endsWith: ":" },
    s: { multiplier: 1000, mod: 60, endsWith: "" },
  };

  useEffect(() => {
    // sets next date object based on interval prop
    if (reset <= maxResets) {
      let add = 0;
      let timeStep = 0;
      Object.keys(interval).map((timeKey) => {
        add = Number(interval[timeKey]) * timeMultiplier[timeKey].multiplier;
        timeStep = timeStep + add;
      });
      setnextDateObject(new Date().getTime() + timeStep);
    }
  }, [reset, interval]);

  useEffect(() => {
    const timeoutFunc = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timeoutFunc);
  }, [timeLeft]); // check if a dependency should be added or would it make problems

  const timePortionNumberToString = (timePortionNumber) => {
    let timePortionString = "";
    if (typeof timePortionNumber === "number") {
      if (timePortionNumber / 10 < 1) {
        timePortionString = "0" + timePortionNumber.toString();
        return timePortionString;
      }
      return timePortionNumber.toString();
    }
  };

  const calculateTimeLeft = () => {
    let remainingTime = {};
    const difference = +nextDateObject - +new Date();
    if (difference > 0) {
      remainingTime = Object.assign(
        {},
        remainingTime,
        ...Object.keys(interval).map((timeKey) => {
          return {
            [timeKey]: Math.floor(
              (difference / timeMultiplier[timeKey].multiplier) %
                timeMultiplier[timeKey].mod
            ),
          };
        })
      );
    }
    if (difference <= 1000 && difference >= 0) {
      doneCallback(false);
    }
    return remainingTime; // still has a problem on first 2 counts
  };

  return (
    <div>
      <Typography className={className} style={textStyle}>
        {Object.keys(interval).map((timeKey) => {
          return (
            (timePortionNumberToString(timeLeft?.[timeKey]) ?? "00") +
            timeMultiplier[timeKey].endsWith
          );
        })}
      </Typography>
    </div>
  );
};

CountDownTimer.defaultProps = {
  interval: { d: 99, h: 23, m: 59, s: 59 }, // these are the maximum values for timeKeys
  textStyle: {},
  reset: 0,
  maxResets: 7,
  doneCallback: () => {
    alert("Timer is Done!");
  },
};

CountDownTimer.propTypes = {
  interval: PropTypes.object,
  textStyle: PropTypes.object,
  reset: PropTypes.number,
  maxResets: PropTypes.number,
  doneCallback: PropTypes.func,
};

export default CountDownTimer;
