const imageTimeStamp = () => {
  let dateObject = new Date();
  let day = dateObject.getDate();
  let month = dateObject.getMonth() + 1;
  let year = dateObject.getFullYear();
  let hour = dateObject.getHours();
  let min = dateObject.getMinutes();
  let sec = dateObject.getSeconds();

  return `${day}-${month}-${year}_${hour}:${min}:${sec}`;
};

export default imageTimeStamp;
