// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import {
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_REQUEST_SUCCEED,
  FETCH_FORMS_REQUEST_FAILURE,
  FETCH_SUBFORMS_REQUEST,
  FETCH_SUBFORMS_REQUEST_SUCCEED,
  FETCH_SUBFORMS_REQUEST_FAILURE,
  UPDATE_ANSWERS,
  UPDATE_HIDDEN,
  UPDATE_FEATURES,
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_REQUEST_SUCCEED,
  FETCH_FIELDS_REQUEST_FAILURE,
  FETCH_CAR_FORMS_REQUEST_SUCCEED,
  UPDATE_POINTS,
  FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED,
  FETCH_CAR_FEATURES_REQUEST_SUCCEED,
  FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED,
  UPDATE_GENERIC_FEATURES,
  FETCH_MODEL_OPTIONS_REQUEST_SUCCEED,
  FETCH_FIELDS_OPTIONS_REQUEST,
  FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
  FETCH_FIELDS_OPTIONS_REQUEST_FAILURE,
  UPDATE_FOEMFIELDS,
  UPDATE_CAR_FORM_REQUEST_SUCCEED,
  REMOVE_UPDATED_FORM,
  RESET_NOTIFICATION,
  TRIGGER_UPDATED_FORMS,
  TRIGGER_UPDATED_FIELDS,
  CLEAR_ANSWERS,
  FILTER_FORMS_OK,
  CLEAR_SAVED_FORM_DATA,
  DTC_FAULTS_SUCCEED,
  FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED,
  UPDATE_PAPARAZZI_IMAGE,
  UPDATE_PAPARAZZI_VIS_IMAGE,
  FETCH_CAR_INVOKED_SUCCEED,
  FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED,
  CLEAR_VIDEO_IMAGES,
  ADD_VIDEO_IMAGE,
  REMOVE_SELECTED_VIDEO_IMAGES,
  TOGGLE_SELECTED_IMAGE,
} from "../constants";
// import fieldTypes from "../../helpers/fields-types";

const fieldTypes = {
  text: {
    isMulti: false,
  },
  dist: {
    isMulti: false,
  },
  date: {
    isMulti: false,
  },
  num: {
    isMulti: false,
  },
  rb: {
    isMulti: false,
  },
  rbg: {
    isMulti: false,
  },
  cb: {
    isMulti: true,
  },
  dd: {
    isMulti: false,
  },
  dda: {
    isMulti: false,
  },
  ddm: {
    isMulti: true,
  },
  ddma: {
    isMulti: true,
  },
  ff: {
    isMulti: true,
  },
  img: {
    isMulti: false,
  },
  mimg: {
    isMulti: true,
  },
  read: {
    isMulti: false,
  },
};

const changeDeepObject = (saved, carId, formId, deepObj, key) => {
  var tempSaved = { ...saved };
  if (carId && formId) {
    // to stop an infinit loop on updating forms if there is a saved object
    // (UPDATE_***** is called without carId or formId => undefined)
    var tempCarData = { ...(tempSaved[carId] ?? {}) };
    var tempFormData = { ...(tempCarData[formId] ?? {}) };
    tempFormData[key] = deepObj;
    tempCarData[formId] = tempFormData;
    tempSaved[carId] = tempCarData;
  }
  return tempSaved;
};

const initialState = {
  fetchingForms: false,
  fetchingSubforms: false,
  forms: [],
  subforms: [],
  answers: {},
  hidden: {},
  features: [],
  formFields: [],
  fetchingFields: false,
  points: {},
  carGenericFeatures: [],
  updated_forms: [],
  invokedFields: [],
  saved: {},
  notfoundSystems: [],
  paparazziImages: {},
  videoImages: [],
};

export default createReducer(initialState, {
  [FETCH_FORMS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingForms: true,
    });
  },
  [FETCH_FORMS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      fetchingForms: false,
      forms: payload,
    });
  },
  [FETCH_FORMS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingForms: false,
    });
  },
  [FETCH_SUBFORMS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingSubforms: true,
    });
  },
  [FETCH_CAR_FEATURES_REQUEST_SUCCEED]: (state, payload) => {
    let defaultAnswers = { ...state.answers };
    let defaultHidden = {
      ...state.hidden,
    };
    let fields = payload;

    for (let i = 0; i < fields.length; i++) {
      let field = fields[i].form_object;
      defaultHidden[field.id.toString()] = false;
      if (field.options) {
        let options = field.options;
        let defaultAnswer = options.find((el) => el.default);
        if (defaultAnswers[field.id] === undefined) {
          // if defaultAnswers (...state.answers) contains the feature (already have been answered), then dont set the default answer for that feature)
          if (defaultAnswer) {
            if (fieldTypes[field.type].isMulti) {
              defaultAnswers[field.id.toString()] = [
                {
                  id: defaultAnswer.id.toString(),
                  field: field,
                  option: defaultAnswer,
                },
              ];
              if (field.type == "ff") {
                defaultAnswers[field.id.toString()][0]["text"] =
                  defaultAnswer.title;
              }
            } else {
              defaultAnswers[field.id.toString()] = {
                id: defaultAnswer.id.toString(),
                field: field,
                option: defaultAnswer,
              };
            }
          }
        }
      }
    }

    return Object.assign({}, state, {
      answers: defaultAnswers,
      hidden: defaultHidden,
    });
  },

  [FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED]: (state, payload) => {
    let carGenericFeaturesIds = [];
    payload.map((feature) => {
      carGenericFeaturesIds.push(feature.feature);
    });
    return Object.assign({}, state, {
      carGenericFeatures: carGenericFeaturesIds,
    });
  },

  [FETCH_SUBFORMS_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      fetchingSubforms: false,
      subforms: payload,
    });
  },
  [FETCH_SUBFORMS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingSubforms: false,
    });
  },

  [UPDATE_ANSWERS]: (state, payload) => {
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var answers = payload.answers;

    var tempSaved = changeDeepObject(saved, carId, formId, answers, "answers");
    if (formId !== 2) {
      // EagleEye form only submits answers (no hidden)
      tempSaved = changeDeepObject(
        tempSaved,
        carId,
        formId,
        state.hidden,
        "hidden"
      );
    }
    if (payload?.callback) {
      payload.callback();
    }
    return Object.assign({}, state, {
      answers: answers,
      saved: tempSaved,
    });
  },
  [UPDATE_FOEMFIELDS]: (state, payload) => {
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var formFields = payload.formFields;
    // alert(JSON.stringify(payload.formFields));
    const tempSaved = changeDeepObject(
      saved,
      carId,
      formId,
      formFields,
      "formFields"
    );
    // alert("FormFields");
    // alert(JSON.stringify(formFields));
    // alert(JSON.stringify(tempSaved[766][23]["formFields"]));
    if (payload?.callback) {
      payload.callback();
    }
    return Object.assign({}, state, {
      formFields: formFields,
      saved: tempSaved,
    });
  },
  [UPDATE_HIDDEN]: (state, payload) => {
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var hidden = payload.hidden;

    var tempSaved = changeDeepObject(saved, carId, formId, hidden, "hidden");
    tempSaved = changeDeepObject(
      tempSaved,
      carId,
      formId,
      state.answers,
      "answers"
    );
    if (payload?.callback) {
      payload.callback();
    }
    return Object.assign({}, state, {
      hidden: hidden,
      saved: tempSaved,
    });
  },
  [UPDATE_FEATURES]: (state, payload) => {
    return Object.assign({}, state, {
      features: payload,
    });
  },
  [UPDATE_GENERIC_FEATURES]: (state, payload) => {
    return Object.assign({}, state, {
      carGenericFeatures: payload,
    });
  },
  [CLEAR_ANSWERS]: (state) => {
    return Object.assign({}, state, {
      answers: {},
    });
  },
  [FETCH_FIELDS_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingFields: true,
    });
  },
  [FETCH_FIELDS_REQUEST_SUCCEED]: (state, payload) => {
    let defaultAnswers = {};
    let defaultHidden = {};
    let fields = payload;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      defaultHidden[field.id.toString()] = false;
      if (field.options) {
        let options = field.options;
        let defaultAnswer = options.find((el) => el.default);
        if (defaultAnswer) {
          if (fieldTypes[field.type].isMulti) {
            defaultAnswers[field.id.toString()] = [
              {
                id: defaultAnswer.id.toString(),
                field: field,
                option: defaultAnswer,
              },
            ];
            if (field.type == "ff") {
              defaultAnswers[field.id.toString()][0]["text"] =
                defaultAnswer.title;
            }
          } else {
            defaultAnswers[field.id.toString()] = {
              id: defaultAnswer.id.toString(),
              field: field,
              option: defaultAnswer,
            };
          }
        }
      }
    }

    return Object.assign({}, state, {
      fetchingFields: false,
      formFields: payload,
      answers: defaultAnswers,
      hidden: defaultHidden,
    });
  },

  [FETCH_MODEL_OPTIONS_REQUEST_SUCCEED]: (state, payload) => {
    let newFields = state.formFields.map(
      (obj) =>
        (payload.response && payload.response.find((p) => p.id === obj.id)) ||
        obj
    );

    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;

    const tempSaved = changeDeepObject(
      saved,
      carId,
      formId,
      newFields,
      "formFields"
    );

    return Object.assign({}, state, {
      formFields: newFields,
      saved: tempSaved,
    });
  },

  [FETCH_FIELDS_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingFields: false,
    });
  },
  [FETCH_CAR_FORMS_REQUEST_SUCCEED]: (state, payload) => {
    if (payload.length > 0) {
      // let answers = payload[0].form_answers;
      let answers = Object.assign({}, state.answers, payload[0].form_answers); // added object assign state.answers to keep the default answer of fields that were invoked after the form has already been saved
      let hidden = Object.assign({}, state.hidden, payload[0].form_hidden); // added object assign state.hidden to keep the default hidden of fields that were invoked after the form has already been saved
      return Object.assign({}, state, {
        answers: { ...answers },
        hidden: { ...hidden },
      });
    }
    return state;
  },
  [UPDATE_CAR_FORM_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      updated_forms: payload.updated_forms,
      invokedFields: payload.updated_form_fields,
    });
  },
  [TRIGGER_UPDATED_FIELDS]: (state, payload) => {
    // adds fields for highlite
    return Object.assign({}, state, {
      invokedFields: [
        ...new Set([...payload.invokedFields, ...state.invokedFields]),
      ],
    });
  },
  [TRIGGER_UPDATED_FORMS]: (state, payload) => {
    // adds forms for highlite
    return Object.assign({}, state, {
      updated_forms: [
        ...new Set([...payload.updated_forms, ...state.updated_forms]),
      ],
    });
  },
  [REMOVE_UPDATED_FORM]: (state, payload) => {
    let form_field = [];
    state.formFields.map((item) => {
      form_field.push(item.id);
    });
    let invoked_fields = state.invokedFields.filter(
      (item) => !form_field.includes(item)
    );
    return Object.assign({}, state, {
      updated_forms: state.updated_forms.filter(
        (item) => item != (payload?.body?.form ?? payload.form)
      ),
      invokedFields: invoked_fields,
    });
  },
  [RESET_NOTIFICATION]: (state, payload) => {
    return Object.assign({}, state, {
      updated_forms: payload,
      invokedFields: payload,
    });
  },
  [FETCH_CAR_INVOKED_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      updated_forms: payload.updated_forms,
      invokedFields: payload.updated_form_fields,
    });
  },
  [FILTER_FORMS_OK]: (state, payload) => {
    return Object.assign({}, state, {
      forms: [...payload],
    });
  },
  [FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      oldImages: [...payload],
    });
  },
  [UPDATE_POINTS]: (state, payload) => {
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var points = payload.points;
    const tempSaved = changeDeepObject(saved, carId, formId, points, "points");

    if (payload?.callback) {
      payload.callback();
    }
    return Object.assign({}, state, {
      points: points,
      saved: tempSaved,
    });
  },
  [FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED]: (state, payload) => {
    if (payload.first) {
      return Object.assign({}, state, {
        points: { ...payload.points },
      });
    } else {
      return Object.assign({}, state, {
        points: { ...state.points, ...payload.points },
      });
    }
  },
  [FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED]: (state, payload) => {
    let defaultAnswers = {};
    let defaultHidden = {};
    let fields = payload.response;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      defaultHidden[field.id.toString()] = false;
      if (field.options) {
        let options = field.options;
        let defaultAnswer = options.find((el) => el.default);
        if (defaultAnswer) {
          if (fieldTypes[field.type].isMulti) {
            defaultAnswers[field.id.toString()] = [
              {
                id: defaultAnswer.id.toString(),
                field: field,
                option: defaultAnswer,
              },
            ];
            if (field.type == "ff") {
              // not sure if necessary
              defaultAnswers[field.id.toString()][0]["text"] =
                defaultAnswer.title;
            }
          } else {
            defaultAnswers[field.id.toString()] = {
              id: defaultAnswer.id.toString(),
              field: field,
              option: defaultAnswer,
            };
          }
        }
      }
    }

    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;

    var formFields = [...state.formFields, ...payload.response];
    var answers = { ...state.answers, ...defaultAnswers };
    var hidden = { ...state.hidden, ...defaultHidden };

    var tempSaved = changeDeepObject(
      saved,
      carId,
      formId,
      formFields,
      "formFields"
    );
    tempSaved = changeDeepObject(tempSaved, carId, formId, answers, "answers");
    tempSaved = changeDeepObject(tempSaved, carId, formId, hidden, "hidden");

    return Object.assign({}, state, {
      fetchingFields: false,
      formFields: formFields,
      answers: answers,
      hidden: hidden,
      saved: tempSaved,
    });
  },
  [CLEAR_SAVED_FORM_DATA]: (state, payload) => {
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    if (saved?.[carId]?.[formId]) {
      delete saved[carId][formId];
    }

    if (Object.keys(saved?.[carId] ?? {}).length == 0) {
      delete saved[carId];
    }
    return Object.assign({}, state, {
      saved: saved,
    });
  },
  [DTC_FAULTS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      notfoundSystems: payload.data.notfoundSystems,
    });
  },
  [FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED]: (state, payload) => {
    let paparazziImages = {};
    payload.map((fieldImageObj) => {
      paparazziImages[fieldImageObj.image_field] = {
        bgImage: fieldImageObj.image_url,
        shadow: fieldImageObj.shadow,
      };
    });
    return Object.assign({}, state, {
      paparazziImages: paparazziImages,
    });
  },
  [UPDATE_PAPARAZZI_IMAGE]: (state, payload) => {
    // updates redux with the async paparazzi image only updates a single field
    // used because using updateAnswers caused problems with async api where a new fields papa image would change the old to origional image due to async image of answers not updated when taking images fast
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var fieldAnswer = payload.fieldAnswer;
    var answers = Object.assign({}, state.answers, { ...fieldAnswer });

    var tempSaved = changeDeepObject(saved, carId, formId, answers, "answers");
    console.log("qqqqqqqqqqqqqqqqqqqqqqvvvvvvvvvvvv", formId);
    return Object.assign({}, state, {
      answers: answers,
      saved: tempSaved,
    });
  },
  [UPDATE_PAPARAZZI_VIS_IMAGE]: (state, payload) => {
    // updates redux with the async paparazzi image only updates a single side's image
    // used because using updatePoints caused problems with async api where a new side's papa image would change the old to origional image due to async image of points not updated when taking images fast
    var saved = state.saved;
    var carId = payload?.carId;
    var formId = payload?.formId;
    var side = payload.side;
    var pointImage = payload.pointImage;
    var oldPoint = state.points[side];
    oldPoint.image = pointImage;
    var points = Object.assign({}, state.points, { [side]: oldPoint });

    const tempSaved = changeDeepObject(saved, carId, formId, points, "points");

    return Object.assign({}, state, {
      points: points,
      saved: tempSaved,
    });
  },

  [ADD_VIDEO_IMAGE]: (state, payload) => {
    // payload is single img obj that contain img and a boolean selected flag
    return Object.assign({}, state, {
      videoImages: [...state.videoImages, payload],
    });
  },

  [REMOVE_SELECTED_VIDEO_IMAGES]: (state, payload) => {
    // payload is an img index
    const tempSelectedImages = [...state.videoImages];
    let removedSelectedImages = tempSelectedImages.filter(
      (imgObj) => !imgObj.selected
    );
    return Object.assign({}, state, {
      videoImages: removedSelectedImages,
    });
  },

  [CLEAR_VIDEO_IMAGES]: (state, payload) => {
    return Object.assign({}, state, {
      videoImages: [],
    });
  },

  [TOGGLE_SELECTED_IMAGE]: (state, payload) => {
    // payload is index of image
    let tempSelectedImages = [...state.videoImages];
    let oldImageObj = tempSelectedImages[payload];
    let newImageObj = {
      image: oldImageObj.image,
      selected: !oldImageObj.selected,
    };
    tempSelectedImages.splice(payload, 1, newImageObj);
    return Object.assign({}, state, {
      videoImages: tempSelectedImages,
    });
  },
});
