import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PaintDescription from "../icons/PaintDescription";
import { Typography } from "@material-ui/core";
import Pickup4doorsLeft from "../icons/Pickup4doorsLeft";
import Pickup4doorsTop from "../icons/Pickup4doorsTop";
import Pickup4doorsRight from "../icons/Pickup4doorsRight";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({}));

const BodyPaintPickup4Doors = (props) => {
  const { t } = useTranslation();
  const { fields } = props;
  const [dest, setDest] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let newObj = {};
    fields.map((item) => {
      let key = item.field_id;
      let y = item.field_score;
      newObj[key] = y;
    });
    setDest(newObj);
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    speed: 450,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <>
      <Hidden smDown>
        <div
          style={{
            height: "inherit",
            textAlign: "center",
            backgroundColor: "white",
            border: "1px solid #BFD6DE",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "30%",
              background:
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%) bottom no-repeat",
              backgroundSize: "100% 1px",
            }}
          >
            <Pickup4doorsRight
              margin={"0 auto"}
              rightPillar={dest?.[354]}
              rightRearFender={dest?.[49]}
              rightFrontDoor={dest?.[51]}
              rightFrontFender={dest?.[53]}
              rightRearDoor={dest?.[50]}
            ></Pickup4doorsRight>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "30%",
              background:
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%) bottom no-repeat",
              backgroundSize: "100% 1px",
            }}
          >
            <Pickup4doorsTop
              margin={"0 auto"}
              hood={dest?.[37]}
              roof={dest?.[43]}
              trunk={dest?.[46]}
            ></Pickup4doorsTop>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "30%",
              background:
                "radial-gradient(50% 50% at 50% 50%, #186985 0%, rgba(24, 105, 133, 0.13) 100%) bottom no-repeat",
              backgroundSize: "100% 1px",
            }}
          >
            <Pickup4doorsLeft
              margin={"0 auto"}
              leftFrontFender={dest?.[38]}
              leftPillar={dest?.[353]}
              leftFrontDoor={dest?.[40]}
              leftRearFender={dest?.[44]}
              leftRearDoor={dest?.[41]}
            ></Pickup4doorsLeft>
          </div>
          <div
            style={{
              height: "10%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "contents",
              }}
            >
              <PaintDescription color={"#DD4B4B"} />
              <Typography>{t("Re-painted")}</Typography>
              <PaintDescription color={"#37C534"} />
              <Typography>{t("Original Paint")}</Typography>
              <PaintDescription color={"#DEEDF8"} />
              <Typography>{t("Not measured")}</Typography>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Slider
          rtl={localStorage.getItem("language") === "en" ? null : "true"}
          {...settings}
          style={{
            marginBottom: "50px",
          }}
        >
          <Pickup4doorsRight
            margin={"0 auto"}
            rightPillar={dest?.[354]}
            rightRearFender={dest?.[49]}
            rightFrontDoor={dest?.[51]}
            rightFrontFender={dest?.[53]}
            rightRearDoor={dest?.[50]}
          ></Pickup4doorsRight>
          <Pickup4doorsTop
            margin={"0 auto"}
            hood={dest?.[37]}
            roof={dest?.[43]}
            trunk={dest?.[46]}
          ></Pickup4doorsTop>
          <Pickup4doorsLeft
            margin={"0 auto"}
            leftFrontFender={dest?.[38]}
            leftPillar={dest?.[353]}
            leftFrontDoor={dest?.[40]}
            leftRearFender={dest?.[44]}
            leftRearDoor={dest?.[41]}
          ></Pickup4doorsLeft>
        </Slider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <PaintDescription color={"#DD4B4B"} />
          <Typography
            style={{
              alignSelf: "center",
              fontSize: "10px",
              color: "#07303E",
              fontWeight: "600",
            }}
          >
            {t("Re-painted")}
          </Typography>
          <PaintDescription color={"#37C534"} />
          <Typography
            style={{
              alignSelf: "center",
              fontSize: "10px",
              color: "#07303E",
              fontWeight: "600",
            }}
          >
            {t("Original Paint")}
          </Typography>
          <PaintDescription color={"#DEEDF8"} />
          <Typography
            style={{
              alignSelf: "center",
              fontSize: "10px",
              color: "#07303E",
              fontWeight: "600",
            }}
          >
            {t("Not measured")}
          </Typography>
        </div>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  body_type: state.car.car.body_type_config.body_type,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BodyPaintPickup4Doors)
);
