import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, TextField, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddNewSystemIcon from "../components/icons/AddNewSystemIcon";
import { DTCFaults, updateAnswers } from "../store/actions/forms";
import { getDTCMapping } from "../store/actions/car";

import { updateDtcForm } from "../store/actions/car";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "../store/helpers";
import UploadDTCPDF from "../components/UploadDTCPDF";
import uploadImage from "../helpers/awsPDFuploader";
import { Hidden } from "@material-ui/core";
import ArrowDown from "../icons/arrow_down.svg";
import useNonInitialEffect from "../helpers/useNonInitialEffect";
const useStyles = makeStyles((theme) => ({
  table: { userSelect: "none" },
  verticalaligntop: {
    verticalAlign: "top",
    borderRight: "solid 1px #CBC6C6",
  },
  firstTextField: { width: "247px", paddingRight: "12px" },
  firstTextField1: { paddingRight: "12px" },
  secondTextField: { width: "151px", paddingRight: "12px" },
  actionBarRelative: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      transformOrigin: "bottom left",
      zIndex: 10,
      width: "100%",
      left: 0,
    },
  },
  actionBarPaper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      boxShadow: "0px -2px 4px rgba(24, 105, 133, 0.25)",
      borderRadius: 0,
      padding: "10px 24px",
    },
  },
  moreVert: {
    marginLeft: "15px",
  },
  showMore: {
    color: "#186985",
    fontSize: "12px",
    fontWeight: "600",
    alignSelf: "center",
    marginLeft: "16px",
    textDecoration: "underline",
  },
  indexNumber: {
    marginRight: "8px",
    color: "#07303E",
    fontSize: "14px",
    fontWeight: "700",
  },
  arrowDown: {
    marginLeft: "6px",
  },
  newSysMobile: {
    fontWeight: 600,
    fontSize: "13.1325px",
    alignSelf: "center",
    marginRight: "10px",
  },
}));

const ComputerScanTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const initialLoad = useRef({ current: true });
  const {
    formFields,
    dashedField,
    updateAnswers,
    answers,
    computerScanAnswers,
    carId,
    formId,
    DTCFaults,
    savedDtcFetched,
    fetchedDtcFromBE,
    setFetchedDtcFromBE,
    getDTCMapping,
  } = props;

  const [fileLink, setFileLink] = useState("");
  useEffect(() => {
    if (initialLoad.current && dashedField) {
      setData([
        {
          system: "",
          sysgroup: {
            id: dashedField?.id,
            title: dashedField?.title,
          },
          numofFaults: 0,
          subrow: [{ faults: "", description: "", state: "" }],
        },
      ]);
      initialLoad["current"] = false;
    }
  }, [dashedField]);

  useEffect(() => {
    if (computerScanAnswers) {
      setData(computerScanAnswers);
    }
  }, [computerScanAnswers, savedDtcFetched]);

  const [undisabledRowsIDs, setUndisabledRowsIDs] = useState([]);

  useNonInitialEffect(() => {
    // this use effect is entered in the case that fill dtc was called or data from be was called (both represented by fetchedDtcFromBE ) or in the case that data was changed by saved object
    if (fetchedDtcFromBE) {
      // if data was not changed by saved any (-----) field is not disabled the rest are
      var dashedIndex = fetchedDtcFromBE
        .map((el, ind) => {
          if (el.sysgroup.id == 940) return ind;
        })
        .filter((el) => el >= 0);
      setUndisabledRowsIDs([...dashedIndex]); // this is the state containing undesabled row ids
    } else {
      // if state was changed by saved the BE is checked for the filled rows if it doesnot exist in BE then its row id is present in the return
      const answeredSystems = computerScanAnswers.map((row) => {
        return { system: row.system, systemGroupId: row.sysgroup.id };
      });
      getDTCMapping({
        answeredSystems,
        carId,
        callback: (response) => {
          setUndisabledRowsIDs([...response.unsaved_systems_indecies]);
        },
      });
    }
  }, [fetchedDtcFromBE, savedDtcFetched]);

  const [isApplicable, setIsApplicable] = useState(false);
  const [isHidden, setIsHidden] = useState(null);
  useEffect(() => {
    if (answers[342]?.option?.id == 1286) {
      setIsApplicable(true);
    } else if (answers[342]?.option?.id == 1287) {
      setIsApplicable(false);
      setData([]);
      answers["table"] = [];
    }
  }, [answers]);

  const [data, setData] = useState([
    {
      system: "",
      sysgroup: {
        id: dashedField?.id,
        title: dashedField?.title,
      },
      numofFaults: 0,
      subrow: [{ faults: "", description: "", state: "" }],
    },
  ]);

  useEffect(() => {
    console.log("swfghjkl", formFields);
  }, [formFields]);

  useEffect(() => {
    if (fileLink) {
      DTCFaults({
        carId: carId,
        pdf: fileLink,
        callback: (response) => {
          let tempData = [];
          response.data.faults.map((row) => {
            tempData.push({
              system: row.system,
              sysgroup: {
                id: row.markabteSystem.id,
                title: row.markabteSystem.title,
              },
              numofFaults: row.numofFaults,
              subrow: [...row.subrow],
            });
          });

          response.data.normals.map((row) => {
            tempData.push({
              system: row.system,
              sysgroup: {
                id: row.markabteSystem.id,
                title: row.markabteSystem.title,
              },
              numofFaults: 0,
              subrow: [{ faults: "", description: "", state: "" }],
            });
          });
          setFetchedDtcFromBE(tempData);

          setData(tempData);
          updateAnswers({
            carId,
            formId,
            answers: Object.assign({}, answers, { table: tempData }),
          });
        },
      });
    }
  }, [fileLink]);

  const handleDelete = (rowId) => {
    let tempData = [...data];
    let filteredAry = tempData.filter((e) => e !== tempData[rowId]);
    setData(filteredAry);
    updateAnswers({
      carId,
      formId,
      answers: Object.assign({}, answers, { table: filteredAry }),
    });
  };
  const handleDeleteFault = (rowId, subrowId) => {
    let tempData = [...data];
    let newFaults = tempData[rowId].subrow.filter(
      (e) => e !== tempData[rowId].subrow[subrowId]
    );
    tempData[rowId].subrow = newFaults;
    tempData[rowId]["numofFaults"] -= 1;
    setData(tempData);
    updateAnswers({
      carId,
      formId,
      answers: Object.assign({}, answers, { table: tempData }),
    });
  };

  const handleChange = (event, key, rowId, subrowId) => {
    let tempData = [...data];
    if (key === "sysgroup") {
      let flag = formFields.filter((el) => {
        return el.title == event.target.value;
      });
      tempData[rowId][key] = flag[0];
    } else if (key === "numofFaults") {
      let number = Math.min(50, Number(event.target.value.replace(/\D/g, "")));
      tempData[rowId][key] = number;
      let diff = number - tempData[rowId].subrow.length;
      for (let i = 0; i < Math.abs(diff); i++) {
        if (number > tempData[rowId].subrow.length) {
          tempData[rowId].subrow.push({
            faults: "",
            description: "",
            state: "",
          });
        } else {
          tempData[rowId].subrow.pop();
        }
      }
      if (number == 0 && diff < 0) {
        tempData[rowId].subrow.push({
          faults: "",
          description: "",
          state: "",
        });
      }
    } else if (subrowId == undefined) {
      tempData[rowId][key] = event.target.value;
    } else {
      tempData[rowId].subrow[subrowId][key] = event.target.value;
    } // CHECK THIS LOGIC AND CONDITIONS
    setData(tempData);
    updateAnswers({
      carId,
      formId,
      answers: Object.assign({}, answers, { table: tempData }),
    });
  };

  return (
    <>
      <Hidden smDown>
        <UploadDTCPDF setFileLink={setFileLink} />
        <TableContainer component={Paper}>
          <table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#F3F3F3" }}>
                <TableCell className={classes.verticalaligntop} align="center">
                  {t("System")}
                </TableCell>
                <TableCell className={classes.verticalaligntop} align="center">
                  {t("System Group")}
                </TableCell>
                <TableCell className={classes.verticalaligntop} align="center">
                  {t("No.Of Faults")}
                </TableCell>
                <Table>
                  <TableRow style={{ backgroundColor: "#F3F3F3" }}>
                    <TableCell
                      className={classes.verticalaligntop}
                      style={{ width: "178px" }}
                      align="center"
                    >
                      {t("Fault")}
                    </TableCell>
                    <TableCell
                      className={classes.verticalaligntop}
                      style={{ width: "267px" }}
                      align="center"
                    >
                      {t("Description")}
                    </TableCell>
                    <TableCell style={{ width: "74px" }} align="center">
                      {t("State")}
                    </TableCell>
                  </TableRow>
                </Table>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((option, i) => (
                <TableRow>
                  <TableCell
                    className={classes.verticalaligntop}
                    component="th"
                    scope="row"
                    style={{
                      height: "63px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        className={classes.firstTextField}
                        id="outlined-basic"
                        variant="outlined"
                        label={t("System Name")}
                        onChange={(event) => handleChange(event, "system", i)}
                        value={data[i]?.system}
                      />
                      {data.length > 1 && (
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div>
                              <IconButton
                                style={{
                                  width: "4px",
                                  height: "20px",
                                  padding: "0",
                                }}
                                {...bindTrigger(popupState)}
                              >
                                <MoreVert />
                              </IconButton>
                              <Popover
                                style={{ cursor: "pointer" }}
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <Box onClick={() => handleDelete(i)} p={1}>
                                  <Typography
                                    style={{
                                      fontSize: "11.34px",
                                      color: "#07303E",
                                    }}
                                  >
                                    {t("Delete Code")}
                                  </Typography>
                                </Box>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.verticalaligntop}
                    align="center"
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        native
                        inputProps={{
                          name: "age",
                          id: "outlined-age-native-simple",
                        }}
                        style={{ width: "257px" }}
                        onChange={(event) => handleChange(event, "sysgroup", i)}
                        value={data[i]?.sysgroup?.title}
                        // disabled={data[i]?.sysgroup?.id !== 940}
                        disabled={
                          !undisabledRowsIDs.includes(i) && option.system
                        }
                      >
                        {formFields.map((field) => (
                          <option>{field.title}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    className={classes.verticalaligntop}
                    align="center"
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        id={i}
                        native
                        onChange={(event) =>
                          handleChange(event, "numofFaults", i)
                        }
                        style={{ width: "173px" }}
                        value={data[i]?.numofFaults}
                      >
                        {[...Array(50)]?.map((key, index) => (
                          <option>{index}</option>
                        ))}
                      </Select>
                      {/* <TextField
                        id={i}
                        style={{ width: "173px" }}
                        variant="outlined"
                        label={t("Number Of Faults")}
                        onChange={(event) =>
                          handleChange(event, "numofFaults", i)
                        }
                        value={data[i]?.numofFaults}
                      /> */}
                    </FormControl>
                  </TableCell>
                  {data[i].subrow.map((option, index) => (
                    <TableRow>
                      <TableCell
                        className={classes.verticalaligntop}
                        align="center"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "58px",
                        }}
                      >
                        <TextField
                          className={classes.secondTextField}
                          id="outlined-basic"
                          variant="outlined"
                          label={t("Fault Code")}
                          onChange={(event) =>
                            handleChange(event, "faults", i, index)
                          }
                          value={data[i]?.subrow[index]?.faults}
                          disabled={data[i].numofFaults == 0 ? true : false}
                        />
                        {data[i].subrow.length > 1 && (
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <IconButton
                                  style={{
                                    width: "4px",
                                    height: "20px",
                                    padding: "0",
                                  }}
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVert />
                                </IconButton>
                                <Popover
                                  style={{ cursor: "pointer" }}
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Box
                                    onClick={() => handleDeleteFault(i, index)}
                                    p={1}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "11.34px",
                                        color: "#07303E",
                                      }}
                                    >
                                      {t("Delete Fault")}
                                    </Typography>
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.verticalaligntop}
                        align="center"
                      >
                        <TextField
                          style={{ width: "262px" }}
                          id="outlined-basic"
                          variant="outlined"
                          label={t("description")}
                          multiline
                          onChange={(event) =>
                            handleChange(event, "description", i, index)
                          }
                          value={data[i]?.subrow[index]?.description}
                          disabled={data[i].numofFaults == 0 ? true : false}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          style={{ width: "71px" }}
                          id="outlined-basic"
                          variant="outlined"
                          label={t("state")}
                          onChange={(event) =>
                            handleChange(event, "state", i, index)
                          }
                          value={data[i]?.subrow[index]?.state}
                          disabled={data[i].numofFaults == 0 ? true : false}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </table>
          <Button
            disabled={isApplicable ? false : true}
            style={{
              width: "218px",
              height: "130px",
              border: "1.31325px solid #186985",
              textAlign: "center",
              margin: "20px 35%",
              boxShadow: "0px 2.62651px 2.62651px rgba(15, 71, 90, 0.4)",
              userSelect: "none",
            }}
          >
            <div
              onClick={() => {
                let tempData = [...data];
                tempData.push({
                  system: "",
                  sysgroup: {
                    id: dashedField?.id,
                    title: dashedField?.title,
                  },
                  numofFaults: 0,
                  subrow: [{ faults: "", description: "", state: "" }],
                });
                setData(tempData);
                setUndisabledRowsIDs((prev) => [...prev, tempData.length - 1]); // used tempData instead of data because i feared it changes before this (safer)

                updateAnswers({
                  carId,
                  formId,
                  answers: Object.assign({}, answers, { table: tempData }),
                });
              }}
              style={{ marginTop: "20px", cursor: "pointer" }}
            >
              <AddNewSystemIcon
                size={46}
                pathColor={isApplicable ? "#186985" : "#808080"}
              />
              <Typography
                style={
                  isApplicable ? { color: "#186985" } : { color: "#808080" }
                }
              >
                {t("Add A New System")}
              </Typography>
            </div>
          </Button>
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        <UploadDTCPDF setFileLink={setFileLink} />
        {data.map((option, i) => (
          <div>
            <div
              style={{
                border: "solid 1px #CBC6C6",
                padding: "20px",
                borderRadius: "3px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  marginBottom: "21px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    label={t("System Name")}
                    onChange={(event) => handleChange(event, "system", i)}
                    value={data[i]?.system}
                  />
                  {data.length > 1 && (
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <IconButton
                            style={{
                              width: "4px",
                              height: "20px",
                              padding: "0",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            <MoreVert className={classes.moreVert} />
                          </IconButton>
                          <Popover
                            style={{ cursor: "pointer" }}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <Box onClick={() => handleDelete(i)} p={1}>
                              <Typography
                                style={{
                                  fontSize: "11.34px",
                                  color: "#07303E",
                                }}
                              >
                                {t("Delete Code")}
                              </Typography>
                            </Box>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  )}
                </div>
              </div>
              <div>
                <FormControl
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginBottom: "21px",
                  }}
                  className={classes.formControl}
                >
                  <Select
                    native
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    onChange={(event) => handleChange(event, "sysgroup", i)}
                    value={data[i]?.sysgroup?.title}
                    // disabled={data[i]?.sysgroup?.id !== 940}
                    disabled={!undisabledRowsIDs.includes(i)}
                  >
                    {formFields.map((field) => (
                      <option>{field.title}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  marginBottom: "21px",
                  display: "flex",
                }}
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    id={i}
                    native
                    onChange={(event) => handleChange(event, "numofFaults", i)}
                    style={{ width: "150px" }}
                    value={data[i]?.numofFaults}
                  >
                    {[...Array(50)]?.map((key, index) => (
                      <option>{index}</option>
                    ))}
                  </Select>
                  {/* <TextField
                    id={i}
                    style={{ width: "150px" }}
                    variant="outlined"
                    label={t("Number Of Faults")}
                    onChange={(event) => handleChange(event, "numofFaults", i)}
                    value={data[i]?.numofFaults}
                  /> */}
                </FormControl>
                <Typography
                  className={classes.showMore}
                  onClick={() => {
                    setIsHidden((prev) => (prev == i ? null : i));
                  }}
                >
                  {isHidden == i && isHidden != null
                    ? t("Show less")
                    : t("Show more")}
                </Typography>
                <img
                  src={ArrowDown}
                  className={classes.arrowDown}
                  style={{
                    transform:
                      isHidden == i && isHidden != null
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                />
              </div>

              {data[i].subrow.map((option, index) =>
                isHidden == i && isHidden != null ? (
                  <div>
                    <hr
                      style={{
                        color: "#07303E",
                      }}
                    ></hr>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBlock: "16px",
                      }}
                    >
                      <div
                        align="center"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.indexNumber}>
                          {index + 1}.
                        </Typography>
                        <TextField
                          style={{ width: "104px" }}
                          id="outlined-basic"
                          variant="outlined"
                          label={t("Fault Code")}
                          onChange={(event) =>
                            handleChange(event, "faults", i, index)
                          }
                          value={data[i]?.subrow[index]?.faults}
                          disabled={data[i].numofFaults == 0 ? true : false}
                        />
                        {data[i].subrow.length > 1 && (
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div>
                                <IconButton
                                  style={{
                                    width: "4px",
                                    height: "20px",
                                    padding: "0",
                                  }}
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVert className={classes.moreVert} />
                                </IconButton>
                                <Popover
                                  style={{ cursor: "pointer" }}
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <Box
                                    onClick={() => handleDeleteFault(i, index)}
                                    p={1}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "11.34px",
                                        color: "#07303E",
                                      }}
                                    >
                                      {t("Delete Fault")}
                                    </Typography>
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        )}
                      </div>
                      <div align="center">
                        <TextField
                          style={{ width: "104px" }}
                          id="outlined-basic"
                          variant="outlined"
                          label={t("state")}
                          onChange={(event) =>
                            handleChange(event, "state", i, index)
                          }
                          value={data[i]?.subrow[index]?.state}
                          disabled={data[i].numofFaults == 0 ? true : false}
                        />
                      </div>
                    </div>

                    <div>
                      <TextField
                        style={{ width: "100%" }}
                        rows={7}
                        id="outlined-basic"
                        variant="outlined"
                        label={t("description")}
                        multiline
                        onChange={(event) =>
                          handleChange(event, "description", i, index)
                        }
                        value={data[i]?.subrow[index]?.description}
                        disabled={data[i].numofFaults == 0 ? true : false}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          </div>
        ))}
        <Button
          disabled={isApplicable ? false : true}
          variant="contained"
          style={{
            backgroundColor: isApplicable ? "#186985" : "#d7d7d7",
            width: "100%",
          }}
        >
          <div
            onClick={() => {
              let tempData = [...data];
              tempData.push({
                system: "",
                sysgroup: {
                  id: dashedField?.id,
                  title: dashedField?.title,
                },
                numofFaults: 0,
                subrow: [{ faults: "", description: "", state: "" }],
              });
              setData(tempData);
              updateAnswers({
                carId,
                formId,
                answers: Object.assign({}, answers, { table: tempData }),
              });
            }}
            style={{ display: "flex", cursor: "pointer" }}
          >
            <Typography
              className={classes.newSysMobile}
              style={{
                color: isApplicable ? "white" : "#808080",
              }}
            >
              {t("Add A New System")}
            </Typography>
            <AddNewSystemIcon
              size={46}
              pathColor={isApplicable ? "#186985" : "#808080"}
            />
          </div>
        </Button>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  formFields: state.forms.formFields.filter(
    (formField) => formField.type === "cimg"
  ),
  dashedField: state.forms.formFields.filter(
    (formField) => formField.id == 940
  )?.[0],
  answers: state.forms.answers,
  subforms: state.forms.subforms,
  fetchingSubforms: state.forms.fetchingSubforms,
  carId: state.car.car.id,
});

const mapDispatchToProps = (dispatch) => ({
  updateAnswers(payload) {
    dispatch(updateAnswers(payload));
  },
  updateDtcForm(payload) {
    dispatch(updateDtcForm(payload));
  },
  DTCFaults(payload) {
    dispatch(DTCFaults(payload));
  },
  getDTCMapping(payload) {
    dispatch(getDTCMapping(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputerScanTable);
