import React from "react";

const FeatureIcon6 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon6Clip0)">
<path d="M7.60672 46.7008L9.84859 44.3035C5.96774 40.6743 3.73239 35.7511 3.55434 30.4407C3.37629 25.1303 5.27692 20.0685 8.90607 16.1876C16.398 8.17588 29.0107 7.75334 37.022 15.2451C44.4099 22.1537 45.3444 33.4174 39.5698 41.4115L36.1778 33.9409L33.1893 35.2978L38.3453 46.6538L49.7013 41.4978L48.3444 38.5092L43.9856 40.4883C48.6722 31.4919 47.0261 20.1066 39.2637 12.8476C32.2154 6.25646 22.1115 4.92304 13.8269 8.68528C11.1413 9.9049 8.64545 11.6608 6.50871 13.9455C-2.21917 23.279 -1.72651 37.9729 7.60672 46.7008Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.90607 16.1876C16.398 8.17588 29.0107 7.75334 37.022 15.2451C44.2645 22.0177 45.3051 32.9757 39.9029 40.9358C39.7944 41.0956 39.6834 41.2542 39.5698 41.4115L36.1778 33.9409L33.1893 35.2978L38.3453 46.6538L49.7013 41.4978L48.3444 38.5092L43.9856 40.4883C44.1081 40.2532 44.2262 40.0165 44.34 39.7782C48.582 30.8997 46.8233 19.9169 39.2637 12.8476C32.2154 6.25646 22.1115 4.92304 13.8269 8.68528C11.1413 9.9049 8.64545 11.6608 6.50871 13.9455C-2.10505 23.157 -1.73782 37.5896 7.24515 46.3554C7.36202 46.4694 7.48034 46.5825 7.60012 46.6946C7.60232 46.6967 7.60452 46.6987 7.60672 46.7008L9.84859 44.3035C5.96773 40.6743 3.73239 35.7511 3.55434 30.4407C3.37629 25.1303 5.27692 20.0685 8.90607 16.1876ZM9.14596 44.3228C5.39079 40.639 3.23152 35.7335 3.05462 30.4575C2.87213 25.0146 4.82114 19.8238 8.54088 15.8461C16.2214 7.63267 29.1505 7.19953 37.3635 14.8799C44.9379 21.9628 45.8951 33.5089 39.9751 41.7043L39.4706 42.4026L35.9293 34.6029L33.8513 35.5464L38.5939 45.9918L49.0393 41.2492L48.0958 39.1712L42.8725 41.5428L43.5422 40.2573C48.128 31.4543 46.516 20.3142 38.9222 13.2128C32.027 6.7649 22.141 5.45878 14.0337 9.14054C11.4066 10.3335 8.96492 12.0512 6.87389 14.2871C-1.55123 23.2968 -1.19458 37.413 7.58675 45.9901L9.14596 44.3228Z" fill="white"/>
<path d="M16.214 29.2099C17.366 28.2139 18.272 27.3979 18.932 26.7619C19.604 26.1139 20.162 25.4419 20.606 24.7459C21.05 24.0499 21.272 23.3539 21.272 22.6579C21.272 21.9379 21.098 21.3739 20.75 20.9659C20.414 20.5579 19.88 20.3539 19.148 20.3539C18.44 20.3539 17.888 20.5819 17.492 21.0379C17.108 21.4819 16.904 22.0819 16.88 22.8379H14.9C14.936 21.4699 15.344 20.4259 16.124 19.7059C16.916 18.9739 17.918 18.6079 19.13 18.6079C20.438 18.6079 21.458 18.9679 22.19 19.6879C22.934 20.4079 23.306 21.3679 23.306 22.5679C23.306 23.4319 23.084 24.2659 22.64 25.0699C22.208 25.8619 21.686 26.5759 21.074 27.2119C20.474 27.8359 19.706 28.5619 18.77 29.3899L17.96 30.1099H23.666V31.8199L14.918 31.8199L14.918 30.3259L16.214 29.2099Z" fill="#07303E"/>
<path d="M29.9867 26.9779L33.1907 31.9999H30.8687L28.7267 28.6339L26.7107 31.9999L24.5687 31.9999L27.7727 27.1219L24.5687 22.0819L26.8907 22.0819L29.0327 25.4479L31.0487 22.0819H33.1907L29.9867 26.9779Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon6Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon6;
