import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  correctBorder: {
    borderColor: "#37C534!important",
  },
  correctColor: {
    color: "#37C534!important",
  },
  paperContainer: {
    height: "50%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  bottomImg: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  formControlRoot: {
    // borderRadius: "8.67px",
    // height: "6.815vh",

    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      // maxHeight: "40px",
    },
  },
  inputSpacing: {
    // margin: "18px 2.2vw",
    // width: "90%",
    // height: "40px",
    margin: "14px 0", /////
    // width: "90%",/////
    height: "46px", /////
    borderRadius: "8.67px",
    boxShadow: "0px 2.20309px 2.67182px rgba(130, 148, 154, 0.4)",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      margin: "10px 0",
    },
  },
  // marginBottom: "3vh",
  inputBaseRoot: {
    borderRadius: "10.67px",
    // height: "40px",
    height: "46px", /////
    // [theme.breakpoints.down("sm")]: {
    //   height: window.screen.height * 0.0707,
    //   maxHeight: "40px",
    //   backgroundColor: "white",
    // },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      height: "37px",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLabel: {
    [theme.breakpoints.down("sm")]: {
      transform: "translate(14px,11px) scale(1)",
    },
    transform: "translate(14px,15px) scale(1)",
  },
  svgIconRoot: {
    fontSize: "2rem",
  },
  checkbox: {
    padding: "0 8px 0 0 ",
  },
  keepLable: {
    fontSize: "clamp(0.8rem,1.28vw,1rem)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  forgotText: {
    textTransform: "none",
    fontWeight: "400",
    padding: 0,
    fontSize: "16px",
    whiteSpace: "nowrap",
    marginLeft: "8px",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  circleDiv: {
    height: "130vh",
    borderRadius: "0% 100rem 100rem 0%", // problematic at high heights and low widths
    // borderRadius: "0% 50% 50% 0%", // not as circular
    backgroundColor: "white",
    position: "relative",
    transform: "translate(0, -15vh)",
    maxWidth: "46.11%",
    flexBasis: "46.11%",
    padding: "15vh 0 15vh 7.013vw",
    zIndex: "2",
  },
  languageButton: {
    display: "flex",
    alignSelf: "flex-end",
    margin: "auto 10.5vw auto 0",
  },
  languageIcon: {
    fill: "#07303E",
    width: "18px",
    height: "18px",
    marginRight: "6px",
  },
  logo: {
    width: "fit-content", // why?
    marginLeft: "-17px",
  },
  logoGridItem: {
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "9.722vw",
      height: "max-content",
    },
  },
  // checkbox: {
  //   padding: 0,
  //   marginRight: "1vw",
  // },
  signinButton: {
    width: "100%",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "none",
    padding: "9px 45px",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    boxShadow: "0px 2px 5px rgba(9, 38, 48, 0.4)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 25px",
    },
  },
  loginImage: {
    position: "absolute",
    top: 0,
    left: 0,
    // right: "-10px",
    height: "100%",
    width: "100%",
    // width: "67.85%",
    objectFit: "cover",
  },
  secondIntroTypography: {
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    // marginLeft: "30%",
    zIndex: "1",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "400",
      margin: "0",
      color: "rgb(7,48,62)",
    },
  },

  icon: {
    borderRadius: 3,
    width: 22,
    height: 22,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    [theme.breakpoints.down("sm")]: {
      width: 16,
      height: 16,
    },
  },
  checkedIcon: {
    backgroundColor: "#0c6885",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      [theme.breakpoints.down("sm")]: {
        width: 16,
        height: 16,
      },
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));

export default useStyle;
