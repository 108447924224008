import fetch from "isomorphic-fetch";
import { parseJSON } from "../store/helpers";
import Live from "./Live";
import { getBase64 } from "./imagesFunctions";
// to test remove background in local go to singleImageInput.js and comment the Live() && condition but keep the rest of it

function checkHttpStatus(response) {
  // handle errors or return response
  if (response.status == 200) {
    return response;
  } else {
    console.error("Error:", response.status, response.statusText);
    let error = new Error(response.status);
    throw error;
  }
}

// function getBase64(file, onLoadCallback) {
//   return new Promise(function (resolve, reject) {
//     var reader = new FileReader();
//     reader.onload = function () {
//       resolve(reader.result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

const removeBg = async (image_file) => {
  return new Promise(function (resolve, reject) {
    const image_file_s = new File([image_file], "name");
    // const apiKey = 'tDongNd7ihNi8q2cW7tBuyst'; // Baker
    const apiKey = "uXmYqdXTeiwwLTeR24W7YN7F"; // Hamzeh
    // const apiKey = "j4Vh8pKCqdGd93MyTNE2g2QM"; // Hamada (EMAIL: ma7sere.1@gmail.com, PW:MENAdomination)
    const url = "https://api.remove.bg/v1.0/removebg";

    var promise = getBase64(image_file_s);
    promise.then(function (result) {
      const image_file_b64 = result.replace(
        "application/octet-stream",
        "image/png"
      );
      const body = {
        image_file_b64: image_file_b64,
        image_url: "",
        // size: Live() ? "full" : "preview", // default change later
        size: "full", // default change later
        type: "car",
        type_level: "2", //  car, car_interior, car_part,
        // type_level:"latest",
        format: "png",
        crop: false,
        scale: "original",
        channels: "rgba", // default
        add_shadow: true,
        semitransparency: true, // default, for car windows
        bg_color: "", // hex
        // bg_image_url:
        //   "https://markabte-static.s3.eu-central-1.amazonaws.com/car-background.png",
      };

      fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "X-Api-Key": apiKey,
          Accept: "application/json", // api returns b64 string without it it returns image file
          "Content-Type": "application/json",
        },
      })
        .then(checkHttpStatus) // handle errors or return response
        .then(parseJSON)
        .then((response) => {
          resolve(response.data.result_b64);
        })
        .catch((error) => {
          let rej = reject(new Error("fail"));
        });
    });
  });
};

export default removeBg;
