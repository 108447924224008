import React from "react";

const FeatureIcon17 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M21.9233 35.4597C20.9378 31.3453 19.7472 28.9896 18.2832 28.2576C14.5184 26.3752 10.3811 27.2886 6.47085 28.5558C6.10852 28.6732 5.89629 29.0484 5.9822 29.4194C7.80872 37.3095 14.4303 43.3121 22.4594 44.3561C22.9243 44.4165 23.3354 44.0124 23.2834 43.5468C23.0947 41.8598 22.6672 38.5659 21.9233 35.4597ZM7.57711 29.7446C10.9917 28.6869 14.5324 28.0231 17.6268 29.5703C19.2406 30.3772 20.8212 35.508 21.7125 42.7535C14.9343 41.5026 9.38934 36.411 7.57711 29.7446Z" fill="#07303E"/>
<path d="M43.5292 28.5558C39.6188 27.2885 35.4813 26.3753 31.7168 28.2575C30.2529 28.9894 29.0622 31.3453 28.0767 35.4597C27.3327 38.5659 26.9052 41.8598 26.7166 43.5468C26.6646 44.0122 27.0756 44.4165 27.5406 44.3561C35.5697 43.312 42.1914 37.3095 44.0178 29.4194C44.1037 29.0483 43.8915 28.6732 43.5292 28.5558ZM28.2875 42.7534C29.1788 35.5079 30.7594 30.3772 32.3732 29.5703C33.6841 28.9148 35.075 28.6562 36.5026 28.6562C38.4448 28.6562 40.4549 29.1349 42.4229 29.7446C40.6107 36.4111 35.0658 41.5026 28.2875 42.7534Z" fill="#07303E"/>
<path d="M6.35919 23.3856H41.7452C42.1505 23.3856 42.479 23.057 42.479 22.6517C42.479 22.2464 42.1505 21.9178 41.7452 21.9178H7.20958C8.68541 13.3263 16.1907 6.9472 25 6.9472C33.8093 6.9472 41.3146 13.3263 42.7904 21.9178H37.5244C37.1192 21.9178 36.7906 22.2464 36.7906 22.6517C36.7906 23.057 37.1192 23.3856 37.5244 23.3856H43.6408C44.0753 23.3856 44.4228 22.9924 44.369 22.5609C43.1547 12.8229 34.8278 5.47949 25 5.47949C15.1722 5.47949 6.84529 12.8229 5.63091 22.5609C5.57709 22.9924 5.92455 23.3856 6.35919 23.3856Z" fill="#07303E"/>
<path d="M42.6777 7.32231C37.9558 2.60049 31.6778 0 25 0C18.3222 0 12.0442 2.60049 7.32231 7.32231C2.60049 12.0442 0 18.3222 0 25C0 31.6778 2.60049 37.9558 7.32231 42.6777C12.0442 47.3995 18.3222 50 25 50C31.6778 50 37.9558 47.3995 42.6777 42.6777C47.3995 37.9558 50 31.6778 50 25C50 18.3222 47.3995 12.0442 42.6777 7.32231ZM25 48.5323C12.0243 48.5323 1.46771 37.9757 1.46771 25C1.46771 12.0243 12.0243 1.46771 25 1.46771C37.9757 1.46771 48.5323 12.0243 48.5323 25C48.5323 37.9757 37.9757 48.5323 25 48.5323Z" fill="#07303E"/>
<path d="M28.865 28.1311C28.865 26 27.1311 24.2661 25 24.2661C22.8689 24.2661 21.135 26 21.135 28.1311C21.135 30.2622 22.8689 31.9961 25 31.9961C27.1311 31.9961 28.865 30.2622 28.865 28.1311ZM25 30.5283C23.6782 30.5283 22.6028 29.4529 22.6028 28.1311C22.6028 26.8093 23.6782 25.7338 25 25.7338C26.3218 25.7338 27.3973 26.8093 27.3973 28.1311C27.3973 29.4529 26.3218 30.5283 25 30.5283Z" fill="#07303E"/>
<path d="M34.375 14.5834L39.2361 16.3691L41.6667 22.9167H34.375V14.5834Z" fill="#07303E"/>
<path d="M15.625 14.5834L10.7639 16.3691L8.33333 22.9167H15.625V14.5834Z" fill="#07303E"/>
<path d="M39.039 18.78H37.255V20.58H36.735V18.78H34.959V18.3H36.735V16.5H37.255V18.3H39.039V18.78Z" fill="white"/>
<path d="M14.8103 18.3V18.772H11.6983V18.3H14.8103Z" fill="white"/>
</svg>
);
};

export default FeatureIcon17;
