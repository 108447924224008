import React from "react";

const FeatureIcon12 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M44.8463 8.50039C26.519 9.21699 10.3218 12.5939 2.25264 17.3647C0.623109 18.3267 0.475862 18.5525 0.672191 19.7893C0.839071 20.8888 1.29063 21.6937 2.37044 22.8619C3.5386 24.1184 3.66622 24.3638 3.66622 25.3258C3.66622 26.2878 3.51897 26.5627 2.41952 27.721C0.711457 29.5273 0.534761 30.077 0.593659 33.444C0.623109 35.4761 0.652558 35.7313 0.848888 36.2319C1.33971 37.459 2.33118 38.4112 3.5386 38.8431C4.00979 39.0002 4.42209 39.0198 7.85785 39.0198H11.6666L11.9808 38.7057C12.2851 38.4014 12.2949 38.3719 12.3734 37.1841C12.5109 35.0441 12.9526 33.3459 13.8263 31.5593C15.8288 27.4462 19.4315 24.6485 24.006 23.6374C25.3999 23.3331 28.3743 23.3331 29.7486 23.6374C34.3329 24.6485 37.9257 27.4364 39.9381 31.5593C40.8118 33.3459 41.2535 35.0343 41.3909 37.1841C41.4695 38.3719 41.4793 38.4014 41.7836 38.7057L42.0977 39.0198H45.7298H49.3619L49.676 38.6959L50 38.3817V23.7552V9.12865V8.50039L49.3619 8.49058L47.4673 8.48076C46.4366 8.48076 45.2488 8.49058 44.8463 8.50039ZM47.6931 23.7552V36.7129H45.6807H43.6782L43.6291 36.389C43.2855 33.984 42.9419 32.6882 42.245 31.0979C39.8105 25.5614 34.6274 21.7624 28.6295 21.1146C21.434 20.3292 14.4742 24.3933 11.5194 31.0979C10.8224 32.6882 10.4789 33.984 10.1353 36.389L10.0862 36.7129H7.25905C4.4908 36.7129 4.42208 36.7129 3.99998 36.4872C3.76438 36.3595 3.44044 36.065 3.27356 35.8393L2.97906 35.4172V33.1496C2.97906 30.4304 2.92998 30.5973 4.28465 29.1346C5.34483 27.9959 5.69823 27.4167 5.92401 26.494C6.19887 25.316 6.0418 24.0202 5.5019 23.0386C5.35465 22.7637 4.82456 22.0962 4.3141 21.5563C3.81346 21.0066 3.30301 20.3783 3.19503 20.1427L2.97906 19.7206L3.22447 19.5243C3.62695 19.2102 6.53263 17.8064 7.83822 17.2861C15.6914 14.1841 27.4025 11.946 40.3307 11.0919C42.2548 10.9643 45.1703 10.8367 46.6427 10.8171L47.6931 10.7974V23.7552Z" fill="#07303E"/>
<path d="M45.9183 8.17456L35.3636 8.67716L37.374 11.1902L45.4157 12.1954L45.9183 8.17456Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.9027 14.703H37.8766C37.5962 14.703 37.3688 14.9304 37.3688 15.2108C37.3688 15.4913 37.5962 15.7186 37.8766 15.7186H42.9027C43.1831 15.7186 43.4105 15.4913 43.4105 15.2108C43.4105 14.9304 43.1831 14.703 42.9027 14.703ZM37.8766 13.703C37.0439 13.703 36.3688 14.3781 36.3688 15.2108C36.3688 16.0436 37.0439 16.7186 37.8766 16.7186H42.9027C43.7354 16.7186 44.4105 16.0436 44.4105 15.2108C44.4105 14.3781 43.7354 13.703 42.9027 13.703H37.8766Z" fill="#07303E"/>
<path d="M38.5334 10.9423C38.4896 11.302 38.3083 11.6479 37.9984 11.8897L37.6908 11.4955L37.999 11.8892C37.3808 12.3732 36.4879 12.2645 36.0039 11.6463L38.5334 10.9423ZM38.5334 10.9423C38.5772 10.5824 38.4841 10.2034 38.2419 9.89414M38.5334 10.9423L38.2419 9.89414M38.2419 9.89414L35.8321 6.81657M38.2419 9.89414L35.8321 6.81657M35.8321 6.81657C35.3481 6.1984 34.4552 6.08964 33.837 6.57369L35.8321 6.81657ZM41.5591 10.5041L41.5517 7.43324C41.55 6.69533 40.9133 6.16704 40.216 6.16871C39.5187 6.17037 38.8845 6.70171 38.8863 7.43962L38.8937 10.5105C38.8954 11.2484 39.5321 11.7767 40.2294 11.775C40.9267 11.7734 41.5609 11.242 41.5591 10.5041ZM42.3491 10.9423C42.3929 11.3018 42.5741 11.6474 42.8836 11.8892L47.0456 6.57369C46.4273 6.08964 45.5345 6.19839 45.0505 6.81657L42.6407 9.89414C42.3985 10.2034 42.3053 10.5824 42.3491 10.9423ZM42.8837 11.8893C43.5019 12.3732 44.3947 12.2645 44.8787 11.6463L47.2884 8.56879C47.2884 8.56877 47.2884 8.56876 47.2884 8.56875C47.7725 7.95056 47.6638 7.05774 47.0456 6.57372L42.8837 11.8893ZM33.5942 8.56879L36.0039 11.6463L33.837 6.57372C33.2188 7.05774 33.11 7.95056 33.5941 8.56875C33.5942 8.56876 33.5942 8.56877 33.5942 8.56879Z" fill="#07303E" stroke="white"/>
</svg>
);
};

export default FeatureIcon12;
