import React from "react";

const FeatureIcon4 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon4Clip0)">
<path d="M25.3405 6.30054C25.3405 5.76118 24.9033 5.32397 24.364 5.32397H8.37979C7.84044 5.32397 7.40323 5.76118 7.40323 6.30054V15.5173C7.40323 16.0567 7.84044 16.4939 8.37979 16.4939H24.364C24.9033 16.4939 25.3405 16.0567 25.3405 15.5173V6.30054ZM23.3874 14.5408H9.35635V7.2771H23.3874V14.5408Z" fill="#07303E"/>
<path d="M15.3291 39.2252C15.4143 39.2484 15.5004 39.2596 15.5856 39.2596C15.9254 39.2596 16.2489 39.0815 16.4274 38.7777L22.3578 28.6861C22.5353 28.3841 22.5374 28.0103 22.3635 27.7063C22.1896 27.4023 21.8661 27.2148 21.5159 27.2148H18.135V21.1207C18.135 20.6801 17.8399 20.2941 17.4148 20.1784C16.9897 20.0627 16.5398 20.246 16.3165 20.6259L10.3861 30.7175C10.2087 31.0195 10.2065 31.3934 10.3805 31.6973C10.5545 32.0013 10.8778 32.1888 11.2281 32.1888H14.6092V38.2829C14.6089 38.7235 14.9039 39.1095 15.3291 39.2252ZM12.9345 30.2358L16.1816 24.7101V28.1913C16.1816 28.7307 16.6189 29.1679 17.1582 29.1679H19.8092L16.562 34.6936V31.2123C16.562 30.673 16.1248 30.2358 15.5855 30.2358H12.9345Z" fill="#07303E"/>
<path d="M47.9531 27.1407H47.87V22.9898C47.87 22.006 47.2638 21.161 46.4052 20.8091V17.8447C46.4052 17.3054 45.968 16.8682 45.4286 16.8682H44.1175V9.73721C44.1175 6.84551 41.7648 4.49287 38.8731 4.49287C35.9814 4.49287 33.6288 6.84551 33.6288 9.73721V35.8744C33.6288 37.5076 32.3001 38.8363 30.6669 38.8363H29.681V28.6945C29.681 28.1552 29.2438 27.718 28.7045 27.718C28.1651 27.718 27.7279 28.1552 27.7279 28.6945V43.6436H5.01572V3.21211C5.01572 2.51787 5.58047 1.95312 6.27461 1.95312H26.469C27.1632 1.95312 27.728 2.51787 27.728 3.21211V29.8652C27.728 30.4046 28.1652 30.8418 28.7046 30.8418C29.2439 30.8418 29.6811 30.4046 29.6811 29.8652V3.21211C29.6811 1.44092 28.2402 0 26.469 0H6.27461C4.50352 0 3.0626 1.44092 3.0626 3.21211V43.6436H2.04687C1.50752 43.6436 1.07031 44.0808 1.07031 44.6201V49.0234C1.07031 49.5628 1.50752 50 2.04687 50H30.6969C31.2362 50 31.6734 49.5628 31.6734 49.0234V44.6201C31.6734 44.0808 31.2362 43.6436 30.6969 43.6436H29.6811V40.7895H30.667C33.3771 40.7895 35.582 38.5846 35.582 35.8744V9.73721C35.582 7.92246 37.0585 6.446 38.8732 6.446C40.688 6.446 42.1644 7.92246 42.1644 9.73721V16.8682H40.8533C40.314 16.8682 39.8768 17.3054 39.8768 17.8447V20.8091C39.0182 21.1611 38.4119 22.0061 38.4119 22.9898V27.1407H38.3288C37.7894 27.1407 37.3522 27.5779 37.3522 28.1173C37.3522 28.6566 37.7894 29.0938 38.3288 29.0938H40.0397V30.5152C40.0397 31.0546 40.4769 31.4918 41.0163 31.4918C41.5557 31.4918 41.9929 31.0546 41.9929 30.5152V29.0938H44.2892V30.5152C44.2892 31.0546 44.7264 31.4918 45.2657 31.4918C45.8051 31.4918 46.2423 31.0546 46.2423 30.5152V29.0938H47.9532C48.4926 29.0938 48.9298 28.6566 48.9298 28.1173C48.9297 27.5779 48.4926 27.1407 47.9531 27.1407ZM29.7203 48.0469H3.02344V45.5967H29.7203V48.0469ZM41.8299 18.8213H44.4521V20.6342H41.8299V18.8213ZM40.365 22.9898C40.365 22.7679 40.5457 22.5873 40.7676 22.5873H45.5143C45.7363 22.5873 45.917 22.7679 45.917 22.9898V27.1407H40.365V22.9898Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon4Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon4;
