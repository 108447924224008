import React from "react";
export function Center(props) {
  return (
    <>
      {props.location == "4288" ? (
        <>
          <g filter="url(#filter0_f_0_1)">
            <ellipse
              cx="456.895"
              cy="429.708"
              rx="103.795"
              ry="97.2909"
              fill="#87BCC7"
              fill-opacity="0.8"
            />
          </g>
          <path
            d="M502.857 442.878C497.44 445.566 491.689 446.941 485.709 446.941C464.457 446.941 447.163 429.647 447.163 408.394C447.163 402.435 448.538 396.664 451.226 391.246C451.83 390.038 451.58 388.6 450.643 387.642C449.684 386.683 448.247 386.454 447.038 387.058C431.703 394.705 422.16 410.061 422.16 427.147C422.16 451.858 442.246 471.944 466.957 471.944C484.043 471.944 499.399 462.422 507.045 447.066C507.65 445.857 507.4 444.42 506.462 443.461C505.504 442.503 504.045 442.273 502.857 442.878V442.878ZM466.957 465.693C445.705 465.693 428.411 448.399 428.411 427.147C428.411 415.52 433.557 404.811 442.246 397.601C441.371 401.123 440.912 404.727 440.912 408.394C440.912 433.106 460.998 453.191 485.709 453.191C489.377 453.191 493.002 452.754 496.502 451.858C489.293 460.547 478.584 465.693 466.957 465.693V465.693Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <g
            onClick={() => props.action("4288")}
            filter="url(#filter0_f_0_1)"
            fill="#00000000"
          >
            <ellipse
              cx="455.009"
              cy="429.64"
              rx="154.262"
              ry="97.2909"
              fill="white"
              fill-opacity="0.8"
            />
          </g>
          <path
            onClick={() => props.action("4288")}
            d="M502.857 442.878C497.44 445.566 491.689 446.941 485.709 446.941C464.457 446.941 447.163 429.647 447.163 408.394C447.163 402.435 448.538 396.664 451.226 391.246C451.83 390.038 451.58 388.6 450.643 387.642C449.684 386.683 448.247 386.454 447.038 387.058C431.703 394.705 422.16 410.061 422.16 427.147C422.16 451.858 442.246 471.944 466.957 471.944C484.043 471.944 499.399 462.422 507.045 447.066C507.65 445.857 507.4 444.42 506.462 443.461C505.504 442.503 504.045 442.273 502.857 442.878V442.878ZM466.957 465.693C445.705 465.693 428.411 448.399 428.411 427.147C428.411 415.52 433.557 404.811 442.246 397.601C441.371 401.123 440.912 404.727 440.912 408.394C440.912 433.106 460.998 453.191 485.709 453.191C489.377 453.191 493.002 452.754 496.502 451.858C489.293 460.547 478.584 465.693 466.957 465.693V465.693Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
      ;
    </>
  );
}

export function Left(props) {
  return (
    <>
      {props.location == "4291" ? (
        <>
          <path
            d="M31.2718 614.021C18.3508 579.607 9.77713 544.049 5.52698 508.133C0.268644 463.698 1.62828 418.716 9.56081 374.68C15.9724 339.086 26.6781 304.111 41.6541 270.54L137.514 313.684C100.475 396.936 97.6196 491.409 129.563 576.746L31.2718 614.021Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />

          <path
            d="M97.0626 450.893C91.6453 453.581 85.8946 454.956 79.9147 454.956C58.6621 454.956 41.3683 437.663 41.3683 416.41C41.3683 410.451 42.7435 404.679 45.4313 399.262C46.0355 398.054 45.7855 396.616 44.8479 395.657C43.8894 394.699 42.4518 394.47 41.2433 395.074C25.908 402.721 16.3652 418.077 16.3652 435.162C16.3652 459.874 36.451 479.96 61.1624 479.96C78.2478 479.96 93.6039 470.437 101.251 455.081C101.855 453.873 101.605 452.435 100.667 451.477C99.7088 450.518 98.2503 450.289 97.0626 450.893V450.893ZM61.1624 473.709C39.9098 473.709 22.616 456.415 22.616 435.162C22.616 423.536 27.7624 412.826 36.451 405.617C35.5759 409.138 35.1175 412.743 35.1175 416.41C35.1175 441.121 55.2033 461.207 79.9147 461.207C83.5818 461.207 87.2073 460.77 90.7077 459.874C83.4985 468.562 72.7888 473.709 61.1624 473.709V473.709Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4291")}
            d="M31.1737 614.09C18.2526 579.676 9.67899 544.117 5.42884 508.202C0.1705 463.767 1.53014 418.785 9.46266 374.748C15.8743 339.155 26.5799 304.179 41.5559 270.608L137.416 313.752C100.377 397.004 97.5214 491.478 129.464 576.814L31.1737 614.09Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4291")}
            d="M97.0626 450.893C91.6453 453.581 85.8946 454.956 79.9147 454.956C58.6621 454.956 41.3683 437.663 41.3683 416.41C41.3683 410.451 42.7435 404.679 45.4313 399.262C46.0355 398.054 45.7855 396.616 44.8479 395.657C43.8894 394.699 42.4518 394.47 41.2433 395.074C25.908 402.721 16.3652 418.077 16.3652 435.162C16.3652 459.874 36.451 479.96 61.1624 479.96C78.2478 479.96 93.6039 470.437 101.251 455.081C101.855 453.873 101.605 452.435 100.667 451.477C99.7088 450.518 98.2503 450.289 97.0626 450.893V450.893ZM61.1624 473.709C39.9098 473.709 22.616 456.415 22.616 435.162C22.616 423.536 27.7624 412.826 36.451 405.617C35.5759 409.138 35.1175 412.743 35.1175 416.41C35.1175 441.121 55.2033 461.207 79.9147 461.207C83.5818 461.207 87.2073 460.77 90.7077 459.874C83.4985 468.562 72.7888 473.709 61.1624 473.709V473.709Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function Right(props) {
  return (
    <>
      {props.location == "4284" ? (
        <>
          <path
            d="M802.419 465.398C803.82 420.287 796.42 375.34 780.636 333.067L878.958 295.873C891.772 330.089 900.368 365.654 904.614 401.783C907.216 423.924 908.184 446.277 907.489 468.661C906.794 491.045 904.44 513.295 900.469 535.232C893.989 571.028 883.203 605.991 868.29 639.346L772.467 596.122C790.843 554.91 801.018 510.509 802.419 465.398Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />

          <path
            d="M889.616 450.894C884.198 453.582 878.447 454.957 872.468 454.957C851.215 454.957 833.921 437.663 833.921 416.411C833.921 410.452 835.296 404.68 837.984 399.263C838.588 398.054 838.338 396.617 837.401 395.658C836.442 394.7 835.005 394.471 833.796 395.075C818.461 402.722 808.918 418.078 808.918 435.163C808.918 459.874 829.004 479.96 853.715 479.96C870.801 479.96 886.157 470.438 893.804 455.082C894.408 453.874 894.158 452.436 893.22 451.478C892.262 450.519 890.803 450.29 889.616 450.894V450.894ZM853.715 473.709C832.463 473.709 815.169 456.416 815.169 435.163C815.169 423.537 820.315 412.827 829.004 405.618C828.129 409.139 827.67 412.744 827.67 416.411C827.67 441.122 847.756 461.208 872.468 461.208C876.135 461.208 879.76 460.77 883.261 459.874C876.051 468.563 865.342 473.709 853.715 473.709V473.709Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4284")}
            d="M780.441 333.456L878.762 296.262C891.576 330.478 900.172 366.043 904.418 402.172C907.02 424.314 907.988 446.667 907.293 469.051C906.598 491.435 904.244 513.684 900.273 535.621C893.793 571.417 883.007 606.38 868.095 639.735L772.271 596.512C790.647 555.3 800.822 510.898 802.223 465.787C803.624 420.676 796.224 375.729 780.441 333.456Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4284")}
            d="M889.616 450.894C884.198 453.582 878.447 454.957 872.468 454.957C851.215 454.957 833.921 437.663 833.921 416.411C833.921 410.452 835.296 404.68 837.984 399.263C838.588 398.054 838.338 396.617 837.401 395.658C836.442 394.7 835.005 394.471 833.796 395.075C818.461 402.722 808.918 418.078 808.918 435.163C808.918 459.874 829.004 479.96 853.715 479.96C870.801 479.96 886.157 470.438 893.804 455.082C894.408 453.874 894.158 452.436 893.22 451.478C892.262 450.519 890.803 450.29 889.616 450.894V450.894ZM853.715 473.709C832.463 473.709 815.169 456.416 815.169 435.163C815.169 423.537 820.315 412.827 829.004 405.618C828.129 409.139 827.67 412.744 827.67 416.411C827.67 441.122 847.756 461.208 872.468 461.208C876.135 461.208 879.76 460.77 883.261 459.874C876.051 468.563 865.342 473.709 853.715 473.709V473.709Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function Front(props) {
  return (
    <>
      {props.location == "4287" ? (
        <>
          <path
            d="M577.678 780.067L615.184 878.27C580.801 891.272 545.262 899.929 509.357 904.263C464.934 909.626 419.949 908.372 375.894 900.543C340.286 894.216 305.285 883.592 271.679 868.695L314.598 772.734C397.936 809.577 492.416 812.21 577.678 780.067Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M506.866 870.716C501.448 873.404 495.698 874.779 489.718 874.779C468.465 874.779 451.171 857.485 451.171 836.233C451.171 830.274 452.546 824.502 455.234 819.085C455.839 817.876 455.589 816.439 454.651 815.48C453.692 814.522 452.255 814.293 451.046 814.897C435.711 822.544 426.168 837.9 426.168 854.985C426.168 879.696 446.254 899.782 470.965 899.782C488.051 899.782 503.407 890.26 511.054 874.904C511.658 873.696 511.408 872.258 510.47 871.3C509.512 870.341 508.053 870.112 506.866 870.716V870.716ZM470.965 893.531C449.713 893.531 432.419 876.238 432.419 854.985C432.419 843.359 437.565 832.649 446.254 825.44C445.379 828.961 444.921 832.566 444.921 836.233C444.921 860.944 465.006 881.03 489.718 881.03C493.385 881.03 497.01 880.592 500.511 879.696C493.302 888.385 482.592 893.531 470.965 893.531V893.531Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4287")}
            d="M577.481 780.15L614.988 878.353C580.604 891.355 545.066 900.012 509.161 904.347C464.738 909.709 419.753 908.456 375.698 900.627C340.09 894.299 305.089 883.675 271.483 868.778L314.401 772.817C397.74 809.66 492.22 812.294 577.481 780.15Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4287")}
            d="M506.866 870.716C501.448 873.404 495.698 874.779 489.718 874.779C468.465 874.779 451.171 857.485 451.171 836.233C451.171 830.274 452.546 824.502 455.234 819.085C455.839 817.876 455.589 816.439 454.651 815.48C453.692 814.522 452.255 814.293 451.046 814.897C435.711 822.544 426.168 837.9 426.168 854.985C426.168 879.696 446.254 899.782 470.965 899.782C488.051 899.782 503.407 890.26 511.054 874.904C511.658 873.696 511.408 872.258 510.47 871.3C509.512 870.341 508.053 870.112 506.866 870.716V870.716ZM470.965 893.531C449.713 893.531 432.419 876.238 432.419 854.985C432.419 843.359 437.565 832.649 446.254 825.44C445.379 828.961 444.921 832.566 444.921 836.233C444.921 860.944 465.006 881.03 489.718 881.03C493.385 881.03 497.01 880.592 500.511 879.696C493.302 888.385 482.592 893.531 470.965 893.531V893.531Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function Rear(props) {
  return (
    <>
      {props.location == "4289" ? (
        <>
          <path
            d="M296.04 31.1396C330.458 18.2291 366.019 9.66632 401.936 5.42719C446.373 0.182482 491.354 1.55592 535.388 9.50195C570.98 15.9245 605.952 26.6409 639.519 41.6272L596.345 137.474C513.105 100.41 418.632 97.5249 333.286 129.442L296.04 31.1396Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M506.866 65.1389C501.448 67.8268 495.698 69.2019 489.718 69.2019C468.465 69.2019 451.171 51.9081 451.171 30.6555C451.171 24.6965 452.546 18.9249 455.234 13.5076C455.839 12.2991 455.589 10.8614 454.651 9.90297C453.692 8.94452 452.255 8.71532 451.046 9.31957C435.711 16.9663 426.168 32.3224 426.168 49.4078C426.168 74.1192 446.254 94.205 470.965 94.205C488.051 94.205 503.407 84.683 511.054 69.3269C511.658 68.1185 511.408 66.6808 510.47 65.7223C509.512 64.7639 508.053 64.5347 506.866 65.1389V65.1389ZM470.965 87.9542C449.713 87.9542 432.419 70.6604 432.419 49.4078C432.419 37.7814 437.565 27.0718 446.254 19.8625C445.379 23.3838 444.921 26.9884 444.921 30.6555C444.921 55.3669 465.006 75.4527 489.718 75.4527C493.385 75.4527 497.01 75.0152 500.511 74.1192C493.302 82.8078 482.592 87.9542 470.965 87.9542V87.9542Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4289")}
            d="M295.942 31.223C330.36 18.3126 365.921 9.74981 401.838 5.51068C446.275 0.265978 491.256 1.63941 535.29 9.58545C570.882 16.008 605.854 26.7244 639.42 41.7107L596.247 137.557C513.007 100.493 418.534 97.6084 333.187 129.525L295.942 31.223Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4289")}
            d="M506.866 65.1389C501.448 67.8268 495.698 69.2019 489.718 69.2019C468.465 69.2019 451.171 51.9081 451.171 30.6555C451.171 24.6965 452.546 18.9249 455.234 13.5076C455.839 12.2991 455.589 10.8614 454.651 9.90297C453.692 8.94452 452.255 8.71532 451.046 9.31957C435.711 16.9663 426.168 32.3224 426.168 49.4078C426.168 74.1192 446.254 94.205 470.965 94.205C488.051 94.205 503.407 84.683 511.054 69.3269C511.658 68.1185 511.408 66.6808 510.47 65.7223C509.512 64.7639 508.053 64.5347 506.866 65.1389V65.1389ZM470.965 87.9542C449.713 87.9542 432.419 70.6604 432.419 49.4078C432.419 37.7814 437.565 27.0718 446.254 19.8625C445.379 23.3838 444.921 26.9884 444.921 30.6555C444.921 55.3669 465.006 75.4527 489.718 75.4527C493.385 75.4527 497.01 75.0152 500.511 74.1192C493.302 82.8078 482.592 87.9542 470.965 87.9542V87.9542Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function RightRear(props) {
  return (
    <>
      {props.location == "4286" ? (
        <>
          <path
            d="M708.197 216.128C677.272 183.255 640.244 156.724 599.182 138.014L642.357 42.1679C675.62 57.2843 706.856 76.3388 735.417 98.8689C752.92 112.676 769.419 127.789 784.763 144.101C800.108 160.412 814.185 177.802 826.899 196.116C847.644 225.999 864.756 258.34 877.814 292.463L779.511 329.707C763.342 287.58 739.121 249.001 708.197 216.128Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M795.43 189.382C790.013 192.07 784.262 193.445 778.282 193.445C757.03 193.445 739.736 176.151 739.736 154.898C739.736 148.939 741.111 143.168 743.799 137.75C744.403 136.542 744.153 135.104 743.215 134.146C742.257 133.187 740.819 132.958 739.611 133.562C724.276 141.209 714.733 156.565 714.733 173.651C714.733 198.362 734.819 218.448 759.53 218.448C776.615 218.448 791.971 208.926 799.618 193.57C800.222 192.361 799.972 190.924 799.035 189.965C798.076 189.007 796.618 188.778 795.43 189.382V189.382ZM759.53 212.197C738.277 212.197 720.983 194.903 720.983 173.651C720.983 162.024 726.13 151.315 734.819 144.105C733.943 147.627 733.485 151.231 733.485 154.898C733.485 179.61 753.571 199.696 778.282 199.696C781.949 199.696 785.575 199.258 789.075 198.362C781.866 207.051 771.156 212.197 759.53 212.197V212.197Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4286")}
            d="M708.001 216.905C677.077 184.032 640.048 157.501 598.987 138.791L642.161 42.9452C675.424 58.0617 706.66 77.1161 735.221 99.6462C752.725 113.454 769.223 128.566 784.568 144.878C799.912 161.19 813.99 178.58 826.703 196.893C847.448 226.776 864.56 259.117 877.618 293.24L779.316 330.485C763.147 288.358 738.926 249.779 708.001 216.905Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4286")}
            d="M795.43 189.382C790.013 192.07 784.262 193.445 778.282 193.445C757.03 193.445 739.736 176.151 739.736 154.898C739.736 148.939 741.111 143.168 743.799 137.75C744.403 136.542 744.153 135.104 743.215 134.146C742.257 133.187 740.819 132.958 739.611 133.562C724.276 141.209 714.733 156.565 714.733 173.651C714.733 198.362 734.819 218.448 759.53 218.448C776.615 218.448 791.971 208.926 799.618 193.57C800.222 192.361 799.972 190.924 799.035 189.965C798.076 189.007 796.618 188.778 795.43 189.382V189.382ZM759.53 212.197C738.277 212.197 720.983 194.903 720.983 173.651C720.983 162.024 726.13 151.315 734.819 144.105C733.943 147.627 733.485 151.231 733.485 154.898C733.485 179.61 753.571 199.696 778.282 199.696C781.949 199.696 785.575 199.258 789.075 198.362C781.866 207.051 771.156 212.197 759.53 212.197V212.197Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function LeftRear(props) {
  return (
    <>
      {props.location == "4292" ? (
        <>
          <path
            d="M293.989 29.6158V29.6157V29.6158ZM138.766 310.194L42.9121 267.036C58.1148 233.567 77.2005 202.364 99.5954 173.965C127.303 138.831 160.075 107.99 196.827 82.4656C226.532 61.8353 258.836 44.6783 293.165 31.5347L330.427 129.831C245.364 162.496 176.533 227.27 138.766 310.194Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M218.301 177.359C212.883 180.046 207.133 181.422 201.153 181.422C179.9 181.422 162.606 164.128 162.606 142.875C162.606 136.916 163.981 131.145 166.669 125.727C167.273 124.519 167.023 123.081 166.086 122.123C165.127 121.164 163.69 120.935 162.481 121.539C147.146 129.186 137.603 144.542 137.603 161.628C137.603 186.339 157.689 206.425 182.4 206.425C199.486 206.425 214.842 196.903 222.489 181.547C223.093 180.338 222.843 178.9 221.905 177.942C220.947 176.984 219.488 176.754 218.301 177.359V177.359ZM182.4 200.174C161.148 200.174 143.854 182.88 143.854 161.628C143.854 150.001 149 139.291 157.689 132.082C156.814 135.603 156.355 139.208 156.355 142.875C156.355 167.587 176.441 187.672 201.153 187.672C204.82 187.672 208.445 187.235 211.946 186.339C204.736 195.027 194.027 200.174 182.4 200.174V200.174Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4292")}
            d="M293.891 30.3934L293.891 30.3934L293.891 30.3934ZM293.891 30.3934L293.891 30.3935L293.891 30.3934ZM138.668 310.971L42.814 267.813C58.0166 234.345 77.1023 203.141 99.4973 174.743C127.205 139.608 159.977 108.767 196.729 83.2433C226.434 62.613 258.738 45.456 293.067 32.3124L330.329 130.608C245.266 163.274 176.435 228.047 138.668 310.971Z"
            stroke="#9F9F9F"
            fill="#00000000"
            stroke-width="3"
          />
          <path
            onClick={() => props.action("4292")}
            d="M218.301 177.359C212.883 180.046 207.133 181.422 201.153 181.422C179.9 181.422 162.606 164.128 162.606 142.875C162.606 136.916 163.981 131.145 166.669 125.727C167.273 124.519 167.023 123.081 166.086 122.123C165.127 121.164 163.69 120.935 162.481 121.539C147.146 129.186 137.603 144.542 137.603 161.628C137.603 186.339 157.689 206.425 182.4 206.425C199.486 206.425 214.842 196.903 222.489 181.547C223.093 180.338 222.843 178.9 221.905 177.942C220.947 176.984 219.488 176.754 218.301 177.359V177.359ZM182.4 200.174C161.148 200.174 143.854 182.88 143.854 161.628C143.854 150.001 149 139.291 157.689 132.082C156.814 135.603 156.355 139.208 156.355 142.875C156.355 167.587 176.441 187.672 201.153 187.672C204.82 187.672 208.445 187.235 211.946 186.339C204.736 195.027 194.027 200.174 182.4 200.174V200.174Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function FrontRight(props) {
  return (
    <>
      {props.location == "4283" ? (
        <>
          <path
            d="M579.209 778.708C664.318 746.164 733.242 681.489 771.127 598.619L866.919 641.913C851.668 675.36 832.538 706.536 810.103 734.903C782.346 769.998 749.529 800.792 712.742 826.264C683.007 846.852 650.679 863.963 616.33 877.057L579.209 778.708Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M766.373 765.509C760.956 768.197 755.205 769.572 749.226 769.572C727.973 769.572 710.679 752.278 710.679 731.026C710.679 725.067 712.054 719.295 714.742 713.878C715.346 712.669 715.096 711.232 714.159 710.273C713.2 709.315 711.763 709.086 710.554 709.69C695.219 717.337 685.676 732.693 685.676 749.778C685.676 774.489 705.762 794.575 730.473 794.575C747.559 794.575 762.915 785.053 770.561 769.697C771.166 768.489 770.916 767.051 769.978 766.093C769.02 765.134 767.561 764.905 766.373 765.509V765.509ZM730.473 788.325C709.221 788.325 691.927 771.031 691.927 749.778C691.927 738.152 697.073 727.442 705.762 720.233C704.887 723.754 704.428 727.359 704.428 731.026C704.428 755.737 724.514 775.823 749.226 775.823C752.893 775.823 756.518 775.385 760.019 774.489C752.809 783.178 742.1 788.325 730.473 788.325V788.325Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4283")}
            d="M579.013 779.569C664.122 747.025 733.046 682.349 770.931 599.479L866.723 642.773C851.473 676.22 832.343 707.397 809.907 735.763C782.15 770.858 749.333 801.652 712.546 827.124C682.811 847.712 650.483 864.823 616.135 877.918L579.013 779.569Z"
            stroke="#9F9F9F"
            stroke-width="3"
            fill="#00000000"
          />
          <path
            onClick={() => props.action("4283")}
            d="M766.373 765.509C760.956 768.197 755.205 769.572 749.226 769.572C727.973 769.572 710.679 752.278 710.679 731.026C710.679 725.067 712.054 719.295 714.742 713.878C715.346 712.669 715.096 711.232 714.159 710.273C713.2 709.315 711.763 709.086 710.554 709.69C695.219 717.337 685.676 732.693 685.676 749.778C685.676 774.489 705.762 794.575 730.473 794.575C747.559 794.575 762.915 785.053 770.561 769.697C771.166 768.489 770.916 767.051 769.978 766.093C769.02 765.134 767.561 764.905 766.373 765.509V765.509ZM730.473 788.325C709.221 788.325 691.927 771.031 691.927 749.778C691.927 738.152 697.073 727.442 705.762 720.233C704.887 723.754 704.428 727.359 704.428 731.026C704.428 755.737 724.514 775.823 749.226 775.823C752.893 775.823 756.518 775.385 760.019 774.489C752.809 783.178 742.1 788.325 730.473 788.325V788.325Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

export function FrontLeft(props) {
  return (
    <>
      {props.location == "4290" ? (
        <>
          <path
            d="M130.724 579.873C163.468 664.906 228.305 733.677 311.264 771.367L268.195 867.261C234.712 852.09 203.491 833.033 175.072 810.664C139.912 782.989 109.041 750.245 83.4825 713.518C62.8247 683.832 45.6378 651.544 32.4623 617.226L130.724 579.873ZM130.2 578.506L131.602 577.972L31.927 615.825L130.188 578.472L130.2 578.505L130.2 578.506Z"
            fill="#87BCC7"
            stroke="#0C6885"
            stroke-width="3"
          />
          <path
            d="M210.285 732.445C204.868 735.133 199.117 736.508 193.137 736.508C171.884 736.508 154.591 719.215 154.591 697.962C154.591 692.003 155.966 686.231 158.654 680.814C159.258 679.606 159.008 678.168 158.07 677.209C157.112 676.251 155.674 676.022 154.466 676.626C139.13 684.273 129.587 699.629 129.587 716.714C129.587 741.426 149.673 761.511 174.385 761.511C191.47 761.511 206.826 751.989 214.473 736.633C215.077 735.425 214.827 733.987 213.89 733.029C212.931 732.07 211.473 731.841 210.285 732.445V732.445ZM174.385 755.261C153.132 755.261 135.838 737.967 135.838 716.714C135.838 705.088 140.985 694.378 149.673 687.169C148.798 690.69 148.34 694.295 148.34 697.962C148.34 722.673 168.426 742.759 193.137 742.759C196.804 742.759 200.43 742.322 203.93 741.426C196.721 750.114 186.011 755.261 174.385 755.261V755.261Z"
            fill="#0C6885"
            stroke="#0C6885"
            stroke-width="2"
          />
        </>
      ) : (
        <>
          <path
            onClick={() => props.action("4290")}
            d="M130.626 579.873C163.37 664.906 228.207 733.677 311.166 771.367L268.097 867.261C234.614 852.09 203.393 833.033 174.974 810.664C139.814 782.989 108.943 750.245 83.3844 713.518C62.7265 683.832 45.5396 651.544 32.3642 617.226L130.626 579.873ZM130.102 578.506L131.504 577.972L31.8289 615.825L130.09 578.472L130.102 578.505L130.102 578.506Z"
            stroke="#9F9F9F"
            fill="#00000000"
            stroke-width="3"
          />
          <path
            onClick={() => props.action("4290")}
            d="M210.285 732.445C204.868 735.133 199.117 736.508 193.137 736.508C171.884 736.508 154.591 719.215 154.591 697.962C154.591 692.003 155.966 686.231 158.654 680.814C159.258 679.606 159.008 678.168 158.07 677.209C157.112 676.251 155.674 676.022 154.466 676.626C139.13 684.273 129.587 699.629 129.587 716.714C129.587 741.426 149.673 761.511 174.385 761.511C191.47 761.511 206.826 751.989 214.473 736.633C215.077 735.425 214.827 733.987 213.89 733.029C212.931 732.07 211.473 731.841 210.285 732.445V732.445ZM174.385 755.261C153.132 755.261 135.838 737.967 135.838 716.714C135.838 705.088 140.985 694.378 149.673 687.169C148.798 690.69 148.34 694.295 148.34 697.962C148.34 722.673 168.426 742.759 193.137 742.759C196.804 742.759 200.43 742.322 203.93 741.426C196.721 750.114 186.011 755.261 174.385 755.261V755.261Z"
            fill="#96AFB8"
            stroke="#96AFB8"
            stroke-width="2"
          />
        </>
      )}
    </>
  );
}

var ids = [
  "4288",
  "4291",
  "4284",
  "4287",
  "4289",
  "4286",
  "4292",
  "4283",
  "4290",
];

export function LightSide(props) {
  // var result=<></>
  switch (props.location) {
    case "4291":
      return (
        <path
          d="M362.466 512.407L37.2559 607.538L43.5978 269.297L362.466 405.65L362.466 512.407Z"
          fill="#87BCC7"
        />
      );
    case "4284":
      return (
        <path
          d="M530.172 495.335L868.319 640.545L833.504 309.564L539.311 425.268L530.172 495.335Z"
          fill="#87BCC7"
        />
      );
    case "4287":
      return (
        <path
          d="M314.23 771.783L359.094 589.486L537.779 562.048L582.681 798.903L420.06 863.894L314.23 771.783Z"
          fill="#87BCC7"
        />
      );
    case "4289":
      return (
        <path
          d="M374.565 255.453L296.967 32.4817L640.756 41.322L544.495 255.453H374.565Z"
          fill="#87BCC7"
        />
      );
    case "4286":
      return (
        <path
          d="M494.817 446.896L876.775 293.15L637.512 46.2917L472.597 397.008L494.817 446.896Z"
          fill="#87BCC7"
        />
      );
    case "4292":
      return (
        <path
          d="M356.949 424.387L43.0347 267.948L292.716 30.699L411.859 308.353L356.949 424.387Z"
          fill="#87BCC7"
        />
      );
    case "4283":
      return (
        <path
          d="M532.352 482.469L866.515 643.626L607.955 855.538L488.813 577.885L532.352 482.469Z"
          fill="#87BCC7"
        />
      );
    case "4290":
      return (
        <path
          d="M411.351 460.121L35.3926 619.977L266.957 869.542L425.83 535.145L411.351 460.121Z"
          fill="#87BCC7"
        />
      );
    default:
      return <></>;
  }
}
