import React from "react";

const FeatureIcon50 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M13.6896 0.624061C14.5696 0.624061 15.3416 0.796061 16.0056 1.14006C16.6776 1.48406 17.1936 1.97606 17.5536 2.61606C17.9216 3.24806 18.1056 3.98406 18.1056 4.82406C18.1056 5.66406 17.9216 6.40006 17.5536 7.03206C17.1936 7.65606 16.6776 8.14006 16.0056 8.48406C15.3416 8.82806 14.5696 9.00006 13.6896 9.00006H10.7616V0.624061H13.6896ZM13.6296 7.57206C14.5096 7.57206 15.1896 7.33206 15.6696 6.85206C16.1496 6.37206 16.3896 5.69606 16.3896 4.82406C16.3896 3.95206 16.1496 3.27206 15.6696 2.78406C15.1896 2.28806 14.5096 2.04006 13.6296 2.04006H12.4416V7.57206H13.6296Z" fill="#07303E"/>
<path d="M21.0192 0.624061V5.80806C21.0192 6.37606 21.1672 6.81206 21.4632 7.11606C21.7592 7.41206 22.1752 7.56006 22.7112 7.56006C23.2552 7.56006 23.6752 7.41206 23.9712 7.11606C24.2672 6.81206 24.4152 6.37606 24.4152 5.80806V0.624061H26.1072V5.79606C26.1072 6.50806 25.9512 7.11206 25.6392 7.60806C25.3352 8.09606 24.9232 8.46406 24.4032 8.71206C23.8912 8.96006 23.3192 9.08406 22.6872 9.08406C22.0632 9.08406 21.4952 8.96006 20.9832 8.71206C20.4792 8.46406 20.0792 8.09606 19.7832 7.60806C19.4872 7.11206 19.3392 6.50806 19.3392 5.79606V0.624061H21.0192Z" fill="#07303E"/>
<path d="M32.8663 7.40406H29.5303L28.9783 9.00006H27.2143L30.2263 0.612061H32.1823L35.1943 9.00006H33.4183L32.8663 7.40406ZM32.4103 6.06006L31.1983 2.55606L29.9863 6.06006H32.4103Z" fill="#07303E"/>
<path d="M38.0002 7.66806H40.7602V9.00006H36.3202V0.624061H38.0002V7.66806Z" fill="#07303E"/>
<path d="M37.8446 20.0368C35.2825 17.0991 31.8413 15.0863 28.0625 14.2924V12.0698H20.6975V14.2926C16.887 15.0932 13.4178 17.1359 10.8524 20.1096C8.05856 23.3477 6.52002 27.4892 6.52002 31.7711C6.52002 41.6191 14.532 49.6311 24.38 49.6311C34.2281 49.6311 42.24 41.6191 42.24 31.7711C42.24 27.4539 40.679 23.2866 37.8446 20.0368ZM23.4594 14.8317H25.3006V20.3554H23.4594V14.8317ZM24.38 46.8692C16.0549 46.8692 9.28188 40.0962 9.28188 31.7711C9.28188 28.1513 10.5823 24.6505 12.9434 21.9137C14.9848 19.5475 17.7014 17.8781 20.6975 17.1256V23.1172H28.0625V17.1263C31.0318 17.8719 33.7257 19.5159 35.7631 21.8521C38.1588 24.5989 39.4782 28.1215 39.4782 31.7711C39.4782 40.0962 32.7052 46.8692 24.38 46.8692Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6825 14.6008V12.4498H21.0775V14.601L20.7757 14.6644C17.0468 15.448 13.6512 17.4471 11.1401 20.3578C8.4059 23.5268 6.90002 27.5802 6.90002 31.7711C6.90002 41.4092 14.7418 49.2511 24.38 49.2511C34.0182 49.2511 41.86 41.4092 41.86 31.7711C41.86 27.5456 40.3322 23.4671 37.5582 20.2865C35.0503 17.411 31.6822 15.4412 27.9844 14.6643L27.6825 14.6008ZM20.6975 12.0698H28.0625V14.2924C31.8413 15.0863 35.2825 17.0991 37.8446 20.0368C40.679 23.2866 42.24 27.4539 42.24 31.7711C42.24 41.6191 34.2281 49.6311 24.38 49.6311C14.532 49.6311 6.52002 41.6191 6.52002 31.7711C6.52002 27.4892 8.05856 23.3477 10.8524 20.1096C13.4178 17.1359 16.887 15.0932 20.6975 14.2926V12.0698ZM23.0794 14.4517H25.6806V20.7354H23.0794V14.4517ZM12.6556 21.6655C14.7482 19.2401 17.5329 17.5286 20.605 16.757L21.0775 16.6383V22.7372H27.6825V16.6391L28.155 16.7578C31.1995 17.5223 33.9611 19.2076 36.0495 21.6024C38.5057 24.4184 39.8582 28.0299 39.8582 31.7711C39.8582 40.3061 32.915 47.2492 24.38 47.2492C15.845 47.2492 8.90188 40.3061 8.90188 31.7711C8.90188 28.0603 10.2349 24.4714 12.6556 21.6655ZM35.7631 21.8521C33.813 19.6159 31.2613 18.014 28.4425 17.2271C28.3164 17.1918 28.1897 17.1583 28.0625 17.1263V23.1172H20.6975V17.1256C20.5704 17.1575 20.4437 17.1911 20.3175 17.2264C17.472 18.0211 14.8982 19.648 12.9434 21.9137C10.5823 24.6505 9.28188 28.1513 9.28188 31.7711C9.28188 40.0962 16.0549 46.8692 24.38 46.8692C32.7052 46.8692 39.4782 40.0962 39.4782 31.7711C39.4782 28.1215 38.1588 24.5989 35.7631 21.8521ZM23.4594 14.8317V20.3554H25.3006V14.8317H23.4594Z" fill="white"/>
<path d="M17.8101 28.7622H16.1479L13.36 36.2566H15.5377L15.9796 34.7894H17.9679L18.4203 36.2566H20.5876L17.8101 28.7622ZM16.2848 33.4172L16.9791 30.905L17.6419 33.4172H16.2848Z" fill="#07303E"/>
<path d="M26.4053 28.7622L20.9873 36.2566H23.1861L28.6146 28.7622H26.4053Z" fill="#07303E"/>
<path d="M33.7272 33.5755C33.671 33.7444 33.5957 33.8869 33.501 34.003C33.4064 34.1191 33.3012 34.2141 33.1854 34.288C33.0697 34.3619 32.9487 34.4147 32.8224 34.4464C32.6962 34.478 32.5733 34.4939 32.4542 34.4939C32.2157 34.4939 32.0035 34.4394 31.8177 34.3302C31.6318 34.2213 31.4739 34.0752 31.3444 33.8922C31.2145 33.7092 31.1163 33.4982 31.0498 33.2589C30.983 33.0197 30.9498 32.7699 30.9498 32.5094C30.9498 32.2772 30.9777 32.0433 31.034 31.8075C31.0903 31.5718 31.1797 31.3607 31.3025 31.1741C31.4251 30.9877 31.5795 30.8364 31.7654 30.7202C31.9511 30.6041 32.1739 30.5461 32.4335 30.5461C32.5455 30.5461 32.6684 30.5602 32.8016 30.5883C32.9349 30.6165 33.061 30.6658 33.1804 30.7361C33.2995 30.8065 33.41 30.9015 33.5117 31.0211C33.6133 31.1408 33.6959 31.2921 33.759 31.475L35.3265 30.3561C35.088 29.8636 34.7198 29.4677 34.2218 29.1686C33.7237 28.8696 33.1417 28.72 32.4754 28.72C31.9074 28.72 31.4005 28.8291 30.9552 29.0472C30.5097 29.2654 30.131 29.5504 29.819 29.9022C29.5068 30.2541 29.2701 30.6516 29.1088 31.095C28.9474 31.5383 28.8668 31.9888 28.8668 32.4461C28.8668 32.9247 28.9545 33.3961 29.1298 33.8605C29.3051 34.325 29.5488 34.7402 29.8611 35.1061C30.1731 35.4721 30.5465 35.7658 30.9814 35.9875C31.4162 36.2091 31.8931 36.32 32.4122 36.32C32.7068 36.32 33.0083 36.2812 33.3169 36.2039C33.6255 36.1265 33.9166 36.0139 34.1901 35.8661C34.4637 35.7183 34.7072 35.5354 34.9213 35.3172C35.1351 35.0991 35.2948 34.8527 35.4 34.5783L33.7272 33.5755Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon50;
