import React from "react";

const FeatureIcon90 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 51 37" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon90Clip0)">
<path d="M11.9685 10.6538L-0.291138 21.2788L0.718478 22.3846L1.77617 23.4904L3.26655 22.2404L4.75694 20.9904L4.90117 29.0192L4.99732 37H6.43963H7.88194V27.625V18.2981L16.1031 11.1827C20.6223 7.28846 24.4685 4.01923 24.6127 3.97115C24.805 3.875 28.6512 7.04807 33.1223 10.9423L41.2954 18.0577L41.4396 25.8462L41.5358 33.6346H33.8435H26.1512V30.5577V27.4327L28.0262 27.2885L29.8531 27.1442L27.4012 22.7212C26.055 20.2692 24.7569 18.3462 24.5646 18.3942C24.3242 18.4423 23.0742 20.5096 21.7762 22.9615L19.3723 27.3846H21.2954H23.2666V32.1923V37H33.8435H44.4204V29.0673C44.4204 24.6923 44.5646 21.1346 44.7569 21.1346C44.9492 21.1346 45.6704 21.6635 46.3435 22.3365L47.5935 23.5385L48.6992 22.4327L49.7569 21.2788L37.4492 10.6058C30.7185 4.78846 24.9973 -0.0192337 24.7569 -0.0192337C24.4685 -0.0192337 18.7473 4.78846 11.9685 10.6538Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon90Clip0">
<rect width="50" height="37" fill="white" transform="translate(0.19873)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon90;
