import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import CheckIcon from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogContentText } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ImageUploader from "./ImageUploader";
import InfoButton from "./PdfPreviewer";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Page } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import PreviewImages from "@material-ui/icons/ImageSearch";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import UploadIcon from "@material-ui/icons/Publish";
import VisualizationPinPointIcon from "@material-ui/icons/LocationOn";
import clsx from "clsx";
import imageTimeStamp from "../helpers/imageTimeStamp";
import makeStyles from "@material-ui/core/styles/makeStyles";
import uploadImage from "../helpers/aws";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { dataURLtoFile } from "../helpers/aws";
import { removeSelectedVideoImages } from "../store/actions/forms";

const useStyles = makeStyles((theme) => ({
  expansionPanelContent: {
    margin: "8px 0",
  },
  expansionSelectField: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  visualizationBadge: {
    padding: 0,
  },
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  labelPosition: {
    transform: "translate(8px,20px) scale(1)",
  },
  mobListBox: {
    maxHeight: "calc(100vh - 150px)",
    minHeight: "calc(100vh - 150px)",
    padding: "0",
  },
  mobPaper: {
    margin: "5px 0",
    boxShadow: "unset",
  },
  mobPopper: {
    transform: "translate3d(16px, 120px, 0px) !important", /// wrong depends on scroll
    // transform: "translate3d(16px, 290px, 0px) !important",
  },
  mobOption: {
    padding: "8.5px 0 13.85px",
    justifyContent: "space-between",
    borderBottom: "1px solid #bbb",
  },
  anything: { whiteSpace: "unset" },
  chip: { margin: "2px" },
  optionTitle: {
    color: "black",
    marginBottom: "2px",
  },
  optionClass: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  SecoundaryText: {
    fontSize: "13px",
    color: "#4e4e4e",
    width: "100%",
    marginBottom: "2.6px",
  },
  descriptionTypography: {
    width: "100%",
    fontSize: "14px",
    color: "#939393",
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AutocompleteComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    options,
    handleChange,
    formTitle,
    handleClickOpen,
    handleInvokedField,
    fromMobile,
    answer,
    i,
    handleOptitonError,
    setShowInfo,
    SetInfoOption,
  } = props;

  const conditionalProps = fromMobile
    ? { open: { fromMobile }, PopperComponent: "Popper" }
    : {};
  return (
    <Autocomplete
      id="combo-box-demo"
      {...conditionalProps}
      // options={options.filter((option) => option.title !== "Excellent")}
      options={options.filter((option) => option.default === false)}
      value={answer.title}
      getOptionLabel={(option) => option.title} //// ????
      // getOptionLabel={(option) =>
      //   option.default === false ? option.title : null
      // }
      style={{ width: "100%" }}
      onChange={(event, value) => {
        if (event.target.nodeName != "path" && event.target.nodeName != "svg")
          handleChange(value, i);
      }}
      filterOptions={filterOptions}
      renderOption={(option) => (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography className={classes.optionTitle}>
              {option.title}{" "}
            </Typography>
            <InfoButton
              option={option}
              SetInfoOption={SetInfoOption}
              setShowInfo={setShowInfo}
              optionClass={classes.optionClass}
            />
            {option?.option_description?.severity && (
              <Typography className={classes.SecoundaryText}>
                {option.option_description.severity}
              </Typography>
            )}
            {option.option_description && (
              <Typography
                color="textSecondary"
                className={classes.descriptionTypography}
              >
                <span>{option.option_description.description}</span>
              </Typography>
            )}
          </div>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("Options")}
          variant={fromMobile ? "standard" : "outlined"}
          onClick={handleClickOpen}
          error={handleOptitonError(i)}
        />
      )}
      classes={{
        listbox: clsx({ [classes.mobListBox]: fromMobile }),
        root: clsx({
          [classes.switch_track]: handleInvokedField(),
          [classes.switch_base]: !handleInvokedField(),
        }),
        paper: clsx({ [classes.mobPaper]: fromMobile }),
        popper: clsx({ [classes.mobPopper]: fromMobile }),
        option: clsx({ [classes.mobOption]: fromMobile }),
      }}
    />
  );
};

const DropdownMultipleDialogAutoComplete = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    formTitle,
    options,
    value,
    onChange,
    disabled,
    fieldId,
    answerObject,
    points,
    side,
    setOpenVisualizationDialog,
    setSide,
    setPoints,
    setSelectedOptionAnswerId,
    setSelectedOptionDdma,
    visualizationPointError,
    field,
    toggleNotification,
    defaultValue,
    invokedFields,
    isdone,
    triggerUpdatedFields,
    imageUrl,
    messageAnswerPointLink,
    formId,
    videoImages,
    removeSelectedVideoImages,
  } = props;
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [open, setOpen] = useState(false); // Autocomplete component has a bug initial state null solves it
  const [selectedOption, setSelectedOption] = useState({});
  const [answers, setAnswers] = useState(
    answerObject.map((el) => {
      el["expanded"] = false;
      return el;
    })
  );
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [infoOption, SetInfoOption] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const carId = props.match.params.id;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (
      answers[answers.length - 1]?.option?.title == undefined ||
      answers[answers.length - 1]?.option?.default == true ||
      (answers[answers.length - 1]?.images?.length == undefined &&
        answers[answers.length - 1]?.option?.with_image == true)
    )
      setDisableAddButton(true);
    else setDisableAddButton(false);
  }, [answers, openDialog]);

  useEffect(() => {
    if (openDialog && fullScreen) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen().catch((e) => console.log(e));
      } else if (document.body.mozRequestFullscreen) {
        document.body.mozRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen().catch((e) => console.log(e));
      } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen().catch((e) => console.log(e));
      }
    }
  }, [openDialog, toggleState]);

  useEffect(() => {
    if (
      !answers[0]?.option?.default && // has an answer should have point
      points?.[side] === undefined && // no points for this side
      !invokedFields?.includes(field.id) // to prevent infinit loop
    ) {
      triggerUpdatedFields({ invokedFields: [field.id] }); // to get highlight as invoked
    }
  }, [value]);

  useEffect(() => {
    // this was done because answerObject prop gets its right data after the initial value of answers useState was set
    // refere to https://stackoverflow.com/questions/54625831/how-to-sync-props-to-state-using-react-hooks-setstate
    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
    // related search keywords componentWillReceiveProps/getDerivedStateFromProps functionality
    setAnswers(
      answerObject.map((el) => {
        el["expanded"] = false;
        return el;
      })
    );
  }, [answerObject]);

  const handleOptitonError = (i) => {
    return visualizationPointError.includes(
      answerObject[i] !== undefined
        ? answerObject[i].unique_answer_id?.toString() ||
            answerObject[i].answerPointLink
        : null
    );
  };

  const handleFieldError = () => {
    let x = Object.keys(answerObject).map((i) => {
      return visualizationPointError.includes(
        answerObject[i] !== undefined
          ? answerObject[i].unique_answer_id?.toString() ||
              answerObject[i].answerPointLink
          : null
      );
    });
    return x.includes(true);
  };

  const handleSetDefault = (event, newKeys) => {
    if (newKeys.length === 0) {
      let option = options.find((el) => el.default);
      setAnswers([
        {
          id: defaultValue,
          expanded: false,
          option: option,
          field: field,
        },
      ]);
      onChange(event, [
        {
          id: defaultValue,
          expanded: false,
          option: option,
          field: field,
        },
      ]);
    }
  };
  const handleFileChange = async (event, answer, j) => {
    // j answer position in array
    event.persist();
    let array = [];
    for (let i = 0; i < event.target.files.length; i++) {
      await uploadImage(
        event.target.files[i],
        `Car-${carId}-Image-${fieldId}-${answer.option.id}-${j}-${
          i + (answer.images ? answer.images.length : 0)
        }-${imageTimeStamp()}.png`,
        (data) => {
          array.push(data.Location);

          // if (i === event.target.files.length - 1) {
          if (array.length === event.target.files.length) {
            answer.images = answer.images
              ? answer.images.concat(array)
              : [...array];
            setAnswers([
              ...answers,
              // commented because after deletion of an answer where an empty option exists causes an error of value is undefined in selected.map because get current values in FormFields returns undefined
              // { id: null, expanded: false, option: null, images: [] },
            ]);
            onChange(event, [...answers]);
          }
        }
      );
    }
  };
  const handleGetSelectedImages = async (event, answer, j) => {
    // j answer position in array
    // event.persist();
    let array = [];
    for (let i = 0; i < videoImages.length; i++) {
      await uploadImage(
        dataURLtoFile(videoImages[i].image),
        `Car-${carId}-Image-${fieldId}-${answer.option.id}-${j}-${
          i + (answer.images ? answer.images.length : 0)
        }-${imageTimeStamp()}.png`,
        (data) => {
          array.push(data.Location);

          // if (i === event.target.files.length - 1) {
          if (array.length === videoImages.length) {
            answer.images = answer.images
              ? answer.images.concat(array)
              : [...array];
            setAnswers([
              ...answers,
              // commented because after deletion of an answer where an empty option exists causes an error of value is undefined in selected.map because get current values in FormFields returns undefined
              // { id: null, expanded: false, option: null, images: [] },
            ]);
            // onChange(event, [...answers]);
            onChange({ target: { checked: true } }, [...answers]);
          }
        }
      );
      removeSelectedVideoImages();
    }
  };
  useEffect(() => {
    if (imageUrl) {
      let splitedAnswerPointLink = messageAnswerPointLink.split("-");
      let dataFieldId = splitedAnswerPointLink[0];
      // let dataOptionId = splitedAnswerPointLink[1];
      let dataPositionInString = splitedAnswerPointLink[2];
      const answer = answers.filter((answer) => {
        return answer?.option?.field == dataFieldId;
      })[dataPositionInString];
      let array = [];
      array.push(imageUrl);
      if (answer) {
        answer.images = answer.images
          ? answer.images.concat(array)
          : [...array];
        setAnswers([...answers]);
        onChange({ target: { checked: true } }, [...answers]);
      }
    }
  }, [imageUrl]);

  const handleUpload = (answer, j) => {
    console.log(window.parent);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        `Multi-${carId}-${fieldId}-${answer.option.id}-${j}-${
          answer.images ? answer.images.length : 0
        }`
      );
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedOption("");
  };

  const handleChange = (value, i) => {
    let option = options.find((el) => el.id === value.id);
    // let tempAnswers = [...answers];
    let newAnswer = Object.assign({}, answers[i], {
      id: value.id.toString(),
      option: option,
      expanded: false,
      answerPointLink: `${fieldId}-${value.id}-${i}`,
      side: field.side, // for ddma with no visualization
    });
    answers[i] = newAnswer;
    setAnswers([...answers]);
    setSelectedOption(value.id);
    let withImage = option?.with_image;
    // the followin is if we wanted to remove options with no image from redux which is a bug
    // where adding an option with no image doesnot add it to redux but it adds it to answers
    // so if i add another answer with image the previous answer is added to redux
    // console.log("iiiiiiiiiii", tempAnswers);
    // let x = tempAnswers.filter((answer) => {
    //   return answer.option.with_image ? answer?.images?.length > 0 : true;
    // });
    // console.log("iiiiiiiiiii2222", x);

    if (withImage == false) {
      onChange({ target: {} }, [...answers]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const renderDialog = () => {
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
      >
        <DialogTitle style={{ paddingBottom: 8 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6">{formTitle} Options</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 8 }}>
          <div style={{ width: "100%" }}>
            <div style={{ marginBottom: 8 }}>
              {answers.map((answer, i) => {
                return (
                  <ExpansionPanel
                    key={`expansion_panel_answer_${i}`}
                    expanded={answer.expanded}
                  >
                    <ExpansionPanelSummary
                      style={{ padding: "0" }}
                      classes={{ content: classes.expansionPanelContent }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className={classes.expansionSelectField}>
                          <IconButton
                            onClick={(event) => {
                              //saving the deleted item for points deletion
                              // if (answer.id) {
                              let deletedItem = `${fieldId}-${answer.id}-${i}`; //
                              let deletedItemUnique = answer.unique_answer_id; // this would work for first point but not the next

                              // old delete option functionality
                              let newAnswers = answers.filter((el, index) => {
                                return i !== index;
                              });
                              // updating link in answers
                              let updatedAnswers = []; //
                              let oldKeys = [];
                              let newKeys = [];
                              newAnswers.map((answer, ind) => {
                                oldKeys.push(
                                  // answer.answerPointLink ||
                                  // answer.unique_answer_id
                                  answer.unique_answer_id ||
                                    answer.answerPointLink /// new
                                ); //

                                newKeys.push(
                                  answer?.unique_answer_id ?? /// new
                                    `${fieldId}-${answer.id}-${ind}`
                                ); //
                                //
                                updatedAnswers.push(
                                  Object.assign({}, answer, {
                                    answerPointLink: `${fieldId}-${answer.id}-${ind}`,
                                  })
                                ); //
                              }); //

                              setAnswers(updatedAnswers);
                              onChange(event, [...updatedAnswers]);
                              // you must make sure that answer is not saved to redux untill a point is given or it will probably crash
                              //updating link in points
                              let newPoints = {};
                              if (points[side]) {
                                // newPoints = { ...points[side].points };
                                newPoints = Object.assign(
                                  {},
                                  points[side].points
                                );

                                // if (deletedItemUnique) {
                                delete newPoints[deletedItemUnique]; // for first saved delete
                                // }
                                // if (!deletedItem.includes("undefined")) {
                                delete newPoints[deletedItem]; // for the changed answerPointLink
                                // } // }

                                const updatedNewPoints = Object.assign(
                                  {},
                                  newPoints
                                );

                                for (let j = 0; j < oldKeys.length; j++) {
                                  const oldPointObject =
                                    updatedNewPoints[oldKeys[j]];
                                  delete updatedNewPoints[oldKeys[j]];
                                  updatedNewPoints[newKeys[j]] = oldPointObject;
                                }
                                // three levels deep re-assigning of points in points[side].points
                                let secondLevelDeepObj = Object.assign(
                                  {},
                                  points[side],
                                  {
                                    points: updatedNewPoints,
                                  }
                                );
                                let firstLevelDeepObj = Object.assign(
                                  {},
                                  points,
                                  { [side]: secondLevelDeepObj }
                                );
                                let zeroLevelDeepObj = Object.assign(
                                  {},
                                  points,
                                  firstLevelDeepObj
                                );

                                setPoints({
                                  carId,
                                  formId,
                                  points: zeroLevelDeepObj,
                                });
                              }
                              handleSetDefault(event, newKeys);
                              // } else {
                              //   let tempAnswers = [...answers];
                              //   tempAnswers.splice(i, 1);
                              //   setAnswers(tempAnswers);
                              // }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {answer?.option?.default ||
                          answer.option == undefined ? (
                            <>
                              <Hidden smDown>
                                <AutocompleteComponent
                                  SetInfoOption={SetInfoOption}
                                  setShowInfo={setShowInfo}
                                  options={options}
                                  handleChange={handleChange}
                                  formTitle={formTitle}
                                  handleClickOpen={handleClickOpen}
                                  handleInvokedField={handleInvokedField}
                                  fromMobile={false}
                                  answer={answer}
                                  i={i}
                                  handleOptitonError={handleOptitonError}
                                />
                              </Hidden>
                              <Hidden mdUp>
                                <FormControl
                                  fullWidth
                                  classes={
                                    handleInvokedField() && isdone
                                      ? { root: classes.switch_track }
                                      : { root: classes.switch_base }
                                  }
                                >
                                  <InputLabel
                                    id={formTitle}
                                    // shrink ans class were added to stop label shrik and position it correctly
                                    shrink={false}
                                    classes={{
                                      formControl: classes.labelPosition,
                                    }}
                                  >
                                    {t("Options")}
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    value={
                                      // answer.id ? answer.id.toString() : "" // to remove excellent from dialog's select, it removes all answers but answered options are a different component now
                                      ""
                                    }
                                    onClick={
                                      options.length !== 0
                                        ? handleClickOpen
                                        : null
                                    }
                                    inputProps={{ readOnly: true }}
                                    disabled={
                                      options.length === 0 ? true : disabled
                                    }
                                  >
                                    {options.map((option, i) => (
                                      <MenuItem
                                        key={`select_menu_item_${option.id}_${i}`}
                                        value={option.id}
                                      >
                                        {console.log("kkkkkkkkkkkk", option)}
                                        {option.title}{" "}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Dialog
                                  fullScreen
                                  open={open}
                                  onClose={handleClickClose}
                                  TransitionComponent={Transition}
                                  style={{ paddingTop: "56px" }}
                                >
                                  <Paper
                                    style={{ padding: "16px", height: "100%" }}
                                  >
                                    {" "}
                                    <AppBar className={classes.appBar}>
                                      <Toolbar>
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          onClick={handleClickClose}
                                          aria-label="close"
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                        <Typography
                                          variant="h6"
                                          className={classes.title}
                                        >
                                          {t(formTitle)} {t("Options")}
                                        </Typography>
                                      </Toolbar>
                                    </AppBar>
                                    <AutocompleteComponent
                                      SetInfoOption={SetInfoOption}
                                      setShowInfo={setShowInfo}
                                      options={options}
                                      handleChange={handleChange}
                                      formTitle={formTitle}
                                      handleClickOpen={handleClickOpen}
                                      handleInvokedField={handleInvokedField}
                                      fromMobile={true}
                                      answer={answer}
                                      i={i}
                                      handleOptitonError={handleOptitonError}
                                    />
                                  </Paper>
                                </Dialog>
                              </Hidden>
                            </>
                          ) : (
                            <div style={{ margin: "auto 0" }}>
                              {answer?.option?.title}
                            </div>
                          )}
                        </div>
                        {answer?.option?.with_image !== false && (
                          <div style={{ display: "flex" }}>
                            {videoImages.length > 0 ? (
                              <>
                                <IconButton
                                  disabled={!answer.id || answer.option.default}
                                  onClick={(event) => {
                                    if (videoImages.length) {
                                      handleGetSelectedImages(event, answer, i);
                                    }
                                  }}
                                >
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      fontSize: "0.5rem",
                                    }}
                                    htmlFor={"images-upload-" + i}
                                  >
                                    <Badge
                                      badgeContent={
                                        answer.images
                                          ? answer.images.length
                                          : null
                                      }
                                      color="primary"
                                    >
                                      <UploadIcon />
                                    </Badge>
                                  </label>
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  disabled={!answer.id || answer.option.default}
                                >
                                  <input
                                    id={"images-upload-" + i}
                                    className={"file-input"}
                                    // disabled={videoImages.length}
                                    type={"file"}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      handleFileChange(event, answer, i);
                                      setToggleState(!toggleState); // add this state to call opendialog fullscreen useEffect.
                                    }}
                                    multiple
                                    accept={"image/*"}
                                  />
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      fontSize: "0.5rem",
                                    }}
                                    htmlFor={"images-upload-" + i}
                                  >
                                    <Badge
                                      badgeContent={
                                        answer.images
                                          ? answer.images.length
                                          : null
                                      }
                                      color="primary"
                                    >
                                      <UploadIcon />
                                    </Badge>
                                  </label>
                                </IconButton>
                                <IconButton
                                  disabled={
                                    !answer.images || answer.images.length === 0
                                  }
                                  onClick={() => {
                                    answers.splice(
                                      i,
                                      1,
                                      Object.assign({}, answer, {
                                        expanded: !answer.expanded,
                                      })
                                    );
                                    setAnswers([...answers]);
                                  }}
                                >
                                  <PreviewImages />
                                </IconButton>
                              </>
                            )}
                            {field.side && (
                              <IconButton
                                disabled={!answer.id || answer.option.default}
                                value={fieldId}
                                onClick={(event) => {
                                  setSide(side);
                                  setOpenVisualizationDialog(
                                    points[side]?.image !== undefined
                                      ? true
                                      : false
                                  );
                                  setSelectedOptionDdma(answer.option);
                                  setSelectedOptionAnswerId(
                                    answer.unique_answer_id ||
                                      `${fieldId}-${answer.id}-${i}`
                                  );
                                  toggleNotification({
                                    variant: "error",
                                    open:
                                      points[side]?.image === undefined
                                        ? true
                                        : false,
                                    message:
                                      "Please upload the respective side image in the Vehicle Images form",
                                  });
                                }}
                              >
                                <Badge
                                  classes={{
                                    badge: classes.visualizationBadge,
                                  }}
                                  badgeContent={
                                    points[side] !== undefined ? (
                                      points[side].points[
                                        `${fieldId}-${answer.id}-${i}`
                                      ] ||
                                      points[side].points[
                                        answer.unique_answer_id
                                      ] ? (
                                        <CheckIcon
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                      ) : null
                                    ) : null
                                  }
                                  color="primary"
                                >
                                  <VisualizationPinPointIcon />
                                </Badge>
                              </IconButton>
                            )}
                          </div>
                        )}
                      </div>
                    </ExpansionPanelSummary>
                    {answer.id && (
                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <ImageUploader
                            carId={carId}
                            fieldId={fieldId}
                            selectedOption={selectedOption.id}
                            images={answer.images}
                            onDeleteImage={(event, index) => {
                              answers[i].images.splice(index, 1);
                              setAnswers([...answers]);
                            }}
                          />
                        </div>
                      </ExpansionPanelDetails>
                    )}
                  </ExpansionPanel>
                );
              })}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Fab
                disabled={disableAddButton}
                color="primary"
                size="small"
                onClick={() => {
                  setAnswers([...answers, { expanded: false }]);
                }}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        open={showInfo}
        onClose={() => {
          setShowInfo(false);
        }}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowInfo(false);
                setOpen(true);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            <Document
              file={
                localStorage.getItem("language") === "ar"
                  ? infoOption?.option_info.ar
                  : infoOption?.option_info.en
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={window.innerWidth}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {openDialog ? renderDialog() : null}
      <FormControl variant="outlined" fullWidth error={handleFieldError()}>
        <InputLabel>{formTitle}</InputLabel>
        <Select
          classes={{ selectMenu: classes.anything }}
          open={false}
          fullWidth
          onOpen={() => {
            setOpenDialog(true);
          }}
          labelId={formTitle}
          id="input-with-icon-adornment"
          multiple
          value={value}
          disabled={disabled}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => {
            return (
              <div className={classes.chips}>
                {selected.map((value, i) => {
                  const current = options.find(
                    (el) => el.id.toString() === value?.toString()
                  );
                  if (current) {
                    let title = current.title;
                    return (
                      <Chip
                        key={`field_chip_${value}_${props.fieldId}_${i}`}
                        label={title}
                        className={classes.chip}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          }}
        />
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => ({
  videoImages: state.forms.videoImages.filter((el) => el.selected === true),
});
const mapDispatchToProps = (dispatch) => ({
  removeSelectedVideoImages(payload) {
    dispatch(removeSelectedVideoImages(payload));
  },
});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DropdownMultipleDialogAutoComplete)
);
