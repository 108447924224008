import React from "react";

const Save = (props) => {
  const { style, className, size, fill } = props;
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9983 16.0007C22.9983 15.4485 22.5507 15.0007 21.9983 15.0007H5.99854C5.44631 15.0007 4.99854 15.4484 4.99854 16.0007V28.0006H22.9983V16.0007ZM17.9984 25.0007H9.99847C9.44624 25.0007 8.99846 24.553 8.99846 24.0007C8.99846 23.4485 9.44613 23.0007 9.99847 23.0007H17.9984C18.5506 23.0007 18.9984 23.4484 18.9984 24.0007C18.9984 24.5529 18.5508 25.0007 17.9984 25.0007V25.0007ZM17.9984 20.0007H9.99847C9.44624 20.0007 8.99846 19.553 8.99846 19.0007C8.99846 18.4484 9.44613 18.0006 9.99847 18.0006H17.9984C18.5506 18.0006 18.9984 18.4483 18.9984 19.0007C18.9984 19.553 18.5508 20.0007 17.9984 20.0007V20.0007Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 26.0022V6.83034C27.999 6.56533 27.8937 6.31136 27.707 6.12335L23.0001 1.41643V7.00239C23.0001 8.65919 21.657 10.0024 20 10.0024H8.00006C6.34326 10.0024 5.00004 8.65929 5.00004 7.00239V0.00244141H2.00002C0.895447 0.00244141 0 0.897889 0 2.00246V26.0023C0 27.1069 0.895447 28.0023 2.00002 28.0023H3.00003V16.0022C3.00003 14.3454 4.34314 13.0022 6.00005 13.0022H22C23.6568 13.0022 25 14.3453 25 16.0022V28.0021H26C27.1046 28.0021 28 27.1068 28 26.0022V26.0022Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00098 0V6.99995C7.00098 7.55218 7.44865 7.99995 8.00099 7.99995H20.0009C20.5531 7.99995 21.0009 7.55229 21.0009 6.99995V0H19.0009V3.99992C19.0009 5.10449 18.1054 5.99994 17.0008 5.99994H15.0008C13.8963 5.99994 13.0008 5.10449 13.0008 3.99992V0H7.00098Z"
        fill={fill}
      />
    </svg>
  );
};

export default Save;
