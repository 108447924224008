import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_REQUEST_SUCCEED,
  FETCH_FORMS_REQUEST_FAILURE,
  FETCH_SUBFORMS_REQUEST,
  FETCH_SUBFORMS_REQUEST_SUCCEED,
  FETCH_SUBFORMS_REQUEST_FAILURE,
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_REQUEST_SUCCEED,
  FETCH_FIELDS_REQUEST_FAILURE,
  FETCH_FIELDS_OPTIONS_REQUEST,
  FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
  FETCH_FIELDS_OPTIONS_REQUEST_FAILURE,
  FETCH_GENERIC_FEATURES_REQUEST,
  FETCH_GENERIC_FEATURES_REQUEST_SUCCEED,
  FETCH_GENERIC_FEATURES_REQUEST_FAILURE,
  FETCH_MODEL_OPTIONS_REQUEST,
  FETCH_MODEL_OPTIONS_REQUEST_SUCCEED,
  FETCH_MODEL_OPTIONS_REQUEST_FAILURE,
  CHECK_VIS_FORMS_POINTS,
  FETCH_FIELDS_COUNT_REQUEST,
  FETCH_FIELDS_COUNT_REQUEST_SUCCEED,
  FETCH_FIELDS_COUNT_REQUEST_FAILURE,
  FILTER_FORMS,
  FILTER_FORMS_OK,
  GET_MAP_SUCCEED,
  GET_MAP,
  GET_MAP_FAILURE,
  UPDATE_CAR_POINTS,
  UPDATE_CAR_POINTS_SUCCEED,
  UPDATE_CAR_POINTS_FAILURE,
  CALCULATE_EAGLE_EYE,
  CALCULATE_EAGLE_EYE_SUCCEED,
  CALCULATE_EAGLE_EYE_FAILURE,
  REMOVE_UPDATED_FORM,
  TOGGLE_NOTIFICATION_BAR,
  TOTAL_TIME,
  TOTAL_TIME_SUCCEED,
  TOTAL_TIME_FAILURE,
  DTC_FAULTS,
  DTC_FAULTS_SUCCEED,
  DTC_FAULTS_FAILURE,
  FETCH_PAPARAZZI_PACKAGE_REQUEST,
  FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED,
  FETCH_PAPARAZZI_PACKAGE_REQUEST_FAILURE,
  FETCH_PAPARAZZI_CAR_IMAGES_REQUEST,
  FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST,
  FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_FAILURE,
  FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_SUCCEED,
  FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_SUCCEED,
  FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST,
  FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_FAILURE,
  FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST,
  FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED,
} from "../constants";

import API_URLS from "../../api";

export function* fetchForms(payload) {
  try {
    let query = `?car=${payload.payload.carId}`;
    let data = {
      url: API_URLS(query).CAR_FORM.DETAILS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_FORMS_REQUEST_SUCCEED, payload: response.results });
    payload.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_FORMS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* fetchFormsWatcher() {
  yield takeLatest(FETCH_FORMS_REQUEST, fetchForms);
}

export function* fetchGenericFeatures(action) {
  try {
    let data = {
      url: API_URLS().CAR_FORM.GENERIC_FEATURES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_GENERIC_FEATURES_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({
      type: FETCH_GENERIC_FEATURES_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* fetchGenericFeaturesWatcher() {
  yield takeLatest(FETCH_GENERIC_FEATURES_REQUEST, fetchGenericFeatures);
}

export function* fetchSubforms(action) {
  try {
    let data = {
      url: API_URLS(action.payload.formIndex).CAR_FORM.SUBFORMS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_SUBFORMS_REQUEST_SUCCEED,
      payload: response.results,
    });
  } catch (e) {
    yield put({ type: FETCH_SUBFORMS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* fetchSubformsWatcher() {
  yield takeLatest(FETCH_SUBFORMS_REQUEST, fetchSubforms);
}

export function* fetchFields(action) {
  try {
    let data = {
      url: API_URLS(
        `${action.payload.formId}&car_id=${action.payload.car_id}&for_dispatcher=true`
      ).CAR_FORM.FORM_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_FIELDS_REQUEST_SUCCEED,
      payload: response.results,
    });
    action.payload.callback(response.results);
  } catch (e) {
    yield put({ type: FETCH_FIELDS_REQUEST_FAILURE, error: new Error(e) });
  }
}

export function* fetchFieldsWatcher() {
  yield takeLatest(FETCH_FIELDS_REQUEST, fetchFields);
}

export function* fetchModelOptions(action) {
  try {
    let data = {
      url: API_URLS(action.payload.make).CAR_FORM.MODEL_OPTIONS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_MODEL_OPTIONS_REQUEST_SUCCEED,
      payload: {
        carId: action.payload.carId,
        formId: action.payload.formId,
        response: response.results,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_MODEL_OPTIONS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* fetchModelOptionsWatcher() {
  yield takeLatest(FETCH_MODEL_OPTIONS_REQUEST, fetchModelOptions);
}

export function* fetchFieldsOptions(action) {
  try {
    let data = {
      url: API_URLS(
        `${action.payload.formId}&invokers__id=${action.payload.invoker_id}`
      ).CAR_FORM.FORM_FIELDS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED,
      payload: {
        carId: action.payload.carId,
        formId: action.payload.formId,
        response: response.results,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_FIELDS_OPTIONS_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* fetchFieldsOptionsWatcher() {
  yield takeLatest(FETCH_FIELDS_OPTIONS_REQUEST, fetchFieldsOptions);
}

export function* checkVisFormsPoints(action) {
  try {
    let data = {
      url: API_URLS(`${action.payload.carId}`).CAR.CHECK_VIS_FORMS_POINTS,
      method: "GET",
    };
    const response = yield requestAPI(data);
    action.payload.callback(response.results); // results is [formId,formId,...]
  } catch (e) {
    console.log(e);
  }
}

export function* checkVisFormsPointsWatcher() {
  yield takeLatest(CHECK_VIS_FORMS_POINTS, checkVisFormsPoints);
}

export function* fetchFieldsCount(payload) {
  try {
    let data = {
      url: API_URLS().CAR_FORM.FEILDS_COUNT,
      method: "POST",
      body: {
        car: payload.payload.carId,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: FETCH_FIELDS_COUNT_REQUEST_SUCCEED, payload: response });
    payload.payload.callback(response);
  } catch (e) {
    yield put({
      type: FETCH_FIELDS_COUNT_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* fetchFieldsCountWatcher() {
  yield takeLatest(FETCH_FIELDS_COUNT_REQUEST, fetchFieldsCount);
}

export function* filterForms(payload) {
  yield put({ type: FILTER_FORMS_OK, payload: payload });
}

export function* filterFormsWatcher() {
  yield takeLatest(FILTER_FORMS, filterForms);
}

export function* getMap(payload) {
  try {
    // const query = `?subform=${action.payload.form}&body_type_config_id=${action.payload.query}`;
    const query = `?body_type_config=${payload.payload.bodyTypeConfigId}`;
    let data = {
      url: API_URLS(query).EAGLE_EYE.MAP,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({ type: GET_MAP_SUCCEED, payload: response });
    payload.payload.callback(response);
  } catch (e) {
    yield put({ type: GET_MAP_FAILURE, error: new Error(e) });
  }
}

export function* getMapWatcher() {
  yield takeLatest(GET_MAP, getMap);
}

export function* updateCarPoints(payload) {
  // this is submit Eagle Eye subform
  try {
    let data = {
      url: API_URLS(payload.payload.carId).EAGLE_EYE.UPDATE_CAR_POINT,
      method: "POST",
      body: {
        points: payload.payload.points,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: UPDATE_CAR_POINTS_SUCCEED, payload: response });
    payload.payload.callback(response);
  } catch (e) {
    yield put({ type: UPDATE_CAR_POINTS_FAILURE, error: new Error(e) });
  }
}

export function* updateCarPointsWatcher() {
  yield takeEvery(UPDATE_CAR_POINTS, updateCarPoints); // for saved submit
}

//////////////////////////

export function* convertPDF(payload) {
  // this is submit Eagle Eye subform
  console.log("ffffffffffff", payload);
  try {
    let data = {
      url: API_URLS(payload.payload.carId).COVERT_PDF.PDF_BLUE,
      method: "POST",
      body: {
        carId: payload.payload.carId,
        pdf: payload.payload.pdf,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: DTC_FAULTS_SUCCEED, payload: response });
    console.log("eeeeeeeeeeeeee", response);
    payload.payload.callback(response);
  } catch (e) {
    yield put({ type: DTC_FAULTS_FAILURE, error: new Error(e) });
    console.log("aaaaaaaaa", e);
  }
}

export function* convertPDFWatcher() {
  yield takeLatest(DTC_FAULTS, convertPDF); // for saved submit
}

////

export function* calculateEagleEye(payload) {
  try {
    let data = {
      url: API_URLS(payload.payload.carId).EAGLE_EYE.CALCULATE,
      method: "POST",
      body: {
        form: payload.payload.form,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: CALCULATE_EAGLE_EYE_SUCCEED, payload: response });
    yield put({ type: REMOVE_UPDATED_FORM, payload: payload.payload });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
    payload.payload.callback(response);
  } catch (e) {
    yield put({ type: CALCULATE_EAGLE_EYE_FAILURE, error: new Error(e) });
  }
}

export function* calculateEagleEyeWatcher() {
  yield takeEvery(CALCULATE_EAGLE_EYE, calculateEagleEye); // for saved submit
}

export function* totalTime(payload) {
  try {
    let data = {
      url: API_URLS().CAR_FORM.TOTAL_TIME,
      method: "POST",
      body: {
        car_id: payload.payload.car_id,
        officer: payload.payload.officer,
        time_from: payload.payload.time_from,
        time_to: payload.payload.time_to,
        time_per_form: payload.payload.time_per_form,
        form: payload.payload.form,
        edited: payload.payload.edited,
      },
    };
    const response = yield requestAPI(data);
    yield put({ type: TOTAL_TIME_SUCCEED, payload: response });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        //to display a notification
        message: "Your changes have been saved",
        variant: "success",
        open: true,
      },
    });
    payload.payload.callback();
  } catch (e) {
    yield put({ type: TOTAL_TIME_FAILURE, error: new Error(e) });
  }
}

export function* totalTimeWatcher() {
  yield takeLatest(TOTAL_TIME, totalTime);
}

export function* getPaparazziPackage(payload) {
  try {
    const { location, sun_location, reflection, carId, interior_lights } =
      payload.payload;
    let data = {
      url: API_URLS(
        `?location=${location}&sun_location=${sun_location}&reflection=${reflection}&interior_lights=${interior_lights}&car_id=${carId}`
      ).PAPARAZZI.PACKAGE,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED,
      payload: response.results[0].images,
    });
    if (payload.payload?.callback) {
      payload.payload.callback(response.results[0].images);
    }
  } catch (e) {
    yield put({
      type: FETCH_PAPARAZZI_PACKAGE_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getPaparazziPackageWatcher() {
  yield takeLatest(FETCH_PAPARAZZI_PACKAGE_REQUEST, getPaparazziPackage);
}

export function* getPaparazziPackageList(payload) {
  try {
    let data = {
      url: API_URLS().PAPARAZZI.PACKAGE_LIST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    console.log("nnnnnnnnnnnnnnnnnnnnn", response);
    yield put({
      type: FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_SUCCEED,
      payload: response,
    });
    if (payload.payload?.callback) {
      payload.payload.callback(response);
    }
  } catch (e) {
    yield put({
      type: FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getPaparazziPackageListWatcher() {
  console.log("hhhhhhhhhhhhhhhhh");
  yield takeLatest(
    FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST,
    getPaparazziPackageList
  );
}

/////////////////////// for test paparazzi only ///////////////////////////
export function* getTestPaparazziPackage(payload) {
  try {
    const {
      location,
      sun_location,
      reflection,
      carId,
      interior_lights,
      package_id,
    } = payload.payload;
    let data = {
      url: API_URLS(
        `?location=${location}&sun_location=${sun_location}&reflection=${reflection}&interior_lights=${interior_lights}&car_id=${carId}&${
          package_id ? `&package_id=${package_id}` : null
        }`
      ).PAPARAZZI.PACKAGE,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_SUCCEED,
      payload: response.results[0].images,
    });
    if (payload.payload?.callback) {
      if (payload.payload.multidata) {
        payload.payload.callback(response.results[0]);
      } else {
        payload.payload.callback(response.results[0].images);
      }
    }
  } catch (e) {
    yield put({
      type: FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_FAILURE,
      error: new Error(e),
    });
  }
}

export function* getTestPaparazziPackageWatcher() {
  yield takeEvery(
    FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST,
    getTestPaparazziPackage
  );
}
///////////////////////////////////////////////////////////////////////////

export function* getPaparazziCarImages(payload) {
  try {
    const { carId } = payload.payload;
    let data = {
      url: API_URLS(`?car_id=${carId}`).PAPARAZZI.CARIMAGES,
      method: "GET",
    };
    const response = yield requestAPI(data);
    if (payload.payload.multidata) {
      payload.payload.callback(response.results[0]);
    } else {
      payload.payload.callback(response.results[0].images);
    }
    yield put({
      type: FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED,
      payload: response.results[0].images,
    });
  } catch (e) {
    console.log("Error", e);
  }
}

export function* getPaparazziCarImagesWatcher() {
  yield takeEvery(FETCH_PAPARAZZI_CAR_IMAGES_REQUEST, getPaparazziCarImages);
}

export function* getPaparazziShadows(payload) {
  try {
    const { sun_location, bodyType } = payload.payload;
    let data = {
      url: API_URLS(`?sun_location=${sun_location}&bodyType=${bodyType}`)
        .PAPARAZZI.GET_SHADOW,
      method: "GET",
    };
    const response = yield requestAPI(data);
    console.log("wwwwwwwwwwwwwwwwwwww", response);
    payload.payload.callback(response);
    // if (payload.payload.multidata) {
    // } else {
    //   payload.payload.callback(response.results[0].images);
    // }
  } catch (e) {
    console.log("Error", e);
  }
}

export function* getPaparazziShadowsWatcher() {
  yield takeEvery(
    FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST,
    getPaparazziShadows
  );
}
