// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import { UPDATE_LANGUAGE } from "../constants";

const initialState = {
  language: "en",
};

export default createReducer(initialState, {
  [UPDATE_LANGUAGE]: (state, payload) => {
    return Object.assign({}, state, {
      language: payload,
    });
  },
});
