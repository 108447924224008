import React, { useEffect, useState } from "react";
import { createCar } from "../../store/actions/car";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ListingRequestCard from "../../components/ListingRequestCard";
import {
  getListingRequests,
  ClaimListingRequests,
} from "../../store/actions/listings";
import {
  clearNewRequests,
  updatePageTimeStamp,
} from "../../store/actions/websockets";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ListingRequestCardSkeleton from "../../components/skeletons/ListingRequestCardSkeleton";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import cookies from "js-cookie";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import GPSOutlined from "../../components/icons/GPSOutlined";
import CarDealership from "../../components/icons/CarDealership";
import Location from "../../components/icons/Location";
import noIncoming from "../../images/noIncoming.svg";
import noClaimed from "../../images/noClaimed.svg";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: "45px 70px",
    backgroundColor: " #f9f9f9",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      height: "calc(100vh - 102px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 112px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 248px)",
    },
  },
  tableRightBorder: {
    backgroundImage:
      "linear-gradient(to bottom, #f3f3f3 25%, #CBC6C6 25%, #CBC6C6 50%, #CBC6C6 50%, #CBC6C6 75%, rgb(245 243 243) 75%)",
    backgroundSize: "1px 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
  },
  tab: {
    fontSize: "14px",
    fontWeight: "600",
    padding: "21px 12px",
    textTransform: "capitalize",
    lineHeight: "1.5",
    color: "#989898",
    [theme.breakpoints.down("sm")]: {
      padding: "0 12px 1px",
    },
  },
  mainCardsGrid: {
    [theme.breakpoints.down("md")]: {
      padding: "5px 5px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0",
    },
  },
  tableContainer: { borderRadius: "10px" },
  titlestablerow: { backgroundColor: "#F3F3F3" },
  verticalaligntop: { verticalAlign: "top", borderRight: "solid 1px #CBC6C6" },
  getDirectionTypo: {
    color: "#186985",
    whiteSpace: "nowrap",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  firstTablerow: {
    backgroundColor: "#F7F7F7",
    height: "64px",
    borderBottom: "solid 1px #CBC6C6",
  },
  tableRowDiv: { display: "flex", marginTop: "18.88px", marginLeft: "16px" },
  tableRowDivTwo: {
    justifyContent: "space-between",
    display: "flex",
    marginTop: "18.88px",
    marginLeft: "16px",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  AnonymousTypo: {
    marginLeft: "7px",
    fontWeight: "500",
    fontSize: "14px",
    color: "#07303E",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // width: '50px',
    textOverflow: "ellipsis",
  },
  dealershipnameTypo: {
    marginLeft: "7px",
    whiteSpace: "nowrap",
    width: "48vw",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "600",
    fontSize: "14px",
    color: "#07303E",
  },
  getDirectionTypoTwo: {
    color: "#186985",
    marginLeft: "auto",
    fontWeight: "500",
    fontSize: "14px",
    whiteSpace: "nowrap",
    marginRight: "16px",
    display: "flex",
    alignItems: "center",
  },
  cityTypo: {
    color: "#727373",
    marginLeft: "7px",
    marginRight: "16px",
    fontWeight: "500",
    fontSize: "14px",
  },
  cityTypoTwo: { color: "#727373", whiteSpace: "nowrap" },
  tabborder: {
    backgroundImage:
      "linear-gradient(to bottom, rgb(255, 255, 255) 25%, #CBC6C6 25%, #CBC6C6 50%, #CBC6C6 50%, #CBC6C6 75%, rgb(255, 255, 255) 75%)",
    backgroundSize: "1px 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
    textTransform: "none",
  },
}));

const IncomingRequsests = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    requests,
    ClaimListingRequests,
    clearNewRequests,
    updatePageTimeStamp,
    userId,
    fetchingRequests,
    requestsObject,
  } = props;

  useEffect(() => {
    clearNewRequests();
    updatePageTimeStamp({
      user_id: userId,
      page: "inc_req_stamp",
    });
  }, []);

  const Claim = (requestId) => {
    ClaimListingRequests({
      requestId: requestId,
      time: new Date().toISOString(),
      claimedBy: userId,
    });
    let queryset = "claimed_at__isnull=true";
  };
  return (
    <Grid container>
      {fetchingRequests ? (
        [1, 2, 3, 4, 5].map(() => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            className={classes.mainCardsGrid}
          >
            <ListingRequestCardSkeleton page="incoming" />
          </Grid>
        ))
      ) : requests?.length == 0 ? (
        <>
          <Hidden smDown>
            <Table
              style={{ borderCollapse: "separate" }}
              aria-label="simple table"
            >
              <TableRow className={classes.titlestablerow}>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Seller Name")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Location")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Listing Number")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Time Passed")}
                </TableCell>
                <TableCell align="left">{t("Action")}</TableCell>
              </TableRow>
            </Table>
          </Hidden>

          <div style={{ display: "grid", width: "inherit", marginTop: "50px" }}>
            <img style={{ margin: "auto auto 16px" }} src={noIncoming} />
            <Typography
              style={{
                color: "#07303E",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "5px",
              }}
              align="center"
            >
              {t("You Have No Incoming Requests!")}
            </Typography>
            <Typography
              style={{ color: "#707070", fontSize: "12px", fontWeight: "400" }}
              align="center"
            >
              {t("Stay tuned, dealers will send requests at anytime.")}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              style={{ borderCollapse: "separate" }}
              aria-label="simple table"
            >
              <TableHead>
                <Hidden smDown>
                  <TableRow className={classes.titlestablerow}>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Seller Name")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Location")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Listing Number")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Time Passed")}
                    </TableCell>
                    <TableCell align="left">{t("Action")}</TableCell>
                  </TableRow>
                </Hidden>
              </TableHead>
              <TableBody>
                {Object.values(requestsObject).map((dealership) => (
                  <>
                    {/* {console.log("jjjjjjjjjjjjj", dealership)} */}
                    {!dealership?.requests[0]?.dealership_info
                      ?.runner_account && (
                      <Hidden smDown>
                        <TableRow>
                          <TableCell
                            rowSpan={dealership.requests.length + 1}
                            align="left"
                            className={classes.verticalaligntop}
                          >
                            {dealership?.dealership_name}
                          </TableCell>
                          <TableCell
                            rowSpan={dealership.requests.length + 1}
                            align="left"
                            className={classes.verticalaligntop}
                          >
                            <Typography className={classes.cityTypoTwo}>
                              {t(dealership.city)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </Hidden>
                    )}
                    {!dealership?.requests[0]?.dealership_info
                      ?.runner_account && (
                      <Hidden mdUp>
                        <TableRow className={classes.firstTablerow}>
                          <div className={classes.tableRowDivTwo}>
                            <div
                              style={{
                                display: "flex",
                                flexBasis: "65%",
                                minWidth: "65%",
                                flexGrow: 0,
                              }}
                            >
                              <CarDealership size="20" fill="#07303E" />
                              <Tooltip
                                leaveDelay={2000}
                                disableFocusListener
                                title={dealership?.dealership_name}
                              >
                                <Typography className={classes.AnonymousTypo}>
                                  {dealership?.dealership_name}
                                </Typography>
                              </Tooltip>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexBasis: "30%",
                                minWidth: "30%",
                                flexGrow: 0,
                              }}
                            >
                              <Location size="20" fill="#727373" />
                              <Typography className={classes.cityTypo}>
                                {t(dealership.city)}
                              </Typography>
                            </div>
                          </div>
                        </TableRow>
                      </Hidden>
                    )}
                    {dealership.requests.map((request) => {
                      return (
                        <>
                          <ListingRequestCard
                            city={request?.dealership_info?.city}
                            area={request?.dealership_info?.area}
                            dealership_name={
                              request?.dealership_info?.dealership_name
                            }
                            dealership_phone={
                              request?.dealership_info.phone_number
                            }
                            createdAt={request?.created_at}
                            id={request?.id}
                            Claim={Claim}
                            page="incoming"
                            carTitle={`${request?.car_details?.make} ${request?.car_details?.model} ${request?.car_details?.year}`}
                            day={request?.car_details?.day}
                            time={request?.car_details?.time}
                            runnerAccount={
                              request?.dealership_info?.runner_account
                            }
                            car_details={request.car_details}
                          />
                        </>
                      );
                    })}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
};

const ClaimedRequsests = (props) => {
  const { requests, handleAddCar, fetchingRequests, requestsObject } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container>
      {fetchingRequests ? (
        [1, 2, 3, 4, 5].map(() => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            className={classes.mainCardsGrid}
          >
            <ListingRequestCardSkeleton page="claimed" />
          </Grid>
        ))
      ) : requests.length == 0 ? (
        <>
          <Hidden smDown>
            <Table
              style={{ borderCollapse: "separate" }}
              aria-label="simple table"
            >
              <TableRow className={classes.titlestablerow}>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Seller Name")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Location")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Phone Number")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Listing Number")}
                </TableCell>
                <TableCell className={classes.tableRightBorder} align="left">
                  {t("Time Passed")}
                </TableCell>
                <TableCell align="left">{t("Action")}</TableCell>
              </TableRow>
            </Table>
          </Hidden>

          <div style={{ display: "grid", width: "inherit", marginTop: "50px" }}>
            <img style={{ margin: "auto auto 16px" }} src={noClaimed} />
            <Typography
              style={{
                color: "#07303E",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "5px",
              }}
              align="center"
            >
              {t("You Have No Claimed Requests!")}
            </Typography>
            <Typography
              style={{ color: "#707070", fontSize: "12px", fontWeight: "400" }}
              align="center"
            >
              {t("When you claim a request, you’ll find it here.")}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table
              style={{ borderCollapse: "separate" }}
              aria-label="simple table"
            >
              <TableHead>
                <Hidden smDown>
                  <TableRow className={classes.titlestablerow}>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Seller Name")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Location ")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Phone Number")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Listing Number")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Time Passed")}
                    </TableCell>
                    <TableCell
                      className={classes.tableRightBorder}
                      align="left"
                    >
                      {t("Notes")}
                    </TableCell>
                    <TableCell align="left">{t("Action")}</TableCell>
                  </TableRow>
                </Hidden>
              </TableHead>
              <TableBody>
                {Object.values(requestsObject).map((dealership) => (
                  <>
                    {!dealership?.requests[0]?.dealership_info
                      ?.runner_account && (
                      <Hidden smDown>
                        <TableRow>
                          <TableCell
                            rowSpan={dealership.requests.length + 1}
                            align="left"
                            className={classes.verticalaligntop}
                          >
                            {dealership.dealership_name}
                          </TableCell>
                          <TableCell
                            rowSpan={dealership.requests.length + 1}
                            align="left"
                            className={classes.verticalaligntop}
                          >
                            {dealership?.gps_location?.includes("google") ||
                            dealership?.gps_location?.includes("goo.gl") ? (
                              <Typography
                                className={classes.getDirectionTypo}
                                style={
                                  localStorage.getItem("language") == "en"
                                    ? { flexDirection: "row" }
                                    : {
                                        flexDirection: "row-reverse",
                                        justifyContent: "flex-end",
                                      }
                                }
                                onClick={() => {
                                  window.location.href =
                                    dealership.gps_location;
                                }}
                              >
                                {t("GPS")}
                                <GPSOutlined
                                  size="13"
                                  fill="#186985"
                                  style={{ marginInline: "6px" }}
                                />
                              </Typography>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        </TableRow>
                      </Hidden>
                    )}
                    {!dealership.requests[0].dealership_info.runner_account && (
                      <Hidden mdUp>
                        <TableRow className={classes.firstTablerow}>
                          <div className={classes.tableRowDiv}>
                            <div style={{ display: "flex" }}>
                              <CarDealership size="20" fill="#07303E" />
                              <Typography
                                className={classes.dealershipnameTypo}
                              >
                                {dealership.dealership_name}
                              </Typography>
                            </div>
                            {dealership?.gps_location?.includes("google") ||
                            dealership?.gps_location?.includes("goo.gl") ? (
                              <Typography
                                className={classes.getDirectionTypoTwo}
                                style={
                                  localStorage.getItem("language") == "en"
                                    ? { flexDirection: "row" }
                                    : { flexDirection: "row-reverse" }
                                }
                                onClick={() => {
                                  window.location.href =
                                    dealership.gps_location;
                                }}
                              >
                                {t("GPS")}
                                <GPSOutlined
                                  size="13"
                                  fill="#186985"
                                  style={{ marginInline: "6px" }}
                                />
                              </Typography>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </TableRow>
                      </Hidden>
                    )}
                    {dealership.requests.map((request) => (
                      <ListingRequestCard
                        dealership_phone={request?.dealership_info.phone_number}
                        city={request?.dealership_info.city}
                        area={request?.dealership_info.area}
                        createdAt={request?.created_at}
                        id={request?.id}
                        gpsLocation={request?.dealership_info.gps_location}
                        page="claimed"
                        started_at={request?.car_info?.[0]?.fields?.started_at}
                        carId={request?.car_info?.[0]?.pk}
                        handleAddCar={handleAddCar}
                        carTitle={`${request?.car_details.make} ${request?.car_details.model} ${request?.car_details.year}`}
                        day={request?.car_details.day}
                        time={request?.car_details.time}
                        runnerAccount={request?.dealership_info.runner_account}
                        claimed={true}
                        car_details_claimed={request.car_details}
                      />
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.mainBox} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function EntryRequests(props) {
  const {
    history,
    user,
    createCar,
    getListingRequests,
    requests,
    fetchingRequests,
    incomingCount,
    claimedCount,
    state,
  } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [requestsObject, setRequestsObject] = useState({});
  useEffect(() => {
    // call the action and add ((claimed -value=1- / not claimed -value=0-) based on page) and user.id
    // to get the right result
    let queryset = value
      ? `claimed_by=${user.user_id}&car__finished_at__isnull=true`
      : `claimed_at__isnull=true&officer=${user.user_id}`;
    getListingRequests({ queryset }); // for incoming claimed = null , for claimed operations user id
  }, [value]);

  useEffect(() => {
    let reqObj = {};
    requests.map((req) => {
      reqObj[req.dealership] !== undefined
        ? reqObj[req.dealership].requests.push(req)
        : (reqObj[req.dealership] = {
            dealership_name: req.dealership_info.dealership_name,
            dealership_name_ar: req.dealership_info.dealership_name_ar,
            dealership_name_en: req.dealership_info.dealership_name_en,
            gps_location: req.dealership_info.gps_location,
            city: req.dealership_info.city,
            requests: [req],
          });
    });
    setRequestsObject(reqObj);
  }, [state.listings]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddCar = (id) => {
    createCar({
      body: {
        listing_request: id,
        started_at: new Date().toISOString(),
        is_active: true,
      },
      callback: (result) => {
        history.push("/car-form/" + result.id);
      },
    });
  };
  if (cookies.get("token")) {
    return (
      <div>
        <AppBar position="static" color="white">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {t("Incoming")}
                    {incomingCount > 0 && (
                      <div
                        style={{
                          backgroundColor: "#186985",
                          borderRadius: "12px",
                          color: "white",
                          height: "20px",
                          width: "26px",
                          marginLeft: "12px",
                        }}
                      >
                        <Typography align="center">{incomingCount}</Typography>
                      </div>
                    )}
                  </div>
                </>
              }
              className={classes.tab && classes.tabborder}
              {...a11yProps(0)}
            />
            <Tab
              label={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {t("Claimed")}
                    {claimedCount > 0 && (
                      <div
                        style={{
                          backgroundColor: "#186985",
                          borderRadius: "12px",
                          color: "white",
                          height: "20px",
                          width: "26px",
                          marginLeft: "12px",
                        }}
                      >
                        <Typography align="center">{claimedCount}</Typography>
                      </div>
                    )}
                  </div>
                </>
              }
              className={classes.tab}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>

        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          children={
            <IncomingRequsests
              requests={props.requests}
              ClaimListingRequests={props.ClaimListingRequests}
              getListingRequests={props.getListingRequests}
              clearNewRequests={props.clearNewRequests}
              updatePageTimeStamp={props.updatePageTimeStamp}
              userId={user.user_id}
              userRole={user.role}
              state={props.state}
              fetchingRequests={fetchingRequests}
              requestsObject={requestsObject}
            />
          }
        />
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          children={
            <ClaimedRequsests
              requests={requests}
              handleAddCar={handleAddCar}
              fetchingRequests={fetchingRequests}
              requestsObject={requestsObject}
            />
          }
        />
      </div>
    );
  } else {
    return <Redirect to={"/login"} />;
  }
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
  requests: state.listings.requests,
  fetchingRequests: state.listings.fetchingRequests,
  // here add redux state for unclaimed and claimed requests
  incomingCount: state.listings.incomingCount,
  claimedCount: state.listings.claimedCount,
});
const mapDispatchToProps = (dispatch) => ({
  createCar(payload) {
    dispatch(createCar(payload));
  },
  getListingRequests(payload) {
    dispatch(getListingRequests(payload));
  },
  ClaimListingRequests(payload) {
    dispatch(ClaimListingRequests(payload));
  },
  clearNewRequests() {
    dispatch(clearNewRequests());
  },
  updatePageTimeStamp(payload) {
    dispatch(updatePageTimeStamp(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EntryRequests)
);
