import { all } from "redux-saga/effects";
import {
  fetchTokenWatcher,
  fetchRefreshTokenWatcher,
  checkForExpiredTokenWatcher,
  confirmResetWatcher,
  activateAccountWatcher,
  validateOTPWatcher,
  sendOTPWatcher,
} from "./sagas-definitions/auth";
import {
  fetchFormsWatcher,
  fetchFieldsWatcher,
  fetchFieldsOptionsWatcher,
  // fetchFeaturesWatcher,
  fetchSubformsWatcher,
  fetchGenericFeaturesWatcher,
  // fetchFeaturesSubformsWatcher,
  fetchModelOptionsWatcher,
  checkVisFormsPointsWatcher,
  fetchFieldsCountWatcher,
  filterFormsWatcher,
  getMapWatcher,
  updateCarPointsWatcher,
  calculateEagleEyeWatcher,
  totalTimeWatcher,
  convertPDFWatcher,
  getPaparazziPackageWatcher,
  getPaparazziCarImagesWatcher,
  getTestPaparazziPackageWatcher,
  getPaparazziPackageListWatcher,
  getPaparazziShadowsWatcher,
} from "./sagas-definitions/forms";

import {
  fetchListingRequestsWatcher,
  claimListingRequestsWatcher,
} from "./sagas-definitions/listings";

import {
  updatePageTimeStampWatcher,
  getPageTimeStampsWatcher,
  getUnseenNotificationsWatcher,
} from "./sagas-definitions/websockets";

import {
  createCarWatcher,
  updateCarFormWatcher,
  getCarWatcher,
  getCarFieldsWatcher,
  getCarFormsWatcher,
  getCarFeaturesWatcher,
  getCarFaultsWatcher,
  getCarVisualizationWatcher,
  updateCarVisualsWatcher,
  getCarListWatcher,
  getCarDetailsWatcher,
  getCarGroupsWatcher,
  submitCarToQCWatcher,
  publishCarForBuyersWatcher,
  publishCarForSellerWatcher,
  getCarGenericFeaturesWatcher,
  updateDtcFormWatcher,
  updateMissedMappingWatcher,
  initiatingPaparazziWatcher,
  getDTCMappingWatcher,
  getCarInvokedWatcher,
  getCarDetailsImagesWatcher,
  changeBgImagesWatcher,
  getDownloadImagesWatcher,
  reportBuilderWatcher,
  getCarIdsTitlesListWatcher,
} from "./sagas-definitions/car";
import {
  getTimeSlotsRequestsWatcher,
  createTimeSlotWatcher,
  updateTimeSlotWatcher,
  getAreaGroupsWatcher,
} from "./sagas-definitions/timeSlots";

// import { saveFormAnswersWatcher } from "./sagas-definitions/webview";

import { requestConnectionWatcher } from "./sagas-definitions/websockets";
function* appSagas() {
  yield all([
    fetchSubformsWatcher(),
    publishCarForBuyersWatcher(),
    publishCarForSellerWatcher(),
    fetchTokenWatcher(),
    fetchRefreshTokenWatcher(),
    checkForExpiredTokenWatcher(),
    activateAccountWatcher(),
    confirmResetWatcher(),
    fetchFormsWatcher(),
    createCarWatcher(),
    updateCarFormWatcher(),
    getCarWatcher(),
    fetchFieldsWatcher(),
    getCarFieldsWatcher(),
    // fetchFeaturesWatcher(),
    getCarFormsWatcher(),
    getCarFeaturesWatcher(),
    getCarFaultsWatcher(),
    getCarVisualizationWatcher(),
    updateCarVisualsWatcher(),
    getCarListWatcher(),
    getCarDetailsWatcher(),
    getCarGroupsWatcher(),
    fetchListingRequestsWatcher(),
    claimListingRequestsWatcher(),
    submitCarToQCWatcher(),
    requestConnectionWatcher(),
    updatePageTimeStampWatcher(),
    getPageTimeStampsWatcher(),
    getUnseenNotificationsWatcher(),
    fetchGenericFeaturesWatcher(),
    // fetchFeaturesSubformsWatcher(),
    getCarGenericFeaturesWatcher(),
    fetchModelOptionsWatcher(),
    fetchFieldsOptionsWatcher(),
    checkVisFormsPointsWatcher(),
    fetchFieldsCountWatcher(),
    filterFormsWatcher(),
    getMapWatcher(),
    updateCarPointsWatcher(),
    convertPDFWatcher(),
    calculateEagleEyeWatcher(),
    updateDtcFormWatcher(),
    // saveFormAnswersWatcher(),
    totalTimeWatcher(),
    updateMissedMappingWatcher(),
    getPaparazziPackageWatcher(),
    initiatingPaparazziWatcher(),
    sendOTPWatcher(),
    validateOTPWatcher(),
    getDTCMappingWatcher(),
    getCarInvokedWatcher(),
    getPaparazziCarImagesWatcher(),
    getTestPaparazziPackageWatcher(),
    getCarDetailsImagesWatcher(),
    changeBgImagesWatcher(),
    getDownloadImagesWatcher(),
    reportBuilderWatcher(),
    getCarIdsTitlesListWatcher(),
    getPaparazziPackageListWatcher(),
    getPaparazziShadowsWatcher(),
    getTimeSlotsRequestsWatcher(),
    createTimeSlotWatcher(),
    updateTimeSlotWatcher(),
    getAreaGroupsWatcher(),
  ]);
}

export default appSagas;
