import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpIcon from "../icons/ArrowUp.svg";
import ArrowDownIcon from "../icons/ArrowDown.svg";
import SortIcon from "../icons/sort.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: "100%",
    flexDirection: "row",
  },
  buttonDiv: {
    display: "flex",
  },
  sortTypography: {
    fontSize: "18px",
    fontWeight: "700",
    display: "grid",
    alignContent: "center",
    paddingRight: "19px",
  },
  dropSortMenue: {
    minWidth: "233px",
    padding: "0",
  },
  dropSortMenuePaper: {
    borderRadius: 0,
  },
  menuItemDiv: {
    width: "calc(100% - 10px)",
    display: "flex",
  },
  menuItem: {
    borderBottom: "1px solid rgba(196,196,196,0.5)",
    paddingTop: "9px",
    paddingBottom: "8px",
    paddingLeft: "0",
  },
  expandLessIcon: {
    marginLeft: "13px",
  },
  firstMenueItemText: {
    marginRight: "auto",
    marginLeft: "16px",
  },
  selectRoot: {
    maxHeight: "37px",
  },
}));

const Sort = (props) => {
  const { handleChange, sortCriteria, handleOpenDrawer } = props; //props from CarListings
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false); //for sort options list

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Hidden smDown>
        <FormControl className={classes.formControl}>
          <Typography className={classes.sortTypography}>
            {t("Sort By")}
          </Typography>
          <Select
            labelId="controlled-open-select-label"
            id="controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={sortCriteria}
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal:
                  window.localStorage.getItem("language") === "en"
                    ? "left"
                    : "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal:
                  window.localStorage.getItem("language") === "en"
                    ? "left"
                    : "right",
              },
              getContentAnchorEl: null,
              classes: {
                list: classes.dropSortMenue,
                paper: classes.dropSortMenuePaper,
              },
            }}
            className={classes.dropSortMenue}
            classes={{ root: classes.selectRoot }}
            IconComponent={(props) => <ExpandMoreIcon {...props} />}
            style={{ padding: "0" }}
          >
            <MenuItem className={classes.menuItem} value={"lowestPrice"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Price")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Low to High)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"highestPrice"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Price")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(High to Low)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"lowestMilage"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Odometer")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Low to High)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"highestMilage"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Odometer")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(High to Low)")}
                </Typography>
              </div>
            </MenuItem>

            <MenuItem className={classes.menuItem} value={"newestYear"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Year")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Oldest First)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"oldestYear"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Year")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Newest First)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"oldest"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Date")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Oldest First)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"newest"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Date")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Newest First)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"lowestScore"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Score")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(Low to High)")}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem className={classes.menuItem} value={"highestScore"}>
              <div className={classes.menuItemDiv}>
                <Typography className={classes.firstMenueItemText}>
                  {t("Score")}
                </Typography>{" "}
                <Typography
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  {t("(High to Low)")}
                </Typography>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.buttonDiv}>
          <Typography style={{ display: "grid", alignContent: "center" }}>
            {t("Sort")}
          </Typography>
          <IconButton
            color="#07303E"
            className={classes.button}
            component="span"
            onClick={handleOpenDrawer}
          >
            <img src={SortIcon} alt="sortIcon" />
          </IconButton>
        </div>
      </Hidden>
    </div>
  );
};

export default Sort;
