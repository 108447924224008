import AWS from "aws-sdk";
import Compressor from "compressorjs";
import Live from "./Live";
import imageMerger from "./mergeImages";
import removeBg from "./removeBg";

AWS.config.region = "eu-central-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-central-1:176dded5-bfe6-4b8d-8187-bccb40c5174f",
});

let s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "markabte-static" },
});

// function getBase64(file, onLoadCallback) {
//   return new Promise(function (resolve, reject) {
//     var reader = new FileReader();
//     reader.onload = function () {
//       resolve(reader.result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

// const urlToObject = async (image) => {
//   const response = await fetch(image);
//   // here image is url/location of image
//   const blob = await response.blob();
//   const file = new File([blob], "image.png", { type: blob.type });
//   return getBase64(file);
// };

export async function checkFileExists(key) {
  try {
    let y = await s3.headObject({ Key: key }).promise();
    return true;
  } catch (error) {
    if (error.code === "NotFound") {
      return false;
    }
    throw error;
  }
}

export const s3Uploader = async (photoKey, body, callback) => {
  await s3.upload(
    {
      Key: photoKey,
      Body: body,
      ACL: "public-read",
    },
    async function (err, data) {
      if (err) {
        return alert("There was an error uploading your photo: ", err.message);
      }
      callback(data);
      return data;
    }
  );
};

export function dataURLtoFile(dataurl, filename) {
  console.log(dataurl, "vvvvvvvvvvvvvvvvv222");
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const handleCompress = (file, imgName, callback) => {
  new Compressor(file, {
    quality: 0.65,
    maxWidth: 2000, //1000,
    maxHeight: 1500, //750,
    convertSize: 500,
    convertTypes: "image/png,image/jpeg,image/jpg",
    async success(result) {
      console.log(result, "vvvvvvvvvvvvvvvv222222");
      s3Uploader(imgName, result, callback);
    },
    error(err) {
      console.log(err.message, "vvvvvvvvvvvvvvvv");
      alert(JSON.stringify(err));
    },
  });
};

export const handleCompressMiniImage = (file, imgName, callback) => {
  new Compressor(file, {
    quality: 0.5,
    maxWidth: 800, //1000,
    maxHeight: 600, //750,
    convertSize: 500,
    convertTypes: "image/png,image/jpeg,image/jpg",
    async success(result) {
      console.log(result, "vvvvvvvvvvvvvvvv22222333");

      s3Uploader(imgName, result, callback);
    },
    error(err) {
      console.log(err.message, "vvvvvvvvvvvvvvvv");
      alert(JSON.stringify(err));
    },
  });
};

const uploadImage = async (
  file,
  fileName,
  callback,
  removeBackground = false,
  packageImages = { bgImage: null, shadow: null }, // probably wrong
  exterior,
  big_body
) => {
  let prefix = "CPDTest/";
  let photoKey = fileName;
  let MiniphotoKey = fileName;
  if (removeBackground) {
    var pngImage = null;
    await removeBg(file)
      .then(async (removed) => {
        console.log("DDDDDDDDDDDDDDD555", removed);
        const base64Response = await fetch(`data:image/png;base64,${removed}`);
        ////////////////////adding bg, shadow and watermark///////////////////
        const { bgImage, shadow } = packageImages; // probably wrong
        await imageMerger(
          {
            carImage: base64Response.url,
            bgImage,
            shadow,
          },
          exterior,
          big_body
        ).then(async (b64MergedImage) => {
          const base64Response = await fetch(b64MergedImage);
          pngImage = dataURLtoFile(b64MergedImage, "imagename.jpg");
          handleCompressMiniImage(
            pngImage,
            `${prefix}Papa-${photoKey}`,
            () => {}
          );
          handleCompress(pngImage, `Papa-${photoKey}`, callback);
          const blob = await base64Response.blob();
        });
        //////////////////////////////////////////////////////////////////////
        const blob = await base64Response.blob();
        console.log("uuuuuuuuuuuuuuuuuuu", blob); // , result
        s3Uploader(`NoBg-${photoKey}`, blob, () => {});

        console.log("ddddddddddddddddd------");
      })
      .catch(() => {
        console.log("ddddddddddddddddd------1");

        handleCompressMiniImage(file, `${prefix}${photoKey}`, () => {});
        handleCompress(file, photoKey, callback);
        console.log("ddddddddddddddddd------2");
      }); // catch error and upload to s3 so that dispatcher could reupload later to remove.bg
  } else {
    console.log("ddddddddddddddddd------3", pngImage);

    handleCompressMiniImage(file, `${prefix}${photoKey}`, () => {});
    handleCompress(file, photoKey, callback);
    console.log("ddddddddddddddddd------4", pngImage);
  }
};

export default uploadImage;
