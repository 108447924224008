import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InfoButton from "./InfoButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import uploadImage from "../helpers/aws";
import imageTimeStamp from "../helpers/imageTimeStamp";
import DeleteIcon from "@material-ui/icons/Delete";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "@material-ui/icons/Publish";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 2.8em - 16px)",
      height: "auto",
    },
    width: "100%",
  },
}));

const RadioButtonInput = (props) => {
  const {
    onChange,
    value,
    options,
    formTitle,
    disabled,
    field,
    invokedFields,
    isdone,
    renderHide,
    carId,
    image,
    title,
    answerObject,
  } = props;
  const { t } = useTranslation();

  const handleDeletePress = (event) => {
    onChange(event, {
      id: answerObject?.id,
      field: field,
      option: answerObject.option,
      images: [],
    });
  };
  const handleFile = (event) => {
    uploadImage(
      event.target.files[0],
      `Car-${carId}-Image-${title}-${field.id}-${
        answerObject?.id
      }-${imageTimeStamp()}.png`,
      (data) => {
        onChange(event, {
          id: answerObject?.id,
          images: [data.Location],
          field: field,
          option: answerObject?.option,
        });
      }
    );
    event.target.value = ""; // this line will reset the input field so if you removed it you can re-add the same file.
  };
  const classes = useStyles();
  const handleChange = (event) => {
    let option = options.find((el) => el.id.toString() === event.target.value);
    onChange(event, {
      id: event.target.value,
      field: field,
      option: option,
      images: image.length && Number(option?.option_percentage) ? [image] : [],
    });
  };
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };

  return (
    <FormControl component="fieldset">
      <div style={{ display: "flex" }}>
        <FormLabel
          component="label"
          color={"secondary"}
          style={{ margin: "auto 0" }}
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          {formTitle}
        </FormLabel>
        <div>{renderHide}</div>
      </div>
      <RadioGroup value={value.toString()} onChange={handleChange}>
        {options.map((option, i) => (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              disabled={disabled}
              value={option.id.toString()}
              control={<Radio color={"primary"} />}
              label={option.title}
              key={`field_radio_button_${i}_${option.id}`}
            />
            {option?.option_info && (
              <InfoButton infoFiles={option.option_info} />
            )}
          </div>
        ))}
      </RadioGroup>
      {field.tag == 28 &&
      answerObject?.option?.option_percentage != "0.00" &&
      answerObject?.option ? (
        <>
          <div
            style={{
              marginTop: 10,
            }}
          >
            <FormLabel
              style={{ margin: "auto 0" }}
              classes={
                handleInvokedField() && isdone
                  ? { root: classes.switch_track }
                  : { root: classes.switch_base }
              }
            >
              {`${title} ${t("image")}:`}
            </FormLabel>
            <div style={{ display: "flex" }}>
              <IconButton disabled={answerObject?.images?.length}>
                <Badge color="primary">
                  <input
                    className={"file-input"}
                    type={"file"}
                    onChange={handleFile}
                    id={"file_upload_" + field.id}
                    accept={"image/*"}
                    style={{ display: "none" }}
                  />
                  <label
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      all: "inherit",
                    }}
                    htmlFor={"file_upload_" + field.id}
                  >
                    <UploadIcon
                      color={answerObject?.images?.length ? "" : "primary"}
                    />
                  </label>
                </Badge>
              </IconButton>
              <IconButton onClick={handleDeletePress}>
                <Badge
                  classes={{ badge: classes.visualizationBadge }}
                  color="primary"
                ></Badge>
                <DeleteIcon
                  color={
                    answerObject?.images?.length != 0 &&
                    typeof answerObject?.images == "object"
                      ? "secondary"
                      : ""
                  }
                />
              </IconButton>
            </div>
            <div style={{ position: "relative" }}>
              <img
                width={"100%"}
                src={
                  answerObject?.images?.length != 0 &&
                  typeof answerObject?.images == "object"
                    ? image
                    : require("../images/img_placeholder.png")
                }
                alt={"placeholder"}
                className={classes.image}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </FormControl>
  );
};

export default RadioButtonInput;
