import React from "react";
import { makeStyles } from "@material-ui/core";
import FiltersTypes from "../../helpers/filter-types";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    position: "relative",
  },
  positionedImage: {
    position: "absolute",
  },
}));

const StackedImageComponent = (props) => {
  const {
    showFrame,
    backgroundImage,
    shadowImage,
    noBgImage,
    savedImage,
    fieldId,
    imageType,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      {/* PAPA STACKED IMAGES */}

      {imageType && (
        <>
          {imageType !== "original" ? (
            <>
              {/* BACKGROUND IMAGE */}
              <img
                src={backgroundImage}
                width="100%"
                className={classes.positionedImage}
              />
              {/* SHADOW IMAGE */}
              <img
                src={shadowImage}
                // alt='#'
                width="100%"
                className={classes.positionedImage}
              />
              {/* NO BG IMAGE */}
              <img
                src={noBgImage}
                width="100%"
                className={classes.positionedImage}
              />
            </>
          ) : (
            <>
              {/* Original IMAGE */}
              <img
                src={
                  savedImage?.replace("Papa", "Origional") ??
                  require("../../images/Front Left_placeholder.png")
                }
                width="100%"
                className={classes.positionedImage}
              />
            </>
          )}
        </>
      )}

      {/* FRAME IMAGE */}
      {showFrame && (
        <img
          src={
            FiltersTypes.filter(
              (elmnt) => elmnt.id === fieldId?.toString()
            )?.[0]?.image
          }
          width="100%"
          className={classes.positionedImage}
        />
      )}

      {/* SAVED IMAGE FROM BE GIVES THE CONTAINER IT'S SIZE */}
      <img
        src={savedImage + (!imageType ? `?${Date.now()}` : "")}
        width="100%"
        style={imageType ? { opacity: 0 } : {}}
      />
    </div>
  );
};

export default StackedImageComponent;
