import React from "react";

const FeatureIcon51 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 52 35" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M10.2719 25.6379L6.52718 19.0238H4.92228V25.6379H1.51794V8.66489H7.88891C9.20201 8.66489 10.3206 8.89996 11.2446 9.37008C12.1686 9.82399 12.8576 10.4481 13.3115 11.2425C13.7816 12.0206 14.0167 12.896 14.0167 13.8687C14.0167 14.9872 13.6925 16.0004 13.044 16.9082C12.3956 17.7999 11.431 18.4159 10.1504 18.7563L14.2112 25.6379H10.2719ZM4.92228 16.4705H7.76733C8.69136 16.4705 9.38033 16.2517 9.83424 15.814C10.2882 15.3601 10.5151 14.736 10.5151 13.9416C10.5151 13.1635 10.2882 12.5637 9.83424 12.1422C9.38033 11.7045 8.69136 11.4856 7.76733 11.4856H4.92228V16.4705Z" fill="#07303E"/>
<path d="M16.269 17.1271C16.269 15.4574 16.6419 13.9659 17.3876 12.6528C18.1495 11.3235 19.1789 10.2941 20.4758 9.56461C21.7889 8.8189 23.256 8.44604 24.8771 8.44604C26.7738 8.44604 28.4355 8.93238 29.8621 9.90504C31.2886 10.8777 32.2856 12.2232 32.853 13.9416H28.938C28.549 13.1311 27.9978 12.5231 27.2845 12.1179C26.5874 11.7126 25.7769 11.5099 24.8528 11.5099C23.8639 11.5099 22.9804 11.745 22.2023 12.2151C21.4404 12.669 20.8406 13.3175 20.4029 14.1605C19.9814 15.0034 19.7706 15.9923 19.7706 17.1271C19.7706 18.2457 19.9814 19.2345 20.4029 20.0937C20.8406 20.9367 21.4404 21.5933 22.2023 22.0634C22.9804 22.5173 23.8639 22.7442 24.8528 22.7442C25.7769 22.7442 26.5874 22.5416 27.2845 22.1363C27.9978 21.7148 28.549 21.0988 28.938 20.2883H32.853C32.2856 22.0228 31.2886 23.3765 29.8621 24.3491C28.4517 25.3056 26.7901 25.7838 24.8771 25.7838C23.256 25.7838 21.7889 25.4191 20.4758 24.6896C19.1789 23.9439 18.1495 22.9145 17.3876 21.6014C16.6419 20.2883 16.269 18.7968 16.269 17.1271Z" fill="#07303E"/>
<path d="M34.934 17.1271C34.934 15.4574 35.3068 13.9659 36.0525 12.6528C36.8145 11.3235 37.8439 10.2941 39.1408 9.56461C40.4539 8.8189 41.921 8.44604 43.5421 8.44604C45.4388 8.44604 47.1004 8.93238 48.527 9.90504C49.9536 10.8777 50.9506 12.2232 51.5179 13.9416H47.603C47.2139 13.1311 46.6627 12.5231 45.9494 12.1179C45.2524 11.7126 44.4418 11.5099 43.5178 11.5099C42.5289 11.5099 41.6454 11.745 40.8672 12.2151C40.1053 12.669 39.5055 13.3175 39.0678 14.1605C38.6463 15.0034 38.4356 15.9923 38.4356 17.1271C38.4356 18.2457 38.6463 19.2345 39.0678 20.0937C39.5055 20.9367 40.1053 21.5933 40.8672 22.0634C41.6454 22.5173 42.5289 22.7442 43.5178 22.7442C44.4418 22.7442 45.2524 22.5416 45.9494 22.1363C46.6627 21.7148 47.2139 21.0988 47.603 20.2883H51.5179C50.9506 22.0228 49.9536 23.3765 48.527 24.3491C47.1166 25.3056 45.455 25.7838 43.5421 25.7838C41.921 25.7838 40.4539 25.4191 39.1408 24.6896C37.8439 23.9439 36.8145 22.9145 36.0525 21.6014C35.3068 20.2883 34.934 18.7968 34.934 17.1271Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon51;
