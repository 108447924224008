import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import colors from "../theme/colors";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { withRouter } from "react-router";
import Markabte_English_Logo from "../icons/Markabte_English_Logo.svg";
import ForgotPassword_SVG from "../icons/forgot-password.svg";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import LOGO from "../icons/MOB_LOGIN_Logo.svg";
import LogInImage from "../images/loginImg.png";
import MobileTopLoginIllustration from "../components/icons/MobileTopLoginIllustration";

import { Hidden } from "@material-ui/core";
import ResetSwitcher from "./resetPassword/ResetSwitcher";

const useStyle = makeStyles((theme) => ({
  paperContainer: {
    height: "50%",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  bottomImg: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  circleDiv: {
    height: "130vh",
    borderRadius: "0% 100rem 100rem 0%", // problematic at high heights and low widths
    // borderRadius: "0% 50% 50% 0%",  // not as circular
    backgroundColor: "white",
    position: "relative",
    transform: "translate(0, -15vh)",
    maxWidth: "46.11%",
    flexBasis: "46.11%",
    padding: "15vh 0 15vh 7.013vw",
  },
  formControlRoot: {
    // borderRadius: "8.67px",
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
    },
  },
  inputBaseRoot: {
    borderRadius: "5px",
    height: "6.815vh",
    [theme.breakpoints.down("sm")]: {
      height: window.screen.height * 0.0707,
      maxHeight: "40px",
      backgroundColor: "white",
    },
  },
  inputLableOutlined: {
    transform: "translate(20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      // transform: "translate(16px, 2.4vh) scale(1)",
      transform: `translate(16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  inputLableOutlinedArabic: {
    transform: "translate(-20px, calc(( 6.815vh - 16px ) / 2)) scale(1)",
    [theme.breakpoints.down("sm")]: {
      // transform: "translate(16px, 2.4vh) scale(1)",
      transform: `translate(-16px, calc((min(40px, ${
        window.screen.height * 0.0707
      }px) - 16px ) / 2)) scale(1)`,
    },
  },
  logo: {
    marginLeft: "-17px",
  },
  sendLinkButton: {
    fontSize: "clamp(14px,1.46vw,18px)",
    fontWeight: "500",
    textTransform: "none",
    padding: "9px clamp(25px,3.13vw,45px)",
    // borderRadius: "8.67px",
    borderRadius: "5px",
    lineHeight: "1.5",
    boxShadow: "0px 2px 5px rgba(9, 38, 48, 0.4)",
    // marginTop: "6.82vh",
    width: "28.542vw",
    minWidth: "335.55px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(12px,3.89vw,14px)",
      padding: "8px 25px",
      marginTop: "0",
      width: "auto",
      minWidth: "auto",
    },
  },

  mainContentDiv: {
    // paddingTop: "14.08vh",
    paddingTop: "5.7vh",
    justifyContent: "left",
  },
}));

const convertPhone = (name, value) => ({
  target: {
    name,
    value,
  },
});

const ForgotPassword = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [phone, setPhone] = useState("");
  const { t, i18n } = useTranslation();
  const { history, isAuthenticating } = props;

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === null) {
      localStorage.setItem("language", "ar");
    }
    var htmlTag = document.getElementsByTagName("html");
    htmlTag[0].dir = localStorage.getItem("language") === "en" ? "ltr" : "rtl";
    var bodyTag = document.getElementsByTagName("body");
    bodyTag[0].style.overflow = "hidden";
    window.scroll(0, 0);
  }, []);

  const Resetter = (props) => {
    return (
      <>
        <Hidden smDown>
          <Grid
            container
            style={{
              minHeight: "100%",
              backgroundColor: "#efefef",
            }}
          >
            <Grid item className={classes.circleDiv}>
              <Grid
                container
                justify={"center"}
                className={classes.mainContentDiv}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid item xs={12}>
                      <img
                        src={Markabte_English_Logo}
                        className={classes.logo}
                      />
                    </Grid>
                    <ResetSwitcher phone={phone} />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "53.89%",
                flexBasis: "53.89%",
              }}
            >
              <img
                src={ForgotPassword_SVG}
                style={{ width: "clamp(472px,42.1vw,606px)" }}
              />
            </Grid>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <div
            style={{
              backgroundColor: "white",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            <img
              src={LogInImage}
              style={{
                position: "fixed",
                filter: "blur(1.3px)", // try a lower blur 1px for example
                left: "-176px",
                width: "202.22%", // try objectFit:'cover', instead of height & width
                height: "128.8%",
              }}
            />
            <div
              style={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                background: "rgba(255, 255, 255, 0.3)",
              }}
            />
            <div
              style={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 99.51%)",
                transform: "rotate(-180deg)",
              }}
            />
            <Grid container>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  // marginTop: "1.72vh",
                  marginLeft: "9.722vw",
                  height: "max-content",
                }}
                // xs={12}
              >
                <img
                  src={LOGO}
                  // style={{ width: "75vw", height: "clamp(50px,23.28vh,122px)" }}
                  style={{
                    zIndex: "2",
                    width: "123px",
                    height: "auto",
                    // margin: "6.107vh 0 14.885vh",
                    margin: "6.107vh 0 15.616vh",
                  }}
                />
              </Grid>
              <ResetSwitcher phone={phone} />
            </Grid>
          </div>
        </Hidden>
      </>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <Resetter />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticating: state.auth.isAuthenticating,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
