import React from "react";

const FeatureIcon42 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M16.45 2.75002C15.75 3.10002 14.35 4.65002 13.35 6.20002C11.65 8.75002 11.35 8.95002 9.4 9.10002C7.5 9.25002 7.25 9.40002 7.25 10.5C7.25 11.4 7.55 11.8 8.3 11.9C9.35 12.05 9.35 12.15 8.65 13.55C7.95 14.95 7.95 15.75 8.65 21.1C9.1 24.9 9.65 27.3 10.1 27.55C11.1 28.2 15.7 28.1 16.4 27.4C16.75 27.05 17 26.4 17 25.9C17 25.05 17.65 25 25 25C32.35 25 33 25.05 33 25.9C33 26.4 33.25 27.05 33.6 27.4C34.3 28.1 38.9 28.2 39.9 27.55C40.35 27.3 40.9 24.9 41.35 21.1C42.05 15.75 42.05 14.95 41.35 13.55C40.65 12.15 40.65 12.05 41.7 11.9C42.45 11.8 42.75 11.4 42.75 10.5C42.75 9.40002 42.5 9.25002 40.6 9.10002C38.65 8.95002 38.35 8.75002 36.65 6.20002C35.65 4.65002 34.2 3.10002 33.45 2.70002C31.5 1.70002 18.3 1.75002 16.45 2.75002ZM33.65 5.60002C34.3 6.50002 35.25 7.85002 35.75 8.60002L36.6 10H25H13.4L14.25 8.60002C14.75 7.85002 15.7 6.50002 16.35 5.60002L17.55 4.00002H25H32.45L33.65 5.60002ZM38.4 12.6C39.5 13.7 39.05 14 36.5 14C34.05 14 34 14.05 34 15.5C34 17 34 17 36.95 17H39.95L39.7 18.85C39.55 19.9 39.35 21.25 39.2 21.85L39 23H25H11L10.8 21.85C10.65 21.25 10.45 19.9 10.3 18.85L10.05 17H13.05C16 17 16 17 16 15.5C16 14.05 15.95 14 13.5 14C10.95 14 10.5 13.7 11.6 12.6C12.45 11.75 37.55 11.75 38.4 12.6ZM15 25.5C15 25.75 14.1 26 13 26C11.9 26 11 25.75 11 25.5C11 25.2 11.9 25 13 25C14.1 25 15 25.2 15 25.5ZM39 25.5C39 25.75 38.1 26 37 26C35.9 26 35 25.75 35 25.5C35 25.2 35.9 25 37 25C38.1 25 39 25.2 39 25.5Z" fill="#07303E"/>
<path d="M13.25 31.65C14.2 32.6 15 33.8 15 34.3C15 34.85 13.45 36.7 11.5 38.5C7.35004 42.4 7.05004 43.55 9.75004 46.25C11.35 47.8 11.85 48 14.05 48H16.6L14.2 46.4C10.25 43.7 10.25 43.5 14.4 39.65C18.45 35.85 18.75 34.8 16.85 31.95C15.9 30.55 15.35 30.2 13.65 30.1L11.55 29.95L13.25 31.65Z" fill="#07303E"/>
<path d="M37.25 31.65C38.2 32.6 39 33.8 39 34.3C39 34.85 37.45 36.7 35.5 38.5C31.35 42.4 31.05 43.55 33.75 46.25C35.35 47.8 35.85 48 38.05 48H40.6L38.2 46.4C34.25 43.7 34.25 43.5 38.4 39.65C42.45 35.85 42.75 34.8 40.85 31.95C39.9 30.55 39.35 30.2 37.65 30.1L35.55 29.95L37.25 31.65Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon42;
