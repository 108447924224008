import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { withRouter, Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: "8px",
  },
  img: {
    width: "100%",
  },
}));

const PaparazziTester = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cars, history } = props;

  const handleCarClick = (carId) => {
    history.push({ pathname: "/paparazzi/view", state: { carId } });
  };

  return (
    <Grid container>
      {cars.map((car) => (
        <Grid
          item
          xs={4}
          spacing={2}
          className={classes.gridItem}
          onClick={() => {
            handleCarClick(car.id);
          }}
        >
          <img src={car.card.feature_image} className={classes.img} />
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  cars: state.car.cars,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaparazziTester)
);
