import React from "react";

const SignOut = (props) => {
  const { size, fill, style } = props;
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 143 142"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M120.924 65.5514H121.33L121.043 65.2644L110.371 54.5855L117.96 46.9925L141.873 70.9201L117.96 94.8466L110.371 87.2536L121.043 76.5758L121.33 76.2888H120.924H89.3506V65.5514H120.924ZM100.083 130.72V130.888H100.251H141.864V141.625H0.531403V16.6564C0.531403 7.59073 7.90414 0.21402 16.9646 0.21402H83.6487C92.7102 0.21402 100.083 7.59074 100.083 16.6564V54.1404H89.3506V16.6564C89.3506 13.5112 86.7935 10.9515 83.6487 10.9515H77.1633H76.9253L77.0048 11.1757C77.6128 12.8916 77.9468 14.7359 77.9468 16.6564V130.72V130.888H78.1149H89.1825H89.3506V130.72V87.6988H100.083V130.72ZM11.2627 130.72V130.888H11.4308H67.0474H67.2155V130.72V16.6564C67.2155 13.5112 64.6574 10.9515 61.5137 10.9515H16.9646C13.8209 10.9515 11.2627 13.5112 11.2627 16.6564V130.72ZM45.3563 70.7816C45.3563 67.8923 47.697 65.5514 50.5835 65.5514C53.471 65.5514 55.8107 67.8922 55.8107 70.7816C55.8107 73.6699 53.471 76.0118 50.5835 76.0118C47.697 76.0118 45.3563 73.6699 45.3563 70.7816Z"
          fill={fill}
          stroke="white"
          stroke-width="0.336243"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="141.747"
            height="141.747"
            fill="white"
            transform="translate(0.363281 0.0458984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignOut;
