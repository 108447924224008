import React from "react";

const FeatureIcon31 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M26.5413 25.0002L38.2037 14.3086C38.412 14.1169 38.5349 13.8461 38.5412 13.5628C38.5474 13.2795 38.4391 13.0045 38.237 12.8045L25.7371 0.304626C25.4371 0.00882771 24.9913 -0.0828706 24.6016 0.0796278C24.2121 0.240075 23.9579 0.621322 23.9579 1.04212V22.6315L13.2039 12.7732C12.781 12.3877 12.1247 12.4127 11.7331 12.8377C11.3436 13.2627 11.3727 13.921 11.7956 14.3106L23.458 25.0001L11.7955 35.6896C11.3726 36.0792 11.3435 36.7376 11.733 37.1626C12.1247 37.5896 12.783 37.6146 13.2038 37.2271L23.9579 27.3689V48.9583C23.9579 49.379 24.2121 49.7603 24.6016 49.9207C24.7287 49.9749 24.8662 49.9999 24.9996 49.9999C25.2704 49.9999 25.5371 49.8937 25.7371 49.6937L38.237 37.1938C38.437 36.9938 38.5474 36.7188 38.5412 36.4355C38.5349 36.1522 38.4141 35.8813 38.2037 35.6897L26.5413 25.0002ZM26.0413 3.55664L35.9932 13.5086L26.0413 22.6315V3.55664ZM26.0413 46.4437V27.3689L35.9932 36.4918L26.0413 46.4437Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon31;
