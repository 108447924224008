import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon404 from "./icons/Icon404.svg";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "46vw",
    height: "230px",
    position: "relative",
    height: "231px",
    top: "37%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      top: "0",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: { top: "0", marginBottom: "80px" },
    [theme.breakpoints.up("lg")]: {
      top: "35%",
      marginBottom: "80px",
      width: "100%",
    },
  },
  FourOFourBlock: {
    margin: "150px 8.3%",
    // padding: "0 ",
    [theme.breakpoints.up("md")]: {
      margin: "12% auto",
      marginTop: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflow: "hidden",
      width: "310px",
      margin: "auto",
    },
  },
  FourOFour: {
    fontWeight: "700",
  },
  secText: {
    fontWeight: "700",
    width: "46vw",
    fontSize: "36px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  FOFSm: {
    fontSize: "96px",
  },
  secTextSm: {
    fontSize: "36px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  spacing: {
    margin: "25px 0",
  },
  button: {
    fontSize: "20px",
  },
  container: {
    [theme.breakpoints.up("md")]: {
      width: "60%",
      margin: "auto",
      padding: "0 10%",
    },
    [theme.breakpoints.up("lg")]: { width: "100%", margin: "auto" },
  },
  center: {
    textAlign: "center",
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
  },

  //   [theme.breakpoints.up("md")]: {},
}));

function Handle404(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const info = window.location.pathname.includes("something-went-wrong")
    ? ["Oops!", "Something went wrong, please try again later."]
    : ["404", "We couldn’t find the page you are looking for."];

  return (
    <>
      {/* <RenderDrawerList /> */}
      <div>
        {/* className={classes.sm} */}
        <Hidden mdDown>
          <Grid container className={classes.container}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className={classes.FourOFourBlock}>
                <Typography
                  className={`${classes.FourOFour} ${classes.spacing}`}
                  color="primary"
                  variant="h1"
                >
                  {t(info[0])}
                </Typography>
                <Typography
                  variant={"h3"}
                  className={`${classes.secText} ${classes.spacing}`}
                >
                  {t(info[1])}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.button} ${classes.spacing}`}
                  onClick={() => window.location.replace("/")}
                >
                  {t("Go To Homepage")}
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <img src={Icon404} className={classes.img} />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <div style={{ padding: "20% 0" }}>
            <Typography
              className={`${classes.FOFSm} ${classes.center}`}
              color="primary"
              variant="h2"
            >
              {t(info[0])}
            </Typography>
            <Typography
              variant={"h3"}
              className={`${classes.secTextSm} ${classes.center}`}
            >
              {t(info[1])}
            </Typography>
            <div className={classes.center}>
              <img src={Icon404} className={classes.img} />
            </div>
            <div
              classNames={classes.center}
              style={{ display: "grid", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={`${classes.button}`}
                onClick={() => window.location.replace("/")}
              >
                {t("Go To Homepage")}
              </Button>
            </div>
          </div>
        </Hidden>
      </div>
    </>
  );
}

export default Handle404;
