import { put, takeLatest } from "redux-saga/effects";
import { requestAPI } from "../helpers";
import {
  FETCH_INCOMING_LISTING_REQUEST,
  FETCH_INCOMING_LISTING_REQUEST_SUCCEED,
  FETCH_INCOMING_LISTING_REQUEST_FAILURE,
  CLAIM_LISTING_REQUEST,
  CLAIM_LISTING_REQUEST_SUCCEED,
  CLAIM_LISTING_REQUEST_FAILURE,
  TOGGLE_NOTIFICATION_BAR,
} from "../constants";

import API_URLS from "../../api";

export function* fetchListingRequests(action) {
  try {
    let data = {
      url: API_URLS(action.payload.queryset).LISTINGS.REQUEST,
      method: "GET",
    };
    const response = yield requestAPI(data);
    yield put({
      type: FETCH_INCOMING_LISTING_REQUEST_SUCCEED,
      payload: response,
    });
    // yield put({
    //   type: TOGGLE_NOTIFICATION_BAR,
    //   payload: {
    //     message:
    //       localStorage.getItem("language") === "en"
    //         ? `There are ${response.count} requests`
    //         : `هناك ${response.count} طلبات`,
    //     variant: "info",
    //     open: true,
    //   },
    // });
  } catch (e) {
    yield put({
      type: FETCH_INCOMING_LISTING_REQUEST_FAILURE,
      error: new Error(e),
    });
    // yield put({
    //   type: TOGGLE_NOTIFICATION_BAR,
    //   payload: {
    //     message: "There was an error sending your request please try again",
    //     variant: "error",
    //     open: true,
    //   },
    // });
  }
}

export function* fetchListingRequestsWatcher() {
  yield takeLatest(FETCH_INCOMING_LISTING_REQUEST, fetchListingRequests);
}

export function* claimListingRequests(action) {
  try {
    let data = {
      url: API_URLS(action.payload.requestId).LISTINGS.CLAIM,
      method: "POST",
      body: {
        time: action.payload.time,
        claimedBy: action.payload.claimedBy,
        // operations id
      },
    };
    const response = yield requestAPI(data);
    yield put({
      type: CLAIM_LISTING_REQUEST_SUCCEED,
      payload: response,
    });
    // yield put({
    //   type: TOGGLE_NOTIFICATION_BAR,
    //   payload: {
    //     message: "Claimed successfully",
    //     variant: "success",
    //     open: true,
    //   },
    // });
  } catch (e) {
    yield put({
      type: CLAIM_LISTING_REQUEST_FAILURE,
      error: new Error(e),
    });
    yield put({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        message:
          "This listing have already been claimed, try refreshing the page to get the latest listings",
        variant: "error",
        open: true,
      },
    });
  }
}

export function* claimListingRequestsWatcher() {
  yield takeLatest(CLAIM_LISTING_REQUEST, claimListingRequests);
}
