import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InfoButton from "./InfoButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Button, Typography } from "@material-ui/core";

import Outside from "../icons/paparazziIcons/Outside.svg";
import ShowRoom from "../icons/paparazziIcons/ShowRoom.svg";
import Trees from "../icons/paparazziIcons/Trees.svg";
import Buildings from "../icons/paparazziIcons/Buildings.svg";
import NoReflection from "../icons/paparazziIcons/NoReflection.svg";
import Lights from "../icons/paparazziIcons/lights.svg";
import NoLights from "../icons/paparazziIcons/noLights.svg";
import { initiatingPaparazzi } from "../store/actions/car";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPaparazziPackage } from "../store/actions/forms";
import PaparazziSelectorComponent from "./sunLocationComponent/PaparazziSelectorComponent";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },

  // yellowButtonRoot: {
  //   backgroundColor: "#00000004",
  //   borderRadius: 5,
  //   border: "1px solid",
  //   color: "yellow",
  //   fontFamily: "monospace",
  //   fontSize: "200%",
  //   height: 32,
  //   "&$checked": {
  //     backgroundColor: "yellow",
  //     color: "white",
  //   },
  //   "&:not($checked):hover": {
  //     backgroundColor: "yellow",
  //   },
  //   transition: "background-color 250ms",
  // },
  // disabled: {
  //   backgroundColor: "#00000004",
  // },
  // checked: {},

  formControlLabelLabel: { display: "none" },
  radioRoot: { padding: 0, margin: 0 },
  button: {
    border: "1px solid #C4C4C4",
    width: "100px",
    height: "100px",
  },
  buttonInnerDiv: {
    // display:

    [theme.breakpoints.up("md")]: {
      // width: "177px",
      // height: "177px",
    },
  },
  buttonTypography: {
    color: "#186985",
    fontSize: "12px",
    textTransform: "none",
  },
}));

const iconSelector = (optionId, fieldId) => {
  switch (optionId) {
    case 4198:
      return Outside;
      break;
    case 4199:
      return ShowRoom;
      break;
    case 4293:
      return Trees;
      break;
    case 4294:
      return Buildings;
      break;
    case 4295:
      return NoReflection;
      break;
    case 4296:
      return Lights;
      break;
    case 4297:
      return NoLights;
      break;
    default:
      return "#";
      break;
  }
};

const CheckButton = (props) => {
  const classes = useStyles();

  const { title, optionId, fieldId, divStyle } = props;
  return (
    <Button variant="outlined" className={classes.button} style={divStyle}>
      <div className={classes.buttonInnerDiv}>
        <img src={iconSelector(optionId, fieldId)} />
        <Typography className={classes.buttonTypography}>{title}</Typography>
      </div>
    </Button>
  );
};

const RadioButtonGroup = (props) => {
  const {
    onChange,
    value,
    options,
    field,
    invokedFields,
    isdone,
    fieldTitle,
    initiatingPaparazzi,
    getPaparazziPackage,
    paparazzi_selection_data,
    carId,
  } = props;
  const classes = useStyles();
  const handleChange = (event) => {
    let option = options.find((el) => el.id.toString() === event.target.value);
    onChange(event, { id: event.target.value, field: field, option: option });
  };
  const handleSunLocationChange = (value) => {
    let option = options.find((el) => el.id.toString() === value);
    onChange(null, { id: value, field: field, option: option });
  };
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  useEffect(() => {
    initiatingPaparazzi({
      value: value,
      fieldId: field.id,
      callback: () => {
        // console.log("RRRRRRRRRRRRRR RBG", paparazzi_selection_data);
        // getPaparazziPackage({
        //   location: paparazzi_selection_data?.location,
        //   sun_location: paparazzi_selection_data?.sun_location,
        //   reflection: paparazzi_selection_data?.reflection,
        //   carId: carId,
        //   fieldTitle, // remove
        // });
      },
    });
  }, [value]);
  return (
    <FormControl component="fieldset">
      <div style={{ display: "flex" }}>
        <FormLabel
          component="label"
          color={"secondary"}
          style={{ margin: "auto 0" }}
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          {fieldTitle}
        </FormLabel>
        {/* <div>{renderHide}</div> */}
      </div>
      {field.id !== 972 ? (
        <RadioGroup
          value={value.toString()}
          onChange={handleChange}
          style={{
            flexDirection: "row",
            width: "100%",
            // flexWrap: "nowrap",
            // justifyContent: "space-between",
          }}
        >
          {options.map((option, i) => (
            <div style={{ display: "flex" }}>
              <FormControlLabel
                // disabled={disabled}
                value={option.id.toString()}
                control={
                  <Radio
                    color={"primary"}
                    checkedIcon={
                      <CheckButton
                        title={option.title}
                        optionId={option.id}
                        fieldId={field.id}
                        divStyle={{ border: "4px solid #186985" }}
                      />
                    }
                    icon={
                      <CheckButton
                        title={option.title}
                        optionId={option.id}
                        fieldId={field.id}
                      />
                    }
                    // classes={{
                    //   root: classes.yellowButtonRoot,
                    //   checked: classes.checked,
                    //   disabled: classes.disabled,
                    // }}
                  />
                }
                label={option.title}
                key={`field_radio_button_${i}_${option.id}`}
                classes={{
                  label: classes.formControlLabelLabel,
                  root: classes.radioRoot,
                }}
                // style={{ padding: 0 }}
              />
              {/* <Radio
              value={option.id.toString()}
              color={"primary"}
              classes={{
                root: classes.yellowButtonRoot,
                checked: classes.checked,
                disabled: classes.disabled,
              }}
            /> */}
              {option?.option_info && (
                <InfoButton infoFiles={option.option_info} />
              )}
            </div>
          ))}
        </RadioGroup>
      ) : (
        <PaparazziSelectorComponent
          handleSunLocationChange={handleSunLocationChange}
          value={value}
        />
      )}
    </FormControl>
  );
};

// export default RadioButtonGroup;

const mapStateToProps = (state) => ({
  paparazzi_selection_data: state.car.car.paparazzi_selection_data,
});

const mapDispatchToProps = (dispatch) => ({
  initiatingPaparazzi(payload) {
    dispatch(initiatingPaparazzi(payload));
  },
  getPaparazziPackage(payload) {
    dispatch(getPaparazziPackage(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RadioButtonGroup)
);
