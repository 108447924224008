import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import StaticIcon from "../icons/StaticTextField.svg";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    width: "100%",
  },
  iconDiv: {
    borderRadius: "3px 0 0 3px",
    backgroundColor: "#F06360",
    padding: "11.5px 10px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      padding: "13px 10px",
    },
  },
  icon: {
    margin: "auto",
    height: "24px",
    [theme.breakpoints.up("md")]: {
      height: "26px",
    },
  },
  typographyDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "13px 16px",
    },
    padding: "11.5px 12px",
    display: "flex",
  },
  Label: {
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    fontWeight: "500",
    color: "#F06360",
    margin: "auto 0",
  },
}));

const StaticText = (props) => {
  const { label } = props;
  const classes = useStyles();
  return (
    <div className={classes.mainDiv}>
      <div className={classes.iconDiv}>
        <img src={StaticIcon} className={classes.icon} />
      </div>
      <div className={classes.typographyDiv}>
        <Typography className={classes.Label}>{label}</Typography>
      </div>
    </div>
  );
};

export default StaticText;
