// AUTH Constants
export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST";
export const FETCH_TOKEN_REQUEST_SUCCEED = "FETCH_TOKEN_REQUEST_SUCCEED";
export const FETCH_TOKEN_REQUEST_FAILURE = "FETCH_TOKEN_REQUEST_FAILURE";

export const FETCH_REFRESH_TOKEN_REQUEST = "FETCH_REFRESH_TOKEN_REQUEST";
export const FETCH_REFRESH_TOKEN_REQUEST_SUCCEED =
  "FETCH_REFRESH_TOKEN_REQUEST_SUCCEED";
export const FETCH_REFRESH_TOKEN_REQUEST_FAILURE =
  "FETCH_REFRESH_TOKEN_REQUEST_FAILURE";

export const ACTIVATE_ACCOUNT_REQUEST = "ACTIVATE_ACCOUNT_REQUEST";
export const ACTIVATE_ACCOUNT_SUCCEED = "ACTIVATE_ACCOUNT_SUCCEED";
export const ACTIVATE_ACCOUNT_FAILURE = "ACTIVATE_ACCOUNT_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCEED =
  "FORGOT_PASSWORD_REQUEST_SUCCEED";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";

export const TOKEN_REMOVE_REQUEST = "TOKEN_REMOVE_REQUEST";

// NOTIFICATIONS Constants
export const TOGGLE_NOTIFICATION_BAR = "TOGGLE_NOTIFICATION_BAR";

// BACKDROP Constants
export const TOGGLE_LOADING_BACKDROP = "TOGGLE_LOADING_BACKDROP";

// FORMS Constants

export const FETCH_FORMS_REQUEST = "FETCH_FORMS_REQUEST";
export const FETCH_FORMS_REQUEST_SUCCEED = "FETCH_FORMS_REQUEST_SUCCEED";
export const FETCH_FORMS_REQUEST_FAILURE = "FETCH_FORMS_REQUEST_FAILURE";

export const FETCH_SUBFORMS_REQUEST = "FETCH_SUBFORMS_REQUEST";
export const FETCH_SUBFORMS_REQUEST_SUCCEED = "FETCH_SUBFORMS_REQUEST_SUCCEED";
export const FETCH_SUBFORMS_REQUEST_FAILURE = "FETCH_SUBFORMS_REQUEST_FAILURE";

export const FETCH_FIELDS_REQUEST = "FETCH_FIELDS_REQUEST";
export const FETCH_FIELDS_REQUEST_SUCCEED = "FETCH_FIELDS_REQUEST_SUCCEED";
export const FETCH_FIELDS_REQUEST_FAILURE = "FETCH_FIELDS_REQUEST_FAILURE";

export const FETCH_FIELDS_OPTIONS_REQUEST = "FETCH_FIELDS_OPTIONS_REQUEST";
export const FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED =
  "FETCH_FIELDS_OPTIONS_REQUEST_SUCCEED";
export const FETCH_FIELDS_OPTIONS_REQUEST_FAILURE =
  "FETCH_FIELDS_OPTIONS_REQUEST_FAILURE";

export const FETCH_MODEL_OPTIONS_REQUEST = "FETCH_MODEL_OPTIONS_REQUEST";
export const FETCH_MODEL_OPTIONS_REQUEST_SUCCEED =
  "FETCH_MODEL_OPTIONS_REQUEST_SUCCEED";
export const FETCH_MODEL_OPTIONS_REQUEST_FAILURE =
  "FETCH_MODEL_OPTIONS_REQUEST_FAILURE";

export const FETCH_FIELDS_COUNT_REQUEST = "FETCH_FIELDS_COUNT_REQUEST";
export const FETCH_FIELDS_COUNT_REQUEST_SUCCEED =
  "FETCH_FIELDS_COUNT_REQUEST_SUCCEED";
export const FETCH_FIELDS_COUNT_REQUEST_FAILURE =
  "FETCH_FIELDS_COUNT_REQUEST_FAILURE";

export const ADD_VIDEO_IMAGE = "ADD_VIDEO_IMAGE";
export const REMOVE_SELECTED_VIDEO_IMAGES = "REMOVE_SELECTED_VIDEO_IMAGES";
export const CLEAR_VIDEO_IMAGES = "CLEAR_VIDEO_IMAGES";
export const TOGGLE_SELECTED_IMAGE = "TOGGLE_SELECTED_IMAGE";

// export const FETCH_FEATURES_REQUEST = "FETCH_FEATURES_REQUEST";
// export const FETCH_FEATURES_REQUEST_SUCCEED = "FETCH_FEATURES_REQUEST_SUCCEED";
// export const FETCH_FEATURES_REQUEST_FAILURE = "FETCH_FEATURES_REQUEST_FAILURE";

export const FETCH_GENERIC_FEATURES_REQUEST = "FETCH_GENERIC_FEATURES_REQUEST";
export const FETCH_GENERIC_FEATURES_REQUEST_SUCCEED =
  "FETCH_GENERIC_FEATURES_REQUEST_SUCCEED";
export const FETCH_GENERIC_FEATURES_REQUEST_FAILURE =
  "FETCH_GENERIC_FEATURES_REQUEST_FAILURE";

export const FETCH_CAR_GENERIC_FEATURES_REQUEST =
  "FETCH_CAR_GENERIC_FEATURES_REQUEST";
export const FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED =
  "FETCH_CAR_GENERIC_FEATURES_REQUEST_SUCCEED";
export const FETCH_CAR_GENERIC_FEATURES_REQUEST_FAILURE =
  "FETCH_CAR_GENERIC_FEATURES_REQUEST_FAILURE";

export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const UPDATE_FOEMFIELDS = "UPDATE_FOEMFIELDS";

export const UPDATE_HIDDEN = "UPDATE_HIDDEN";
export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const UPDATE_GENERIC_FEATURES = "UPDATE_GENERIC_FEATURES";
export const UPDATE_POINTS = "UPDATE_POINTS";
export const CLEAR_ANSWERS = "CLEAR_ANSWERS";

export const CLEAR_SAVED_FORM_DATA = "CLEAR_SAVED_FORM_DATA";

export const FETCH_PAPARAZZI_PACKAGE_REQUEST =
  "FETCH_PAPARAZZI_PACKAGE_REQUEST";
export const FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED =
  "FETCH_PAPARAZZI_PACKAGE_REQUEST_SUCCEED";
export const FETCH_PAPARAZZI_PACKAGE_REQUEST_FAILURE =
  "FETCH_PAPARAZZI_PACKAGE_REQUEST_FAILURE";

export const FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST =
  "FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST";
export const FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_SUCCEED =
  "FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_SUCCEED";
export const FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_FAILURE =
  "FETCH_PAPARAZZI_PACKAGE_LIST_REQUEST_FAILURE";

export const FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST =
  "FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST";
export const FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST_SUCCEED =
  "FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST_SUCCEED";
export const FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST_FAILURE =
  "FETCH_PAPARAZZI_SHADOWS_PACKAGE_LIST_REQUEST_FAILURE";

export const FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST =
  "FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST";
export const FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_SUCCEED =
  "FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_SUCCEED";
export const FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_FAILURE =
  "FETCH_TEST_PAPARAZZI_PACKAGE_REQUEST_FAILURE";

export const FETCH_PAPARAZZI_CAR_IMAGES_REQUEST =
  "FETCH_PAPARAZZI_CAR_IMAGES_REQUEST";

export const FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED =
  "FETCH_PAPARAZZI_CAR_IMAGES_REQUEST_SUCCEED";

export const UPDATE_PAPARAZZI_IMAGE = "UPDATE_PAPARAZZI_IMAGE";
export const UPDATE_PAPARAZZI_VIS_IMAGE = "UPDATE_PAPARAZZI_VIS_IMAGE";

export const CHANGE_BG_IMAGE = "CHANGE_BG_IMAGE";
export const CHANGE_BG_IMAGE_SUCCEED = "CHANGE_BG_IMAGE_SUCCEED";
export const CHANGE_BG_IMAGE_FAILURE = "CHANGE_BG_IMAGE_FAILURE";

export const FETCH_IMAGE_DOWNLOAD = "FETCH_IMAGE_DOWNLOAD";
export const FETCH_IMAGE_DOWNLOAD_SUCCEED = "FETCH_IMAGE_DOWNLOAD_SUCCEED";
export const FETCH_IMAGE_DOWNLOAD_FAILURE = "FETCH_IMAGE_DOWNLOAD_FAILURE";
// CAR Constants

export const SUBMIT_CAR_TO_QC = "SUBMIT_CAR_TO_QC";
export const SUBMIT_CAR_TO_QC_SUCCEED = "SUBMIT_CAR_TO_QC_SUCCEED";
export const SUBMIT_CAR_TO_QC_FAILURE = "SUBMIT_CAR_TO_QC_FAILURE";

export const LIST_CARS_REQUEST = "LIST_CARS_REQUEST";
export const LIST_CARS_REQUEST_SUCCEED = "LIST_CARS_REQUEST_SUCCEED";
export const LIST_CARS_REQUEST_FAILURE = "LIST_CARS_REQUEST_FAILURE";

export const PUBLISH_CAR_FOR_BUYERS_REQUEST = "PUBLISH_CAR_FOR_BUYERS_REQUEST";
export const PUBLISH_CAR_FOR_BUYERS_REQUEST_SUCCEED =
  "PUBLISH_CAR_FOR_BUYERS_REQUEST_SUCCEED";
export const PUBLISH_CAR_FOR_BUYERS_REQUEST_FAILURE =
  "PUBLISH_CAR_FOR_BUYERS_REQUEST_FAILURE";

export const PUBLISH_CAR_FOR_SELLER_REQUEST = "PUBLISH_CAR_FOR_SELLER_REQUEST";
export const PUBLISH_CAR_FOR_SELLER_REQUEST_SUCCEED =
  "PUBLISH_CAR_FOR_SELLER_REQUEST_SUCCEED";
export const PUBLISH_CAR_FOR_SELLER_REQUEST_FAILURE =
  "PUBLISH_CAR_FOR_SELLER_REQUEST_FAILURE";

export const CREATE_EMPTY_CAR_REQUEST = "CREATE_EMPTY_CAR_REQUEST";
export const CREATE_EMPTY_CAR_REQUEST_SUCCEED =
  "CREATE_EMPTY_CAR_REQUEST_SUCCEED";
export const CREATE_EMPTY_CAR_REQUEST_FAILURE =
  "CREATE_EMPTY_CAR_REQUEST_FAILURE";

export const UPDATE_CAR_FORM_REQUEST = "UPDATE_CAR_FORM_REQUEST";
export const UPDATE_CAR_FORM_REQUEST_SUCCEED =
  "UPDATE_CAR_FORM_REQUEST_SUCCEED";
export const UPDATE_CAR_FORM_REQUEST_FAILURE =
  "UPDATE_CAR_FORM_REQUEST_FAILURE";

export const UPDATE_DTC_FORM_REQUEST = "UPDATE_DTC_FORM_REQUEST";
export const UPDATE_DTC_FORM_REQUEST_SUCCEED =
  "UPDATE_DTC_FORM_REQUEST_SUCCEED";

export const GET_DTC_MAPPING = "GET_DTC_MAPPING";
export const GET_DTC_MAPPING_SUCCESS = "GET_DTC_MAPPING_SUCCESS";
export const GET_DTC_MAPPING_FAILURE = "GET_DTC_MAPPING_FAILURE";

export const REMOVE_UPDATED_FORM = "REMOVE_UPDATED_FORM";
export const TRIGGER_UPDATED_FORMS = "TRIGGER_UPDATED_FORMS";
export const TRIGGER_UPDATED_FIELDS = "TRIGGER_UPDATED_FIELDS";

export const CHECK_VIS_FORMS_POINTS = "CHECK_VIS_FORMS_POINTS";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

export const UPDATE_CAR_VISUALS_REQUEST = "UPDATE_CAR_VISUALS_REQUEST";
export const UPDATE_CAR_VISUALS_REQUEST_SUCCEED =
  "UPDATE_CAR_VISUALS_REQUEST_SUCCEED";
export const UPDATE_CAR_VISUALS_REQUEST_FAILURE =
  "UPDATE_CAR_VISUALS_REQUEST_FAILURE";

export const FETCH_CAR_REQUEST = "FETCH_CAR_REQUEST";
export const FETCH_CAR_REQUEST_SUCCEED = "FETCH_CAR_REQUEST_SUCCEED";
export const FETCH_CAR_REQUEST_FAILURE = "FETCH_CAR_REQUEST_FAILURE";

export const FETCH_CAR_FIELDS_REQUEST = "FETCH_CAR_FIELDS_REQUEST";
export const FETCH_CAR_FIELDS_REQUEST_SUCCEED =
  "FETCH_CAR_FIELDS_REQUEST_SUCCEED";
export const FETCH_CAR_FIELDS_REQUEST_FAILURE =
  "FETCH_CAR_FIELDS_REQUEST_FAILURE";

export const FETCH_CAR_FORMS_REQUEST = "FETCH_CAR_FORMS_REQUEST";
export const FETCH_CAR_FORMS_REQUEST_SUCCEED =
  "FETCH_CAR_FORMS_REQUEST_SUCCEED";
export const FETCH_CAR_FORMS_REQUEST_FAILURE =
  "FETCH_CAR_FORMS_REQUEST_FAILURE";

export const FETCH_CAR_FEATURES_REQUEST = "FETCH_CAR_FEATURES_REQUEST";
export const FETCH_CAR_FEATURES_REQUEST_SUCCEED =
  "FETCH_CAR_FEATURES_REQUEST_SUCCEED";
export const FETCH_CAR_FEATURES_REQUEST_FAILURE =
  "FETCH_CAR_FEATURES_REQUEST_FAILURE";

export const FETCH_CAR_FAULTS_REQUEST = "FETCH_CAR_FAULTS_REQUEST";
export const FETCH_CAR_FAULTS_REQUEST_SUCCEED =
  "FETCH_CAR_FAULTS_REQUEST_SUCCEED";
export const FETCH_CAR_FAULTS_REQUEST_FAILURE =
  "FETCH_CAR_FAULTS_REQUEST_FAILURE";

export const BUILD_REPORT = "BUILD_REPORT";
export const BUILD_REPORT_SUCCEED = "BUILD_REPORT_SUCCEED";
export const BUILD_REPORT_FAILURE = "BUILD_REPORT_FAILURE";

export const FETCH_CAR_VISUALIZATION_REQUEST =
  "FETCH_CAR_VISUALIZATION_REQUEST";
export const FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED =
  "FETCH_CAR_VISUALIZATION_REQUEST_SUCCEED";
export const FETCH_CAR_VISUALIZATION_REQUEST_FAILURE =
  "FETCH_CAR_VISUALIZATION_REQUEST_FAILURE";

export const FETCH_CAR_DETAILS_REQUEST = "FETCH_CAR_DETAILS_REQUEST";
export const FETCH_CAR_DETAILS_REQUEST_SUCCEED =
  "FETCH_CAR_DETAILS_REQUEST_SUCCEED";
export const FETCH_CAR_DETAILS_REQUEST_FAILURE =
  "FETCH_CAR_DETAILS_REQUEST_FAILURE";

export const FETCH_CAR_GROUPS_REQUEST = "FETCH_CAR_GROUPS_REQUEST";
export const FETCH_CAR_GROUPS_REQUEST_SUCCEED =
  "FETCH_CAR_GROUPS_REQUEST_SUCCEED";
export const FETCH_CAR_GROUPS_REQUEST_FAILURE =
  "FETCH_CAR_GROUPS_REQUEST_FAILURE";

export const FETCH_CAR_INVOKED = "FETCH_CAR_INVOKED";
export const FETCH_CAR_INVOKED_SUCCEED = "FETCH_CAR_INVOKED_SUCCEED";
export const FETCH_CAR_INVOKED_FAILURE = "FETCH_CAR_INVOKED_FAILURE";

export const FETCH_CAR_DETAILS_IMAGES_REQUEST =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST";
export const FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST_SUCCEED";
export const FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE =
  "FETCH_CAR_DETAILS_IMAGES_REQUEST_FAILURE";

export const FETCH_CAR_IDS_TITLES_LIST = "FETCH_CAR_IDS_TITLES_LIST";

// Listing requests Constants

export const FETCH_INCOMING_LISTING_REQUEST = "FETCH_INCOMING_LISTING_REQUEST";
export const FETCH_INCOMING_LISTING_REQUEST_SUCCEED =
  "FETCH_INCOMING_LISTING_REQUEST_SUCCEED";
export const FETCH_INCOMING_LISTING_REQUEST_FAILURE =
  "FETCH_INCOMING_LISTING_REQUEST_FAILURE";

export const CLAIM_LISTING_REQUEST = "CLAIM_LISTING_REQUEST";
export const CLAIM_LISTING_REQUEST_SUCCEED = "CLAIM_LISTING_REQUEST_SUCCEED";
export const CLAIM_LISTING_REQUEST_FAILURE = "CLAIM_LISTING_REQUEST_FAILURE";

//  Language Constants

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

//  websockets constants
// ws:// connection constants
export const CONNECTION_REQUEST = "CONNECTION_REQUEST";
export const CONNECTION_REQUEST_SUCCEED = "CONNECTION_REQUEST_SUCCEED";
export const CONNECTION_REQUEST_FAILURE = "CONNECTION_REQUEST_FAILURE";
// http:// constants
export const UPDATE_PAGE_TIME_STAMP = "UPDATE_PAGE_TIME_STAMP";
export const UPDATE_PAGE_TIME_STAMP_SUCCEED = "UPDATE_PAGE_TIME_STAMP_SUCCEED";
export const UPDATE_PAGE_TIME_STAMP_FAILURE = "UPDATE_PAGE_TIME_STAMP_FAILURE";

export const FETCH_PAGE_TIME_STAMP = "FETCH_PAGE_TIME_STAMP";
export const FETCH_PAGE_TIME_STAMP_SUCCEED = "FETCH_PAGE_TIME_STAMP_SUCCEED";
export const FETCH_PAGE_TIME_STAMP_FAILURE = "FETCH_PAGE_TIME_STAMP_FAILURE";

export const FETCH_UNSEEN_NOTIFICATIONS = "FETCH_UNSEEN_NOTIFICATIONS";
export const FETCH_UNSEEN_NOTIFICATIONS_SUCCEED =
  "FETCH_UNSEEN_NOTIFICATIONS_SUCCEED";
export const FETCH_UNSEEN_NOTIFICATIONS_FAILURE =
  "FETCH_UNSEEN_NOTIFICATIONS_FAILURE";

// redux store constants
export const ADD_REQUEST = "ADD_REQUEST";
export const REMOVE_REQUEST = "REMOVE_REQUEST";
export const ADD_CAR = "ADD_CAR";
export const REMOVE_CAR = "REMOVE_CAR";

export const CLEAR_NEW_REQUESTS = "CLEAR_NEW_REQUESTS";
export const CLEAR_NEW_CARS = "CLEAR_NEW_CARS";
export const CLEAR_REMOVED_REQUESTS = "CLEAR_REMOVED_REQUESTS";
export const CLEAR_REMOVED_CARS = "CLEAR_REMOVED_CARS";

export const FILTER_FORMS = "FILTER_FORMS";
export const FILTER_FORMS_OK = "FILTER_FORMS_OK";

// Eagle Eye constants
export const GET_MAP = "GET_MAP";
export const GET_MAP_SUCCEED = "GET_MAP_SUCCEED";
export const GET_MAP_FAILURE = "GET_MAP_FAILURE";

export const UPDATE_CAR_POINTS = "UPDATE_CAR_POINTS";
export const UPDATE_CAR_POINTS_SUCCEED = "UPDATE_CAR_POINTS_SUCCEED";
export const UPDATE_CAR_POINTS_FAILURE = "UPDATE_CAR_POINTS_FAILURE";

export const CALCULATE_EAGLE_EYE = "CALCULATE_EAGLE_EYE";
export const CALCULATE_EAGLE_EYE_SUCCEED = "CALCULATE_EAGLE_EYE_SUCCEED";
export const CALCULATE_EAGLE_EYE_FAILURE = "CALCULATE_EAGLE_EYE_FAILURE";

// Web View constants
export const SET_APP_MESSAGE = "SET_APP_MESSAGE";
// Time per form
export const TOTAL_TIME = "TOTAL_TIME";
export const TOTAL_TIME_SUCCEED = "TOTAL_TIME_SUCCEED";
export const TOTAL_TIME_FAILURE = "TOTAL_TIME_FAILURE";

// DTCFaults

export const DTC_FAULTS = "DTC_FAULTS";
export const DTC_FAULTS_SUCCEED = "DTC_FAULTS_SUCCEED";
export const DTC_FAULTS_FLUSH = "DTC_FAULTS_FLUSH";
export const DTC_FAULTS_FAILURE = "DTC_FAULTS_FAILURE";

export const UPDATE_MAPPED = "UPDATE_MAPPED";
export const UPDATE_MAPPED_SUCCEED = "UPDATE_MAPPED_SUCCEED";
export const UPDATE_MAPPED_FAILURE = "UPDATE_MAPPED_FAILURE";

export const INITIATING_PAPRAZZI = "INITIATING_PAPRAZZI";
export const INITIATING_PAPRAZZI_SUCCEED = "INITIATING_PAPRAZZI_SUCCEED";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCEED = "SEND_OTP_SUCCEED";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCEED = "VALIDATE_OTP_SUCCEED";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

// Time Slots

export const GET_TIME_SLOTS_REQUEST = "GET_TIME_SLOTS_REQUEST";
export const GET_TIME_SLOTS_REQUEST_SUCCEED = "GET_TIME_SLOTS_REQUEST_SUCCEED";
export const GET_TIME_SLOTS_REQUEST_FAILURE = "GET_TIME_SLOTS_REQUEST_FAILURE";

export const CREATE_TIME_SLOT_REQUEST = "CREATE_TIME_SLOT_REQUEST";
export const CREATE_TIME_SLOT_REQUEST_SUCCEED =
  "CREATE_TIME_SLOT_REQUEST_SUCCEED";
export const CREATE_TIME_SLOT_REQUEST_FAILURE =
  "CREATE_TIME_SLOT_REQUEST_FAILURE";

export const UPDATE_TIME_SLOT_REQUEST = "UPDATE_TIME_SLOT_REQUEST";
export const UPDATE_TIME_SLOT_REQUEST_SUCCEED =
  "UPDATE_TIME_SLOT_REQUEST_SUCCEED";
export const UPDATE_TIME_SLOT_REQUEST_FAILURE =
  "UPDATE_TIME_SLOT_REQUEST_FAILURE";

export const GET_AREA_GROUPS_REQUEST = "GET_AREA_GROUPS_REQUEST";
export const GET_AREA_GROUPS_REQUEST_SUCCEED =
  "GET_AREA_GROUPS_REQUEST_SUCCEED";
export const GET_AREA_GROUPS_REQUEST_FAILURE =
  "GET_AREA_GROUPS_REQUEST_FAILURE";
