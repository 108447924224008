// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import {
  CONNECTION_REQUEST_SUCCEED,
  CLEAR_NEW_REQUESTS,
  CLEAR_NEW_CARS,
  CLEAR_REMOVED_REQUESTS,
  CLEAR_REMOVED_CARS,
  ADD_CAR,
  REMOVE_CAR,
  ADD_REQUEST,
  REMOVE_REQUEST,
  FETCH_PAGE_TIME_STAMP_SUCCEED,
  FETCH_UNSEEN_NOTIFICATIONS_SUCCEED,
} from "../constants";

const initialState = {
  data: {},
  removedRequests: [],
  removedCars: [],
  incReqStamp: "",
  carListStamp: "",
  unseenIncRequests: 0,
  unseenCarListings: 0,
  working: false,
};

export default createReducer(initialState, {
  [ADD_CAR]: (state, payload) => {
    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: state.unseenCarListings + 1,
    });
  },
  [REMOVE_CAR]: (state, payload) => {
    let removed_cars = state.removedCars;
    removed_cars.push(payload.instance);
    let removed = 0;
    if (new Date(payload.finished_at) - new Date(state.carListStamp) > -1) {
      removed = state.unseenCarListings - 1;
    } else {
      removed = state.unseenCarListings;
    }
    return Object.assign({}, state, {
      data: payload,
      unseenCarListings: removed,
      removedCars: removed_cars,
    });
  },
  [ADD_REQUEST]: (state, payload) => {
    return Object.assign({}, state, {
      data: payload,
      unseenIncRequests: state.unseenIncRequests + 1,
    });
  },
  [REMOVE_REQUEST]: (state, payload) => {
    let removed_requests = state.removedRequests;
    removed_requests.push(payload.instance);
    let removed = 0;
    if (new Date(payload.created_at) - new Date(state.incReqStamp) > -1) {
      removed = state.unseenIncRequests - 1;
    } else {
      removed = state.unseenIncRequests;
    }
    return Object.assign({}, state, {
      data: payload,
      unseenIncRequests: removed,
      removedRequests: removed_requests,
    });
  },
  [CLEAR_NEW_REQUESTS]: (state) => {
    return Object.assign({}, state, {
      unseenIncRequests: 0,
    });
  },
  [CLEAR_NEW_CARS]: (state) => {
    return Object.assign({}, state, {
      unseenCarListings: 0,
    });
  },
  [CLEAR_REMOVED_REQUESTS]: (state) => {
    return Object.assign({}, state, {
      removedRequests: [],
    });
  },
  [CLEAR_REMOVED_CARS]: (state) => {
    return Object.assign({}, state, {
      removedCars: [],
    });
  },
  [FETCH_PAGE_TIME_STAMP_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      //  new state for stamps
      incReqStamp: payload["inc_req_stamp"],
      carListStamp: payload["car_list_stamp"],
    });
  },
  [FETCH_UNSEEN_NOTIFICATIONS_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      unseenIncRequests: payload.unseen_incoming_listings,
      unseenCarListings: payload.unseen_cars,
    });
  },
});
