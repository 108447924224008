import React from "react";

const FeatureIcon58 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M42.1 2.34999C40.2 2.69999 37.2 6.14999 36 9.29999C34.6 13 33 21.25 33 24.6C33 26.85 32.9 27.1 32 26.85C31.25 26.65 31 26.85 31 27.55C31 28.55 32.4 29.5 33.9 29.5C34.75 29.5 34.9 29 35.15 24.15C35.5 18.55 36.9 11.65 38.3 8.84999C39.5 6.44999 41.45 4.59999 43.1 4.24999C44.75 3.84999 46 4.64999 46 5.99999C46 6.49999 43.9 12.95 41.3 20.3C38.15 29.2 36.2 34 35.55 34.55C33.8 36 34.55 37.55 36.5 36.5C37.9 35.75 38.2 35.15 43.35 20.55C48.85 4.94999 48.95 4.24999 45.95 2.74999C44.55 1.99999 44.2 1.94999 42.1 2.34999Z" fill="#07303E"/>
<path d="M7.10004 16.15C5.15004 18.15 5.00004 19.2 6.75004 18.9C7.85004 18.65 8.00004 18.8 8.00004 20C8.00004 20.75 8.15004 22.1 8.30004 22.95C8.55004 24 8.45004 24.5 8.00004 24.5C6.55004 24.5 4.05004 26.2 3.50004 27.55C2.60004 29.7 3.30004 33.25 4.95004 35.15C6.20004 36.5 6.75004 36.7 9.65004 36.9C13.45 37.15 13.3 36.8 13.8 44.6C14.1 48.9 14.25 49.5 15.1 49.5C15.85 49.5 16 49.1 16 46.7C16 41.65 15 36.9 12.95 32.5C11.2 28.6 10.05 23.7 10 19.95C10 18.8 10.15 18.65 11.25 18.9C13.05 19.2 12.85 18.05 10.85 16.1C9.95004 15.2 9.10004 14.5 8.95004 14.5C8.80004 14.5 8.00004 15.25 7.10004 16.15ZM9.20004 28.1C9.35004 29 10.05 30.95 10.75 32.35L11.95 35H9.80004C6.85004 35 5.30004 33.35 5.30004 30.15C5.25004 28.15 5.45004 27.7 6.50004 27.15C8.40004 26.2 8.85004 26.35 9.20004 28.1Z" fill="#07303E"/>
<path d="M16.1 16.15C14.15 18.15 14 19.2 15.75 18.9C16.85 18.65 17 18.8 17 20.2C17 21.05 17.15 22.25 17.3 22.85C17.6 23.95 17.45 24 15.05 24C12.85 24 12.5 24.15 12.5 25C12.5 25.85 12.85 26 14.65 26C17.35 26 17.65 26.25 18.45 29C18.8 30.25 19.55 32.1 20.05 33.1L21 35H18.7C16.8 35 16.45 35.15 16.6 35.85C16.7 36.55 17.35 36.8 19.35 36.9C22.35 37.05 22.3 36.95 22.8 44.6C23.1 48.9 23.25 49.5 24.1 49.5C24.85 49.5 25 49.1 25 46.7C25 41.65 24 36.9 21.95 32.5C20.2 28.6 19.05 23.7 19 19.95C19 18.8 19.15 18.65 20.25 18.9C22.05 19.2 21.85 18.05 19.85 16.1C18.95 15.2 18.1 14.5 17.95 14.5C17.8 14.5 17 15.25 16.1 16.15Z" fill="#07303E"/>
<path d="M25.1001 16.15C23.1501 18.15 23.0001 19.2 24.7001 18.9C25.8001 18.65 25.9001 18.85 26.2001 21.9C26.4001 23.65 26.4501 25.2 26.3501 25.3C26.2501 25.4 25.1001 25.3 23.8001 25.05C21.5501 24.65 21.4501 24.65 21.6001 25.7C21.7001 26.55 22.2501 26.85 24.3501 27.2C26.7501 27.6 27.0001 27.75 27.5501 29.45C27.8501 30.45 28.5501 32.1 29.1001 33.1L30.0001 35H27.7001C25.8001 35 25.4501 35.15 25.6001 35.85C25.7001 36.55 26.3501 36.8 28.3501 36.9C31.3501 37.05 31.3001 36.95 31.8001 44.6C32.1001 48.9 32.2501 49.5 33.1001 49.5C33.8501 49.5 34.0001 49.1 34.0001 46.7C34.0001 41.65 33.0001 36.9 30.9501 32.5C29.2001 28.6 28.0501 23.7 28.0001 19.95C28.0001 18.8 28.1501 18.65 29.2501 18.9C31.0501 19.2 30.8501 18.05 28.8501 16.1C27.9501 15.2 27.1001 14.5 26.9501 14.5C26.8001 14.5 26.0001 15.25 25.1001 16.15Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon58;
