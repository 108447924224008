import React, { useEffect, useState } from "react";
// import PopupCard from "../containers/ListingRequests/PopupCard";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  clearAnswers,
  updateAnswers,
  getMap,
  updateCarPoints,
} from "../store/actions/forms";

import { getCarForms, getCar } from "../store/actions/car";
import EagleEyeField from "../components/EagleEyeField";

const useStyles = makeStyles((theme) => ({}));

const EagleEyeForm = (props) => {
  const classes = useStyles();

  const {
    carId,
    getMap,
    updateCarPoints,
    bodyTypeConfig,
    clearAnswers,
    updateAnswers,
    getCar,
    getCarForms,
    subforms,
    answers,
    loadingForm,
    submitting,
    setSubformloading,
    subformloading,
    formId,
    // eeSubmitAll,
    saved,
  } = props;
  const [mapPoints, setMapPoints] = useState([]); // db map rows
  const [mapFields, setMapFields] = useState([]); //
  const [answersArrived, setAnswersArrived] = useState(false);
  const [backendAnswers, setBackendAnswers] = useState({});
  const [fetchedSubformsCount, setFetchedSubformsCount] = useState(0);
  const [rehydrateSubformState, setRehydrateSubformState] = useState({});

  useEffect(() => {
    if (submitting == false) {
      clearAnswers();
      getCar({
        // to get bodytypeconfig if changed used to cause problems but seezed all of a sudden dont know why but keep just in case
        id: carId,
        callback: (car) => {
          getCarForms({
            formId: 2,
            carId: carId,
            callback: (result) => {
              setAnswersArrived(true);
              setBackendAnswers(result[0].form_answers);
            },
          });
          getMap({
            bodyTypeConfigId: bodyTypeConfig.id,
            callback: (response) => {
              setMapPoints(response.results);
            },
          });
          subformloading !== undefined && setSubformloading(false);
        },
      });
    }
  }, [submitting]);
  useEffect(() => {
    if (subforms.length !== 0 && subforms[0].form === 2) {
      let mapFieldsCreation = [];
      subforms.map((subform) => {
        let points = mapPoints.filter((point) => {
          return point.subform === subform.id;
        });
        mapFieldsCreation.push({
          title: subform.title,
          subformId: subform.id,
          points: points,
        });
      });
      setMapFields(mapFieldsCreation);
    }
  }, [subforms, mapPoints]);

  // Removed saved rehydrate because it is not necessary anymore due to new inspection application
  // This caused old answers to show if the form was entered prevously even if answeres changed in BE
  // Kept it for reference, if we needed to activate it again
  // useEffect(() => {
  //   // rehydrates form data if exist in saved **Does not submit
  //   if (fetchedSubformsCount == subforms.length) {
  //     // count increases every time a subform updates it's answers due to fetched data
  //     const formSavedObject = saved?.[carId]?.[formId];
  //     if (formSavedObject) {
  //       const formSavedAnswers = formSavedObject?.answers;
  //       if (formSavedAnswers) {
  //         updateAnswers({ answers: formSavedAnswers });
  //         setRehydrateSubformState(formSavedAnswers); // used to send saved answers to EEField to update it's subformAnswers state
  //       }
  //     }
  //   }
  // }, [fetchedSubformsCount, carId]); // carId not necessary

  const handleSubformSubmit = (subformAnswers) => {
    updateCarPoints({ carId: carId, points: subformAnswers });
  };

  return (
    <>
      {mapFields.map((mapField) => (
        <>
          <EagleEyeField
            subformTitle={mapField.title}
            subformId={mapField.subformId}
            points={mapField.points}
            answers={answers}
            answersArrived={answersArrived}
            handleSubformSubmit={handleSubformSubmit}
            bodyTypeConfig={bodyTypeConfig}
            backendSubFormAnswers={backendAnswers[mapField.subformId]}
            updateAnswers={props.updateAnswers}
            carId={carId}
            setFetchedSubformsCount={setFetchedSubformsCount}
            rehydrateSubformState={rehydrateSubformState}
            subforms={subforms}
          />
        </>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  bodyTypeConfig: state.car.car.body_type_config,
  subforms: state.forms.subforms,
  answers: state.forms.answers,
  saved: state.forms.saved,
});

const mapDispatchToProps = (dispatch) => ({
  clearAnswers(payload) {
    dispatch(clearAnswers(payload));
  },
  updateAnswers(payload) {
    dispatch(updateAnswers(payload));
  },
  getMap(payload) {
    dispatch(getMap(payload));
  },
  updateCarPoints(payload) {
    dispatch(updateCarPoints(payload));
  },
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getCarForms(payload) {
    dispatch(getCarForms(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EagleEyeForm);
