import React from "react";

const FeatureIcon76 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M44.8242 7.17774C46.748 7.65625 48.1836 8.99414 48.8574 10.9375L49.0722 11.5723L49.1015 23.9746C49.1211 32.334 49.0918 36.5918 49.0234 37.041C48.6523 39.4629 46.9922 41.2598 44.6679 41.748C43.6523 41.9629 6.24998 41.9629 5.23435 41.748C2.91013 41.2598 1.24998 39.4629 0.878883 37.041C0.810524 36.5918 0.781227 32.334 0.800758 23.9746L0.830055 11.5723L1.0449 10.9375C1.65037 9.19922 2.90037 7.91016 4.58982 7.30469L5.22459 7.08008L24.7558 7.06055C40.9082 7.05079 44.3847 7.07032 44.8242 7.17774ZM5.14646 8.69141C3.97459 9.05274 3.02732 9.97071 2.50974 11.2402L2.2949 11.7676L2.2656 23.9746C2.24607 32.3438 2.27537 36.3965 2.34373 36.8652C2.40232 37.2363 2.55857 37.7832 2.68552 38.0664C2.97849 38.7012 3.75974 39.5313 4.37498 39.873C5.45896 40.459 4.2285 40.4297 24.9512 40.4297C45.6738 40.4297 44.4433 40.459 45.5273 39.873C46.1426 39.5313 46.9238 38.7012 47.2168 38.0664C47.3437 37.7832 47.5 37.2656 47.5488 36.9141C47.6953 36.0352 47.6953 12.8906 47.5488 12.0117C47.3047 10.4492 46.2207 9.15039 44.7949 8.70118C44.1992 8.51563 43.7597 8.50586 24.9902 8.50586C5.99607 8.4961 5.77146 8.4961 5.14646 8.69141Z" fill="#07303E"/>
<path d="M43.3887 10.6445C44.2481 10.791 44.8926 11.2988 45.3711 12.207C45.5469 12.5391 45.5566 13.0371 45.5859 24.209C45.6152 37.041 45.6348 36.4355 45.0195 37.2754C44.8145 37.5488 44.5117 37.7832 44.1113 37.9883L43.5059 38.2812L24.8535 38.2617C6.63086 38.2324 6.19141 38.2324 5.82032 38.0469C5.30274 37.7832 4.80469 37.2656 4.55079 36.7285L4.34571 36.2793V24.4141V12.5488L4.56055 12.1484C4.91211 11.4941 5.24414 11.1621 5.83985 10.8691L6.39649 10.5957L24.6582 10.5859C34.707 10.5762 43.125 10.6055 43.3887 10.6445ZM6.17188 12.4414L5.81055 12.7734L5.78125 24.1895C5.76172 32.041 5.78125 35.7031 5.85938 35.957C5.91797 36.1523 6.09375 36.4258 6.25977 36.5625L6.5625 36.8164H24.9512H43.3398L43.6426 36.5625C43.8086 36.4258 43.9844 36.1523 44.043 35.957C44.1211 35.7031 44.1406 32.041 44.1211 24.1895L44.0918 12.7734L43.7305 12.4414L43.3594 12.1094H24.9512H6.54297L6.17188 12.4414Z" fill="#07303E"/>
<path d="M33.5449 22.0801C33.8477 22.2559 42.5781 30.9766 42.7344 31.2695C43.0078 31.7871 42.6563 32.3242 42.0508 32.3242C41.7871 32.3242 41.1817 31.748 37.1777 27.7539C34.668 25.2441 32.5879 23.0957 32.5586 22.9687C32.461 22.6172 32.5977 22.2754 32.9102 22.1191C33.2422 21.9434 33.291 21.9434 33.5449 22.0801Z" fill="#07303E"/>
<path d="M16.2109 22.041C16.5332 22.1582 16.6992 22.4023 16.6992 22.7441C16.6992 23.0762 16.4648 23.3301 12.0801 27.7148C7.90039 31.8848 7.43164 32.3242 7.14844 32.3242C6.73828 32.3242 6.44531 32.0312 6.44531 31.6113C6.44531 31.3281 6.86523 30.8789 11.0547 26.6894C13.5938 24.1602 15.7617 22.0703 15.8691 22.041C15.9766 22.0117 16.1328 22.0117 16.2109 22.041Z" fill="#07303E"/>
<path d="M31.3183 23.3496C32.041 24.0527 32.6464 24.7265 32.6757 24.8633C32.7734 25.2441 32.4414 25.625 31.9921 25.664C31.6406 25.6933 31.5722 25.6445 30.5273 24.6191L29.4335 23.5351H28.5253C27.9296 23.5351 27.539 23.4863 27.3535 23.3886C26.9042 23.164 26.8261 22.7441 27.1582 22.3242C27.3535 22.0703 27.3632 22.0703 28.6914 22.0703H30.0292L31.3183 23.3496Z" fill="#07303E"/>
<path d="M22.2656 22.2656C22.5097 22.5098 22.5195 22.959 22.2753 23.252C22.1093 23.4668 21.9921 23.4863 21.0546 23.5352L20.0195 23.584L18.9062 24.6289C17.9882 25.498 17.7343 25.6836 17.4902 25.6836C17.0898 25.6836 16.7968 25.3809 16.7968 24.9707C16.7968 24.6875 16.9531 24.4922 18.0956 23.3594L19.4042 22.0703H20.7323C21.9433 22.0703 22.0898 22.0898 22.2656 22.2656Z" fill="#07303E"/>
<path d="M35.8594 27.8027C37.3438 29.2676 37.4414 29.4629 36.9629 29.9316C36.5235 30.3809 36.3086 30.2832 35.0586 29.0527L33.9258 27.9297H32.2461C30.6934 27.9297 30.5469 27.9102 30.3711 27.7344C30.1074 27.4707 30.1074 26.9238 30.3906 26.6602C30.586 26.4746 30.7227 26.4648 32.5586 26.4648H34.502L35.8594 27.8027Z" fill="#07303E"/>
<path d="M19.0918 26.709C19.2285 26.8359 19.3359 27.0605 19.3359 27.1973C19.3359 27.334 19.2285 27.5586 19.0918 27.6855C18.8574 27.9297 18.8281 27.9297 17.2363 27.9297H15.6152L14.4043 29.0527C13.7402 29.668 13.125 30.1758 13.0469 30.1758C12.5293 30.1758 12.1191 29.6387 12.2852 29.1895C12.3437 29.0625 12.9492 28.3984 13.6523 27.7051L14.9219 26.4648H16.8848C18.8477 26.4648 18.8574 26.4648 19.0918 26.709Z" fill="#07303E"/>
<path d="M40.9375 32.793C42.4707 34.3262 42.6758 34.5605 42.6758 34.8438C42.6758 35.2637 42.3828 35.5469 41.9336 35.5469C41.6016 35.5469 41.4551 35.4297 40.0488 34.0332L38.5156 32.5195H36.8945C35.0879 32.5195 34.9219 32.4707 34.8047 31.8652C34.7559 31.6113 34.7949 31.5039 34.9902 31.3086L35.2441 31.0547H37.2266H39.1992L40.9375 32.793Z" fill="#07303E"/>
<path d="M14.4336 31.2793C14.7168 31.582 14.707 31.9824 14.4043 32.2754C14.1699 32.5195 14.1406 32.5195 12.5293 32.5195H10.8984L9.36524 34.043C7.88087 35.5176 7.82228 35.5566 7.47072 35.5273C6.9922 35.4883 6.6797 35.1074 6.77736 34.6973C6.82619 34.5215 7.43165 33.8379 8.53517 32.7344L10.2246 31.0547H12.2266C14.1895 31.0547 14.2285 31.0547 14.4336 31.2793Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon76;
