import React from "react";

const FeatureIcon35 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M27.8779 4.34577C28.0366 3.97116 28.125 3.55836 28.125 3.12505C28.125 1.39924 26.7258 0 25 0C23.2742 0 21.875 1.39924 21.875 3.12505C21.875 3.55845 21.9634 3.97116 22.1221 4.34577C10.8244 5.76103 2.08246 15.4017 2.08246 27.084C2.08246 39.7399 12.3427 50 25 50C37.6573 50 47.9175 39.7399 47.9175 27.084C47.9175 15.4017 39.1756 5.76103 27.8779 4.34577ZM25 2.08363C25.5737 2.08363 26.0422 2.55053 26.0422 3.12505C26.0422 3.69957 25.5737 4.16647 25 4.16647C24.4263 4.16647 23.9578 3.69957 23.9578 3.12505C23.9578 2.55053 24.4263 2.08363 25 2.08363ZM39.7311 41.8151C37.8176 43.7286 35.5898 45.2316 33.1086 46.2814C30.8595 47.232 28.4867 47.7722 26.0422 47.8912V45.8343C26.0422 45.259 25.5753 44.7921 25 44.7921C24.4247 44.7921 23.9578 45.259 23.9578 45.8343V47.8913C21.5133 47.7722 19.1405 47.2321 16.8914 46.2815C14.4103 45.2317 12.1824 43.7286 10.2689 41.8152C8.35394 39.9017 6.85245 37.6739 5.80263 35.1927C4.85203 32.9436 4.31179 30.5708 4.19128 28.1248H6.24971C6.82501 28.1248 7.29191 27.6593 7.29191 27.0841C7.29191 26.5089 6.82501 26.0418 6.24971 26.0418H4.19128C4.31179 23.5966 4.85203 21.2238 5.80263 18.9754C6.85245 16.4943 8.35394 14.2664 10.2689 12.3522C12.1824 10.438 14.4102 8.93578 16.8914 7.88665C19.1405 6.93527 21.5133 6.39512 23.9578 6.27529V8.33372C23.9578 8.90903 24.4247 9.37515 25 9.37515C25.5753 9.37515 26.0422 8.90903 26.0422 8.33372V6.27529C28.4867 6.39512 30.8595 6.93527 33.1086 7.88665C35.5897 8.93569 37.8176 10.438 39.7311 12.3522C41.6461 14.2665 43.1475 16.4943 44.1974 18.9754C45.148 21.2238 45.6882 23.5966 45.8087 26.0418H43.7503C43.175 26.0418 42.7081 26.5087 42.7081 27.084C42.7081 27.6593 43.175 28.1247 43.7503 28.1247H43.7519H45.8088C45.6883 30.5707 45.1481 32.9435 44.1975 35.1926C43.1475 37.6737 41.6461 39.9016 39.7311 41.8151Z" fill="#07303E"/>
<path d="M27.2095 24.8737C26.6189 24.284 25.8347 23.959 25 23.959C24.1653 23.959 23.381 24.284 22.7905 24.8737C21.5713 26.0922 21.5713 28.0758 22.7905 29.2935C23.381 29.8841 24.1653 30.2091 25 30.2091C25.8347 30.2091 26.6189 29.8841 27.2095 29.2935C27.8 28.703 28.125 27.9187 28.125 27.084C28.125 26.2494 27.8 25.4643 27.2095 24.8737ZM25.737 27.8195C25.5325 28.024 25.267 28.1247 25 28.1247C24.733 28.1247 24.4674 28.024 24.2629 27.8195C23.8571 27.4136 23.8571 26.7544 24.2629 26.347C24.4674 26.1441 24.733 26.0418 25 26.0418C25.267 26.0418 25.5325 26.1441 25.737 26.347C26.1429 26.7544 26.1429 27.4136 25.737 27.8195Z" fill="#07303E"/>
<path d="M19.107 21.1911L12.5334 39.5507L30.893 32.9771L37.4667 14.6174L19.107 21.1911ZM29.2633 31.3475L15.9803 36.1022L20.7365 22.8208L34.0196 18.0645L29.2633 31.3475Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon35;
