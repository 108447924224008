// hidder is the element that used to hide componenets and focuses on specific element with id outline
import { Button, Hidden, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Info from "../icons/Information.svg";
import { connect } from "react-redux";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import theme from "../theme";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MarkabteTooltip from "./MarkabteTooltip";
import { Portrait } from "@material-ui/icons";
import Visualisation from "./CosmeticConditionProto";
const useStyles = makeStyles((theme) => ({
  mainDiv: {},
  selectDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "37px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "32.52px",
    },
  },
  tooltip: {
    backgroundColor: "white",
    color: "rgb(99,99,99)",
    border: "1px solid #000000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    fontSize: "12px",
    padding: "15px",
    maxWidth: "327px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      transformOrigin: (isPortrait) => (isPortrait ? "top left" : ""),
      transform: (isPortrait) => (isPortrait ? "rotate(90deg)" : ""),
      fontSize: "8px",
      padding: "8px",
      maxWidth: "218px",
    },
  },
  tooltipDark: {
    backgroundColor: "#000000dd",
    color: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    fontSize: "16px",
    padding: "8px",
    maxWidth: "327px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      transform: (isPortrait) => (isPortrait ? "rotate(90deg)" : ""),
      padding: (isPortrait) => (isPortrait ? "18px" : ""),
      fontSize: "8px",
      // padding: "8px",
      maxWidth: "218px",
    },
  },
  tooltipArrowDark: {
    "&::before": {
      backgroundColor: "#000000dd",
      width: "95%",
    },
  },
  tooltipArrow: {
    "&::before": {
      backgroundColor: "white",
      border: "1px solid #000000",
      width: "95%",
    },
  },
  tooltipTypographyDark: {
    fontSize: "10px",
    fontWeight: 500,
    // backgroundColor: "#000000ee",
    fontSize: "16px",
    color: "#fff",
    width: "180px",
    display: "flex",
  },
  tooltipTypography: {
    fontSize: "10px",
    fontWeight: "700",
    color: "#000",
    width: "180px",
    display: "flex",
  },
  infoIcon: {
    position: "absolute",
    zIndex: 1000,
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      width: "26px",
      height: "26px",
      margin: "auto 0 auto auto",
    },
  },
  main: {
    position: "relative",
    overflow: "hidden",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
    },
  },
  arrowIcons: {
    fill: "#283949",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    height: "fit-content",
    top: 0,
    position: "absolute",
    zIndex: "10",
  },
  tourerTooltip: {
    width: "240px",
    pointerEvents: "all",
    [theme.breakpoints.down("sm")]: {
      width: "217px",
      "& .MuiIconButton-root": {
        padding: "4px",
      },
    },
  },
  weird: {},
  description: {
    fontWeight: "400",
    lineHeight: "1.4",
    fontSize: ".8rem",
  },
}));

function Tour(props) {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const classes = useStyles(isPortrait);
  const { points, setRecallGroupsToggle, previouslyUnlocked } = props;
  const [mobile, setmobile] = useState(window.screen.availWidth < 959); //check if device is mobile
  const [run, setrun] = useState(false);
  const [open, setOpen] = React.useState(null);
  const handleTooltipClose = () => {
    setOpen(null);
  };
  const [tooltipStyle, setTooltipStyle] = useState({
    display: "none",
    transform: isPortrait ? "rotate(90deg)" : "",
  });
  var ids = mobile
    ? ["coco", "sidesMob", "issuesMob", "mobPoint"]
    : ["coco", "sides", "issues", "point"];
  var elements = [];
  for (var i of ids) {
    elements.push(document.getElementById(i));
  }
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [nextStepper, setNextStepper] = useState(-1);
  const [toolTipDir, setToolTipDir] = useState("right");
  const [lang, setlang] = useState(localStorage.getItem("language"));
  const [tooltipStartButtonVisability, setTooltipStartButtonVisability] =
    useState({ loading: true, drawerOpened: false });
  const tooltipstarter = useRef(null);
  const avaWidth = window.screen.availWidth;
  const avaHeight = window.screen.availHeight;
  const [outLinedStyle, setOutLinedStyle] = useState({
    //this state contains the style for hidder you can find the discriton of terminology hidder at line 0
    position: "absolute",
    width: "100px",
    height: "70px",
    // border: "17px solid #fffff0",
    borderRadius: "5px",
    boxShadow: "0px 0px 1000px #000",
    top: "0px",
    outline: "10000px solid #000000aa",
    // backgroundColor: "#000000dd",
    zIndex: 1000,
    PointerEvent: "none",
  });
  var tooltip = document.getElementById("tooltip");
  function isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  //the terminology hidder described in line 0 #0h
  var sidesMobHider = document.getElementById("sidesMobHider");
  const carViews = (elements) => {
    //this function represents the car Views (sides) bar location and make hidder in the same position
    setTitle("Car Views"); //this settes tooltip title
    setDescription(
      //this settes tooltip descrition
      "Click on the views to check the car's condition from a specific view."
    );
    elements[2].style.zIndex = "";
    elements[1].style.zIndex = "2101";
    if (mobile) {
      tooltipPositingMobile(elements[1], "carViews");
    }
    // alert(Math.round(elements[1]?.getBoundingClientRect().x));

    var leftDestance = mobile
      ? Math.round(elements[1]?.getBoundingClientRect().x)
      : elements[1].offsetLeft;
    var topDestance = mobile
      ? Math.round(elements[1]?.getBoundingClientRect().y)
      : 0;
    setOutLinedStyle({
      ...outLinedStyle,
      top: topDestance + "px",
      right: "",
      left: leftDestance + "px", //elements[1].offsetLeft
      width:
        isPortrait && mobile
          ? elements[1].offsetHeight + "px"
          : elements[1].offsetWidth + "px",
      height:
        isPortrait && mobile
          ? elements[1].offsetWidth + "px"
          : elements[1].offsetHeight + "px",
      borderRadius: "5px",
    });
  };
  //the terminology hidder described in line 0 #0h
  const damageList = (elements) => {
    //this function represents the damage List bar location and make hidder in the same position
    setTitle("issues list"); //this settes tooltip title
    setDescription(
      //this settes tooltip descrition
      mobile
        ? "Click here to view the issue’s list of a certain view."
        : "You can view all the issues of the selected car view here."
    );
    if (mobile) {
      tooltipPositingMobile(elements[2], "damageList");
    }
    elements[1].style.zIndex = "";
    elements[2].style.zIndex = "2101";
    var leftBigScreenBalaning = "0px";
    var topBigScreenBalaning = "0px";
    if (mobile) {
      topBigScreenBalaning =
        Math.round(elements[2]?.getBoundingClientRect().y) - 5;
      leftBigScreenBalaning =
        Math.round(elements[2]?.getBoundingClientRect().x) - 5;
    } else {
      leftBigScreenBalaning =
        Math.round(elements[2]?.getBoundingClientRect().x) -
        document?.getElementById("mainTC")?.getBoundingClientRect().x;
    }
    setOutLinedStyle({
      ...outLinedStyle,
      top: `calc(${topBigScreenBalaning}px + ${0 + "px"})`,
      right: "",
      left: `calc(${leftBigScreenBalaning}px + ${0 + "px"})`,
      width: elements[2].offsetWidth + 10 + "px",
      height: elements[2].offsetHeight + 10 + "px",
      borderRadius: mobile ? "50%" : "5px",
    });
  };
  //the terminology hidder described in line 0 #0h
  const damageArea = (elements) => {
    //this function represents first hotspot location and make hidder in the same position
    setTitle("Damage Areas"); //this settes tooltip title
    setDescription(
      //this settes tooltip descrition
      "Click on the hotspots to see an up-close image of the damage."
    );
    elements[2].style.zIndex = "";
    if (elements[3]) elements[3].style.zIndex = "2101";
    else {
      endTour();
      return;
    }
    var windowScreen = window.screen.width; //1281
    var rightBigScreenBalaning = ""; // this variable contains the posistion of the first damage hotspot depending on breakpoints
    if (mobile && elements[3]) {
      tooltipPositingMobile(elements[3], "damageArea");
    }
    // this variable contains the posistion of the first damage hotspot
    var issuesListWidth = elements[3]?.offsetWidth - 10 + "px"; // -10px to center hidder look at line 0
    if (windowScreen > 1279) {
      //breakpoint change
      rightBigScreenBalaning =
        theme.direction === "ltr" ? "95px" : issuesListWidth;
    } else {
      rightBigScreenBalaning =
        theme.direction === "ltr" ? "105px" : issuesListWidth;
    }
    if (mobile) {
      setOutLinedStyle({
        ...outLinedStyle,
        left: `calc(${elements[3]?.getBoundingClientRect().x}px - 10px)`,
        right: "",
        top: `calc(${elements[3]?.getBoundingClientRect().y}px - 10px)`,
        width: `calc(20px + ${elements[3]?.style.width})`,
        height: `calc(20px + ${elements[3]?.style.height})`,
        borderRadius: "50%",
      });
    } else {
      var leftimageOffset = document
        .getElementById("visualizationImageMobile")
        .getBoundingClientRect().x;
      var leftParentOffset = document
        .getElementById("mainTC")
        .getBoundingClientRect().x;
      var outlineGap = leftimageOffset - leftParentOffset;
      setOutLinedStyle({
        ...outLinedStyle,
        left: "",
        right: `calc(${rightBigScreenBalaning} + ${
          document.getElementById("pointParent").getBoundingClientRect().right
        })`,
        top: `calc(${document.getElementById("pointParent").style.top})`,
        width: `calc( ${elements[3].style.width})`, //20px +
        height: `calc( ${elements[3].style.height})`, //20px +
        borderRadius: "50%",
        left: elements[3].getBoundingClientRect().x - leftParentOffset,
      });
    }
  };

  const tooltipPositingMobile = (TourPoint, part) => {
    var element = document.getElementById("tooltip");
    element.style.display = "block";
    var left = element?.getBoundingClientRect().left;
    var right = element?.getBoundingClientRect().right;
    var top = element?.getBoundingClientRect().top;
    var bottom = element?.getBoundingClientRect().bottom;
    var width = 220;
    var height = element?.getBoundingClientRect().height;
    var targetElemetPosition = TourPoint.getBoundingClientRect();
    var QuaWidth = 65;
    var halfWidth = 120;
    var threeQuart = 175;
    if (part == "damageList") {
      lang == "ar" ? setToolTipDir("top-right") : setToolTipDir("top-left");
      if (isPortrait) {
        setTooltipStyle({
          ...tooltipStyle,
          top: targetElemetPosition.top + QuaWidth + "px",
          left:
            lang == "ar"
              ? targetElemetPosition.left - halfWidth - QuaWidth
              : targetElemetPosition.left + "px",
          transform: lang == "ar" ? "rotate(-90deg)" : "rotate(90deg)",
        });
      } else {
        setTooltipStyle({
          ...tooltipStyle,
          top: targetElemetPosition.top - halfWidth + "px",
          left:
            lang == "ar"
              ? targetElemetPosition.right - width - 10
              : targetElemetPosition.left + "px",
          transform: "",
        });
      }
    }
    if (part == "carViews") {
      if (sidesMobHider) sidesMobHider.style.display = "none";
      lang == "ar" ? setToolTipDir("right") : setToolTipDir("left");
      if (isPortrait) {
        setTooltipStyle({
          ...tooltipStyle,
          top: targetElemetPosition.top - threeQuart + "px",
          left: 0,
          transform:
            lang == "ar"
              ? "translate(50px, 0px) rotate(-90deg)"
              : "translate(50px, 0px) rotate(90deg)",
        });
      } else {
        setTooltipStyle({
          ...tooltipStyle,
          top: 0,
          left:
            lang == "ar"
              ? targetElemetPosition.right
              : targetElemetPosition.left - width - 20 + "px",
          transform: "translate(0px, 50px)",
        });
      }
    } else {
      if (sidesMobHider) sidesMobHider.style.display = "block";
    }
    if (part == "damageArea") {
      if (isPortrait) {
        let dir = "";
        if (lang == "ar") {
          if (avaWidth / 2 > targetElemetPosition.left) {
            dir = "bottom-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "left";
            } else {
              dir += "right";
            }
          } else {
            dir = "top-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "left";
            } else {
              dir += "right";
            }
          }
        } else {
          if (avaWidth / 2 > targetElemetPosition.left) {
            dir = "top-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "right";
            } else {
              dir += "left";
            }
          } else {
            dir = "bottom-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "right";
            } else {
              dir += "left";
            }
          }
        }
        setToolTipDir(dir);

        setTooltipStyle({
          ...tooltipStyle,
          top:
            avaHeight / 2 > targetElemetPosition.top
              ? targetElemetPosition.top + 50 + "px"
              : targetElemetPosition.top - 150 + "px",
          left:
            avaWidth / 2 > targetElemetPosition.left
              ? targetElemetPosition.left - 20 + "px"
              : targetElemetPosition.left - 190,
          transform: lang == "ar" ? "rotate(-90deg)" : "rotate(90deg)",
        });
      } else {
        let dir = "";
        if (lang == "ar") {
          if (avaWidth / 2 > targetElemetPosition.left) {
            dir = "top-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "left";
            } else {
              dir += "right";
            }
          } else {
            dir = "bottom-";
            if (avaHeight / 2 < targetElemetPosition.top) {
              dir += "left";
            } else {
              dir += "right";
            }
          }
        } else {
          if (avaHeight / 2 > targetElemetPosition.top) {
            dir = "bottom-";
            if (avaWidth / 2 < targetElemetPosition.left) {
              dir += "right";
            } else {
              dir += "left";
            }
          } else {
            dir = "top-";
            if (avaWidth / 2 < targetElemetPosition.left) {
              dir += "right";
            } else {
              dir += "left";
            }
          }
        }
        setToolTipDir(dir);

        setTooltipStyle({
          ...tooltipStyle,
          top:
            avaHeight / 2 > targetElemetPosition.top
              ? targetElemetPosition.top + 30 + "px"
              : targetElemetPosition.top - 124 + "px",
          left:
            avaWidth / 2 > targetElemetPosition.left
              ? targetElemetPosition.left - 18 + "px"
              : targetElemetPosition.left - 200 + "px",
          transform: "",
        });
      }
    }
  };

  const endTour = () => {
    //this function ends the tour hide shadow and visible element and hide tooltip
    setrun(false);
    setNextStepper(-1);
    if (mobile) {
      setTooltipStyle({ display: "none" });
      if (elements[3]) elements[3].style.zIndex = "";
      if (TourerMobHider) TourerMobHider.style.display = "none";
      if (sidesMobHider) sidesMobHider.style.display = "none";
      if (tooltip) {
        tooltip.style.display = "none";
      }
      setTooltipStyle({});
    }
  };

  const dispatchNext = () => {
    //this function handle the next state of tour guide depending on its state index (nextStepper)
    if (nextStepper < 0) {
      setNextStepper((prev) => prev + 1);
    } else {
      if (nextStepper > 4) {
        endTour();
      } else {
        setNextStepper((prev) => prev + 1);
      }
    }
  };

  var TourerMobHider = document.getElementById("TourerMobHider");
  const startTour = () => {
    if (mobile) {
      if (TourerMobHider) TourerMobHider.style.display = "block";
      setTooltipStyle({ display: "block" });
    }
    //this function starts tour
    setrun(true); //run is flag used to open tooltip and make the outline "the shadow and visible part" element visible
    setNextStepper(0); //toggle between tour steppes
    !mobile && elements[0].scrollIntoView(); //make sceen focused on visualization part
  };

  useEffect(() => {
    endTour();
    // if (mobile && isPortrait) {
    //   setToolTipDir("bottom-end");
    // } else {
    //   setToolTipDir("right");
    // }
  }, [isPortrait]);

  useEffect(() => {
    //switch between views
    switch (nextStepper) {
      case 0:
        carViews(elements);
        break;
      case 1:
        damageList(elements);
        break;
      case 2:
        damageArea(elements);
        break;
      default:
        endTour(elements);
    }
  }, [nextStepper]);
  const [showToolTip, setshowToolTip] = useState(false);
  useEffect(() => {
    endTour();
    setTooltipStyle({ display: "none" });
    //switch between views
  }, []);

  const tooltipStartButtonStyle = () => {
    var style = {};
    var visImage = document.getElementById("visualizationImageMobile");
    // var sidesBar = ids?.[1].getBoundingClientRect().width;
    var visContainer = document.getElementById("visu");
    var topPos = "";
    var leftPos = "";
    var rightPos = "";

    var imageTop = "";
    var imageLeft = "";
    var imageRight = "";
    var visContainerLeft = "";
    var visImageWidth = "";

    if (visImage) {
      imageTop = visImage.getBoundingClientRect().top;
      imageLeft = visImage.getBoundingClientRect().left;
      imageRight = visImage.getBoundingClientRect().right;
      visImageWidth = document.getElementById("visualizationImageMobile").style
        .width;
    }
    if (visContainer) {
      visContainerLeft = visContainer.getBoundingClientRect().left;
    }
    if (mobile) {
      if (isPortrait) {
        if (lang == "ar") {
          style["transform"] = "rotate(-90deg)";
          style["left"] = "20px";
          if (isIOS()) {
            style["top"] = "10px";
          } else {
            style["bottom"] = `calc(50% - ${
              (window.screen.availWidth * 4) / 6
            }px ${isIOS() ? "- 70px" : "+ " + 83 + "px"})`;
          }
        } else {
          style["transform"] = "rotate(90deg)";
          style["right"] = "20px";
          if (isIOS()) {
            style["top"] = "10px";
          } else {
            style["bottom"] = `calc(50% - ${
              (window.screen.availWidth * 4) / 6
            }px ${isIOS() ? "+ 10px" : "+ " + 83 + "px"})`;
          }
        }
      } else {
        if (lang == "ar") {
          style["transform"] = "rotate(0deg)";
          style["left"] = `calc(50% - ${
            (window.screen.availHeight * 4) / 6
          }px ${isIOS() ? "+ 10px" : "+ " + 83 + "px"})`;
          style["top"] = "20px";
        } else {
          style["transform"] = "";
          style["right"] = `calc(50% - ${
            (window.screen.availHeight * 4) / 6
          }px ${isIOS() ? "+ 10px" : "+ " + 83 + "px"})`;
          style["top"] = "20px";
        }
      }
    } else {
      var spaceFromParent = imageLeft - visContainerLeft;
      style["transform"] = "rotate(0deg)";
      style["left"] = `calc(${spaceFromParent}px + ${20 + "px"})`;
      style["top"] = "20px";
    }
    return style;
  };

  const tooltipStartStyle = () => {
    var style = {};
    var visImage = document.getElementById("visualizationImageMobile");
    var visContainer = document.getElementById("visu");
    let tourStartButton = document.getElementById("tourStartButton");
    // `calc(${tourStartButton.top}px - ${42 + "px"})`
    var topPos = 0;
    var leftPos = 0;
    var rightPos = 0;
    if (tourStartButton) {
      topPos = tourStartButton.getBoundingClientRect().top;
      leftPos = tourStartButton.getBoundingClientRect().left;
      rightPos = tourStartButton.getBoundingClientRect().right;
    }
    var imageTop = "";
    var imageLeft = "";
    var imageRight = "";
    var visContainerLeft = "";
    var visImageWidth = "";
    if (visImage) {
      imageTop = visImage.getBoundingClientRect().top;
      imageLeft = visImage.getBoundingClientRect().left;
      imageRight = visImage.getBoundingClientRect().right;
    }
    if (visContainer) {
      visContainerLeft = visContainer.getBoundingClientRect().left;
    }
    if (mobile) {
      if (isPortrait) {
        if (lang == "ar") {
          style["transform"] = "rotate(-90deg)";
          style["left"] = "-42px";
          style["top"] = `calc(${topPos}px + ${120 + "px"})`; //isIOS() ? `calc(${topPos}px + ${120 + "px"})` : `34vh`;
          style["padding"] = "2px";
          style["borderRadius"] = "5px";
        } else {
          style["transform"] = "rotate(90deg)";
          style["right"] = "-44px";
          style["top"] = `calc(${topPos}px + ${120 + "px"})`; //isIOS() ? `calc(${topPos}px + ${120 + "px"})` : `20vh`;
          style["padding"] = "2px";
          style["borderRadius"] = "5px";
        }
      } else {
        if (lang == "ar") {
          /////////////
          style["transform"] = "rotate(0deg)";
          style["left"] = `calc(${leftPos}px + 54px)`;
          //150px + ${160 + "px"}
          // isIOS() `calc(${topPos}px + ${120 + "px"})`
          //   ? `calc(${leftPos}px - ${160 + "px"})`
          //   : `24vw`;
          style["top"] = "22px";
          style["padding"] = "2px";
          style["borderRadius"] = "5px";
        } else {
          style["transform"] = "";
          style["left"] = `calc(${leftPos}px - 150px - ${22 + "px"})`; //isIOS() ? `30vh` : `40vh`; 44
          style["top"] = "20px";
          style["padding"] = "2px";
          style["borderRadius"] = "5px";
        }
      }
    } else {
      var spaceFromParent = imageLeft - visContainerLeft;
      style["transform"] = "rotate(0deg)";
      style["left"] = `calc(${spaceFromParent}px + ${45 + "px"})`;
      style["top"] = "20px";
      style["padding"] = "2px";
      style["borderRadius"] = "5px";
    }
    return style;
  };

  var rotatedOffsetLeft = isPortrait ? 0 : 50;
  var rotatedOffsetTop = isPortrait ? 50 : 0;

  return (
    <div className={classes.main} id="mainTC">
      <div
        style={{
          width: !mobile ? "100%" : "100vw",
          height: !mobile ? "100%" : "100vh",
          position: mobile ? "fixed" : "absolute",
          pointerEvents: "none",
          left: "0",
          top: "0",
          zIndex: "1000",
        }}
      >
        <Hidden mdUp>
          <MarkabteTooltip dir={toolTipDir} style={tooltipStyle}>
            <div className={classes.tourerTooltip}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                style={{
                  left: theme.direction == "rtl" ? "0px" : "",
                  right: theme.direction == "ltr" ? "0px" : "",
                  // top: "-7px",
                }}
                onClick={() => endTour()}
              >
                <CloseIcon
                  style={{
                    background: "#F4F4F4",
                    color: "#07303E",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  position: "relative",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className={classes.tooltipTypography}
                  style={{
                    width: "180px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  {t(title)}
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <Typography className={classes.description}>
                  {t(description)}
                </Typography>
              </div>
              <div
                style={{
                  width: "105%",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button color="primary" onClick={() => dispatchNext()}>
                  <span style={{ fontSize: "14px" }}>
                    {t(nextStepper == elements.length - 2 ? "Exit" : "Next")}
                  </span>
                  {theme.direction === "ltr" ? (
                    <KeyboardArrowRight style={{ fill: "#0c6885" }} />
                  ) : (
                    <KeyboardArrowLeft style={{ fill: "#0c6885" }} />
                  )}
                </Button>
              </div>
            </div>
          </MarkabteTooltip>
        </Hidden>
        {!tooltipStartButtonVisability.loading &&
        !tooltipStartButtonVisability.drawerOpened &&
        !run &&
        !window.location.pathname.includes("carVis") ? (
          <div>
            <img
              onMouseEnter={() => setshowToolTip(true)}
              onMouseLeave={() => setshowToolTip(false)}
              id="tourStartButton"
              src={Info}
              className={classes.infoIcon}
              onClick={() => {
                setOpen(!open);
                startTour();
              }}
              value={4}
              style={{
                pointerEvents: "all",
                ...tooltipStartButtonStyle(),
              }}
            />
            <MarkabteTooltip
              showToolTip={showToolTip}
              setshowToolTip={setshowToolTip}
              id="elementTracker"
              elementToTrack="tourStartButton"
              dir={"left"}
              style={{
                display: "block",
                width: "150px",
                background: "#000000ee",
                color: "#fff",
                position: "absolute",
                textAlign: "center",
                ...tooltipStartStyle(),
              }}
            >
              {t("Click here for help")}
            </MarkabteTooltip>
          </div>
        ) : null}
        <Hidden smDown>
          <Tooltip
            title={
              <div className={classes.tourerTooltip}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  style={{
                    left: theme.direction == "rtl" ? "0px" : "",
                    right: theme.direction == "ltr" ? "0px" : "",
                  }}
                  onClick={() => endTour()}
                >
                  <CloseIcon
                    style={{
                      background: "#F4F4F4",
                      color: "#07303E",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>

                <div
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className={classes.tooltipTypography}
                    style={{
                      width: "180px",
                      display: "flex",
                      fontSize: "16px",
                    }}
                  >
                    {t(title)}
                  </Typography>
                </div>
                <div style={{ width: "100%" }}>
                  <Typography>{t(description)}</Typography>
                </div>
                <div
                  style={{
                    width: "105%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button color="primary" onClick={() => dispatchNext()}>
                    <span style={{ fontSize: "14px" }}>{t("Next")}</span>
                    {theme.direction === "ltr" ? (
                      <KeyboardArrowRight style={{ fill: "#0c6885" }} />
                    ) : (
                      <KeyboardArrowLeft style={{ fill: "#0c6885" }} />
                    )}
                  </Button>
                </div>
              </div>
            }
            arrow
            placement={toolTipDir} //"top" //"right"
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            onClose={handleTooltipClose}
            open={nextStepper >= 0 && nextStepper < 4}
          >
            <div //the hidder it self
              id="outline"
              style={{ display: run ? "block" : "none", ...outLinedStyle }}
            ></div>
          </Tooltip>
        </Hidden>
      </div>
      <Visualisation
        {...props}
        setTooltipStartButtonVisability={setTooltipStartButtonVisability}
        tooltipStartButtonVisability={tooltipStartButtonVisability}
        setRecallGroupsToggle={setRecallGroupsToggle}
        previouslyUnlocked={previouslyUnlocked}
      />
    </div>
  );
}

export default Tour;
