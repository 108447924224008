import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import colors from "../theme/colors";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgressPrimary: {
    color: colors.primary.first,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressSecondary: {
    color: colors.primary.second,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();
  const { loading, variant, disabled, fullWidth } = props;
  let necessaryProps = Object.assign({}, props);
  delete necessaryProps.loading;

  return (
    <div className={classes.wrapper}>
      <Button
        {...necessaryProps}
        fullWidth={fullWidth}
        disabled={loading || disabled}
      >
        {props.children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={
            variant === "primary"
              ? classes.buttonProgressSecondary
              : classes.buttonProgressPrimary
          }
        />
      )}
    </div>
  );
};

export default CustomButton;
