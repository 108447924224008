import React from "react";

const GPS = (props) => {
  const { size, fill, style } = props;
  return (
    <svg
      style={style}
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93216 0.246155C2.49469 0.246155 1.3252 1.41565 1.3252 2.85312C1.3252 4.65987 3.93472 7.74615 3.93472 7.74615C3.93472 7.74615 6.53912 4.57101 6.53912 2.85312C6.53912 1.41565 5.36968 0.246155 3.93216 0.246155ZM4.71873 3.61644C4.50185 3.83328 4.21703 3.94173 3.93216 3.94173C3.64734 3.94173 3.36243 3.83328 3.14563 3.61644C2.7119 3.18275 2.7119 2.47707 3.14563 2.04333C3.35565 1.83322 3.63503 1.7175 3.93216 1.7175C4.22929 1.7175 4.50862 1.83327 4.71873 2.04333C5.15247 2.47707 5.15247 3.18275 4.71873 3.61644Z"
        fill={fill}
      />
    </svg>
  );
};

export default GPS;
