import React from "react";

const ForwardArrow = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size*0.641}
      height={size} viewBox="0 0 25 39" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M24.3212 17.9782L5.38423 0.623044C4.94624 0.221315 4.36156 0 3.73813 0C3.11471 0 2.53003 0.221315 2.09204 0.623044L0.697462 1.90084C-0.209999 2.73347 -0.209999 4.08672 0.697462 4.91808L16.5993 19.4919L0.679818 34.0819C0.241828 34.4836 0 35.0192 0 35.5902C0 36.1619 0.241828 36.6974 0.679818 37.0995L2.0744 38.377C2.51273 38.7787 3.09706 39 3.72049 39C4.34391 39 4.92859 38.7787 5.36658 38.377L24.3212 21.0059C24.7602 20.6029 25.0014 20.0649 25 19.4929C25.0014 18.9187 24.7602 18.3809 24.3212 17.9782Z" fill={fill}/>
</svg>
  );
};

export default ForwardArrow;