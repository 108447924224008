import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
  switch_track: {
    backgroundColor: "#ffff00",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
}));

const DistanceField = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { value, onChange, field, invokedFields, isdone, disabled } = props;
  const units = ["Km", "Mile"];

  const [selectedUnit, setSelectedUnit] = useState(units[0]);

  useEffect(() => {
    setSelectedUnit(units[value.match(/[a-zA-Z]+/)?.[0] == "Mile" ? 1 : 0]);
  }, [value]);

  const numberAndCommasRegEx = /^(\d+\,*)*/;

  const handleNumChange = (event) => {
    if (event.target.value.match(/^\d+/)) {
      onChange(event, {
        text:
          event.target.value
            .match(numberAndCommasRegEx)?.[0]
            .replaceAll(",", "") +
          " " +
          selectedUnit,
        field: field,
      });
    } else {
      onChange(event, { text: "", field: field });
    }
  };

  const handleUnitChange = (event) => {
    onChange(event, {
      text: value.replace(/[a-zA-Z]+/, event.target.value),
      field: field,
    });
    setSelectedUnit(event.target.value);
  };

  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };

  return (
    <FormControl fullWidth>
      <TextField
        {...props}
        value={numberWithCommas(value.match(numberAndCommasRegEx)?.[0] ?? "")}
        onChange={handleNumChange}
        disabled={disabled}
        InputLabelProps={{
          className:
            handleInvokedField() && isdone
              ? classes.switch_track
              : classes.switch_base,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={selectedUnit}
                onChange={handleUnitChange}
                disabled={disabled}
              >
                {units.map((unit) => (
                  <MenuItem value={unit}>{t(unit)}</MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default DistanceField;
