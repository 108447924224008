import React from "react";

const FeatureIcon14 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M39.6742 5.504H21.875C21.3984 5.504 20.9211 5.52601 20.4531 5.56376C20.15 5.52759 19.8437 5.504 19.5312 5.504C17.9859 5.504 16.5484 5.96319 15.3351 6.74555C9.58435 9.014 5.32029 14.2043 4.20388 20.3004L4.03357 21.2298H39.6742C40.8789 21.2298 42.0547 20.8901 43.0758 20.2477C44.9656 19.0589 46.0937 17.0106 46.0937 14.7689V11.9649C46.0937 8.40305 43.214 5.504 39.6742 5.504ZM39.8437 13.3669C39.8437 16.8352 37.0398 19.6572 33.5937 19.6572C30.1476 19.6572 27.3437 16.8352 27.3437 13.3669C27.3437 9.89857 30.1476 7.07658 33.5937 7.07658C37.0398 7.07658 39.8437 9.89857 39.8437 13.3669ZM28.9265 7.07658C27.9258 7.83142 27.1117 8.819 26.5625 9.96226C26.0133 8.819 25.2 7.83142 24.1984 7.07658H28.9265ZM25.7812 13.3669C25.7812 16.8352 22.9773 19.6572 19.5312 19.6572C16.0851 19.6572 13.2812 16.8352 13.2812 13.3669C13.2812 9.89857 16.0851 7.07658 19.5312 7.07658C22.9773 7.07658 25.7812 9.89857 25.7812 13.3669ZM5.93904 19.6572C6.88591 15.7855 9.23123 12.3809 12.4367 10.0951C11.9804 11.0929 11.7187 12.1985 11.7187 13.3669C11.7187 15.9404 12.9593 18.2222 14.864 19.6572H5.93904ZM24.1984 19.6572C25.1992 18.9024 26.0133 17.9148 26.5625 16.7715C27.1117 17.9148 27.925 18.9032 28.9265 19.6572H24.1984ZM44.5312 14.7689C44.5312 16.4649 43.6773 18.0147 42.2484 18.9142C41.475 19.4009 40.5859 19.6572 39.6742 19.6572H38.2609C40.1656 18.2222 41.4062 15.9404 41.4062 13.3669C41.4062 10.7934 40.1656 8.51156 38.2609 7.07658H39.6742C42.3523 7.07658 44.5312 9.26954 44.5312 11.9649V14.7689Z" fill="#07303E"/>
<path d="M39.6742 2.35886H21.875C10.2438 2.35886 0.78125 11.8824 0.78125 23.5887V24.375H39.6742C41.4648 24.375 43.2141 23.8694 44.7328 22.9148C47.5422 21.148 49.2188 18.1027 49.2188 14.7689V11.965C49.2188 6.66773 44.9375 2.35886 39.6742 2.35886ZM47.6562 14.7689C47.6562 17.5571 46.2539 20.1031 43.9047 21.5813C42.6344 22.3802 41.1719 22.8024 39.6742 22.8024H2.35938C2.77031 12.3267 11.3672 3.93144 21.875 3.93144H39.6742C44.0758 3.93144 47.6562 7.53501 47.6562 11.965V14.7689Z" fill="#07303E"/>
<path d="M19.5312 11.7944C20.393 11.7944 21.0938 12.4997 21.0938 13.3669C21.0938 14.2342 20.393 14.9395 19.5312 14.9395V16.5121C21.2547 16.5121 22.6562 15.1015 22.6562 13.3669C22.6562 11.6324 21.2547 10.2218 19.5312 10.2218C17.8078 10.2218 16.4062 11.6324 16.4062 13.3669H17.9688C17.9688 12.4997 18.6695 11.7944 19.5312 11.7944Z" fill="#07303E"/>
<path d="M33.5938 11.7944C34.4555 11.7944 35.1562 12.4997 35.1562 13.3669C35.1562 14.2342 34.4555 14.9395 33.5938 14.9395V16.5121C35.3172 16.5121 36.7188 15.1015 36.7188 13.3669C36.7188 11.6324 35.3172 10.2218 33.5938 10.2218C31.8703 10.2218 30.4688 11.6324 30.4688 13.3669H32.0312C32.0312 12.4997 32.732 11.7944 33.5938 11.7944Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon14;
