import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
    height: "100%",
    display: "grid",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardContent: {
    padding: "21px 31px 0 17px",
    marginBottom: "-24px", // 24px is to over ride .MuiCardContent-root:last-child's 24px padding just a quick fix
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0 24px 7px", // 24px is over ridden in .MuiCardContent-root:last-child just included it to explain the -9 margin just a quick fix
      margin: "0 0 -9px",
    },
  },
  icons: {
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    width: "20px",
    height: "20px",
    transform: "none",
  },
  firstButtonGrid: {
    flexBasis: "50%",
    minWidth: "50%",
    paddingRight: "5px",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      maxWidth: "100%",
      padding: "0",
    },
  },
  claimButtonGridItem: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      display: "flex",
    },
  },
  claimButton: {
    transform: "none",
    width: "62px",
    fontSize: "14px",
    fontWeight: "500",
    padding: "8px 35px",
    textTransform: "none",
    boxShadow: "0px 3.01558px 4.52336px rgba(15, 71, 90, 0.4)",
    borderRadius: "7.54px",
    lineHeight: 1.5,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 25px",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  buttons: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: 1.5,
    padding: "8px 25px",
    borderRadius: "7.54px",
    textTransform: "none",
    boxShadow: "0px 3.01558px 4.52336px rgba(15, 71, 90, 0.4)",
    height: "100%",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  buttonsFirst: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: 1.5,
    padding: "8px 25px",
    borderRadius: "7.54px",
    textTransform: "none",
    boxShadow: "0px 3.01558px 4.52336px rgba(15, 71, 90, 0.4)",
    height: "100%",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      marginBottom: "10px",
    },
  },
  secondButtonGrid: {
    flexBasis: "50%",
    minWidth: "50%",
    paddingLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      maxWidth: "100%",
      padding: "0",
    },
  },
  cardText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  firstCardGrid: {
    flexBasis: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "58.82%",
      maxWidth: "58.82%",
    },
  },
  secondCardGrid: {
    flexBasis: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "41.18%",
      maxWidth: "41.18%",
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  cardActions: {
    padding: "22px 31px 29px",
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 7px 15px 0",
      marginTop: "0",
    },
  },
  firstTwoTexts: {
    display: "flex",
    marginBottom: "11px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  lastText: {
    display: "flex",
  },
}));

const ListingRequestCardSkeleton = (props) => {
  const { page } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item className={classes.firstCardGrid}>
          <CardContent className={classes.cardContent}>
            {[1, 2, 3].map((i) => (
              <div
                className={i === 3 ? classes.lastText : classes.firstTwoTexts}
              >
                <Skeleton className={classes.icons} />
                <Skeleton
                  className={classes.cardText}
                  style={{ width: `${188 - i * 27}px` }}
                ></Skeleton>
              </div>
            ))}
          </CardContent>
        </Grid>
        <Grid item className={classes.secondCardGrid}>
          <CardActions className={classes.cardActions}>
            {page === "incoming" ? (
              <Grid container justify="flex-end">
                <Grid item className={classes.claimButtonGridItem}>
                  <Skeleton className={classes.claimButton}></Skeleton>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justify="space-between"
                style={{ height: "100%" }}
              >
                <Grid item className={classes.firstButtonGrid}>
                  <Skeleton className={classes.buttonsFirst}></Skeleton>
                </Grid>
                <Grid item className={classes.secondButtonGrid}>
                  <Skeleton className={classes.buttons}></Skeleton>
                </Grid>
              </Grid>
            )}
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ListingRequestCardSkeleton;
