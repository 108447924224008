import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "./Button";
import ImageCropper5 from "./ImageCropper";
import Forms from "../wrappers/Forms";
import {
  addVideoImage,
  clearVideoImages,
  getForms,
  getSubforms,
  toggleSelectedImage,
} from "../store/actions/forms";
import { toggleNotification } from "../store/actions/notification";
import { getCar, updateCarForm } from "../store/actions/car";
import { updateCarVisuals } from "../store/actions/car";
import { clearSavedFormData, totalTime } from "../store/actions/forms";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: "100%",
    minWidth: "100%",
    margin: "0",
  },
  dialogPaper1: {
    margin: "0",
    zIndex: 1000,
    width: "50vw",
    textAlign: "center",
  },
}));

const VideoUploader = (props) => {
  const { t, i18n } = useTranslation();

  const {
    video,
    fetchingSubforms,
    fetchingForms,
    form,
    addVideoImage,
    clearVideoImages,
    toggleSelectedImage,
    videoImages,
    forms,
    points,
    answers,
    toggleNotification,
    updateCarForm,
    hidden,
    updateCarVisuals,
    clearSavedFormData,
    totalTime,
    user,
    getForms,
    getCar,
    getSubforms,
    saved,
    isExt,
    isInt,
  } = props;
  const [open, setOpen] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [subformloading, setSubformloading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [rehydratingSaved, setRehydratingSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState(false);
  const [currentForms, setCurrentForms] = useState([]);
  const [formIndex, setFormIndex] = useState(1);
  const [visualizationPointError, setVisualizationPointError] = useState([]);
  const [paparazziUnLoaded, setPaparazziUnLoaded] = useState([]);
  const [timeFrom, setTimeFrom] = useState("");
  const [inLarge, setInLarge] = useState(false);
  const [inLargeImg, setInLargeImg] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [screenshot, setScreenshot] = useState(null);
  const [videoIndex, setVideoIndex] = useState(0);
  // const [currentVideo, setCurrentVideo] = useState("");
  const [videoo, setVideoo] = useState(video[0]);

  const carId = props.match.params.id;
  async function fetchData(submit) {
    setLoading(true);
    let index = 0;
    await getForms({
      carId,
      callback: async (forms) => {
        await getCar({
          id: carId,
          callback: async (car) => {
            setCurrentForms([...forms]);
            setLoading(false);
            setSubmitting(false);
          },
        });
        await getSubforms({
          id: carId,
          formIndex: 1,
        });
      },
    });
    setFetchedData(!fetchedData);
    setLoading(false);
  }

  const largeFunc = (image, isopen) => {
    setInLarge(isopen);
    setInLargeImg(image);
  };

  const calcTimePerForm = () => {
    let totalTimePerForm = 0;
    let newTimeFrom = 0;
    let newTimeTo = 0;
    let timeTo = new Date().toLocaleString("en-GB");
    newTimeFrom += parseInt(timeFrom?.substr(12, 2)) * 3600;
    newTimeFrom += parseInt(timeFrom?.substr(15, 2)) * 60;
    newTimeFrom += parseInt(timeFrom?.substr(18, 2));
    newTimeTo += parseInt(timeTo?.substr(12, 2)) * 3600;
    newTimeTo += parseInt(timeTo?.substr(15, 2)) * 60;
    newTimeTo += parseInt(timeTo?.substr(18, 2));
    totalTimePerForm = newTimeTo - newTimeFrom;
    return totalTimePerForm;
  };

  const handleSubmitting = (event, submit) => {
    const form = forms[formIndex];
    setSubmitting(true);

    let missingPoints = [];
    if (isExt || isInt) {
      if ((isExt && !isInt) || (!isExt && isInt)) {
        let sideOptions = [];
        let answerOptions = [];
        let passedOptions = [];
        let passedAnswers = [];
        for (const side in points) {
          sideOptions = Object.keys(points[side].points);
          passedOptions.push(...sideOptions);
        }

        Object.keys(answers).map((key) => {
          if (
            Array.isArray(answers[key]) &&
            (answers[key][0]?.field_type === "ddma" ||
              answers[key][0]?.field?.type == "ddma")
          ) {
            answerOptions = Object.keys(answers[key]).map((el) => {
              return answers[key][el];
            });
            let faultyOptions = answerOptions.filter((el) => {
              if (typeof el === "object") {
                if (el?.option) {
                  return el.option.default !== true;
                }
              }
            });
            faultyOptions.map((el) => {
              if (
                (el?.unique_answer_id || el?.answerPointLink) &&
                el?.option?.with_image &&
                el.side
              ) {
                passedAnswers.push(
                  el.unique_answer_id?.toString() ||
                    el.answerPointLink.toString()
                );
              }
            });
          }
        });
        for (const faultyAnswer in passedAnswers) {
          if (!passedOptions.includes(passedAnswers[faultyAnswer])) {
            missingPoints.push(passedAnswers[faultyAnswer]);
          }
        }

        if (missingPoints.length !== 0) {
          toggleNotification({
            variant: "error",
            open: true,
            message: "Answers missing visualization point allocation",
          });
          setLoading(false);
          setSubmitting(false);
          setVisualizationPointError(missingPoints);
        } else {
          setVisualizationPointError([]);
          updateCarForm({
            id: carId,
            body: { answers: answers, form: 1, hidden },
            callback: (payload) => {
              if (payload.status == "success") {
                updateCarVisuals({
                  id: carId,
                  body: {
                    answers: points,
                    answerPointLink: payload.response.answerPointLink,
                    form: 1,
                    position: isExt ? "ext" : "int",
                  },
                  callback: () => {
                    clearSavedFormData({ carId, formId: 1 });
                    totalTime({
                      car_id: carId,
                      officer: user.user_id,
                      time_from: timeFrom,
                      time_to: new Date().toLocaleString("en-GB"),
                      time_per_form: calcTimePerForm(),
                      form: 1,
                      // edited: currentForms[formIndex].is_done,
                      callback: () => {
                        setTimeFrom(new Date().toLocaleString("en-GB"));
                      },
                    });
                    fetchData(submit);
                  },
                });
              } else {
                setSubmitting(false);
              }
            },
          });
        }
      } else {
        updateCarForm({
          id: carId,
          body: { answers: answers, form: 1, hidden },
          callback: (payload) => {
            if (payload.status == "success") {
              updateCarVisuals({
                id: carId,
                body: {
                  answers: points,
                  answerPointLink: payload.response.answerPointLink,
                  form: 1,
                },
                callback: () => {
                  clearSavedFormData({ carId, formId: 1 });
                  totalTime({
                    car_id: carId,
                    officer: user.user_id,
                    time_from: timeFrom,
                    time_to: new Date().toLocaleString("en-GB"),
                    time_per_form: calcTimePerForm(),
                    form: 1,
                    // edited: currentForms[formIndex].is_done,
                    callback: () => {
                      setTimeFrom(new Date().toLocaleString("en-GB"));
                    },
                  });
                  fetchData(submit);
                },
              });
            } else {
              setSubmitting(false);
            }
          },
        });
      }
    }
  };

  // useEffect(() => {
  //   fetch(video[videoIndex]).then((data) => {
  //     setCurrentVideo(data?.url);
  //   });
  // }, [videoIndex]);

  // useEffect(() => {
  //   fetch(() => video[videoIndex])
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setCurrentVideo(data?.url);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, [videoIndex]);

  useEffect(() => {
    subformloading !== undefined &&
      setSubformloading(
        fetchingSubforms || fetchingForms || submitting || rehydratingSaved
      );
  }, [fetchingSubforms, fetchingForms, submitting, rehydratingSaved]);

  const takeScreenshot = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    // console.log(
    //   "TTTTTTTTTRrr",
    //   canvas.width,
    //   canvas.height,
    //   video.videoWidth,
    //   video.videoHeight,
    // );

    // These numbers work for current device (Techno) video dimentions (1920x1080)
    // check comment on canvas and check these links:
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    // https://livefiredev.com/html5-how-to-scale-image-to-fit-a-canvas-with-demos/
    context.drawImage(
      video,
      // 240, // [1920 / 4 = 480], [1080 / 3 = 360], [360 < 480 => 360 is base], [1080 / 3 * 4 = 1440], [(1920 - 1440) / 2 = 240]
      0, // [640 / 4 = 160], [480 / 3 = 160], [360 < 480 => 360 is base], [1080 / 3 * 4 = 1440], [(1920 - 1440) / 2 = 240]
      0,
      canvas.width, // because canvas.width = 1440 otherwise it should be calculated based on top comment array before last
      canvas.height, // because canvas.height = 1080 otherwise it should follow above logic
      0,
      0,
      canvas.width,
      canvas.height
    );
    setScreenshot(canvas.toDataURL());
  };

  useEffect(() => {
    setVideoo(video[videoIndex]);
  }, [videoIndex, video]);

  useEffect(() => {
    clearVideoImages();
    return () => clearVideoImages();
  }, []);

  const getData = (data) => {
    const imgObj = { image: data, selected: false };
    addVideoImage(imgObj);
  };

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <video key={video[videoIndex]} width="400px">
          <source src={video[videoIndex]} type="video/mp4" />
        </video>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
          scroll={"body"}
          maxWidth={false}
          classes={{ paper: classes.dialogPaper }}
          style={{
            margin: "0",
            height: "100%",
          }}
        >
          <DialogTitle
            style={{
              padding: "0px 30px 0px 30px",
              backgroundColor: "#d5c3c3",
            }}
            id="responsive-dialog-title"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography>Exterior Condition</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "35%",
                  height: "fit-content",
                }}
              >
                <div
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                    minWidth: "200px",
                    border: "solid",
                    borderColor: "gray",
                    marginRight: "20px",
                  }}
                >
                  <Forms
                    setSubformloading={setSubformloading}
                    subformloading={subformloading}
                    loadingForm={loading}
                    submitting={submitting}
                    fetchedData={fetchedData}
                    form={form}
                    visualizationPointError={visualizationPointError}
                    setPaparazziUnLoaded={setPaparazziUnLoaded}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "65%",
                  height: "inherit",
                }}
              >
                {/* {currentVideo && ( */}
                <div style={{ width: "592px", height: "444px" }}>
                  {console.log("TTTTTTTTTTTTTT1111", video)}
                  <video
                    key={video[videoIndex]}
                    crossOrigin="anonymous"
                    controls
                    ref={videoRef}
                    // width="400px"
                    width="592px"
                    style={{
                      // width: "100%",
                      // height: "50%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <source src={video[videoIndex]} type="video/mp4" />
                  </video>
                </div>
                {/* )} */}
                <div style={{ display: "flex" }}>
                  <Button
                    disabled={videoIndex === 0}
                    onClick={() => {
                      setVideoIndex(videoIndex - 1);
                      // setCurrentVideo(video[videoIndex]);
                    }}
                  >
                    {t("Prev")}
                  </Button>
                  <Button
                    disabled={videoIndex === video.length - 1}
                    onClick={() => {
                      setVideoIndex(videoIndex + 1);
                      // setCurrentVideo(video[videoIndex]);
                    }}
                  >
                    {t("Next")}
                  </Button>
                </div>
                <canvas
                  ref={canvasRef}
                  width={videoRef.current?.videoWidth}
                  height={videoRef.current?.videoHeight}
                  // width={1440} // i think wrong, it should be videoRef.videwWidth or videoRef.videwHeight / 3 * 4 or a useEffect that calculates video width and height then determines which is less to make 4:3 then calculate width and height and set state
                  // height={1080} // i think wrong, it should be videoRef.videwHeight
                  style={{ display: "none" }}
                />
                {
                  <div
                    style={{
                      borderColor: "gray",
                      borderStyle: "solid",
                      borderRadius: "10px",
                      padding: "10px",
                      height: "72px",
                      width: "initial",
                      display: "flex",
                      overflowX: "scroll",
                    }}
                  >
                    {/* dfgthyjk */}
                    {videoImages?.length > 0 &&
                      videoImages.map((imageObj, index) => {
                        return (
                          <img
                            id={"image" + index}
                            alt={index}
                            src={imageObj.image}
                            style={
                              imageObj.selected
                                ? {
                                    marginRight: "10px",
                                    border: "solid",
                                    borderColor: "red",
                                    borderStyle: "solid",
                                  }
                                : {
                                    marginRight: "10px",
                                  }
                            }
                            onClick={() => {
                              toggleSelectedImage(index);
                            }}
                            onDoubleClick={() =>
                              largeFunc(imageObj.image, true)
                            }
                          />
                        );
                      })}
                  </div>
                }
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      takeScreenshot();
                      setOpenCrop(true);
                    }}
                    style={{
                      marginTop: "5px",
                      width: "150px",
                    }}
                  >
                    {t("Crop")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={Object.values(saved)[0][1] !== undefined}
                    onClick={() => {
                      window.open("/carVis/" + carId, "_blank");
                    }}
                    style={{
                      marginTop: "5px",
                      width: "150px",
                    }}
                  >
                    {t("Preview")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmitting}
                    style={{
                      marginTop: "5px",
                      width: "150px",
                    }}
                  >
                    {t("Save")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => clearVideoImages()}
                    style={{
                      marginTop: "5px",
                      width: "150px",
                    }}
                  >
                    {t("Clear All")}
                  </Button>
                </div>
                <ImageCropper5
                  image={screenshot}
                  openCrop={openCrop}
                  setOpenCrop={setOpenCrop}
                  ff={getData}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={inLarge}
          aria-labelledby="responsive-dialog-title"
          scroll={"body"}
          maxWidth={false}
          classes={{ paper: classes.dialogPaper1 }}
          style={{
            margin: "0",
            height: "100%",
          }}
        >
          <DialogTitle
            style={{
              padding: "0px 30px 0px 30px",
              backgroundColor: "#d5c3c3",
              textAlign: "right",
            }}
            id="responsive-dialog-title"
          >
            <IconButton onClick={() => setInLarge(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <img
            alt="inLargeImg"
            style={{
              marginBlock: "25px",
              width: "500px",
              height: "375px",
            }}
            src={inLargeImg}
          />
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fetchingForms: state.forms.fetchingForms,
  fetchingSubforms: state.forms.fetchingSubforms,
  videoImages: state.forms.videoImages,
  forms: state.forms.forms,
  points: state.forms.points,
  answers: state.forms.answers,
  hidden: state.forms.hidden,
  user: state.auth.user,
  saved: state.forms.saved,
});
const mapDispatchToProps = (dispatch) => ({
  addVideoImage(payload) {
    dispatch(addVideoImage(payload));
  },
  clearVideoImages(payload) {
    dispatch(clearVideoImages(payload));
  },
  toggleSelectedImage(payload) {
    dispatch(toggleSelectedImage(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
  updateCarForm(payload) {
    dispatch(updateCarForm(payload));
  },
  updateCarVisuals(payload) {
    dispatch(updateCarVisuals(payload));
  },
  clearSavedFormData(payload) {
    dispatch(clearSavedFormData(payload));
  },
  totalTime(payload) {
    dispatch(totalTime(payload));
  },
  getForms(payload) {
    dispatch(getForms(payload));
  },
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getSubforms(payload) {
    dispatch(getSubforms(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoUploader)
);
