import React, { useState } from "react";

import Button from "./Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import InfoButton from "./InfoButton";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import imageTimeStamp from "../helpers/imageTimeStamp";
import makeStyles from "@material-ui/core/styles/makeStyles";
import uploadImage from "../helpers/aws";
import { withRouter } from "react-router-dom";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Global from "./icons/Global";

const useStylesImages = makeStyles((theme) => ({
  img: {
    overflow: "hidden",
    display: "block",
    width: "100%",
    height: 300,
    objectFit: "contain",
    margin: "auto",
  },
}));

const ImagesStepper = (props) => {
  const classes = useStylesImages();
  const { images, onDeleteImage, invokedFields, activeStep, setActiveStep } =
    props;
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Paper>
        <img
          className={classes.img}
          src={images[activeStep]}
          alt={images[activeStep]}
        />
        <Button
          fullWidth
          color={"secondary"}
          variant={"text"}
          onClick={(event) => {
            onDeleteImage(event, activeStep);
          }}
        >
          <DeleteIcon />
          Delete Image
        </Button>
      </Paper>
      <br />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            Back
          </Button>
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    height: 500,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  switch_track: {
    backgroundColor: "#ffff00",
    width: "150px",
  },
  switch_base: {
    backgroundColor: "#fff",
  },
  subIcon: {
    position: "absolute",
    right: "5px",
    bottom: "5px",
  },
}));

const MultiImageInput = (props) => {
  const { title, fieldId, value, onChange, field, invokedFields, isdone } =
    props;
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  let valueIsArray = Array.isArray(value);
  const classes = useStyles();
  const carId = props.match.params.id;
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  const handleFile = async (event) => {
    event.persist();
    setLoading(true);
    let array = [];
    for (let i = 0; i < event.target.files.length; i++) {
      await uploadImage(
        event.target.files[i],
        `Car-${carId}-Image-${title}-${fieldId}-${
          value.length + i
        }-${imageTimeStamp()}.png`,

        (data) => {
          array.push(data.Location);
          if (array.length === event.target.files.length) {
            setLoading(false);
            onChange(event, {
              text: title,
              images: valueIsArray ? [...value, ...array] : array,
              field: field,
              option: null,
            });
          }
        }
      );
    }
  };

  const handleDeleteImage = (event, activeStep) => {
    const newImages = value.filter((el, i) => i !== activeStep);
    if (activeStep + 1 > newImages.length && newImages.length != 0) {
      // if activeStep is at last image and image is not the only one
      setActiveStep(newImages.length - 1);
    }
    onChange(event, {
      text: title,
      images: newImages,
      field: field,
      option: null,
    });
  };

  return (
    <Paper style={{ padding: "1em" }}>
      <div style={{ display: "flex" }}>
        <Typography
          children={title}
          style={{ margin: "auto 0" }}
          classes={
            handleInvokedField() && isdone
              ? { body1: classes.switch_track }
              : { body1: classes.switch_base }
          }
        />
        {field?.field_info && <InfoButton infoFiles={field.field_info} />}
      </div>
      <Grid container spacing={2} justify={"center"}>
        <Grid item xs={12} md={6}>
          {valueIsArray ? (
            <div className={classes.root}>
              {value.length === 0 ? (
                <img
                  width={"100%"}
                  src={require("../images/img_placeholder.png")}
                  alt={"placeholder"}
                />
              ) : (
                <div>
                  <ImagesStepper
                    images={value}
                    onDeleteImage={handleDeleteImage}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                  />
                </div>
              )}
            </div>
          ) : (
            <img width={"100%"} src={value} alt={"placeholder"} />
          )}
          <Button
            style={{
              backgroundColor: "#BFD6DE",
              border: "1px solid #0D6885",
              color: "#186985",
            }}
            fullWidth
            variant={"contained"}
            loading={loading}
          >
            <input
              className={"file-input"}
              type={"file"}
              multiple
              onChange={handleFile}
              id={"file_upload_" + fieldId}
              accept={"image/*"}
              style={{ display: "none" }}
            />
            <label
              style={{ cursor: "pointer", width: "100%", all: "inherit" }}
              htmlFor={"file_upload_" + fieldId}
            >
              <PhotoCameraIcon fontSize={"large"} />
              <Global size={17} fill={"#186985"} className={classes.subIcon} />
            </label>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withRouter(MultiImageInput);
