import React, { useState } from "react";
import PaparazziSelector from "./PaparazziSelector";

function PaparazziSelectorComponent(props) {
  const { posibleValues, handleSunLocationChange, value, size } = props;
  const [getValue, setGetValue] = useState(value); //default
  var ids = [
    "4288",
    "4291",
    "4284",
    "4287",
    "4289",
    "4286",
    "4292",
    "4283",
    "4290",
  ];
  var squarSize = size ?? 300;

  return (
    <div>
      {/* center - left - right - front - rear - RightRear -leftrear - FrontRight - FrontLeft   */}
      <PaparazziSelector
        ids={ids}
        width={squarSize}
        height={squarSize}
        location={value}
        setLocation={handleSunLocationChange}
      />
    </div>
  );
}

export default PaparazziSelectorComponent;
