import React from "react";

const FeatureIcon9 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M47.44 20.7353L37.3334 19.0849L34.4045 14.7812C33.6448 13.666 32.3748 13 31.007 13H15.9098C14.542 13 13.2719 13.666 12.5123 14.7812L10.0291 18.4307L7.72314 16.1718C6.96345 15.4267 5.91071 15 4.83606 15H2.02085C1.45659 15 0.999985 15.4472 0.999985 16C0.999985 16.5528 1.45659 17 2.02085 17H4.83616C5.37248 17 5.89885 17.2139 6.27965 17.586L8.19132 19.4586L3.32079 20.6514C1.95501 20.9853 0.999985 22.1826 0.999985 23.5615V28.5859C0.999985 29.7647 1.46855 30.8731 2.31992 31.7071C3.15934 32.5283 4.31974 33.0001 5.50608 33.0001H8.24903C8.72334 35.2793 10.7844 37 13.25 37C15.7156 37 17.7767 35.2794 18.251 33H31.7282C32.2025 35.2793 34.2636 37 36.7292 37C39.1948 37 41.2559 35.2794 41.7302 33H46.3055C47.0851 33 47.7848 32.5772 48.1338 31.8945L49.7847 28.6581C49.8205 28.5878 49.8485 28.5136 49.8664 28.4374L49.974 27.9902C49.992 27.9159 50 27.8398 50 27.7636V23.6943C50.0001 22.2207 48.9234 20.9765 47.44 20.7353ZM13.25 35C11.5612 35 10.1875 33.6543 10.1875 32C10.1875 30.3457 11.5612 29 13.25 29C14.9388 29 16.3125 30.3457 16.3125 32C16.3125 33.6543 14.9388 35 13.25 35ZM36.7292 35C35.0404 35 33.6667 33.6543 33.6667 32C33.6667 30.3457 35.0404 29 36.7292 29C38.418 29 39.7917 30.3457 39.7917 32C39.7917 33.6543 38.418 35 36.7292 35ZM47.9585 27.6475L46.3055 31H41.7303C41.256 28.7206 39.1949 27 36.7293 27C34.2637 27 32.2026 28.7205 31.7283 31H18.251C17.7767 28.7206 15.7156 27 13.25 27C10.7844 27 8.72334 28.7205 8.24903 31H5.50598C4.85798 31 4.22193 30.7422 3.76342 30.2929C3.29887 29.8368 3.04162 29.2305 3.04162 28.5859V23.5615C3.04162 23.1016 3.3606 22.7031 3.81519 22.5908L10.4347 20.9697C10.6819 20.9102 10.8953 20.7617 11.0369 20.5547L14.2111 15.8906C14.5899 15.333 15.2259 15 15.9098 15H31.007C31.6909 15 32.3269 15.333 32.7057 15.8906L35.8799 20.5547C36.0354 20.7851 36.2826 20.9414 36.5618 20.9863L47.1052 22.708C47.5997 22.7881 47.9585 23.2031 47.9585 23.6943V27.6475Z" fill="#07303E"/>
<path d="M11.7888 9.72995C10.2137 8.03562 8.16337 7.08952 6.01549 7.06592C3.86712 7.04229 1.80158 7.94309 0.19939 9.60235C-0.0572407 9.86812 -0.0592034 10.3019 0.195024 10.5712C0.449251 10.8405 0.863372 10.8434 1.12 10.5776C2.47259 9.17685 4.20718 8.41629 6.00423 8.43605C7.80082 8.45577 9.52259 9.25444 10.8524 10.6849C10.9792 10.8212 11.1468 10.8906 11.315 10.8925C11.4815 10.8943 11.6487 10.8299 11.7774 10.6987C12.0359 10.435 12.0411 10.0013 11.7888 9.72995Z" fill="#07303E"/>
<path d="M9.15559 11.601C8.31194 10.7086 7.09675 10.1467 5.98416 10.1344C5.98403 10.1344 5.9838 10.1344 5.98367 10.1344L5.93654 10.1339C5.93635 10.1339 5.93625 10.1339 5.93605 10.1339C4.82342 10.1216 3.59912 10.6567 2.74099 11.5303C2.48222 11.7938 2.47672 12.2275 2.72874 12.4991C2.85553 12.6357 3.02332 12.7053 3.19171 12.7071C3.35806 12.709 3.52503 12.6447 3.65362 12.5138C4.26453 11.8919 5.15596 11.4955 5.92486 11.504C5.92495 11.504 5.92508 11.504 5.92518 11.504L5.9725 11.5045C5.97263 11.5045 5.97267 11.5045 5.9728 11.5045C6.74159 11.513 7.62641 11.9289 8.22692 12.5642C8.48132 12.8333 8.89551 12.8359 9.15191 12.5698C9.40838 12.3039 9.41002 11.8701 9.15559 11.601Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon9;
