import React, { useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import InfoButton from "./InfoButton";
import { makeStyles } from "@material-ui/core/styles";

const CheckboxInput = (props) => {
  const {
    formTitle,
    options,
    value,
    onChange,
    disabled,
    field,
    defaultValue,
    invokedFields,
    isdone,
    renderHide,
    answerObject,
  } = props;
  const useStyles = makeStyles((theme) => ({
    switch_track: {
      backgroundColor: "#ffff00",
    },
    switch_base: {
      backgroundColor: "#fff",
    },
  }));
  const classes = useStyles();
  const handleInvokedField = () => {
    if (invokedFields !== undefined) {
      return invokedFields.includes(field.id);
    }
    return false;
  };
  const handleChange = (option) => (event) => {
    const newValue = event.target.value;
    if (value.includes(newValue.toString())) {
      let newValues = value.filter((el) => el !== newValue.toString());
      if (newValues.includes(defaultValue)) {
        newValues = newValues.filter((el) => el !== defaultValue);
      } else if (newValues.length === 0) {
        if (defaultValue) {
          newValues = [{ id: defaultValue, field: field, option: option }];
        } else {
          newValues = [];
        }
      } else {
        newValues = answerObject.filter((el) => {
          return newValues.indexOf(el.id) > -1;
        });
      }
      onChange(event, newValues);
    } else {
      if (newValue.toString() === defaultValue) {
        onChange(event, [
          { id: newValue.toString(), field: field, option: option },
        ]);
      } else {
        let newValues = value[0] != null ? value : [];
        if (value.includes(defaultValue)) {
          newValues = value.filter((el) => el !== defaultValue);
        }

        newValues = newValues.map((el) => ({
          id: el.toString(),
          field: field,
          option: option,
        }));

        onChange(event, [
          ...answerObject,
          { id: newValue.toString(), field: field, option: option },
        ]);
      }
    }
  };

  return (
    <FormControl component="fieldset">
      <div style={{ display: "flex" }}>
        <FormLabel
          component="label"
          color={"secondary"}
          style={{ margin: "auto 0" }}
          classes={
            handleInvokedField() && isdone
              ? { root: classes.switch_track }
              : { root: classes.switch_base }
          }
        >
          {formTitle}
        </FormLabel>
        <div>{renderHide}</div>
      </div>
      <FormGroup>
        {options.map((option, i) => (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  onChange={handleChange(option)}
                  checked={value.includes(option.id.toString())}
                  value={option.id.toString()}
                />
              }
              label={option.title}
              key={`field_check_box_${i}_${option.id}`}
              disabled={disabled}
            />
            {option?.option_info && (
              <InfoButton infoFiles={option.option_info} />
            )}
          </div>
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxInput;
