import { put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  CREATE_EMPTY_CAR_REQUEST,
  UPDATE_CAR_FORM_REQUEST,
  FETCH_CAR_REQUEST,
  FETCH_CAR_FIELDS_REQUEST,
  FETCH_CAR_FEATURES_REQUEST,
  FETCH_CAR_FORMS_REQUEST,
  FETCH_CAR_FAULTS_REQUEST,
  FETCH_CAR_VISUALIZATION_REQUEST,
  UPDATE_CAR_VISUALS_REQUEST,
  LIST_CARS_REQUEST,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_GROUPS_REQUEST,
  SUBMIT_CAR_TO_QC,
  PUBLISH_CAR_FOR_BUYERS_REQUEST,
  PUBLISH_CAR_FOR_SELLER_REQUEST,
  FETCH_CAR_GENERIC_FEATURES_REQUEST,
  UPDATE_DTC_FORM_REQUEST,
  UPDATE_MAPPED,
  INITIATING_PAPRAZZI,
  INITIATING_PAPRAZZI_SUCCEED,
  GET_DTC_MAPPING,
  FETCH_CAR_INVOKED,
  FETCH_CAR_DETAILS_IMAGES_REQUEST,
  CHANGE_BG_IMAGE,
  FETCH_IMAGE_DOWNLOAD,
  BUILD_REPORT,
  FETCH_CAR_IDS_TITLES_LIST,
} from "../constants";

export function createCar(payload) {
  return {
    type: CREATE_EMPTY_CAR_REQUEST,
    payload: payload,
  };
}

export function submitCarToQC(payload) {
  return {
    type: SUBMIT_CAR_TO_QC,
    payload: payload,
  };
}

export function updateCarForm(payload) {
  return {
    type: UPDATE_CAR_FORM_REQUEST,
    payload: payload,
  };
}

export function updateDtcForm(payload) {
  return {
    type: UPDATE_DTC_FORM_REQUEST,
    payload: payload,
  };
}

export function updateCarVisuals(payload) {
  return {
    type: UPDATE_CAR_VISUALS_REQUEST,
    payload: payload,
  };
}

export function getCar(payload) {
  return {
    type: FETCH_CAR_REQUEST,
    payload: payload,
  };
}

export function getCarForms(payload) {
  return {
    type: FETCH_CAR_FORMS_REQUEST,
    payload: payload,
  };
}

export function getCarFields(payload) {
  return {
    type: FETCH_CAR_FIELDS_REQUEST,
    payload: payload,
  };
}

export function getCarFeatures(payload) {
  return {
    type: FETCH_CAR_FEATURES_REQUEST,
    payload: payload,
  };
}

export function getCarGenericFeatures(payload) {
  return {
    type: FETCH_CAR_GENERIC_FEATURES_REQUEST,
    payload: payload,
  };
}

export function getCarFaults(payload) {
  return {
    type: FETCH_CAR_FAULTS_REQUEST,
    payload: payload,
  };
}

export function reportBuilder(payload) {
  return {
    type: BUILD_REPORT,
    payload: payload,
  };
}

export function getCarVisualization(payload) {
  return {
    type: FETCH_CAR_VISUALIZATION_REQUEST,
    payload: payload,
  };
}

export function getCarsList(payload) {
  return {
    type: LIST_CARS_REQUEST,
    payload: payload,
  };
}

export function publishCarForBuyers(payload) {
  return {
    type: PUBLISH_CAR_FOR_BUYERS_REQUEST,
    payload: payload,
  };
}

export function publishCarForSeller(payload) {
  return {
    type: PUBLISH_CAR_FOR_SELLER_REQUEST,
    payload: payload,
  };
}

export function getCarDetails(payload) {
  return {
    type: FETCH_CAR_DETAILS_REQUEST,
    payload: payload,
  };
}

export function getCarGroups(payload) {
  return {
    type: FETCH_CAR_GROUPS_REQUEST,
    payload: payload,
  };
}

export function updateMissedMapping(payload) {
  return {
    type: UPDATE_MAPPED,
    payload: payload,
  };
}

export function initiatingPaparazzi(payload) {
  return {
    type: INITIATING_PAPRAZZI,
    payload: payload,
  };
}

export function getDTCMapping(payload) {
  return {
    type: GET_DTC_MAPPING,
    payload: payload,
  };
}

export function getCarInvoked(payload) {
  return {
    type: FETCH_CAR_INVOKED,
    payload: payload,
  };
}

export function getCarDetailImages(payload) {
  return {
    type: FETCH_CAR_DETAILS_IMAGES_REQUEST,
    payload: payload,
  };
}

export function changeBackgrounds(payload) {
  return {
    type: CHANGE_BG_IMAGE,
    payload: payload,
  };
}

export function getDownloadImages(payload) {
  return {
    type: FETCH_IMAGE_DOWNLOAD,
    payload: payload,
  };
}

export function getCarIdsTitlesList(payload) {
  return {
    type: FETCH_CAR_IDS_TITLES_LIST,
    payload: payload,
  };
}
