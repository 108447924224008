import React from "react";

const FeatureIcon37 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 38" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M43.2159 0.281466C42.0188 0.610105 41.3615 1.12654 40.7277 2.22982C38.7559 5.56316 41.9953 9.67114 45.6338 8.45048C49.6948 7.11245 49.554 1.40823 45.4225 0.257992C44.3427 -0.0471725 44.2958 -0.0471725 43.2159 0.281466ZM45.0704 3.05142C46.7605 3.96691 45.7042 6.26738 43.8263 5.77442C42.6995 5.49273 42.5352 3.68522 43.5915 3.07489C44.1314 2.76973 44.5305 2.74625 45.0704 3.05142Z" fill="#07303E"/>
<path d="M11.385 9.36603C9.92962 10.0233 9.0376 11.8543 9.69487 12.7698C10.0705 13.2862 11.7371 13.1923 11.9719 12.6759C12.5118 11.4787 12.6291 11.3848 13.4742 11.3144C14.601 11.2205 15.0235 11.5726 15.0235 12.6524C15.0235 13.7792 14.7653 14.1078 13.8263 14.2956C13.1221 14.4364 13.0282 14.5538 12.9578 15.2346C12.8639 16.1031 13.1691 16.4318 14.0376 16.4318C15.2817 16.4318 15.939 18.2627 15.1174 19.4364C14.8122 19.9059 14.5775 19.9764 13.5681 19.9059C12.6996 19.859 12.324 19.7181 12.1831 19.413C12.0658 19.2017 11.878 18.8261 11.7606 18.5914C11.5259 18.0984 9.97656 17.9106 9.48361 18.3332C9.0376 18.7087 9.06107 19.1548 9.64793 20.3285C10.2817 21.6665 11.5493 22.3003 13.662 22.3003C16.5024 22.3003 18.0752 20.9153 18.0752 18.3566C18.0752 16.9012 17.6526 15.9623 16.8075 15.5163C16.2442 15.1876 16.2207 15.1641 16.6432 14.8825C17.77 14.0843 17.9343 11.0796 16.9014 9.97636C15.8686 8.87307 13.0986 8.56791 11.385 9.36603Z" fill="#07303E"/>
<path d="M23.0751 9.17793C22.0423 9.4831 20.9625 10.6333 20.6338 11.7836C20.493 12.3704 20.4226 14.1545 20.4695 16.1732C20.5165 19.084 20.6103 19.7178 21.0094 20.3986C21.8075 21.7601 22.5822 22.1592 24.5305 22.253C27.77 22.4174 29.3427 21.0324 29.3427 18.0512C29.3427 16.361 28.8733 15.3282 27.7934 14.6239C26.8779 14.0371 24.4132 14.0371 23.7324 14.6474L23.2395 15.0934V13.6615C23.2395 12.5113 23.3333 12.1122 23.7089 11.7366C24.5775 10.8681 26.7606 11.361 26.7606 12.4174C26.7606 13.0277 27.2301 13.3798 28.0517 13.3798C28.9671 13.3798 29.3427 13.0042 29.3427 12.1122C29.3427 11.0324 28.5211 9.88216 27.3944 9.3892C26.338 8.89624 24.3662 8.80235 23.0751 9.17793ZM26.1502 16.6427C27.324 17.9338 26.5963 20.1873 24.9765 20.1873C23.8263 20.1873 23.2395 19.5066 23.2395 18.192C23.2395 17.4408 23.3803 16.9948 23.7089 16.6662C24.3193 16.0559 25.6103 16.0559 26.1502 16.6427Z" fill="#07303E"/>
<path d="M34.4131 9.15471C34.0375 9.27208 33.4742 9.60072 33.169 9.90588C32.0188 10.9622 31.9249 11.4552 31.9249 15.6571C31.9249 20.1876 32.1127 20.8683 33.7089 21.7603C35 22.5115 37.5352 22.5115 38.8263 21.7603C40.5164 20.8214 40.7277 20.1641 40.7277 15.7275C40.7277 12.2533 40.6807 11.7603 40.2347 10.9153C39.3427 9.22513 36.784 8.45048 34.4131 9.15471ZM37.5587 11.7369C37.9812 12.1594 38.0282 12.5115 38.0282 15.5632C38.0282 19.4834 37.8638 19.9528 36.4084 19.9528C34.7418 19.9528 34.6244 19.7181 34.554 15.9153C34.4836 12.7697 34.507 12.5115 34.9765 11.9012C35.6103 11.1031 36.8544 11.0326 37.5587 11.7369Z" fill="#07303E"/>
<path d="M4.108 14.6712C1.40847 16.69 0.140869 18.8026 0.140869 21.2674C0.140869 24.0609 2.41786 27.0186 6.15026 29.0843C9.31927 30.8214 15.1409 32.4411 19.554 32.7698C20.6808 32.8637 21.5963 33.0045 21.5963 33.0749C21.5963 33.1688 21.1737 33.6618 20.6573 34.1547C19.3897 35.3989 19.4601 36.5491 20.8451 36.9717C21.3146 37.1125 21.7606 36.7604 23.8498 34.7181C25.7042 32.8871 26.2911 32.1594 26.2911 31.69C26.2911 31.244 25.7042 30.5163 23.9906 28.8026C21.5728 26.3848 20.9155 26.0796 20.1174 26.9482C19.5071 27.6055 19.6479 28.2393 20.5399 29.1078C20.9859 29.5538 21.3615 29.9763 21.3615 30.0937C21.3615 30.4458 15.5165 29.5303 12.6761 28.7087C11.5728 28.4036 9.57749 27.6055 8.23946 26.9717C4.34275 25.0702 2.48829 22.8402 2.91082 20.5632C3.12209 19.5069 4.27232 17.981 5.63383 16.9482C7.39439 15.6336 7.51176 15.5163 7.51176 14.9294C7.51176 14.6008 7.34744 14.1782 7.13617 13.9904C6.50237 13.3566 5.61035 13.5679 4.108 14.6712Z" fill="#07303E"/>
<path d="M43.9436 14.4603C43.0047 15.1176 43.2629 15.8687 45.0469 17.5823C46.8309 19.2725 47.3709 20.4227 47.0892 21.9251C46.4788 25.1645 40.6338 28.3805 32.9812 29.695C31.4084 29.9532 30.7746 30.1645 30.4695 30.5401C29.953 31.1504 29.953 31.4321 30.4225 32.1363C30.7981 32.6528 30.9155 32.6762 32.3943 32.5354C34.5774 32.3476 38.9906 31.1974 41.4319 30.188C45.2112 28.6622 48.2863 26.1504 49.3427 23.7795C50.446 21.3147 49.7417 18.2631 47.5586 16.1035C46.4788 15.0237 45.1643 14.0847 44.7183 14.0847C44.5774 14.0847 44.2253 14.249 43.9436 14.4603Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon37;
