import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import PapaSlice from "./PapaSlice";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const PapaContainer = (props) => {
  const { initialCarId } = props;
  const [carList, setCarList] = useState([
    props?.location?.state?.carId ?? initialCarId,
  ]);
  const [newCarId, setNewCarId] = useState();
  const [showFrames, setShowFrames] = useState(false);

  const handleAdd = () => {
    let tempCarList = [...carList];
    tempCarList.push(newCarId);
    setCarList(tempCarList);
  };

  const handleRemove = (index) => {
    let newCarList = [...carList];
    newCarList.splice(index, 1);
    setCarList(newCarList);
  };

  const handleChange = (event) => {
    setNewCarId(event.target.value);
  };

  const handleToggleFrames = () => {
    setShowFrames((prev) => !prev);
  };

  return (
    <Grid container>
      <Grid item xs={1} style={{ padding: "5px" }}>
        <div style={{ position: "sticky", top: "5px" }}>
          <TextField
            variant="outlined"
            placeholder="Car ID"
            onChange={handleChange}
            style={{ paddingBottom: "5px" }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAdd}
            disabled={carList.length == 4}
          >
            Add
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleToggleFrames}
            style={{ margin: "5px 0" }}
          >
            Frames
          </Button>
        </div>
      </Grid>
      <Grid item xs={11}>
        <Grid container>
          {carList.map((carId, index) => (
            <PapaSlice
              length={carList.length}
              carId={carId}
              index={index}
              handleRemove={handleRemove}
              showFrames={showFrames}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  initialCarId: state.car.cars?.[0]?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PapaContainer)
);
