import React from "react";

const FeatureIcon52 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M43.2166 10.5746C39.8453 6.70923 35.3174 4.06087 30.3454 3.01621V0.0917969H20.6546V3.01646C15.6408 4.06996 11.0761 6.75768 7.70045 10.6704C4.02439 14.9311 2 20.3804 2 26.0145C2 38.9724 12.5421 49.5145 25.5 49.5145C38.458 49.5145 49 38.9724 49 26.0145C49 20.334 46.9461 14.8508 43.2166 10.5746ZM24.2887 3.72582H26.7113V10.9939H24.2887V3.72582ZM25.5 45.8805C14.5459 45.8805 5.63402 36.9686 5.63402 26.0145C5.63402 21.2516 7.34504 16.6454 10.4518 13.0443C13.1379 9.93091 16.7123 7.73426 20.6546 6.74411V14.6279H30.3454V6.74508C34.2523 7.72615 37.7969 9.88924 40.4777 12.9633C43.63 16.5774 45.366 21.2125 45.366 26.0145C45.366 36.9686 36.4542 45.8805 25.5 45.8805Z" fill="#07303E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.8454 3.42208V0.591797H21.1546V3.42232L20.7575 3.50577C15.851 4.5367 11.3832 7.16716 8.07903 10.997C4.48142 15.1668 2.5 20.5002 2.5 26.0145C2.5 38.6963 12.8182 49.0145 25.5 49.0145C38.1818 49.0145 48.5 38.6963 48.5 26.0145C48.5 20.4547 46.4897 15.0882 42.8398 10.9033C39.5398 7.11969 35.1081 4.52781 30.2426 3.50553L29.8454 3.42208ZM20.6546 0.0917969H30.3454V3.01621C35.3174 4.06087 39.8453 6.70923 43.2166 10.5746C46.9461 14.8508 49 20.334 49 26.0145C49 38.9724 38.458 49.5145 25.5 49.5145C12.5421 49.5145 2 38.9724 2 26.0145C2 20.3804 4.02439 14.9311 7.70045 10.6704C11.0761 6.75768 15.6408 4.06996 20.6546 3.01646V0.0917969ZM23.7887 3.22582H27.2113V11.4939H23.7887V3.22582ZM10.0732 12.7177C12.8265 9.5264 16.4906 7.27442 20.5328 6.25918L21.1546 6.103V14.1279H29.8454V6.104L30.4671 6.26014C34.473 7.26605 38.1067 9.48367 40.8546 12.6346C44.0864 16.34 45.866 21.0918 45.866 26.0145C45.866 37.2448 36.7303 46.3805 25.5 46.3805C14.2697 46.3805 5.13402 37.2448 5.13402 26.0145C5.13402 21.1319 6.88802 16.4097 10.0732 12.7177ZM40.4777 12.9633C37.9117 10.0209 34.5544 7.91312 30.8454 6.87763C30.6794 6.83129 30.5127 6.7871 30.3454 6.74508V14.6279H20.6546V6.74411C20.4873 6.78614 20.3206 6.83035 20.1546 6.8767C16.4105 7.9224 13.0239 10.0631 10.4518 13.0443C7.34504 16.6454 5.63402 21.2516 5.63402 26.0145C5.63402 36.9686 14.5459 45.8805 25.5 45.8805C36.4542 45.8805 45.366 36.9686 45.366 26.0145C45.366 21.2125 43.63 16.5774 40.4777 12.9633ZM24.2887 3.72582V10.9939H26.7113V3.72582H24.2887Z" fill="white"/>
<path d="M16.8555 22.0556H14.6683L11 31.9167H13.8654L14.4468 29.9861H17.0631L17.6583 31.9167H20.51L16.8555 22.0556ZM14.8484 28.1806L15.762 24.875L16.6341 28.1806H14.8484Z" fill="#07303E"/>
<path d="M28.1648 22.0556L21.036 31.9167H23.9291L31.0719 22.0556H28.1648Z" fill="#07303E"/>
<path d="M37.799 28.3889C37.725 28.6111 37.626 28.7986 37.5014 28.9514C37.3768 29.1042 37.2384 29.2292 37.0861 29.3264C36.9338 29.4236 36.7747 29.4931 36.6084 29.5347C36.4424 29.5764 36.2807 29.5972 36.1239 29.5972C35.8102 29.5972 35.5309 29.5256 35.2864 29.3819C35.0419 29.2385 34.8341 29.0464 34.6636 28.8056C34.4928 28.5648 34.3636 28.2872 34.276 27.9722C34.1881 27.6575 34.1445 27.3288 34.1445 26.9861C34.1445 26.6806 34.1812 26.3728 34.2553 26.0625C34.3293 25.7524 34.447 25.4747 34.6086 25.2292C34.7699 24.9839 34.973 24.7847 35.2177 24.6319C35.462 24.4792 35.7551 24.4028 36.0967 24.4028C36.2441 24.4028 36.4057 24.4214 36.5811 24.4583C36.7564 24.4955 36.9224 24.5603 37.0794 24.6528C37.2362 24.7454 37.3815 24.8704 37.5155 25.0278C37.649 25.1853 37.7577 25.3844 37.8408 25.625L39.9033 24.1528C39.5894 23.5047 39.105 22.9839 38.4497 22.5903C37.7944 22.1969 37.0285 22 36.1518 22C35.4045 22 34.7375 22.1436 34.1516 22.4306C33.5655 22.7177 33.0671 23.0927 32.6566 23.5556C32.2458 24.0186 31.9343 24.5417 31.7222 25.125C31.5097 25.7083 31.4037 26.301 31.4037 26.9028C31.4037 27.5325 31.519 28.1528 31.7498 28.7639C31.9804 29.375 32.301 29.9214 32.7119 30.4028C33.1225 30.8844 33.6138 31.2708 34.1861 31.5625C34.7582 31.8542 35.3856 32 36.0687 32C36.4563 32 36.853 31.949 37.2591 31.8472C37.6651 31.7455 38.0482 31.5972 38.4081 31.4028C38.768 31.2083 39.0885 30.9677 39.3702 30.6806C39.6515 30.3936 39.8616 30.0694 40 29.7083L37.799 28.3889Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon52;
