import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  switch: {
    position: "relative",
    display: "inline-block",
    width: "140px",
    height: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "195px",
      height: "32px",
    },
    [theme.breakpoints.up("md")]: {
      width: "226px", ////
      height: "34.33px", ////
    },
  },
  switchInput: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  sliderExterior: {
    borderRadius: "15px",
    border: "1px solid #ffffff",
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#07303E",
    "-webkit-transition": ".4s",
    transition: ".4s",

    "&::before": {
      borderBottomLeftRadius: "15px",
      borderTopLeftRadius: "15px",
      position: "absolute",
      content: '" "',
      height: "28px",
      width: "70px",
      left: "-1px",
      bottom: 0,
      backgroundColor: "white",
      "-webkit-transition": ".4s",
      transition: ".4s",
      [theme.breakpoints.up("sm")]: {
        width: "97.5px", ////
        height: "31px", ////
      },
      [theme.breakpoints.up("md")]: {
        height: "33.33px", ////
        width: "121px", ////
      },
    },
  },
  sliderInterior: {
    borderRadius: "15px",
    border: "1px solid #ffffff",
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#07303E",
    "-webkit-transition": ".4s",
    transition: ".4s",
    "&::before": {
      borderTopRightRadius: "15px",
      borderBottomRightRadius: "15px",
      position: "absolute",
      content: '" "',
      height: "28px",
      width: "70px", // smaller white slider because of smaller text
      left: "12px", // to compensate for smaller white slider (width)
      bottom: 0,
      backgroundColor: "white",
      "-webkit-transition": ".4s",
      transition: ".4s",
      transform: "translateX(61px)",
      [theme.breakpoints.up("sm")]: {
        width: "97.5px", ////
        height: "31px", ////
        transform: "translateX(84px)",
      },
      [theme.breakpoints.up("md")]: {
        height: "33.33px", ////
        width: "121px", ////
        left: 0,
        transform: "translateX(107.15px)",
      },
    },
  },
  selectedText: {
    textAlign: "center",
    width: "79px",
    fontSize: "11px", ////
    fontWeight: "700",
    zIndex: "2",
    position: "relative",
    color: "#07303E",
    "-webkit-transition": ".4s",
    transition: ".4s",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px", ////
      paddingLeft: "18px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px", ////
      paddingLeft: "24px",
      paddingRight: "20px",
    },
  },
  unselectedText: {
    textAlign: "center",
    width: "79px",
    fontSize: "11px",
    fontWeight: "700",
    zIndex: "2",
    position: "relative",
    color: "white",
    "-webkit-transition": ".4s",
    transition: ".4s",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px", ////
      paddingLeft: "18px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px", ////
      paddingLeft: "24px",
      paddingRight: "20px",
    },
  },
}));
const PositionSwitch = (props) => {
  const { t, i18n } = useTranslation();
  const { className, onChange, style, setPositionExternally } = props;
  const classes = useStyles();
  const [position, setPosition] = useState("exterior");
  useEffect(() => {
    setPositionExternally || setPositionExternally === undefined
      ? setPosition("exterior")
      : setPosition("interior");
  }, [setPositionExternally]);

  const handleInputChange = (event) => {
    position === "exterior" ? setPosition("interior") : setPosition("exterior");
    onChange(event);
  };
  let sliderClass = classes.sliderExterior;
  position === "exterior"
    ? (sliderClass = classes.sliderExterior)
    : (sliderClass = classes.sliderInterior);

  return (
    <div className={className} style={style}>
      <label className={classes.switch}>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Typography
            className={
              position === "exterior"
                ? classes.selectedText
                : classes.unselectedText
            }
          >
            {t("EXTERIOR")}
          </Typography>
          <Typography
            className={
              position === "exterior"
                ? classes.unselectedText
                : classes.selectedText
            }
          >
            {t("INTERIOR")}
          </Typography>
        </div>
        <input
          type="checkbox"
          className={classes.switchInput}
          checked={position === "exterior" ? true : false}
          onChange={handleInputChange}
        />
        <span className={sliderClass}></span>
      </label>
    </div>
  );
};

export default PositionSwitch;
