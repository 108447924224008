import {
  GET_TIME_SLOTS_REQUEST,
  CREATE_TIME_SLOT_REQUEST,
  UPDATE_TIME_SLOT_REQUEST,
  GET_AREA_GROUPS_REQUEST,
} from "../constants";

export function getTimeSlots(payload) {
  return {
    type: GET_TIME_SLOTS_REQUEST,
    payload: payload,
  };
}

export function createTimeSlot(payload) {
  return {
    type: CREATE_TIME_SLOT_REQUEST,
    payload: payload,
  };
}

export function updateTimeSlot(payload) {
  return {
    type: UPDATE_TIME_SLOT_REQUEST,
    payload: payload,
  };
}

export function getAreaGroups(payload) {
  return {
    type: GET_AREA_GROUPS_REQUEST,
    payload: payload,
  };
}
