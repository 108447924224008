import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircle from "@material-ui/icons/CheckCircle";
import UncheckCircle from "@material-ui/icons/RadioButtonUnchecked";
import clsx from "clsx";
import colors from "../theme/colors";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  selected: {
    // background: colors.cssGradient.primary,
    background: "#e0e0e0",
    color: "#000",
    borderBottom: `3px solid #f15a29`,
  },
  selectedError: {
    // background: colors.cssGradient.primary,
    background: "#e0e0e0",
    borderBottom: `3px solid #f15a29`,
    // color: "#DD4B4B",
    color: "#000",
  },
  tabRoot: {
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabRootError: {
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    // color: "#DD4B4B",
    background: "#ffff00",
  },
}));

export default function ScrollableTabsButtonForce(props) {
  const classes = useStyles();
  const { onChange, tabs, tillIndex, updated_forms } = props;

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#fff" }}>
        <Tabs
          value={props.index}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="secondary"
          style={{ color: "#000" }}
        >
          {tabs?.map((tab, index) => (
            <Tab
              label={<div>{tab?.title}</div>}
              icon={
                tab?.is_done && !updated_forms?.includes(tab?.id) ? (
                  <CheckCircle />
                ) : (
                  <UncheckCircle />
                )
              }
              classes={{
                root: clsx({
                  [classes.tabRootError]:
                    tab?.is_done && updated_forms?.includes(tab?.id),
                  [classes.tabRoot]: !updated_forms?.includes(tab?.id),
                }),
                selected: clsx({
                  [classes.selectedError]:
                    tab?.is_done && updated_forms?.includes(tab?.id),
                  [classes.selected]: !updated_forms?.includes(tab?.id),
                }),
              }}
              key={"form_tab_" + tab?.id}
              {...a11yProps(index)}
              disabled={index > tillIndex}
            />
          ))}
        </Tabs>
      </AppBar>
      {props.children}
    </div>
  );
}
