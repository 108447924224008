import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PapaTestRadioGroup from "../../components/PapaTestRadioGroup";
import { getPaparazziPackageList } from "../../store/actions/forms";
import PapaPopup from "./PapaPopup";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: "calc(100vh - 87px)",
    position: "relative",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  divider: {
    marginInline: "16px",
  },
  mainList: {
    maxHeight: "calc(100vh - 177px)",
    overflowY: "scroll",
  },
  nested: {
    paddingInline: "32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  mainListTitle: {
    fontWeight: 500,
  },
  suggestedPackageHeaderText: {
    color: "#07303E",
  },
  suggestedPackageSubText: {
    fontSize: "14px",
    color: "#9F9F9F",
    padding: "9px",
  },
  selectedPackageName: {
    fontSize: "14px",
    paddingInline: "32px",
    color: "#9F9F9F",
  },
  shadowLocationComponentDiv: {
    marginBlock: "16px",
  },
  button: {
    textTransform: "none",
    paddingBlock: "8px",
  },
  selectedPackageButton: {
    margin: "8px 32px",
  },
  tempButton: {
    marginBlock: "16px",
  },
  suggestButton: {
    margin: "8px 32px 32px",
    width: "calc(100% - 64px)",
  },
  loadingButtonDiv: {
    position: "relative",
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
  submitButton: {
    width: "calc(100% - 32px)",
    margin: "16px",
  },
  buttonLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  packagesGridContainer: {
    padding: "16px",
    overflow: "scroll",
  },
  packageTitle: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    color: "#07303E",
  },
}));

const SelectBackgroundView = (props) => {
  const { packages, selectedPackage, setSelectedPackage } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.packagesGridContainer}>
      {packages.map((packageObj) => (
        <Grid
          item
          xs={2}
          onClick={() =>
            setSelectedPackage({
              id: packageObj.package,
              title: packageObj.package_title,
            })
          }
        >
          <img
            src={packageObj.image_url}
            width="100%"
            style={
              selectedPackage.id === packageObj.package
                ? { border: "5px solid #186985" }
                : {}
            }
          />
          <Typography className={classes.packageTitle}>
            {packageObj.package_title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const Grids = (props) => {
  const classes = useStyles();
  const { withFrame, setWithFrame } = props;
  const { t } = useTranslation();

  const handleRadioChange = (event) => {
    setWithFrame(event.target.value);
  };

  return (
    <RadioGroup
      aria-label="grids"
      name="grids"
      value={withFrame}
      onChange={handleRadioChange}
      className={classes.nested}
    >
      <FormControlLabel
        value="with"
        control={<Radio color="primary" />}
        label={t("With Grids")}
      />
      <FormControlLabel
        value="without"
        control={<Radio color="primary" />}
        label={t("Without Grids")}
      />
    </RadioGroup>
  );
};

const BgSuggest = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    background,
    setBackground,
    reflection,
    setReflection,
    interiorLights,
    setInteriorLights,
    suggestedPackage,
    setSuggestedPackage,
    packages,
    carId,
  } = props;

  return (
    <>
      <List component="div" disablePadding>
        <ListItem className={classes.nested}>
          <ListItemText primary={t("Location Field")} />
          <PapaTestRadioGroup
            options={[
              { id: 4198, title: "Outside" },
              { id: 4199, title: "Showroom" },
            ]}
            setVal={setBackground}
            val={background}
          />
        </ListItem>
        <ListItem className={classes.nested}>
          {background == 4198 ? (
            <>
              <ListItemText primary={t("Reflection On Car")} />
              <PapaTestRadioGroup
                options={[
                  { id: 4293, title: "Trees" },
                  { id: 4294, title: "Buildings" },
                  { id: 4295, title: "No Reflection" },
                ]}
                setVal={setReflection}
                val={reflection}
              />
            </>
          ) : (
            <>
              <ListItemText primary={t("Interior Lights")} />
              <PapaTestRadioGroup
                options={[
                  { id: 4296, title: "Lights" },
                  { id: 4297, title: "No Lights" },
                ]}
                setVal={setInteriorLights}
                val={interiorLights}
              />
            </>
          )}
        </ListItem>
        <ListItem className={classes.nested}>
          <Typography className={classes.suggestedPackageHeaderText}>{`${t(
            "Suggested Package"
          )}:`}</Typography>
          <Typography className={classes.suggestedPackageSubText}>
            {t(suggestedPackage.package_title)}
          </Typography>
        </ListItem>
      </List>
      <Button
        color="secondary"
        variant="contained"
        children={t("Suggest Background")}
        className={[classes.button, classes.suggestButton]}
        onClick={() => {
          let locationPackagesArray = packages.filter((papaPackage) => {
            if (background === 4198) {
              return papaPackage.selection_data.reflection_id === reflection;
            } else {
              return (
                papaPackage.selection_data.interior_lights_id === interiorLights
              );
            }
          });

          let suggestedPackageIndex = carId % locationPackagesArray.length;
          let packageSuggestion = locationPackagesArray[suggestedPackageIndex];
          if (packageSuggestion) {
            setSuggestedPackage(packageSuggestion);
          } else {
            // reset to no Suggestions
            setSuggestedPackage({
              id: null,
              package_title: "No Suggestions",
            });
          }
        }}
      />
    </>
  );
};

const BgSelect = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    backgroundOption,
    setBackgroundOption,
    selectedPackage,
    suggestedPackage,
    setSelectedPackage,
    packages,
  } = props;

  const [manualBgSelectorOpen, setManualBgSelectorOpen] = useState(false);

  const handleRadioChange = (event) => {
    setBackgroundOption(event.target.value);
  };

  return (
    <RadioGroup
      aria-label="background-select"
      name="background-select"
      value={backgroundOption}
      onChange={handleRadioChange}
      className={classes.nested}
    >
      <FormControlLabel
        value="original"
        control={<Radio color="primary" />}
        label={t("Original")}
      />
      <div>
        <FormControlLabel
          value="suggested"
          control={<Radio color="primary" />}
          label={t("Suggested")}
        />
        <Typography className={classes.selectedPackageName}>
          {t(suggestedPackage.package_title)}
        </Typography>
      </div>
      <div>
        <FormControlLabel
          value="manual"
          control={<Radio color="primary" />}
          label={t("Manual")}
        />
        <Typography className={classes.selectedPackageName}>
          {t(selectedPackage.title)}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          className={[classes.button, classes.selectedPackageButton]}
          onClick={() => setManualBgSelectorOpen(true)}
          children={t("Select Background")}
          disabled={backgroundOption !== "manual"}
        />
      </div>
      <PapaPopup
        open={manualBgSelectorOpen}
        onClose={() => setManualBgSelectorOpen(false)}
        title={"Change Background"}
      >
        <SelectBackgroundView
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          packages={packages}
        />
      </PapaPopup>
    </RadioGroup>
  );
};

const ShadowSelect = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { sunLocation, setSunLocation } = props;

  return (
    <div className={classes.nested}>
      <Typography>{t("Shadow Location")}</Typography>
      <div className={classes.shadowLocationComponentDiv}>
        <PapaTestRadioGroup
          sunLocation={true}
          options={[
            { id: 4283, title: "Driver - Front" },
            { id: 4286, title: "Driver - Rear" },
            { id: 4284, title: "Driver - Top" },
            { id: 4287, title: "Middle - Front" },
            { id: 4289, title: "Middle - Rear" },
            { id: 4288, title: "Middle - Top" },
            { id: 4290, title: "Passenger - Front" },
            { id: 4292, title: "Passenger - Rear" },
            { id: 4291, title: "Passenger - Top" },
          ]}
          setVal={setSunLocation}
          val={sunLocation}
          size={236}
        />
      </div>
    </div>
  );
};

const MainTemp = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    setOpenMainTemperaturePopup,
    mainTemperature,
    backgroundImages,
    imageType,
  } = props;

  return (
    <div className={classes.nested}>
      <Typography>{t(mainTemperature)}</Typography>
      <Button
        children={t("Change Temperature")}
        variant="outlined"
        color="primary"
        disabled={!(backgroundImages.length && imageType !== "original")}
        className={[classes.button, classes.tempButton]}
        fullWidth
        onClick={() => setOpenMainTemperaturePopup(true)}
      />
    </div>
  );
};

const ControlBar = (props) => {
  const {
    gridsProps,
    bgSuggestProps,
    bgSelectprops,
    shadowSelectProps,
    mainTempProps,
    submitNewImages,
    saving,
    carId,
    getPaparazziPackageList,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [packages, setPackages] = useState([]);

  const mainParts = [
    { text: "Grids", component: <Grids {...gridsProps} /> },
    {
      text: "Background Suggest",
      component: (
        <BgSuggest {...bgSuggestProps} packages={packages} carId={carId} />
      ),
    },
    {
      text: "Background Select",
      component: <BgSelect {...bgSelectprops} packages={packages} />,
    },
    {
      text: "Shadow Select",
      component: <ShadowSelect {...shadowSelectProps} />,
    },
    { text: "Main Temperature", component: <MainTemp {...mainTempProps} /> },
  ];

  const [open, setOpen] = useState(Array.from(mainParts, () => false));

  useEffect(() => {
    getPaparazziPackageList({
      callback: (res) => {
        setPackages(res.results);
      },
    });
  }, []);

  const handleOpenMain = (index) => {
    const tempOpen = [...open];
    tempOpen[index] = !tempOpen[index];
    setOpen(tempOpen);
  };

  return (
    <div
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <Divider className={classes.divider} />
      <List className={classes.mainList}>
        {mainParts.map((partObj, index) => (
          <>
            <ListItem
              button
              key={partObj.text}
              onClick={() => handleOpenMain(index)}
            >
              <ListItemText
                primary={t(partObj.text)}
                classes={{ primary: classes.mainListTitle }}
              />
              {open[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              {partObj.component}
            </Collapse>
          </>
        ))}
      </List>
      <Divider className={classes.divider} />
      <div className={classes.loadingButtonDiv}>
        <Button
          children={t("Save Changes")}
          color="primary"
          variant="contained"
          onClick={submitNewImages}
          fullWidth
          className={[classes.button, classes.submitButton]}
          disabled={saving}
        />
        {saving && (
          <CircularProgress size={24} className={classes.buttonLoading} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  carId: state.car.car.id,
});

const mapDispatchToProps = (dispatch) => ({
  getPaparazziPackageList(payload) {
    dispatch(getPaparazziPackageList(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlBar);
