import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getPaparazziCarImages } from "../../store/actions/forms";

import { Button, Grid, Typography } from "@material-ui/core";
import FilterTypes from "../../helpers/filter-types";
import SelectDialog from "./SelectDialog";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: "8px",
  },
  imgDiv: {
    display: "flex",
    position: "relative",
    width: "100%",
    maxWidth: "700px",
    maxHeight: "525px",
    margin: "auto",
  },
  img: {
    width: "100%",
    maxWidth: "700px",
    margin: "auto",
    position: "absolute",
  },
  extImage: {
    width: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const PapaSlice = (props) => {
  const classes = useStyles();

  const {
    length,
    getPaparazziCarImages,
    carId,
    index,
    handleRemove,
    showFrames,
  } = props;

  const [paparazzi, setPaparazzi] = useState([]);
  const [carImages, setCarImages] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    getPaparazziCarImages({
      carId,
      callback: (result) => {
        setCarImages(result);
      },
    });
  }, [carId]); // change

  return (
    <Grid item xs={12 / length}>
      <Grid container onClick={setSelectOpen}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            handleRemove(index);
          }}
          style={{ margin: "5px" }}
        >
          Remove Car
        </Button>
        {paparazzi.map((field) => {
          let carImageObject = carImages.filter((imageField) => {
            return imageField.field_id == field.image_field;
          })?.[0];
          let selectedFilter = FilterTypes.find(
            (el) => String(el.id) === String(field.image_field)
          );
          return (
            <>
              {carImageObject ? (
                <Grid item xs={12} spacing={2} className={classes.gridItem}>
                  <Typography>{carImageObject?.field_title}</Typography>

                  <div
                    className={classes.imgDiv}
                    style={{ height: `calc(91.6667vw / ${length} / 4 * 3)` }}
                  >
                    <img src={field.image_url} className={classes.img} />
                    <img src={field.shadow} className={classes.img} />
                    <img
                      src={carImageObject?.image
                        ?.replace("Papa", "NoBg")
                        ?.replace("Origional", "NoBg")}
                      className={clsx({
                        [classes.img]: 1,
                        [classes.extImage]: carImageObject?.ext,
                      })}
                    />
                    {showFrames && (
                      <img
                        src={selectedFilter?.image}
                        className={clsx({
                          [classes.img]: 1,
                          [classes.extImage]: carImageObject?.ext,
                        })}
                      />
                    )}
                  </div>
                </Grid>
              ) : null}
            </>
          );
        })}
      </Grid>
      <SelectDialog
        open={selectOpen}
        setOpen={setSelectOpen}
        setPaparazzi={setPaparazzi}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPaparazziCarImages(payload) {
    dispatch(getPaparazziCarImages(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PapaSlice);
