import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({}));

function MarkabteTooltip(props) {
  const {
    dir,
    setOpen,
    open,
    type,
    elementToTrack,
    id,
    setshowToolTip,
    showToolTip,
  } = props;
  //   const [showToolTip, setshowToolTip] = useState(false);
  const [lang, setlang] = useState(localStorage.getItem("language"));
  const [mobile, setmobile] = useState(window.screen.availWidth < 959); //check if device is mobile
  const [passCompoenet, setpassCompoenet] = useState(false);

  useEffect(() => {
    if (mobile) {
      setTimeout(() => {
        setshowToolTip && setshowToolTip(true);
      }, 3000);
      setTimeout(() => {
        setshowToolTip && setshowToolTip(false);
      }, 7000);
    }
  }, []);

  useEffect(() => {
    if (passCompoenet && !mobile) {
      setshowToolTip && setshowToolTip(true);
      setTimeout(() => {
        setshowToolTip && setshowToolTip(false);
      }, 3000);
    }
  }, [passCompoenet]);

  useEffect(() => {
    const options = {};
    const actionButtonObserver = new IntersectionObserver(
      (entries, actionButtonObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!mobile) setpassCompoenet(entry.isIntersecting);
          } else if (
            !entry.isIntersecting &&
            entry?.boundingClientRect?.y < 0
          ) {
            if (!mobile) setpassCompoenet(entry.isIntersecting);
          } else {
            // not intersecting but came from below (we dont want it to re appear (keeps true from first if))
          }
        });
      },
      options
    );
    var visu = document.getElementById("visu");
    if (visu) {
      actionButtonObserver.observe(visu);
    }
    return () => {
      if (visu) {
        actionButtonObserver.unobserve(visu);
      }
    };
  });

  const [defaultStyle, setdefaultStyle] = useState({ display: "none" });
  const classes = useStyles();
  return showToolTip || id != "elementTracker" ? (
    <div>
      {dir == "top" && (
        <div
          id={id ?? "tooltip"}
          class="top on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "20px", top: "30px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "top-right" && (
        <div
          id={id ?? "tooltip"}
          class="top-right on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "20px", top: "30px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "top-left" && (
        <div
          id={id ?? "tooltip"}
          class="top-left on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "20px", top: "30px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "right" && (
        <div
          id={id ? id : "tooltip"}
          class="right on"
          style={{ ...defaultStyle, ...props.style }} //left: "200px", top: "45px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "right-top" && (
        <div
          id={id ?? "tooltip"}
          class="right-top on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "200px", top: "45px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "right-bottom" && (
        <div
          id={id ?? "tooltip"}
          class="right-bottom on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "200px", top: "45px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "bottom" && (
        <div
          id={id ?? "tooltip"}
          class="bottom on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "150px", top: "86px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "bottom-left" && (
        <div
          id={id ?? "tooltip"}
          class="bottom-left on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "150px", top: "86px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "bottom-right" && (
        <div
          id={id ?? "tooltip"}
          class="bottom-right on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "150px", top: "86px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "left" && (
        <div
          id={id ? id : "tooltip"}
          class="left on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "35px", top: "60px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "left-top" && (
        <div
          id={id ?? "tooltip"}
          class="left-top on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "35px", top: "60px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
      {dir == "left-bottom" && (
        <div
          id={id ? id : "tooltip"}
          class="left-bottom on"
          style={{ display: "none", ...defaultStyle, ...props.style }} //left: "35px", top: "60px",
        >
          <div class="tooltip-arrow"></div>
          <div class="tooltip-label">{props.children}</div>
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
}

export default MarkabteTooltip;
