import React from "react";

const FeatureIcon40 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon40Clip0)">
<path d="M6.92941 41.5798L7.56331 47.8604L19.1393 47.8604L19.77 41.5792C19.8433 40.8591 19.511 40.1579 18.9072 39.7587L18.7434 39.6502C15.4768 37.4743 11.2226 37.4743 7.95601 39.6502L7.79152 39.7594C7.18785 40.1585 6.8557 40.8599 6.92941 41.5798ZM8.51319 40.8432L8.67768 40.734C11.5092 38.8479 15.1967 38.8479 18.0282 40.734L18.1921 40.8426C18.3924 40.9759 18.5028 41.209 18.4788 41.4485L17.9658 46.56L8.73685 46.56L8.22387 41.4479C8.20104 41.2083 8.3123 40.9757 8.51319 40.8432Z" fill="#07303E"/>
<path d="M18.6661 37.8141L19.7479 37.0928L17.1473 33.1921L16.0655 33.9134L18.6661 37.8141Z" fill="#07303E"/>
<path d="M8.0369 37.7991L10.6387 33.8984L9.55687 33.1768L6.95504 37.0774L8.0369 37.7991Z" fill="#07303E"/>
<path d="M1.28544 45.7896C1.46431 45.9107 1.69162 45.9351 1.89203 45.8546L4.58693 44.7767L4.90095 48.5021L3.96993 59.6197C3.71734 62.5306 5.27715 65.2981 7.89817 66.5894L8.41179 66.8449C11.5259 68.385 15.1801 68.385 18.2942 66.8449L18.8071 66.5894C21.4291 65.2989 22.9901 62.5312 22.738 59.6197L21.805 48.5021L22.1171 44.7741L24.812 45.852C25.1448 45.9867 25.5238 45.826 25.6585 45.4931C25.6896 45.4163 25.7057 45.3342 25.7059 45.2513L25.7059 43.531C25.7084 42.7325 25.222 42.0139 24.4797 41.7196L22.4428 40.905L22.7152 37.6685C22.9715 34.6069 21.233 31.7269 18.4047 30.5272L16.1707 29.5734C14.371 28.8088 12.3376 28.8088 10.5378 29.5734L8.30386 30.5272C5.47447 31.726 3.7349 34.6063 3.99073 37.6685L4.26185 40.905L2.22491 41.7196C1.48316 42.0143 0.997338 42.7328 1.00002 43.531L1.00002 45.2513C1.00002 45.467 1.10689 45.6686 1.28544 45.7896ZM22.4551 42.3106L23.9967 42.9276C24.2442 43.0254 24.4065 43.2648 24.4056 43.531L24.4056 44.291L22.4551 43.5108L22.4551 42.3106ZM5.26504 59.7309L6.20126 48.5021L5.28584 37.5573C5.07869 35.0548 6.50123 32.702 8.81359 31.7228L11.0475 30.7697C12.5207 30.1436 14.1853 30.1436 15.6584 30.7697L17.8924 31.7228C20.2038 32.7041 21.6243 35.0582 21.4149 37.5606L20.5047 48.5021L21.4416 59.7309C21.6466 62.1096 20.3712 64.3704 18.2291 65.425L17.7155 65.6805C14.9664 67.0407 11.7402 67.0407 8.99108 65.6805L8.47811 65.425C6.33592 64.3706 5.06016 62.1097 5.26504 59.7309ZM2.30033 43.531C2.29943 43.2648 2.46173 43.0253 2.70928 42.9276L4.2508 42.3106L4.2508 43.5108L2.30033 44.291L2.30033 43.531Z" fill="#07303E"/>
<path d="M29.9294 12.5798L30.5633 18.8604L42.1393 18.8604L42.77 12.5792C42.8433 11.8591 42.511 11.1579 41.9072 10.7587L41.7434 10.6502C38.4768 8.47434 34.2226 8.47434 30.956 10.6502L30.7915 10.7594C30.1879 11.1585 29.8557 11.8599 29.9294 12.5798ZM31.5132 11.8432L31.6777 11.734C34.5092 9.84787 38.1967 9.84788 41.0282 11.734L41.1921 11.8426C41.3924 11.9759 41.5028 12.209 41.4788 12.4485L40.9658 17.56L31.7368 17.56L31.2239 12.4479C31.201 12.2083 31.3123 11.9757 31.5132 11.8432Z" fill="#07303E"/>
<path d="M30.9866 32.2193L32.2472 32.9195C34.7974 34.3465 37.9058 34.3465 40.4561 32.9195L41.718 32.2193C42.4379 31.8173 42.8281 31.0068 42.6933 30.1934L42.1049 26.6539L30.601 26.6539L30.0113 30.1927C29.8762 31.0063 30.2664 31.8172 30.9866 32.2193ZM31.2941 30.4066L31.7024 27.9542L41.0035 27.9542L41.4125 30.4073C41.4578 30.6787 41.3273 30.9493 41.0867 31.0828L39.8261 31.783C37.668 32.9904 35.0378 32.9904 32.8798 31.783L31.6192 31.0828C31.3795 30.9481 31.2496 30.678 31.2941 30.4066Z" fill="#07303E"/>
<path d="M41.6661 8.81406L42.7479 8.09277L40.1473 4.19213L39.0655 4.91342L41.6661 8.81406Z" fill="#07303E"/>
<path d="M31.0369 8.79908L33.6387 4.89844L32.5569 4.1768L29.955 8.07745L31.0369 8.79908Z" fill="#07303E"/>
<path d="M24.2854 16.7896C24.4643 16.9107 24.6916 16.9351 24.892 16.8546L27.5869 15.7767L27.901 19.5021L26.9699 30.6197C26.7173 33.5306 28.2771 36.2981 30.8982 37.5894L31.4118 37.8449C34.5259 39.385 38.1801 39.385 41.2942 37.8449L41.8071 37.5894C44.4291 36.2989 45.9901 33.5312 45.738 30.6197L44.805 19.5021L45.1171 15.7741L47.812 16.852C48.1448 16.9867 48.5238 16.826 48.6585 16.4931C48.6896 16.4163 48.7057 16.3342 48.7059 16.2513L48.7059 14.531C48.7084 13.7325 48.222 13.0139 47.4797 12.7196L45.4428 11.905L45.7152 8.66851C45.9715 5.60693 44.233 2.7269 41.4047 1.5272L39.1707 0.573421C37.371 -0.191163 35.3376 -0.191163 33.5378 0.57342L31.3039 1.5272C28.4745 2.72601 26.7349 5.60628 26.9907 8.66851L27.2618 11.905L25.2249 12.7196C24.4832 13.0143 23.9973 13.7328 24 14.531L24 16.2513C24 16.467 24.1069 16.6686 24.2854 16.7896ZM45.4551 13.3106L46.9967 13.9276C47.2442 14.0254 47.4065 14.2648 47.4056 14.531L47.4056 15.291L45.4551 14.5108L45.4551 13.3106ZM28.265 30.7309L29.2013 19.5021L28.2858 8.55734C28.0787 6.0548 29.5012 3.70197 31.8136 2.72283L34.0475 1.76971C35.5207 1.14361 37.1853 1.14361 38.6584 1.76971L40.8924 2.72283C43.2038 3.70409 44.6243 6.05822 44.4149 8.56058L43.5047 19.5021L44.4416 30.7309C44.6466 33.1096 43.3712 35.3704 41.2291 36.425L40.7155 36.6805C37.9664 38.0407 34.7402 38.0407 31.9911 36.6805L31.4781 36.425C29.3359 35.3706 28.0602 33.1097 28.265 30.7309ZM25.3003 14.531C25.2994 14.2648 25.4617 14.0253 25.7093 13.9276L27.2508 13.3106L27.2508 14.5108L25.3003 15.291L25.3003 14.531Z" fill="#07303E"/>
<g clip-path="url(#FeatureIcon40Clip1)">
<path d="M15.766 15.7208C15.5142 18.4056 16.2684 21.069 17.8895 23.2206C19.511 25.3726 21.8632 26.832 24.5129 27.33C24.9373 27.4098 25.3114 27.1254 25.3485 26.6948C25.3855 26.2642 25.0716 25.8505 24.6471 25.7708C22.4102 25.3503 20.4314 24.1274 19.075 22.3273C17.719 20.5277 17.0893 18.2883 17.3019 16.0216C17.3221 15.8056 17.2536 15.5931 17.1267 15.4246C17.001 15.2578 16.8179 15.134 16.6069 15.0926C16.1828 15.0096 15.8063 15.2908 15.766 15.7208Z" fill="#07303E"/>
<path d="M19.3856 17.1043C19.2637 18.5324 19.7097 20.1117 20.5494 21.2262C20.5495 21.2264 20.5496 21.2266 20.5497 21.2267L20.5853 21.2739C20.5854 21.2741 20.5855 21.2742 20.5857 21.2744C21.4254 22.389 22.8207 23.2532 24.2269 23.5299C24.6509 23.6134 25.0277 23.3325 25.0684 22.9026C25.0888 22.6864 25.0204 22.4736 24.8933 22.3049C24.7677 22.1383 24.585 22.0146 24.3742 21.9732C23.3731 21.7762 22.3515 21.1513 21.7712 20.3811C21.7711 20.381 21.771 20.3809 21.7709 20.3808L21.7352 20.3334C21.7351 20.3333 21.7351 20.3332 21.735 20.3331C21.1547 19.563 20.8358 18.4085 20.9226 17.392C20.9593 16.9614 20.6451 16.5478 20.2206 16.4684C19.7962 16.3889 19.4223 16.6737 19.3856 17.1043Z" fill="#07303E"/>
</g>
</g>
<defs>
<clipPath id="FeatureIcon40Clip0">
<rect width="50" height="50" fill="white" transform="translate(50 50) rotate(-180)"/>
</clipPath>
<clipPath id="FeatureIcon40Clip1">
<rect width="21" height="19" fill="white" transform="translate(20.231 34.603) rotate(-126.996)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon40;
