import React from "react";

const FeatureIcon62 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M49.1667 6.25H13.3333C12.5833 6.25 11.8333 6.5833 11.3333 7.1667L0.75 18.4167C0.25 18.9167 0 19.6667 0 20.3334V27.0834V42.9167C0 43.4167 0.333301 43.75 0.833301 43.75H49.1666C49.6666 43.75 49.9999 43.4167 49.9999 42.9167V27.0833V12.0833V7.0833C50 6.5833 49.6667 6.25 49.1667 6.25ZM48.3333 26.25H6.6667V23.3333C6.6667 23.0833 6.75 22.75 7 22.5833L15.6667 13.25C15.9167 13.0833 16.1667 12.9167 16.5 12.9167H48.3333V26.25ZM1.6667 20.3333C1.6667 20.0833 1.75 19.8333 2 19.5833L12.5 8.25C12.75 8.0833 13 7.9167 13.3333 7.9167H48.3333V11.25H16.5C15.6667 11.25 14.9167 11.5833 14.4167 12.1667L5.75 21.4167C5.25 21.9167 5 22.6667 5 23.3334V26.25H1.6667V20.3333ZM48.3333 42.0833H1.6667V27.9167H5.8334H48.3334V42.0833H48.3333Z" fill="#07303E"/>
<path d="M30.4995 35.4166C30.2495 35.4166 30.0828 35.4999 29.9162 35.6666L28.8329 36.7499L28.8329 31.2499C28.8329 30.7499 28.4996 30.4166 27.9996 30.4166C27.4996 30.4166 27.1663 30.7499 27.1663 31.2499L27.1663 36.7499L26.083 35.6666C25.9163 35.4999 25.7497 35.4166 25.4997 35.4166C25.2497 35.4166 25.083 35.4999 24.9164 35.6666C24.5831 35.9999 24.5831 36.4999 24.9164 36.8333L27.2738 39.1907C27.41 39.4357 27.6677 39.5833 27.9998 39.5833C28.3318 39.5833 28.5894 39.4357 28.7257 39.1907L31.0832 36.8333C31.4165 36.5 31.4165 36 31.0832 35.6666C30.9162 35.4999 30.7495 35.4166 30.4995 35.4166Z" fill="#07303E"/>
<path d="M25.5 19.1667C25.75 19.1667 25.9167 19.0834 26.0833 18.9167L27.1666 17.8334V23.3334C27.1666 23.8334 27.4999 24.1667 27.9999 24.1667C28.4999 24.1667 28.8332 23.8334 28.8332 23.3334V17.8334L29.9165 18.9167C30.0832 19.0834 30.2498 19.1667 30.4998 19.1667C30.7498 19.1667 30.9165 19.0834 31.0831 18.9167C31.4164 18.5834 31.4164 18.0834 31.0831 17.75L28.7257 15.3926C28.5895 15.1476 28.3318 15 27.9998 15C27.6677 15 27.4101 15.1476 27.2738 15.3926L24.9164 17.75C24.5831 18.0833 24.5831 18.5833 24.9164 18.9167C25.0833 19.0834 25.25 19.1667 25.5 19.1667Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon62;
