// import { createReducer } from "../helpers";
import { createReducer } from "../createReducerFunc";

import {
  FETCH_INCOMING_LISTING_REQUEST,
  FETCH_INCOMING_LISTING_REQUEST_SUCCEED,
  FETCH_INCOMING_LISTING_REQUEST_FAILURE,
  ADD_REQUEST,
  REMOVE_REQUEST,
  CLAIM_LISTING_REQUEST_SUCCEED,
} from "../constants";

const initialState = {
  requests: [],
  fetchingRequests: null,
  claimedCount: null,
  incomingCount: null,
};

export default createReducer(initialState, {
  [FETCH_INCOMING_LISTING_REQUEST]: (state) => {
    return Object.assign({}, state, {
      fetchingRequests: true,
    });
  },
  [FETCH_INCOMING_LISTING_REQUEST_SUCCEED]: (state, payload) => {
    return Object.assign({}, state, {
      requests: payload.results,
      claimedCount: payload.claimed_count ?? state.claimedCount,
      incomingCount: payload.hasOwnProperty("claimed_count")
        ? payload.results.length
        : payload.incoming_count,
      fetchingRequests: false,
    });
  },
  [FETCH_INCOMING_LISTING_REQUEST_FAILURE]: (state) => {
    return Object.assign({}, state, {
      fetchingRequests: false,
    });
  },
  [ADD_REQUEST]: (state, payload) => {
    const old_requests = state.requests;
    const listing = payload.instance[0].fields;
    listing.id = payload.instance[0].pk;
    const dealershipInfo = payload.dealership_instance[0].fields;
    dealershipInfo.id = payload.dealership_instance[0].pk;
    listing.dealership_info = dealershipInfo;

    const car_details = payload.car_details;
    listing.car_details = car_details;
    old_requests.push(listing);

    return Object.assign({}, state, {
      requests: old_requests,
      incomingCount: state.incomingCount + 1,
    });
  },
  [REMOVE_REQUEST]: (state, payload) => {
    const removed = payload.instance;
    const modifiedRequests = state.requests;
    modifiedRequests.forEach((element, index) => {
      if (element.id === removed) {
        modifiedRequests.splice(index, 1);
      }
    });
    if (payload.is_claimed != null) {
      return Object.assign({}, state, {
        requests: modifiedRequests,
        claimedCount: state.claimedCount - 1,
      });
    } else {
      return Object.assign({}, state, {
        requests: modifiedRequests,
        incomingCount: state.incomingCount - 1,
      });
    }
  },
  [CLAIM_LISTING_REQUEST_SUCCEED]: (state) => {
    return Object.assign({}, state, {
      claimedCount: state.claimedCount + 1,
      // incomingCount: state.incomingCount - 1,
    });
  },
});
