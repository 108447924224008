import React from "react";

const FeatureIcon66 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon66Clip0)">
<path d="M0.780423 29.656C3.41728 29.6645 5.83337 28.1991 7.02381 25.869C7.02381 25.8442 7.02381 25.8211 7.02381 25.7956C7.02381 16.4632 12.9972 11.1111 23.4127 11.1111C33.1344 11.1072 46.8254 15.6671 46.8254 25.7917C46.8254 35.9162 33.1344 40.4762 23.4127 40.4762H0.780423C0.349434 40.4762 0 40.1301 0 39.7033V30.4289C0 30.0021 0.349434 29.656 0.780423 29.656ZM45.2645 25.7917C45.2645 16.8264 32.2252 12.653 23.4127 12.653C13.851 12.653 8.58466 17.3195 8.58466 25.7917C8.58466 34.2639 13.851 38.9304 23.4127 38.9304C32.2252 38.9304 45.2645 34.7569 45.2645 25.7917ZM1.56085 38.9304H14.956C10.5427 37.023 7.86277 33.3874 7.20097 28.3282C5.74948 29.9446 3.73491 30.9582 1.56085 31.1662V38.9304Z" fill="#07303E"/>
<path d="M24.0647 14.2857C32.198 14.2857 43.6508 17.9793 43.6508 26.1905C43.6508 34.4016 32.198 38.0952 24.0647 38.0952C15.3283 38.0952 10.3174 33.7563 10.3174 26.1905C10.3174 18.6246 15.3283 14.2857 24.0647 14.2857ZM24.0647 36.5079C31.3384 36.5079 42.1004 33.2285 42.1004 26.1905C42.1004 19.1524 31.3384 15.873 24.0647 15.873C19.5097 15.873 11.8678 17.2135 11.8678 26.1905C11.8678 35.1674 19.5097 36.5079 24.0647 36.5079Z" fill="#07303E"/>
<ellipse cx="42.6646" cy="23.9264" rx="3.64852" ry="1.50634" transform="rotate(-26.6917 42.6646 23.9264)" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.1026 24.9154C46.3057 27.1031 43.0818 29.5938 37.7007 30.1164V32.7864C37.7007 33.5032 37.2918 34.1876 36.6081 34.5056C35.9602 34.8069 35.1733 34.7275 34.5981 34.2505C34.597 34.2495 34.5958 34.2486 34.5947 34.2476L25.5843 26.8185C25.5838 26.8181 25.5834 26.8177 25.5829 26.8173C25.1564 26.4663 24.8904 25.9322 24.8904 25.3563C24.8904 24.7943 25.1368 24.2589 25.577 23.8961C25.5778 23.8954 25.5786 23.8947 25.5795 23.894L34.6033 16.4245C35.1514 15.9801 35.9285 15.8533 36.6145 16.183C37.2862 16.5007 37.6959 17.1731 37.6959 17.8969V20.4456C41.2474 20.3262 45.4153 19.6079 47.7534 15.4845C48.0983 14.8743 48.7377 14.5227 49.4059 14.5227C49.5239 14.5227 49.7035 14.5327 49.9045 14.5895C50.7382 14.8159 51.2992 15.573 51.2999 16.4151C51.2999 16.4157 51.2999 16.4162 51.2999 16.4167H49.9999L51.2999 16.4136C51.2999 16.4141 51.2999 16.4146 51.2999 16.4151C51.3051 18.6931 50.4513 22.0542 48.1026 24.9154ZM48.8849 16.1246C48.992 15.9347 49.1917 15.8227 49.4059 15.8227C49.4546 15.8227 49.5081 15.8276 49.5568 15.8422M48.6999 16.4392C48.6998 16.4394 48.7 16.4391 48.6999 16.4392V16.4392Z" fill="white"/>
<path d="M47.098 24.0904C45.3744 26.1889 42.1268 28.6867 36.4008 28.901V32.7864C36.4008 33.0153 36.2693 33.2295 36.0599 33.3269C35.8506 33.4243 35.6023 33.3951 35.427 33.249L26.4095 25.814C26.2732 25.702 26.1904 25.5316 26.1904 25.3563C26.1904 25.1761 26.2683 25.0106 26.4047 24.8986L35.4221 17.4344C35.6023 17.2883 35.8457 17.2542 36.0551 17.3565C36.2644 17.4539 36.3959 17.6632 36.3959 17.897V21.7678C40.1353 21.7532 45.8516 21.4757 48.885 16.1246C48.9921 15.9347 49.1917 15.8228 49.4059 15.8228C49.4546 15.8228 49.5082 15.8276 49.5569 15.8422C49.8198 15.9104 50 16.149 50 16.4168C50.0048 18.4179 49.2404 21.4806 47.098 24.0904ZM35.8019 22.9607C35.6412 22.9607 35.4903 22.8974 35.3783 22.7855C35.2663 22.6735 35.203 22.5225 35.203 22.3619V19.1629L27.729 25.3514L35.2079 31.5205V28.3215C35.2079 27.9953 35.4708 27.7275 35.797 27.7226C44.3665 27.6399 47.5606 22.4154 48.4906 18.7442C44.7998 22.9559 39.2735 22.9607 35.8019 22.9607Z" fill="#07303E"/>
</g>
<defs>
<clipPath id="FeatureIcon66Clip0">
<rect width="50" height="50" fill="white" transform="matrix(-1 0 0 1 50 0)"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon66;
