import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Visualisation from "./CosmeticConditionProto";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";
import Tour from "./Tour";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  reportGrid: {
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
    },
  },
  reportPaper: {
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
    },
  },
}));

const VisIns = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const carId = props.match.params.id;
  const [imgLoaded, setImgLoaded] = useState(false);

  const renderDialog = () => {
    return (
      <>
        <Hidden smDown>
          <Tour
            imgLoaded={imgLoaded}
            setImgLoaded={setImgLoaded}
            fromBigScreen={true}
            carId={carId}
            openVisualization={true}
          >
            <Visualisation />
          </Tour>
        </Hidden>
      </>
    );
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.reportPaper}>{renderDialog()}</Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, null)(VisIns));
