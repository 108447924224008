import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: "none",
    width: "100%",
    height: "100%",
  },
  dialogTitle: {
    borderBottom: "3px solid #07303E",
    marginInline: "16px",
    paddingInline: 0,
    paddingTop: "32px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#07303E",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "34px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: "4px",
  },
  dialogContent: {
    flexDirection: "row",
    display: "flex",
    padding: 0,
    overflow: "hidden",
  },
}));

const PapaPopup = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { children, title, onClose } = props;

  return (
    <Dialog {...props} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle id="Paparazzi-Hub" className={classes.dialogTitle}>
        <Typography className={classes.title}>{t(title)}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default PapaPopup;
