import React from "react";

const GPSOutlined = (props) => {
  const { size, fill, style, className } = props;
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 142 143"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M71.0388 31.3599C56.5285 31.3599 44.7234 43.165 44.7234 57.6753C44.7234 72.1856 56.5285 83.9908 71.0388 83.9908C85.5491 83.9908 97.3543 72.1856 97.3543 57.6753C97.3543 43.165 85.5494 31.3599 71.0388 31.3599ZM71.0388 72.9107C62.6381 72.9107 55.8035 66.0761 55.8035 57.6753C55.8035 49.2746 62.6381 42.44 71.0388 42.44C79.4395 42.44 86.2742 49.2746 86.2742 57.6753C86.2742 66.0761 79.4395 72.9107 71.0388 72.9107Z"
          fill={fill}
        />
        <path
          d="M71.0398 0.887939C39.7277 0.887939 14.2536 26.3623 14.2536 57.6741V59.2438C14.2536 75.0797 23.3327 93.5334 41.2393 114.091C54.2203 128.995 67.0199 139.387 67.5581 139.822L71.0398 142.635L74.5214 139.822C75.0599 139.387 87.8595 128.995 100.84 114.092C118.747 93.5334 127.826 75.0799 127.826 59.2441V57.6744C127.826 26.3623 102.352 0.887939 71.0398 0.887939ZM116.746 59.2441C116.746 86.0058 82.2638 118.401 71.0398 128.236C59.8127 118.398 25.3337 86.0039 25.3337 59.2441V57.6744C25.3337 32.4721 45.8375 11.9683 71.0398 11.9683C96.242 11.9683 116.746 32.4721 116.746 57.6744V59.2441Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="141.747"
            height="141.747"
            fill="white"
            transform="translate(0.134521 0.888062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GPSOutlined;
