import React from "react";

const FeatureIcon33 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 64" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M38.0061 1.28371L37.9392 0L36.6616 0.137497C36.5363 0.150881 24.1871 1.63901 23.3402 15.4458L23.2551 16.8281L24.6373 16.7344C24.7785 16.7247 38.7654 15.6089 38.0061 1.28371ZM35.5957 2.82537C35.3633 11.1457 28.89 13.4601 25.9186 14.0843C27.0502 5.99874 32.9139 3.53476 35.5957 2.82537Z" fill="#07303E"/>
<path d="M49.9087 45.6501L49.1312 45.3982C44.4259 43.8772 41.2659 39.5674 41.2659 34.6735C41.2659 30.3198 43.7275 26.4261 47.6918 24.5109L48.8745 23.9402L48.215 22.805C47.9327 22.3183 45.2935 18.0072 39.7182 16.5799C35.2964 15.4483 30.3246 16.4497 24.9355 19.5574C22.654 18.1727 15.0759 14.1816 9.04673 18.1824C7.86645 18.8346 -4.6433 26.3288 1.86771 46.5493C2.05874 47.0019 6.59856 57.6378 11.9707 61.6216C13.4198 63.0063 17.1262 64.7365 21.3618 61.8491C22.0955 61.5461 27.0052 59.6565 30.1202 61.8698C31.2701 62.6571 33.2778 63.549 35.3986 63.549C37.0072 63.549 38.6803 63.0367 40.0942 61.5765C40.7537 61.0059 46.778 55.6289 49.9087 47.1114L50 46.8632L49.9087 45.6501ZM38.4685 59.7623L38.3749 59.8512C35.7065 62.6534 31.6606 59.9728 31.511 59.8731C29.9194 58.7414 28.076 58.3728 26.347 58.3728C23.2089 58.3728 20.4492 59.5883 20.2728 59.6674L20.0744 59.7794C16.401 62.3431 13.8859 60.0872 13.6194 59.8305L13.4758 59.7076C8.6172 56.1643 4.15161 45.706 4.14552 45.7024C-1.94813 26.7595 9.72569 20.5637 10.2221 20.3118L10.356 20.2327C16.0104 16.4363 24.1385 21.9313 24.2188 21.9861L24.8673 22.4314L25.539 22.0262C30.6008 18.966 35.1686 17.9257 39.1147 18.9368C42.3294 19.7594 44.3785 21.7683 45.3811 23.0033C41.3085 25.4989 38.8336 29.8538 38.8336 34.6735C38.8336 40.2171 42.1615 45.1427 47.2318 47.2854C44.1108 54.9037 38.5282 59.7124 38.4685 59.7623Z" fill="#07303E"/>
</svg>
);
};

export default FeatureIcon33;
