import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import ForwardArrowIcon from "../components/icons/ForwardArrow.js";
import BackArrowIcon from "../components/icons/BackArrow.js";

import OpenListIcon from "../components/icons/VisOpenList.js";

import { getCarFaults, getCarVisualization } from "../store/actions/car";
import { toggleNotification } from "../store/actions/notification";
import { updatePoints } from "../store/actions/forms";

import Button from "../components/Button";
import DrawerLeft from "../components/CosmeticConditionDrawerProto";
import { useTranslation } from "react-i18next";
import theme from "../theme";

import UnFaultyAnswer from "../icons/unFaultyPoint.svg";
import InformativeAnswer from "../icons/infoPoint.svg";
import FaultyAnswer from "../icons/faultyPoint.svg";

import DFAIcon from "../components/icons/DFAIcon.js";
import PRAIcon from "../components/icons/PRAIcon.js";
import DVIcon from "../components/icons/DVIcon.js";
import PVIcon from "../components/icons/PVIcon.js";
import THDIcon from "../components/icons/THDIcon.js";
import TVIcon from "../components/icons/TVIcon.js";
import { ListItemIcon } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import HotSpot from "../components/HotSpot.js";

const positions = [
  {
    id: "ext",
    title: "Exterior",
    sides: [
      { id: "dfv", title: "Driver Front View" },
      { id: "prf", title: "Passenger Rear View" },
    ],
  },
  {
    id: "int",
    title: "Interior",
    sides: [
      { id: "fv", title: "Front View" },
      { id: "rf", title: "Rear View" },
      { id: "3rd", title: "3rd Row View" },
      { id: "tv", title: "Trunk View" },
    ],
  },
];

const sideIconsStyle = { height: "4.93vw", width: "9.86vw" };

const combinedPositions = {
  dfv: "ext",
  prf: "ext",
  fv: "int",
  rf: "int",
  "3rd": "int",
  tv: "int",
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "black",
    },
  },
  landStepperImg: {
    overflow: "hidden",
    display: "block",
    margin: "auto",
    // width: "100%",
    // // height: "69.09vh",
    // height: !isIOS() ? "69.09vh" : "100%",

    // height: calc(100vh - 102.5px);
    // width: calc(calc(100vh - 102.5px) / 0.75);

    width: !isIOS()
      ? "calc(calc(100vh - 122.5px) / 0.75)"
      : "calc(calc(100vh - 102.5px) / 0.75)",
    height: !isIOS() ? "calc(100vh - 122.5px)" : "calc(100vh - 102.5px)",
    objectFit: "cover",
  },
  portStepperImg: {
    overflow: "hidden",
    display: "block",
    margin: "auto",
    // width: "100%",
    // // height: "69.09vw",
    // height: !isIOS() ? "69.09vw" : "100%",
    width: "calc(100vw - 32px)",
    height: "calc(calc(100vw - 32px) * 0.75)",
    objectFit: "cover",
  },
  visImageDiv: {
    height: "100%",
    width: "100%",
    right: 0,
    position: "fixed",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "black",
      // backgroundColor: "yellow",
    },
  },
  imgBig: {
    display: "block",
    margin: "auto",
    // width: "100%",
    // height: "52.79vh",
    width: "600px",
    height: "450px",
    objectFit: "cover",
    // position: "absolute",
    // transform: "translate(-50%,-55%)",
    // left: "50%",
    // bottom: 0,
    borderRadius: "4px",
  },
  fullland: {
    // styles for image in landscape orientation
    position: "fixed",
    top: "50%",
    left: "calc(50% - 7.8vw)",
    transform: "translate(-50%, -50%)",
    objectFit: "cover",
    width: "auto",
  },
  fullport: {
    // styles for image in portrait orientation
    position: "fixed",
    left: "0",
    transformOrigin: "top left", ///
    transform: "rotate(90deg) translate(0, -100%)",
    transition: "none",
    objectFit: "cover",
  },
  webExit: {
    // styles for X in landscape orientation
    position: "fixed",
    top: "0%", // this will work for web but not mobile fix later
    left: "100%", // this will work for web but not mobile fix later
    transform: "translate(-50%, -50%)",
  },
  MobExit: {
    position: "fixed",
    top: "32px",
    left: "32px",
    transform: "translate(-50%, -50%)",
  },
  closeIconGrid: {
    borderRadius: "50%",
    backgroundColor: "rgba(7, 48, 62, 0.8)",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(0, 0, 0, 0.49)",
    },
  },
  closeIcon: {
    fill: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18.1px",
      padding: "3px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22.8px",
      padding: "3.8px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "26.6px",
      padding: "3.8px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "31.4px",
      padding: "3.8px",
    },
  },
  portDiv: {
    left: "0",
    transform: "rotate(90deg) translate(0, -100%)",
    transformOrigin: "top left",
  },
  landDiv: {
    top: "50%",
    left: "calc(50% - 7.8vw)",
    transform: "translate(-50%, -50%)",
  },
  exampleWrapper: {
    position: "relative",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  views: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  popoverPaper: {
    minWidth: "395px",
    backgroundColor: "rgba(255,255,255,0)",
  },
  selectionButton: {
    borderLeft: "none!important",
    borderTopLeftRadius: "0px!important",
    borderTopRightRadius: "8px!important",
    borderBottomRightRadius: "8px!important",
    borderBottomLeftRadius: "0px!important",
    [theme.breakpoints.up("md")]: {
      width: "110px!important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "110px!important",
    },
    [theme.breakpoints.up("xl")]: {
      width: "126px!important",
    },
  },
  bigScreenImage: {
    objectFit: "cover",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "419.95px",
      width: "559.93px",
      right: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "472.33px",
      width: "629.77px",
      right: "130px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "540px",
      width: "720px",
      right: "126px",
    },
  },
  viewSelection: {
    width: "72.4138%",
    height: "15.9827%",
    position: "fixed",
    right: "0",
    backgroundColor: "white",
    zIndex: "2000",
    backgroundColor: "white",
    display: "flex",
    top: "84.0183%",
    backgroundColor: "#F3F4FA",
  },
  viewTitle: {
    color: "#07303E",
    textAlign: "center",
    fontWeight: "600",
    minWidth: "100%",
    padding: "3.5px 0",
    borderRadius: "0 0 8px 8px",
    [theme.breakpoints.up("md")]: {
      fontSize: "9.34px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10.51px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "12px",
    },
  },
  choicesDiv: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      right: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      right: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      right: "0px",
    },
  },
  selectionDiv: {
    border: "1px solid #7D929C",
    borderRadius: "8px",
    marginBottom: "4.8124px",
    backgroundColor: "#F3F4FA",
    padding: 0,
    float: "right",
    [theme.breakpoints.up("md")]: {
      height: "65.98px",
      width: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "74.73px",
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "86px",
      width: "117px",
    },
  },
  selectionLabel: {
    flexWrap: "wrap",
  },
  viewSelectionImage: {
    borderBottom: "1px solid #7D929C",
    [theme.breakpoints.up("md")]: {
      height: "41px",
      width: "fit-content",
      objectFit: "cover",
    },
    [theme.breakpoints.up("lg")]: {
      height: "46px",
    },
    [theme.breakpoints.up("xl")]: { height: "58px" },
  },
  viewSelectionImageWidth: {
    [theme.breakpoints.up("md")]: {
      height: "46px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "51px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "59px",
    },
  },

  imgDiv: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "419.95px",
      width: "559.93px",
      right: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "472.33px",
      width: "629.77px",
      right: "130px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "540px",
      width: "720px",
      right: "126px",
    },
  },
  dialogPaperWidthSm: {
    maxWidth: 700,
  },
  dialogPaper: {
    backgroundColor: "transparent",
    overflow: "auto",
    // height: window.screen.width > 960 ? "70%" : "100%",
    height: "100%", /////here
    maxHeight: isIOS && "100vh",
    margin: 0,
    boxShadow: "none",
  },
  dialogContentRoot: {
    alignItems: "center", /////here
    display: "flex", /////here
    // height: "60vh",
    height: "100vh", /////here
    overflow: "hidden",
  },
  landIosDialogContentRoot: {
    // width: "60vw",
    width: "calc(calc(100vh - 102.5px) / 0.75)",
    overflow: "hidden",
  },
  visualizationDialogDiv: {
    position: "relative",
    transform: "translate(0%,0%)",
    top: "50%",
    left: "0%",

    [theme.breakpoints.down("sm")]: {
      all: "initial",
    },

    [theme.breakpoints.up("md")]: {
      width: "947.04px",
      height: "460px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1037px",
      height: "510px",
      right: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1165px",
      height: "590px",
    },
  },
  imageStepperTypography: {
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  stepperToggler: {
    display: "inline-flex",
    height: "33.04px",
    width: "33.06px",
    top: "100%",
    transform: "translate(0,-100%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  bigStepCounterDiv: {
    position: "absolute",
    height: "33.04px",
    width: "33.06px",
    top: "100%",
    transform: "translate(0,-100%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepCounterDiv: {
    position: "absolute",
    top: "100%",
    transform: "translate(0,-100%)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    height: "7.38vh",
    width: "6.25vw",
    display: "flex",
  },
  portStepCounterDiv: {
    position: "absolute",
    top: "100%",
    transform: "translate(0,-100%)",
    background:
      "linear-gradient(270deg, rgba(0, 0, 0, 0) -52.5%, rgba(0, 0, 0, 0.7) 138.33%)",
    height: "7.38vw",
    width: "6.25vh",
    display: "flex",
  },
  stepCounter: {
    color: "white",
    padding: "8px 17px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "5px 6px",
      margin: "auto",
    },
  },
  bigStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    top: "70%",
    // transform: "translate(0,-50%)",
    cursor: "pointer",
    height: "3em",
    width: "3em",
    // transform: "translate(0,50%)",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    top: "100%",
    // transform: "translate(0,15%)",
    background: "#fff",
    color: "#186985",
    cursor: "pointer",
    height: "1.9em",
    width: "1.9em",
    margin: "auto 0",
    borderRadius: "50%",
  },
  portStepperBackArrow: {
    // position: "absolute",
    display: "flex",
    // top: "50%",
    // transform: "translate(0,-50%)",
    cursor: "pointer",
    width: "5.66vh",
    height: "5.66vh",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
    // top: "100%",
    transform: !isIOS ? "translate(0,50%)" : "",
    margin: "auto 0",
  },
  bigStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    top: "70%",
    left: "100%",
    // transform: "translate(-115%,50%)",
    cursor: "pointer",
    height: "3em",
    width: "3em",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
  },
  landStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    top: "100%",
    left: "100%",
    // transform: "translate(-100%,15%)",
    margin: "auto 0",
    background: "#fff",
    color: "#186985",
    cursor: "pointer",
    height: "1.9em",
    width: "1.9em",
    borderRadius: "50%",
  },
  portStepperForwardkArrow: {
    // position: "absolute",
    display: "flex",
    // top: "70%",
    // left: "100%",
    // transform: "translate(-100%,-50%)",
    cursor: "pointer",
    width: "5.66vh",
    height: "5.66vh",
    background: "#fff",
    color: "#186985",
    borderRadius: "50%",
    // top: "100%",
    left: "100%",
    transform: !isIOS ? "translate(-100%,15%)" : "",
    // : "translate(-100%,50%)",
    margin: "auto 0",
  },
  viewsIconsDiv: { left: "100%" },
  viewListDiv: {
    height: "38px",
    width: "38px",
    backgroundColor: "rgba(0,0,0, 0.75)", // transparent inner black circle
    border: "1px solid rgba(255, 255, 255, 0.75)", // transparent white ring
    transform: "translate(-50%,-50%)",
    position: "absolute",
    left: "32px",
    borderRadius: "50%",
    display: "flex",
    top: "calc(100% - 40px)",
    boxShadow: "0 0 0px 2px rgba(0,0,0, 0.75)", // transparent outer black ring
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  visButtonTextLand: {
    fontSize: "1.4vw",
    maxWidth: "100%",
    marginBottom: "auto",
    textTransform: "none",
  },
  visButtonTextPort: {
    fontSize: "1.4vh",
    maxWidth: "100%",
    marginBottom: "auto",
    textTransform: "none",
  },
  visButtonRootLand: {
    maxHeight: "none",
    height: "calc(19vh - 3px)",
    minHeight: "calc(12vw - 3px)",
  },
  visButtonRootPort: {
    maxHeight: "none",
    height: "calc(19vw - 3px)",
    minHeight: "calc(12vh - 3px)",
  },
  visButtonRootTHDLand: {
    maxHeight: "none",
    height: "calc(16vh - 3px)",
    minHeight: "calc(12vw - 3px)",
  },
  visButtonRootTHDPort: {
    maxHeight: "none",
    height: "calc(16vw - 3px)",
    minHeight: "calc(12vh - 3px)",
  },
  visButtonSelected: {
    background:
      "linear-gradient(180deg, rgba(127, 241, 248, 0) 0%, rgba(0, 228, 242, 0.63) 100%)",
  },
  visButtonWrapper: {
    width: "100%",
  },
  visStartIconExterior: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    padding: "5px 0",
  },
  visStartIconInterior: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    padding: "0",
    margin: "0 auto auto",
  },
  visLabel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  hotSpot: {
    cursor: "pointer",
  },
  openListIcon: { margin: "auto ", height: "17px", width: "20px" },
  arrowAnimation: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeOut}`,
  },
  arrowIcon: {
    margin: "auto",
    fill: "#07303E",
    [theme.breakpoints.up("md")]: {
      width: "19.53px",
      height: "30.48px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "21.97px",
      height: "34.27px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25px",
      height: "39px",
    },
  },
  arrowAnimationExit: {
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes myEffect": {
    "0%": {
      width: "33px",
    },
    "50%": {
      width: "25px",
    },
    "100%": {
      width: "33px",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      width: "33px",
    },
    "50%": {
      width: "25px",
    },
    "100%": {
      width: "33px",
    },
  },
  stepperText: {
    // position: "absolute",
    display: "inline-flex",
    // top: "70%",
    left: "50%",
    width: !isIOS ? "80%" : "70%",
    // transform: !isIOS ? "translate(-50%,50%)" : "translate(-50%,35%)",
    cursor: "pointer",
    color: "#fff",
    justifyContent: "space-between",
    fontFamily: "Poppins",
  },
}));

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isIOSInApp() {
  return isIOS() && /AppName/i.test(navigator.userAgent);
}

const RenderStepper = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { images, option_score, open, setOpen, detailedFaultsWithImages } =
    props;
  const onClose = () => {
    setOpen(false);
  };
  const fromBigScreen = props.fromBigScreen;
  const [nextClicked, setNextClicked] = useState(false); // used as a condition for clsx in arrow class pick
  const [backClicked, setBackClicked] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [data, setdata] = useState([]);
  const maxSteps = images?.length;

  const handleNext = () => {
    if (activeStep != maxSteps - 1) {
      activeStep + 1 === maxSteps
        ? setActiveStep(0)
        : setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextClicked(!nextClicked);
      setImageLoaded(false);
    }
    if (
      images[activeStep] ==
        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png" ||
      images[activeStep] ==
        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png"
    ) {
      setImageLoaded(true);
    }
  };

  const handleBack = () => {
    if (activeStep != 0) {
      activeStep === 0
        ? setActiveStep(maxSteps - 1)
        : setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setBackClicked(!backClicked);
      setImageLoaded(false);
    }
    if (
      images[activeStep] ==
        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgEn.png" ||
      images[activeStep] ==
        "https://markabte-static.s3.eu-central-1.amazonaws.com/NoImgAr.png"
    ) {
      setImageLoaded(true);
    }
  };

  return (
    <div
      style={
        fromBigScreen
          ? {
              // width: "700px",
              width: "600px",
            }
          : isPortrait
          ? theme.direction === "ltr"
            ? isIOS()
              ? {
                  // transform: "translate(-50%,-50%) rotate(90deg)", /////?????
                  // transform: "translate(-50%,-50%)", /////?????
                  // position: "relative",
                  // // top: "40vh",
                  // // left: "38%",
                  // left: "50%",
                  // top: "50%",

                  // width: "50vh",
                  // width: "95%",
                  width: "calc(100vw - 32px)",
                  // overflow: "scroll",
                  // height: "100vw",
                }
              : {
                  // transform: "translate(-50%,-50%) rotate(90deg)",
                  transform: "translate(-50%,-50%)", /////here
                  position: "fixed",
                  // top: "50vh",
                  top: "50%",
                  left: "50%",
                  // width: "60vh",
                  // width: "95%", /////here
                  width: "calc(100vw - 32px)",
                }
            : isIOS()
            ? {
                // transform: "translate(-50%,-50%) rotate(90deg)", /////?????
                // transform: "translate(-50%,-50%)", /////?????
                // position: "relative",
                // // top: "40vh",
                // // left: "38%",
                // // top: "50vh",
                // top: "50%",
                // // left: "50vw",
                // left: "50%",
                // width: "95%",
                width: "calc(100vw - 32px)",
                // overflow: "scroll",
                // height: "100vw",
              }
            : {
                // transform: "translate(-50%,-50%) rotate(-90deg)",
                transform: "translate(-50%,-50%)", /////here
                position: "fixed",
                // top: "50vh",
                top: "50%",
                left: "50%",
                // width: "60vh",
                // width: "95%",
                width: "calc(100vw - 32px)",
              }
          : isIOS() && !isPortrait
          ? {
              // width: "50vw"
            }
          : {
              // transform: "translate(-50%,-50%)",
              // position: "fixed",
              // // top: "50vh",
              // top: "50%",
              // left: "50%",
              // width: "50vw",
              // overflow: "visible",
            }
      }
    >
      <div
        style={
          isPortrait
            ? {
                transformOrigin: "top left",
                backgroundColor: "transparent",
                padding: !isIOS() ? "10px 0px" : "0",
                color: "#07303E",
                display: "flex",
                overflow: "visible",
                justifyContent: "space-between",
              }
            : {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "transparent",
                padding: !isIOS() ? "10px 0px" : "0",
                color: "#07303E",
              }
        }
      >
        <div style={{ color: "#fff", fontFamily: "Poppins", width: "250px" }}>
          <div style={{ display: "inline-flex", width: "300px" }}>
            <Grid
              item
              xs={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ListItemIcon style={{ minWidth: "0" }}>
                <div style={{ margin: "auto 0" }}>
                  {detailedFaultsWithImages[activeStep] &&
                  detailedFaultsWithImages[activeStep][
                    Object.keys(detailedFaultsWithImages[activeStep])[0]
                  ][1] <= 50 ? (
                    <img src={FaultyAnswer} />
                  ) : detailedFaultsWithImages[activeStep][
                      Object.keys(detailedFaultsWithImages[activeStep])[0]
                    ][1] <= 99 ? (
                    <img src={InformativeAnswer} />
                  ) : (
                    <img src={UnFaultyAnswer} />
                  )}
                </div>
              </ListItemIcon>
            </Grid>
            <span
              style={{
                fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
              }}
            >
              {detailedFaultsWithImages[activeStep] &&
              detailedFaultsWithImages[activeStep][
                Object.keys(detailedFaultsWithImages[activeStep])[0]
              ][1] <= 50
                ? t("Major Issue")
                : detailedFaultsWithImages[activeStep][
                    Object.keys(detailedFaultsWithImages[activeStep])[0]
                  ][1] <= 99
                ? t("Minor Issue")
                : t("Excellent")}
            </span>
          </div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1.25em",
              margin: "0 11px",
              fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
            }}
          >
            {detailedFaultsWithImages[activeStep]
              ? detailedFaultsWithImages[activeStep][
                  Object.keys(detailedFaultsWithImages[activeStep])[0]
                ][2]
              : ""}
          </div>
        </div>
        <IconButton
          style={{
            padding: 0,
            backgroundColor: "#fff",
            borderRadius: "50%",
            color: "#07303E",
            marginTop: "10px",
            width: !fromBigScreen && !isIOS() && isPortrait ? "10vw" : "28px",
            height: !fromBigScreen && !isIOS() && isPortrait ? "10vw" : "28px",
          }}
          onClick={onClose}
        >
          <CloseIcon
            style={
              fromBigScreen
                ? { fill: "#186985", fontSize: "2rem" }
                : isPortrait
                ? { fill: "#186985", fontSize: "clamp(1rem,6.3vw,2rem" }
                : { fill: "#186985", fontSize: "clamp(1rem,6.3vh,2rem" }
            }
          />
        </IconButton>
      </div>
      <Paper
        style={
          fromBigScreen
            ? {
                // backgroundColor: "transparent",
                // width: "100%",
                // height: "52.7vh",
                backgroundColor: "white",
                width: "600px",
                height: "450px",
              }
            : {
                backgroundColor: "transparent",
                position: "relative",
                overflow: "visible",
                height: isIOS()
                  ? isPortrait
                    ? ""
                    : "calc(100vh - 102.5px)"
                  : "fit-content",
                // height: calc(100vh - 102.5px);
                // width: calc(calc(100vh - 102.5px) / 0.75);
              }
        }
      >
        {!imageLoaded && (
          <div
            className={
              fromBigScreen
                ? classes.imgBig
                : isPortrait
                ? classes.portStepperImg
                : classes.landStepperImg
            }
            style={{
              position: "absolute",
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress color={"primary"} />
            </div>
            <Skeleton
              style={{
                transform: "none",
              }}
              className={
                fromBigScreen
                  ? classes.imgBig
                  : isPortrait
                  ? classes.portStepperImg
                  : classes.landStepperImg
              }
            />
          </div>
        )}
        <img
          style={
            fromBigScreen
              ? {
                  borderRadius: "4px",
                }
              : isPortrait
              ? {}
              : {}
          }
          className={
            fromBigScreen
              ? classes.imgBig
              : isPortrait
              ? classes.portStepperImg
              : classes.landStepperImg
          }
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={
            images[activeStep]
            // detailedFaultsWithImages[activeStep]
            //   ? detailedFaultsWithImages[activeStep][
            //       Object.keys(detailedFaultsWithImages[activeStep])[0]
            //     ][0]
            //   : ""
          }
          // alt={
          //   images[activeStep]
          //     ? images[activeStep][Object.keys(images[activeStep])[0]][0]
          //     : ""
          // }
        />
      </Paper>
      <div
        style={{
          display: "inline-flex",
          position: "relative",
          justifyContent: "space-between",
          width: "100%",
          marginTop: fromBigScreen && "17px",
        }}
      >
        {maxSteps !== 1 && (
          <div
            className={
              fromBigScreen
                ? classes.bigStepperBackArrow
                : isPortrait
                ? classes.portStepperBackArrow
                : classes.landStepperBackArrow
            }
            onClick={() => {
              localStorage.getItem("language") === "ar"
                ? handleNext()
                : handleBack();
            }}
          >
            {localStorage.getItem("language") === "en" ? (
              <BackArrowIcon
                className={clsx(classes.arrowAnimation, {
                  [classes.arrowAnimationExit]: backClicked,
                })}
                size={fromBigScreen ? "27" : isPortrait ? "5vw" : "3vh"}
                style={{
                  margin: "auto",
                  fill: 0 != activeStep ? "#186985" : "#B8B8B8",
                }}
              />
            ) : (
              <ForwardArrowIcon
                className={clsx(classes.arrowAnimation, {
                  [classes.arrowAnimationExit]: backClicked,
                })}
                size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                style={{
                  margin: "auto",
                  fill: activeStep + 1 != maxSteps ? "#186985" : "#B8B8B8",
                }}
              />
            )}
          </div>
        )}

        <div
          className={classes.stepperText}
          style={{
            top: fromBigScreen ? "70%" : isPortrait ? "90%" : "90%",
            fontSize: fromBigScreen ? "21px" : "18px",
            justifyContent: maxSteps == 1 && "center",
            width: maxSteps == 1 && "100%",
            textAlign: maxSteps == 1 && "center",
          }}
        >
          {maxSteps > 1 && (
            <div
              style={{
                lineHeight: "50px",
                fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
              }}
            >
              {localStorage.getItem("language") === "en"
                ? t("Previous")
                : t("Next")}
            </div>
          )}
          <div style={{ lineHeight: "50px", fontSize: "0.8em" }}>
            {activeStep + 1} / {maxSteps}
          </div>
          {maxSteps > 1 && (
            <div
              style={{
                lineHeight: "50px",
                fontSize: !fromBigScreen && isPortrait && isIOS() && "12px",
              }}
            >
              {localStorage.getItem("language") === "en"
                ? t("Next")
                : t("Previous")}
            </div>
          )}
        </div>

        {maxSteps !== 1 && (
          <div
            className={
              fromBigScreen
                ? classes.bigStepperForwardkArrow
                : isPortrait
                ? classes.portStepperForwardkArrow
                : classes.landStepperForwardkArrow
            }
            onClick={() => {
              console.log("bbbbbbbb", maxSteps, activeStep);
              localStorage.getItem("language") === "ar"
                ? handleBack()
                : handleNext();
            }}
          >
            {localStorage.getItem("language") === "en" ? (
              <ForwardArrowIcon
                className={clsx(classes.arrowAnimation, {
                  [classes.arrowAnimationExit]: nextClicked,
                })}
                size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                style={{
                  margin: "auto",
                  fill: activeStep + 1 != maxSteps ? "#186985" : "#B8B8B8",
                }}
              />
            ) : (
              <BackArrowIcon
                className={clsx(classes.arrowAnimation, {
                  [classes.arrowAnimationExit]: nextClicked,
                })}
                size={fromBigScreen ? "20" : isPortrait ? "5vw" : "3vh"}
                style={{
                  margin: "auto",
                  fill: 0 != activeStep ? "#186985" : "#B8B8B8",
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function FaultsStepper(props) {
  const classes = useStyles();
  const fromBigScreen = !!(window.screen.width >= 960);
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  //stepper inside a dialog component to shoe images of a faulty point

  // image stepper component there are 3 styles one for big screen and 2 for mobile(portrait & landscape)

  return (
    <div style={{ overflow: "visible", height: "100%" }}>
      <Dialog
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.65)" } }}
        classes={{
          paperWidthSm: classes.dialogPaperWidthSm,
          paper: classes.dialogPaper,
        }}
        style={{
          zIndex: "2000",
          position: "fixed",
          top: 0,
          left: 0,
          maxWidth: "679",
          // overflow: "scroll",
        }}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          classes={{
            root:
              !fromBigScreen && isIOS() && !isPortrait
                ? classes.landIosDialogContentRoot
                : classes.dialogContentRoot,
          }}
          style={{
            padding: 0,
            overflow: !isPortrait && !fromBigScreen && "visible",
          }}
        >
          <RenderStepper {...props} fromBigScreen={fromBigScreen} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FaultsStepper;
