import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import TextInput from "../../components/TextInput";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { useState } from "react";
// import CustomButton from "../../../components/Button";
import SelectDialog from "../PaparazziTester/SelectDialog";
import CustomButton from "../../components/Button";
import imageMerger from "../../helpers/mergeImages";
import {
  dataURLtoFile,
  handleCompress,
  handleCompressMiniImage,
} from "../../helpers/aws";
import { urlToObject } from "../../helpers/imagesFunctions";
// import { changeBackgrounds } from "../../store/actions/car";
import { connect } from "react-redux";
import { changeBackgrounds, getCar } from "../../store/actions/car";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import { Children } from "react";
import { array } from "prop-types";
import {
  getPaparazziCarImages,
  getPaparazziPackage,
  getPaparazziPackageList,
  getPaparazziShadows,
  getTestPaparazziPackage,
} from "../../store/actions/forms";
import useNonInitialEffect from "../../helpers/useNonInitialEffect";
import { useEffect } from "react";
import FiltersTypes from "../../helpers/filter-types";
import { useTranslation } from "react-i18next";
import { toggleNotification } from "../../store/actions/notification";
import PapaTestRadioGroup from "../../components/PapaTestRadioGroup";

const useStyles = makeStyles((theme) => ({
  carPartcontainer: {
    flex: "12",
    height: "calc(100vh - 70px)",
    overflow: "scroll",
    "& .MuiGrid-container": {
      padding: "10px",
      textAlign: "center",
      img: {
        width: "300px",
      },
    },
  },
  formPartLabel: {
    color: "#07303E",
    fontSize: "20px",
    fontWeight: 600,
  },
  formPartLabelDividor: {
    borderBottom: "2px solid #D4D4D4",
    width: "100%",
    // paddingBlock: "10px",
    marginBlock: "13px",
  },
  form_container: {
    div: {},
  },
  controlPartSpacing: {
    // marginBlock: "13px",
    marginBlock: "24px",
  },
  filedTitle: {
    marginBlock: "10px",
  },
  // paperWidthSm: { maxWidth: "90%", width: "calc(100% - 64px)" },
  paperWidthSm: { maxWidth: "100%" },
  visPaperPosition: {
    height: "100%",
    // transform: "translate(-50%)",
    left: "50%",
  },
  PapaStudioHead: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    "& div:first-child": {
      display: "flex",
      //   justifyContent: "space-between",
      alignItems: "center",
    },
    // div: {
    //   display: "flex",
    //   justifyContent: "space-between",
    // },
    borderBottom: "3px solid #000",
  },
  closeButt: {
    background: "#186985",
    color: "#fff",
    borderRadius: "50%",
    padding: "17px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginRight: "20px",
    "& span": { position: "absolute", fontSize: "25px", lineHeight: "0" },
  },
  bgsList: {
    textAlign: "center",
    "& img": {
      marginBlock: "10px",
    },
  },
  PapaStudioPoperChild: {
    paddingBlock: "20px",
  },
  left_alighned_text: {
    textAlign: "left",
    width: "100%",
  },
  sideBlock: {
    // width: "300px",
    margin: "10px",
    position: "relative",
  },
  headText: {
    fontSize: "24px",
    fontWeight: "bold",
  },
}));

const CarsPart = (props) => {
  const { t, i18n } = useTranslation();
  const {
    setOpenVisualizationDialog,
    setChangerPoper,
    handleEdit,
    setSelectedAngle,
    images,
    withGrids,
    bodyType,
    globalForm,
    setGlobalForm,
    currentPackage,
    handleChangeSingleImages,
    setNewPackage,
    newPackage,
    selectedAngle,
    setAngleCnt,
    bigBody,
    shadowPack,
    toggleNotification,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.carPartcontainer}>
      <Grid container>
        {console.log("bbbbbbbbbbbbbbbbbbbbb", newPackage)}
        {Object.keys(newPackage).map((el, ind) => (
          <Grid
            item
            md="4"
            onClick={() => {
              if (!(!(globalForm.type === "Papa") || !currentPackage.length)) {
                handleEdit();
                setSelectedAngle(el);
                setAngleCnt(ind);
                setChangerPoper("SideEdit");
              } else {
                toggleNotification({
                  variant: "warning",
                  open: true,
                  message: t(
                    "Please select the Paparazzi option and a background first"
                  ),
                });
              }
            }}
          >
            <div className={classes.sideBlock}>
              <Typography className={classes.left_alighned_text}>
                {el}
              </Typography>
              {globalForm.grids === "with_grids" && newPackage[el] && (
                <>
                  {/* {FiltersTypes.map((el) => console.log("vvvvvvvvvssss", el))} */}
                  {/* {console.log(
                    "vvvvvssss",
                    FiltersTypes.filter((el) => el.serverKey === )
                  )} */}
                  <img
                    width={"100%"}
                    src={
                      FiltersTypes.filter(
                        (elmnt) => elmnt.serverKey === el
                      )?.[0]?.image
                    }
                    alt={""}
                    className={classes.filterOverlay}
                    style={{
                      position: "absolute",
                      // bottom: "0",
                      left: "0",
                      zIndex: 10,
                      // transform:
                      //   globalForm.type !== "Papa"
                      //     ? ""
                      //     : bigBody
                      //     ? ""
                      //     : "scale(0.8)",
                    }} // i think wrong value maybe placeholder at aws
                  />
                </>
              )}
              {globalForm.type === "Papa" ? (
                <>
                  <img
                    width="100%"
                    src={shadowPack[images?.[ind]?.field_title]}
                    className={classes.filterOverlay}
                    style={{
                      position: "absolute",
                      // bottom: "0",
                      left: "0",
                      // transform: bigBody ? "" : "scale(0.8)",
                    }}
                  />
                  {/* Shadow */}
                  <img
                    width="100%"
                    src={
                      shadowPack?.filter(
                        (angle) => angle.image_field_title == el
                      )?.[0]?.image_url
                    }
                    className={classes.filterOverlay}
                    style={{
                      position: "absolute",
                      // bottom: "0",
                      left: "0",
                      // transform: bigBody ? "" : "scale(0.8)",
                    }}
                  />
                  {/* no bg image in all angles */}
                  <img
                    width="100%"
                    src={handleChangeSingleImages("NoBg", images?.[ind]?.image)}
                    className={classes.filterOverlay}
                    style={{
                      position: "absolute",
                      // bottom: "0",
                      left: "0",
                      // transform: bigBody ? "" : "scale(0.8)",
                    }}
                  />

                  {/* this image starts as origional then becomes background */}
                  <img
                    width={"100%"}
                    src={newPackage[el]} // this changes between origional and background after selecting one (shows origional for a bit untill background is loaded)
                    // has same bug as above
                    // src={
                    //   currentPackage
                    //     .filter((elem) => elem.field_title === el)?.[0]
                    //     ?.moods?.filter(
                    //       (mood) => mood.mood_name === globalForm.moodName
                    //     )?.[0]?.img ?? newPackage[el]
                    // }
                    className={classes.filterOverlay}
                    style={currentPackage.length ? {} : { opacity: 0 }}
                  />
                </>
              ) : (
                <img width="100%" src={newPackage[el]} />
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const ShadowPopup = (props) => {
  const { suggestPapaPackage } = props;

  const { t } = useTranslation();

  const [background, setBackground] = useState(4198);
  const [sunLocation, setSunLocation] = useState(4288);
  const [reflection, setReflection] = useState(4295);
  const [interiorLights, setInteriorLights] = useState(4296);

  return (
    <div>
      <Typography /*className={classes.shadowTitle}*/>
        {t("Backgrounds")}
      </Typography>
      <PapaTestRadioGroup
        options={[
          { id: 4198, title: "Outside" },
          { id: 4199, title: "Showroom" },
        ]}
        setVal={setBackground}
        val={background}
      />
      {background == 4198 ? (
        <>
          <Typography /*className={classes.shadowTitle}*/>
            {/* Horizontal Sun Rays */}
            {t("Sun Location")}
          </Typography>

          <PapaTestRadioGroup
            sunLocation={true}
            options={[
              { id: 4283, title: "Driver - Front" },
              { id: 4286, title: "Driver - Rear" },
              { id: 4284, title: "Driver - Top" },
              { id: 4287, title: "Middle - Front" },
              { id: 4289, title: "Middle - Rear" },
              { id: 4288, title: "Middle - Top" },
              { id: 4290, title: "Passenger - Front" },
              { id: 4292, title: "Passenger - Rear" },
              { id: 4291, title: "Passenger - Top" },
            ]}
            setVal={setSunLocation}
            val={sunLocation}
          />
          <Typography /*className={classes.shadowTitle}*/>
            {t("Reflection On Car")}
          </Typography>

          <PapaTestRadioGroup
            options={[
              { id: 4293, title: "Trees" },
              { id: 4294, title: "Buildings" },
              { id: 4295, title: "No Reflection" },
            ]}
            setVal={setReflection}
            val={reflection}
          />
        </>
      ) : (
        <>
          <Typography /*className={classes.shadowTitle}*/>
            {t("Interior Lights")}
          </Typography>
          <PapaTestRadioGroup
            options={[
              { id: 4296, title: "Lights" },
              { id: 4297, title: "No Lights" },
            ]}
            setVal={setInteriorLights}
            val={interiorLights}
          />
        </>
      )}
      <Button
        children="Suggest Background"
        color="secondary"
        variant="contained"
        fullWidth
        onClick={() => {
          suggestPapaPackage({
            location: background,
            sun_location: background == 4198 ? sunLocation : null,
            reflection: background == 4198 ? reflection : null,
            interior_lights: background == 4198 ? null : interiorLights,
          });
        }}
        style={{
          padding: "10px",
          margin: "10px",
          width: "calc(100% - 10px)",
        }}
      />
    </div>
  );
};

const ControlPart = (props) => {
  const { t, i18n } = useTranslation();
  const {
    setOpenVisualizationDialog,
    handleEditTemp,
    setChangerPoper,
    setImages,
    images,
    globalForm,
    setGlobalForm,
    getPaparazziPackageList,
    bgs,
    setBgs,
    selectedPackage,
    handleSubmitChangedImages,
    handleChangeSingleImages,
    newPackage,
    currentPackage,
    getPaparazziPackage,
    car,
  } = props;
  const classes = useStyles();

  const [openShadow, setOpenShadow] = useState(false);
  const [suggestedPackage, setSuggestedPackage] = useState();
  const handleChangeBg = () => {
    setOpenVisualizationDialog((prev) => !prev);
    setChangerPoper("BGs");
    getPaparazziPackageList({
      callback: (res) => {
        setBgs(res.results);
        // const tempnoBgImages = [];
        // images.map((image) => {
        //   image.image = handleChangeSingleImages("NoBg", image.image);
        // });
        // setImages(tempnoBgImages);
        console.log("xxxxxxxxxxxxxxxxx", images, res.results);
      },
    });
  };

  const handleChangeImages = async (type, bgPackage) => {
    let tempImages = [...images];
    tempImages.map((imageObj) => {
      imageObj.width = 2000;
      let imageString = imageObj.image;
      let substringIndex = imageString.indexOf(".com/") + 5;
      let secondSubstringIndex = imageString.indexOf("Car-");
      let newImgName =
        imageString.substring(0, substringIndex) +
        type +
        // "Origional" +
        "-" +
        imageString.substring(secondSubstringIndex, imageString.length);
      bgPackage[imageObj.field_title] = newImgName;
      imageObj.image = newImgName;
    });
    setImages(tempImages);
  };

  const suggestPapaPackage = (packageSelectObject) => {
    // getPaparazziPackage({
    //   location: packageSelectObject.location,
    //   sun_location: packageSelectObject.sun_location,
    //   reflection: packageSelectObject.reflection,
    //   interior_lights: packageSelectObject.interior_lights,
    //   carId: car.id
    // });
  };

  return (
    <div
      style={{
        flex: 3,
        // background: "red",
        padding: "10px",
        display: "flex",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "90%",
        height: "calc(100vh - 90px)", // 90 is 70 header and (10 10) padding
        overflow: "scroll",
      }}
    >
      <div className="form_container">
        {/* <Typography>Grids</Typography> */}
        <div className={classes.controlPartSpacing}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-radio-buttons-group-label"
              className={classes.formPartLabel}
            >
              Grids
            </FormLabel>
            <span className={classes.formPartLabelDividor}></span>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="with_grids"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="with_grids"
                control={<Radio color="primary" />}
                label={t("with grids")}
                onClick={() => {
                  setGlobalForm({ ...globalForm, grids: "with_grids" });
                }}
              />
              <FormControlLabel
                value="without_grids"
                control={<Radio color="primary" />}
                label={t("without grids")}
                onClick={() => {
                  setGlobalForm({ ...globalForm, grids: "without_grids" });
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.controlPartSpacing}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-radio-buttons-group-label"
              className={classes.formPartLabel}
            >
              {t("Shadow & Background Suggest")}
            </FormLabel>
            <span className={classes.formPartLabelDividor}></span>
            <ShadowPopup suggestPapaPackage={suggestPapaPackage} />
          </FormControl>
        </div>
        <div className={classes.controlPartSpacing}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-radio-buttons-group-label"
              className={classes.formPartLabel}
            >
              {t("Choose Background")}
            </FormLabel>
            <span className={classes.formPartLabelDividor}></span>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="original"
                control={<Radio color="primary" />}
                label={t("original")}
                onClick={() => {
                  handleChangeImages("Origional", newPackage);
                  setGlobalForm({ ...globalForm, type: "Origional" });
                }}
              />
              <FormControlLabel
                value="suggested"
                control={<Radio color="primary" />}
                label={t("suggested")}
                onClick={() => {
                  handleChangeImages("Papa", suggestedPackage);
                  setGlobalForm({ ...globalForm, type: "Papa" });
                }}
              />
              <FormControlLabel
                value="manual"
                control={<Radio color="primary" />}
                label={t("manual")}
                onClick={() => {
                  handleChangeImages("Papa", newPackage);
                  setGlobalForm({ ...globalForm, type: "Papa" });
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.controlPartSpacing}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-radio-buttons-group-label"
              className={classes.formPartLabel}
            >
              {t("background name")}
            </FormLabel>
            <span className={classes.formPartLabelDividor}></span>
            <Typography className={classes.filedTitle}>
              {selectedPackage.package_name}
            </Typography>
            <Button
              onClick={handleChangeBg}
              children={t("change background")}
              variant="outlined"
              color="primary"
              style={{
                fontSize: "18px",
                textTransform: "none",
                padding: "6.3px 15px",
              }}
              disabled={!(globalForm.type === "Papa")}
            />
          </FormControl>
        </div>
        <div className={classes.controlPartSpacing}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-radio-buttons-group-label"
              className={classes.formPartLabel}
            >
              {t("initial temperture")}
            </FormLabel>
            <span className={classes.formPartLabelDividor}></span>
            <Typography className={classes.filedTitle}>
              {globalForm.moodName}
            </Typography>
            <Button
              onClick={() => handleEditTemp("all")}
              children={t("change tempreture")}
              variant="outlined"
              color="primary"
              style={{
                fontSize: "18px",
                textTransform: "none",
                padding: "6.3px 15px",
              }}
              disabled={!(globalForm.type === "Papa") || !currentPackage.length} //
            />
          </FormControl>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ padding: "10px", margin: "10px" }}
        onClick={handleSubmitChangedImages}
      >
        {t("save changes")}
      </Button>
    </div>
  );
};

function PapaStudio(props) {
  const classes = useStyles();
  const { openVisualizationDialog, setOpenVisualizationDialog } = props;
  return (
    <>
      <Dialog
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        open={openVisualizationDialog}
        onClose={() => setOpenVisualizationDialog(false)}
      >
        <div style={{ width: "calc(100vw - 64px)" }}>
          <Paper className={classes.visPaperPosition}>
            <div className={classes.PapaStudioHead}>
              <div>
                <div
                  className={classes.closeButt}
                  onClick={() => {
                    setOpenVisualizationDialog(false);
                  }}
                >
                  <span>x</span>
                </div>
                <Button variant="contained" color="primary">
                  save
                </Button>
              </div>
              <div>
                <Typography variant="h2" className={classes.headText}>
                  {props.title}
                </Typography>
              </div>
            </div>
            <div className={classes.PapaStudioPoperChild}>{props.children}</div>
          </Paper>
        </div>
      </Dialog>
    </>
  );
}

function PAangles(props) {
  const { t, i18n } = useTranslation();
  const {
    changeBackgrounds,
    setBGselectorDialog,
    car,
    getCar,
    getPaparazziCarImages,
    bodyType,
    getPaparazziPackageList,
    getTestPaparazziPackage,
    getPaparazziShadows,
    bodyTypeID,
    sun_location,
    originalImage,
    toggleNotification,
    getPaparazziPackage,
  } = props;
  const classes = useStyles();
  const handleChange = () => {};
  const [images, setImages] = useState([]);
  const [carId, setCarId] = useState(0);
  //   const [images, setImages] = useState([]);
  const [getImages, setGetImages] = useState(false);
  const [pointsShifter, setPointsShifter] = useState(null);
  const [changerPoper, setChangerPoper] = useState("BGs");

  const [openVisualizationDialog, setOpenVisualizationDialog] = useState(false);
  const [selectedAngle, setSelectedAngle] = useState("");
  const [angleCnt, setAngleCnt] = useState(0);

  const [withGrids, setWithGrids] = useState(false);
  const [bgs, setBgs] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [currentPackage, setCurrentPackage] = useState([]);
  const [newPackage, setNewPackage] = useState({});
  const [shadowPack, setShadowPack] = useState([]);
  const [globalForm, setGlobalForm] = useState({
    grids: "with_grids",
    type: "",
    papaBG: "",
    initTemp: 0,
    moodName: "neutral",
  });
  const [loadObject, setLoadObject] = useState({});
  const [bigBody, setBigBody] = useState(false);
  const [paparazzi, setPaparazzi] = useState([]);
  const [oldImagesType, setOldImagesType] = useState("");

  useEffect(() => {
    bodyType &&
      setBigBody(
        bodyType?.body_type_object?.selected_option == 932 ||
          bodyType?.body_type_object?.selected_option == 938
      );
  }, [bodyType]);

  useEffect(() => {
    getCar({
      id: car.id,
      callback: () => {},
    });
    getPaparazziCarImages({
      carId: car.id,
      callback: (result) => {
        const pack = {};
        setOldImagesType(
          result[0].image.includes("Origional") ? "Origional" : "Papa"
        );
        setImages(result);
        result.map((el) => {
          pack[el.field_title] = el.image;
        });
        setNewPackage(pack);
        // console.log("rrrrrrrrrrrrrrrrrrrrrrrr", pack);
      },
    });
    // setNewPackage({ ...newPackage, [el.field_title]: el.image });
  }, [getImages]);

  useNonInitialEffect(() => {
    getPaparazziShadows({
      // bodyType: 1,
      bodyType: bodyTypeID,
      sun_location: sun_location,
      callback: (resp) => {
        if (globalForm?.initTemp !== undefined) {
          // added !== undefined as the new nuetral mood's id (initTemp) is 0
          const shadowTemps = {};

          // ////////////////////// Why is this ?? ////////////////////// //
          // var filteredMoodShadow = [];
          // if (globalForm.initTemp) {
          //   // add !== undefined as nuetral mood's id (initTemp) is 0
          //   filteredMoodShadow = resp.results.filter(
          //     (el) => el.mood == globalForm.initTemp // ??
          //   );
          // } else {
          //   filteredMoodShadow = resp.results.filter((el) => el.mood == 1);
          // }
          // ////////////////////// Why is this ?? ////////////////////// //

          console.log(
            "QQQQQQQQQQQQQQQsss",
            resp.results,
            bodyTypeID,
            sun_location
          );

          // resp.results
          //   .filter((el) => el.mood == 1)
          //   .map((el) => {
          //     shadowTemps[el.image_field_title] = el.image_url;
          //   });
          // setShadowPack(shadowTemps);
          setShadowPack(resp.results);
        }
      },
    });
  }, [currentPackage, globalForm.initTemp]);

  const handleGetImages = () => {
    setImages([]);
    setGetImages(!getImages);
  };

  const getImageTitle = (imageLink) => {
    let begining = imageLink.indexOf(".com/") + 5;
    let title = decodeURIComponent(imageLink.substring(begining));
    return title;
  };

  const handleChangeSingleImages = (type, image) => {
    let imageString = image;
    let substringIndex = imageString?.indexOf(".com/") + 5;
    let secondSubstringIndex = imageString?.indexOf("Car-");
    let newImgName =
      imageString?.substring(0, substringIndex) +
      type +
      // "Origional" +
      "-" +
      imageString?.substring(secondSubstringIndex, imageString.length);
    // imageObj.image = newImgName;
    return newImgName;
  };

  const handleSubmitChangedImages = async () => {
    // setLoading(true);
    // const pack = {};

    let tempImages = [...images];
    // const tempnoBgImages = [...result];
    var type = globalForm.type == "Papa" ? "Papa" : "Origional";
    if (type === "Papa") {
      tempImages.map((image, ind) => {
        tempImages[ind].image = handleChangeSingleImages(
          "NoBg",
          tempImages[ind].image
        );
      });
    }

    // if NoBg || Origional => MergeImages (car/bg/shadow/watermark || car/watermark)
    if (["NoBg", "Origional", "Papa"].includes(type)) {
      ////////////////////////////////////////////////////
      await Promise.all(
        tempImages.map(async (imageObj) => {
          let photoKey =
            type == "Origional"
              ? getImageTitle(imageObj.image)
              : getImageTitle(imageObj.image).replace("NoBg", "Papa");
          var finalpack = [];
          currentPackage.map((el, index) => {
            currentPackage[index].image_url =
              newPackage[currentPackage[index].field_title];
          });
          let fieldPapa = currentPackage.find((item) => {
            return item.field_title == imageObj.field_title;
          });
          console.log("zzzzzzzzzzzzzzzz", fieldPapa);
          const { image_url: bgImage, shadow } = fieldPapa ?? {
            image_url: "",
            shadow: "",
          };
          let fetchedCar = await urlToObject(imageObj.image);
          if (!fetchedCar.includes("data:application/xml;")) {
            await imageMerger(
              {
                carImage: fetchedCar,
                bgImage: type == "Origional" ? null : bgImage,
                shadow:
                  type == "Origional" ? null : shadowPack[imageObj.field_title],
              },
              type == "Origional" ? false : true, // exterior (0.8)
              bodyType?.body_type_object?.selected_option == 932 ||
                bodyType?.body_type_object?.selected_option == 938
            )
              .then(async (b64MergedImage) => {
                const base64Response = await fetch(b64MergedImage);
                let pngImage = dataURLtoFile(b64MergedImage, "imagename.jpg");
                // replace s3: Origional => replace Origional / NoBg => replace Papa

                imageObj.image = imageObj.image.replace("NoBg", "Papa");

                handleCompress(pngImage, photoKey, () => {});
                handleCompressMiniImage(
                  pngImage,
                  `CPDTest/${photoKey}`,
                  () => {}
                );
                ///////////////////////////////////xxxx////////////////
              })
              .catch((e) => {
                console.log("errrrrrrffr", e);
              });
          }
        })
      );

      ///////////////////////////////////////////////////
    }
    // replace DB entries
    var isPapaOrigin =
      originalImage[0].image.includes("Papa") ||
      originalImage[0].image.includes("NoBg");
    const explode =
      !bigBody && oldImagesType != globalForm.type
        ? oldImagesType == "Origional" && globalForm.type == "Papa"
          ? false
          : oldImagesType == "Papa" && globalForm.type == "Origional"
          ? true
          : null
        : null;

    changeBackgrounds({
      carId: car.id,
      body: { images: tempImages, explode },
      callback: () => {
        setOldImagesType(globalForm.type);
      },
    });
  };

  const handleEdit = (scope) => {
    setOpenVisualizationDialog((prev) => !prev);
    scope == "all" ? setChangerPoper("TempEdit") : setChangerPoper("SideEdit");
  };

  const BgEditor = (props) => {
    const {
      scope,
      origionalImage,
      papaImage,
      tempImages,
      globalForm,
      setGlobalForm,
      currentPackage,
      originalImage,
      noBGcarImage,
      selectedAngle,
      newPackage,
      shadowPack,
    } = props;

    return (
      <div>
        {selectedAngle}
        <div style={{ display: "flex" }}>
          <div style={{ maxWidth: "50%" }}>
            <Typography>{t("edited (paparazzi)")}</Typography>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                width="90%"
                src={
                  scope == "single"
                    ? newPackage[selectedAngle]
                    : // currentPackage
                      //     .filter((el) => el.field_title === selectedAngle)[0]
                      //     ?.moods.filter(
                      //       (el) => el.mood == globalForm.initTemp
                      //     )[0]?.img
                      currentPackage[0]?.moods.filter(
                        (el) => el.mood == globalForm.initTemp
                      )[0]?.img
                }
              />
              {/* Shadow */}
              <img
                width="90%"
                src={
                  shadowPack?.filter(
                    (angle) => angle.image_field_title == selectedAngle
                  )?.[0]?.image_url
                }
                className={classes.filterOverlay}
                style={{
                  position: "absolute",
                  // bottom: "0",
                  // left: "0",
                  // transform: bigBody ? "" : "scale(0.8)",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  margin: 0,
                }}
              />
              {/* no bg image in angle selector */}
              <img
                width="90%"
                src={noBGcarImage}
                style={{
                  position: "absolute",
                  // bottom: "0",
                  // left: "50%",
                  // marginLeft: "-45%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  margin: 0,
                  // transform: bigBody ? "" : "scale(0.8)",
                }}
              />
            </div>
          </div>
          <div style={{ maxWidth: "50%" }}>
            <Typography>{t("original")}</Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img width="90%" src={originalImage} />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: "2.5%",
          }}
        >
          {currentPackage[scope == "single" ? angleCnt : 0]?.moods?.map(
            (pak) => (
              <div
                style={{
                  width: "300px",
                  width: `${
                    (1 /
                      currentPackage[scope == "single" ? angleCnt : 0]?.moods
                        .length) *
                      100 -
                    5
                  }%`,
                }}
                onClick={
                  scope == "single"
                    ? () => {
                        const newpack = {
                          ...newPackage,
                          [currentPackage[angleCnt].field_title]: pak.img,
                        };
                        console.log("ppppppppppppppp", pak.img);
                        // newpack[currentPackage[angleCnt].field_title] = el.img;
                        setNewPackage(newpack);
                      }
                    : () => {
                        setGlobalForm({
                          ...globalForm,
                          initTemp: pak.mood,
                          moodName: pak.mood_name,
                        });
                        // scope == "single" &&
                        var newpack = {};
                        currentPackage.map((el, ind) => {
                          newpack[currentPackage[ind].field_title] =
                            currentPackage[ind].moods.filter(
                              (smood) => smood.mood == pak.mood
                            )[0].img;
                        });
                        setNewPackage(newpack);
                      }
                }
              >
                <img
                  width="100%"
                  src={pak.img}
                  style={{
                    borderRadius: "10px",
                    border:
                      scope == "single"
                        ? pak.img == newPackage[selectedAngle] &&
                          "5px solid #0c6885"
                        : pak.mood == globalForm.initTemp &&
                          "5px solid #0c6885",
                    boxShadow:
                      scope == "single"
                        ? pak.img == newPackage[selectedAngle] && "0 0 5px #000"
                        : pak.mood == globalForm.initTemp && "0 0 5px #000",
                    boxSizing: "border-box",
                    margin: "1px",
                  }}
                />
                <Typography>{pak.mood_name}</Typography>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const PackgeSelector = (props) => {
    const { getTestPaparazziPackage, currentPackage, setCurrentPackage } =
      props;
    // const
    return (
      <Grid container>
        {bgs.map((el) => (
          <Grid
            item
            md="4"
            textAlign="center"
            onClick={() => {
              setSelectedPackage({
                package_id: el.package,
                package_name: el.package_title,
              });
              getTestPaparazziPackage({
                location: 4198,
                sun_location: 4288,
                reflection: null,
                interior_lights: null,
                carId: el.package,
                package_id: el.package,
                multidata: true,
                callback: (paparazzi) => {
                  setCurrentPackage(paparazzi.images);
                  const pack = newPackage;
                  // setPaparazzi(paparazzi);
                  paparazzi.images.map((el) => {
                    pack[el.field_title] = el.image_url;
                  });
                  setNewPackage(pack);
                },
              });
            }}
          >
            <img
              src={el.image_url}
              width="265"
              style={{
                border:
                  el.package == selectedPackage.package_id &&
                  "5px solid #186985",
              }}
            />
            <Typography>{el.package_title}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "15px",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          borderBottom: "5px solid #000",
          alignItems: "center",
        }}
      >
        <div
          className={classes.closeButt}
          onClick={() => {
            setBGselectorDialog(false);
          }}
        >
          <span>x</span>
        </div>
        <div>{t("all angles")}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // maxHeight: "calc(100vh - 60px)", //calc -64/
          overflow: "hidden",
        }}
      >
        <CarsPart
          setOpenVisualizationDialog={setOpenVisualizationDialog}
          setChangerPoper={setChangerPoper}
          handleEdit={handleEdit}
          setSelectedAngle={setSelectedAngle}
          setAngleCnt={setAngleCnt}
          selectedAngle={selectedAngle}
          images={images}
          withGrids={withGrids}
          bodyType={bodyType}
          globalForm={globalForm}
          setGlobalForm={setGlobalForm}
          currentPackage={currentPackage}
          handleChangeSingleImages={handleChangeSingleImages}
          setNewPackage={setNewPackage}
          newPackage={newPackage}
          shadowPack={shadowPack}
          bigBody={
            bodyType?.body_type_object?.selected_option == 932 ||
            bodyType?.body_type_object?.selected_option == 938
          }
          toggleNotification={toggleNotification}
        />
        <ControlPart
          setOpenVisualizationDialog={setOpenVisualizationDialog}
          handleEditTemp={handleEdit}
          setChangerPoper={setChangerPoper}
          images={images}
          setImages={setImages}
          withGrids={withGrids}
          setWithGrids={setWithGrids}
          globalForm={globalForm}
          setGlobalForm={setGlobalForm}
          getPaparazziPackageList={getPaparazziPackageList}
          bgs={bgs}
          setBgs={setBgs}
          selectedPackage={selectedPackage}
          handleSubmitChangedImages={handleSubmitChangedImages}
          handleChangeSingleImages={handleChangeSingleImages}
          setNewPackage={setNewPackage}
          newPackage={newPackage}
          currentPackage={currentPackage}
          getPaparazziPackage={getPaparazziPackage}
          car={car}
        />
        {/* {alert(changerPoper)} */}
        <PapaStudio
          openVisualizationDialog={openVisualizationDialog}
          setOpenVisualizationDialog={setOpenVisualizationDialog}
          title={
            changerPoper === "BGs"
              ? t("change background")
              : changerPoper === "TempEdit"
              ? t("change tempreture for all angles")
              : selectedAngle
          }
        >
          <div className={classes.bgsList}>
            {changerPoper === "BGs" ? (
              <PackgeSelector
                handleChangeSingleImages={handleChangeSingleImages}
                getTestPaparazziPackage={getTestPaparazziPackage}
                currentPackage={currentPackage}
                setNewPackage={setNewPackage}
                setCurrentPackage={setCurrentPackage}
              />
            ) : (
              <BgEditor
                scope={changerPoper == "TempEdit" ? "all" : "single"}
                globalForm={globalForm}
                setGlobalForm={setGlobalForm}
                currentPackage={currentPackage}
                originalImage={handleChangeSingleImages(
                  "Origional",
                  images[angleCnt].image
                )}
                setNewPackage={setNewPackage}
                noBGcarImage={handleChangeSingleImages(
                  "NoBg",
                  images[angleCnt].image
                )}
                selectedAngle={selectedAngle}
                newPackage={newPackage}
                shadowPack={shadowPack}
              />
            )}
          </div>
        </PapaStudio>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  bodyType: state.car.car.body_type_config,
  bodyTypeID: state.car.car.body_type_config.configuration.id,
  sun_location: state.car.car.paparazzi_selection_data.sun_location,
  originalImage: state.forms.oldImages,
  car: state.car.car,
});

const mapDispatchToProps = (dispatch) => ({
  changeBackgrounds(payload) {
    dispatch(changeBackgrounds(payload));
  },
  getPaparazziCarImages(payload) {
    dispatch(getPaparazziCarImages(payload));
  },
  getCar(payload) {
    dispatch(getCar(payload));
  },
  getPaparazziPackageList(payload) {
    dispatch(getPaparazziPackageList(payload));
  },
  getPaparazziPackage(payload) {
    dispatch(getPaparazziPackage(payload));
  },
  getTestPaparazziPackage(payload) {
    dispatch(getTestPaparazziPackage(payload));
  },
  getPaparazziShadows(payload) {
    dispatch(getPaparazziShadows(payload));
  },
  toggleNotification(payload) {
    dispatch(toggleNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PAangles);
