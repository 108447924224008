import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { setAppMessage } from "../store/actions/webview";

const customQueryStringParser = (str, sep, equ) => {
  const seperatorSplit = str.split(sep);
  const newObj = {};
  seperatorSplit.map((keyValStr) => {
    const equalitySplit = keyValStr.split(equ);
    newObj[equalitySplit[0]] = equalitySplit[1];
  });
  return newObj;
};

const WebViewMessageReceiver = (props) => {
  const { setAppMessage, appMessage } = props;
  useEffect(() => {
    const onMessage = (event) => {
      const parsed = queryString.parse(event.data);
      // const parsed = customQueryStringParser(
      //   event.data,
      //   "=",
      //   "&"
      //   // "%equalSep%",
      //   // "%keyValSep%"
      // );

      setAppMessage(parsed);
    };
    document.addEventListener("message", onMessage);

    return () => document.removeEventListener("message", onMessage);
  }, []);

  // useEffect(() => {
  // alert(JSON.stringify(appMessage));
  // }, [appMessage]);

  return null;
};

const mapStateToProps = (state) => ({
  // appMessage: state.webview.appMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setAppMessage(payload) {
    dispatch(setAppMessage(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebViewMessageReceiver);
