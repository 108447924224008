import React from "react";

const FeatureIcon30 = (props) =>{
const {size, fill, style, className, selected} = props;
return(
<svg style={style} className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#FeatureIcon30Clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9016 38.4298H11.9727V41.1813C11.9727 44.9412 15.0315 48.0001 18.7915 48.0001H26.8304C30.5904 48.0001 33.6493 44.9412 33.6493 41.1813V8.59547C33.6493 6.02447 35.7409 3.93287 38.3119 3.93287C40.8829 3.93287 42.9745 6.02447 42.9745 8.59547V36.6177C42.9745 37.213 43.4573 37.6958 44.0526 37.6958C44.6479 37.6958 45.1307 37.213 45.1307 36.6177V8.59547C45.1306 4.8355 42.0717 1.77661 38.3118 1.77661C34.5518 1.77661 31.4929 4.8355 31.4929 8.59547V41.1813C31.4929 43.7523 29.4013 45.8439 26.8303 45.8439H18.7914C16.2204 45.8439 14.1288 43.7523 14.1288 41.1813V38.4298H14.4121L13 38L11.9016 38.4298Z" fill="#07303E"/>
<mask id="FeatureIcon30Mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="0" width="36" height="50">
<path d="M32.9167 0.833154C27.8541 0.833154 23.75 4.93723 23.75 9.99986V40.8333C23.75 43.1345 21.8845 45 19.5833 45C17.2822 45 15.4167 43.1345 15.4167 40.8333V38.3333H17.0833C17.5435 38.3333 17.9167 37.9602 17.9167 37.5V19.1666C17.9167 18.7064 17.5435 18.3332 17.0833 18.3332H16.25V3.33316C16.25 3.11212 16.1621 2.90025 16.0058 2.744L13.5058 0.243985C13.1804 -0.0813284 12.6529 -0.0813284 12.3275 0.243985L9.82747 2.744C9.67122 2.90025 9.5833 3.11212 9.5833 3.33316V18.3332H8.74996C8.28975 18.3332 7.91663 18.7064 7.91663 19.1666V37.5C7.91663 37.9602 8.28975 38.3333 8.74996 38.3333H12.0833V40.8333C12.0833 45.8959 14.5207 50 19.5833 50C24.646 50 28.7501 45.8959 28.7501 40.8333V9.99986C28.7501 7.6987 30.6156 5.83317 32.9167 5.83317C35.2179 5.83317 37.0834 7.6987 37.0834 9.99986V50H38.7501V9.99986C38.7501 6.77818 36.1384 4.1665 32.9167 4.1665C29.6951 4.1665 27.0834 6.77818 27.0834 9.99986V40.8333C27.0834 44.9754 23.7255 48.3333 19.5833 48.3333C15.4412 48.3333 12.0833 44.9754 12.0833 40.8333V38.3333H15.4167V40.8333C15.4167 44.055 16.3617 46.6667 19.5833 46.6667C22.805 46.6667 25.4167 44.055 25.4167 40.8333V9.99986C25.4167 5.85765 28.7746 2.49983 32.9167 2.49983C37.0589 2.49983 40.4168 5.85765 40.4168 9.99986V50H42.0834V9.99986C42.0834 4.93723 37.9794 0.833154 32.9167 0.833154ZM11.25 3.67817L12.9166 2.01149L14.5833 3.67817V5.83317H11.25V3.67817ZM11.25 7.49985H14.5833V9.99986H11.25V7.49985ZM11.25 11.6665H14.5833V14.1665H11.25V11.6665ZM11.25 15.8332H14.5833V18.3332H11.25V15.8332ZM9.5833 36.6666V19.9999H16.25V36.6666H9.5833Z" fill="#07303E"/>
</mask>
<g mask="url(#FeatureIcon30Mask1)">
<rect y="-8" width="22" height="47" fill="#07303E"/>
</g>
</g>
<defs>
<clipPath id="FeatureIcon30Clip0">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
);
};

export default FeatureIcon30;
